export const ESIG_ENVELOPE_TYPES = {
  AGREEMENT_AND_ORDER: 'AGREEMENT_AND_ORDER',
  CREDIT: 'CREDIT',
  ADDITIONAL_CREDIT: 'ADDITIONAL_CREDIT',
  AMENDMENT: 'AMENDMENT',
};

export const ESIG_ENVELOPE_TYPES_LIST = Object.keys(ESIG_ENVELOPE_TYPES);

export const ESIG_ENVELOPE_TYPE_LABELS = {
  ORDER: 'msg_esig_type_label_order',
  QUOTE: 'msg_quote',
  CREDIT: 'msg_esig_type_label_credit',
  ADDITIONAL_CREDIT: 'msg_additional_credit',
  AMENDMENT: 'msg_esig_type_label_amendment',
};
