/**
 * Uppercase first letter of each word
 * @param {string} string
 * @param {string} splitDelimiter
 * @param {string} joinDelimiter
 * @returns {string}
 */
export const UpperCaseEachWord = (string, splitDelimiter = ' ', joinDelimiter = ' ') => {
  return string
    .toLowerCase()
    .split(splitDelimiter)
    .map(word => word.charAt(0).toUpperCase() + word.substring(1))
    .join(joinDelimiter);
};
