const headerItems = [
  {
    className: 'quotes-table-cell quotes-table-cell_number quotes-table-cell_master_order_id',
    hoverLabel: 'msg_original_quote_id',
    id: 'masterOrderId',
    label: 'msg_original_quote_id_short',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_number quotes-table-cell_id',
    id: 'id',
    label: 'msg_id',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_text quotes-table-cell_customer_name',
    id: 'customerName',
    label: 'msg_customer_name',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_text quotes-table-cell_package_type',
    id: 'packageIdSelected',
    label: 'msg_quote_type',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_text quotes-table-cell_order_type',
    id: 'orderType',
    label: 'msg_order_type',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_currency quotes-table-cell_upfront_total',
    hoverLabel: 'msg_up_front_cost',
    id: 'upfrontTotal',
    label: 'msg_up_front_cost_short',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_currency quotes-table-cell_recurring_total',
    hoverLabel: 'msg_recurring_cost',
    id: 'recurringTotal',
    label: 'msg_recurring_cost_short',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_status',
    id: 'quoteStatus',
    label: 'msg_status',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_date quotes-table-cell_updated',
    id: 'updated',
    label: 'msg_saved_date',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_text quotes-table-cell_title',
    id: 'quoteTitle',
    label: 'msg_quote_title',
  },
  {
    className: 'quotes-table-cell quotes-table-cell_text quotes-table-cell_dealer_contact_name',
    id: 'dealerContactName',
    label: 'msg_quote_owner',
    s2sUsersOnly: true,
  },
  {
    className: 'quotes-table-cell quotes-table-cell_text quotes-table-cell_dealer_name',
    id: 'dealerName',
    label: 'msg_partner',
    s2sUsersOnly: true,
  },
];

export default headerItems;
