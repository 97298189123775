import { CatalogModel, LineItemsCollectionModel, QuoteModel, QuotesCollectionModel } from './';
import {
  constants,
  SOLUTION_TYPES,
  SOLUTION_TYPES_OPTIONS,
  QUOTE_STATUS,
  SCHEDULE_TYPE_OPTIONS,
  WORKSPACE_TYPE_OPTIONS,
  SOLUTION_TYPES_W_SWITCHVOX_OPTIONS,
  SWITCHVOX_ON_PREM_TYPES,
  SWITCHVOX_ON_PREM_TERM_YEARS,
  ORDER_TYPES,
  SOLUTION_TYPES_W_STANDALONE_OPTIONS,
  PRICEBOOK_TAGS,
} from '../common/enums';
import apiData from '../Storage/apiData';
import { PriceBookContext } from '../Context';
import bindMultiple from '../common/helpers/bindMultiple';

class LocationQuoteModel extends QuoteModel {
  constructor(sibling) {
    if (!(sibling instanceof QuotesCollectionModel)) {
      throw new Error('LocationQuoteModel higherOrderComponent should only be a LocationQuoteModel');
    }

    super(sibling);

    this._numLinesMinimumAllowed = 0;
    this._lineItems = new LineItemsCollectionModel(this);
    this._locationUuid = null;
    this._pciCompliant = false;
    this._allocateAllCustomerLevelPackagedApplications = false;
    this._daasScheduleType = SCHEDULE_TYPE_OPTIONS.NONE;
    this._solutionTypeOptions = SOLUTION_TYPES_OPTIONS;
    this._solutionTypeId = SOLUTION_TYPES.BUSINESS_VOICE_PLUS;
    this._numSwitchvoxOnPremUsers = 0;
    this._opportunityProductTypeOverride = null;
    this._smartOffice = false;

    this.lineItemsCatalog = new CatalogModel(this).initLineItemsCatalogs();
    this.shipped = false;
    this.nameIsUniqueOnBe = true;
    this.syncOpportunityProductTypeFromMO = true;
    this.forceDisabled = false;

    this.locationInfo = {
      locationQuoteId: this.uuid,
    };
    this.serviceInfo.locationName = `Location #${sibling.items.length}`;

    bindMultiple(this, this.onNumLinesChange, this.onNumSwitchvoxOnPremUsersChange);

    this._ignoreOnExport.push(
      // TODO: Remove customerOrder from ignore list once getter replaced by masterOrder
      'customerOrder',
      'masterOrder',
      'finalizedLocationWithPackagedApplications'
    );
  }

  onNumLinesChange(value) {
    this.onChange('numLines', value);
  }

  onNumSwitchvoxOnPremUsersChange(value) {
    this.onChange('numSwitchvoxOnPremUsers', value);
  }

  get contractTermMonths() {
    return this.customerOrder.contractTermMonths;
  }

  get numLocationsWithDealerDemoKit() {
    if (this.customerOrder !== null) {
      return this.customerOrder.dealerDemoKit ? this.numLocations : 0;
    }

    return 0;
  }

  get numLocationsWithoutDealerDemoKit() {
    if (this.customerOrder !== null) {
      return this.customerOrder.dealerDemoKit ? 0 : this.numLocations;
    }

    return 0;
  }

  setProps(props, skipProps = []) {
    let dates = {
      dateInstall: props.dateInstall || null,
      dateShippingRequested: props.dateShippingRequested || null,
    };

    this.ictpDates.setProps(dates);

    delete props.ictpDates;

    super.setProps(props, skipProps);

    return this;
  }

  get sequence() {
    const collection = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (collection && collection.locationQuotes) {
      return collection.locationQuotes.findIndex(d => d.uuid === this.uuid);
    }

    return null;
  }

  syncLineItems(singleLocation = false) {
    // Add line items from Master Order if not added already
    for (let i = 0; i < this.customerOrder.lineItems.length; i++) {
      /** @type {LineItemModel} */
      const masterOrderLineItem = this.customerOrder.lineItems.items[i];
      const isPresentOnLocationAlready = this._lineItems.items.some(
        lineItem =>
          lineItem.parentUuid === masterOrderLineItem.uuid && lineItem.existing === masterOrderLineItem.existing
      );

      if (isPresentOnLocationAlready) {
        continue;
      }

      const lineItemApiData = apiData.lineItems.find(d => d.lineItemId === masterOrderLineItem.lineItemId);
      // TODO: while BE doesn't handle ictp line items in locations use this quantity handle
      const notIctpLineItemQuantity = singleLocation ? masterOrderLineItem.quantity : 0;
      const quantity =
        lineItemApiData && lineItemApiData.lineItemCategoryId === constants.ICTP_CATEGORY_ID
          ? masterOrderLineItem.quantity
          : notIctpLineItemQuantity;

      const addFunction = masterOrderLineItem.existing ? this._lineItems.addExisting : this._lineItems.add;
      const lineItem = addFunction(
        {
          ...lineItemApiData,
          quantity: quantity,
          doFocus: false,
        },
        false
      );

      lineItem.setParentUuid(masterOrderLineItem.uuid);
    }

    const lineItemsUuidsToRemove = [];

    // Remove line items that presents on location but missing on Master Order
    for (let i = 0; i < this._lineItems.items.length; i++) {
      /** @type {LineItemModel} */
      const locationLineItem = this._lineItems.items[i];
      const isMasterOrderSiblingExists = this.customerOrder.lineItems.items.some(
        d => d.uuid === locationLineItem.parentUuid
      );

      if (!isMasterOrderSiblingExists) {
        lineItemsUuidsToRemove.push(locationLineItem.uuid);
      }
    }

    for (let i = 0; i < lineItemsUuidsToRemove.length; i++) {
      this._lineItems.removeFromCollectionByUuid(lineItemsUuidsToRemove[i]);
    }
  }

  get lineItems() {
    return this._lineItems;
  }

  set lineItems(lineItemsDataArray) {
    if (!Array.isArray(lineItemsDataArray)) {
      return;
    }

    this.syncLineItems();
    this.ictpProviders.init();

    const masterOrderLineItems = this.customerOrder.lineItems.items;

    for (let i = 0; i < lineItemsDataArray.length; i++) {
      const data = lineItemsDataArray[i];

      // Look for parent line item in customer order quoteLineItemsId
      // quoteLineItemUuid and parentQuoteLineItemUuid are now saved in DB
      // Until the very first quote save
      // quoteLineItemUuid and parentQuoteLineItemUuid are equal to Master Order Line Item uuid
      // But after page reload this values will not match anymore
      const parent = masterOrderLineItems.find(d => d.quoteLineItemUuid === data.parentQuoteLineItemUuid);

      if (parent) {
        const child = this._lineItems.items.find(d => d.parentUuid === parent.uuid);

        if (!child) {
          console.log('%cLocation Line Item not found for data', 'color: red; forn-weight: bold;', data);
        }

        // Update child data
        child.setProps(data);
      } else {
        console.log('%cMaster Order Line Item not found', 'color: red; forn-weight: bold;', data);
      }
    }
  }

  /**
   * @return {CustomerOrderModel|null}
   */
  get customerOrder() {
    /** @type {QuotesCollectionModel} */
    const collection = this.findSibling(d => d instanceof QuotesCollectionModel);

    return collection ? collection.customerOrder : null;
  }

  // TODO: Replace all occurrences of customerOrder getter by masterOrder and remove customerOrder getter
  get masterOrder() {
    return this.customerOrder;
  }

  // Number of locations should be locked to 1 (one) for location quote
  get numLocations() {
    return 1;
  }

  set numLocations(value) {
    // do nothing
  }

  // Confusing naming
  // quoteUserInputId is a real DB ID of customer order
  // TODO: KM-3868 Discuss and update naming
  get quoteUserInputId() {
    return this.customerOrder ? this.customerOrder.id : null;
  }

  get locationName() {
    return this.serviceInfo.locationName;
  }

  set locationName(value) {
    this.serviceInfo.locationName = value;
  }

  get locations() {
    return null;
  }

  set locations(value) {
    // Do nothing
  }

  // BE uses status property name for location quotes not quoteStatus as master order does
  // TODO: Make naming consistent for both MO and location quotes
  get status() {
    return this.quoteStatus;
  }

  set status(value) {
    super.quoteStatus = value;
  }

  get packageIdSelected() {
    return this.customerOrder.packageIdSelected;
  }
  set packageIdSelected(v) {
    // Do nothing
  }

  get locationIctpProviders() {
    for (let i = 0; i < this.ictpProviders.providers.length; i++) {
      /** @type {ProviderModel} */
      const masterOrderProvider = this.customerOrder.ictpProviders.providers[i];
      /** @type {ProviderModel} */
      const locationProvider = this.ictpProviders.providers[i];

      if (locationProvider.selectedOption.lineItemId !== masterOrderProvider.selectedOption.lineItemId) {
        locationProvider.selectOption(masterOrderProvider.selectedOption.lineItemId, false);
      }
    }

    return this.ictpProviders;
  }

  get lineType() {
    return this.customerOrder.lineType;
  }

  set lineType(value) {
    // Do nothing
  }

  get overnightGuarantee() {
    return this.customerOrder.overnightGuarantee;
  }

  get useNumLinesDefault() {
    return false;
  }

  set useNumLinesDefault(value) {
    // Do nothing
  }

  get locationUuid() {
    if (this._locationUuid === null) {
      this._locationUuid = this._uuid;
    }

    return this._locationUuid;
  }

  set locationUuid(value) {
    this._locationUuid = String(value);
  }

  get numSC3InitialSetup() {
    return 0;
  }

  get useESigDocumentsFlow() {
    return this.customerOrder.useESigDocumentsFlow;
  }
  set useESigDocumentsFlow(value) {
    // Do nothing
  }

  get lockedInPrintFlow() {
    return this.customerOrder.lockedInPrintFlow;
  }
  set lockedInPrintFlow(value) {
    // Do nothing
  }

  get pciCompliant() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._pciCompliant;
  }
  set pciCompliant(value) {
    this._pciCompliant = value;
  }

  get allocateAllCustomerLevelPackagedApplications() {
    if (
      PriceBookContext.model.isSupportsSwitchvoxSIPStation ||
      // KM-12904: Force disable package allocation on locations
      //           if no package application available on Master Order level
      !this.customerOrder.packagedApplicationsExists ||
      PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.CPAAS_LOCATION_ENFORCEMENT_DISABLED)
    ) {
      return false;
    }

    return this._allocateAllCustomerLevelPackagedApplications;
  }

  set allocateAllCustomerLevelPackagedApplications(value) {
    // KM-12904: Ignore setter logic for package application allocation on locations
    //           if no package application available on Master Order level
    // KM-13262: Ignore setter logic if PB tag 'CPaaSLocationEnforcementDisabled' exists
    if (
      !this.customerOrder.packagedApplicationsExists ||
      PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.CPAAS_LOCATION_ENFORCEMENT_DISABLED)
    ) {
      return;
    }

    // Setter logic requirements originally described in KM-9928
    if (!value) {
      this._allocateAllCustomerLevelPackagedApplications = value;

      return;
    }

    const finalizedLocationWithPackagedApplications = this.finalizedLocationWithPackagedApplications;

    // Reset Packaged Applications toggles to NO on all locations except finalized
    const locationQuotes = this.customerOrder.locations;

    for (let i = 0; i < locationQuotes.length; i++) {
      /** @type {LocationQuoteModel} */
      const locationQuote = locationQuotes[i];

      if (
        finalizedLocationWithPackagedApplications !== null &&
        locationQuote.uuid === finalizedLocationWithPackagedApplications.uuid
      ) {
        continue;
      }

      // Public `allocateAllCustomerLevelPackagedApplications` setter can not be used here
      // as it will cause infinite loop.
      // Added a public setter method to prevent usage of private property.
      locationQuote.setAllocateAllCustomerLevelPackagedApplications(false);
    }

    if (
      finalizedLocationWithPackagedApplications !== null &&
      // This condition required to allow setting any given value for finalized location
      // on quote retrieve for example
      finalizedLocationWithPackagedApplications.uuid !== this.uuid
    ) {
      return;
    }

    // Set any given value if there is no finalized location with PA allocated yet
    this._allocateAllCustomerLevelPackagedApplications = value;
  }

  setAllocateAllCustomerLevelPackagedApplications(value) {
    this._allocateAllCustomerLevelPackagedApplications = value;
  }

  /**
   *
   * @returns {LocationQuoteModel|null}
   */
  get finalizedLocationWithPackagedApplications() {
    const locationQuotes = this.customerOrder.locations;

    for (let i = 0; i < locationQuotes.length; i++) {
      const { allocateAllCustomerLevelPackagedApplications, status } = locationQuotes[i];

      if (allocateAllCustomerLevelPackagedApplications && status === QUOTE_STATUS.FINALIZED) {
        return locationQuotes[i];
      }
    }

    return null;
  }

  get finalizedLocationNameWithPackagedApplications() {
    /** @type {LocationQuoteModel} */
    const location = this._getCachedValueOnExport('finalizedLocationWithPackagedApplications');

    return location ? location.locationName : '';
  }

  get finalizedLocationUuidWithPackagedApplications() {
    /** @type {LocationQuoteModel} */
    const location = this._getCachedValueOnExport('finalizedLocationWithPackagedApplications');

    return location ? location.uuid : null;
  }

  get packagedApplicationsAllocationBlockedByFinalizedLocation() {
    /** @type {LocationQuoteModel} */
    const location = this._getCachedValueOnExport('finalizedLocationWithPackagedApplications');

    return location !== null;
  }

  get daasScheduleType() {
    const numScheduledDaaS = this.calcResults.getValue('numScheduledDaaS', 'integer');

    if (this.customerOrder !== null && this.customerOrder.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.NONE) {
      return SCHEDULE_TYPE_OPTIONS.NONE;
    }

    if (
      ![SCHEDULE_TYPE_OPTIONS.NONE, SCHEDULE_TYPE_OPTIONS.MIXED].includes(this.customerOrder.daasScheduleType) &&
      numScheduledDaaS > 0
    ) {
      return this.customerOrder.daasScheduleType;
    }

    return this._daasScheduleType;
  }

  set daasScheduleType(v) {
    this._daasScheduleType = v;
  }

  get hideDaasScheduleType() {
    const numScheduledDaaS = this.calcResults.getValue('numScheduledDaaS', 'integer');

    return Boolean(
      ![SCHEDULE_TYPE_OPTIONS.NONE, SCHEDULE_TYPE_OPTIONS.MIXED].includes(this.customerOrder.daasScheduleType) &&
        numScheduledDaaS === 0
    );
  }

  get daasScheduleTypeDisabled() {
    const numScheduledDaaS = this.calcResults.getValue('numScheduledDaaS', 'integer');

    return Boolean(
      ![SCHEDULE_TYPE_OPTIONS.NONE, SCHEDULE_TYPE_OPTIONS.MIXED].includes(this.customerOrder.daasScheduleType) &&
        numScheduledDaaS > 0
    );
  }

  get solutionTypeOptions() {
    if (this.customerOrder.standaloneServiceNonUCaaS) {
      return SOLUTION_TYPES_W_STANDALONE_OPTIONS;
    }

    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return SOLUTION_TYPES_W_SWITCHVOX_OPTIONS;
    }

    return this._solutionTypeOptions;
  }

  set solutionTypeOptions(opts) {
    this._solutionTypeOptions = opts;
  }

  get solutionTypeId() {
    if (this.customerOrder.standaloneServiceNonUCaaS) {
      return SOLUTION_TYPES.STANDALONE_NON_UCAAS;
    }

    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return SOLUTION_TYPES.SWITCHVOX_SIP_STATION;
    }

    if (this.customerOrder.sangomaCXStandalone) {
      return SOLUTION_TYPES.SANGOMA_CX_STANDALONE;
    }

    return this._solutionTypeId;
  }

  set solutionTypeId(val) {
    this._solutionTypeId = val;
  }

  get maintTypeProp() {
    return this.customerOrder.orderType === ORDER_TYPES.ADD_ON
      ? this.customerOrder.switchvoxOnPremMaintTypeEffective
      : this.customerOrder.switchvoxOnPremMaintType;
  }

  get maintTermProp() {
    return this.customerOrder.orderType === ORDER_TYPES.ADD_ON
      ? this.customerOrder.switchvoxOnPremMaintTermYearsEffective
      : this.customerOrder.switchvoxOnPremMaintTermYears;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1R() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.TWO_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1R2() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.THREE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1R4() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.FIVE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1R() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.TWO_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1R2() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.THREE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1R4() {
    if (
      (this.customerOrder.switchvoxOnPremEnabledFromQuoteId !== null ||
        this.customerOrder.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.FIVE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  get numSwitchvoxOnPremUsers() {
    if (!this.customerOrder.switchvoxOnPremEnabled) {
      return 0;
    }

    return this._numSwitchvoxOnPremUsers;
  }

  set numSwitchvoxOnPremUsers(v) {
    this._numSwitchvoxOnPremUsers = v;
  }

  /**
   * DO NOT REMOVE. This getter is used by flag
   * @returns {number}
   */
  get smartOfficeServerQty() {
    return Number(this.smartOffice);
  }

  get opportunityProductType() {
    if (this.syncOpportunityProductTypeFromMO) {
      return this.masterOrder.opportunityProductType;
    }

    return this.opportunityProductTypeOverride;
  }

  set opportunityProductType(value) {
    this.opportunityProductTypeOverride = value;
  }

  get opportunityProductTypeOverride() {
    if (!this.syncOpportunityProductTypeFromMO && this._opportunityProductTypeOverride === null) {
      this._opportunityProductTypeOverride = this.masterOrder.opportunityProductType;
    }

    return this._opportunityProductTypeOverride;
  }

  set opportunityProductTypeOverride(value) {
    this._opportunityProductTypeOverride = value;
  }

  get smartOffice() {
    if (!PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE)) {
      return false;
    }

    return this._smartOffice;
  }

  set smartOffice(value) {
    this._smartOffice = value;
  }
}

export default LocationQuoteModel;
