import React from 'react';
import PropTypes from 'prop-types';
import AbstractInput from '../AbstractInput';
import InputMask from 'react-input-mask';

class MaskInput extends React.Component {
  static propTypes = {
    ...AbstractInput.propTypes,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    formatChars: PropTypes.object,
    alwaysShowMask: PropTypes.bool,
    beforeMaskedValueChange: PropTypes.func,
    mutateValueBeforeCommit: PropTypes.func,
  };

  static defaultProps = {
    ...AbstractInput.defaultProps,
    maskChar: '_',
    alwaysShowMask: false,
  };

  render() {
    const classNames = ['mask-input-field'];

    if (typeof this.props.className === 'string' && this.props.className.trim() !== '') {
      classNames.push(this.props.className);
    }

    const inputMask = (
      <InputMask
        id={this.props.id}
        mask={this.props.mask}
        maskChar={this.props.maskChar}
        formatChars={this.props.formatChars}
        alwaysShowMask={this.props.alwaysShowMask}
        beforeMaskedValueChange={this.props.beforeMaskedValueChange}
      />
    );

    return <AbstractInput {...this.props} className={classNames.join(' ')} inputElement={inputMask} />;
  }
}

export default MaskInput;
