import CommonSectionModel from './CommonSectionModel';
import bindMultiple from '../../common/helpers/bindMultiple';
import { AuthContextModel, PartnerContextModel, PriceBookContextModel } from '../../Context';
import {
  CONTRACT_TERM_MONTH_OPTIONS,
  LINE_TYPES_OPTIONS,
  ORDER_TYPES,
  PACKAGE_TYPE_IDS,
  PRICEBOOK_TAGS,
  QUOTE_STATUS,
  SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
  SWITCHVOX_ON_PREM_TYPES_OPTIONS,
} from '../../common/enums';
import config from '../../config';

class QuoteInformationSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'quote-information-section';
    this.title = 'msg_quote_information';
    this.isGeneralSection = true;

    bindMultiple(
      this,
      this.getSwitchvoxOnPremMaintTypeLabelValue,
      this.isSwitchvoxOnPremMaintTypeDisabled,
      this.getSwitchvoxOnPremMaintTermYearsLabelValue,
      this.isSwitchvoxOnPremMaintTermYearsDisabled,
      this.handleUseNumLinesDefaultChange,
      this.handleNumLinesChange,
      this.handleContractTermMonthsChange,
      this.handleNumLocationsChange,
      this.handleNumStarCloudLocationsChange,
      this.handleLineTypeChange,
      this.handleDealerNetToggleChange,
      this.handleCobrandingEnabledChange,
      this.handleQuoteTitleChange,
      this.handleSwitchvoxOnPremEnabledChange,
      this.handleSipStationEnabledChange,
      this.handleSwitchvoxOnPremMaintTypeChange,
      this.handleSwitchvoxOnPremMaintTermYearsChange,
      this.handleNumSwitchvoxOnPremUsersChange,
      this.handleSmartOfficeLocationsQtyChange,
      this.handleSwitchvoxApplianceProviderChange,
      this.handleAutoSetOpportunityProductTypeChange,
      this.handleOpportunityProductTypeChange
    );
  }

  get __sipStationEnabled() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation && this.customerOrder.sipStationEnabled;
  }

  get __isOneMonthContractTermSelected() {
    return this.customerOrder.contractTermMonths === CONTRACT_TERM_MONTH_OPTIONS.ONE_MONTH.value;
  }

  get __contractTermSipStationEnabledHint() {
    return this.__isOneMonthContractTermSelected ? 'msg_not_applicable' : 'msg_sip_station_term_length';
  }

  get __contractTermSwitchvoxSipStationHint() {
    return this.customerOrder.sipStationEnabled ? this.__contractTermSipStationEnabledHint : 'msg_not_applicable';
  }

  get lockedState() {
    return super.lockedState || this.controller.appState.switchvoxWlwDisabled;
  }

  get showUseNumLinesDefault() {
    return !(
      PriceBookContextModel.flags.hideNumLinesControlsWithDefaultValue !== undefined ||
      [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_THREE].includes(this.customerOrder.packageIdSelected) ||
      PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get showNumLines() {
    return !(
      PriceBookContextModel.flags.hideNumLinesControlsWithDefaultValue !== undefined ||
      [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_THREE].includes(this.customerOrder.packageIdSelected) ||
      PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get showContractTermSelect() {
    return (
      AuthContextModel.hasInternalPermissions ||
      ![ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(this.customerOrder.orderType)
    );
  }

  get isContractTermDisabled() {
    return (
      (this.customerOrder.quoteStatus === QUOTE_STATUS.AMENDING && !AuthContextModel.hasSalesOpsPermissions) ||
      (PriceBookContextModel.isSupportsSwitchvoxSIPStation &&
        (!this.customerOrder.sipStationEnabled || this.customerOrder.hasFinalizedLocations)) ||
      (this.customerOrder.orderType === ORDER_TYPES.ADD_ON &&
        PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.MATCH_ADD_ON_TERM_LENGTH_TO_LOCATION_QUOTE) &&
        !AuthContextModel.hasSalesOpsPermissions) ||
      this.disabledState
    );
  }

  get contractTermHint() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation ? this.__contractTermSwitchvoxSipStationHint : '';
  }

  get contractTermAutoColumns() {
    return (this.__sipStationEnabled && AuthContextModel.isSalesOps) || this.__isOneMonthContractTermSelected;
  }

  get showLocationsNumber() {
    return ![ORDER_TYPES.ADD_ON].includes(this.customerOrder.orderType);
  }
  get showLocationsNumberAsLabel() {
    return ORDER_TYPES.REWRITE === this.customerOrder.orderType;
  }

  get showStarCloudLocations() {
    return (
      !this.customerOrder.sangomaCXStandalone &&
      !this.customerOrder.standaloneServiceNonUCaaS &&
      (config.userInputsSolutionTypeEnabled || AuthContextModel.hasSalesOpsPermissions) &&
      !PriceBookContextModel.isSupportsSwitchvoxSIPStation
    );
  }

  get showStarCloudLocationsAsLabel() {
    return ORDER_TYPES.REWRITE === this.customerOrder.orderType;
  }

  get showBusinessVoicePlusLocations() {
    return !(
      PriceBookContextModel.isSupportsSwitchvoxSIPStation ||
      this.customerOrder.standaloneServiceNonUCaaS ||
      this.customerOrder.sangomaCXStandalone
    );
  }

  get businessVoicePlusLocationsValue() {
    return this.customerOrder.numLocations - this.customerOrder.numStarCloudLocations;
  }

  get showSwitchvoxSIPStationLocations() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get showLineType() {
    const { PACKAGE_TWO, PACKAGE_FOUR } = PACKAGE_TYPE_IDS;

    return (
      [PACKAGE_TWO, PACKAGE_FOUR].includes(this.customerOrder.packageIdSelected) &&
      !PartnerContextModel.isWholeSale &&
      PriceBookContextModel.flags.hideNumLinesControlsWithDefaultValue === undefined
    );
  }

  get lineTypeDisabled() {
    return (
      this.disabledState ||
      (this.customerOrder.quoteStatus === QUOTE_STATUS.UNFINALIZED && !AuthContextModel.hasSalesOpsPermissions)
    );
  }

  get lineTypeValue() {
    return Number(Object.keys(LINE_TYPES_OPTIONS).find(d => LINE_TYPES_OPTIONS[d] === this.customerOrder.lineType));
  }

  get showDealerNetToggle() {
    return (
      config.eSignatureEnabled &&
      this.customerOrder.useESigDocumentsFlow &&
      !PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.HIDE_DEALER_NET_TOGGLE)
    );
  }

  get dealerNetToggleDisabled() {
    return this.customerOrder.isFinanced || this.disabledState;
  }

  get showBulkMarkup() {
    return !PriceBookContextModel.flags.hidePriceAndMarkup;
  }

  get showNrcInputs() {
    return PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.HAS_QUICK_SET_MARKUP_NRC);
  }

  get showRcInputs() {
    return PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.HAS_QUICK_SET_MARKUP_RC);
  }

  get showBusinessContinuity() {
    return this.customerOrder.businessContinuity && !PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get showCoBrandingToggle() {
    return !PartnerContextModel.isWhiteLabelWholesale;
  }

  get coBrandingToggleDisabled() {
    return this.disabledState || this.customerOrder.partnerLogoPath === undefined;
  }

  get showSwitchvoxSipStationToggles() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get switchvoxSipStationTogglesDisabled() {
    return this.disabledState || this.customerOrder.hasFinalizedLocations;
  }

  get showSwitchvoxAsLabel() {
    return this.customerOrder.switchvoxExistsInOriginalLocation;
  }

  get showSipStationAsLabel() {
    return this.customerOrder.sipStationExistsInOriginalLocation;
  }

  get showSwitchvoxOnPremMaintType() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get showSwitchvoxOnPremMaintTypeAsLabel() {
    return this.customerOrder.switchvoxExistsInOriginalLocation;
  }

  get showSwitchvoxOnPremMaintTermYears() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get showSwitchvoxOnPremMaintTermYearsAsLabel() {
    return this.customerOrder.switchvoxExistsInOriginalLocation;
  }

  get showNumSwitchvoxOnPremUsers() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get numSwitchvoxOnPremUsersDisabled() {
    return !this.customerOrder.switchvoxOnPremEnabled || this.disabledState;
  }

  get showStandaloneServiceNonUCaaS() {
    return this.customerOrder.standaloneServiceNonUCaaS;
  }

  get showSmartOfficeLocationsQty() {
    return PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE);
  }

  get smartOfficeLocationsQtyDisabled() {
    return !AuthContextModel.hasSalesOpsPermissions || this.disabledState;
  }

  get showSmartOfficeLocation() {
    return PriceBookContextModel.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE);
  }

  get smartOfficeLocationDisabled() {
    return Boolean(
      (this.customerOrder.id === null && this.customerOrder.smartOfficeFromQuoteId !== null) ||
        (this.customerOrder.id !== null &&
          this.customerOrder.smartOfficeFromQuoteId !== null &&
          this.customerOrder.smartOfficeFromQuoteId !== this.customerOrder.id) ||
        this.disabledState ||
        !AuthContextModel.hasSalesOpsPermissions
    );
  }

  get showSwitchvoxApplianceProvider() {
    return PriceBookContextModel.isSupportsSwitchvoxSIPStation;
  }

  get switchvoxApplianceProviderDisabled() {
    return !this.customerOrder.switchvoxOnPremEnabled || this.disabledState;
  }

  get showOpportunityProductType() {
    return AuthContextModel.hasInternalPermissions;
  }

  get autoSetOpportunityProductTypeDisabled() {
    return !AuthContextModel.hasSalesOpsPermissions || this.disabledState;
  }

  get showOpportunityProductTypeAsLabel() {
    return this.customerOrder.opportunityProductType === null && this.customerOrder.autoSetOpportunityProductType;
  }

  get opportunityProductTypeDropdownDisabled() {
    return (
      this.customerOrder.autoSetOpportunityProductType || !AuthContextModel.hasSalesOpsPermissions || this.disabledState
    );
  }

  getSwitchvoxOnPremMaintTypeLabelValue() {
    return SWITCHVOX_ON_PREM_TYPES_OPTIONS.find(
      opt => this.customerOrder.switchvoxOnPremMaintTypeEffective === opt.value
    )?.text;
  }

  isSwitchvoxOnPremMaintTypeDisabled() {
    return (
      !this.customerOrder.switchvoxOnPremEnabled ||
      this.customerOrder.hasFinalizedLocations ||
      (this.customerOrder.switchvoxOnPremEnabled && !AuthContextModel.hasSalesOpsPermissions) ||
      this.disabledState
    );
  }

  getSwitchvoxOnPremMaintTermYearsLabelValue() {
    return SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS.find(
      opt => this.customerOrder.switchvoxOnPremMaintTermYearsEffective === opt.value
    ).text;
  }

  isSwitchvoxOnPremMaintTermYearsDisabled() {
    return !this.customerOrder.switchvoxOnPremEnabled || this.customerOrder.hasFinalizedLocations || this.disabledState;
  }

  handleUseNumLinesDefaultChange(value) {
    this.customerOrder.onChange('useNumLinesDefault', value);
  }

  handleNumLinesChange(value) {
    this.customerOrder.onChange('numLines', value);
  }

  handleContractTermMonthsChange(value) {
    this.customerOrder.onChange('contractTermMonths', value);
  }

  handleNumLocationsChange(value) {
    this.customerOrder.onChange('numLocations', value);
  }

  handleNumStarCloudLocationsChange(value) {
    this.customerOrder.onChange('numStarCloudLocations', value);
  }

  handleLineTypeChange(value) {
    this.customerOrder.onChange('lineType', LINE_TYPES_OPTIONS[value]);
  }

  handleDealerNetToggleChange(value) {
    this.customerOrder.onChange('dealerNetToggle', value);
  }

  handleCobrandingEnabledChange(value) {
    this.customerOrder.onChange('cobrandingEnabled', value);
  }

  handleQuoteTitleChange(value) {
    this.customerOrder.onChange('quoteTitle', (value || '').substring(0, 51));
  }

  handleSwitchvoxOnPremEnabledChange(value) {
    this.customerOrder.onChange('switchvoxOnPremEnabled', value);
  }

  handleSipStationEnabledChange(value) {
    this.customerOrder.onChange('sipStationEnabled', value);
  }

  handleSwitchvoxOnPremMaintTypeChange(value) {
    this.customerOrder.onChange('switchvoxOnPremMaintType', value);
  }

  handleSwitchvoxOnPremMaintTermYearsChange(value) {
    this.customerOrder.onChange('switchvoxOnPremMaintTermYears', value);
  }

  handleNumSwitchvoxOnPremUsersChange(value) {
    this.customerOrder.onChange('numSwitchvoxOnPremUsers', value);
  }

  handleSmartOfficeLocationsQtyChange(value) {
    this.customerOrder.onChange('smartOfficeLocationsQty', Number(value));
  }

  handleSwitchvoxApplianceProviderChange(value) {
    this.customerOrder.onChange('switchvoxApplianceProvider', value);
  }

  handleAutoSetOpportunityProductTypeChange(value) {
    this.customerOrder.onChange('autoSetOpportunityProductType', value);
  }

  handleOpportunityProductTypeChange(value) {
    this.customerOrder.onChange('opportunityProductType', value);
  }
}

export default QuoteInformationSectionModel;
