const REQUIRED_BILLING_INFO_FIELDS = {
  contactFirstName: '',
  contactLastName: '',
  streetAddress1: '',
  city: '',
  stateId: '',
  zip: '',
  countryId: '',
  contactOfficePhone: '',
  contactEmail: '',
};

export const checkRequiredForTaxEstimateGeneration = (isSaved, isSelectedPackageOpened, billingInfo) => {
  const errors = [];
  const requiredFields = Object.keys(REQUIRED_BILLING_INFO_FIELDS);
  const missing = requiredFields.filter(field => !billingInfo[field]);

  if (!isSaved) {
    errors.push('@msg_please_save_first@\n');
  }

  if (!isSelectedPackageOpened) {
    errors.push('@msg_please_change_packages_and_save_first@\n');
  }

  if (missing.length > 0) {
    errors.push('@msg_please_complete_billing_info_and_save@\n');
  }

  return errors;
};
