export const CONTRACT_TERM_MONTH_OPTIONS = {
  ONE_MONTH: {
    key: '1',
    value: 1,
    text: 'Month to Month',
    overrideRequired: true,
  },
  ONE_YEAR: {
    key: '2',
    value: 12,
    text: '12',
    overrideRequired: true,
  },
  TWO_YEARS: {
    key: '3',
    value: 24,
    text: '24',
    overrideRequired: true,
  },
  THREE_YEARS: {
    key: '4',
    value: 36,
    text: '36',
    overrideRequired: false,
  },
  FOUR_YEARS: {
    key: '5',
    value: 48,
    text: '48',
    overrideRequired: false,
  },
  FIVE_YEARS: {
    key: '6',
    value: 60,
    text: '60',
    overrideRequired: false,
  },
};

export const CONTRACT_TERM_MONTH_OPTIONS_LIST = Object.values(CONTRACT_TERM_MONTH_OPTIONS);
