import { v4 as UUID } from 'uuid';
import ControllerAbstract from '../Controllers/ControllerAbstract';
import { CollectionAbstract } from './index';

export const GET_CLASS_DEEP_INFO = currentInstance => {
  const getters = [];
  const setters = [];
  const methods = [];
  const propsOwn = [];
  const propsInherited = [];
  let ownPropsCollected = false;

  while (currentInstance) {
    const nextInstance = Object.getPrototypeOf(currentInstance);
    const descriptors = Object.getOwnPropertyDescriptors(currentInstance);

    for (let prop in descriptors) {
      const d = descriptors[prop];

      if (typeof d.get === 'function') {
        getters.push(prop);
      }

      if (typeof d.set === 'function') {
        setters.push(prop);
      }

      if (typeof d.value === 'function') {
        methods.push(prop);
      }

      // DEV NOTES
      // Strange behaviour of the JS or babel...
      // Ex. QuotesCollectionModel extends CollectionAbstract which also extends ModelAbstract
      // QuotesCollectionModel <= CollectionAbstract <= ModelAbstract
      // But the real QuotesCollectionModel OWN properties are get on third loop iteration
      // Which is ModelAbstract o.O
      // So if the next instance is not a ModelAbstract instance
      // currentInstance is a right candidate to collect own props
      // TODO: test it with a native ES6. Will behaviour be different than with babel compiled code
      if (nextInstance instanceof ModelAbstract === false && !ownPropsCollected) {
        propsOwn.push(prop);
      } else {
        propsInherited.push(prop);
      }
    }

    if (nextInstance instanceof ModelAbstract === false) {
      ownPropsCollected = true;
    }

    currentInstance = nextInstance;
  }

  return {
    getters,
    setters,
    methods,
    propsOwn,
    propsInherited,
  };
};

export const FIND_SIBLING = (sibling, condition) => {
  if (sibling) {
    if (typeof condition === 'function' && condition(sibling)) {
      return sibling;
    } else if (sibling && typeof sibling.findSibling === 'function') {
      return sibling.findSibling(condition);
    }
  }

  return undefined;
};

// TODO: Remove eslint comment when Proxy re-enabled
// eslint-disable-next-line
const PROXY_GET = (instance, prop, receiver) => {
  // TODO: Re-test smart-cache performance
  // if (instance.exportingProps) {
  //   if (instance._cache[prop] !== undefined) {
  //     return instance._cache[prop];
  //   }
  //
  //   const value = (instance.hasProperty(prop)) ? instance[prop] : instance._proxyGet(prop);
  //
  //   instance._cache[prop] = value;
  //
  //   return value;
  // }

  return instance.hasProperty(prop) ? instance[prop] : instance._proxyGet(prop);
};

// TODO: Remove eslint comment when Proxy re-enabled
// eslint-disable-next-line
const PROXY_SET = (instance, prop, value, receiver) => {
  if (instance.hasSetter(prop) || !instance.hasGetter(prop)) {
    instance[prop] = value;
  }

  if (!instance.hasProperty(prop)) {
    instance._collectClassInfo();
  }

  return true;
};

class ModelAbstract {
  // Naming convention
  // Private props should have double underscore prefix __
  // Protected props should have single underscore prefix _
  // As far as JS has no native protected/private support yet
  // devs should follow it by their self

  // Private prop can NOT be used in child class
  // Protected prop can be used in child class but not as a public variable of class instance

  _uuid;
  _sibling;
  _className;

  _propsOwn = [];
  _propsInherited = [];
  _propsAll = [];
  _getters = [];
  _setters = [];
  // Such syntax end up with property located in the object prototype
  // methodName() { ... }
  // But this one is considered as own property and is readable by Object.getOwnPropertyNames
  // methodName2 = () => { ... }
  _methods = [];

  _ignoreOnExport = ['controller', 'constructor', 'className', 'toJS', 'toJSON', 'setProps', 'exportingProps'];

  /**
   * _exportingProps could be used to do different logic in getters
   * Ex. 1. return different values when accessed as a model prop and another on export
   * Ex. 2. Reset value/do some logic only on export process
   * @type {boolean}
   * @protected
   */
  _exportingProps = false;
  _cache = {};
  _rootExportingModel = null;

  constructor(higherOrderClassInstance) {
    this._sibling = higherOrderClassInstance;
    this._uuid = UUID();

    // Read class name once instead of each getter request
    this._className = Object.getPrototypeOf(this).constructor.name;

    // Collect prop names
    this._collectClassInfo();

    // TODO: @iyatskiv. Re-enable after better testing
    // return new Proxy(this, {
    //   get: PROXY_GET,
    //   set: PROXY_SET
    // });
  }

  static get className() {
    // In static calls "this" is a reference to constructor
    // TODO: investigate how does it behave with different browsers
    return this.name;
  }

  get className() {
    return this._className;
  }

  _collectClassInfo() {
    const info = GET_CLASS_DEEP_INFO(this);

    this._propsOwn = info.propsOwn;
    this._propsInherited = info.propsInherited;
    this._getters = info.getters;
    this._setters = info.setters;
    this._methods = info.methods;

    // Concat own and inherited props only as it also includes getter/setters/methods
    this._propsAll = [].concat(this._propsOwn, this._propsInherited);
  }

  _proxyGet(prop) {
    return undefined;
  }

  // DEV Notes: Do not add any instanceof | typeof
  // as it causes some wierd (breaks?) JS class initialization process.
  // When setProps uses (in)directly in class constructor
  // TODO: Investigate and update deep props set for nested models
  setProps(props, skipProps = []) {
    if (props !== null && typeof props === 'object') {
      for (let prop in props) {
        if (props.hasOwnProperty(prop)) {
          if (skipProps.includes(prop)) {
            continue;
          }

          const value = props[prop];

          if (this[prop] instanceof ModelAbstract && !this.hasSetter(prop)) {
            this[prop].setProps(value);

            continue;
          }

          if (this.canSet(prop)) {
            this[prop] = value;
          }
        }
      }
    }

    return this;
  }

  hasProperty(prop) {
    return this._propsAll.indexOf(prop) !== -1;
  }

  hasOwnProperty(prop) {
    return this._propsOwn.indexOf(prop) !== -1;
  }

  hasInheritedProperty(prop) {
    return this._propsInherited.indexOf(prop) !== -1;
  }

  canSet(prop) {
    // Forbid to set protected and private props
    return !prop.match(/^_{1,2}.*/) && (!this.hasGetter(prop) || this.hasSetter(prop));
  }

  _canExport(prop) {
    let result = true;

    // Skip private and protected props/methods or that don't exist
    if (this._ignoreOnExport.indexOf(prop) !== -1 || prop.match(/^__?.+/gi)) {
      result = false;
    }
    return result;
  }

  findSibling(condition = () => false) {
    return FIND_SIBLING(this._sibling, condition);
  }

  modelChanged(instance, ...props) {
    if (this._sibling && typeof this._sibling.modelChanged === 'function') {
      this._sibling.modelChanged(instance, ...props);
    }
  }

  get controller() {
    return this.findSibling(d => d instanceof ControllerAbstract);
  }

  get uuid() {
    return this._uuid;
  }

  hasGetter(prop) {
    return this._getters.indexOf(prop) !== -1;
  }

  hasSetter(prop) {
    return this._setters.indexOf(prop) !== -1;
  }

  _exportInstance(obj, props, skipFunctions, level, parentUuids, cache, isArray = false) {
    const nextLevel = level + 1;
    let result = isArray ? [] : {};

    for (let i = 0; i < props.length; i++) {
      const prop = props[i];
      const propValue = obj[prop];

      if (typeof propValue === 'function' && skipFunctions) {
        continue;
      }

      if (propValue instanceof ModelAbstract) {
        if (propValue.uuid === obj.uuid) {
          const msg = obj.className + '.' + prop + ' is a reference to itself';

          console.warn(msg);
          throw new Error(msg);
        }

        if (parentUuids.includes(propValue.uuid)) {
          const msg = obj.className + '.' + prop + " is a reference to one of it's parents";

          console.warn(msg);
          throw new Error(msg);
        }

        if (propValue instanceof CollectionAbstract) {
          result[prop] = propValue.toJS(skipFunctions, nextLevel, parentUuids, cache);
          continue;
        }

        Object.defineProperty(result, prop, {
          enumerable: true,
          configurable: true,
          get: function (uuid) {
            return cache[uuid];
          }.bind(null, propValue.uuid),
        });

        propValue.toJS(skipFunctions, nextLevel, parentUuids, cache);

        continue;
      }

      if (Array.isArray(propValue)) {
        result[prop] = this._exportInstance(
          propValue,
          Array.apply(null, { length: propValue.length }).map(Number.call, Number),
          skipFunctions,
          nextLevel,
          parentUuids,
          cache,
          true
        );

        continue;
      }

      if (typeof propValue === 'object' && propValue !== null) {
        // Workaround for react elements
        // Perfectly Models should not contain react components
        // For now ErrorContextModel does receive message property as react component
        if (propValue.$$typeof && propValue.$$typeof === Symbol.for('react.element')) {
          result[prop] = propValue;
          continue;
        }

        // Plain object recursion
        if (propValue === obj) {
          const msg = 'Object recursion';

          console.warn(msg);
          throw new Error(msg);
        }

        result[prop] = this._exportInstance(
          propValue,
          Object.getOwnPropertyNames(propValue),
          skipFunctions,
          nextLevel,
          parentUuids,
          cache
        );

        continue;
      }

      result[prop] = propValue;
    }

    return result;
  }

  toJS(skipFunctions = false, level = 0, parentUuids = [], cache = []) {
    if (cache[this._uuid] !== undefined) {
      return cache[this._uuid];
    }

    this.exportingProps = true;

    const propsAll = this._getters.concat(Object.getOwnPropertyNames(this));
    const props = [];

    for (let i = 0; i < propsAll.length; i++) {
      if (props.includes(propsAll[i]) || !this._canExport(propsAll[i])) {
        continue;
      }

      props.push(propsAll[i]);
    }

    const result = this._exportInstance(this, props, skipFunctions, level, parentUuids.concat(this._uuid), cache);

    cache[this._uuid] = result;

    this.exportingProps = false;

    return result;
  }

  toJSON() {
    return this.toJS(true);
  }

  get exportingProps() {
    if (this._exportingProps) {
      return true;
    }

    if (this._sibling instanceof ModelAbstract) {
      return this._sibling.exportingProps;
    }

    return false;
  }

  set exportingProps(value) {
    this._exportingProps = value;

    if (value === false) {
      this._rootExportingModel = null;
      this._cache = {};
    }
  }

  _getCachedValueOnExport(propName) {
    if (this.exportingProps) {
      const rootModel = this._findTopExportingSibling();
      const index = this._uuid + propName;

      if (rootModel._cache[index] === undefined) {
        rootModel._cache[index] = this[propName];
      }

      return rootModel._cache[index];
    }

    return this[propName];
  }

  /**
   * @param {ModelAbstract} currentModel
   * @param {ModelAbstract} prevModel
   * @private
   */
  _findTopExportingSibling(currentModel = this, prevModel = null) {
    if (this._rootExportingModel !== null) {
      return this._rootExportingModel;
    }

    if (!currentModel.exportingProps) {
      this._rootExportingModel = prevModel;

      return prevModel;
    }

    const parent = currentModel._sibling;

    if (parent instanceof ModelAbstract) {
      return this._findTopExportingSibling(parent, currentModel);
    }

    this._rootExportingModel = currentModel;

    return currentModel;
  }

  forceRender() {
    if (!this.controller) {
      return false;
    }

    this.controller.renderView();

    return true;
  }
}

export default ModelAbstract;
