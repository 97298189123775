import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import translator from '../../common/translate';
import NumLines from '../NumLines';
import PureViewComponent from '../PureViewComponent';
import './css/location-num-lines.css';

class LocationNumLines extends PureViewComponent {
  static propTypes = {
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    selectedLocation: PropTypes.object,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
  };

  render() {
    const { lockedState, selectedLocation, disabledState, total, allocated } = this.props;
    const available = total - allocated;
    const availableClassName = classNames({
      'red-text': available < 0,
    });

    return (
      <div className="location-num-lines">
        <div className="location-num-lines-header">
          <div />
          <div>{translator.getMessage('msg_total')}</div>
          <div>{translator.getMessage('msg_available')}</div>
          <div>{translator.getMessage('msg_quantity')}</div>
        </div>
        <div className="location-num-lines-body">
          <div>{translator.getMessage('msg_number_of_lines_for_location')}</div>
          <div>{total}</div>
          <div className={availableClassName}>{available}</div>
          <div>
            <NumLines
              lockedState={lockedState}
              disabledState={disabledState}
              key={'num-lines-' + selectedLocation.uuid}
              id={'location-' + selectedLocation.uuid}
              tooltipText={'Suggested number of lines based on allocated devices: ' + selectedLocation.numLinesDefault}
              editable={true}
              value={selectedLocation.numLines}
              onChange={selectedLocation.onNumLinesChange}
              label=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LocationNumLines;
