import { LineItemModel } from '../';
import { CELL_TYPES } from '../../components/LineItems/enums';
import { AuthContext, PartnerContext } from '../../Context';
import fixFloat from '../../common/helpers/fixFloat';

export default class IctpLineItemModel extends LineItemModel {
  constructor(props) {
    super(props);

    this._overrideValue = null;
  }

  __setCellTypes() {
    this.cellTypes.locationPrice = CELL_TYPES.amount;
    this.cellTypes.locationOverride = CELL_TYPES.amount;
    this.cellTypes.dealerNet = this.isPartner ? CELL_TYPES.amount : CELL_TYPES.label;
    this.setOverrideCellType();

    if (PartnerContext.model.isWhiteLabelWholesale && this.isPartner) {
      this.cellTypes.dealerNet = CELL_TYPES.label;
    }
  }

  setProps(props) {
    super.setProps(props);

    this.__setCellTypes();

    return this;
  }

  get providerName() {
    return this.lineItemName;
  }

  get isPartner() {
    let isPartner = false;

    if (this.getFlag('ictppsItem')) {
      isPartner = this.getFlag('ictppsItem').valueAsString === 'PARTNER';
    }

    return isPartner;
  }

  get isStar() {
    let isStar = false;

    if (this.getFlag('ictppsItem')) {
      isStar = this.getFlag('ictppsItem').valueAsString === 'STAR2STAR';
    }

    return isStar;
  }

  get overrideValue() {
    return this._overrideValue;
  }

  set overrideValue(value) {
    this._overrideValue = value === null ? null : Number(value) || 0;
  }

  get _allocatedLookup() {
    let result = 0;
    const locationSiblings = this.locationSiblings;

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      result = fixFloat(result, 2) + fixFloat(sibling.price, 2);
    }

    return fixFloat(result, 2);
  }

  get allocated() {
    return this._getCachedValueOnExport('_allocatedLookup');
  }

  get _allocatedFinalizedLookup() {
    let result = 0;
    const locationSiblings = this._finalizedLocationSiblings;

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      result = fixFloat(result, 2) + fixFloat(sibling.price, 2);
    }

    return fixFloat(result, 2);
  }

  get allocatedFinalized() {
    return this._getCachedValueOnExport('_allocatedFinalizedLookup');
  }

  get _allocatedOverrideLookup() {
    let result = null;
    const locationSiblings = this.locationSiblings;

    for (let i = 0; i < locationSiblings.length; i++) {
      /** @type {LocationLineItemModel} */
      const sibling = locationSiblings[i];

      if (sibling.overrideValue !== null) {
        if (result === null) {
          result = 0;
        }

        result = fixFloat(result, 2) + fixFloat(sibling.overrideValue, 2);
      }
    }

    return fixFloat(result, 2);
  }

  get allocatedOverride() {
    return this._getCachedValueOnExport('_allocatedOverrideLookup');
  }

  setOverrideCellType() {
    this.cellTypes.overrideValue = !this.hasFlag('disableOverride') ? CELL_TYPES.amount : CELL_TYPES.label;

    // Hide all non-editable override inputs
    if (
      // Ex. Customer installation can not have override value
      (this.cellTypes.overrideValue === CELL_TYPES.label && this.overrideValue === null) ||
      // Or user do not have override permissions
      !AuthContext.model.hasSalesOpsPermissions
    ) {
      this.cellTypes.overrideValue = '';
      this._cellTypes.overrideValue = '';
    }

    return this.cellTypes.overrideValue;
  }
}
