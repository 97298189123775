import React from 'react';

const Row = ({ data }) => (
  <tr className="checkpoints-table-row">
    <td className="id align-right auto-width-nowrap">{data.id.toString()}</td>
    <td className="value auto-width-nowrap">{data.userData !== null ? data.userData.toString() : ''}</td>
    <td className="is-enabled align-right auto-width-nowrap">{Number(data.enable)}</td>
    <td className="name">{data.name.toString()}</td>
  </tr>
);

export default Row;
