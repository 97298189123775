import React from 'react';
import { FormattedMessage, FormattedDate, FormattedTime, FormattedNumber } from 'react-intl';

/**
 * Class-wrapper for react-intl, for translations
 */
export default class translator {
  /*
   * NOTE: For plain text messages Injection API must be uses within component
   * React Intl provides an API to inject the imperative formatting API into a React component via its props
   * the formatting API will be provided to the wrapped component via props.intl
   * @docs: https://github.com/yahoo/react-intl/wiki/API#injection-api
   */

  /*
   * getMessage() gets basic message with optional param "data"
   * @param {string} key message key to retrieve
   * @param {object} data message variables to pass to FormattedMessage component
   * @returns {React Component} Returns the translated component object.
   */
  static getMessage(key, data = null) {
    const props = data ? { values: data } : null;

    if (typeof key === 'string' && key.trim() !== '') {
      return <FormattedMessage id={key} {...props} />;
    }
  }

  /*
   * getDate() gets Date component formatted based on passed "data"
   * @param {object} data message variables to pass to FormattedDate component
   * @returns {React Component} Returns the translated component object.
   *
   * @example
   *
   * translator.getDate({year:'numeric', month:'long', day:'2-digit', value: (new Date())})
   *
   * @docs: https://github.com/yahoo/react-intl/wiki/Components#formatteddate
   */
  static getDate(data) {
    return <FormattedDate {...data} />;
  }

  /*
   * getTime() gets Time component formatted based on passed "data"
   * @param {object} data message variables to pass to FormattedTime component
   * @returns {React Component} Returns the translated component object.
   *
   * @example
   *
   * translator.getTime({value: (new Date())})
   *
   * @docs: https://github.com/yahoo/react-intl/wiki/Components#formattedtime
   */
  static getTime(data) {
    return <FormattedTime {...data} />;
  }

  /*
   * getNumber() gets Number component formatted based on passed "data"
   * @param {object} data message variables to pass to FormattedNumber component
   * @returns {React Component} Returns the translated component object.
   *
   * @example
   *
   * translator.getNumber({style:'decimal', value: 1004304923.447903})
   *
   * @docs: https://github.com/yahoo/react-intl/wiki/Components#formattednumber
   */
  static getNumber(data) {
    return <FormattedNumber {...data} />;
  }

  /*
   * getCurrency() gets Currency component formatted based on passed "data"
   * @param {object} data message variables to pass to FormattedNumber component
   * @returns {React Component} Returns the translated component object.
   *
   * @example
   *
   * translator.getCurrency({currency: 'USD', value: 1004304923.447843})
   *
   * @docs: https://github.com/yahoo/react-intl/wiki/Components#formattednumber
   */
  static getCurrency(data) {
    return this.getNumber({ ...{ ...data, style: 'currency' } });
  }
}
