import React from 'react';
import { SelectControl } from '../../../Controls';

const ServiceType = ({ label, options, value, onChange, disabled }) => {
  return (
    <SelectControl
      id="service-type-select"
      value={value}
      label={label}
      options={options}
      onChange={onChange}
      disabledState={disabled}
    />
  );
};

export default ServiceType;
