export const WORKSPACE_TYPE_OPTIONS = {
  NONE: 'NONE',
  MULTI_TENANT: 'MULTI_TENANT',
  SINGLE_TENANT: 'SINGLE_TENANT',
};

export const WORKSPACE_TYPE_DROPDOWN_OPTIONS = [
  {
    key: '1',
    value: 'NONE',
    text: 'msg_lease_options_label_none',
  },
  {
    key: '2',
    value: 'MULTI_TENANT',
    text: 'msg_multi_tenant',
  },
  {
    key: '3',
    value: 'SINGLE_TENANT',
    text: 'msg_single_tenant',
  },
];
