import { QUOTE_STATUS } from '../../common/enums';
import ToolbarButtonModel from './ToolbarButtonModel';
import { AuthContext } from '../../Context';

class CancelAmendmentModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_cancel_amendment';
  }

  get disabled() {
    return Boolean(this.errorsCnt || super.disabled || this.appState.switchvoxWlwDisabled);
  }

  get errors() {
    const errors = [];

    if (this.appState.isEdited) {
      errors.push('msg_cancel_amendment_unsaved_quote_error');
    }

    return errors;
  }

  get errorsCnt() {
    return this.errors.length;
  }

  get visible() {
    const { quoteStatus } = this.masterOrder;

    return (
      !this.appState.quoteLoading &&
      this.appState.isSignatureFlowAllowed &&
      this.isESigFlow &&
      AuthContext.model.hasSalesOpsPermissions &&
      quoteStatus === QUOTE_STATUS.AMENDING
    );
  }
}

export default CancelAmendmentModel;
