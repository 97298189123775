import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import translator from '../../common/translate';
import PureViewComponent from '../PureViewComponent';
import './css/location-switchvox-users.css';
import { NumberControl } from '../Controls';

class LocationSwitchvoxUsers extends PureViewComponent {
  static propTypes = {
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    selectedLocation: PropTypes.object,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
  };

  render() {
    const { lockedState, selectedLocation, disabledState, total, allocated } = this.props;
    const available = total - allocated;
    const availableClassName = classNames({
      'red-text': available < 0,
    });

    return (
      <div className="location-switchvox-users">
        <div className="location-switchvox-users-header">
          <div />
          <div>{translator.getMessage('msg_total')}</div>
          <div>{translator.getMessage('msg_available')}</div>
          <div>{translator.getMessage('msg_quantity')}</div>
        </div>
        <div className="location-switchvox-users-body">
          <div>{translator.getMessage('msg_qty_switchvox_users_for_location')}</div>
          <div>{total}</div>
          <div className={availableClassName}>{available}</div>
          <div>
            <NumberControl
              lockedState={lockedState}
              disabledState={disabledState}
              key={'location-switchvox-users-' + selectedLocation.uuid}
              id={'location-switchvox-users-' + selectedLocation.uuid}
              value={selectedLocation.numSwitchvoxOnPremUsers}
              onChange={selectedLocation.onNumSwitchvoxOnPremUsersChange}
              label=""
              editable
            />
          </div>
        </div>
      </div>
    );
  }
}

export default LocationSwitchvoxUsers;
