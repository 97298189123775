export const UI_QUOTE_STATUS = {
  LOADING: 'LOADING',
  SAVING: 'SAVING',
  CALCULATING: 'CALCULATING',
  LOADING_CATALOG: 'LOADING_CATALOG',
  FINALIZING: 'FINALIZING',
  UN_FINALIZING: 'UN_FINALIZING',
  SAVED: 'SAVED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const UI_QUOTE_STATUS_TRANSLATE_CODES = {
  [UI_QUOTE_STATUS.LOADING]: 'msg_ui_quote_status_loading',
  [UI_QUOTE_STATUS.SAVING]: 'msg_ui_quote_status_saving',
  [UI_QUOTE_STATUS.CALCULATING]: 'msg_ui_quote_status_calculating',
  [UI_QUOTE_STATUS.LOADING_CATALOG]: 'msg_ui_quote_status_loading_catalog',
  [UI_QUOTE_STATUS.FINALIZING]: 'msg_ui_quote_status_finalizing',
  [UI_QUOTE_STATUS.UN_FINALIZING]: 'msg_ui_quote_status_un_finalizing',
  [UI_QUOTE_STATUS.SAVED]: 'msg_ui_quote_status_saved',
  [UI_QUOTE_STATUS.IN_PROGRESS]: 'msg_ui_quote_status_in_progress',
};

export const UI_QUOTE_STATUS_LIST = Object.keys(UI_QUOTE_STATUS);
