import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './css/env-badge.css';

class EnvBadge extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
  };

  render() {
    return (
      <div className="env-badge">
        <label className="env-badge-label">{this.props.label}</label>
      </div>
    );
  }
}

export default EnvBadge;
