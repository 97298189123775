import React from 'react';
import { bool, string, object, func } from 'prop-types';
import { TAX_EXEMPT_OPTIONS } from '../../common/enums';
import translator from '../../common/translate';
import { LabelField, SelectControl, ToggleControl } from '../Controls';
import CustomerName from './nested/CustomerName';
import Dba from './nested/Dba';
import DbaToggle from './nested/DbaToggle';
import OvernightGuaranteeToggle from './nested/OvernightGuaranteeToggle';

const CustomerInfo = ({
  // props
  customerOrder,
  lockedState,
  disabledState,
  showContactCenterConcurrencyToggle,
  showProCustomerToggle,
  showOrderTypeFields,
  showOvernightToggle,
  alsDisabled,
  alsTooltip,
  showDbaFields,
  showTaxExemptField,
  showExistingAlsAgreementToggle,
  showCreditDocumentsOnFileLabel,
  showGreatAmericaRentalAgreementOnFileLabel,
  greatAmericaRentalAgreementOnFileLabel,
  creditDocumentsOnFileLabel,
  customerNameEditable,
  customerNameLocked,
  customerNameError,
  dbaError,
  disableDba,
  // methods
  handleDbaNameChange,
  handleDbaToggleChange,
  handleTaxExemptChange,
  handleOvernightGuaranteeChange,
  handleExistingAlsAgreementChange,
  handleContactCenterConcurrencyChange,
  handleCustomerNameCommit,
  handleCustomerNameStateChange,
}) => {
  const { existingServiceInsightCustomerToggle } = customerOrder;

  return (
    <div>
      {customerNameEditable ? (
        <CustomerName
          key="customerNameField"
          id="customerName"
          value={customerOrder.customerName}
          errorText={customerOrder.customerName !== null ? customerNameError : null}
          onChange={handleCustomerNameCommit}
          onStateChange={handleCustomerNameStateChange}
          lockedState={lockedState || customerNameLocked}
          disabledState={disabledState}
          tooltipText={'msg_customer_name_tooltip'}
        />
      ) : (
        <div key="customerNameFieldLabel" className="dealer-customer-name">
          <LabelField
            id="customerName"
            label="msg_customer"
            className="dealer-customer-name-label"
            value={customerOrder.customerName}
          />
        </div>
      )}

      <div key="customerNameValidationLink" className="customer-name-validation-link-block input-abstract-component">
        <span />
        <span>
          <a href="https://partners.sangoma.com/learn/legal-name-search/" target="_blank" rel="noopener noreferrer">
            {translator.getMessage('msg_customer_name_validate_label')}
          </a>
        </span>
      </div>

      {showDbaFields && (
        <>
          <Dba
            key="Dba-field"
            id="DBA"
            value={customerOrder.dba}
            errorText={dbaError}
            onChange={handleDbaNameChange}
            lockedState={lockedState}
            disabledState={disabledState || disableDba}
          />

          <DbaToggle
            key="Dba-toggle-field"
            id="DBA-toggle"
            value={customerOrder.useDba}
            onChange={handleDbaToggleChange}
            lockedState={lockedState}
            disabledState={disabledState || disableDba}
          />
        </>
      )}

      {showTaxExemptField && (
        <div>
          <SelectControl
            id="taxExempt"
            label="msg_tax_exempt"
            key="taxExemptToggleControl"
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder.taxExempt}
            options={TAX_EXEMPT_OPTIONS}
            onChange={handleTaxExemptChange}
            parentClassName="short-input"
            required
            hint={customerOrder.taxExempt ? 'msg_tax_exempt_message' : ''}
          />
        </div>
      )}

      {showOvernightToggle && (
        <OvernightGuaranteeToggle
          value={customerOrder.overnightGuarantee}
          onChange={handleOvernightGuaranteeChange}
          orderType={customerOrder.orderType}
          isPurchase={customerOrder.isPurchase}
          lockedState={lockedState}
          disabledState={disabledState}
        />
      )}

      {showOrderTypeFields && (
        <>
          {showGreatAmericaRentalAgreementOnFileLabel && (
            <LabelField
              id="greatAmericaRentalAgreementOnFile"
              label="msg_rental_agreement_on_file"
              value={translator.getMessage(greatAmericaRentalAgreementOnFileLabel)}
              textRight
            />
          )}
          {showCreditDocumentsOnFileLabel && (
            <LabelField
              id="creditDocumentsOnFile"
              label="msg_credit_documents_on_file"
              value={translator.getMessage(creditDocumentsOnFileLabel)}
              textRight
            />
          )}
          {showExistingAlsAgreementToggle && (
            <ToggleControl
              id="existingAlsAgreement"
              key="existingAlsAgreement"
              label="msg_existing_als_agreement"
              lockedState={lockedState}
              disabledState={alsDisabled}
              tooltipText={alsTooltip}
              value={customerOrder.existingAlsAgreement}
              onChange={handleExistingAlsAgreementChange}
            />
          )}
        </>
      )}

      {showProCustomerToggle && (
        <ToggleControl
          id="proCustomerToggleControl"
          key="proCustomerToggleControl"
          label="msg_pro_customer"
          value={customerOrder.customerAttributes.existingSC3ProfessionalCustomer}
          lockedState={lockedState}
          disabledState={true}
        />
      )}

      {showContactCenterConcurrencyToggle && (
        <ToggleControl
          id="contactCenterConcurrencyToggleControl"
          key="contactCenterConcurrencyToggleControl"
          label="msg_contact_center_concurrency"
          value={customerOrder.contactCenterConcurrency}
          onChange={handleContactCenterConcurrencyChange}
          lockedState={lockedState}
          disabledState={disabledState || !customerOrder.contactCenterConcurrencyToggleEnabled}
        />
      )}

      {existingServiceInsightCustomerToggle.isVisible ? (
        <ToggleControl key={existingServiceInsightCustomerToggle.id} {...existingServiceInsightCustomerToggle} />
      ) : null}
    </div>
  );
};

CustomerInfo.propTypes = {
  customerOrder: object,
  lockedState: bool,
  disabledState: bool,
  hideDate: bool,
  customerNameError: string,
  customerNameEditable: bool,
  customerNameLocked: bool,
  onChangeDba: func,
  dbaError: string,
  disableDba: bool,
  partnerCanSOBO: bool,
  handleCustomerNameCommit: func,
  handleCustomerNameStateChange: func,
  handleDbaChange: func,
  handleUseDbaChange: func,
  handleTaxExemptChange: func,
  handleOvernightGuaranteeChange: func,
  handleExistingAlsAgreementChange: func,
  handleContactCenterConcurrencyChange: func,
  showContactCenterConcurrencyToggle: bool,
  showProCustomerToggle: bool,
  showOrderTypeFields: bool,
  showOvernightToggle: bool,
  alsDisabled: bool,
  alsTooltip: string,
  showDbaFields: bool,
  showTaxExemptField: bool,
  showExistingAlsAgreementToggle: bool,
  showCreditDocumentsOnFileLabel: bool,
  showGreatAmericaRentalAgreementOnFileLabel: bool,
  greatAmericaRentalAgreementOnFileLabel: string,
  creditDocumentsOnFileLabel: string,
};

export default CustomerInfo;
