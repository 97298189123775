import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ToggleControl } from '../../Controls';

const PROP_NAME = 'useSOBO';

class SubmitOnBehalfOfToggle extends PureComponent {
  constructor() {
    super();

    this.handleOnChange = this.handleOnChange.bind(this);
  }
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
    value: false,
  };

  handleOnChange(value) {
    this.props.onChange(value, PROP_NAME);
  }

  render() {
    const { value, lockedState, disabledState, id } = this.props;

    return (
      <div className="sobo-toggle-component">
        <ToggleControl
          {...{ lockedState, disabledState, value }}
          id={id}
          label="msg_submitting_on_behalf_of"
          onChange={this.handleOnChange}
        />
      </div>
    );
  }
}

export default SubmitOnBehalfOfToggle;
