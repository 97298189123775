import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from '../../../Context';
import TEXT_INPUT_TYPES_ENUM from '../../../common/enums/TEXT_INPUT_TYPES_ENUM';
import translator from '../../../common/translate';
import { DatePickerControl } from '../../Controls';
import BadgeButton from '../../BadgeButton';
import '../css/dates.css';

class Dates extends PureComponent {
  static propTypes = {
    dateIsSelected: PropTypes.bool,
    dateShippingRequested: PropTypes.number,
    dateInstall: PropTypes.number,
    minDateShip: PropTypes.number,
    minDateInstall: PropTypes.number,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    onChange: PropTypes.func,
    unset: PropTypes.func,
    openDetails: PropTypes.func,
    disableIctpps: PropTypes.bool,
    timezone: PropTypes.string,
  };

  static defaultProps = {
    dateIsSelected: false,
    dateShippingRequested: null,
    dateInstall: null,
    lockedState: false,
    disabledState: false,
  };

  constructor(props) {
    super(props);

    this.openDetails = this.openDetails.bind(this);
    this.handleChangeDateShippingRequested = this.handleChangeDateShippingRequested.bind(this);
    this.handleChangeDateInstall = this.handleChangeDateInstall.bind(this);
  }

  openDetails() {
    if (typeof this.props.openDetails === 'function') {
      this.props.openDetails();
    }
  }

  handleOnChange(paramValue, paramName) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(paramValue, paramName);
    }
  }

  handleChangeDateShippingRequested(value) {
    this.handleOnChange(new Date(value), 'dateShippingRequested');
  }

  handleChangeDateInstall(value) {
    this.handleOnChange(new Date(value), 'dateInstall');
  }

  render() {
    const {
      lockedState,
      disabledState,
      unset,
      dateIsSelected,
      dateShippingRequested,
      dateInstall,
      minDateShip,
      minDateInstall,
      disableIctpps,
      hideDates,
      timezone,
    } = this.props;

    const hintText = dateIsSelected ? '' : 'msg_select_a_date';

    return (
      <div className="ictp-parameters">
        {!hideDates && (
          <DatePickerControl
            id="ictpShippingDate"
            label="msg_ship_date"
            className="ictp-dates"
            requiredBlue={false}
            errorStyleDates
            inputType={TEXT_INPUT_TYPES_ENUM.DATE}
            hintText={hintText}
            value={dateShippingRequested}
            lockedState={lockedState}
            disabledState={disabledState}
            minDate={minDateShip}
            onUnsetDate={unset}
            onChange={this.handleChangeDateShippingRequested}
            timezone={timezone}
          />
        )}

        {!hideDates && (
          <DatePickerControl
            id="ictpInstallDate"
            label="msg_requested_install_date"
            className="ictp-dates"
            requiredBlue={false}
            errorStyleDates
            inputType={TEXT_INPUT_TYPES_ENUM.DATE}
            hintText={hintText}
            value={dateInstall}
            lockedState={lockedState}
            disabledState={disabledState}
            minDate={minDateInstall}
            onUnsetDate={unset}
            onChange={this.handleChangeDateInstall}
            timezone={timezone}
          />
        )}
        {AuthContext.model.hasInternalPermissions && (
          <BadgeButton
            id="view-ictpps-components"
            key="view-ictpps-components"
            label={translator.getMessage('msg_view_ictpps_components')}
            btnClassName="ictpps-btn tertiary blue-border"
            onClick={this.openDetails}
            errors=""
            hideInfoBadge={true}
            disabled={disableIctpps}
          />
        )}
      </div>
    );
  }
}

export default Dates;
