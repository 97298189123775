import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bindMultiple from '../../../common/helpers/bindMultiple';
import AbstractControl from '../AbstractControl';
import '../css/checkbox-input.css';
import { getTranslateElement } from '../../../common/helpers/getTranslateElement';

class CheckboxControl extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorText: PropTypes.string,
    tooltipText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    onClick: PropTypes.func,
  };

  constructor(props) {
    super(props);

    bindMultiple(this, this.handleChange, this.handleClick);
  }

  render() {
    const {
      // Props for current component
      id,
      value,
      lockedState,
      disabledState,
      className,
      parentClassName,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      errorText,
      required,
      requiredBlue,
      tooltipText,
    } = this.props;

    const inputAbstractProps = {
      hint,
      style,
      focus,
      errorText,
      required,
      requiredBlue,
      disabledState,
      tooltipText,
      className: classNames('checkbox-input-component', className),
      parentClassName: classNames('checkbox-input-abstract', parentClassName),
    };

    const labelText = typeof label === 'string' ? getTranslateElement(label) : label;
    const isLabelNeeded = (typeof labelText === 'string' && labelText.length) || typeof labelText === 'object';
    const checkedClassNames = classNames({
      'icon-check-box': true,
      'icon-check-box-checked': true,
      disabled: lockedState || disabledState,
    });
    const uncheckedClassNames = classNames({
      'icon-check-box': true,
      'icon-check-box-unchecked': true,
      disabled: lockedState || disabledState,
    });

    return (
      <AbstractControl {...inputAbstractProps}>
        <React.Fragment>
          <input
            id={id}
            className="toggle-checkbox"
            checked={value}
            onChange={this.handleChange}
            type="checkbox"
            disabled={lockedState || disabledState}
          />
          <label htmlFor={id} onClick={this.handleClick}>
            {value ? <span className={checkedClassNames} /> : <span className={uncheckedClassNames} />}
            {isLabelNeeded && <span>{labelText}</span>}
          </label>
        </React.Fragment>
      </AbstractControl>
    );
  }

  handleChange() {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(!this.props.value);
    }
  }

  handleClick(ev) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(ev);
    }
  }
}

export default CheckboxControl;
