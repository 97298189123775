import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import DropDown from './nested/DropDown';
import './css/header.css';
import { IntlContext } from '../../Context';

class Header extends PureComponent {
  static propTypes = {
    isNewBrowser: PropTypes.bool,
    showSnackBar: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    snackBarBgColor: PropTypes.string,
    snackBarMessage: PropTypes.string,
    snackBarSubMessage: PropTypes.string,
    toggleDrawer: PropTypes.func,
    signOut: PropTypes.func,
    handleSidebarEscPress: PropTypes.func,
  };

  static defaultProps = {
    isNewBrowser: true,
    showSnackBar: false,
    firstName: '',
    lastName: '',
    snackBarBgColor: '#359d48',
    snackBarMessage: '',
    snackBarSubMessage: '',
    toggleDrawer: () => {},
  };

  constructor(props) {
    super(props);

    this.openDrawer = this.openDrawer.bind(this);
  }

  openDrawer() {
    if (typeof this.props.toggleDrawer === 'function') {
      this.props.toggleDrawer(true);
    }
  }

  render() {
    const initials = (this.props.firstName[0] || '') + (this.props.lastName[0] || '');
    const userName = `${this.props.firstName} ${this.props.lastName}`;

    return (
      <header id="app-header" className="header-wrapper">
        <div className="header-flex-container">
          <div className="header-drawer-button">
            {this.props.isNewBrowser && (
              <button
                id="top-menu-drawer-button"
                title={this.props.intl.formatMessage({
                  id: 'msg_open_main_menu',
                })}
                onClick={this.openDrawer}
                className="square large no-bg no-border"
                onKeyDown={this.props.handleSidebarEscPress}
              >
                <span className="icon-apps" />
              </button>
            )}
          </div>
          <div className="header-username">
            <span className="header-name-label">{userName}</span>
            <DropDown
              signOut={this.props.signOut}
              switchLanguage={this.props.switchLanguage}
              userName={userName}
              initials={initials.toUpperCase()}
              locale={IntlContext.model.locale}
            />
          </div>

          {this.props.showSnackBar && (
            <div className="sub-header-component">
              {this.props.snackBarMessage}
              <span className="sub-header-sub-message">{this.props.snackBarSubMessage}</span>
            </div>
          )}
        </div>
      </header>
    );
  }
}

export default injectIntl(Header);
