import React from 'react';
import { MultiSelectMinimalControl } from '../../../../Controls';

const ColumnFilter = ({ control, type, label, options, selectedOptions, onChangeColumnFilter, onClearColumnFilter }) => {
  const handleChangeColumnFilter = React.useCallback(
    value => onChangeColumnFilter(type, value),
    [onChangeColumnFilter, type]
  );
  const handleClearColumnFilter = React.useCallback(() => onClearColumnFilter(type), [onClearColumnFilter, type]);

  if (control !== 'multiSelectMinimal') {
    return null;
  }

  return (
    <MultiSelectMinimalControl
      id={type + '-multi-select'}
      className={type.toLowerCase() + '-multi-select'}
      label={label}
      options={options}
      values={selectedOptions}
      onChange={handleChangeColumnFilter}
      onClearSelected={handleClearColumnFilter}
    />
  );
};

export default ColumnFilter;
