const CATEGORY_GROUPING_DEFAULTS = [
  // Default settings for new/unrecognized category ID that is not specifically listed below
  {
    id: -1,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Phones
  {
    id: 10,
    groupBy: 'brandName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Adopted Phones
  {
    id: 15,
    groupBy: 'brandName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // SD-WAN Non-Recurring
  {
    id: 30,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // SD-WAN Recurring Services
  {
    id: 40,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // 4G Failover Non-Recurring
  {
    id: 50,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // 4G Failover Recurring Services
  {
    id: 60,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Quote Location Icttps
  {
    id: 70,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Telecom/Services
  {
    id: 100,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Additional Services
  {
    id: 110,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Numbers
  {
    id: 120,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Partner Provided Non-Recurring Items
  {
    id: 200,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Partner Provided Monthly Recurring Items
  {
    id: 300,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Other NRC, Promotions and Discounts
  {
    id: 800,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // RC Promotions and Discounts
  {
    id: 900,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // ICTPPS
  {
    id: 1000,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Starbox
  {
    id: 1100,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: true,
    expandedGroups: [],
  },
  // StarCenter MRC
  {
    id: 1200,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Demo Kit
  {
    id: 1300,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Admin
  {
    id: 1400,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // ATA/Paging
  {
    id: 1500,
    groupBy: 'brandName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Switches
  {
    id: 1600,
    groupBy: 'brandName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // StarCenter NRC
  {
    id: 1700,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Accessories
  {
    id: 1800,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // Legacy Line Products
  {
    id: 1900,
    groupBy: null,
    alwaysExpanded: false,
    expandedGroups: [],
  },
  // StarDaaS
  {
    id: 2000,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  //PackagedApplications
  {
    id: 2200,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  {
    id: 80,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  {
    id: 90,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  {
    id: 2400,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
  {
    id: 2500,
    groupBy: 'lineItemSubCategoryName',
    alwaysExpanded: false,
    expandedGroups: [],
  },
];

export default CATEGORY_GROUPING_DEFAULTS;
