import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bindMultiple from '../../../common/helpers/bindMultiple';
import * as formatter from '../../../common/helpers/format';
import config from '../../../config';
import { AuthContext } from '../../../Context';
import { CheckboxControl } from '../../Controls';
import '../css/catalog-list-item.css';
import { SOLUTION_TYPES, USER_ROLES } from '../../../common/enums';
import apiData from '../../../Storage/apiData';

export default class CatalogListItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    headers: PropTypes.arrayOf(PropTypes.object),
    isSelected: PropTypes.bool,
    onCheckItem: PropTypes.func,
    showSolutionTypeMark: PropTypes.bool,
    preSelected: PropTypes.bool,
    onSuppressAdd: PropTypes.func,
    onSuppressRemove: PropTypes.func,
    isSuppressed: PropTypes.bool,
    isCheckboxDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.isSelected,
      isSuppressed: this.props.isSuppressed,
    };

    bindMultiple(this, this.handleChangeSuppress, this.handleCheckItem);
  }

  __isAllowedToAddOnlyBySalesOps() {
    return this.props.item.minimumRoleRequired === USER_ROLES.SALES_OPERATIONS_USER;
  }

  handleCheckItem() {
    if (this.props.isSelected || this.state.isSuppressed || this.props.isCheckboxDisabled) {
      return false;
    }

    this.setState(
      state => ({
        ...state,
        checked: !state.checked,
      }),
      () => this.props.onCheckItem(this.props.item)
    );
  }

  handleChangeSuppress(isSuppressed) {
    const { item, onSuppressAdd, onSuppressRemove } = this.props;

    if (typeof onSuppressAdd === 'function' && typeof onSuppressRemove === 'function') {
      const callback = isSuppressed ? onSuppressAdd : onSuppressRemove;

      this.setState(
        state => ({
          ...state,
          isSuppressed,
        }),
        () => callback(item.lineItemId)
      );
    }
  }

  __getCheckboxElement() {
    const { isSelected, item, isCheckboxDisabled } = this.props;

    return isSelected ? (
      <span className="icon-check" />
    ) : (
      <CheckboxControl
        id={`add-product-${item.lineItemId}`}
        parentClassName="catalog-list-item-checkbox-component"
        className="catalog-list-item-checkbox"
        value={this.state.checked}
        onChange={this.handleCheckItem}
        disabledState={isCheckboxDisabled}
      />
    );
  }

  render() {
    const { item, headers, preSelected, isSelected, isCheckboxDisabled } = this.props;

    const isSalesOps = AuthContext.model.hasSalesOpsPermissions;
    const isSuppressed = this.state.isSuppressed;

    const includesEitherBvOrBvPlusOnly =
      item.applicableSolutionTypes.length === 1 &&
      (item.applicableSolutionTypes.includes(SOLUTION_TYPES.BUSINESS_VOICE) ||
        item.applicableSolutionTypes.includes(SOLUTION_TYPES.BUSINESS_VOICE_PLUS));

    const componentClassName = classNames({
      'catalog-list-item': true,
      'catalog-list-item-sales-ops': isSalesOps,
      'item-selected': isSelected,
      'item-checked': this.state.checked,
      'item-pre-selected': preSelected && !isSelected,
      'item-suppressed': isSuppressed,
      'item-force-disabled': isCheckboxDisabled,
    });

    const priceCellClassNames = classNames({
      'item-price': true,
      // KM-5155: Style bundle price to look different than regular items
      'bundle-price': item.dealerNet === 0 && item._bundleDealerNet > 0,
    });

    const brandName = apiData.brandNames.find(brandName => brandName.id === item.brandNameId).description;

    return (
      <div className={componentClassName}>
        {isSuppressed ? <span /> : this.__getCheckboxElement()}
        <div className="item-name" title={item.description} onClick={this.handleCheckItem}>
          {this.__isAllowedToAddOnlyBySalesOps() && isSalesOps ? '[SO] ' : ''}
          {this.props.showSolutionTypeMark &&
            includesEitherBvOrBvPlusOnly &&
            { 1: '[BV]', 2: '[BV+]' }[item.applicableSolutionTypes[0]] + ' '}
          {item.lineItemName}
        </div>
        {headers.some(h => h.name === 'brandName') && (
          <div className="item-type" onClick={this.handleCheckItem}>
            {brandName}
          </div>
        )}
        {headers.some(h => h.name === 'lineItemSubCategoryName') && (
          <div className="item-type" onClick={this.handleCheckItem}>
            {item.lineItemSubCategoryName}
          </div>
        )}
        {headers.some(h => h.name === 'dealerNet') && (
          <div className={priceCellClassNames} onClick={this.handleCheckItem}>
            {/* KM-5155: Visually display price of the bundle */}
            {formatter.currency(item.dealerNet || item._bundleDealerNet)}
          </div>
        )}
        {headers.some(h => h.name === 'slick') && item.pdfPath ? (
          // eslint-disable-next-line
          <a
            className="slick-pdf icon-pdf"
            onClick={e => e.stopPropagation()}
            href={config.api.url + '/' + item.pdfPath + '?token=' + AuthContext.model.token}
            target="_blank"
            rel="noopener noreferrer"
          />
        ) : (
          <div onClick={this.handleCheckItem}>&nbsp;</div>
        )}
        {headers.some(h => h.name === 'suppress') && !isSelected && !this.state.checked && (
          <CheckboxControl
            parentClassName="catalog-list-item-checkbox-component-suppress"
            className="catalog-list-item-checkbox-suppress"
            id={`suppress-${item.lineItemId}`}
            onChange={this.handleChangeSuppress}
            value={isSuppressed}
            label=""
          />
        )}
      </div>
    );
  }
}
