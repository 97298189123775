import React, { Component } from 'react';
import { FrameComponent, OrderInitiationComponent } from '../components/views';

class CreateQuoteLayout extends Component {
  render() {
    return (
      <FrameComponent {...this.props}>
        <OrderInitiationComponent {...this.props} />
      </FrameComponent>
    );
  }
}

export default CreateQuoteLayout;
