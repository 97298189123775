import React, { forwardRef } from 'react';
import { bool, func, number } from 'prop-types';
import Pagination from '../../../../Pagination';
import { DEFAULT_PAGE_RANGE } from '../constants';
import usePaginatorStyles from '../hooks/usePaginatorStyles';
import '../styles/pagination-bar.css';

const Paginator = forwardRef(
  (
    {
      activePage = 1,
      itemsCountPerPage = 10,
      onChange,
      pageRangeDisplayed = DEFAULT_PAGE_RANGE,
      totalItemsCount,
      visible = false,
    },
    ref
  ) => {
    const style = usePaginatorStyles();

    return (
      <div className="pagination-bar" style={style} ref={ref}>
        {visible && (
          <div className="pagination">
            <Pagination
              activePage={activePage}
              firstPageText=" "
              isFirstPageStartsWithZero
              itemsCountPerPage={itemsCountPerPage}
              lastPageText=" "
              onChange={onChange}
              pageRangeDisplayed={pageRangeDisplayed}
              totalItemsCount={totalItemsCount}
            />
          </div>
        )}
      </div>
    );
  }
);

Paginator.propTypes = {
  activePage: number,
  itemsCountPerPage: number,
  onChange: func.isRequired,
  pageRangeDisplayed: number,
  totalItemsCount: number.isRequired,
  visible: bool,
};

export default Paginator;
