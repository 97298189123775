import ERROR_TEXT_COLLECTION from '../../common/ERROR_TEXT_COLLECTION';
import ToolbarButtonModel from './ToolbarButtonModel';

class SaveModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_save';
  }

  get disabled() {
    return !this.appState.isSaveAllowed || super.disabled;
  }

  get errors() {
    return this.controller.constantsModel.getSaveFields
      .map(field => this.appState.errors[field])
      .filter(Boolean)
      .reduce((acc, code) => {
        if (Array.isArray(code)) {
          return acc.concat(code);
        }

        return acc.concat(ERROR_TEXT_COLLECTION[code] || code);
      }, []);
  }

  get errorsCnt() {
    return this.errors.length;
  }
}

export default SaveModel;
