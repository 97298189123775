import ToolbarButtonModel from './ToolbarButtonModel';
import { ORDER_TYPES } from '../../common/enums';
import { PriceBookContext } from '../../Context';

class SignaturesModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_signatures';
  }

  get disabled() {
    return !this.masterOrder.id || super.disabled;
  }

  get visible() {
    if (
      this.masterOrder.orderType === ORDER_TYPES.ADD_ON &&
      PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
      !this.masterOrder.isSipStationDocumentsAvailable &&
      this.masterOrder.signatureHistory.length === 0
    ) {
      return false;
    }

    return this.isESigFlow;
  }
}

export default SignaturesModel;
