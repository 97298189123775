import config from '../../config';

const _isVariablesEqual = (var1, var2) => {
  // Types are not equal. Which definitely means (var1 !== var2)
  if (typeof var1 !== typeof var2) {
    return false;
  }

  // Functions is very hard to compare in JS and it could become performance bottleneck
  // Consider any functions as equal
  // Such logic fits well for Objects generated by ModelAbstract.toJS method
  if (typeof var1 === 'function' && var2 === 'function') {
    return true;
  }

  // TODO: NaN values should also be considered as equal
  // In JavaScript NaN !== NaN
  // There should not NaN values ever happen with ModelAbstract.toJS
  // Considering that. NaN equality check is not required at the moment and can be implemented later

  if (Array.isArray(var1) && Array.isArray(var2)) {
    // If array length are not equal no need to do a deep loop
    if (var1.length !== var2.length) {
      return false;
    }

    for (let i = 0; i < var1.length; i++) {
      const isEqual = _isVariablesEqual(var1[i], var2[i]);

      // Break loop on very first difference found
      if (!isEqual) {
        return false;
      }
    }

    // All elements in var1 and var2 are equal
    return true;
  }

  if (typeof var1 === 'object' && var1 !== null && typeof var2 === 'object' && var2 !== null) {
    // Number of properties are not equal
    // No need to do a deep loop. Objects are different for sure
    if (Object.keys(var1).length !== Object.keys(var2).length) {
      return false;
    }

    for (let key in var1) {
      if (!var1.hasOwnProperty(key)) {
        continue;
      }

      const isEqual = _isVariablesEqual(var1[key], var2[key]);

      // Break loop on very first difference found
      if (!isEqual) {
        return false;
      }
    }

    // All properties of var1 and var2 are equal
    return true;
  }

  // Do strict equality for basic variable types: String, Number, Boolean... and null as exception
  // NaN values check will also trigger here. See TO-DO comment above
  return var1 === var2;
};

/**
 * Basic variables comparison aimed at performance.
 * Script breaks at very first difference found.
 *
 * @param {*} var1
 * @param {*} var2
 * @param {*} notes
 * @return {boolean}
 */
const isVariablesEqual = (var1, var2, notes = null) => {
  let t1 = 0;
  let t2 = 0;

  if (config.showConsoleLog && notes) {
    t1 = window.performance.now();
  }

  const result = _isVariablesEqual(var1, var2);

  if (config.showConsoleLog && notes) {
    t2 = window.performance.now();
  }

  if (config.showConsoleLog && notes) {
    console.groupCollapsed('isVariablesEqual: ' + notes, result, t2 - t1 + ' ms');
    console.log('var1', var1);
    console.log('var2', var2);
    console.groupEnd();
  }

  return result;
};

export default isVariablesEqual;
