import React from 'react';
import { injectIntl } from 'react-intl';
import config from '../../config.js';
import { AuthContext } from '../../Context';
import Loader from '../Loader';
import { statusCollection, donutsCollection, loaderCollection } from './proposal.quote.status.props';
import getStatusStyles from './helpers/getStatusStyles';
import './proposal.quote.status.style.css';
import { getQuoteStatusTranslatedString } from '../../common/helpers/getQuoteStatusTranslatedString';

class QuoteStatus extends React.Component {
  render() {
    const isAgent = AuthContext.model.isAgent;
    const quote = this.props.quote;
    const quoteStatusUI = this.props.appState.quoteStatus;
    const quoteStatusBE = quote.quoteStatusLabel;
    const quoteUrl = quote.trackingLocationId ? config.trackingUrl + quote.trackingLocationId : null;
    // TODO: Do a clean-up once this styles moved to CSS file
    const isUIStatusActive = donutsCollection.includes(quoteStatusUI);
    const statusBE = statusCollection[quoteStatusBE];
    const statusUI = statusCollection[quoteStatusUI];
    const isLoaderNeeded = loaderCollection.includes(quoteStatusUI);
    const statusStyle = {
      ...getStatusStyles(isUIStatusActive, statusUI, statusBE),
    };

    const statusLabel = getQuoteStatusTranslatedString(quoteStatusBE);
    const statusUiLabel = getQuoteStatusTranslatedString(quoteStatusUI, this.props.intl);

    return (
      <div className="quote-status-component">
        <Loader
          isVisible={isLoaderNeeded}
          className="small"
          parentClassName={['quote-status-loader', quoteStatusUI.toLowerCase(), quoteStatusBE.toLowerCase()].join(' ')}
          title={statusUiLabel}
        />
        {!isLoaderNeeded && (
          <span
            className={['quote-status-circle', quoteStatusUI.toLowerCase(), quoteStatusBE.toLowerCase()].join(' ')}
            title={statusUiLabel}
            style={statusStyle}
          />
        )}
        <span id={'quote-status'}>{statusLabel}</span>
        {!isAgent && quoteUrl && (
          <span className="quote-status-link">
            <a href={quoteUrl} id={'quote-url'} className="quote-status-btn" target="_blank" rel="noopener noreferrer">
              <span id={'cpq-quote-number'}>#{quote.trackingLocationId}</span>
            </a>
          </span>
        )}
      </div>
    );
  }
}

export default injectIntl(QuoteStatus);
