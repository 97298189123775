import React, { useState } from 'react';
import Modal from 'react-modal';
import { SelectControl, ToggleControl } from '../Controls';
import translator from '../../common/translate';
import apiData from '../../Storage/apiData';
import BadgeButton from '../BadgeButton';
import './styles.css';

const CloneOptionsComponent = ({ onClose, handleQuoteClone, isSalesOps }) => {
  const [retainOverride, setRetainOverride] = useState(true);
  const [industryType, setIndustryType] = useState(null);
  const isCloneButtonDisabled = industryType === null;
  const industryTypeOptions = apiData.industryTypes.map(industryType => {
    return {
      key: industryType.id,
      value: industryType.id,
      text: industryType.name,
    };
  });

  const handleRetainOverrideChange = v => setRetainOverride(v);

  const handleIndustryTypeChange = v => setIndustryType(v);

  const handleClone = () => {
    handleQuoteClone(!retainOverride, industryType);
    onClose();
  };

  return (
    <Modal
      overlayClassName="default-overlay-modal"
      className="default-confirm-modal clone-options-modal"
      bodyOpenClassName="body-overflow-hidden"
      ariaHideApp={false}
      onRequestClose={onClose}
      isOpen
    >
      <div className="default-modal-label">
        <p className="light-bold">{translator.getMessage('msg_clone_options')}</p>
      </div>
      <div className="clone-options-inputs-wrapper">
        {isSalesOps && (
          <ToggleControl
            label="msg_retain_overrides"
            id="clone-options-retain-overrides"
            className="clone-options-retain-overrides"
            value={retainOverride}
            onChange={handleRetainOverrideChange}
          />
        )}
        <SelectControl
          id="clone-options-industry-type"
          className="clone-options-industry-type"
          label="msg_industry_type"
          value={industryType}
          options={industryTypeOptions}
          onChange={handleIndustryTypeChange}
          required
        />
      </div>
      <div className="default-confirm-modal-actions">
        <button
          id="clone-options-cancel-button"
          key="clone-options-cancel-button"
          className="clone-options-cancel-button text-btn"
          onClick={onClose}
        >
          {translator.getMessage('msg_cancel')}
        </button>
        <BadgeButton
          id="clone-options-add-button"
          key="clone-options-add-button"
          className="clone-options-add-button"
          onClick={handleClone}
          disabled={isCloneButtonDisabled}
          errors={isCloneButtonDisabled ? 'msg_industry_type_should_be_selected' : ''}
          label={translator.getMessage('msg_clone')}
          tooltipVerticalAlign="top"
          hideInfoBadge
          blueErrorWarning
        />
      </div>
    </Modal>
  );
};

export default CloneOptionsComponent;
