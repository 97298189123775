import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AmountControl, SelectControl } from '../Controls';
import { LEASE_SELECT_INPUT_OPTIONS } from './enums';
import bindMultiple from '../../common/helpers/bindMultiple';
import { LEASE_OPTIONS, PRICEBOOK_TAGS } from '../../common/enums';
import translator from '../../common/translate';
import './css/leaseOption.css';
import { currency } from '../../common/helpers/format';
import ExternalLink from '../ExternalLink';
import BadgeButton from '../BadgeButton';
import { PriceBookContext } from '../../Context';

class LeaseOption extends Component {
  static propTypes = {
    disabledState: PropTypes.bool,
    lockedState: PropTypes.bool,
    leaseOption: PropTypes.string,
    onOptionChange: PropTypes.func,
    onPaymentChange: PropTypes.func,
    enteredAmount: PropTypes.number,
    calculatedAmount: PropTypes.number,
    onLeaseApplicationClick: PropTypes.func,
    leaseButtonErrors: PropTypes.array,
    leaseButtonDisabled: PropTypes.bool,
  };

  static defaultProps = {
    leaseButtonErrors: [],
    leaseButtonDisabled: true,
  };

  constructor(props) {
    super(props);

    bindMultiple(this, this.handleOptionChange, this.handlePaymentChange);
  }

  handleOptionChange(value) {
    if (typeof this.props.onOptionChange === 'function') {
      this.props.onOptionChange(value);
    }
  }

  handlePaymentChange(value) {
    if (typeof this.props.onPaymentChange === 'function') {
      this.props.onPaymentChange(value);
    }
  }

  getLeasePaymentComponent() {
    const { disabledState, lockedState, leaseOption, enteredAmount, calculatedAmount } = this.props;

    if (leaseOption === LEASE_OPTIONS.NONE) {
      return <strong>{translator.getMessage('msg_not_available_abbreviation')}</strong>;
    }

    if (leaseOption === LEASE_OPTIONS.THIRD_PARTY_CUSTOM_AMOUNT) {
      return (
        <AmountControl
          id="lease-option-amount-input"
          onChange={this.handlePaymentChange}
          disabledState={disabledState}
          lockedState={lockedState}
          value={enteredAmount}
        />
      );
    }

    if (calculatedAmount <= 0) {
      return <strong>{translator.getMessage('msg_not_eligible')}</strong>;
    }

    return <strong>{currency(calculatedAmount)}</strong>;
  }

  render() {
    const { disabledState, lockedState, leaseOption, leaseButtonErrors, onLeaseApplicationClick, leaseButtonDisabled } =
      this.props;

    const { pricebookTag } = PriceBookContext.model.flags;
    const leaseOptionsList = LEASE_SELECT_INPUT_OPTIONS.filter(
      ({ value }) =>
        value === leaseOption ||
        !pricebookTag.includes(PRICEBOOK_TAGS.SUPPRESS_NAVITAS_PROMOTIONAL_LEASE_RATE) ||
        value !== LEASE_OPTIONS.NAVITAS_PROMOTIONAL_RATE
    );

    return (
      <>
        <label htmlFor="lease-option-select-control">{translator.getMessage('msg_lease_option_control_label')}</label>

        <SelectControl
          id="lease-option-select-control"
          options={leaseOptionsList}
          value={leaseOption}
          onChange={this.handleOptionChange}
          disabledState={disabledState}
          lockedState={lockedState}
        />

        <label>{translator.getMessage('msg_lease_payment_control_label')}</label>

        {this.getLeasePaymentComponent()}

        <span>
          <BadgeButton
            disabled={leaseButtonDisabled}
            errors={leaseButtonErrors}
            errorsCnt={leaseButtonErrors.length}
            btnClassName="tertiary blue-border"
            onClick={onLeaseApplicationClick}
            label={translator.getMessage('msg_lease_form_dialog_trigger_button')}
            tooltipAlign={'left'}
            tooltipVerticalAlign={'top'}
          />
        </span>

        <ExternalLink
          path="https://navitex.navitascredit.com/resource_centers/star2star_ce"
          title={translator.getMessage('msg_lease_options_link_label')}
        />
      </>
    );
  }
}

export default LeaseOption;
