import * as formatter from '../../common/helpers/format';
import { ORDER_TYPES, PACKAGE_TYPE_IDS } from '../../common/enums';
import CommonPackageModel from './CommonPackageModel';
import { AuthContext } from '../../Context';

class PackageFourModel extends CommonPackageModel {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this.id = PACKAGE_TYPE_IDS.PACKAGE_FOUR;
    this.packageClassName = 'package-4';
  }

  get available() {
    const { locationPackageType, orderType } = this.customerOrder;

    if (orderType !== ORDER_TYPES.ADD_ON || !locationPackageType) {
      return true;
    }

    return (
      AuthContext.model.hasSalesOpsPermissions || [this.id, PACKAGE_TYPE_IDS.PACKAGE_TWO].includes(locationPackageType)
    );
  }

  get nrc() {
    return {
      className: this.animatedClassName,
      value: formatter.currency(this.summaryValues['proposalUpfront4'], 0),
    };
  }

  get rc() {
    return {
      className: this.animatedClassName,
      value: formatter.currency(this.summaryValues['proposalRecur4'], 0),
    };
  }
}

export default PackageFourModel;
