import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import bindMultiple from '../../../../common/helpers/bindMultiple';

class DayButton extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    selected: PropTypes.bool,
    preselected: PropTypes.bool,
    isMonthAfter: PropTypes.bool,
    isMonthBefore: PropTypes.bool,
    isToday: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    bindMultiple(this, this.handleOnClick, this.handleOnKeyDown, this.handleOnFocus);
  }

  handleOnClick() {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(this.props.value);
    }
  }

  handleOnKeyDown(e) {
    if (e.key !== 'Enter') {
      return;
    }

    this.handleOnClick();
  }

  handleOnFocus(ev) {
    ev.stopPropagation();
  }

  render() {
    const { label, disabled, selected, className, preselected, isMonthAfter, isMonthBefore, isToday } = this.props;

    const dayClassName = classnames(
      {
        'selected-day': selected,
        'preselected-day': preselected,
        'gray-btn': disabled || isMonthAfter || isMonthBefore,
        'is-today': isToday,
      },
      'day-btn-component',
      className
    );

    return (
      <span
        className={dayClassName}
        onClick={this.handleOnClick}
        onKeyDown={this.handleOnKeyDown}
        onFocus={this.handleOnFocus}
        tabIndex="0"
      >
        <span className="day-btn-label">{label}</span>
      </span>
    );
  }
}

export default DayButton;
