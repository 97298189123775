import React from 'react';

const PartnersContext = React.createContext({
  partners: [],
  partnersFetching: false,
  selectedPartnerId: null,
  selectedPartnerLabel: '',
});

PartnersContext.displayName = 'PartnersContext';

export default PartnersContext;
