export const BILLING_EMAIL_VALIDATION_STATUSES = {
  NONE: 'NONE',
  UNKNOWN: 'UNKNOWN',
  VALID: 'VALID',
  INVALID: 'INVALID',
  RISKY: 'RISKY',
  CONFIRMED_AS_VALID: 'CONFIRMED_AS_VALID',
  ERROR: 'ERROR',
};

export const VALIDATED_STATUSES = [
  BILLING_EMAIL_VALIDATION_STATUSES.VALID,
  BILLING_EMAIL_VALIDATION_STATUSES.INVALID,
  BILLING_EMAIL_VALIDATION_STATUSES.RISKY,
  BILLING_EMAIL_VALIDATION_STATUSES.CONFIRMED_AS_VALID,
  BILLING_EMAIL_VALIDATION_STATUSES.ERROR,
];
