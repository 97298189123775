import ToolbarButtonModel from './ToolbarButtonModel';

class FinalizedModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_finalized';
  }

  get disabled() {
    return true;
  }

  get visible() {
    return this.areLocationsDone && !this.isESignatureFlowAllowed && this.isNotQuoteLoading;
  }

  get infoTooltip() {
    const { allLocationsAreShipped } = this.masterOrder;

    return allLocationsAreShipped ? 'msg_all_locations_shipped' : 'msg_all_locations_finalized';
  }
}

export default FinalizedModel;
