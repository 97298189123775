import { PureComponent } from 'react';
import PropTypes from 'prop-types';

class LocationActionBtn extends PureComponent {
  static propTypes = {
    action: PropTypes.func.isRequired,
    confirmText: PropTypes.node,
    actionLabel: PropTypes.object,
    disabled: PropTypes.bool,
    id: PropTypes.string,
  };

  render() {
    return this.props.children;
  }
}

export default LocationActionBtn;
