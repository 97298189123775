import { SWITCHVOX_ON_PREM_TYPES } from './switchvoxOnPremTypes';

export const ADMIN_SECTION_SWITCHVOX_ON_PREM_TYPES_OPTIONS = [
  {
    key: '1',
    value: null,
    text: 'msg_no_override',
  },
  {
    key: SWITCHVOX_ON_PREM_TYPES.PLATINUM,
    value: SWITCHVOX_ON_PREM_TYPES.PLATINUM,
    text: 'msg_platinum',
  },
  {
    key: SWITCHVOX_ON_PREM_TYPES.TITANIUM,
    value: SWITCHVOX_ON_PREM_TYPES.TITANIUM,
    text: 'msg_titanium',
  },
];

export const ADMIN_SECTION_SWITCHVOX_ON_PREM_TERM_OPTIONS = [
  {
    key: '1',
    value: null,
    text: 'msg_no_override',
  },
  {
    key: 'switchvoxTermYears-1',
    value: 1,
    text: 'msg_one_year',
  },
  {
    key: 'switchvoxTermYears-2',
    value: 2,
    text: 'msg_two_years',
  },
  {
    key: 'switchvoxTermYears-3',
    value: 3,
    text: 'msg_three_years',
  },
  {
    key: 'switchvoxTermYears-5',
    value: 5,
    text: 'msg_five_years',
  },
];
