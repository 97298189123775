import React from 'react';
import { bool, number, string } from 'prop-types';
import classNames from 'classnames';
import translator from '../../../../common/translate';

const Input = ({ checked = false, customStyles = false, disabled = false, id, label, value }) => {
  const radioClassNames = classNames({
    'radio-input': !customStyles,
    disabled: disabled,
    checked: checked,
  });

  return (
    <>
      <input type="radio" id={id} name="order-type" value={value} defaultChecked={checked} disabled={disabled} />
      <label className={radioClassNames} htmlFor={id}>
        {translator.getMessage(label)}
      </label>
    </>
  );
};

Input.propTypes = {
  checked: bool,
  customStyles: bool,
  disabled: bool,
  id: string.isRequired,
  label: string.isRequired,
  value: number.isRequired,
};

export default Input;
