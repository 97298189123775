import React from 'react';
import translator from '../../../../common/translate/index';
import dayjs from 'dayjs';

const Reassignment = ({ reassignment }) => {
  const reassignedBy = reassignment.reassignedByName + ' ' + reassignment.reassignedByEmail;
  const reassignedTo = reassignment.reassignedToName + ' ' + reassignment.reassignedToEmail;

  return (
    <div>
      <div className="reassignments-tooltip-row">
        <div>{translator.getMessage('msg_on')}:</div>
        <div>{dayjs(reassignment.creationDate).format('MM/DD/YYYY h:mm:ss A')}</div>
      </div>
      <div className="reassignments-tooltip-row">
        <div>{translator.getMessage('msg_by')}:</div>
        <div>{reassignedBy}</div>
      </div>
      <div className="reassignments-tooltip-row">
        <div>{translator.getMessage('msg_to')}:</div>
        <div>{reassignedTo}</div>
      </div>
    </div>
  );
};

export default Reassignment;
