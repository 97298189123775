const ORDER_TYPES = {
  NEW_CUSTOMER: 'NEW_CUSTOMER_NEW_LOCATION',
  NEW_LOCATIONS: 'EXISTING_CUSTOMER_NEW_LOCATION',
  ADD_ON: 'ADDON_TO_EXISTING_LOCATION',
  REDUCTION: 'REDUCTION_FROM_EXISTING_LOCATION',
  REWRITE: 'REWRITE',
};

export default ORDER_TYPES;

export const ORDER_TYPES_TRANSLATE = {
  NEW_CUSTOMER_NEW_LOCATION: 'msg_new_customer_new_location',
  EXISTING_CUSTOMER_NEW_LOCATION: 'msg_existing_customer_new_location',
  ADDON_TO_EXISTING_LOCATION: 'msg_add_on_to_existing_location',
  REWRITE: 'msg_order_type_rewrite_label',
  REDUCTION_FROM_EXISTING_LOCATION: 'msg_change_order',
};

export const ORDER_TYPES_LINKS = {
  EXISTING_CUSTOMER_NEW_LOCATION: 'new',
  ADDON_TO_EXISTING_LOCATION: 'add-on',
  REWRITE: 'rewrite',
};

export const ORDER_TYPE_OPTIONS = {
  NEW_CUSTOMER: 1,
  NEW_LOCATIONS: 2,
  ADD_ON: 3,
  REWRITE: 4,
  REDUCTION: 5,
};

export const ORDER_TYPES_BY_ID = {
  1: 'NEW_CUSTOMER',
  2: 'NEW_LOCATIONS',
  3: 'ADD_ON',
  4: 'REWRITE',
  5: 'REDUCTION',
};

export const ORDER_TYPE_DROPDOWN_OPTIONS = [
  {
    key: ORDER_TYPES.NEW_CUSTOMER,
    value: ORDER_TYPES.NEW_CUSTOMER,
    text: ORDER_TYPES_TRANSLATE[ORDER_TYPES.NEW_CUSTOMER],
  },
  {
    key: ORDER_TYPES.NEW_LOCATIONS,
    value: ORDER_TYPES.NEW_LOCATIONS,
    text: ORDER_TYPES_TRANSLATE[ORDER_TYPES.NEW_LOCATIONS],
  },
  {
    key: ORDER_TYPES.ADD_ON,
    value: ORDER_TYPES.ADD_ON,
    text: ORDER_TYPES_TRANSLATE[ORDER_TYPES.ADD_ON],
  },
  {
    key: ORDER_TYPES.REWRITE,
    value: ORDER_TYPES.REWRITE,
    text: ORDER_TYPES_TRANSLATE[ORDER_TYPES.REWRITE],
  },
];
