import React from 'react';
import PropTypes from 'prop-types';
import translator from '../../../common/translate';
import BadgeButton from '../../BadgeButton';
import { buttonPropTypes } from '../propTypes';
import PdfHiddenForm from '../../PdfHiddenForm';

const LeftBarButtons = ({
  preliminaryBtnEndpoint,
  printFinalPdfEndpoint,
  onSaveBtnClick,
  preliminaryButton,
  printFinalButton,
  saveButton,
}) => (
  <div className="toolbar-actions-component left-action-bar">
    <BadgeButton
      {...saveButton}
      btnClassName="toolbar-btn save-btn primary large dark-bg"
      id="saveToolbarBtn"
      label={translator.getMessage(saveButton.label)}
      onClick={onSaveBtnClick}
      tooltipAlign="left"
    />

    <BadgeButton
      {...preliminaryButton}
      btnClassName="toolbar-btn print-btn large outlined-white dark-bg"
      hideInfoBadge
      id="preliminaryToolbarBtn"
      infoTooltip="msg_quick_quote_tooltip"
      label={translator.getMessage(preliminaryButton.label)}
      tooltipAlign="left"
      form="preliminaryToolbarBtnForm"
    />
    {preliminaryBtnEndpoint ? <PdfHiddenForm id="preliminaryToolbarBtnForm" endpoint={preliminaryBtnEndpoint} /> : null}

    <BadgeButton
      {...printFinalButton}
      btnClassName="toolbar-btn print-btn large outlined-white dark-bg"
      hideInfoBadge
      id="printFinalBtn"
      infoTooltip="msg_final_quote_tooltip"
      label={translator.getMessage(printFinalButton.label)}
      tooltipAlign="left"
      form="printFinalBtnForm"
    />
    {printFinalPdfEndpoint ? <PdfHiddenForm id="printFinalBtnForm" endpoint={printFinalPdfEndpoint} /> : null}
  </div>
);

LeftBarButtons.propTypes = {
  preliminaryBtnEndpoint: PropTypes.string,
  printFinalPdfEndpoint: PropTypes.string,
  onSaveBtnClick: PropTypes.func.isRequired,
  preliminaryButton: buttonPropTypes.isRequired,
  printFinalButton: buttonPropTypes.isRequired,
  saveButton: buttonPropTypes.isRequired,
};

export default LeftBarButtons;
