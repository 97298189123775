export const ESIG_DOCS = {
  QUOTE_SUMMARY: 'QUOTE_SUMMARY',
  SUBSCRIPTION_AGREEMENT: 'SUBSCRIPTION_AGREEMENT',
  MONTHLY_PAYMENT_OPTION: 'MONTHLY_PAYMENT_OPTION',
  CREDIT_AGREEMENT: 'CREDIT_AGREEMENT',
  CREDIT_APPLICATION: 'CREDIT_APPLICATION',
  EPAY_INSTRUCTIONS: 'EPAY_INSTRUCTIONS',
  LOA_FOR_INFORMATION_SHARING: 'LOA_FOR_INFORMATION_SHARING',
  MARKETING_SLICKS: 'MARKETING_SLICKS',
  CUSTOM_DOCUMENT: 'CUSTOM_DOCUMENT',
  SUBSCRIPTION_AGREEMENT_AMENDMENT: 'SUBSCRIPTION_AGREEMENT_AMENDMENT',
  CREDIT_APPLICATION_AGREEMENT: 'CREDIT_APPLICATION_AGREEMENT',
  PERSONAL_GUARANTEE: 'PERSONAL_GUARANTEE',
};

export const ESIG_DOCS_LIST = Object.keys(ESIG_DOCS);

export const ESIG_DOCS_LABELS = {
  QUOTE_SUMMARY: 'msg_esig_docs_label_quote_summary',
  SUBSCRIPTION_AGREEMENT: 'msg_esig_docs_label_subscription_agreement',
  MONTHLY_PAYMENT_OPTION: 'msg_esig_docs_label_monthly_payment_option',
  CREDIT_AGREEMENT: 'msg_esig_docs_label_credit_agreement',
  CREDIT_APPLICATION: 'msg_esig_docs_label_credit_application',
  EPAY_INSTRUCTIONS: 'msg_esig_docs_label_epay_instructions',
  LOA_FOR_INFORMATION_SHARING: 'msg_esig_docs_label_loa_for_information_sharing',
  MARKETING_SLICKS: 'msg_esig_docs_label_marketing_slicks',
  CUSTOM_DOCUMENT: 'msg_esig_docs_label_custom_document',
  SUBSCRIPTION_AGREEMENT_AMENDMENT: 'msg_esig_docs_label_subscription_agreement_amendment',
  CREDIT_APPLICATION_AGREEMENT: 'msg_credit_application_agreement',
  PERSONAL_GUARANTEE: 'msg_personal_guarantee',
  GREATAMERICA_RENTAL_AGREEMENT: 'msg_great_america_rental_agreement',
  ASSIGNMENT_ADDENDUM: 'msg_assignment_addendum',
  BUSINESS_DOWNTURN_ADDENDUM: 'msg_business_downturn_addendum',
  AUTO_RENEWAL_ADDENDUM: 'msg_auto_renewal_addendum',
  LD_REDUCED_RATE_ADDENDUM: 'msg_ld_reduced_rate_addendum',
  TF_REDUCED_RATE_ADDENDUM: 'msg_tf_reduced_rate_addendum',
  RIGHT_TO_CANCEL_EXTENSION_ADDENDUM: 'msg_right_to_cancel_extension_addendum',
};

export const ESIG_DOCS_RESUBMIT_COMPATIBILITY_MAPPING = {
  [ESIG_DOCS.CREDIT_AGREEMENT]: ESIG_DOCS.CREDIT_APPLICATION_AGREEMENT,
  [ESIG_DOCS.CREDIT_APPLICATION]: ESIG_DOCS.CREDIT_APPLICATION_AGREEMENT,
};
