import { ErrorContext } from '../../Context';
import { ERROR_CONTEXT_TYPES } from '../../common/enums';

function numLocationsExceededErrorBanner() {
  ErrorContext.model.setProps({
    isShown: true,
    details: 'msg_num_tracking_locations_exceeded_error',
    autoHideDelay: 0,
    showCloseButton: true,
    type: ERROR_CONTEXT_TYPES.ERROR,
  });
}

export default numLocationsExceededErrorBanner;
