export const CATEGORY_IDS = {
  phones: {
    id: 10,
    subCategories: {
      phone: 11,
      conferencePhone: 12,
      softwareSoftwareBased: 13,
      expansionHandsets: 14,
    },
  },
  adoptedPhones: {
    id: 15,
    subCategories: {
      adoptedPhone: 15,
    },
  },
  sdWanNonRecurring: {
    id: 30,
    subCategories: {
      sdWanIpBlock: 31,
      sdWanSpeedPackage: 32,
      sdWanRouter: 33,
      sdWanHaHw: 34,
      sdWanWsHw: 35,
    },
  },
  sdWanServicePackages: {
    id: 40,
    subCategories: {
      sdWanPackage: 41,
      sdWanService: 42,
      sdWanHaService: 43,
      sdWanWsService: 44,
    },
  },
  FourGFailoverNonRecurring: {
    id: 50,
    subCategories: {
      FourGPackage: 51,
      FourGRouter: 52,
    },
  },
  FourGLteFailoverService: {
    id: 60,
    subCategories: {
      FourGService: 61,
      FourGDataPackage: 62,
    },
  },
  QuoteLocationIcttps: {
    id: 70,
    subCategories: {
      locationIctppsData: 71,
    },
  },
  appliances: {
    id: 80,
  },
  softwareAndSubscriptions: {
    id: 90,
  },
  ucaasAndTelecom: {
    id: 100,
    subCategories: {
      telecom: 101,
      userLicense: 102,
      telecomLines: 103,
      ucaas: 104,
      minuteBuckets: 105,
      e911: 106,
      users: 107,
      seatService: 108,
      maintenance: 131,
      discount: 141,
    },
  },
  ucaasFeatures: {
    id: 110,
    subCategories: {
      callRouting: 111,
      callRecording: 112,
      softwareAndApplications: 113,
      starBandMrc: 114,
    },
  },
  numbers: {
    id: 120,
    subCategories: {
      number: 121,
    },
  },
  partnerProvidedNonRecurringItems: {
    id: 200,
    subCategories: {
      nrcPartnerLineItem: 201,
    },
  },
  partnerProvidedRecurringItems: {
    id: 300,
    subCategories: {
      rcPartnerLineItem: 301,
    },
  },
  starDaas: {
    id: 400,
    subCategories: {},
  },
  otherNrcPromotionsAndDiscounts: {
    id: 800,
    subCategories: {
      q1Promotion: 801,
      q2Promotion: 802,
      q3Promotion: 803,
      q4Promotion: 804,
      nrcDiscount: 805,
      nrcPromotion: 806,
      otherNrc: 807,
    },
  },
  recurringPromotionsAndDiscounts: {
    id: 900,
    subCategories: {
      q1Promotion: 901,
      q2Promotion: 902,
      q3Promotion: 903,
      q4Promotion: 904,
      rcDiscount: 905,
      rcPromotion: 906,
    },
  },
  ictpps: {
    id: 1000,
    subCategories: {
      installation: 1010,
      configuration: 1020,
      training: 1030,
      portingManagement: 1040,
      provisioning: 1050,
      shipping: 1060,
    },
  },
  starBoxPortExtensionActivation: {
    id: 1100,
    subCategories: {
      starBox: 1110,
      coldStorage: 1120,
      adminUpgrades: 1130,
      portExtensionActivation: 1140,
    },
  },
  starCenter: {
    id: 1200,
    subCategories: {
      starCenter3: 1210,
      starCenter2: 1220,
    },
  },
  demoKit: {
    id: 1300,
    subCategories: {
      demoKit: 1310,
    },
  },
  otherRecurring: {
    id: 1400,
    subCategories: {
      otherRecurring: 1410,
    },
  },
  ataPaging: {
    id: 1500,
    subCategories: {
      ata: 1510,
      pagingSystems: 1520,
    },
  },
  switchesUps: {
    id: 1600,
    subCategories: {
      switches: 1610,
      netgearSwitches: 1620,
      ciscoSwitches: 1630,
      ups: 1640,
    },
  },
  starCenterNrc: {
    id: 1700,
    subCategories: {
      starCenterNrc3: 1710,
      starCenterNrc2: 1720,
      starCenter3Devices: 1730,
    },
  },
  Accessories: {
    id: 1800,
    subCategories: {
      Headset: 1802,
      powerSupply: 1804,
      ethernetcable: 1805,
      starBandNrc: 1807,
      wallMounts: 1808,
      otherAccessories: 1809,
      expansionModule: 1810,
    },
  },
  legacyLineProducts: {
    id: 1900,
    subCategories: {
      legacyLineProducts: 1910,
    },
  },
  starDaasRc: {
    id: 2000,
    subCategories: {
      starDaasUsers: 2010,
      Storage: 2020,
      starDaasApplications: 2030,
      starDaasApplicationUsers: 2040,
    },
  },
  PackagedApplications: {
    id: 2200,
    subCategories: {
      PackagedApplications: 2210,
      SMSPackages: 2220,
    },
  },
  sipStation: {
    id: 2500,
  },
  voipGatewaysAndFaxDevices: {
    id: 2400,
  },
  sangomaCxContactCenter: {
    id: 3100,
  },
};
