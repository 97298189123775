import { ModelAbstract, PartnerCategoryModel, PromotionCategoryModel, CategoryModel } from '../';
import apiData from '../../Storage/apiData';
import { AuthContext } from '../../Context';
import fixFloat from '../../common/helpers/fixFloat';

const PROMOTION_NRC_CATEGORY_ID = 800;
const PROMOTION_RC_CATEGORY_ID = 900;

class CatalogModel extends ModelAbstract {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this._recurring = [];
    this._nonRecurring = [];
  }

  get recurring() {
    return this._recurring;
  }

  get grandTotalRecurring() {
    let total = null;

    for (let i = 0; i < this.recurring.length; i++) {
      total = fixFloat(total) + fixFloat(this.recurring[i].total);
    }

    return fixFloat(total);
  }

  get nonRecurring() {
    return this._nonRecurring;
  }

  get grandTotalNonRecurring() {
    let total = null;

    for (let i = 0; i < this._nonRecurring.length; i++) {
      total = fixFloat(total) + fixFloat(this._nonRecurring[i].total);
    }

    return fixFloat(total);
  }

  get categories() {
    return this._nonRecurring.concat(this._recurring);
  }

  initLineItemsCatalogs() {
    // Build up line items catalog
    for (let i = 0; i < apiData.categories.length; i++) {
      const c = apiData.categories[i];
      let category = null;

      if (apiData.partnerCategoryIds.includes(c.id)) {
        category = new PartnerCategoryModel(this).setProps(c);
      } else if ([PROMOTION_NRC_CATEGORY_ID, PROMOTION_RC_CATEGORY_ID].includes(c.id)) {
        category = new PromotionCategoryModel(this).setProps(c);
      } else {
        category = new CategoryModel(this).setProps(c);
      }

      if (!AuthContext.model.hasSalesOpsPermissions) {
        const index = category.columns.findIndex(d => d.alias === 'overrideValue');

        if (index !== -1) {
          category.columns.splice(index, 1);
        }

        // KM-3224: Override amount should be shown as price for user without override permissions
        // Dev note: LineItemModel::price getter takes overrides into account. So we simply change alias
        const dealerNetIndex = category.columns.findIndex(d => d.alias === 'dealerNet');

        if (dealerNetIndex !== -1) {
          category.columns[dealerNetIndex].alias = 'price';
        }
      }

      if (c.recurring) {
        this._recurring.push(category);
      } else {
        this._nonRecurring.push(category);
      }
    }

    return this;
  }

  _hasError(catalogType) {
    if (!['_nonRecurring', '_recurring'].includes(catalogType)) {
      throw new Error('Incorrect catalog type');
    }

    return this[catalogType].some(categoryModel => categoryModel.hasError);
  }

  get _nonRecurringHasError() {
    return this._hasError('_nonRecurring');
  }

  get _recurringHasError() {
    return this._hasError('_recurring');
  }

  get nonRecurringHasError() {
    return this._getCachedValueOnExport('_nonRecurringHasError');
  }

  get recurringHasError() {
    return this._getCachedValueOnExport('_recurringHasError');
  }
}

export default CatalogModel;
