import { v4 as UUID } from 'uuid';

class ControllerAbstract {
  _uuid = null;
  _view = null;
  _className = null;
  viewMounted = false;

  constructor() {
    this._uuid = UUID();
    this._className = Object.getPrototypeOf(this).constructor.name;

    this.interfaces = {};
  }

  setView(instance) {
    this._view = instance;
  }

  get uuid() {
    return this._uuid;
  }

  get props() {
    return {};
  }

  get className() {
    return this._className;
  }

  renderView(props = this.props, callback) {
    if (this._view && this.viewMounted) {
      if (typeof callback === 'function') {
        this._view.setState(props, callback);
      } else {
        this._view.setState(props);
      }
    }
  }

  modelChanged(model) {
    this.renderView();
  }
}

export default ControllerAbstract;
