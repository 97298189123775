export const SWITCHVOX_ON_PREM_TERM_YEARS = {
  ONE_YEAR: 1,
  TWO_YEARS: 2,
  THREE_YEARS: 3,
  FIVE_YEARS: 5,
};

export const SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS = [
  {
    key: 'switchvoxTermYears-1',
    value: SWITCHVOX_ON_PREM_TERM_YEARS.ONE_YEAR,
    text: 'msg_one_year',
  },
  {
    key: 'switchvoxTermYears-2',
    value: SWITCHVOX_ON_PREM_TERM_YEARS.TWO_YEARS,
    text: 'msg_two_years',
  },
  {
    key: 'switchvoxTermYears-3',
    value: SWITCHVOX_ON_PREM_TERM_YEARS.THREE_YEARS,
    text: 'msg_three_years',
  },
  {
    key: 'switchvoxTermYears-5',
    value: SWITCHVOX_ON_PREM_TERM_YEARS.FIVE_YEARS,
    text: 'msg_five_years',
  },
];
