// To be noted. This list is and should in future be consistent with BE values.
// That means it should contain the same set of statuses that are available on BE side.
export const QUOTE_STATUS = {
  OPEN: 'OPEN',
  SUBMITTING: 'SUBMITTING',
  SUBMITTED: 'SUBMITTED',
  FINALIZED: 'FINALIZED',
  UNFINALIZED: 'UNFINALIZED',
  CLOSED: 'CLOSED',
  SE_PENDING_APPROVAL: 'SE_PENDING_APPROVAL',
  SE_APPROVED: 'SE_APPROVED',
  SENT_FOR_SIGNATURE: 'SENT_FOR_SIGNATURE',
  SIGNED_AND_FINALIZED: 'SIGNED_AND_FINALIZED',
  AMENDING: 'AMENDING',
};

export const QUOTE_STATUS_TRANSLATE_CODES = {
  [QUOTE_STATUS.OPEN]: 'msg_quote_status_open',
  [QUOTE_STATUS.SUBMITTING]: 'msg_quote_status_submitting',
  [QUOTE_STATUS.SUBMITTED]: 'msg_quote_status_submitted',
  [QUOTE_STATUS.FINALIZED]: 'msg_quote_status_finalized',
  [QUOTE_STATUS.UNFINALIZED]: 'msg_quote_status_unfinalized',
  [QUOTE_STATUS.CLOSED]: 'msg_quote_status_closed',
  [QUOTE_STATUS.SE_PENDING_APPROVAL]: 'msg_quote_status_se_pending_approval',
  [QUOTE_STATUS.SE_APPROVED]: 'msg_quote_status_se_approved',
  [QUOTE_STATUS.SENT_FOR_SIGNATURE]: 'msg_quote_status_sent_for_signature',
  [QUOTE_STATUS.SIGNED_AND_FINALIZED]: 'msg_quote_status_signed_and_finalized',
  [QUOTE_STATUS.AMENDING]: 'msg_quote_status_amending',
};

export const QUOTE_STATUS_LIST = Object.keys(QUOTE_STATUS);

export const QUOTE_STATUS_OPTIONS = [
  {
    key: QUOTE_STATUS.OPEN,
    value: QUOTE_STATUS.OPEN,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.OPEN],
  },
  {
    key: QUOTE_STATUS.UNFINALIZED,
    value: QUOTE_STATUS.UNFINALIZED,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.UNFINALIZED],
  },
  {
    key: QUOTE_STATUS.SENT_FOR_SIGNATURE,
    value: QUOTE_STATUS.SENT_FOR_SIGNATURE,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.SENT_FOR_SIGNATURE],
  },
  {
    key: QUOTE_STATUS.FINALIZED,
    value: QUOTE_STATUS.FINALIZED,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.FINALIZED],
  },
  {
    key: QUOTE_STATUS.SIGNED_AND_FINALIZED,
    value: QUOTE_STATUS.SIGNED_AND_FINALIZED,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.SIGNED_AND_FINALIZED],
  },
  {
    key: QUOTE_STATUS.AMENDING,
    value: QUOTE_STATUS.AMENDING,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.AMENDING],
  },
  {
    key: QUOTE_STATUS.SE_PENDING_APPROVAL,
    value: QUOTE_STATUS.SE_PENDING_APPROVAL,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.SE_PENDING_APPROVAL],
  },
  {
    key: QUOTE_STATUS.SE_APPROVED,
    value: QUOTE_STATUS.SE_APPROVED,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.SE_APPROVED],
  },
  {
    key: QUOTE_STATUS.CLOSED,
    value: QUOTE_STATUS.CLOSED,
    text: QUOTE_STATUS_TRANSLATE_CODES[QUOTE_STATUS.CLOSED],
  },
];
