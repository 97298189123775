import React from 'react';
import PropTypes from 'prop-types';
import { v4 as UUID } from 'uuid';
import * as formatter from '../../../common/helpers/format';
import PureViewComponent from '../../PureViewComponent';
import '../css/category-totals.css';
import { getTranslateString } from '../../../common/helpers/getTranslateString';
import { injectIntl } from 'react-intl';

class CategoryTotals extends PureViewComponent {
  static propTypes = {
    showMarkup: PropTypes.bool,
    markupTotal: PropTypes.number,
    total: PropTypes.number,
  };

  static defaultProps = {
    showMarkup: true,
  };

  render() {
    const { total, showMarkup, markupTotal, intl } = this.props;

    return (
      <div className="li-category-totals">
        {showMarkup && (
          <div key={UUID()} className="value">
            <label className="label" title={getTranslateString('msg_markup_total', intl)}>
              {formatter.currency(markupTotal)}
            </label>
          </div>
        )}
        <div className="value">
          <label title={getTranslateString('msg_total', intl)}>{formatter.currency(total)}</label>
        </div>
      </div>
    );
  }
}

export default injectIntl(CategoryTotals);
