import { QUOTE_STATUS, UI_QUOTE_STATUS } from '../../common/enums';
import { AuthContext } from '../../Context';
import config from '../../config';
import ModelAbstract from '../ModelAbstract';

class CommonConditionsModel extends ModelAbstract {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this._ignoreOnExport.push('appState', 'masterOrder', 'appErrors');
  }

  get appState() {
    return this.controller.appState;
  }

  get masterOrder() {
    return this.appState.masterOrder;
  }

  get appErrors() {
    return this.appState.errors;
  }

  get printFlow() {
    return !this.masterOrder.useESigDocumentsFlow;
  }

  get isESigFlow() {
    return this.masterOrder.useESigDocumentsFlow && config.eSignatureEnabled;
  }

  get isFinalizedMO() {
    return this.masterOrder.quoteStatus === QUOTE_STATUS.FINALIZED;
  }

  get isNotFinalizedQuoteHasExeptions() {
    const { quoteStatus } = this.masterOrder;

    return (
      // Quote can be without sales exceptions but in 'SE Pending Approval' status
      (this.hasSalesExceptionsForNotSeApproved || quoteStatus === QUOTE_STATUS.SE_PENDING_APPROVAL) &&
      quoteStatus !== QUOTE_STATUS.FINALIZED &&
      quoteStatus !== QUOTE_STATUS.SIGNED_AND_FINALIZED
    );
  }

  get isQuoteSubmittedAndAbleToUpdate() {
    const { allLocationsAreShipped, amendButtonEnabled, quoteStatus } = this.masterOrder;

    return (
      (quoteStatus === QUOTE_STATUS.SIGNED_AND_FINALIZED && !allLocationsAreShipped) ||
      (quoteStatus === QUOTE_STATUS.SENT_FOR_SIGNATURE && amendButtonEnabled)
    );
  }

  get isESignatureFlowAllowed() {
    return this.appState.isSignatureFlowAllowed && this.isESigFlow;
  }

  get isNotQuoteLoading() {
    return !this.appState.quoteLoading;
  }

  get isQuoteAmendingByInternalUser() {
    return this.masterOrder.quoteStatus === QUOTE_STATUS.AMENDING && AuthContext.model.hasInternalPermissions;
  }

  get canSendForSignature() {
    const { quoteStatus } = this.appState;
    const { sendForSignatureAllowed } = this.masterOrder;

    return quoteStatus !== UI_QUOTE_STATUS.LOADING && sendForSignatureAllowed;
  }

  get areLocationsDone() {
    const { allLocationsAreFinalized, allLocationsAreShipped } = this.masterOrder;
    return this.isFinalizedMO && (allLocationsAreShipped || allLocationsAreFinalized);
  }

  get hasSalesExceptionsForNotSeApproved() {
    return (
      this.appErrors.salesExceptionsRules.length > 0 && this.masterOrder.quoteStatusLabel !== QUOTE_STATUS.SE_APPROVED
    );
  }
}

export default CommonConditionsModel;
