import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../css/autocomplete-options-list.css';

class OptionItem extends PureComponent {
  static propTypes = {
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    error: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  static defaultProps = {
    text: '',
    value: 0,
  };

  render() {
    const { text, onClick, selected, error, value } = this.props;

    const optionClassName = classnames({
      'autocomplete-option': true,
      'selected-option': selected,
      error: error > 0,
    });

    return (
      <div
        tabIndex="0"
        title={text}
        className={optionClassName}
        onClick={onClick}
        data-value={value}
        data-text={text}
        data-type={typeof value}
      >
        {text}
      </div>
    );
  }
}

class AutocompleteOptionsList extends PureComponent {
  static propTypes = {
    onSelectOption: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    optionsList: PropTypes.arrayOf(PropTypes.object),
    optionsListTitle: PropTypes.node,
    optionsListClassName: PropTypes.node,
    optionsCount: PropTypes.number,
    renderMoreOptions: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  dropDownElem = null;

  constructor(props) {
    super(props);

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.getOptions = this.getOptions.bind(this);
  }

  componentDidMount() {
    if (this.props.isOpen) {
      let parentPosition = this.dropDownElem.parentNode.getBoundingClientRect();
      let listHeight = this.dropDownElem.offsetHeight;
      let toBottom = window.innerHeight - parentPosition.bottom;
      // 130px it's a height of the toolbar (fixed top)
      let toTop = parentPosition.top - 130;

      if (toBottom < listHeight && parentPosition.top > toBottom && toTop > listHeight) {
        this.dropDownElem.style.top = '';
        this.dropDownElem.style.bottom = `${parentPosition.height + 8}px`;
      } else {
        this.dropDownElem.style.top = 'calc(100% + 8px)';
        this.dropDownElem.style.bottom = '';
      }
    }

    this.dropDownElem.addEventListener('scroll', this.handleScroll, true);
  }

  componentWillUnmount() {
    this.dropDownElem.removeEventListener('scroll', this.handleOnFocusout);
  }

  getOptions() {
    const { id, value, onSelectOption, optionsList, optionsCount } = this.props;
    const length = Math.min(optionsList.length, optionsCount);
    const options = [];

    for (let i = 0; i < length; i++) {
      const opt = optionsList[i];

      options.push(
        <OptionItem key={`${id}-option-${i}`} {...opt} selected={value === opt.value} onClick={onSelectOption} />
      );
    }

    return options;
  }

  render() {
    const { isOpen, fullWidth, optionsListTitle, optionsListClassName } = this.props;

    const containerClassName = classnames({
      'options-list-container': true,
      'full-width-option-list': fullWidth,
    });

    if (isOpen) {
      return (
        <div className={containerClassName} onKeyDown={this.handleKeyDown} ref={elem => (this.dropDownElem = elem)}>
          {optionsListTitle && <div className="option-list-title">{optionsListTitle}</div>}
          <div className={optionsListClassName}>{this.getOptions()}</div>
        </div>
      );
    } else {
      return '';
    }
  }

  handleKeyDown(event) {
    const key = event.key;
    const element = event.target;

    switch (key) {
      case 'ArrowDown':
        element.nextElementSibling
          ? element.nextElementSibling.focus()
          : this.dropDownElem.querySelector('.autocomplete-option-list').firstChild.focus();
        event.preventDefault();
        break;
      case 'ArrowUp':
        element.previousElementSibling
          ? element.previousElementSibling.focus()
          : this.dropDownElem.querySelector('.autocomplete-option-list').lastChild.focus();
        event.preventDefault();
        break;
      case 'Enter':
        if (typeof this.props.onSelectOption === 'function') {
          this.props.onSelectOption(event);
        }
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleScroll(event) {
    const opts = event.target;
    const lastOpt = opts.lastElementChild;

    if (
      lastOpt.offsetTop - (opts.scrollTop + opts.offsetHeight) < 0 &&
      opts.childElementCount !== this.props.optionsList.length
    ) {
      this.props.renderMoreOptions();
    }
  }
}

export default injectIntl(AutocompleteOptionsList);
