import { QUOTE_STATUS } from './enums/quoteStatusEnums';
import { CALC_RESULT_PACKAGED, RESULT_TYPES } from '../Models/CalcResultsModel';
import { CONTACT_PROPS } from '../components/CustomerContactInfo/ContactSectionProps';
import { LEASE_OPTIONS, ORDER_TYPES } from './enums';

const REQUIRED_FOR_PRINT = {
  isSpiffConfirmationValid: false,
};

export const checkRequiredFieldsForPrint = (quote, seRules, rollUpDisabled = true) => {
  let missingFields = [];
  let separateLineFields = [];
  let seRulesMessage = '';

  Object.keys(REQUIRED_FOR_PRINT).forEach(el => {
    // Get Spiff / Discount Confirmation
    if (!quote[el] && el === 'isSpiffConfirmationValid') {
      missingFields.push({
        fieldName: el,
        fieldLabel: '@msg_customer_discount_spiff_confirmation@',
      });
    }
  });

  if (!rollUpDisabled) {
    missingFields.push({
      fieldLabel: '@msg_location_breakouts_dont_match_the_order_totals@',
    });
  }

  if (quote.quoteStatus === QUOTE_STATUS.SE_PENDING_APPROVAL) {
    missingFields.push({
      fieldLabel: '@msg_print_button_se_approval@',
    });
  }

  if (quote.calcResults.getValue('ipBlockRequiresSdWanWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_ip_block_requires_sd_wan@',
    });
  } else if (quote.calcResults.getValue('sdWanRequiresIpBlockWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_sd_wan_requires_ip_block@',
    });
  }

  const message = missingFields.length ? missingFields.map(m => m.fieldLabel).join('\n') : '';

  if (
    !quote.useESigDocumentsFlow &&
    quote.calcResults.getValue('estimatedLeasePayment', RESULT_TYPES.float, CALC_RESULT_PACKAGED) <= 0 &&
    quote.leaseOption !== LEASE_OPTIONS.NONE
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_lease_options_not_eligible@',
    });

    missingFields.push({
      fieldLabel: '@msg_lease_options_not_eligible@',
    });
  }

  if (
    quote.expired &&
    [QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.OPEN].includes(quote.quoteStatusLabel) &&
    [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(quote.orderType)
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_expired_warning@',
    });

    // push to missingFields to add badge count
    // TODO refactor how we counting errors in badge
    missingFields.push({
      fieldLabel: '@msg_expired_warning@',
    });
  }

  if (
    missingFields.length === 0 &&
    seRules.length > 0 &&
    ![
      QUOTE_STATUS.SE_APPROVED,
      QUOTE_STATUS.FINALIZED,
      QUOTE_STATUS.SENT_FOR_SIGNATURE,
      QUOTE_STATUS.SIGNED_AND_FINALIZED,
    ].includes(quote.quoteStatusLabel)
  ) {
    seRulesMessage = seRules.join('\r\n');
    missingFields = seRules.slice();
  }

  return {
    message: message,
    fields: missingFields,
    seRulesMessage: seRulesMessage,
    separateLineFields,
  };
};

export const checkRequiredFieldsForFinalPrint = (quote, REQUIRED_FOR_FINALIZE, seRules, rollUpDisabled = true) => {
  let missingFields = [];
  let separateLineFields = [];
  let seRulesMessage = '';

  Object.keys(REQUIRED_FOR_PRINT).forEach(el => {
    // Get Spiff / Discount Confirmation
    if (!quote[el] && el === 'isSpiffConfirmationValid') {
      missingFields.push({
        fieldName: el,
        fieldLabel: '@msg_customer_discount_spiff_confirmation@',
      });
    }
  });

  if (quote.quoteStatus === QUOTE_STATUS.SE_PENDING_APPROVAL) {
    missingFields.push({
      fieldLabel: '@msg_print_button_se_approval@',
    });
  }

  if (!rollUpDisabled) {
    missingFields.push({
      fieldLabel: '@msg_location_breakouts_dont_match_the_order_totals@',
    });
  }

  for (let el in REQUIRED_FOR_FINALIZE) {
    if (REQUIRED_FOR_FINALIZE.hasOwnProperty(el) && typeof REQUIRED_FOR_FINALIZE[el] === 'object') {
      const requiredFields = Object.keys(REQUIRED_FOR_FINALIZE[el]);
      const missing = requiredFields.filter(subEl => !quote[el][subEl]);

      if (missing.length && el === 'serviceInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_service_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_service_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      }

      if (missing.length && el === 'billingInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_billing_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_billing_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      }
    }
    // Get Ictp dates
    else if (!quote[el] && el === 'dateShippingRequested') {
      missingFields.push({
        fieldName: el,
        fieldLabel: '@msg_ictp_ship_install_dates@',
      });
    }
  }

  if (quote.calcResults.getValue('ipBlockRequiresSdWanWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_ip_block_requires_sd_wan@',
    });
  } else if (quote.calcResults.getValue('sdWanRequiresIpBlockWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_sd_wan_requires_ip_block@',
    });
  }

  const message = missingFields.length ? missingFields.map(m => m.fieldLabel).join('\n') : '';

  if (
    !quote.useESigDocumentsFlow &&
    quote.calcResults.getValue('estimatedLeasePayment', RESULT_TYPES.float, CALC_RESULT_PACKAGED) <= 0 &&
    quote.leaseOption !== LEASE_OPTIONS.NONE
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_lease_options_not_eligible@',
    });

    missingFields.push({
      fieldLabel: '@msg_lease_options_not_eligible@',
    });
  }

  if (
    quote.expired &&
    [QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.OPEN].includes(quote.quoteStatusLabel) &&
    [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(quote.orderType)
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_expired_warning@',
    });

    // push to missingFields to add badge count
    // TODO refactor how we counting errors in badge
    missingFields.push({
      fieldLabel: '@msg_expired_warning@',
    });
  }

  if (
    missingFields.length === 0 &&
    seRules.length > 0 &&
    ![
      QUOTE_STATUS.SE_APPROVED,
      QUOTE_STATUS.FINALIZED,
      QUOTE_STATUS.SENT_FOR_SIGNATURE,
      QUOTE_STATUS.SIGNED_AND_FINALIZED,
    ].includes(quote.quoteStatusLabel)
  ) {
    seRulesMessage = seRules.join('\r\n');
    missingFields = seRules.slice();
  }

  return {
    message: message,
    fields: missingFields,
    seRulesMessage: seRulesMessage,
    separateLineFields,
  };
};
