import React from 'react';
import { func } from 'prop-types';
import translator from '../../../common/translate';
import BadgeButton from '../../BadgeButton';
import { buttonPropTypes } from '../propTypes';

const RightBarButtons = ({
  amendmentButton,
  approvalButton,
  cancelAmendmentButton,
  finalizeButton,
  finalizedButton,
  onAmendBtnClick,
  onApprovalBtnClick,
  onCancelAmendmentBtnClick,
  onFinalizeBtnClick,
  onFinalizedBtnClick,
  onSendForSignatureBtnClick,
  onSignaturesBtnClick,
  onSlexToolbarBtnClick,
  sendForSignatureButton,
  signaturesButton,
  slexButton,
}) => (
  <div className="toolbar-actions-component right-action-bar">
    <BadgeButton
      {...slexButton}
      btnClassName="toolbar-btn slex-btn large outlined-white dark-bg"
      id="slexToolbarBtn"
      label={translator.getMessage(slexButton.label)}
      onClick={onSlexToolbarBtnClick}
      tooltipAlign="right"
    />
    <BadgeButton
      {...signaturesButton}
      btnClassName="toolbar-btn signatures-btn large outlined-white dark-bg"
      id="signaturesBtn"
      label={translator.getMessage(signaturesButton.label)}
      onClick={onSignaturesBtnClick}
      tooltipAlign="right"
    />

    <BadgeButton
      {...amendmentButton}
      btnClassName="toolbar-btn amend-btn primary large dark-bg"
      id="amendToolbarBtn"
      label={translator.getMessage(amendmentButton.label)}
      onClick={onAmendBtnClick}
      tooltipAlign="right"
    />
    <BadgeButton
      {...sendForSignatureButton}
      blueErrorWarning
      btnClassName="toolbar-btn send-for-signature large primary dark-bg"
      id="sendForSignature"
      label={translator.getMessage(sendForSignatureButton.label)}
      onClick={onSendForSignatureBtnClick}
      tooltipAlign="right"
    />
    <BadgeButton
      {...finalizedButton}
      btnClassName="toolbar-btn finalize-btn critical large dark-bg"
      hideInfoBadge
      id="finalizedToolbarBtn"
      label={translator.getMessage(finalizedButton.label)}
      onClick={onFinalizedBtnClick}
      tooltipAlign="right"
    />

    <BadgeButton
      {...approvalButton}
      blueErrorWarning
      btnClassName="toolbar-btn submit-for-approval primary large dark-bg"
      id="submitForApprovalToolbarBtn"
      label={translator.getMessage(approvalButton.label)}
      onClick={onApprovalBtnClick}
      tooltipAlign="right"
    />
    <BadgeButton
      {...finalizeButton}
      hideInfoBadge
      id="finalizeToolbarBtn"
      label={translator.getMessage(finalizeButton.label)}
      onClick={onFinalizeBtnClick}
      tooltipAlign="right"
    />
    <BadgeButton
      {...cancelAmendmentButton}
      btnClassName="toolbar-btn cancel-amendment-btn critical large dark-bg"
      id="cancelAmendmentToolbarBtn"
      label={translator.getMessage(cancelAmendmentButton.label)}
      onClick={onCancelAmendmentBtnClick}
      tooltipAlign="right"
    />
  </div>
);

RightBarButtons.propTypes = {
  amendmentButton: buttonPropTypes.isRequired,
  approvalButton: buttonPropTypes.isRequired,
  cancelAmendmentButton: buttonPropTypes.isRequired,
  finalizeButton: buttonPropTypes.isRequired,
  finalizedButton: buttonPropTypes.isRequired,
  onAmendBtnClick: func.isRequired,
  onApprovalBtnClick: func.isRequired,
  onCancelAmendmentBtnClick: func.isRequired,
  onFinalizeBtnClick: func.isRequired,
  onFinalizedBtnClick: func.isRequired,
  onSendForSignatureBtnClick: func.isRequired,
  onSignaturesBtnClick: func.isRequired,
  onSlexToolbarBtnClick: func.isRequired,
  sendForSignatureButton: buttonPropTypes.isRequired,
  signaturesButton: buttonPropTypes.isRequired,
  slexButton: buttonPropTypes.isRequired,
};

export default RightBarButtons;
