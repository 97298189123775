const constants = {
  ICTP_CATEGORY_ID: 1000,
  ICTP_INSTALLATION_ID: 1010,
  STAR_BOX_CATEGORY_ID: 1100,
  STAR_BOX_SUB_CATEGORY_ID: 1110,
  NRC_4G_CATEGORY_ID: 50,
  NRC_SD_WAN_CATEGORY_ID: 30,
  ATA_CATEGORY_ID: 1500,
  SB_RELIANT_PRODUCT_IDS: [90210, 90219, 90226],
  MAINTENANCE_CATEGORY_ID: 2300,
};

export default constants;
