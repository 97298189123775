export const TAX_EXEMPT_OPTIONS = [
  {
    key: '1',
    value: true,
    text: 'msg_yes',
  },
  {
    key: '2',
    value: false,
    text: 'msg_no',
  },
];
