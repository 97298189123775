import { SCREEN_SIZE, DEFAULT_PAGE_RANGE } from '../constants';

export default function getPageRange(wrapperRef) {
  if (!wrapperRef.current) {
    return DEFAULT_PAGE_RANGE;
  }

  const { width } = wrapperRef.current.getBoundingClientRect();

  switch (true) {
    case width > SCREEN_SIZE.medium:
      return 15;
    case width > SCREEN_SIZE.small:
      return 10;
    default:
      return DEFAULT_PAGE_RANGE;
  }
}
