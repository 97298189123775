import { CustomerOrderModel, ModelAbstract } from '../index';

class CommonDownloadButtonModel extends ModelAbstract {
  constructor(customerOrderInstance) {
    if (!(customerOrderInstance instanceof CustomerOrderModel)) {
      throw new Error('CommonDownloadButtonModel only accept CustomerOrderModel as higher order instance');
    }

    super(customerOrderInstance);

    this._ignoreOnExport.push('masterOrder');
  }

  /**
   * @returns {CustomerOrderModel}
   */
  get masterOrder() {
    return this._sibling;
  }
}

export default CommonDownloadButtonModel;
