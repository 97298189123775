import React from 'react';
import classNames from 'classnames';
import { NumberControl } from '../../Controls';

const RIGHT_ALIGNED_CELLS = ['id', 'cost', 'usagecap'];
const CELL_VALUES_BY_TYPE = {
  id: 'markerType',
  description: 'description',
  cost: 'costField',
  usagecap: 'usagecapField',
};
const MIN_VALUE_BY_TYPE = {
  cost: 0,
  usagecap: -9999999999,
};

const Cell = ({ data, cellType }) => {
  const value = data[CELL_VALUES_BY_TYPE[cellType]];
  const cellClassNames = classNames({
    [cellType]: true,
    'align-right': RIGHT_ALIGNED_CELLS.includes(cellType),
    'auto-width-nowrap': true,
  });
  const onChangeHandler = React.useCallback(
    value => {
      data.onChange(CELL_VALUES_BY_TYPE[cellType], value);
    },
    [cellType, data]
  );

  return (
    <td className={cellClassNames} id={data.markerType + '-' + cellType} key={data.markerType + '-' + cellType}>
      {data.editableFields.includes(cellType) ? (
        <NumberControl
          id={data.markerType + '-' + cellType + '-NumberControl'}
          onChange={onChangeHandler}
          value={Number(value)}
          min={MIN_VALUE_BY_TYPE[cellType]}
          max={9999999999}
          ariaLabel={data.markerType + '-' + cellType}
          allowNegativeValue
          allowBlankField
        />
      ) : (
        <span>{value}</span>
      )}
    </td>
  );
};

export default Cell;
