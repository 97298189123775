export const CELL_TYPES = {
  label: 'label',
  text: 'ProposalTextInput',
  number: 'NumberInput',
  amount: 'CashInput',
  textForceDisabled: 'ProposalTextInputForceDisabled',
  numberForceDisabled: 'NumberInputForceDisabled',
  amountForceDisabled: 'CashInputForceDisabled',
  empty: 'empty',
};

export const CELL_TYPES_ARRAY = Object.keys(CELL_TYPES).map(key => CELL_TYPES[key]);
