import { CustomerOrderModel, ModelAbstract } from './index';
import bindMultiple from '../common/helpers/bindMultiple';
import fixFloat from '../common/helpers/fixFloat';

const OVERRIDDEN_PROPS_BY_FIELD_NAME = {
  description: '_descriptionOverride',
  cost: '_costOverride',
  usagecap: '_usagecapOverride',
};

class MarkerModel extends ModelAbstract {
  constructor(masterOrder) {
    if (!(masterOrder instanceof CustomerOrderModel)) {
      throw new Error("MarkerModel only accept CustomerOrderModel instance as it's parent sibling.");
    }

    super(masterOrder);

    this.markerType = 0;
    this.markerSubType = 0;
    this.description = '';
    this.editableFields = [];

    this._descriptionOverride = null;
    this._costOverride = null;
    this._usagecapOverride = null;
    this._cost = 0;
    this._usagecap = 0;

    bindMultiple(this, this.onChange);
  }

  onChange(prop, value) {
    this[prop] = value;
    this.modelChanged(this);
  }

  get cost() {
    return this._cost;
  }

  set cost(v) {
    this._cost = fixFloat(v);
  }

  get usagecap() {
    return this._usagecap;
  }

  set usagecap(v) {
    this._usagecap = parseInt(v);
  }

  get descriptionField() {
    return this._descriptionOverride !== null ? this._descriptionOverride : this.description;
  }

  set descriptionField(v) {
    this._descriptionOverride = v;
  }

  get costField() {
    return this._costOverride !== null ? this._costOverride : this._cost;
  }

  set costField(v) {
    this._costOverride = v;
  }

  get usagecapField() {
    return this._usagecapOverride !== null ? this._usagecapOverride : this._usagecap;
  }

  set usagecapField(v) {
    this._usagecapOverride = v;
  }

  buildSaveOverridesRequest() {
    return this.editableFields.reduce((all, current) => {
      const overriddenPropertyValue = this[OVERRIDDEN_PROPS_BY_FIELD_NAME[current]];

      if (overriddenPropertyValue !== null) {
        all.push({
          fieldName: current,
          markerSubType: this.markerSubType,
          markerType: this.markerType,
          value: overriddenPropertyValue,
        });
      }

      return all;
    }, []);
  }
}

export default MarkerModel;
