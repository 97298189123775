import { ModelAbstract, QuoteModel, QuotesCollectionModel } from '../';
import { AuthContext, ErrorContext, PartnerContext, PriceBookContext } from '../../Context';
import { RESULT_TYPES } from '../CalcResultsModel';
import { PRICEBOOK_TAGS, USER_ROLES } from '../../common/enums';
import apiData from '../../Storage/apiData';
import fixFloat from '../../common/helpers/fixFloat';

class SpiffDiscountModel extends ModelAbstract {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    this._ignoreOnExport.push('customerOrder');

    this._madTotal = 0;
    this._spiffAmount = 0;
    this._sliderValue = 100;
    this._isConfirmed = false;
    this._madPoolOverride = false;
    this._madRecurringOverride = 0;
    this._madUpfrontOverride = 0;
    this._spiffOverride = 0;
    this._altDescriptionOptions = null;
    this._recurringDiscountAltDescription = null;
    this._nonRecurringDiscountAltDescription = null;
    this._spiffOverrideEnabled = false;
    this._additionalRcDiscountPercent = 0;
    this._additionalUpfrontDiscountPercent = 0;

    this.errorText = '';
    this.madRecurring = 0;
    this.madNonRecurring = 0;
    this.madRecurringMax = 0;
    this.madRemain = 0;
    this.madMonthlyStr = 0;
    this.madMaxUpfrontAllowed3 = 100;
    this.madMaxUpfrontAllowed4 = 100;
    this.sliderLimitValue = 100;
    this.rcApplied = 0;
    this.nrcApplied = 0;
    this.madTotalFundsStandard = 0;
    this.madOverrideResetMetric = 0;
    this.spiffOverrideResetMetric = 0;
    this.additionalRecurringDiscount = 0;
    this.additionalUpfrontDiscount = 0;
    this.calculatedMadRecurAmount = 0;
    this.calculatedMadUpfrontAmount = 0;
    this.disableSangomaPhoneIncentive = false;

    this.onChange = this.onChange.bind(this);
  }

  get nrcAvailable() {
    const { locations } = this.customerOrder;

    if (locations.length === 0) {
      return this.madNonRecurring;
    }

    const appliedNrc = locations.reduce(
      (sum, location) =>
        fixFloat(sum) + location.calcResults.getValue('madUpfrontAmount' + this.packageIdSelected, 'float'),
      0
    );

    return fixFloat(this.madNonRecurring - appliedNrc);
  }

  get rcAvailable() {
    const { locations } = this.customerOrder;

    if (locations.length === 0) {
      return this.madRecurring;
    }

    const appliedRc = locations.reduce(
      (sum, location) =>
        fixFloat(sum) + location.calcResults.getValue('madRecurAmount' + this.packageIdSelected, 'float'),
      0
    );

    return fixFloat(this.madRecurring - appliedRc);
  }

  get spiffAvailable() {
    const { locations } = this.customerOrder;
    const totalSpiff = this.customerOrder.calcResults.getValue('spiffAmount', 'float');

    if (locations.length === 0) {
      return totalSpiff;
    }

    const appliedSpiff = locations.reduce(
      (sum, location) => fixFloat(sum) + location.calcResults.getValue('spiffAmount', 'float'),
      0
    );

    return fixFloat(totalSpiff - appliedSpiff);
  }

  onChange(value, prop) {
    if (this.canSet(prop)) {
      this[prop] = value;

      this.modelChanged(this);
    }
  }

  get customerOrder() {
    /** @type {QuotesCollectionModel} */
    const quotesCollection = this.findSibling(i => i instanceof QuotesCollectionModel);

    return quotesCollection.masterOrder;
  }

  get packageIdSelected() {
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (quotes) {
      return quotes.masterOrder.packageIdSelected;
    }

    return null;
  }

  get madTotal() {
    return this._madTotal;
  }

  set madTotal(value) {
    this._madTotal = value;
  }

  get spiffAmount() {
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (quotes) {
      /** @type {CustomerOrderModel} */
      const masterOrder = quotes.masterOrder;

      // Partner Target SPIFF input available
      if (masterOrder.isPartnerTargetSpiffAvailable) {
        return this._spiffAmount;
      }

      // KM-8206: Partner Target SPIFF no longer available. Use calculated value
      return masterOrder.calcResults.getValue('spiffAmount', RESULT_TYPES.float);
    }

    return this._spiffAmount;
  }

  set spiffAmount(v) {
    this._spiffAmount = v;
  }

  get overrideMadRecurPrcnt() {
    /** @type {CustomerOrderModel} */
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (quote) {
      return quote.calcResults.getValue('overrideMadRecurPrcnt', RESULT_TYPES.float);
    }

    return null;
  }

  get sliderValue() {
    return this._sliderValue;
  }

  set sliderValue(v) {
    // "100 -" - all values for slider are reversed...
    if (100 - v > this.sliderLimitValue && !this.madPoolOverride) {
      this._sliderValue = 100 - this.sliderLimitValue;
    } else {
      this._sliderValue = v;
    }
  }

  updateSliderLimits() {
    /** @type {QuotesCollectionModel} */
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);
    const masterOrder = quotes.masterOrder;
    const dataPoint = 'madMaxUpfrontAllowed' + masterOrder.packageIdSelected;

    const sliderLimitValue = this[dataPoint];

    this.sliderLimitValue = sliderLimitValue > 0 ? sliderLimitValue : 0;

    if (
      !this.customerOrder.isFinanced &&
      this.sliderLimitValue >= 0 &&
      this.sliderLimitValue !== 100 &&
      this.sliderValueWithOverride < 100 - this.sliderLimitValue
    ) {
      this.sliderValue = 100 - this.sliderLimitValue;
      this.modelChanged(this);
    }
  }

  get isConfirmed() {
    return this._isConfirmed;
  }

  set isConfirmed(value) {
    value = Boolean(value);

    // Force disable MAD and SPIFF override if confirmation disabled
    if (this._isConfirmed === true && value === false) {
      this._madPoolOverride = false;
      this._spiffOverrideEnabled = false;
    }

    this._isConfirmed = value;
  }

  get canBypassConfirmation() {
    return this.madPoolOverride && AuthContext.model.hasSalesOpsPermissions;
  }

  get madPoolOverride() {
    return this._madPoolOverride;
  }

  set madPoolOverride(value) {
    value = Boolean(value);

    if (this._madPoolOverride === false && value === true) {
      this._isConfirmed = true;
    }

    this._madPoolOverride = value;
  }

  _parseFloatValueOrSetZero(value) {
    if (typeof value === 'number') {
      return value;
    } else {
      value = parseFloat(value);

      return isNaN(value) ? 0 : value;
    }
  }

  get madRecurringOverride() {
    return this._madRecurringOverride;
  }

  set madRecurringOverride(value) {
    this._madRecurringOverride = this._parseFloatValueOrSetZero(value);
  }

  get madUpfrontOverride() {
    if (this.customerOrder && this.customerOrder.isAllInclusive && this.madPoolOverride) {
      return 0;
    }

    return this._madUpfrontOverride;
  }

  set madUpfrontOverride(value) {
    this._madUpfrontOverride = this._parseFloatValueOrSetZero(value);
  }

  get spiffOverride() {
    return this._spiffOverride;
  }

  set spiffOverride(value) {
    this._spiffOverride = this._parseFloatValueOrSetZero(value);
  }

  get spiffAmountWithOverride() {
    return this.showSpiffOverride ? this.spiffOverride : this.spiffAmount;
  }

  get spiffWarningPooled() {
    /** @type {QuoteModel} */
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (quote) {
      return quote.calcResults.getValue('spiffWarningPooled', RESULT_TYPES.boolean);
    }

    return false;
  }

  get sliderValueWithOverride() {
    return this.madPoolOverride ? 100 - this.overrideMadRecurPrcnt : this._sliderValue;
  }

  get recurringDiscountAltDescription() {
    return this.getAltDescription('recurring', this._recurringDiscountAltDescription);
  }

  set recurringDiscountAltDescription(value) {
    this._recurringDiscountAltDescription = value;

    this.addCustomAltDescriptionIfNeeded('recurring', this._recurringDiscountAltDescription);
  }

  get nonRecurringDiscountAltDescription() {
    return this.getAltDescription('nonRecurring', this._nonRecurringDiscountAltDescription);
  }

  set nonRecurringDiscountAltDescription(value) {
    this._nonRecurringDiscountAltDescription = value;

    this.addCustomAltDescriptionIfNeeded('nonRecurring', this._nonRecurringDiscountAltDescription);
  }

  addCustomAltDescriptionIfNeeded(type, altDescription) {
    if (this.altDescriptionOptions === null || altDescription === null || altDescription === '') {
      return false;
    }

    const optionDefined = this.altDescriptionOptions[type].find(({ text }) => text === altDescription);

    if (optionDefined) {
      return false;
    }

    this._altDescriptionOptions[type].unshift({
      value: altDescription,
      text: altDescription,
    });

    return true;
  }

  getDefaultAltDescription(type) {
    if (this.altDescriptionOptions === null) {
      return '';
    }

    const defaultOption = this.altDescriptionOptions[type].find(d => d.default);

    return defaultOption ? defaultOption.value : '';
  }

  getAltDescription(type, value) {
    return value === null ? this.getDefaultAltDescription(type) : value;
  }

  get altDescriptionOptions() {
    // Options will remain the same for whole quote session
    // So we can cache it in protected property preventing multiple recalculations
    if (apiData.altDescriptionOptions !== null && this._altDescriptionOptions === null) {
      this._altDescriptionOptions = {
        recurring: [...apiData.altDescriptionOptions.recurring],
        nonRecurring: [...apiData.altDescriptionOptions.nonRecurring],
      };
    }

    return this._altDescriptionOptions;
  }

  get isSpiffVisible() {
    if (AuthContext.model.hasSalesOpsPermissions) {
      return true;
    }

    if (PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.NO_MAD_APPLIES_UNLESS_SALESOPS_OVERRIDE)) {
      return Boolean(this.madPoolOverride || this.spiffAmountCalculated || this.madRecurring || this.madNonRecurring);
    }

    return true;
  }

  get isAltDescriptionsDisabled() {
    return (
      PartnerContext.model.isWholeSale &&
      this.isSpiffVisible &&
      AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER
    );
  }

  get spiffOverrideEnabled() {
    if (!this.isSupportsSeparateSpiffOverride) {
      return false;
    }

    return this._spiffOverrideEnabled;
  }

  set spiffOverrideEnabled(value) {
    if (this._spiffOverrideEnabled === false && value === true) {
      this.isConfirmed = true;
    }
    this._spiffOverrideEnabled = value;
  }

  get isSupportsSeparateSpiffOverride() {
    return PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SEPARATE_MAD_SPIFF_OVERRIDE);
  }

  get showSpiffOverride() {
    return Boolean(
      (this.isSupportsSeparateSpiffOverride && this.spiffOverrideEnabled) ||
        (!this.isSupportsSeparateSpiffOverride && this.madPoolOverride)
    );
  }

  get additionalRcDiscountPercent() {
    if (this.madPoolOverride || this.spiffOverrideEnabled) {
      return 0;
    }

    return this._additionalRcDiscountPercent;
  }

  set additionalRcDiscountPercent(value) {
    this._additionalRcDiscountPercent = value;
  }

  get additionalUpfrontDiscountPercent() {
    if (this.madPoolOverride || this.spiffOverrideEnabled || this.customerOrder.isFinanced) {
      return 0;
    }

    return this._additionalUpfrontDiscountPercent;
  }

  set additionalUpfrontDiscountPercent(value) {
    this._additionalUpfrontDiscountPercent = value;
  }

  showAdditionalDiscountRemovedBanner() {
    ErrorContext.model.setProps({
      isShown: true,
      message: 'msg_additional_discount_removed',
      details: 'msg_additional_discount_removed_details',
      autoHideDelay: 0,
    });
  }

  get sangomaPhoneIncentiveTotal() {
    return this.customerOrder.calcResults.getValue('checkpoint623', RESULT_TYPES.float);
  }

  get sangomaPhoneIncentiveAvailable() {
    const { locations } = this.customerOrder;

    if (locations.length === 0) {
      return this.sangomaPhoneIncentiveTotal;
    }

    let applied = 0;

    for (let i = 0; i < locations.length; i++) {
      /** @type {LocationQuoteModel} */
      const location = locations[i];

      applied += location.calcResults.getValue('checkpoint623', RESULT_TYPES.float);
    }

    return fixFloat(this.sangomaPhoneIncentiveTotal - applied);
  }

  get showDisableSangomaIncentiveToggle() {
    return Boolean(
      AuthContext.model.hasSalesOpsPermissions &&
        PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_DISABLE_SANGOMA_PHONE_INCENTIVE)
    );
  }
}

export default SpiffDiscountModel;
