import {
  AutocompleteControl,
  AmountCheckboxControl,
  AmountControl,
  CheckboxControl,
  DatePickerControl,
  LabelField,
  MaskControl,
  NumberControl,
  RadioButtonControl,
  SelectControl,
  TextControl,
  ToggleControl,
  ZipControl,
} from '../../Controls';

const CONTROL_COMPONENT_CLASS_TABLE = {
  amount: AmountControl,
  autocomplete: AutocompleteControl,
  checkAmount: AmountCheckboxControl,
  checkbox: CheckboxControl,
  date: DatePickerControl,
  label: LabelField,
  mask: MaskControl,
  number: NumberControl,
  radio: RadioButtonControl,
  select: SelectControl,
  text: TextControl,
  toggle: ToggleControl,
  zip: ZipControl,
};

export default function getControlComponentClass(type) {
  return CONTROL_COMPONENT_CLASS_TABLE[type] || TextControl;
}
