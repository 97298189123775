import { ModelAbstract, ProviderModel, QuoteModel } from '../';
import { AuthContext, PartnerContext, PriceBookContext } from '../../Context';
import { constants, ORDER_TYPES, PRICEBOOK_TAGS } from '../../common/enums';
import fixFloat from '../../common/helpers/fixFloat';
import bindMultiple from '../../common/helpers/bindMultiple';

const PRO_SERVICES_FEE_SUB_CATEGORY_ID = 1070;

class ProvidersGroupModel extends ModelAbstract {
  constructor(instance) {
    super(instance);

    this._isAgent = AuthContext.model.isAgent;
    this.inited = false;
    this.providers = [];
    this.mixed = false;
    this.mixedDealerNet = {
      1010: 0,
      1020: 0,
      1030: 0,
      1040: 0,
      1050: 0,
      1060: 0,
    };
    this._isConfirmed = false;

    bindMultiple(
      this,
      this.__getIctpLineItems,
      this.isAllPricesAllocated,
      this.isProServicesExists,
      this.onChange,
      this.priceIsExceeded
    );
  }

  __getIctpLineItems() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    return quote.lineItems.items.filter(d => d.lineItemCategoryId === constants.ICTP_CATEGORY_ID);
  }

  get grandTotal() {
    let total = null;

    for (let i = 0; i < this.providers.length; i++) {
      const selectedProvider = this.providers[i].selectedOption;

      total += selectedProvider.price;
    }

    return total;
  }

  get showOverrideColumn() {
    return AuthContext.model.hasSalesOpsPermissions;
  }

  // TODO: It seems like the whole init logic may be done as one-time cached getter(s).
  //       If done so there will be no need to call init() method directly in many places.
  init() {
    if (this.inited) {
      return false;
    }

    const quote = this.findSibling(d => d instanceof QuoteModel);
    const isQuoteRetrieval = quote.id !== null;

    this.providers = this.__getIctpLineItems().reduce((res, li) => {
      let providerIndex = res.findIndex(p => li.lineItemSubCategoryId === p.subCategoryId);

      // KM-4622 Partner price input defaults to blank field
      if (
        quote.orderType === ORDER_TYPES.ADD_ON &&
        li.isPartner &&
        !isQuoteRetrieval &&
        // KM-9133: Business Continuity pricebook add-ons should not have blank values
        !PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.BUSINESS_CONTINUITY)
      ) {
        // TODO: Should it be changed to null considering all other empty amount inputs has nulls (ex. overrides)
        //       blank string were used before null value support was implemented
        li.dealerNet = '';
      }

      if (PartnerContext.model.isWhiteLabelWholesale && li.isPartner && !isQuoteRetrieval) {
        li.dealerNet = 0;
      }

      if (providerIndex === -1) {
        providerIndex =
          res.push(
            new ProviderModel(this).setProps({
              subCategoryId: li.lineItemSubCategoryId,
            })
          ) - 1;
      }

      res[providerIndex].optionPush(li);

      li.setOverrideCellType();

      return res;
    }, []);

    this.inited = this.providers.length > 0;

    return true;
  }

  onChange(prop, value) {
    if (this.canSet(prop)) {
      this[prop] = value;

      this.modelChanged(this);
    }
  }

  get somePartnerEqualsZero() {
    const partners = this.providers.filter(provider => provider.selectedOption.isPartner);

    return partners.length === 0 ? false : partners.some(provider => provider.selectedOption.dealerNet === 0);
  }

  get isConfirmed() {
    if (!this.somePartnerEqualsZero && this._isConfirmed) {
      return false;
    }

    return this._isConfirmed;
  }

  set isConfirmed(v) {
    this._isConfirmed = v;
  }

  priceIsExceeded() {
    for (let i = 0; i < this.providers.length; i++) {
      const selectedProvider = this.providers[i].selectedOption;

      if (fixFloat(selectedProvider.price, 2) < fixFloat(selectedProvider.allocated, 2)) {
        return true;
      }
    }

    return false;
  }

  priceIsUnderAllocated() {
    for (let i = 0; i < this.providers.length; i++) {
      const selectedProvider = this.providers[i].selectedOption;

      if (fixFloat(selectedProvider.allocated, 2) < fixFloat(selectedProvider.price, 2)) {
        return true;
      }
    }

    return false;
  }

  // use only for Customer Order quote
  isAllPricesAllocated() {
    for (let i = 0; i < this.providers.length; i++) {
      const selectedProvider = this.providers[i].selectedOption;

      if (fixFloat(selectedProvider.price, 2) !== fixFloat(selectedProvider.allocated, 2)) {
        return false;
      }
    }

    return true;
  }

  isProServicesExists() {
    for (let i = 0; i < this.providers.length; i++) {
      const selectedProvider = this.providers[i].selectedOption;

      if (selectedProvider.subCategoryId === PRO_SERVICES_FEE_SUB_CATEGORY_ID) {
        return true;
      }
    }

    return false;
  }

  get isPriceExceeded() {
    return this.priceIsExceeded();
  }
}

export default ProvidersGroupModel;
