import { LineItemModel } from '../';
import { CELL_TYPES } from '../../components/LineItems/enums';

class PartnerLineItemModel extends LineItemModel {
  constructor(props) {
    super(props);

    this._cellTypes = {
      quantity: CELL_TYPES.number,
      dealerNet: CELL_TYPES.amount,
      partnerProductName: CELL_TYPES.text,
    };

    this.cellTypes = { ...this._cellTypes };
  }

  get partnerAdd() {
    return true;
  }

  get dealerNet() {
    this.cellTypes.dealerNet = this.allocatedFinalized ? CELL_TYPES.amountForceDisabled : this._cellTypes.dealerNet;

    return this._dealerNet;
  }

  set dealerNet(value) {
    this._dealerNet = value;
  }

  get isUniqueByLineItemId() {
    return false;
  }

  get partnerProductName() {
    this.cellTypes.partnerProductName = this.allocatedFinalized
      ? CELL_TYPES.textForceDisabled
      : this._cellTypes.partnerProductName;

    return this._partnerProductName;
  }

  set partnerProductName(value) {
    this._partnerProductName = value;
  }
}

export default PartnerLineItemModel;
