import React from 'react';
import { injectIntl } from 'react-intl';
import { ToggleControl } from '../Controls';
import { TemplateStringReplace } from '../../common/helpers/format';

const PackagedApplicationsToggle = ({ onChange, intl, finalizedLocationName, value, ...props }) => {
  const handleOnChange = React.useCallback(
    v => {
      onChange('allocateAllCustomerLevelPackagedApplications', v);
    },
    [onChange]
  );

  let tooltipText = '';

  if (finalizedLocationName) {
    tooltipText = TemplateStringReplace(
      intl.formatMessage({
        id: 'msg_packaged_applications_not_allowed_tooltip',
      }),
      {
        locationName: finalizedLocationName,
      }
    );
  }

  return (
    <div className="location-pci-compliant">
      <ToggleControl
        {...props}
        onChange={handleOnChange}
        label="msg_allocate_packaged_application"
        tooltipText={tooltipText}
        value={value}
      />
    </div>
  );
};

export default injectIntl(PackagedApplicationsToggle);
