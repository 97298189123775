import { ORDER_TYPES, constants } from '../../common/enums';
import { LocationQuoteModel, QuoteModel, ModelAbstract, CustomerOrderModel, QuotesCollectionModel } from '../';
import apiData from '../../Storage/apiData';
import { AuthContext, PartnerContext, PriceBookContext } from '../../Context';
import fixFloat from '../../common/helpers/fixFloat';
import { AddButtonModel, AddExistingButtonModel } from './index';

export default class CategoryModel extends ModelAbstract {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this._active = true;
    this._showMarkupTotal = true;
    this._masterOrder = null;

    this.id = null;
    this.name = '';
    this.recurring = false;

    this.addButton = new AddButtonModel(this);
    this.addExistingButton = new AddExistingButtonModel(this);

    this._ignoreOnExport.push('masterOrder');
  }

  get masterOrder() {
    if (this._masterOrder instanceof CustomerOrderModel) {
      return this._masterOrder;
    }

    /** @type {QuotesCollectionModel} */
    const quotesCollection = this.findSibling(obj => obj instanceof QuotesCollectionModel);
    this._masterOrder = quotesCollection.masterOrder;

    return this._masterOrder;
  }

  get isInLocationQuote() {
    return this.findSibling(d => d instanceof QuoteModel) instanceof LocationQuoteModel;
  }

  get columns() {
    let result = [];

    if (this.isInLocationQuote) {
      result = [
        {
          alias: 'lineItemName',
          header: null,
        },
        {
          alias: 'totalQuantity',
          header: 'msg_total_quantity',
        },
        {
          alias: 'available',
          header: 'msg_available',
        },
        {
          alias: 'quantity',
          header: 'msg_quantity',
        },
        {
          alias: 'quotePrice',
          header: 'msg_quote_price',
        },
        {
          alias: 'total',
          header: 'msg_total',
        },
      ];

      if ([constants.NRC_4G_CATEGORY_ID, constants.NRC_SD_WAN_CATEGORY_ID].includes(this.id)) {
        result = result.filter(d => !['total'].includes(d.alias));
      }

      if (!this.recurring) {
        result.splice(
          result.findIndex(d => d.alias === 'quotePrice'),
          0,
          {
            alias: 'dealerNetPrice',
            header: 'msg_dealer_net_price',
          }
        );
      }

      if (this.id === constants.ATA_CATEGORY_ID && AuthContext.model.hasSalesOpsPermissions) {
        const allocatedIndex = result.findIndex(d => d.alias === 'totalQuantity');

        result.splice(allocatedIndex, 0, {
          alias: 'numPortsWithOverridePerItem',
          header: 'msg_ports',
        });
      }

      if (AuthContext.model.hasSalesOpsPermissions) {
        result.splice(result.findIndex(d => d.alias === 'quantity') + 1, 0, {
          alias: 'overrideQuantity',
          header: 'msg_quantity_override',
        });
      }

      if (this.id === constants.MAINTENANCE_CATEGORY_ID) {
        result = [
          {
            alias: 'lineItemName',
            header: null,
          },
          {
            alias: 'parentPrice',
            header: 'msg_total_price',
          },
          {
            alias: 'availablePrice',
            header: 'msg_available',
          },
        ];

        if (AuthContext.model.hasSalesOpsPermissions) {
          result.push(
            {
              alias: 'dealerNet',
              header: 'msg_price',
            },
            {
              alias: 'overrideValue',
              header: 'msg_override',
            }
          );
        } else {
          result.push({
            alias: 'dealerNetPrice',
            header: 'msg_price',
          });
        }
      }
    } else {
      result = [
        {
          alias: 'lineItemName',
          header: null,
        },
        {
          alias: 'quantity',
          header: 'msg_quantity',
        },
        {
          alias: 'allocated',
          header: 'msg_allocated',
        },
        {
          alias: 'dealerNet',
          header: 'msg_price',
        },
        {
          alias: 'overrideValue',
          header: 'msg_override',
        },
        {
          alias: 'markupValue',
          header: 'msg_markup',
        },
        {
          alias: 'quotePrice',
          header: 'msg_quote_price',
        },
        {
          alias: 'totalMarkup',
          header: 'msg_total_markup',
        },
        {
          alias: 'total',
          header: 'msg_total',
        },
      ];

      if (this.skipAddOnReductionOrderTypes) {
        result.splice(
          result.findIndex(d => d.alias === 'allocated'),
          1
        );
      }

      if (!AuthContext.model.hasSalesOpsPermissions) {
        const dealerNet = result.findIndex(d => d.alias === 'dealerNet');

        result.splice(
          result.findIndex(d => d.alias === 'overrideValue'),
          1
        );

        if (dealerNet !== -1) {
          result[dealerNet].alias = 'price';
        }
      }

      const { hidePriceAndMarkup } = PriceBookContext.model.flags;
      const pricebookAllowRcMarkup = PartnerContext.model.isWholeSale;

      if (hidePriceAndMarkup) {
        let removeColumns = ['markupValue', 'totalMarkup', 'dealerNet', 'price'];

        if (PartnerContext.model.isWhiteLabelWholesale && AuthContext.model.hasSalesOpsPermissions) {
          removeColumns = removeColumns.filter(col => col !== 'dealerNet');
        }

        if (!PartnerContext.model.isWhiteLabelWholesale && AuthContext.model.hasSalesOpsPermissions) {
          removeColumns = [];
        }

        if (removeColumns.length > 0) {
          result = result.filter(d => !removeColumns.includes(d.alias));
          this.showMarkupTotal = false;
        }
      }

      if (this.recurring && !pricebookAllowRcMarkup) {
        result = result.filter(d => !['markupValue', 'totalMarkup'].includes(d.alias));
        this.showMarkupTotal = false;
      }

      if (this.id === constants.ATA_CATEGORY_ID && AuthContext.model.hasSalesOpsPermissions) {
        const allocatedIndex = result.findIndex(d => d.alias === 'quantity');

        result.splice(
          allocatedIndex,
          0,
          {
            alias: 'numPorts',
            header: 'msg_ports',
          },
          {
            alias: 'numPortsOverride',
            header: 'msg_ports_override',
          }
        );
      }

      if (AuthContext.model.hasSalesOpsPermissions) {
        result.splice(result.findIndex(d => d.alias === 'quantity') + 1, 0, {
          alias: 'overrideQuantity',
          header: 'msg_quantity_override',
        });
      }

      if (this.id === constants.MAINTENANCE_CATEGORY_ID) {
        result = [
          {
            alias: 'lineItemName',
            header: null,
          },
        ];

        if (AuthContext.model.hasSalesOpsPermissions) {
          result.push(
            {
              alias: 'dealerNet',
              header: 'msg_price',
            },
            {
              alias: 'overrideValue',
              header: 'msg_override',
            }
          );
        } else {
          result.push({
            alias: 'dealerNetPrice',
            header: 'msg_price',
          });
        }
      }
    }

    return result;
  }

  get skipAddOnReductionOrderTypes() {
    const quote = this.findSibling(d => d instanceof QuoteModel);
    return [ORDER_TYPES.REDUCTION, ORDER_TYPES.ADD_ON].indexOf(quote.orderType) !== -1;
  }

  get _lineItemsLookup() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    if (!quote) {
      return [];
    }

    return quote.lineItems.items.filter(d => d.lineItemCategoryId === this.id);
  }

  get lineItems() {
    return this._getCachedValueOnExport('_lineItemsLookup');
  }

  get visibleLineItems() {
    return this.lineItems.filter(lineItem => !lineItem.hidden);
  }

  get active() {
    const apiActiveLineItems = apiData.lineItems.filter(d => d.lineItemCategoryId === this.id && d.active);

    return Boolean(apiActiveLineItems.length) || Boolean(this.lineItems.length) || this._active;
  }

  get hidden() {
    if (this.visibleLineItems.length === 0 && this.masterOrder.isFocusMode) {
      return true;
    }

    if (this.lineItems.some(d => d.hidden === false)) {
      return false;
    }

    let categoryHidden = false;
    const apiCategoryLineItems = apiData.lineItems.filter(d => d.lineItemCategoryId === this.id);
    const apiActiveLineItems = apiCategoryLineItems.filter(d => d.active);

    // Category does not have line items at all. Hide it
    if (apiCategoryLineItems.length === 0) {
      return true;
    }

    for (let i = 0; i < apiActiveLineItems.length; i++) {
      const d = apiActiveLineItems[i];

      // Loop thrue all active line items
      if (d.catalogFlags.some(el => el.flag.name === 'hiddenItem' && el.valueAsString !== 'FALSE')) {
        // If all of them are hidden. Hide category also
        categoryHidden = true;
      } else {
        // Category has at least one NOT hidden and active line item
        // Category should be visible
        categoryHidden = false;
        break;
      }
    }

    // TODO: telSolution become deprecated in KM-12282.
    //       This code should be discussed, reviewed and updated or removed.
    // TODO: Replace this code by more complex category visibility logic based on
    //       items that can be added directly by customer or as a sub-items
    // KM-6482: Hide category if it consist of non-supported items only.
    // Commented for now to prevent unexpected bugs

    // if (!categoryHidden && this.isInLocationQuote) {
    //   /** @type {LocationQuoteModel} */
    //   const quote = this.findSibling(d => d instanceof LocationQuoteModel);
    //
    //   if (quote) {
    //     const solutionTypeId = quote.solutionTypeId;
    //     const allowedToAddLineItems = apiActiveLineItems.filter(d => d.allowedToAdd && d.selectable);
    //     let categoryHasUnsupportedItemsOnly = true;
    //
    //     for (let i = 0; i < allowedToAddLineItems.length; i++) {
    //       const d = allowedToAddLineItems[i];
    //
    //       if (d.telSolution === 0 || d.telSolution === solutionTypeId) {
    //         categoryHasUnsupportedItemsOnly = false;
    //         break;
    //       }
    //     }
    //
    //     if (categoryHasUnsupportedItemsOnly) {
    //       categoryHidden = true;
    //     }
    //   }
    // }

    return categoryHidden;
  }

  set active(value) {
    this._active = Boolean(value);
  }

  get selectable() {
    let selectableItems = apiData.lineItems.filter(
      d => d.lineItemCategoryId === this.id && d.active && d.selectable && d.allowedToAdd
    );

    if (selectableItems.length && this._performSolutionTypeFiltering) {
      const supportedLineItemIdsBySolutionType = this._supportedLineItemIdsBySolutionType;

      selectableItems = selectableItems.filter(d => supportedLineItemIdsBySolutionType.includes(d.lineItemId));
    }

    return Boolean(selectableItems.length);
  }

  get _supportedLineItemIdsBySolutionType() {
    const solutionTypeId = this._quoteSolutionTypeId;

    return apiData.lineItems
      .filter(d => d.lineItemCategoryId === this.id && d.applicableSolutionTypes.includes(solutionTypeId))
      .map(d => d.lineItemId);
  }

  get showMarkupTotal() {
    return this.isInLocationQuote ? false : this._showMarkupTotal;
  }

  set showMarkupTotal(value) {
    this._showMarkupTotal = Boolean(value);
  }

  get _markupTotalLookup() {
    let result = 0;

    for (let i = 0; i < this.lineItems.length; i++) {
      const d = this.lineItems[i];

      if (!d.active || d.hidden) {
        continue;
      }

      result = fixFloat(result) + fixFloat(d.totalMarkup);
    }

    return fixFloat(result);
  }

  get markupTotal() {
    return this._getCachedValueOnExport('_markupTotalLookup');
  }

  get _totalLookup() {
    let result = 0;

    for (let i = 0; i < this.lineItems.length; i++) {
      const d = this.lineItems[i];

      if (d.hidden) {
        continue;
      }

      result = fixFloat(result) + fixFloat(d.total);
    }

    return fixFloat(result);
  }

  get total() {
    return this._getCachedValueOnExport('_totalLookup');
  }

  get _performSolutionTypeFiltering() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    return this.isInLocationQuote || (quote && quote.orderType === ORDER_TYPES.ADD_ON);
  }

  get _quoteSolutionTypeId() {
    const quote = this.findSibling(d => d instanceof QuoteModel);

    return quote ? quote.solutionTypeId : null;
  }

  //TODO make use of pricebook properties and access rules
  get hideAddButtonAndTotals() {
    return this.id === constants.MAINTENANCE_CATEGORY_ID;
  }

  get _hasError() {
    const lineItems = this.lineItems;

    for (let i = 0; i < lineItems.length; i++) {
      /** @type {LineItemModel|LocationLineItemModel} */
      const lineItem = lineItems[i];

      // Check for invalidateItemsNotAllowedToAddOnCondition flag issue
      if (lineItem.isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable) {
        return true;
      }

      // Check for allowedToAllocateOnConditionAtLocation flag on Location level
      // Strict comparison should be used because this prop will be undefined on MO level line items
      if (lineItem.isAllowedToAllocateOnConditionAtLocationValid === false && lineItem.quantityWithOverride) {
        return true;
      }

      // Check for not active line items
      if (!lineItem.active) {
        return true;
      }
    }

    return false;
  }

  get hasError() {
    return this._getCachedValueOnExport('_hasError');
  }
}
