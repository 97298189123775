import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Summary from '../Summary';
import LeftBarButtons from './nested/LeftBarButtons';
import RightBarButtons from './nested/RightBarButtons';
import './css/toolbar.css';

class Toolbar extends Component {
  static propTypes = {
    summaryData: PropTypes.object,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isPackageChangeEnabled: PropTypes.bool,
  };

  render() {
    const {
      amendmentButton,
      approvalButton,
      cancelAmendmentButton,
      finalizeButton,
      finalizedButton,
      preliminaryButton,
      printFinalButton,
      saveButton,
      sendForSignatureButton,
      signaturesButton,
      slexButton,

      packageOne,
      packageTwo,
      packageThree,
      packageFour,

      onAmendBtnClick,
      onApprovalBtnClick,
      onCancelAmendmentBtnClick,
      onFinalizeBtnClick,
      onFinalizedBtnClick,
      preliminaryBtnEndpoint,
      printFinalPdfEndpoint,
      onSaveBtnClick,
      onSendForSignatureBtnClick,
      onSignaturesBtnClick,
      onSlexToolbarBtnClick,

      onDetailsClick,
      onSelectPackage,
      disableDetailsClick,

      isSelectedPackageOverride,
    } = this.props;

    return (
      <div id="app-toolbar" className="toolbar-wrapper">
        <div className="toolbar max-width-container">
          <LeftBarButtons
            preliminaryBtnEndpoint={preliminaryBtnEndpoint}
            printFinalPdfEndpoint={printFinalPdfEndpoint}
            onSaveBtnClick={onSaveBtnClick}
            preliminaryButton={preliminaryButton}
            printFinalButton={printFinalButton}
            saveButton={saveButton}
          />
          <Summary
            onDetailsClick={onDetailsClick}
            onSelectPackage={onSelectPackage}
            packageOne={packageOne}
            packageTwo={packageTwo}
            packageThree={packageThree}
            packageFour={packageFour}
            isSelectedPackageOverride={isSelectedPackageOverride}
            disableDetailsClick={disableDetailsClick}
          />
          <RightBarButtons
            onAmendBtnClick={onAmendBtnClick}
            onApprovalBtnClick={onApprovalBtnClick}
            onCancelAmendmentBtnClick={onCancelAmendmentBtnClick}
            onFinalizeBtnClick={onFinalizeBtnClick}
            onFinalizedBtnClick={onFinalizedBtnClick}
            onSendForSignatureBtnClick={onSendForSignatureBtnClick}
            onSignaturesBtnClick={onSignaturesBtnClick}
            onSlexToolbarBtnClick={onSlexToolbarBtnClick}
            amendmentButton={amendmentButton}
            approvalButton={approvalButton}
            cancelAmendmentButton={cancelAmendmentButton}
            finalizeButton={finalizeButton}
            finalizedButton={finalizedButton}
            sendForSignatureButton={sendForSignatureButton}
            signaturesButton={signaturesButton}
            slexButton={slexButton}
          />
        </div>
      </div>
    );
  }
}

export default Toolbar;
