import $http from '../common/$http';
import config from '../config';

const QuoteTaxEstimatesApi = {
  async getTaxEstimates(quoteId) {
    try {
      const { data } = await $http.instance.api.get(`${config.api.url}/quote/${quoteId}/tax-estimates`);

      return data.content;
    } catch (response) {
      return response.data;
    }
  },

  async generateTaxEstimates(quoteId) {
    try {
      const { data } = await $http.instance.api.post(`${config.api.url}/quote/${quoteId}/tax-estimates`);

      return data.content;
    } catch (response) {
      return response.data;
    }
  },

  async getTaxEstimatesReport(quoteId) {
    try {
      const { data } = await $http.instance.api.get(`${config.api.url}/quote/${quoteId}/tax-estimates-report`);

      return data.content;
    } catch (response) {
      return response.data;
    }
  },
};

export default QuoteTaxEstimatesApi;
