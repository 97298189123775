import React from 'react';
import translator from '../../../common/translate';
import Row from './Row';

const Table = ({ data }) => {
  return (
    <table className="rq-table striped sticky valign-middle markers-wrapper">
      <thead>
        <tr className="markers-table-header">
          <th className="align-right">{translator.getMessage('msg_id')}</th>
          <th>{translator.getMessage('msg_line_items_header_description')}</th>
          <th className="align-right">{translator.getMessage('msg_cost_in_cents')}</th>
          <th className="align-right">{translator.getMessage('msg_usagecap')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(row => (
          <Row data={row} key={'marker-' + row.markerType} />
        ))}
      </tbody>
    </table>
  );
};

export default Table;
