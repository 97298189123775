import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { array, objectOf, string, bool, func, number } from 'prop-types';
import { RadioButtonControl, AutocompleteControl, MultiSelectControl, LabelField } from '../../../Controls';
import PartnerInput from './PartnerInput';
import BadgeButton from '../../../BadgeButton';
import translator from '../../../../common/translate';
import formatString from '../../../../common/helpers/format/formatString';
import ServiceType from './ServiceType';
import { ORDER_TYPE_OPTIONS } from '../../../../common/enums';
import IndustryType from './IndustryType';

class CardContent extends Component {
  static propTypes = {
    actionOptionsLocked: array,
    actions: objectOf(string),
    customers: array,
    customersFetching: bool,
    locationDisabled: bool,
    locations: array,
    locationsFetching: bool,
    onChangeCustomer: func,
    onChangePartner: func,
    onPropChange: func,
    selectedAction: number,
    selectedCustomerId: number,
    selectedLocationId: number,
    isLabelPartnerField: bool,
    isLabelOrderTypeField: bool,
    isLabelCustomerField: bool,
    isLabelLocationField: bool,
    quoteTitleLabel: string,
  };

  static defaultProps = {
    actionOptionsLocked: [],
    actions: {},
    customers: [],
    customersFetching: false,
    locationDisabled: false,
    locations: [],
    locationsFetching: false,
    onChangeCustomer: () => {},
    onChangePartner: () => {},
    onPropChange: () => {},
    selectedAction: null,
    selectedCustomerId: null,
    selectedLocationId: null,
  };

  constructor(props) {
    super(props);

    this.handleChangeSelectedLocationId = this.handleChangeSelectedLocationId.bind(this);
  }

  handleChangeSelectedLocationId(value) {
    this.props.onPropChange('selectedLocationId', value);
  }

  render() {
    const {
      actionOptionsLocked,
      actions,
      customerDisabled,
      customers,
      customersFetching,
      locationDisabled,
      locations,
      locationsFetching,
      onChangePartner,
      onChangeOrderType,
      onChangeCustomer,
      selectedAction,
      selectedCustomerId,
      selectedLocationId,
      partnerLocked,
      onCancelButtonClick,
      onStartQuoteButtonClick,
      startBtnDisabled,
      startErrorsText,
      invalidFieldsCnt,
      isRewriteSelected,
      selectedLocationIds,
      onChangeLocationIds,
      partnerInfoLabel,
      serviceType,
      onChangeServiceType,
      serviceTypeOptions,
      serviceTypeDisabled,
      isCustomerRequired,
      isLocationRequired,
      industryTypes,
      onChangeIndustryTypeId,
      selectedIndustryTypeId,
      partnersFetching,
      isDataFetching,
      doInitialInputFocus,
      isLabelPartnerField,
      isLabelOrderTypeField,
      isLabelCustomerField,
      isLabelLocationField,
      quoteTitle,
    } = this.props;

    const _partnerInfoLabel = formatString(partnerInfoLabel, this.props.intl.formatMessage);
    const isNewNewActionSelected = selectedAction === ORDER_TYPE_OPTIONS.NEW_CUSTOMER;
    const isStartButtonDisabled =
      startBtnDisabled || isDataFetching || customersFetching || locationsFetching || partnersFetching;
    const isPartnerInputDisabled = partnerLocked || customersFetching;

    return (
      <div className="card-content">
        {quoteTitle ? (
          <LabelField
            parentClassName="quoteTitleLabelField"
            id="quoteTitleLabelField"
            key="quoteTitleLabelField"
            label="msg_quote_title"
            value={quoteTitle}
          />
        ) : null}

        <PartnerInput
          onChangePartner={onChangePartner}
          disabled={isPartnerInputDisabled}
          doFocus={!isPartnerInputDisabled && doInitialInputFocus}
          isLabel={isLabelPartnerField}
        />

        {_partnerInfoLabel ? (
          <LabelField
            parentClassName="partnerInfoLabel"
            id="partnerInfoLabel"
            key="partnerInfoLabel"
            label=" "
            value={_partnerInfoLabel}
          />
        ) : null}

        {isLabelOrderTypeField ? (
          <LabelField
            id="order-initiation-order-type-label"
            label="msg_select_order_type"
            value={actions[selectedAction]}
          />
        ) : (
          <RadioButtonControl
            id="order-initiation-action-select"
            label="msg_select_order_type"
            lockedOptions={actionOptionsLocked}
            onChange={onChangeOrderType}
            options={actions}
            required
            value={selectedAction}
            parentClassName="order-type-select"
          />
        )}

        {isLabelCustomerField ? (
          <LabelField
            id="order-initiation-customer-label"
            label="msg_select_a_customer"
            value={customers.find(o => o.value === selectedCustomerId)?.text}
          />
        ) : (
          <AutocompleteControl
            disabledState={customerDisabled}
            id="order-initiation-customer-select"
            isLoading={customersFetching}
            label="msg_select_a_customer"
            onChange={onChangeCustomer}
            options={customers}
            required={isCustomerRequired}
            value={selectedCustomerId}
            focus={!customerDisabled && doInitialInputFocus}
          />
        )}

        {isLabelLocationField ? (
          selectedLocationId ? (
            <LabelField
              id="order-initiation-location-label"
              label="msg_select_a_location"
              value={locations.find(o => o.value === selectedLocationId)?.text}
            />
          ) : null
        ) : isRewriteSelected ? (
          <MultiSelectControl
            disabledState={locationDisabled}
            id="order-initiation-location-select"
            isLoading={locationsFetching}
            label="msg_select_a_location"
            onChange={onChangeLocationIds}
            options={locations}
            value={selectedLocationIds}
            required
          />
        ) : (
          <AutocompleteControl
            disabledState={locationDisabled}
            id="order-initiation-location-select"
            isLoading={locationsFetching}
            label="msg_select_a_location"
            onChange={this.handleChangeSelectedLocationId}
            options={locations}
            required={isLocationRequired}
            value={selectedLocationId}
            focus={!locationDisabled && doInitialInputFocus}
          />
        )}

        <ServiceType
          label="msg_service_type"
          options={serviceTypeOptions}
          value={serviceType}
          onChange={onChangeServiceType}
          disabled={serviceTypeDisabled}
        />

        {isNewNewActionSelected && (
          <IndustryType
            label="msg_industry_type"
            industryTypes={industryTypes}
            value={selectedIndustryTypeId}
            onChange={onChangeIndustryTypeId}
          />
        )}

        <div className="btn-collection order-initiation-actions-bottom">
          <BadgeButton
            onClick={onCancelButtonClick}
            btnClassName="card-cancel-quote-btn text-btn large"
            id="cancelQuoteToolbarBtn"
            label={translator.getMessage('msg_cancel')}
          />
          <BadgeButton
            onClick={onStartQuoteButtonClick}
            btnClassName="card-start-quote-btn primary large"
            id="startQuoteToolbarBtn"
            label={translator.getMessage('msg_create_quote')}
            disabled={isStartButtonDisabled}
            errors={startErrorsText}
            errorsCnt={invalidFieldsCnt}
            tooltipVerticalAlign={'top'}
          />
        </div>
      </div>
    );
  }
}

export default injectIntl(CardContent);
