export const CONTACT_PROPS = {
  shippingInfo: {
    companyName: {
      type: 'text',
      id: 'shippingCompanyName',
      label: 'msg_company_name',
      maxLength: 100,
    },
    contactFirstName: {
      type: 'text',
      id: 'shippingContactFirstName',
      label: 'msg_first_name',
    },
    contactLastName: {
      type: 'text',
      id: 'shippingContactLastName',
      label: 'msg_last_name',
    },
    streetAddress1: {
      type: 'text',
      id: 'shippingAddress1',
      label: 'msg_address_line_1',
    },
    streetAddress2: {
      type: 'text',
      id: 'shippingAddress2',
      label: 'msg_address_line_2',
    },
    city: {
      type: 'text',
      id: 'shippingCity',
      label: 'msg_city',
    },
    stateId: {
      type: 'select',
      id: 'shippingStateId',
      placeholder: 'msg_select_state',
      label: 'msg_state',
      optionsType: 'states',
      collection: 'customerContact',
      isReadOnly: false,
    },
    countryId: {
      type: 'select',
      id: 'shippingCountryId',
      placeholder: 'msg_select_country',
      label: 'msg_country',
      optionsType: 'countries',
      collection: 'customerContact',
      locked: true,
    },
    zip: {
      type: 'zip',
      id: 'shippingZip',
      label: 'msg_zip_code',
    },
  },
  serviceInfo: {
    locationName: {
      type: 'label',
      id: 'locationName',
      label: 'msg_location_name',
    },
    contactFirstName: {
      type: 'text',
      id: 'businessContactFirstName',
      label: 'msg_business_contact_first_name',
    },
    contactLastName: {
      type: 'text',
      id: 'businessContactLastName',
      label: 'msg_business_contact_last_name',
    },
    streetAddress1: {
      type: 'text',
      id: 'streetAddress1',
      label: 'msg_address_line_1',
    },
    streetAddress2: {
      type: 'text',
      id: 'streetAddress2',
      label: 'msg_address_line_2',
    },
    city: {
      type: 'text',
      id: 'city',
      label: 'msg_city',
      maxLength: 30,
    },
    stateId: {
      type: 'select',
      id: 'stateId',
      label: 'msg_state',
      placeholder: 'msg_select_state',
      optionsType: 'states',
      collection: 'customerContact',
      isReadOnly: false,
    },
    zip: {
      type: 'zip',
      id: 'zip',
      label: 'msg_zip_code',
    },
    countryId: {
      type: 'select',
      id: 'countryId',
      hint: '',
      label: 'msg_country',
      defaultValue: 'United States', // Default
      optionsType: 'countries',
      collection: 'customerContact',
      locked: true,
    },
    contactOfficePhone: {
      type: 'mask',
      id: 'businessContactOfficePhone',
      label: 'msg_business_contact_office_number',
      maxLength: 20,
      mask: '999-999-9999',
    },
    contactMobilePhone: {
      type: 'mask',
      id: 'businessContactMobilePhone',
      label: 'msg_business_contact_mobile_number',
      maxLength: 20,
      mask: '999-999-9999',
    },
    contactEmail: {
      type: 'text',
      id: 'businessContactEmail',
      label: 'msg_business_contact_email',
    },
    btn: {
      type: 'mask',
      id: 'btn',
      label: 'msg_business_telephone_number',
      maxLength: 20,
      mask: '999-999-9999',
      requiredForSubmission: true,
    },
  },
  billingInfo: {
    decentralizedAccounting: {
      type: 'toggle',
      id: 'decentralizedAccounting',
      label: 'msg_decentralized_accounting',
      hint: 'msg_decentralized_accounting_hint',
      parentClassName: 'decentralized-accounting-toggle',
    },
    billToId: {
      type: 'select',
      id: 'billToId',
      label: 'msg_bill_to',
      options: [
        { value: null, text: '' },
        { value: 1, text: 'Dealer' },
        { value: 2, text: 'Customer' },
      ],
      optionsType: 'billTo',
      disabled: false,
    },
    contactFirstName: {
      type: 'text',
      id: 'billingContactFirstName',
      label: 'msg_billing_contact_first_name',
    },
    contactLastName: {
      type: 'text',
      id: 'billingContactLastName',
      label: 'msg_billing_contact_last_name',
    },
    streetAddress1: {
      type: 'text',
      id: 'billingContactAddress1',
      label: 'msg_billing_contact_address_line_1',
      maxLength: 100,
    },
    streetAddress2: {
      type: 'text',
      id: 'billingContactAddress2',
      label: 'msg_billing_contact_address_line_2',
      maxLength: 100,
    },
    city: {
      type: 'text',
      id: 'billingContactCity',
      label: 'msg_billing_contact_city',
    },
    stateId: {
      type: 'select',
      id: 'billingContactStateId',
      label: 'msg_billing_contact_state',
      placeholder: 'msg_select_state',
      optionsType: 'states',
      collection: 'customerContact',
      isReadOnly: false,
    },
    zip: {
      type: 'zip',
      id: 'billingContactZip',
      label: 'msg_billing_contact_zip',
    },
    countryId: {
      type: 'select',
      id: 'billingContactCountryId',
      hint: '',
      label: 'msg_billing_contact_country',
      defaultValue: 'United States', // Default
      optionsType: 'countries',
      collection: 'customerContact',
      locked: true,
    },
    contactOfficePhone: {
      type: 'mask',
      id: 'billingContactOfficePhone',
      label: 'msg_billing_contact_office_number',
      inputType: 'PHONE',
      mask: '999-999-9999',
    },
    contactMobilePhone: {
      type: 'mask',
      id: 'billingContactMobilePhone',
      label: 'msg_billing_contact_mobile_number',
      inputType: 'PHONE',
      mask: '999-999-9999',
    },
    contactEmail: {
      type: 'text',
      id: 'billingContactEmail',
      label: 'msg_billing_contact_email',
      parentClassName: 'billing-contact-email',
    },
    validateEmail: {
      id: 'billingValidateEmail',
      label: 'msg_validate_email_address_label',
    },
  },
};

const COPY_TEXT = {
  COPY_FROM_MASTER_ORDER_SERVICE: 'msg_copy_from_mo_service',
  COPY_FROM_MASTER_ORDER_BILLING: 'msg_copy_from_mo_billing',
  COPY_FROM_MASTER_ORDER_SHIPPING: 'msg_copy_from_mo_shipping',
  COPY_FROM_LOCATION_SERVICE: 'msg_copy_from_service',
  COPY_FROM_LOCATION_BILLING: 'msg_copy_from_billing',
  COPY_FROM_PARTNER: 'msg_copy_from_partner',
};

export const COPY_PROPS = {
  MASTER_ORDER_BILLING: [
    {
      value: 'COPY_FROM_MASTER_ORDER_SERVICE',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SERVICE,
    },
    {
      value: 'COPY_FROM_PARTNER_MASTER_ORDER',
      text: COPY_TEXT.COPY_FROM_PARTNER,
    },
  ],
  MASTER_ORDER_SHIPPING: [
    {
      value: 'COPY_FROM_MASTER_ORDER_SERVICE',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SERVICE,
    },
    {
      value: 'COPY_FROM_MASTER_ORDER_BILLING',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_BILLING,
    },
    {
      value: 'COPY_FROM_PARTNER_MASTER_ORDER',
      text: COPY_TEXT.COPY_FROM_PARTNER,
    },
  ],
  LOCATION_SERVICE: [
    {
      value: 'COPY_FROM_MASTER_ORDER_SERVICE',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SERVICE,
    },
    {
      value: 'COPY_FROM_MASTER_ORDER_BILLING',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_BILLING,
    },
    {
      value: 'COPY_FROM_MASTER_ORDER_SHIPPING',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SHIPPING,
    },
  ],
  LOCATION_BILLING: [
    {
      value: 'COPY_FROM_MASTER_ORDER_SERVICE',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SERVICE,
    },
    {
      value: 'COPY_FROM_MASTER_ORDER_BILLING',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_BILLING,
    },
    {
      value: 'COPY_FROM_LOCATION_SERVICE',
      text: COPY_TEXT.COPY_FROM_LOCATION_SERVICE,
    },
  ],
  LOCATION_SHIPPING: [
    {
      value: 'COPY_FROM_MASTER_ORDER_SERVICE',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SERVICE,
    },
    {
      value: 'COPY_FROM_MASTER_ORDER_BILLING',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_BILLING,
    },
    {
      value: 'COPY_FROM_MASTER_ORDER_SHIPPING',
      text: COPY_TEXT.COPY_FROM_MASTER_ORDER_SHIPPING,
    },
    {
      value: 'COPY_FROM_LOCATION_SERVICE',
      text: COPY_TEXT.COPY_FROM_LOCATION_SERVICE,
    },
    {
      value: 'COPY_FROM_LOCATION_BILLING',
      text: COPY_TEXT.COPY_FROM_LOCATION_BILLING,
    },
    {
      value: 'COPY_FROM_PARTNER_LOCATION',
      text: COPY_TEXT.COPY_FROM_PARTNER,
    },
  ],
};

export const PROPS_TO_SKIP = ['locationName', 'decentralizedAccounting'];
export const PROPS_TO_SKIP_WLW = ['contactOfficePhone', 'contactMobilePhone', 'contactEmail'];
