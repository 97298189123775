import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import BulkMarkupRow from './BulkMarkupRow';

class BulkMarkup extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    disabledState: PropTypes.bool,
    lockedState: PropTypes.bool,
    showNrcInputs: PropTypes.bool,
    showRcInputs: PropTypes.bool,
  };

  static defaultProps = {
    showNrcInputs: false,
    showRcInputs: false,
  };

  render() {
    const { showNrcInputs, showRcInputs } = this.props;

    if (!showNrcInputs && !showRcInputs) {
      return null;
    }

    const { onChange, onApply, bulkRecurring, bulkNonRecurring, disabledState, lockedState } = this.props;

    return (
      <div>
        <span>{translator.getMessage('msg_quick_set_markup')}</span>
        <p>{translator.getMessage('msg_add_a_percent_to_markup_each_section')}</p>
        <p className="text-hint">{translator.getMessage('msg_this_will_replace_any_existing_markup')}</p>

        {showNrcInputs ? (
          <BulkMarkupRow
            label="msg_non_recurring"
            id="bulkNonRecurring"
            value={bulkNonRecurring}
            onChange={onChange}
            onApply={onApply}
            disabledState={disabledState}
            lockedState={lockedState}
          />
        ) : null}

        {showRcInputs ? (
          <BulkMarkupRow
            label="msg_recurring"
            id="bulkRecurring"
            value={bulkRecurring}
            onChange={onChange}
            onApply={onApply}
            disabledState={disabledState}
            lockedState={lockedState}
          />
        ) : null}
      </div>
    );
  }
}

export default BulkMarkup;
