import { AppStateModel, ModelAbstract } from './index';
import { PriceBookContext } from '../Context';

// TODO: refactor this to remove keys hard coded list
const ERROR_KEYS = [
  'lineItems',
  'numLines',
  'numLocations',
  'billingDealerId',
  'dealerContactUserId',
  'quoteTitle',
  'inactiveLineItems',
  'spiffAmount',
  'spiffAvailableAmountRc',
  'spiffAvailableAmountNrc',
  'isLocationUnique',
  'customerNameLength',
  'customerNameIsUnique',
  'blankIctpPartner',
  'ictpConfirmationRequired',
  'NRC_MRC_LineItems',
  'locationName',
  'saveButtonSeApproval',
  'unlimitedCallRoutingCountMixedWarning',
  'unlimitedCallRoutingLimitWarning',
  'saveButtonSeApproval',
  'allocationsForFinalizedMo',
  'numStarBoxesError',
  'customerInstallStarPlus',
  'solutionType',
  'location4gRequiresSdwan',
  'madPoolOverAllocated',
  'spiffOverAllocated',
  'madUpfrontOverAllocated',
  'madRecurOverAllocated',
  'starCloudWithCustomerInstallation',
  'legacyLineItemsAddons',
  'legacyLineItemsLocations',
  'negativeAmount',
  'onePerGroup',
  'starFaxDigitalMixedAtLocationWarning',
  'dbaError',
  'pciComplianceWarning',
  'bcUserMo',
  'bcUserLocation',
  'leaseCustomPaymentAmount',
  'customerInfoErrors',
  'addendumErrors',
  'allowedToAllocateOnConditionAtLocationError',
  'invalidateItemsNotAllowedToAddOnConditionError',
  'switchvoxOrSipStationMustBePresent',
  'contactCenterConcurrencyItemMustBePresent',
  'smartOfficeGatewayKitValidation',
  'smartOfficeAccessDoorLicenseValidation',
  'smartOfficeLicenceOverallocationWarning',
  'switchvoxApplianceValidation',
  'standaloneCPaaSValidationError',
  'numSangomaCXUsersExceedsNumUCaaSUsersError',
  'sangomaCXUsersRequiredError',
  'sangomaCXConcurrencyItemsValidation',
  'calculationRequired',
];

class AppErrorsModel extends ModelAbstract {
  lineItems = '';
  numLines = '';
  numLocations = '';
  billingDealerId = '';
  dealerContactUserId = '';
  quoteTitle = '';
  inactiveLineItems = '';
  spiffAmount = '';
  spiffAvailableAmountRc = '';
  spiffAvailableAmountNrc = '';
  isLocationUnique = '';
  customerNameLength = '';
  customerNameIsUnique = '';
  blankIctpPartner = '';
  ictpConfirmationRequired = '';
  NRC_MRC_LineItems = '';
  addOnFinalizeAllow = '';
  salesExceptionsRules = [];
  locationName = '';
  saveButtonSeApproval = '';
  unlimitedCallRoutingCountMixedWarning = '';
  unlimitedCallRoutingLimitWarning = '';
  allocationsForFinalizedMo = '';
  numStarBoxesError = '';
  customerInstallStarPlus = '';
  solutionType = '';
  location4gRequiresSdwan = '';
  madPoolOverAllocated = '';
  spiffOverAllocated = '';
  madUpfrontOverAllocated = '';
  madRecurOverAllocated = '';
  starCloudWithCustomerInstallation = '';
  legacyLineItemsAddons = '';
  legacyLineItemsLocations = '';
  negativeAmount = '';
  onePerGroup = [];
  starFaxDigitalMixedAtLocationWarning = '';
  dbaError = '';
  starPhoneOnlyExtensionExceedUsersWarning = '';
  pciComplianceWarning = '';
  bcUserMo = '';
  bcUserLocation = '';
  leaseCustomPaymentAmount = '';
  customerInfoErrors = '';
  addendumErrors = '';
  allowedToAllocateOnConditionAtLocationError = '';
  invalidateItemsNotAllowedToAddOnConditionError = '';
  switchvoxOrSipStationMustBePresent = '';
  contactCenterConcurrencyItemMustBePresent = '';
  smartOfficeGatewayKitValidation = '';
  smartOfficeAccessDoorLicenseValidation = '';
  smartOfficeLicenceOverallocationWarning = '';
  switchvoxApplianceValidation = '';
  standaloneCPaaSValidationError = '';
  numSangomaCXUsersExceedsNumUCaaSUsersError = '';
  sangomaCXUsersRequiredError = '';
  sangomaCXConcurrencyItemsValidation = '';

  constructor(instance) {
    super(instance);

    this._appState = null;

    this._ignoreOnExport.push('appState');
  }

  _filterErrors(condition) {
    let result = {};

    for (let i = 0; i < ERROR_KEYS.length; i++) {
      const key = ERROR_KEYS[i];
      const code = this[key];

      if (condition(code, key)) {
        result[key] = code;
      }
    }

    return result;
  }

  get all() {
    return this._filterErrors(() => true);
  }

  get valid() {
    return this._filterErrors(code => code.length === 0);
  }

  get invalid() {
    return this._filterErrors(code => code.length);
  }

  get invalidCodes() {
    return Object.keys(this.invalid).map(key => this[key]);
  }

  setProps(props, skipProps = []) {
    // KM-10911: Force blank errors for specific validation rules if it is listed among PB suppress flags
    const suppressWarningByName = PriceBookContext.model.flags.suppressWarningByName;

    if (Array.isArray(suppressWarningByName) && suppressWarningByName.length) {
      suppressWarningByName.forEach(validationRuleName => {
        props[validationRuleName] = '';
      });
    }

    return super.setProps(props, skipProps);
  }

  /**
   * @returns {AppStateModel}
   */
  get appState() {
    if (this._appState !== null) {
      return this._appState;
    }

    this._appState = this.findSibling(obj => obj instanceof AppStateModel);

    return this._appState;
  }

  get calculationRequired() {
    return !this.appState.isCalculated && !this.appState.isCalcRequested ? 'CALCULATION_REQUIRED' : '';
  }
}

export default AppErrorsModel;
