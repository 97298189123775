import React from 'react';
import { ContextConnect, ContextModel } from './';
import { PARTNER_TYPES, WHOLESALE_TYPES_LIST } from '../common/enums';

const context = React.createContext();

class PartnerContextModel extends ContextModel {
  constructor() {
    super();

    this.canSobo = false;
    this.pricebookId = null;
    this.tier = null;
    this.type = null;
  }

  clearPartnerContext() {
    this.canSobo = false;
    this.pricebookId = null;
    this.tier = null;
    this.type = null;
  }

  get isWholeSale() {
    return WHOLESALE_TYPES_LIST.includes(this.type);
  }

  get isStarCloudPlus() {
    // KM-11816: Temporary change. Exclusive logic to determine StarCloud 4.0+ type
    return this.type !== PARTNER_TYPES.FULL_SERVICE_WHOLESALE && this.type !== PARTNER_TYPES.WHITE_LABEL_WHOLESALE;

    // TODO: Revert changes when DB values are fixed
    // return PARTNER_TYPES.STARCLOUD_PLUS_4_0 === this.type;
  }

  get isFullServiceWholesale() {
    return this.type === PARTNER_TYPES.FULL_SERVICE_WHOLESALE;
  }

  get isWhiteLabelWholesale() {
    return this.type === PARTNER_TYPES.WHITE_LABEL_WHOLESALE;
  }
}

export const model = new PartnerContextModel();
const Provider = ContextConnect(context, model);
const Consumer = context.Consumer;

const PartnerContext = {
  Provider,
  Consumer,
  model,
};

export default PartnerContext;
