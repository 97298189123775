import packageJson from '../package.json';
import defaultConfig from './config.defaults.json';

let configOverrides = {};
let apiOverrides = {};

// KM-4822: ENV variables to override config values
// REACT_APP_API_PROTOCOL
// REACT_APP_API_HOSTNAME
// REACT_APP_API_PORT
// REACT_APP_API_PATH
// REACT_APP_TRACKING_URL
// REACT_APP_LOGIN_URL
// REACT_APP_SHOW_CONSOLE_LOG
// REACT_APP_LOCALE
// REACT_APP_ENV_LABEL
// REACT_APP_USER_INPUTS_SOLUTION_TYPE_ENABLED
// REACT_APP_E_SIGNATURE_ENABLED
// REACT_APP_COMMIT_INPUTS_ON_BLUR
if (typeof process.env.REACT_APP_API_PROTOCOL === 'string') {
  apiOverrides.protocol = process.env.REACT_APP_API_PROTOCOL;
}

if (typeof process.env.REACT_APP_API_HOSTNAME === 'string') {
  apiOverrides.hostname = process.env.REACT_APP_API_HOSTNAME;
}

if (typeof process.env.REACT_APP_API_PORT === 'string') {
  apiOverrides.port = process.env.REACT_APP_API_PORT;
}

if (typeof process.env.REACT_APP_API_PATH === 'string') {
  apiOverrides.path = process.env.REACT_APP_API_PATH;
}

if (typeof process.env.REACT_APP_TRACKING_URL === 'string') {
  configOverrides.trackingUrl = process.env.REACT_APP_TRACKING_URL;
}

if (typeof process.env.REACT_APP_LOGIN_URL === 'string') {
  configOverrides.loginPage = process.env.REACT_APP_LOGIN_URL;
}

if (typeof process.env.REACT_APP_SHOW_CONSOLE_LOG === 'string') {
  configOverrides.showConsoleLog = process.env.REACT_APP_SHOW_CONSOLE_LOG.toLowerCase() === 'true';
}

if (typeof process.env.REACT_APP_LOCALE === 'string') {
  configOverrides.locale = process.env.REACT_APP_LOCALE;
}

if (typeof process.env.REACT_APP_USER_INPUTS_SOLUTION_TYPE_ENABLED === 'string') {
  configOverrides.userInputsSolutionTypeEnabled =
    process.env.REACT_APP_USER_INPUTS_SOLUTION_TYPE_ENABLED.toLowerCase() === 'true';
}

if (typeof process.env.REACT_APP_E_SIGNATURE_ENABLED === 'string') {
  configOverrides.eSignatureEnabled = process.env.REACT_APP_E_SIGNATURE_ENABLED.toLowerCase() === 'true';
}

if (typeof process.env.REACT_APP_COMMIT_INPUTS_ON_BLUR === 'string') {
  configOverrides.commitInputsOnBlur = process.env.REACT_APP_COMMIT_INPUTS_ON_BLUR.toLowerCase() === 'true';
}

class URL {
  protocol;
  hostname;
  port;
  path;

  constructor({ protocol, hostname, port, path }) {
    this.protocol = protocol || window.location.protocol.slice(0, -1);
    this.hostname = hostname || window.location.hostname;
    this.port = port;
    this.path = path || '';
  }

  get url() {
    return this.urlWithoutPath + this.path;
  }

  get urlWithoutPath() {
    const port = this.port ? ':' + this.port : '';

    return this.protocol + '://' + this.hostname + port;
  }
}

let config = Object.assign({}, defaultConfig, configOverrides);
config.api = new URL(Object.assign({}, config.api, apiOverrides));

config.version = packageJson.version;
config.COUNTRY_CODE_USA = 254;

// KM-5092: Environment badge support
config.envLabel =
  typeof process.env.REACT_APP_ENV_LABEL === 'string' && process.env.REACT_APP_ENV_LABEL !== ''
    ? process.env.REACT_APP_ENV_LABEL
    : null;

export default config;
