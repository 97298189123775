import CommonSectionModel from './CommonSectionModel';
import bindMultiple from '../../common/helpers/bindMultiple';

class AddendumSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'addendums-section';
    this.title = 'msg_addendums';
    this.isGeneralSection = true;

    bindMultiple(this, this.onChangeAddendumStatus, this.onChangeAddendumField);
  }

  get lockedState() {
    return super.lockedState || this.appState.switchvoxWlwDisabled;
  }

  onChangeAddendumStatus(value, type) {
    const { quoteAddendums, onChange } = this.customerOrder;
    const idx = quoteAddendums.findIndex(addendum => addendum.documentType === type);
    quoteAddendums[idx].active = value;

    if (typeof onChange === 'function') {
      onChange('quoteAddendums', quoteAddendums);
    }
  }

  onChangeAddendumField(val, type, field) {
    const { quoteAddendums, onChange } = this.customerOrder;
    const idx = quoteAddendums.findIndex(addendum => addendum.documentType === type);
    quoteAddendums[idx].fields[field] = val;

    if (typeof onChange === 'function') {
      onChange('quoteAddendums', quoteAddendums);
    }
  }
}

export default AddendumSectionModel;
