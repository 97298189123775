import React from 'react';
import { LabelField, SelectControl, ToggleControl } from '../Controls';
import translator from '../../common/translate';
import { injectIntl } from 'react-intl';
import { SANGOMA_CX_USER_TYPE_DROPDOWN_OPTIONS } from '../../common/enums';

const SangomaCxSettings = ({
  intl,
  customerOrder,
  lockedState,
  showSpeechAnalyticsActiveToggle,
  speechAnalyticsActiveToggleDisabled,
  showSangomaCxStandalone,
  sangomaCXConcurrencyDisabled,
  userTypeSelectDisabled,
  onUserSelectChange,
  onConcurrencyChange,
  onSpeechAnalyticsActiveChange,
}) => {
  const __getLabel = prop => {
    return customerOrder[prop]
      ? intl.formatMessage({ id: 'msg_yes_set_by_rqqid' }) + ' ' + customerOrder[prop]
      : intl.formatMessage({ id: 'msg_no' });
  };

  return (
    <div>
      <LabelField
        id="existingSangomaCxCustomer"
        label="msg_existing_sangoma_cx_customer"
        value={
          customerOrder.existingSangomaCXCustomer ? translator.getMessage('msg_yes') : translator.getMessage('msg_no')
        }
        textRight
      />
      <SelectControl
        id="userTypeSelect"
        label="msg_user_type"
        key="userTypeSelect"
        lockedState={lockedState}
        disabledState={userTypeSelectDisabled}
        value={customerOrder.sangomaCXUserType}
        options={SANGOMA_CX_USER_TYPE_DROPDOWN_OPTIONS}
        onChange={onUserSelectChange}
      />
      <LabelField
        id="optionalDataExportCustomer"
        label="msg_optional_data_export_customer"
        value={__getLabel('sangomaCXDataExportFromQuoteId')}
        textRight
      />
      {showSangomaCxStandalone && (
        <>
          <LabelField
            id="sangomaCXStandalone"
            label="msg_sangoma_cx_standalone"
            value={translator.getMessage('msg_yes')}
            textRight
          />
          <ToggleControl
            id="sangomaCXConcurrency"
            label="msg_sangoma_cx_concurrency"
            value={customerOrder.sangomaCXConcurrency}
            onChange={onConcurrencyChange}
            lockedState={lockedState}
            disabledState={sangomaCXConcurrencyDisabled}
          />
        </>
      )}
      {showSpeechAnalyticsActiveToggle && (
        <ToggleControl
          id="speechAnalyticsActive"
          label="msg_speech_analytics_active"
          value={customerOrder.speechAnalyticsActive}
          onChange={onSpeechAnalyticsActiveChange}
          lockedState={lockedState}
          disabledState={speechAnalyticsActiveToggleDisabled}
        />
      )}
    </div>
  );
};

export default injectIntl(SangomaCxSettings);
