import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import BadgeButton from '../BadgeButton';
import PdfHiddenForm from '../PdfHiddenForm';

class RentalFundingInvoice extends Component {
  static propTypes = {
    disabledState: PropTypes.bool,
    rentalFundingInvoiceEndpoint: PropTypes.string,
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    errorsCnt: PropTypes.number,
  };

  render() {
    const { disabledState, errors, errorsCnt, rentalFundingInvoiceEndpoint } = this.props;

    return (
      <>
        <BadgeButton
          disabled={disabledState || Boolean(errorsCnt)}
          label={translator.getMessage('msg_rental_funding_invoice')}
          errors={errors}
          errorsCnt={errorsCnt}
          tooltipAlign="left"
          form="rental-funding-invoice-download-form"
          tooltipVerticalAlign={'top'}
        />
        <PdfHiddenForm id="rental-funding-invoice-download-form" endpoint={rentalFundingInvoiceEndpoint} />
      </>
    );
  }
}

export default RentalFundingInvoice;
