import React from 'react';
import translator from '../../common/translate';
import { LabelField, SelectControl, ToggleControl } from '../Controls';
import {
  ORDER_TYPES,
  SCHEDULE_TYPE_OPTIONS,
  WORKSPACE_TYPE_DROPDOWN_OPTIONS,
  SCHEDULE_TYPE_DROPDOWN_OPTIONS,
} from '../../common/enums';
import { AuthContext } from '../../Context';

const DaasSection = ({
  lockedState,
  disabledState,
  daasWorkspaceTypeDisabled,
  onDaasWorkspaceTypeChange,
  onDaasScheduleTypeChange,
  customerOrder: {
    daasScheduleTypeDisabled,
    existingDaasCustomer,
    orderType,
    daasWorkspaceType,
    daasScheduleType,
    exchangeMigrationToggle,
  },
}) => {
  const { hasSalesOpsPermissions } = AuthContext.model;
  const __getScheduleTypeOptions = () => {
    let options = SCHEDULE_TYPE_DROPDOWN_OPTIONS;

    if (
      orderType === ORDER_TYPES.ADD_ON ||
      (!hasSalesOpsPermissions && daasScheduleType !== SCHEDULE_TYPE_OPTIONS.MIXED)
    ) {
      options = options.filter(opt => opt.value !== SCHEDULE_TYPE_OPTIONS.MIXED);
    }

    return options;
  };

  return (
    <div>
      <LabelField
        id="existingDaasCustomer"
        label="msg_existing_daas_customer"
        value={existingDaasCustomer ? translator.getMessage('msg_yes') : translator.getMessage('msg_no')}
        textRight
      />
      <SelectControl
        id="workspaceTypeSelect"
        label="msg_workspace_type"
        key="workspaceTypeSelect"
        lockedState={lockedState}
        disabledState={disabledState || daasWorkspaceTypeDisabled}
        value={daasWorkspaceType}
        options={WORKSPACE_TYPE_DROPDOWN_OPTIONS}
        onChange={onDaasWorkspaceTypeChange}
      />
      <SelectControl
        id="scheduleTypeSelect"
        label="msg_schedule_type"
        key="scheduleTypeSelect"
        lockedState={lockedState}
        disabledState={disabledState || daasScheduleTypeDisabled}
        value={daasScheduleType}
        options={__getScheduleTypeOptions()}
        onChange={onDaasScheduleTypeChange}
      />
      <ToggleControl
        {...exchangeMigrationToggle}
        id="onPremisesExchangeMigrationRequired"
        key="onPremisesExchangeMigrationRequired"
        disabledState={exchangeMigrationToggle.disabled}
        lockedState={exchangeMigrationToggle.locked}
      />
    </div>
  );
};

export default DaasSection;
