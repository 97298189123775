export const FINANCING_OPTIONS = {
  ALL_INCLUSIVE_STAR2STAR: 'ALL_INCLUSIVE_STAR2STAR',
  RENTAL_GREATAMERICA: 'RENTAL_GREATAMERICA',
};

export const FINANCING_DROPDOWN_OPTIONS = [
  {
    key: FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR,
    value: FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR,
    text: 'msg_all_inclusive',
  },
  {
    key: FINANCING_OPTIONS.RENTAL_GREATAMERICA,
    value: FINANCING_OPTIONS.RENTAL_GREATAMERICA,
    text: 'msg_rental_great_america',
  },
];
