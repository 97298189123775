import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import bindMultiple from '../../../common/helpers/bindMultiple';
import AbstractControl from '../AbstractControl';
import CalendarToolbar from './nested/CalendarToolbar';
import Calendar from './nested/Calendar';
import InputField from './nested/InputField';
import './css/date-picker-input.css';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import weekday from 'dayjs/plugin/weekday';
import { dayjsRange } from '../../../common/helpers/dayjsRange';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);

window.dayjs = dayjs;

const CALENDAR_CLOSE_DELAY = 500;

class DatePickerControl extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onUnsetDate: PropTypes.func,
    value: PropTypes.number,
    minDate: PropTypes.number,
    openInDate: PropTypes.number,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    hintText: PropTypes.string,
    errorStyleDates: PropTypes.bool,
    ariaLabel: PropTypes.string,
    timezone: PropTypes.string,
  };

  static defaultProps = {
    timezone: dayjs.tz.guess(),
  };

  minDate = null;
  inputValue = null;
  calendarCloseTimeout = null;
  preventDocumentClickClose = false;

  constructor(props) {
    super(props);

    this.state = {
      calendarInfo: null,
      isOpen: false,
      doFocusOnCalendar: false,
    };

    bindMultiple(
      this,
      this.handleDateSetByClick,
      this.handleDocumentClick,
      this.handleOnClickCapture,
      this.handleOnFocusCapture,
      this.handleOnKeyUp,
      this.handleOnUnsetDate,
      this.handleInputBlur,
      this.handleInputCancel,
      this.handleInputFocus,
      this.handleInputSet,
      this.handleInputSwitch,
      this.nextMonth,
      this.preselectDate,
      this.prevMonth,
      this.handleTodayClick,
      this.handleCalendarBlur
    );
  }

  componentDidMount() {
    const { value, timezone } = this.props;
    let date = value ? dayjs.utc(value).tz(timezone) : dayjs.utc().tz(timezone);
    this.getDays(date);

    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  getDays(_date, preselectedDay = null) {
    const { timezone, minDate } = this.props;
    const date = _date ? dayjs.utc(_date).tz(timezone) : dayjs.utc().tz(timezone);
    const today = dayjs.utc().tz(timezone);
    const value = dayjs.utc(this.props.value).tz(timezone);

    this.minDate =
      dayjs.utc(minDate).tz(timezone).startOf('day').valueOf() || dayjs.utc().tz(timezone).startOf('day').valueOf();

    const current = {
      day: date.date(),
      month: date.month(),
      year: date.year(),
    };
    const start = date.clone().startOf('month').weekday(0);
    const end = date.clone().endOf('month').weekday(6);
    const days = [];
    const range = dayjsRange(start, end);
    const _preselected = dayjs(preselectedDay) || null;
    let preselected = null;

    if (_preselected) {
      preselected = {
        day: _preselected.date(),
        month: _preselected.month(),
        year: _preselected.year(),
      };
    }

    for (let i = 0; i < range.length; i++) {
      const day = range[i];

      days.push({
        label: day.format('D'),
        disabled: day.isBefore(this.minDate, 'day'),
        selected: day.date() === value.date() && day.month() === value.month() && day.year() === value.year(),
        preselected:
          preselected &&
          day.date() === preselected.day &&
          day.month() === preselected.month &&
          day.year() === preselected.year,
        isMonthBefore: (day.month() < current.month && day.year() <= current.year) || day.year() < current.year,
        isMonthAfter: day.month() > current.month || day.year() > current.year,
        isToday: day.date() === today.date() && day.month() === today.month() && day.year() === today.year(),
        value: day.valueOf(),
        weekDay: day.day(),
      });
    }

    this.setState({
      calendarInfo: {
        title: date.endOf('months').format('MMMM') + ' ' + date.year(),
        visibleDate: date,
        daysList: days,
        value: value,
      },
    });
  }

  handleOnFocusCapture() {
    this.calendarCloseClearTimeout();
  }

  handleOnClickCapture() {
    this.preventDocumentClickClose = true;
    this.calendarCloseClearTimeout();
  }

  handleInputFocus() {
    this.calendarOpen();
  }

  handleInputBlur(timestamp) {
    this.inputValue = timestamp;

    this.calendarClose(() => {
      this.setValue(timestamp);
    }, CALENDAR_CLOSE_DELAY);
  }

  handleInputSet(timestamp) {
    this.setValue(timestamp);
    this.calendarClose();
  }

  handleInputCancel() {
    const date = dayjs(this.props.value);
    const timestamp = dayjs.isDayjs(date) && date.isValid() ? date.valueOf() : null;

    this.setValue(timestamp);
    this.calendarClose();
  }

  handleInputSwitch(timestamp) {
    const preselectTimestamp = timestamp > this.minDate ? timestamp : this.minDate;

    this.calendarCloseClearTimeout();
    this.preselectDate(preselectTimestamp);

    this.setState(
      {
        doFocusOnCalendar: true,
      },
      () => {
        this.setState({
          doFocusOnCalendar: false,
        });
      }
    );
  }

  handleDocumentClick() {
    if (this.preventDocumentClickClose) {
      this.preventDocumentClickClose = false;
    } else if (this.state.isOpen) {
      this.setValue(this.inputValue);
      this.calendarClose();
    }
  }

  render() {
    const { calendarInfo } = this.state;
    const toolbarTitle = (calendarInfo && calendarInfo.title) || '';

    const {
      id,
      lockedState,
      disabledState,
      className,
      value,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      parentClassName,
      errorStyleDates,
    } = this.props;

    const inputAbstractProps = {
      hint,
      style,
      label,
      focus,
      errorText,
      required,
      requiredBlue,
      disabledState,
      tooltipText,
      errorStyleDates,
      parentClassName,
      className: classNames(
        {
          'date-picker-component': true,
          open: this.state.isOpen,
        },
        className
      ),
      labelFor: id,
    };

    const timezone = this.props.timezone || dayjs.tz.guess();

    return (
      <AbstractControl
        {...inputAbstractProps}
        containerProps={{
          onClickCapture: this.handleOnClickCapture,
        }}
      >
        <React.Fragment>
          <InputField
            id={id}
            value={value}
            timezone={timezone}
            onFocus={this.handleInputFocus}
            onBlur={this.handleInputBlur}
            onSet={this.handleInputSet}
            onCancel={this.handleInputCancel}
            onSwitch={this.handleInputSwitch}
            disabled={lockedState || disabledState}
          />
          <div className="icon-calendar" />
          <button className="unset-btn" onClick={this.handleOnUnsetDate}>
            <div className="icon-close" />
          </button>
          <div
            className="calendar-wrapper"
            onFocusCapture={this.handleOnFocusCapture}
            onBlur={this.handleCalendarBlur}
            onKeyUp={this.handleOnKeyUp}
          >
            <CalendarToolbar
              displayDate={toolbarTitle}
              onNext={this.nextMonth}
              onPrev={this.prevMonth}
              onToday={this.handleTodayClick}
            />
            <Calendar
              calendarInfo={calendarInfo}
              onDayClick={this.handleDateSetByClick}
              doFocus={this.state.doFocusOnCalendar}
            />
          </div>
        </React.Fragment>
      </AbstractControl>
    );
  }

  handleCalendarBlur(event) {
    if (!event.relatedTarget?.closest('.calendar-wrapper')) {
      this.calendarClose();
    }
  }

  preselectDate(date) {
    this.getDays(date, date);
  }

  handleDateSetByClick(value) {
    this.props.onChange(value);
    this.calendarClose();
  }

  handleOnUnsetDate() {
    if (typeof this.props.onUnsetDate === 'function') {
      this.props.onUnsetDate();
    } else {
      this.setValue(null);
    }

    this.calendarClose();
  }

  handleOnKeyUp(e) {
    if (e.key === 'Escape') {
      this.calendarClose();
    }
  }

  nextMonth() {
    this.getDays(this.state.calendarInfo.visibleDate.clone().add(1, 'months'));
  }

  prevMonth() {
    this.getDays(this.state.calendarInfo.visibleDate.clone().subtract(1, 'months'));
  }

  setValue(timestamp) {
    if (timestamp >= this.minDate || timestamp === null) {
      this.props.onChange(timestamp);
    }
  }

  calendarOpen() {
    if (!this.state.isOpen) {
      const { timezone, value, minDate } = this.props;
      let date = null;

      if (value) {
        date = dayjs.utc(value).tz(timezone);
      } else {
        date = minDate ? dayjs.utc(minDate).tz(timezone) : dayjs.utc().tz(timezone);
      }

      this.getDays(date);

      this.setState({ isOpen: true });
    }
  }

  calendarClose(callback, delay = 0) {
    this.calendarCloseClearTimeout();

    if (delay) {
      this.calendarCloseTimeout = setTimeout(this.calendarCloseAction.bind(this, callback), delay);
    } else {
      this.calendarCloseAction(callback);
    }
  }

  calendarCloseClearTimeout() {
    if (this.calendarCloseTimeout !== null) {
      clearTimeout(this.calendarCloseTimeout);
    }
  }

  calendarCloseAction(callback) {
    this.setState({
      isOpen: false,
    });

    if (typeof callback === 'function') {
      callback();
    }
  }

  handleTodayClick() {
    this.preselectDate(dayjs(), dayjs());
  }
}

export default DatePickerControl;
