import AbstractContactModel from './AbstractContactModel';
import EscapeRegExp from '../../common/helpers/EscapeRegExp';

const FORBIDDEN_CHARACTERS = '|';

class ShippingInfoModel extends AbstractContactModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    this._companyName = null;
  }

  __getCleanValue(value) {
    const regex = new RegExp(`[${EscapeRegExp(FORBIDDEN_CHARACTERS)}]`, 'gi');

    return typeof value === 'string' ? value.replace(regex, '') : value;
  }

  _synchronizeWithCloneModel() {
    if (!super._synchronizeWithCloneModel()) {
      return false;
    }

    this.companyName = this._quotesList.masterOrder.customerName;

    return true;
  }

  _getClonedValueOrProtectedProp(propName) {
    return this.__getCleanValue(this.cloneFromModel ? this.cloneFromModel[propName] : this['_' + propName]);
  }

  get companyName() {
    if (!this.cloneFromModel) {
      return this.__getCleanValue(this._companyName);
    }

    return this.cloneFromModel.companyName
      ? this._getClonedValueOrProtectedProp('companyName')
      : this.__getCleanValue(this._quotesList.masterOrder.customerName);
  }

  set companyName(value) {
    this._companyName = value;
  }
}

export default ShippingInfoModel;
