import { ModelAbstract } from '../Models';
import config from '../config.js';

class ContextModel extends ModelAbstract {
  _listeners = [];
  _contextProviderComponent = null;
  providerMounted = false;

  constructor() {
    super(null);

    if (config.showConsoleLog) {
      console.log(this.className + '::constructor', this.uuid);
    }
  }

  linkContext(contextProviderComponent) {
    this._contextProviderComponent = contextProviderComponent;
  }

  updateProviderValue() {
    if (this.providerMounted) {
      this._contextProviderComponent.setState({
        value: this.toJS(),
      });
    }
  }

  broadcast(forceRender = false) {
    this._callBroadcastEvents(forceRender);
  }

  addBroadcastListener(callback) {
    // Execute callback to ensure it does NOT meet requirement and has to be listened further
    const callbackFinalized = callback();

    if (callbackFinalized) {
      return null;
    } else {
      this._listeners.push(callback);

      return () => {
        this._listeners = this._listeners.filter(d => d !== callback);
      };
    }
  }

  _callBroadcastEvents(forceRender = false) {
    let triggerRender = false;
    const preservedListenerIds = [];

    for (let i = 0; i < this._listeners.length; i++) {
      const callback = this._listeners[i];

      const isLastCall = callback();

      if (isLastCall) {
        triggerRender = true;
      } else {
        preservedListenerIds.push(i);
      }
    }

    if (triggerRender || forceRender) {
      this.updateProviderValue();
    }

    this._listeners = this._listeners.filter((fn, i) => preservedListenerIds.indexOf(i) !== -1);
  }

  setProps(data) {
    super.setProps(data);
    this.broadcast(true);

    return this;
  }

  forceRender() {
    this.broadcast(true);

    return true;
  }
}

export default ContextModel;
