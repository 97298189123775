import AbstractContactModel from './AbstractContactModel';
import { LocationQuoteModel, QuoteModel } from '../index';

class ServiceInfoModel extends AbstractContactModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    this._locationName = 'Master Order';
    this._contactOfficePhone = null;
    this._contactMobilePhone = null;
    this._contactEmail = null;
    this._btn = null;
    this._industryCodeId = 1;
  }

  _synchronizeWithCloneModel() {
    if (!super._synchronizeWithCloneModel()) {
      return false;
    }

    this.contactOfficePhone = this.cloneFromModel.contactOfficePhone;
    this.contactMobilePhone = this.cloneFromModel.contactMobilePhone;
    this.contactEmail = this.cloneFromModel.contactEmail;
    this.btn = this.cloneFromModel.btn;

    return true;
  }

  get locationName() {
    return this._locationName;
  }

  set locationName(value) {
    this._locationName = value;
  }

  get contactOfficePhone() {
    return this._getClonedValueOrProtectedProp('contactOfficePhone');
  }

  set contactOfficePhone(value) {
    this._contactOfficePhone = value;
  }

  get contactMobilePhone() {
    return this._getClonedValueOrProtectedProp('contactMobilePhone');
  }

  set contactMobilePhone(value) {
    this._contactMobilePhone = value;
  }

  get contactEmail() {
    return this._getClonedValueOrProtectedProp('contactEmail');
  }

  set contactEmail(value) {
    this._contactEmail = value;
  }

  get btn() {
    return this._getClonedValueOrProtectedProp('btn');
  }

  set btn(value) {
    this._btn = value;
  }

  get isInLocationQuoteModel() {
    return this.findSibling(d => d instanceof QuoteModel) instanceof LocationQuoteModel;
  }

  get industryCodeId() {
    // return 1('Other') for locations as per KM-13614
    if (this.isInLocationQuoteModel) {
      return 1;
    }

    return this._industryCodeId;
  }

  set industryCodeId(v) {
    this._industryCodeId = v;
  }
}

export default ServiceInfoModel;
