// TODO: Replace this file by CSS implementation
export default function getStatusStyles(isUIStatusActive, statusUI, statusBE) {
  let backgroundColor = statusBE ? statusBE.color : 'var(--color-silver-chalice)';

  if (isUIStatusActive) {
    backgroundColor = 'white';
  }

  return {
    background: backgroundColor,
    borderStyle: isUIStatusActive ? 'solid' : 'none',
    borderWidth: 'var(--size-half-pt)',
    borderColor: isUIStatusActive ? statusUI.color : 'var(--color-silver-chalice)',
  };
}
