import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { SOLUTION_TYPES, USER_ROLES } from '../../../common/enums';
import { AuthContext } from '../../../Context';
import PureViewComponent from '../../PureViewComponent';
import { CELL_TYPES } from '../enums';
import '../css/line-item.css';
import LineItemCell from './LineItemCell';

class LineItem extends PureViewComponent {
  static propTypes = {
    uuid: PropTypes.string.isRequired,
    cells: PropTypes.arrayOf(PropTypes.string),
    cellTypes: PropTypes.object,
    active: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    removable: PropTypes.bool,
    onChange: PropTypes.func,
    onRemove: PropTypes.func,
    hasNegativeQuantityChild: PropTypes.bool,
    getFlagValue: PropTypes.func,
    quantityLessThanAllocatedFinalized: PropTypes.bool,
    allocatedFinalized: PropTypes.number,
    invalidSolutionType: PropTypes.bool,
    showSolutionTypePrefix: PropTypes.bool,
    isTagApplicable: PropTypes.func,
    existing: PropTypes.bool,
    isAllowedToAllocateOnConditionAtLocationValid: PropTypes.bool,
    isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable: PropTypes.bool,
    quantityWithOverride: PropTypes.number,
    applicableSolutionTypes: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    active: true,
    lockedState: false,
    disabledState: false,
    removable: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      mouseOver: false,
    };

    this.handleRemove = this.handleRemove.bind(this);
  }

  handleRemove() {
    if (typeof this.props.onRemove === 'function') {
      this.props.onRemove();
    }
  }

  renderCells(props) {
    const { columns, getFlagValue, isTagApplicable, intl, existing, applicableSolutionTypes } = props;

    return props.cells.map(alias => {
      const type = props.cellTypes[alias] || CELL_TYPES.label;
      const key = props.uuid + '_' + alias;
      const lockedState = !props.active || props.lockedState;
      const id = 'line-item_' + alias + (lockedState ? '_lockedState' : '') + '_' + props.uuid;
      const columnHeader = (columns.find(c => c.alias === alias) || {}).header;
      let value = this.props[alias];

      // Set default tooltiop as a column header
      let title = columnHeader ? intl.formatMessage({ id: columnHeader }) : null;
      const ariaLabel = title;

      if (type === 'label' && ['quantity', 'totalQuantity', 'available', 'allocated'].includes(alias)) {
        value = props[alias].toLocaleString('en-US');
      }

      const disableOverride = getFlagValue('disableOverride') === 'TRUE';
      const disableOverrideQuantity = getFlagValue('disableOverrideQuantity') === 'TRUE';
      const disableMarkup = getFlagValue('disableMarkup') === 'TRUE';
      const hideCount = getFlagValue('hideCount') === 'TRUE';
      const hideUnitPrice = getFlagValue('hideUnitPrice') === 'TRUE';
      const hideTotalPrice = getFlagValue('hideTotalPrice') === 'TRUE';

      if (
        (disableOverride && alias === 'overrideValue') ||
        (disableMarkup && ['markupValue', 'totalMarkup'].includes(alias)) ||
        (hideCount && ['quantity', 'totalQuantity', 'available', 'allocated'].includes(alias)) ||
        (hideUnitPrice && ['dealerNet', 'price', 'quotePrice'].includes(alias)) ||
        (hideTotalPrice && alias === 'total') ||
        (alias === 'overrideQuantity' &&
          value === null &&
          (disableOverrideQuantity ||
            hideCount ||
            [CELL_TYPES.number, CELL_TYPES.numberForceDisabled].includes(this.props.cellTypes.quantity)))
      ) {
        return <div id={id} key={key} className={['cell', 'empty', alias].join(' ')} />;
      }

      let hasError = false;

      if (alias === 'quantity') {
        if (this.props.quantityLessThanAllocatedFinalized) {
          hasError = true;
        }

        if (AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) {
          value = this.props.quantityWithOverride;
        }
      }

      // Display tooltips for line item name and partner line items description
      if (['lineItemName', 'partnerProductName'].includes(alias)) {
        if (existing) {
          value = intl.formatMessage({ id: 'msg_existing_line_item_prefix' }) + ' - ' + value;
        }

        if (alias === 'lineItemName' && this.props.showSolutionTypePrefix) {
          const includesEitherBvOrBvPlusOnly =
            applicableSolutionTypes.length === 1 &&
            (applicableSolutionTypes.includes(SOLUTION_TYPES.BUSINESS_VOICE) ||
              applicableSolutionTypes.includes(SOLUTION_TYPES.BUSINESS_VOICE_PLUS));

          if (includesEitherBvOrBvPlusOnly) {
            value = { 1: '[BV]', 2: '[BV+]' }[applicableSolutionTypes[0]] + ' ' + value;
          }
        }

        title = value;
      }

      if (alias === 'allocated' && this.props.allocatedFinalized) {
        title = intl.formatMessage({ id: 'msg_finalized_items_count_tooltip' }) + this.props.allocatedFinalized;
      }

      return (
        <LineItemCell
          id={id}
          key={key}
          type={type}
          alias={alias}
          value={value}
          title={title}
          onChange={props.onChange}
          isFocused={props.doFocus && props.focusField === alias}
          lockedState={lockedState}
          disabledState={isTagApplicable('readOnlyIfCannotAdd') || props.disabledState}
          numberValueType={props.numberValueType}
          amountValueType={props.amountValueType}
          onLineItemMounted={props.onLineItemMounted}
          hasError={hasError}
          pdfPath={props.pdfPath}
          ariaLabel={ariaLabel}
        />
      );
    });
  }

  render() {
    const { uuid, active, lockedState, disabledState, removable, hasNegativeQuantityChild, getFlagValue, recurring } =
      this.props;
    const classNames = ['line-item'];

    // KM-3797
    // Mark as inactive only unlocked line items
    // Locked means quote is submitted and can not be edited
    // No need to mark outdated line items on locked quote as it will not change
    classNames.push(!active && !lockedState ? 'inactive' : 'active');

    classNames.push(recurring ? 'recurring' : 'non-recurring');

    let notes = '';
    let notesType = 'error';

    if (hasNegativeQuantityChild) {
      notes = getFlagValue('stopIfHasChildWithNegativeCount');
    }

    if (
      this.props.invalidSolutionType ||
      // isAllowedToAllocateOnConditionAtLocationValid shall be strictly compared to false
      // because this value is undefined on MasterOrder level and those components are shared
      (this.props.isAllowedToAllocateOnConditionAtLocationValid === false && this.props.quantityWithOverride) ||
      this.props.isFlagInvalidateItemsNotAllowedToAddOnConditionApplicable
    ) {
      classNames.push('has-error');
    }

    const deleteButtonTitle = [
      this.props.intl.formatMessage({ id: 'msg_delete' }),
      this.props.partnerProductName || this.props.lineItemName,
      this.props.intl.formatMessage({ id: 'msg_line_item' }),
    ].join(' ');

    return [
      <div key={'line-item-cells'} className={classNames.join(' ')}>
        {this.renderCells(this.props)}
        {removable && !lockedState && (
          <div className="remove-trigger-wrapper">
            <button
              id={'line-item_delete-button_' + uuid}
              className="icon-delete delete-button no-bg"
              onClick={this.handleRemove}
              disabled={disabledState}
              title={deleteButtonTitle}
              aria-label={deleteButtonTitle}
            />
          </div>
        )}
      </div>,
      notes ? (
        <div key={'notes'} className={['line-item-notes', notesType].join(' ')}>
          {notes}
        </div>
      ) : null,
    ];
  }
}

export default injectIntl(LineItem);
