import { shape, bool, string, number, arrayOf, oneOfType } from 'prop-types';

export const buttonPropTypes = shape({
  visible: bool,
  disabled: bool,
  label: string,
  errors: oneOfType([arrayOf(string), string]),
  errorsCnt: number,
  infoTooltip: string,
});
