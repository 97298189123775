import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HistoryRow from './HistoryRow';
import { AuthContext } from '../../../Context';
import translator from '../../../common/translate';
import { SortArrayOfObjects } from '../../../common/helpers/SortArrayOfObjects';
import { SIGNER_STATUS } from '../../../common/enums';

class HistoryTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    onClickCancel: PropTypes.func,
    getPreviewUrlAndValues: PropTypes.func,
    onClickDetails: PropTypes.func,
    onClickReSubmit: PropTypes.func,
    onClickVoid: PropTypes.func,
    getSignatureDownloadEndpoint: PropTypes.func,
    cancelDisabled: PropTypes.bool,
    reSubmitAllowed: PropTypes.bool,
    allowToVoid: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    quoteStatus: PropTypes.string,
    isExistingAls: PropTypes.bool,
    availableEnvelopeTypes: PropTypes.array,
  };

  constructor(props) {
    super(props);

    this.state = {
      sortField: 'lastModified',
      sortDirection: 'desc',
    };

    this.mapData = this.mapData.bind(this);
  }

  handleSort(field) {
    if (this.state.sortField === field) {
      const direction = this.state.sortDirection === 'asc' ? 'desc' : 'asc';

      this.setState({
        sortDirection: direction,
      });
    } else {
      this.setState({
        sortField: field,
      });
    }
  }

  pickCurrentSigner(signers) {
    // Force reorder signers by signerOrder but don't blindly rely on API response order
    signers.sort((a, b) => a.signerOrder - b.signerOrder);

    if (!signers.length) {
      return null;
    }

    // Pick very first signer that does not signed document yet
    let signer = signers.find(d => d.signedOn === null);

    // Else
    if (!signer) {
      // Pick very last signer that has signed the document
      signer = signers[signers.length - 1];
    }

    return signer;
  }

  mapData(data) {
    const customerSigners = data.eSigners.filter(d => d.s2sSigner === false);
    const counterSigners = data.eSigners.filter(d => d.s2sSigner === true);

    data.allSigned = data.eSigners.every(d => d.status === SIGNER_STATUS.SIGNED);

    data.customerSigner = this.pickCurrentSigner(customerSigners);
    data.customerSignerName = data.customerSigner ? data.customerSigner.name : '';

    data.counterSigner = this.pickCurrentSigner(counterSigners);
    data.counterSignerName = data.counterSigner ? data.counterSigner.name : '';

    // Collect first letter of each document type. It is enough for sorting purposes
    data.contentsSortString = data.eSignatureDocuments.map(d => d.documentType[0]).join('');

    return data;
  }

  renderHeaderCell(label, className, isSortable, sortField) {
    let onClick = null;
    const classNames = [className];

    if (isSortable) {
      classNames.push('sortable');
      onClick = () => this.handleSort(sortField);
    }

    if (this.state.sortField === sortField) {
      classNames.push(this.state.sortDirection);
    }

    return (
      <th className={classNames.join(' ')} onClick={onClick}>
        {translator.getMessage(label)}
      </th>
    );
  }

  render() {
    const {
      onClickCancel,
      getPreviewUrlAndValues,
      onClickDetails,
      onClickReSubmit,
      onClickVoid,
      getSignatureDownloadEndpoint,
      cancelDisabled,
      reSubmitAllowed,
      allowToVoid,
      isSalesOps,
      quoteStatus,
      isExistingAls,
      availableEnvelopeTypes,
    } = this.props;

    const data = SortArrayOfObjects(this.props.data.map(this.mapData), this.state.sortField, this.state.sortDirection);

    return (
      <table id="e-sig-history-table" className="rq-table sticky signature-history-table">
        <thead>
          <tr>
            {this.renderHeaderCell('msg_esig_history_dialog_table_header_type', 'type', true, 'bundleType')}
            {this.renderHeaderCell(
              'msg_esig_history_dialog_table_header_contents',
              'contents',
              true,
              'contentsSortString'
            )}
            {this.renderHeaderCell(
              'msg_esig_history_dialog_table_header_customer_signer',
              'customer-signer',
              true,
              'customerSignerName'
            )}

            {AuthContext.model.hasInternalPermissions &&
              this.renderHeaderCell(
                'msg_esig_history_dialog_table_header_counter_signer',
                'counter-signer',
                true,
                'counterSignerName'
              )}

            {this.renderHeaderCell('msg_esig_history_dialog_table_header_sent', 'sent', true, 'creationDate')}
            {this.renderHeaderCell('msg_esig_history_dialog_table_header_updated', 'updated', true, 'lastModified')}
            {this.renderHeaderCell('msg_esig_history_dialog_table_header_status', 'status', true, 'status')}
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) => (
            <HistoryRow
              {...data}
              key={'signature-history-row-' + data.id}
              onClickCancel={onClickCancel}
              getPreviewUrlAndValues={getPreviewUrlAndValues}
              onClickDetails={onClickDetails}
              onClickReSubmit={onClickReSubmit}
              onClickVoid={onClickVoid}
              getSignatureDownloadEndpoint={getSignatureDownloadEndpoint}
              cancelDisabled={cancelDisabled}
              reSubmitAllowed={reSubmitAllowed}
              allowToVoid={allowToVoid}
              isSalesOps={isSalesOps}
              quoteStatus={quoteStatus}
              isExistingAls={isExistingAls}
              isEnvelopeAvailable={availableEnvelopeTypes.includes(data.bundleType)}
              isOdd={(index + 1) % 2 === 1}
            />
          ))}
        </tbody>
      </table>
    );
  }
}

export default HistoryTable;
