import * as formatter from '../../common/helpers/format';
import { ORDER_TYPES, PACKAGE_TYPE_IDS } from '../../common/enums';
import CommonPackageModel from './CommonPackageModel';
import { AuthContext } from '../../Context';

class PackageThreeModel extends CommonPackageModel {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this.id = PACKAGE_TYPE_IDS.PACKAGE_THREE;
    this.packageClassName = 'package-3';
  }

  get available() {
    const { locationPackageType, orderType } = this.customerOrder;

    if (orderType !== ORDER_TYPES.ADD_ON || !locationPackageType) {
      return true;
    }

    return (
      AuthContext.model.hasSalesOpsPermissions || [this.id, PACKAGE_TYPE_IDS.PACKAGE_ONE].includes(locationPackageType)
    );
  }

  get nrc() {
    return {
      className: this.animatedClassName,
      value: formatter.currency(this.summaryValues['proposalUpfront3'], 0),
    };
  }

  get rc() {
    return {
      className: this.animatedClassName,
      value: formatter.currency(this.summaryValues['proposalRecur3'], 0),
    };
  }
}

export default PackageThreeModel;
