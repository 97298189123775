const ERROR_TEXT_COLLECTION = {
  BILLING_DEALER_SHOULD_BE_SELECTED: 'msg_billing_dealer_should_be_selected',
  CPQ_BTN_TOO_SHORT: 'msg_business_telephone_number_should_contain_10_digits',
  CPQ_BTN_NEW_ONLY: 'msg_only_new_customer_allowed_in_rocket_quote_telephone_number',
  CPQ_EMAIL_TOO_SHORT: 'msg_contact_email_should_net_be_empty',
  CPQ_FIRST_NAME_TOO_SHORT: 'msg_contact_first_name_should_not_be_empty',
  CPQ_LAST_NAME_TOO_SHORT: 'msg_contact_last_name_should_not_be_empty',
  CPQ_LOCATION_NAME_TOO_SHORT: 'msg_location_name_should_not_be_empty',
  CPQ_PHONE_TOO_SHORT: 'msg_contact_phone_should_not_be_empty',
  SELECT_QUOTE_OWNER_NAME: 'msg_select_quote_owner_name',
  CUSTOMER_NAME_NEW_ONLY: 'msg_only_new_customer_allowed_in_rocket_quote',
  CUSTOMER_NAME_TOO_SHORT: 'msg_customer_name_is_too_short',
  DBA_TOO_SHORT: 'msg_dba_name_too_short',
  SELECT_CUSTOMER_NAME: 'msg_select_customer_name',
  CUSTOMER_TEXT_INPUT_DEFAULT_ERROR: 'msg_text_is_to_short',
  DEALER_CONTACT_NAME_SHOULD_BE_SELECTED: 'msg_dealer_name_should_be_selected',
  DEALER_CONTACT_NAME_SHOULD_BE_RESELECTED: 'msg_dealer_name_must_be_update_after_change_partner',
  DEVICES_LIST_TOO_SHORT: 'msg_should_be_at_least_one_device',
  SPIFF_NOT_CONFIRMED: 'msg_customer_discount_spiff_need_to_be_accepted',
  ICTP_PARTNER_TOO_SMALL: 'msg_partner_amount_should_be_positive',
  NUM_COURTESY_MORE_THAN_DEVICES: 'msg_courtesy_extensions_should_not_be_more_than_total_devices',
  NUM_COURTESY_MORE_THAN_DPHONES: 'msg_courtesy_extensions_should_not_be_more_than_total_desk_phones',
  NUM_LOCATIONS_TOO_SMALL: 'msg_location_should_be_more_than_0',
  PROCESSING: 'msg_processing',
  QUOTE_TITLE_TOO_SHORT: 'msg_enter_quote_title',
  SPIFF_DISCOUNT_EXCEED_RC: 'msg_applied_recurring_discount_exceeds_available_amount_across_locations',
  SPIFF_DISCOUNT_EXCEED_NRC: 'msg_applied_non_recurring_discount_exceeds_available_amount_across_locations',
  STARBOX_CCM_TOO_SMALL: 'msg_should_be_equal_to_0_or_more',
  TOTAL_DESK_PHONES_TOO_SMALL: 'msg_should_be_more_than_0',
  NUMBER_OF_LINES_INCORRECT: 'msg_number_of_lines_not_selected_correctly',
  AMOUNT_TO_HIGH: 'msg_amount_in_field_is_too_high',
  CUSTOMER_CONTACT_INFORMATION_ERROR: 'msg_you_have_error_in_customer_contact_information_section',
  INACTIVE_LINE_ITEMS: 'msg_remove_inactive_line_item',
  SPIFF_WARNING: 'msg_spiff_values_exceeded',
  LOCATION_NAME_NOT_UNIQUE: 'msg_location_name_must_be_unique',
  PARTNER_SHOULD_BE_SELECTED: 'msg_partner_should_be_selected',
  ACTION_SHOULD_BE_SELECTED: 'msg_action_should_be_selected',
  CUSTOMER_SHOULD_BE_SELECTED: 'msg_customer_should_be_selected',
  LOCATION_SHOULD_BE_SELECTED: 'msg_location_should_be_selected',
  AT_LEAST_ONE_LOCATION_SHOULD_BE_SELECTED: 'msg_at_least_location_should_be_selected',
  PROVIDE_RC_ITEM_DESCRIPTION: 'msg_define_rc_provide_item_description',
  PROVIDE_NRC_ITEM_DESCRIPTION: 'msg_define_nrc_provide_item_description',
  ICTPPS_BLANK_PARTNER_PRICE: 'msg_add_on_blank_ictp_partner',
  ICTPPS_CONFIRMATION_REQUIRED: 'msg_add_on_ictp_confirmation_required',
  FILL_LOCATION_NAME: 'msg_please_fill_in_location_name',
  LINE_ITEMS_HAS_NEGATIVE_QUANTITY: 'msg_some_of_line_items_has_negative_quantity',
  SAVE_BUTTON_SE_APPROVAL: 'msg_save_button_se_approval',
  COUNT_MIXED_WARNING: 'msg_unlimited_call_routing_includes_unlimited_call_queues',
  LIMIT_WARNING: 'msg_number_of_unlimited_call_routing_products',
  STARCLOUD_PLUS_REQUIRES_STARBOX: 'msg_business_voice_plus_requires_starbox',
  ALLOCATIONS_FOR_FINALIZED_MO: 'msg_allocations_for_finalized_mo',
  STARBOX_IS_REQUIRED: 'msg_number_of_starbox_devices_should_be_equal',
  LINE_ITEM_QUANTITY_LESS_THAN_ALLOCATED_FINALIZED: 'msg_line_item_quantity_less_than_allocated_finalized',
  CUSTOMER_INSTALL_STAR_PLUS: 'msg_customer_install_star_plus',
  SOLUTION_TYPE_MISMATCH: 'msg_solution_type_mismatch',
  SOLUTION_TYPE_MISMATCH_ADDONS: 'msg_solution_type_mismatch_addons',
  LOCATION_4G_REQUIRES_SDWAN: 'msg_location_4g_requires_sdwan',
  STAR_CLOUD_WITH_CUSTOMER_INSTALLATION: 'msg_customer_installation_is_not_allowed',
  MAD_POOL_OVER_ALLOCATED: 'msg_applied_total_discount_exceeds_available_amount',
  SPIFF_OVER_ALLOCATED: 'msg_applied_spiff_exceeds_available_amount',
  MAD_UP_FRONT_OVER_ALLOCATED: 'msg_applied_non_recurring_discount_exceeds_available_amount',
  MAD_RECUR_OVER_ALLOCATED: 'msg_applied_recurring_discount_exceeds_available_amount',
  LEGACY_LINE_ITEMS_LIMITS: 'msg_legacy_items_per_sub_category_limit_error',
  ONE_PER_GROUP: 'msg_one_item_can_be_selected',
  MULTIPLE_PROMOTION_ITEMS_ADDED: 'msg_multiple_promotions_added_error',
  NEGATIVE_NRC_AMOUNT: 'msg_negative_nrc_amount',
  STAR_FAX_DIGITAL_MIXED_WARNING: 'msg_star_fax_digital_mixed_warning',
  DBA_MUST_BE_NOT_EQUAL_CUSTOMER_NAME: 'msg_dba_should_be_different_as_customer_name',
  STAR_PHONE_EXTENSION_EXCEED_USERS_WARNING: 'msg_star_phone_extension_exceed_users_warning',
  PCI_COMPLIANT_LOCATION_WARNING: 'msg_pci_compliant_location_require',
  LOCATIONS_MUST_BE_UNFINALIZED_OR_ADDON: 'msg_location_must_be_unfinalized_or_addon',
  BC_USER_MO: 'msg_bc_user_mo',
  BC_USER_LOCATION: 'msg_bc_user_location',
  CUSTOM_LEASE_AMOUNT_MUST_BE_POSITIVE: 'msg_custom_lease_amount_must_be_positive',
  QUOTE_TOTALS_CANNOT_BE_NEGATIVE: 'msg_totals_cannot_be_negative',
  FILL_ALL_FIELDS_IN_ACTIVE_ADDENDUMS: 'msg_fill_all_fields_in_active_addendums',
  ALLOWED_TO_ALLOCATE_ON_CONDITION_AT_LOCATION_ERROR: 'msg_allowed_to_allocate_on_condition_at_location_flag_error',
  INVALIDATE_ITEMS_NOT_ALLOWED_TO_ADD_ON_CONDITION_ERROR:
    'msg_invalidate_items_not_allowed_to_add_on_condition_flag_error',
  WLW_PARTNER_TYPE: 'msg_work_w_sales_ops',
  IS_NEW_LOCATION_FINALIZED: 'msg_is_new_location_finalized',
  SIP_STATION_NOT_SUPPORTED_FOT_REWRITES: 'msg_sip_station_not_supported_for_rewrites',
  SWITCHVOX_OR_SIPSTATION_MUST_BE_PRESENT: 'msg_switchvox_or_sip_station_must_be_present',
  STANDALONE_LOCATION_MUST_BE_FINALIZED: 'msg_standalone_location_must_be_finalized',
  CONTACT_CENTER_CONCURRENCY_ITEM_MUST_BE_PRESENT: 'msg_contact_center_concurrency_item_must_be_present',
  SMART_OFFICE_GATEWAY_KIT_VALIDATION: 'msg_smart_office_gateway_kit_validation',
  SMART_OFFICE_GATEWAY_KIT_LOCATION_VALIDATION: 'msg_smart_office_gateway_kit_location_validation',
  SMART_OFFICE_ACCESS_DOOR_LICENSE: 'msg_smart_office_access_door_license_validation',
  SMART_OFFICE_ACCESS_DOOR_LOCATION_LICENSE: 'msg_smart_office_access_door_license_location_validation',
  SMART_OFFICE_LICENCE_OVERALLOCATION: 'msg_smart_office_licence_overallocation_warning',
  SWITCHVOX_APPLIANCE_VALIDATION: 'msg_switchvox_appliance_validation',
  STANDALONE_CPAAS_VALIDATION: 'msg_standalone_cpaas_validation',
  NUM_SANGOMA_CX_USERS_EXCEEDS_NUM_UCAAS_USERS: 'msg_num_sangoma_cx_users_exceeds_num_ucaas_users_error',
  SANGOMA_CX_USERS_REQUIRED: 'msg_sangoma_cx_users_required_error',
  SANGOMA_STANDALONE_ONLY_SINGLE_LOC: 'msg_sangoma_standalone_only_single_loc',
  SANGOMA_CX_CONCURRENCY_ITEMS_VALIDATION: 'msg_sangoma_cx_concurrency_items_validation',
  INDUSTRY_TYPE_SHOULD_BE_SELECTED: 'msg_industry_type_should_be_selected',
  CALCULATION_REQUIRED: 'msg_calculation_required_save_button_error_badge',
};

export default ERROR_TEXT_COLLECTION;
