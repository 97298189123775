import ToolbarButtonModel from './ToolbarButtonModel';

class SlexModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_approvals';
  }

  get disabled() {
    return this.isUnsavedMasterOrder || super.disabled;
  }

  get errors() {
    return this.isUnsavedMasterOrder ? 'msg_save_before_continue' : '';
  }

  get errorsCnt() {
    return Number(this.isUnsavedMasterOrder);
  }

  get isUnsavedMasterOrder() {
    return this.masterOrder.id === null;
  }
}

export default SlexModel;
