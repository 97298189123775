import React, { forwardRef } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import { AuthContext, IntlContext } from '../../../../../Context';
import { quotePropType } from '../propTypes';
import '../styles/quotes-table-wrapper.css';
import headerItems from '../headerItems';
import HeaderCell from './HeaderCell';
import TableRow from './TableRow';
import NoResults from './NoResults';
import classNames from 'classnames';

const Table = forwardRef(
  (
    {
      intl,
      isLoading = false,
      noResultsMessage = 'msg_no_quotes_created_yet',
      onChangeSort,
      quotes = [],
      sortAsc = false,
      sortBy = '',
    },
    { headerRef, rowRef }
  ) => {
    const formatMessage = intl.formatMessage;
    const { hasInternalPermissions, isAgent } = AuthContext.model;
    const tableWrapperClassName = classNames({
      'quotes-table-wrapper': true,
      spanish: !IntlContext.model.isEnglishLocale(),
    });

    return (
      <div className={tableWrapperClassName}>
        <table className="quotes-list rq-table striped valign-middle hover">
          <thead ref={headerRef}>
            <tr>
              {headerItems.map(({ className, id, hoverLabel, label, s2sUsersOnly }) => (
                <HeaderCell
                  className={className}
                  formatMessage={formatMessage}
                  hoverLabel={hoverLabel}
                  key={label}
                  id={id}
                  label={label}
                  onChangeSort={onChangeSort}
                  sortAsc={sortAsc}
                  sortBy={sortBy}
                  visible={!s2sUsersOnly || hasInternalPermissions}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {!!quotes.length && (
              <TableRow
                intl={intl}
                isAgent={isAgent}
                hasInternalPermissions={hasInternalPermissions}
                quote={quotes[0]}
                ref={rowRef}
              />
            )}
            {quotes.slice(1).map(quote => (
              <TableRow
                intl={intl}
                isAgent={isAgent}
                hasInternalPermissions={hasInternalPermissions}
                key={quote.id}
                quote={quote}
              />
            ))}
            <NoResults message={noResultsMessage} visible={!quotes.length && !isLoading} />
          </tbody>
        </table>
      </div>
    );
  }
);

Table.propTypes = {
  isLoading: bool,
  noResultsMessage: string,
  onChangeSort: func.isRequired,
  quotes: arrayOf(quotePropType),
  sortAsc: bool,
  sortBy: string,
  intl: object,
};

export default Table;
