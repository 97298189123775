import { ModelAbstract, ProvidersGroupModel, CustomerOrderModel } from '../';
import { QUOTE_STATUS } from '../../common/enums/quoteStatusEnums.js';

class ProviderModel extends ModelAbstract {
  constructor(instance) {
    super(instance);

    this._options = [];

    this.isAgent = instance._isAgent;
    this.locked = false;
    this.subCategoryId = 0;

    this.onChange = this.onChange.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onChange(prop, value) {
    const providerGroup = this.findSibling(d => d instanceof ProvidersGroupModel);
    const MO = this.findSibling(d => d instanceof CustomerOrderModel);

    if (this.selectedOption.canSet(prop)) {
      if (MO && MO.quoteStatus === QUOTE_STATUS.UNFINALIZED && value < this.selectedOption.allocatedFinalized) {
        this.selectedOption[prop] = this.selectedOption.allocatedFinalized;

        this.modelChanged(this);
      } else {
        this.selectedOption[prop] = value;

        if (this.selectedOption.isPartner && value === 0 && providerGroup.isConfirmed) {
          providerGroup.isConfirmed = false;
        }

        this.modelChanged(this);
      }
    }
  }

  selectOption(lineItemId, triggerModelChange = true) {
    const providerGroup = this.findSibling(d => d instanceof ProvidersGroupModel);

    for (let i = 0; i < this._options.length; i++) {
      /** @type {IctpLineItemModel} */
      const lineItem = this._options[i];

      lineItem.quantity = Number(lineItem.lineItemId === lineItemId);
    }

    if (this.selectedOption.isPartner && this.selectedOption.price === 0 && providerGroup.isConfirmed) {
      providerGroup.isConfirmed = false;
    }

    if (triggerModelChange) {
      this.modelChanged(this);
    }
  }

  get selectedOption() {
    return this._options.find(lineItem => lineItem.quantity) || false;
  }

  get options() {
    return this._options.filter(
      /** @type {IctpLineItemModel} */
      lineItem => {
        return lineItem.allowedToAdd || lineItem.quantity;
      }
    );
  }

  optionPush(lineItem) {
    this._options.push(lineItem);
    this._options.sort((a, b) => b.sequence - a.sequence);
  }

  get label() {
    return !!this.selectedOption ? this.selectedOption.lineItemName : '';
  }
}

export default ProviderModel;
