import { QUOTE_STATUS_TRANSLATE_CODES, UI_QUOTE_STATUS_TRANSLATE_CODES } from '../enums';
import translator from '../translate';
import { UpperCaseEachWord } from './format';

/**
 * Returns translation for quote status code
 *
 * @param quoteStatus
 * @param intl prop from injectIntl
 * @returns {string|object} If no matching code found for quote status, string will be returned by wrapping status string with UpperCaseEachWord function. If intl prop available raw String will be returned or React component otherwise.
 */
export function getQuoteStatusTranslatedString(quoteStatus, intl = null) {
  if (!quoteStatus) {
    return '';
  }

  const translateCode = QUOTE_STATUS_TRANSLATE_CODES[quoteStatus] || UI_QUOTE_STATUS_TRANSLATE_CODES[quoteStatus];

  if (!translateCode) {
    return UpperCaseEachWord(quoteStatus, '_');
  }

  if (intl) {
    return intl.formatMessage({ id: translateCode });
  }

  return translator.getMessage(translateCode);
}
