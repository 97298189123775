import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import formatter from '../../../common/helpers/format/formatter';
import NumberInput from '../NumberInput';

class AmountInput extends PureComponent {
  static propTypes = {
    ...NumberInput.propTypes,
    marker: PropTypes.string,
  };

  static defaultProps = {
    className: 'amount-input-field',
    minValue: 0,
    maxValue: 9999999.0,
    marker: '$',
    decimals: 2,
  };

  constructor(props) {
    super(props);

    this.handleBeforeStateChange = this.handleBeforeStateChange.bind(this);
    this.formatValue = this.formatValue.bind(this);
  }

  formatValue(value) {
    if (this.props.allowNullValue && ['', null].includes(value)) {
      return '';
    }

    return formatter(value, this.props.decimals).replace('$', '');
  }

  handleBeforeStateChange(stringValue) {
    // Override behaviour by HoC
    if (typeof this.props.onBeforeStateChange === 'function') {
      return this.props.onBeforeStateChange(stringValue);
    }

    // Else use default
    return (
      stringValue.match(new RegExp(`^-?\\d*(:?\\.\\d{0,${this.props.decimals}})?$`)) && this.isValidValue(stringValue)
    );
  }

  isValidValue(stringValue) {
    const numberValue = Math.abs(Number(stringValue));

    return !isNaN(numberValue) && numberValue <= this.props.maxValue && numberValue >= this.props.minValue;
  }

  render() {
    return (
      <React.Fragment>
        {this.props.marker && <span className="field-marker">{this.props.marker}</span>}
        <NumberInput {...this.props} onBeforeStateChange={this.handleBeforeStateChange} formatter={this.formatValue} />
      </React.Fragment>
    );
  }
}

export default AmountInput;
