import { QuotesCollectionModel, LineItemModel, QuoteModel, LocationQuoteModel } from '../';
import { CELL_TYPES } from '../../components/LineItems/enums';
import { QUOTE_STATUS } from '../../common/enums/quoteStatusEnums';
import fixFloat from '../../common/helpers/fixFloat';
import { constants } from '../../common/enums';
import { PriceBookContext } from '../../Context';

export default class LocationLineItemModel extends LineItemModel {
  constructor(instance) {
    super(instance);

    this._parentUuid = null;
    this._setPropsOnParentDifinition = false;
    this._setPropsData = {};
    this._masterOrder = null;
    this._parent = null;
    this._cellTypes.partnerProductName = CELL_TYPES.label;

    this.cellTypes = { ...this._cellTypes };

    this._ignoreOnExport.push('parent', 'masterOrder', 'locationQuote');
  }

  static validateAllowedToAllocateOnConditionAtLocation({
    lineItemFlags,
    productId,
    locationQuoteModel,
    returnNullIfNoFlags = false,
  }) {
    return this._validateConditionsVersusSpecifiedFlagName({
      flagName: 'allowedToAllocateOnConditionAtLocation',
      lineItemFlags,
      productId,
      quoteModel: locationQuoteModel,
      returnNullIfNoFlags,
    });
  }

  setProps(data, skipProps = []) {
    if (this._parentUuid === null) {
      this._setPropsData = data;
      this._setPropsOnParentDifinition = true;
    } else {
      return super.setProps(data, skipProps);
    }

    return this;
  }

  // Prevent setting parentUuid by property name or setProps
  get parentUuid() {
    return this._parentUuid;
  }

  setParentUuid(value) {
    this._parentUuid = String(value);

    if (this._setPropsOnParentDifinition) {
      this.setProps(this._setPropsData);

      this._setPropsOnParentDifinition = false;
      this._setPropsData = {};
    }
  }

  get _masterOrderLookup() {
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (quotes) {
      this._masterOrder = quotes.customerOrder;
      return this._masterOrder;
    }

    return null;
  }

  /**
   * @return {CustomerOrderModel|null}
   */
  get masterOrder() {
    return this._masterOrder !== null ? this._masterOrder : this._masterOrderLookup;
  }

  get _parentLookup() {
    if (!this.masterOrder) {
      return null;
    }

    for (let i = 0; i < this.masterOrder.lineItems.items.length; i++) {
      if (this.masterOrder.lineItems.items[i].uuid === this.parentUuid) {
        this._parent = this.masterOrder.lineItems.items[i];

        return this._parent;
      }
    }

    return null;
  }

  /**
   * @return {LineItemModel|null}
   */
  get parent() {
    return this._parent !== null ? this._parent : this._parentLookup;
  }

  get available() {
    const parent = this.parent;
    const allocated = parent ? parent.allocated : 0;

    return this.totalQuantity - allocated;
  }

  get totalQuantity() {
    const parent = this.parent;

    return parent ? parent.quantityWithOverride : 0;
  }

  onRemove() {
    // Do nothing
  }

  // KM-3793: (sub-rule) Delete Line Item shall only be available on Customer Order (not on Location Quotes)
  get removable() {
    return false;
  }

  set removable(value) {
    // Do nothing
  }

  get _quantityGetter() {
    if (this.getFlagValuesArray('productTag').includes('packagedApplicationsThatRequireConsistentAllocation')) {
      const location = this.findSibling(d => d instanceof LocationQuoteModel);
      const isToggleOn = location.allocateAllCustomerLevelPackagedApplications;

      this.cellTypes.quantity = CELL_TYPES.label;

      if (location.status === QUOTE_STATUS.FINALIZED) {
        return this._quantity;
      }

      return isToggleOn ? this.parent.quantityWithOverride : 0;
    }

    const manualCountsAtLocation = (this.getFlagValue('manualCountsAtLocation') || 'false').toLowerCase();

    // If location line item does NOT have manualCountsAtLocation flag
    // Proceed with default quantity calculation logic
    // Which may depends on other flags to auto-calculate value
    if (manualCountsAtLocation === 'false') {
      this._quantity = super._quantityGetter;

      if (this.maxItemCountPerLocation && this._quantity > this.maxItemCountPerLocation) {
        this._quantity = this.maxItemCountPerLocation;
      }
    } else if (manualCountsAtLocation === 'true') {
      const bundleParents = this.linkedLineItems;

      if (bundleParents.length) {
        let isBundleParentsAllocated = false;

        // Is any of the parent items are allocated
        for (let i = 0; i < bundleParents.length; i++) {
          /** @type {LineItemModel} */
          const bundleParent = bundleParents[i];

          if (bundleParent.quantityWithOverride > 0) {
            isBundleParentsAllocated = true;
            break;
          }
        }

        // Allocation is only available in case any of the parent items are allocated
        if (isBundleParentsAllocated) {
          this.cellTypes.quantity = CELL_TYPES.number;
        }
        // No parent items are allocated. Reset child quantity to zero and disable input
        else {
          this._quantity = 0;
          this.cellTypes.quantity = CELL_TYPES.numberForceDisabled;
        }
      }
      // Make quantity field editable for stand-alone items
      // that have manualCountsAtLocation flag set to true
      else {
        this.cellTypes.quantity = CELL_TYPES.number;
      }
    }

    return this._quantity;
  }

  toJS(skipFunctions = false, level = 0, parentUuids = [], cache = []) {
    let data = super.toJS(skipFunctions, level, parentUuids, cache);

    this.exportingProps = true;

    // KM-3804: Forbid customer to set line item quantity per sub-category
    // In case of category has maxItemCountPerLocation flag set
    if (this.maxItemCountPerLocation) {
      const quote = this.findSibling(d => d instanceof QuoteModel);

      if (quote) {
        let countInSubCategory = 0;

        for (let i = 0; i < quote.lineItems.items.length; i++) {
          const d = quote.lineItems.items[i];

          // Skip current line item
          if (d.uuid === this.uuid) {
            continue;
          }

          // Skip line items from different sub categories
          if (d.lineItemSubCategoryId !== this.lineItemSubCategoryId) {
            continue;
          }

          countInSubCategory += d.quantity;
        }

        if (countInSubCategory) {
          data.cellTypes.quantity = CELL_TYPES.label;
        } else {
          data.cellTypes.quantity = CELL_TYPES.number;
        }
      }
    }

    this.cellTypes.quantity = data.cellTypes.quantity;

    this.exportingProps = false;

    return data;
  }

  // Naming is a bit confusing
  // quoteLineItemsId is a DB ID of parent line item from Customer Order
  // TODO: Update naming. Part of KM-3868
  get quoteLineItemsId() {
    const parent = this.parent;

    return parent ? parent.id : null;
  }

  // Percent prop is expected by BE
  // TODO: Clarify what value should be used for percent prop
  get percent() {
    return 0;
  }

  // TODO: remove duplicate getters when API will be updated to has
  // consistent prop naming with a customer order data points
  get priceQuoted() {
    return this.quotePrice;
  }

  get parentQuoteLineItemUuid() {
    const parent = this.parent;

    return parent ? parent.quoteLineItemUuid : null;
  }

  // Override quoteLineItemUuid getter and setter on location level
  // quoteLineItemUuid prop should not be used on location level
  // All the logic should be based on parentQuoteLineItemUuid instead
  get quoteLineItemUuid() {
    return null;
  }

  set quoteLineItemUuid(value) {
    // Do nothing
  }

  get numPortsWithOverridePerItem() {
    return this.parent.numPortsOverride !== null ? this.parent.numPortsOverride : this.parent.numPorts;
  }

  get markupValue() {
    return this.parent ? this.parent.markupValue : 0;
  }

  set markupValue(value) {
    // Do nothing
  }

  get overrideValue() {
    if (this.lineItemCategoryId === constants.MAINTENANCE_CATEGORY_ID) {
      return this._overrideValue;
    }

    return this.parent ? this.parent.overrideValue : null;
  }

  set overrideValue(value) {
    if (this.lineItemCategoryId === constants.MAINTENANCE_CATEGORY_ID) {
      this._overrideValue = value === null ? null : Number(value) || 0;
    }
  }

  get _hiddenLookup() {
    const superIsHidden = super._hiddenLookup;

    if (superIsHidden) {
      return true;
    }

    if (
      this.quoteSolutionTypeId !== null &&
      !this.applicableSolutionTypes.includes(this.quoteSolutionTypeId) &&
      this.quantity === 0
    ) {
      return true;
    }

    return false;
  }

  get _quoteSolutionTypeIdLookup() {
    /** @type {LocationQuoteModel} */
    const quote = this.findSibling(d => d instanceof LocationQuoteModel);

    if (quote) {
      return quote.solutionTypeId;
    }

    return null;
  }

  get quoteSolutionTypeId() {
    return this._getCachedValueOnExport('_quoteSolutionTypeIdLookup');
  }

  get invalidSolutionType() {
    return !this.applicableSolutionTypes.includes(this.quoteSolutionTypeId);
  }

  get showSolutionTypePrefix() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this.invalidSolutionType;
  }

  // There are number of inherited properties that are not valid for locations
  // TODO: Create LineItemAbstract which will holds shared props only and new LineItemMasterOrderModel

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get locationSiblings() {
    return null;
  }

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get allocated() {
    return null;
  }

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get allocatedFinalized() {
    return null;
  }

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get allocatedOverride() {
    return null;
  }

  get partnerProductName() {
    return this.parent.partnerProductName;
  }

  set partnerProductName(value) {
    // Do nothing
  }

  get parentPrice() {
    return fixFloat(this.parent.price, 2);
  }

  get availablePrice() {
    const parent = this.parent;
    const allocated = parent ? parent.allocatedPrice : 0;

    return fixFloat(this.parentPrice - fixFloat(allocated), 2);
  }

  /**
   * @returns {LocationQuoteModel|null}
   */
  get locationQuote() {
    return this.findSibling(instance => instance instanceof LocationQuoteModel);
  }

  get _isAllowedToAllocateOnConditionAtLocationValid() {
    if (this.locationQuote) {
      return this.constructor.validateAllowedToAllocateOnConditionAtLocation({
        lineItemFlags: this.catalogFlags,
        productId: this.productId,
        locationQuoteModel: this.locationQuote,
      });
    }

    return true;
  }

  get isAllowedToAllocateOnConditionAtLocationValid() {
    return this._getCachedValueOnExport('_isAllowedToAllocateOnConditionAtLocationValid');
  }
}
