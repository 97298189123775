import ORDER_TYPES from '../../../common/enums/orderTypes.enums';

const HIDDEN_ADD_ON_ORDER_FIELD_ID_LIST = [
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactAddress2',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactMobilePhone',
  'billingContactEmail',
  'decentralizedAccounting',
  'billingValidateEmail',
];

export default function getFieldVisibility({ fieldId, isMOBillingSection, orderType }) {
  const isDecentralizedAccountingField = !isMOBillingSection && fieldId === 'decentralizedAccounting';
  const isHiddenAddOnField = orderType === ORDER_TYPES.ADD_ON && HIDDEN_ADD_ON_ORDER_FIELD_ID_LIST.includes(fieldId);

  return !Boolean(isHiddenAddOnField || isDecentralizedAccountingField);
}
