import React from 'react';
import PropTypes from 'prop-types';

class QuoteNumber extends React.PureComponent {
  static propTypes = {
    quoteId: PropTypes.number,
    orderType: PropTypes.string,
    customerName: PropTypes.string,
  };

  render() {
    const itemType = 'Quote';
    const customerName = this.props.customerName;

    return (
      <div className="quote-number-component">
        <span id={`${itemType}-id`} title={this.props.orderType}>
          {(this.props.quoteId || 'not saved') + ':'}&nbsp;
        </span>
        <span className="sm customer-name" title={customerName}>
          {customerName}{' '}
        </span>
      </div>
    );
  }
}

export default QuoteNumber;
