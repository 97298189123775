import React from 'react';
import { AmountControl, NumberControl, SelectControl, TextControl } from '../Controls';
import './nested/addendums-section.css';
import {
  ADDENDUM_FIELDS_LABEL,
  ADDENDUM_DROPDOWN_FIELDS,
  ADDENDUM_DROPDOWN_FIELDS_OPTIONS,
  ADDENDUM_FIELDS,
  ADDENDUM_AMOUNT_FIELDS,
  ADDENDUM_NUMBER_FIELDS,
  ADDENDUM_NUMBER_FIELDS_MAX_VALUE,
} from '../../common/enums';
import AddendumBlock from './nested/AddendumBlock';

const AddendumsSection = ({
  disabledState,
  lockedState,
  customerOrder,
  onChangeAddendumStatus,
  onChangeAddendumField,
}) => {
  const getAddendumFields = (fields, addendumType, disabled) => {
    let result = [];

    for (let fieldType in fields) {
      if (customerOrder.skipSubscriptionAgreementDate && fieldType === ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE) {
        continue;
      }

      if (fields.hasOwnProperty(fieldType)) {
        const value = fields[fieldType];
        const key = [addendumType, fieldType].join('_').replaceAll('_', '-').toLowerCase();

        let inputField = null;
        let inputProps = {
          id: key,
          label: ADDENDUM_FIELDS_LABEL[fieldType],
          disabledState: disabled,
          lockedState: lockedState,
          value: value,
          onChange: _value => onChangeAddendumField(_value, addendumType, fieldType),
        };

        if (ADDENDUM_DROPDOWN_FIELDS.includes(fieldType)) {
          inputField = <SelectControl {...inputProps} options={ADDENDUM_DROPDOWN_FIELDS_OPTIONS[fieldType]} key={key} />;
        } else if (ADDENDUM_AMOUNT_FIELDS.includes(fieldType)) {
          inputField = <AmountControl {...inputProps} value={Number(value)} decimals={3} key={key} />;
        } else if (ADDENDUM_NUMBER_FIELDS.includes(fieldType)) {
          inputField = (
            <NumberControl
              {...inputProps}
              max={ADDENDUM_NUMBER_FIELDS_MAX_VALUE[fieldType]}
              value={Number(value)}
              key={key}
            />
          );
        } else {
          inputField = <TextControl {...inputProps} key={key} changeOnBlur />;
        }

        result.push(inputField);
      }
    }
    return result;
  };

  return (
    <div className="addendums-section-content">
      {customerOrder.quoteAddendums.map(addendum => (
        <AddendumBlock
          key={addendum.documentType}
          disabledState={disabledState}
          lockedState={lockedState}
          onChange={onChangeAddendumStatus}
          getAddendumFields={getAddendumFields}
          {...addendum}
        />
      ))}
    </div>
  );
};

export default AddendumsSection;
