import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../../common/translate';
import AlternateDescription from './AlternateDescription';
import * as formatter from '../../../common/helpers/format';
import { AuthContext } from '../../../Context';
import { AmountControl } from '../../Controls';

const MAX_ADDITIONAL_DISCOUNT_PERCENT_VALUE = 99;

class AdditionalDiscountTable extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    rcDescriptionOptions: PropTypes.arrayOf(PropTypes.object),
    rcDescriptionValue: PropTypes.string,
    nrcDescriptionOptions: PropTypes.arrayOf(PropTypes.object),
    nrcDescriptionValue: PropTypes.string,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isAltDescriptionsDisabled: PropTypes.bool,
    additionalRecurringDiscount: PropTypes.number,
    additionalUpfrontDiscount: PropTypes.number,
    calculatedMadRecurAmount: PropTypes.number,
    calculatedMadUpfrontAmount: PropTypes.number,
    madRecurringFormatted: PropTypes.string,
    madNonRecurringFormatted: PropTypes.string,
    additionalRcDiscountPercent: PropTypes.number,
    additionalUpfrontDiscountPercent: PropTypes.number,
    hideAdditionalDiscountValues: PropTypes.bool,
    hideNrcAdditionalDiscountValues: PropTypes.bool,
    disableAdditionalDiscountInputs: PropTypes.bool,
    displayMadOverrideInputs: PropTypes.bool,
    discountInputsDisabledState: PropTypes.bool,
    madRecurringOverride: PropTypes.number,
    madUpfrontOverride: PropTypes.number,
    isAllInclusive: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChangeMadRecurringOverride = this.handleChangeMadRecurringOverride.bind(this);
    this.handleChangeMadUpfrontOverride = this.handleChangeMadUpfrontOverride.bind(this);
    this.handleChangeAdditionalRcDiscountPercent = this.handleChangeAdditionalRcDiscountPercent.bind(this);
    this.handleChangeAdditionalUpfrontDiscountPercent = this.handleChangeAdditionalUpfrontDiscountPercent.bind(this);
  }

  handleChange(propName, value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, propName);
    }
  }

  handleChangeMadRecurringOverride(value) {
    this.handleChange('madRecurringOverride', value);
  }

  handleChangeMadUpfrontOverride(value) {
    this.handleChange('madUpfrontOverride', value);
  }

  handleChangeAdditionalRcDiscountPercent(value) {
    this.handleChange('additionalRcDiscountPercent', value);
  }

  handleChangeAdditionalUpfrontDiscountPercent(value) {
    this.handleChange('additionalUpfrontDiscountPercent', value);
  }

  getTableHeaders() {
    return (
      <tr className="table-header">
        <th className="empty-table-header">
          <strong>{translator.getMessage('msg_customer_discount_spiff_summary')}</strong>
        </th>
        <th className="label-table-header">{translator.getMessage('msg_calculated_discount')}</th>
        <th className="label-table-header">{translator.getMessage('msg_additional_discount_percent')}</th>
        <th className="label-table-header">{translator.getMessage('msg_additional_discount_absolute')}</th>
        <th className="label-table-header">{translator.getMessage('msg_total_extended_discount')}</th>
      </tr>
    );
  }

  getRecurringRow() {
    const {
      onChange,
      rcDescriptionOptions,
      rcDescriptionValue,
      lockedState,
      disabledState,
      isAltDescriptionsDisabled,
      additionalRecurringDiscount,
      calculatedMadRecurAmount,
      madRecurringFormatted,
      additionalRcDiscountPercent,
      hideAdditionalDiscountValues,
      disableAdditionalDiscountInputs,
      displayMadOverrideInputs,
      discountInputsDisabledState,
      madRecurringOverride,
    } = this.props;

    const formattedCalculatedMadRecurAmount = formatter.currency(calculatedMadRecurAmount);
    const formattedAdditionalRecurringDiscount = formatter.currency(additionalRecurringDiscount);

    return (
      <tr>
        <td className="auto-width">
          <AlternateDescription
            id="alternate-description-recurring"
            onChange={onChange}
            value={rcDescriptionValue}
            lockedState={lockedState}
            disabledState={disabledState || isAltDescriptionsDisabled}
            type="recurringDiscountAltDescription"
            altDescriptionOptions={rcDescriptionOptions}
          />
        </td>
        <td className="auto-width align-right">
          {displayMadOverrideInputs ? (
            <AmountControl
              id="madRecurringOverride"
              onChange={this.handleChangeMadRecurringOverride}
              lockedState={lockedState}
              disabledState={discountInputsDisabledState}
              value={madRecurringOverride}
              hideErrorText
            />
          ) : (
            formattedCalculatedMadRecurAmount
          )}
        </td>
        <td className="auto-width align-right">
          {hideAdditionalDiscountValues ? (
            translator.getMessage('msg_not_available_abbreviation')
          ) : AuthContext.model.hasInternalPermissions ? (
            <AmountControl
              onChange={this.handleChangeAdditionalRcDiscountPercent}
              id="additional-discount-percent-rc"
              value={additionalRcDiscountPercent}
              max={MAX_ADDITIONAL_DISCOUNT_PERCENT_VALUE}
              decimals={0}
              marker="%"
              parentClassName="short-input"
              lockedState={lockedState}
              disabledState={disabledState || disableAdditionalDiscountInputs}
            />
          ) : (
            additionalRcDiscountPercent
          )}
        </td>
        <td className="auto-width align-right">
          {hideAdditionalDiscountValues
            ? translator.getMessage('msg_not_available_abbreviation')
            : formattedAdditionalRecurringDiscount}
        </td>
        <td className="auto-width align-right">{madRecurringFormatted}</td>
      </tr>
    );
  }

  getNonRecurringRow() {
    const {
      onChange,
      nrcDescriptionOptions,
      nrcDescriptionValue,
      lockedState,
      disabledState,
      isAltDescriptionsDisabled,
      additionalUpfrontDiscount,
      calculatedMadUpfrontAmount,
      madNonRecurringFormatted,
      additionalUpfrontDiscountPercent,
      hideAdditionalDiscountValues,
      hideNrcAdditionalDiscountValues,
      disableAdditionalDiscountInputs,
      displayMadOverrideInputs,
      discountInputsDisabledState,
      madUpfrontOverride,
      isAllInclusive,
    } = this.props;

    const formattedCalculatedMadNonRecurAmount = formatter.currency(calculatedMadUpfrontAmount);
    const formattedAdditionalNonRecurringDiscount = formatter.currency(additionalUpfrontDiscount);

    return (
      <tr>
        <td className="alternate-description-cell">
          <AlternateDescription
            id="alternate-description-non-recurring"
            onChange={onChange}
            value={nrcDescriptionValue}
            lockedState={lockedState}
            disabledState={disabledState || isAltDescriptionsDisabled}
            type="nonRecurringDiscountAltDescription"
            altDescriptionOptions={nrcDescriptionOptions}
          />
        </td>
        <td className="auto-width align-right">
          {displayMadOverrideInputs ? (
            <AmountControl
              id="madUpfrontOverride"
              onChange={this.handleChangeMadUpfrontOverride}
              lockedState={lockedState}
              disabledState={discountInputsDisabledState || isAllInclusive}
              value={madUpfrontOverride}
              hideErrorText
            />
          ) : (
            formattedCalculatedMadNonRecurAmount
          )}
        </td>
        <td className="auto-width align-right">
          {hideAdditionalDiscountValues || hideNrcAdditionalDiscountValues ? (
            translator.getMessage('msg_not_available_abbreviation')
          ) : AuthContext.model.hasInternalPermissions ? (
            <AmountControl
              onChange={this.handleChangeAdditionalUpfrontDiscountPercent}
              id="additional-discount-percent-nrc"
              value={additionalUpfrontDiscountPercent}
              max={MAX_ADDITIONAL_DISCOUNT_PERCENT_VALUE}
              decimals={0}
              marker="%"
              parentClassName="short-input"
              lockedState={lockedState}
              disabledState={disabledState || disableAdditionalDiscountInputs}
            />
          ) : (
            additionalUpfrontDiscountPercent
          )}
        </td>

        <td className="auto-width align-right">
          {hideAdditionalDiscountValues || hideNrcAdditionalDiscountValues
            ? translator.getMessage('msg_not_available_abbreviation')
            : formattedAdditionalNonRecurringDiscount}
        </td>
        <td className="auto-width align-right">{madNonRecurringFormatted}</td>
      </tr>
    );
  }

  render() {
    return (
      <table className="extended-discount-spiff-table rq-table">
        <thead>{this.getTableHeaders()}</thead>
        <tbody>
          {this.getRecurringRow()}
          {this.getNonRecurringRow()}
        </tbody>
      </table>
    );
  }
}

export default AdditionalDiscountTable;
