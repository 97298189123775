import React from 'react';
import PropTypes from 'prop-types';
import { ZipInput } from '../../Inputs';
import AbstractControl from '../AbstractControl';

class ZipControl extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    hint: PropTypes.string,
    label: PropTypes.node,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    changeOnBlur: PropTypes.bool,
    errorText: PropTypes.string,
    tooltipText: PropTypes.string,

    onStateChange: PropTypes.func,
    style: PropTypes.object,
    focus: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    clearFieldHandler: PropTypes.func,
    ariaLabel: PropTypes.string,
  };

  render() {
    const parentClassNames = ['zip-input-component'];

    if (typeof this.props.className === 'string' && this.props.className !== '') {
      parentClassNames.push(this.props.className);
    }

    const inputAbstractProps = {
      hint: this.props.hint,
      style: this.props.style,
      focus: this.props.focus,
      label: this.props.label,
      errorText: this.props.errorText,
      required: this.props.required,
      requiredBlue: this.props.requiredBlue,
      tooltipText: this.props.tooltipText,
      disabledState: this.props.disabledState,
      parentClassName: this.props.parentClassName,
      className: parentClassNames.join(' '),
      labelFor: this.props.id,
    };

    return (
      <AbstractControl {...inputAbstractProps}>
        <ZipInput
          id={this.props.id}
          disabled={this.props.lockedState || this.props.disabledState}
          value={this.props.value}
          doFocus={this.props.focus}
          onChange={this.props.onChange}
          commitOnBlur={this.props.changeOnBlur}
          onStateChange={this.props.onStateChange}
          ariaLabel={this.props.ariaLabel}
        />
      </AbstractControl>
    );
  }
}

export default ZipControl;
