import { REQUIRED_FIELDS } from './required-fields';
import { APP_STATE } from './app-state';
import {
  QUOTE_INFO_INPUTS_NEW_NEW,
  QUOTE_INFO_INPUTS_NEW_LOCATIONS,
  QUOTE_INFO_INPUTS_ADD_ON,
  QUOTE_INFO_INPUTS_REWRITE,
  QUOTE_INFO_INPUTS_REDUCTION,
} from './quote-info-inputs';
import {
  REQUIRED_FOR_FINALIZE_NEW_NEW,
  REQUIRED_FOR_FINALIZE_NEW_LOCATIONS,
  REQUIRED_FOR_FINALIZE_ADD_ON,
  REQUIRED_FOR_FINALIZE_REWRITE,
  REQUIRED_FOR_FINALIZE_REDUCTION,
} from './required-for-finalize';

const constants = {
  NEW_NEW: {
    REQUIRED_FIELDS_CONTACT_MO: REQUIRED_FIELDS.CONTACT_MO.NEW_NEW,
    REQUIRED_FIELDS_CONTACT_LOC: REQUIRED_FIELDS.CONTACT_LOC.NEW_NEW,
    SAVE_FIELDS: APP_STATE.SAVE_FIELDS.NEW_NEW,
    CALC_FIELDS: APP_STATE.CALC_FIELDS.NEW_NEW,
    QUOTE_INFO_INPUTS: QUOTE_INFO_INPUTS_NEW_NEW,
    REQUIRED_FOR_FINALIZE: REQUIRED_FOR_FINALIZE_NEW_NEW,
  },
  NEW_LOCATIONS: {
    REQUIRED_FIELDS_CONTACT_MO: REQUIRED_FIELDS.CONTACT_MO.NEW_NEW,
    REQUIRED_FIELDS_CONTACT_LOC: REQUIRED_FIELDS.CONTACT_LOC.NEW_NEW,
    SAVE_FIELDS: APP_STATE.SAVE_FIELDS.NEW_LOCATIONS,
    CALC_FIELDS: APP_STATE.CALC_FIELDS.NEW_LOCATIONS,
    QUOTE_INFO_INPUTS: QUOTE_INFO_INPUTS_NEW_LOCATIONS,
    REQUIRED_FOR_FINALIZE: REQUIRED_FOR_FINALIZE_NEW_LOCATIONS,
  },
  ADD_ON: {
    REQUIRED_FIELDS_CONTACT_MO: REQUIRED_FIELDS.CONTACT_MO.ADD_ON,
    SAVE_FIELDS: APP_STATE.SAVE_FIELDS.ADD_ON,
    CALC_FIELDS: APP_STATE.CALC_FIELDS.ADD_ON,
    QUOTE_INFO_INPUTS: QUOTE_INFO_INPUTS_ADD_ON,
    REQUIRED_FOR_FINALIZE: REQUIRED_FOR_FINALIZE_ADD_ON,
  },
  REWRITE: {
    REQUIRED_FIELDS_CONTACT_MO: REQUIRED_FIELDS.CONTACT_MO.REWRITE,
    REQUIRED_FIELDS_CONTACT_LOC: REQUIRED_FIELDS.CONTACT_LOC.NEW_NEW,
    SAVE_FIELDS: APP_STATE.SAVE_FIELDS.REWRITE,
    CALC_FIELDS: APP_STATE.CALC_FIELDS.REWRITE,
    QUOTE_INFO_INPUTS: QUOTE_INFO_INPUTS_REWRITE,
    REQUIRED_FOR_FINALIZE: REQUIRED_FOR_FINALIZE_REWRITE,
  },
  REDUCTION: {
    REQUIRED_FIELDS_CONTACT_MO: REQUIRED_FIELDS.CONTACT_MO.NEW_NEW,
    REQUIRED_FIELDS_CONTACT_LOC: REQUIRED_FIELDS.CONTACT_LOC.NEW_NEW,
    SAVE_FIELDS: APP_STATE.SAVE_FIELDS.REDUCTION,
    CALC_FIELDS: APP_STATE.CALC_FIELDS.REDUCTION,
    QUOTE_INFO_INPUTS: QUOTE_INFO_INPUTS_REDUCTION,
    REQUIRED_FOR_FINALIZE: REQUIRED_FOR_FINALIZE_REDUCTION,
  },
  WLW_EXCLUDED_FIELDS: ['contactOfficePhone', 'contactEmail', 'businessContactOfficePhone', 'businessContactEmail'],
};

export default constants;
