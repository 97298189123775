import $http from '../../common/$http';
import config from '../../config';

class QuoteStatusPollInterface {
  /**
   * @param {QuoteController} controller
   */
  constructor(controller) {
    this.controller = controller;
    this._timeout = null;

    this._requestQuoteStatus = this._requestQuoteStatus.bind(this);
  }

  _pollPromiseFunction(interval, retries, resolve, reject) {
    if (retries <= 0) {
      this.controller.appState.quoteLoading = false;
      this.controller.renderView();

      reject(false);

      return false;
    }

    const prevQuoteStatus = this.controller.masterOrder.quoteStatus;

    this._requestQuoteStatus().then(newQuoteStatus => {
      if (!newQuoteStatus || newQuoteStatus === prevQuoteStatus) {
        this.clearTimeout();
        this._timeout = setTimeout(
          this._pollPromiseFunction.bind(this, interval, retries - 1, resolve, reject),
          interval
        );
      } else {
        this.controller.masterOrder.quoteStatus = newQuoteStatus;

        this.controller.renewSaveAndCalcSchemaCache();

        this.controller.appState.quoteLoading = false;
        this.controller.renderView();

        resolve(newQuoteStatus);
      }
    });
  }

  poll(interval = 500, retries = 10) {
    this.controller.appState.quoteLoading = true;
    this.controller.renderView();

    return new Promise(this._pollPromiseFunction.bind(this, interval, retries));
  }

  _requestQuoteStatus() {
    const url = config.api.url + '/quote/quotes/' + this.controller.masterOrder.id + '/basicInfo';

    return $http.instance.api.get(url).then(r => {
      if (r.status === 200 && r.data && r.data.content && r.data.content.quoteStatus) {
        return r.data.content.quoteStatus;
      } else {
        return false;
      }
    });
  }

  clearTimeout() {
    if (this._timeout !== null) {
      clearTimeout(this._timeout);
    }
  }
}

export default QuoteStatusPollInterface;
