import config from '../../config';
import DEFAULTS from './CATEGORY_GROUPING_DEFAULTS';
import KEY from './LOCAL_STORAGE_KEY';

class AddLineItemsDialogSettings {
  constructor() {
    this.unrecognizedCategoryDefaults = {};
    this.settings = [];

    const savedSettings = localStorage.getItem(KEY);

    // Read localStorage data
    if (typeof savedSettings === 'string') {
      // Prevent JSON.parse error to break app execution
      // in case if there is some non JSON string in the storage by some reason
      try {
        this.settings = JSON.parse(savedSettings);
      } catch (e) {
        // Do nothing
      }
    }

    // There might be a case when new category added
    // And there is no settings for some categories yet
    // Or it is initial session, user just logged in (new token)
    // Extend current settings by default ones
    for (let i = 0; i < DEFAULTS.length; i++) {
      const categoryDefaults = DEFAULTS[i];

      if (categoryDefaults.id === -1) {
        this.unrecognizedCategoryDefaults = {
          ...categoryDefaults,
        };

        continue;
      }

      const settingsIndex = this.settings.findIndex(d => d.id === categoryDefaults.id);

      // Extends defaults by saved settings
      if (settingsIndex !== -1) {
        this.settings[settingsIndex] = Object.assign(categoryDefaults, this.settings[settingsIndex]);

        continue;
      }

      this.settings.push(categoryDefaults);
    }

    this.handleUnloadEvent = this.handleUnloadEvent.bind(this);

    // Init event listener to update localStorage data on browser/tab close
    window.addEventListener('beforeunload', this.handleUnloadEvent);
  }

  handleUnloadEvent() {
    this.saveState();
  }

  saveState() {
    localStorage[KEY] = JSON.stringify(this.settings);
  }

  getByCategoryId(id) {
    const settings = this.settings.find(d => d.id === id);

    if (settings) {
      return settings;
    }

    // Create new settings object for unrecognized category ID
    this.settings.push({
      ...this.unrecognizedCategoryDefaults,
      id,
    });

    return this.settings[this.settings.length - 1];
  }

  setPropByCategoryId(categoryId, propName, value) {
    const index = this.settings.findIndex(d => d.id === categoryId);

    if (index === -1) {
      return false;
    }

    this.settings[index][propName] = value;

    return true;
  }
}

const addLineItemsDialogSettings = new AddLineItemsDialogSettings();

if (config.showConsoleLog) {
  console.groupCollapsed('addLineItemsDialogSettings (singleton, updates interactively)');
  console.log(addLineItemsDialogSettings);
  console.groupEnd();
}

export default addLineItemsDialogSettings;
