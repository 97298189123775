import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SelectControl } from '../Controls';
import classNames from 'classnames';
import './css/location-select.css';

class LocationSelect extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    locations: [],
  };

  inputElement = null;

  constructor(props) {
    super(props);

    this.hideInput = this.hideInput.bind(this);
    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  componentDidMount() {
    this.locationSelectElem = document.querySelector('.location-select');
    this.inputElement = this.locationSelectElem.querySelector('input');
  }

  hideInput() {
    this.locationSelectElem.classList.toggle('show-location-select', false);
  }

  handleOnClick() {
    if (this.locationSelectElem.classList.toggle('show-location-select')) {
      this.inputElement.value = '';
      this.inputElement.focus();
    }
  }

  handleOnChange(value) {
    this.props.onChange(this.props.locations[value]);
  }

  getLocationOptions() {
    if (this.props.locations.length > 0) {
      return this.props.locations.map((location, index) => {
        return {
          value: index,
          text: location.serviceInfo.locationName,
          error: 0, //transmit the amount of errors
        };
      });
    }
    return [];
  }

  render() {
    const locationSelectInputClassname = classNames({
      'location-select-input': true,
      left: this.props.locations.length < 3,
    });

    return (
      <div className="location-select-component">
        <button
          type="button"
          className="locations-select-btn square tertiary"
          onClick={this.handleOnClick}
          aria-label={'Location select drop-down menu'}
        >
          <div className="icon-expand-more" />
        </button>
        <SelectControl
          {...this.props}
          onChange={this.handleOnChange}
          className="location-select"
          id="locationsSelect"
          parentClassName={locationSelectInputClassname}
          placeholder="Find a location..."
          fullWidth
          locked={false}
          focusWhenOpen
          onBlur={this.hideInput}
          options={this.getLocationOptions()}
          style={{ marginBottom: 0 }}
          isReadOnly={false}
        />
      </div>
    );
  }
}

export default LocationSelect;
