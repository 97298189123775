import { QuotesTableModel } from '../Models';
import $http from '../common/$http';
import { AppStateContext, AuthContext } from '../Context';
import bindMultiple from '../common/helpers/bindMultiple';
import AppController from './AppController';
import { FILTERABLE_COLUMNS } from '../common/enums';

class QuotesTableController extends AppController {
  constructor() {
    super();

    bindMultiple(
      this,
      this.handleChangeLimit,
      this.handleChangePage,
      this.handleChangeSearch,
      this.handleChangeSort,
      this.handleChangeColumnFilter,
      this.handleClearColumnFilter
    );

    this._fetchQuotesTimeout = null;
    this.quotesTableModel = new QuotesTableModel(this);

    this._props = {
      quotes: [],
      totalCount: 0,
      isLoading: false,
      columnFilters: this.quotesTableModel.columnFilters,
      onChangePage: this.handleChangePage,
      onChangeSort: this.handleChangeSort,
      onChangeSearch: this.handleChangeSearch,
      onChangeLimit: this.handleChangeLimit,
      onChangeColumnFilter: this.handleChangeColumnFilter,
      onClearColumnFilter: this.handleClearColumnFilter,
    };
    this._abortController = null;

    this.listenAppStateContext = this.listenAppStateContext.bind(this);
    AppStateContext.model.addBroadcastListener(this.listenAppStateContext);
  }

  listenAppStateContext() {
    if (AppStateContext.model.appInitiated) {
      this.fetchQuoteTypes();
      this.fetchQuotes(0);

      return true;
    }
  }

  get props() {
    const { page, limit, sortBy, sortOrder, searchString, onChange, savePropsToStorage } = this.quotesTableModel.toJS();

    return {
      ...this._props,
      page,
      limit,
      sortBy,
      sortOrder,
      searchString,
      onChange,
      savePropsToStorage,
    };
  }

  _requestQuotes() {
    const model = this.quotesTableModel;
    const endpoint = $http.instance.apiDomainURI + '/quote/quotes';
    const params = {
      userId: AuthContext.model.userId,
      page: model.page,
      pageLength: model.limit,
      sort: model.sortBy,
      sortOrder: model.sortOrder,
      search: model.searchString,
      quoteStatus: model.getColumnFilterByType(FILTERABLE_COLUMNS.STATUS).selectedOptions.join(','),
      orderType: model.getColumnFilterByType(FILTERABLE_COLUMNS.ORDER_TYPE).selectedOptions.join(','),
      packageShortNameSelected: model.getColumnFilterByType(FILTERABLE_COLUMNS.QUOTE_TYPE).selectedOptions.join(','),
    };
    const paramsArray = [];

    if (this._abortController) {
      this._abortController.abort();
      this._abortController = null;
    }

    for (let name in params) {
      const value = encodeURIComponent(params[name]);

      paramsArray.push(name + '=' + value);
    }

    const url = endpoint + '?' + paramsArray.join('&');

    this._abortController = new AbortController();

    return $http.instance.api.get(url, {
      signal: this._abortController.signal,
    });
  }

  _requestQuotesCatch(error) {
    if (error?.code === 'ERR_CANCELED') {
      return;
    }

    throw error;
  }

  _fetchQuotesHandler(r) {
    this._props.isLoading = false;
    this._props.quotes = r.data.content.quotes;
    this._props.totalCount = r.data.content.totalCount;
    this._abortController = null;

    this.renderView();

    return r;
  }

  fetchQuotes(delay) {
    if (this._fetchQuotesTimeout !== null) {
      clearTimeout(this._fetchQuotesTimeout);
    }

    this._props.isLoading = true;
    this.renderView();

    if (delay) {
      return new Promise(resolve => {
        this._fetchQuotesTimeout = setTimeout(() => {
          this._requestQuotes()
            .then(r => {
              this._fetchQuotesHandler(r);

              resolve(r);
            })
            .catch(this._requestQuotesCatch);
        }, delay);
      });
    } else {
      return this._requestQuotes()
        .then(r => {
          return this._fetchQuotesHandler(r);
        })
        .catch(this._requestQuotesCatch);
    }
  }

  _handleChange(delay = 0) {
    this.quotesTableModel.updateUrl();

    this.fetchQuotes(delay).then(() => {
      this.renderView();
    });
  }

  handleChangePage(pageNumber) {
    if (this.quotesTableModel.page === pageNumber) {
      // Same page is selected. Do nothing.
      return false;
    }

    this.quotesTableModel.page = pageNumber;
    this._handleChange();

    return true;
  }

  handleChangeSort(propName) {
    const isSameProperty = this.quotesTableModel.sortBy === propName;

    if (isSameProperty) {
      this.quotesTableModel.sortOrder =
        this.quotesTableModel.sortOrder === QuotesTableModel.SORT_ORDER.descending
          ? QuotesTableModel.SORT_ORDER.ascending
          : QuotesTableModel.SORT_ORDER.descending;
    } else {
      this.quotesTableModel.sortBy = propName;
    }

    // Reset to first page on sort column change
    this.quotesTableModel.page = 0;

    this._handleChange();

    return true;
  }

  handleChangeSearch(searchString) {
    this.quotesTableModel.searchString = String(searchString).trim();

    // Reset to first page on search string change
    this.quotesTableModel.page = 0;

    this._handleChange(1000);

    return true;
  }

  handleChangeLimit(limit) {
    if (this.quotesTableModel.limit === limit) {
      // Same limit is selected. Do nothing.
      return false;
    }

    // Reset to first page on limit change
    this.quotesTableModel.page = 0;
    this.quotesTableModel.limit = limit;
    this._handleChange();

    return true;
  }

  handleChangeColumnFilter(columnType, value) {
    const columnFilter = this.quotesTableModel.columnFilters.find(c => c.type === columnType);
    const idx = columnFilter.selectedOptions.indexOf(value);

    if (idx === -1) {
      columnFilter.selectedOptions.push(value);
    } else {
      columnFilter.selectedOptions.splice(idx, 1);
    }

    this._handleChange();

    return true;
  }

  handleClearColumnFilter(columnType) {
    const columnFilter = this.quotesTableModel.columnFilters.find(c => c.type === columnType);
    columnFilter.selectedOptions = [];

    this._handleChange();

    return true;
  }

  async fetchQuoteTypes() {
    const endpoint = $http.instance.apiDomainURI + '/quote/quotes/quoteTypes';
    const quoteTypesResponse = await $http.instance.api.get(endpoint);
    const quoteTypes = quoteTypesResponse?.data?.content;

    if (quoteTypes) {
      this.quotesTableModel.addQuoteTypeFilterOptions(quoteTypes);
    }

    this.quotesTableModel.readPropsFromUrl();
    this._handleChange();

    return quoteTypes;
  }
}

export default QuotesTableController;
