import { CategoryModel, QuotesCollectionModel } from '../';
import { AuthContext } from '../../Context';
import { ORDER_TYPES } from '../../common/enums';

export default class PromotionCategoryModel extends CategoryModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this._showMarkupTotal = false;
  }

  get columns() {
    let result = [];

    if (this.isInLocationQuote) {
      result = [
        {
          alias: 'lineItemName',
          header: null,
        },
        {
          alias: 'totalQuantity',
          header: 'msg_total_quantity',
        },
        {
          alias: 'available',
          header: 'msg_available',
        },
        {
          alias: 'quantity',
          header: 'msg_quantity',
        },
        {
          alias: 'quotePrice',
          header: 'msg_quote_price',
        },
        {
          alias: 'total',
          header: 'msg_total',
        },
      ];
    } else {
      result = [
        {
          alias: 'lineItemName',
          header: null,
        },
        {
          alias: 'quantity',
          header: 'msg_quantity',
        },
        {
          alias: 'allocated',
          header: 'msg_allocated',
        },
        {
          alias: 'quotePrice',
          header: 'msg_quote_price',
        },
        {
          alias: 'total',
          header: 'msg_total',
        },
      ];

      /** @type {QuotesCollectionModel} **/
      const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

      if (quotes && quotes.customerOrder) {
        if ([ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(quotes.customerOrder.orderType)) {
          result.splice(
            result.findIndex(d => d.alias === 'allocated'),
            1
          );
        }
      }

      if (AuthContext.model.hasSalesOpsPermissions) {
        result.splice(
          result.findIndex(d => d.alias === 'quotePrice'),
          0,
          {
            alias: 'overrideValue',
            header: 'msg_override',
          }
        );
      }
    }

    if (this._hasPlaceholderProductItems) {
      result.splice(result.findIndex(d => d.alias === 'lineItemName') + 1, 0, {
        alias: 'partnerProductName',
        header: 'msg_line_items_header_description',
      });
    }

    if (AuthContext.model.hasSalesOpsPermissions) {
      result.splice(result.findIndex(d => d.alias === 'quantity') + 1, 0, {
        alias: 'overrideQuantity',
        header: 'msg_quantity_override',
      });
    }

    return result;
  }

  get _hasPlaceholderProductItems() {
    return this.lineItems.some(d => d.getFlagValue('placeholderProduct') === 'TRUE');
    // TODO: Uncomment to look into line items catalog also. To prevent "jumping" columns
    // do not forget to import apiData
    // || apiData.lineItems
    //   .filter(d => d.lineItemCategoryId === this.id && d.active && d.allowedToAdd)
    //   .some(d => d.catalogFlags.some(d => d.flag.name === 'placeholderProduct' && d.valueAsString === 'TRUE'))
  }
}
