import { finalizeBtnTooltipText } from '../../common/check-required-for-finalize';
import { AuthContext } from '../../Context';
import { checkRequiredFieldsForSignature } from '../../common/check-required-for-signature';
import ToolbarButtonModel from './ToolbarButtonModel';
import { QUOTE_STATUS } from '../../common/enums';

class SendForSignatureModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_send_for_signature';
  }

  get disabled() {
    return Boolean(
      this.isActiveQuoteValidationRequested ||
        this.requiredFields.fields.length ||
        this.requiredFields.separateLineFields.length ||
        [QUOTE_STATUS.SUBMITTED, QUOTE_STATUS.CLOSED].includes(this.masterOrder.quoteStatus) ||
        !this.appState.isSaved ||
        !this.masterOrder.trackingLocationId ||
        !this.controller.rollUpDisabled ||
        (this.masterOrder.quoteStatus === QUOTE_STATUS.AMENDING && !AuthContext.model.hasInternalPermissions) ||
        (this.masterOrder.quoteStatus === QUOTE_STATUS.AMENDING &&
          AuthContext.model.hasInternalPermissions &&
          !this.masterOrder.amendmentTaskNumber) ||
        super.disabled ||
        this.appState.switchvoxWlwDisabled
    );
  }

  get errors() {
    const requiredFields = this.requiredFields;

    if (!requiredFields || [QUOTE_STATUS.SUBMITTED, QUOTE_STATUS.CLOSED].includes(this.masterOrder.quoteStatus)) {
      return '';
    }

    const hasAltErrorWrapper =
      requiredFields.fields.length && requiredFields.fields.every(({ altErrorWrapper }) => altErrorWrapper);

    if (hasAltErrorWrapper) {
      return `@msg_please_work_with_sales@${requiredFields.message}) @`;
    }

    return finalizeBtnTooltipText(
      requiredFields,
      AuthContext.model.hasInternalPermissions,
      this.appState.isSaved,
      !this.controller.rollUpDisabled
    );
  }

  get errorsCnt() {
    const { fields, separateLineFields } = this.requiredFields;
    return fields.length + separateLineFields.length;
  }

  get visible() {
    return (
      this.isESignatureFlowAllowed &&
      ((!this.isQuoteSubmittedAndAbleToUpdate &&
        this.canSendForSignature &&
        (!this.isQuoteAmendingByInternalUser || !this.hasSalesExceptionsForNotSeApproved)) ||
        (this.isQuoteAmendingByInternalUser && !this.hasSalesExceptionsForNotSeApproved)) &&
      this.isNotQuoteLoading
    );
  }

  get requiredFields() {
    return checkRequiredFieldsForSignature(this.requiredFinalizeFields, this.masterOrder, AuthContext.model.role);
  }
}

export default SendForSignatureModel;
