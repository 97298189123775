import { LocationQuoteModel, ModelAbstract, QuotesCollectionModel, CustomerOrderModel } from '../index';
import config from '../../config';
import Validate from '../Validate';

class AbstractContactModel extends ModelAbstract {
  constructor(higherOrderModel) {
    super(higherOrderModel);
    this._cloneFromModel = null;
    this.copyFromValue = null;

    this._syncCheckBoxValue = false;
    this._contactFirstName = null;
    this._contactLastName = null;
    this._countryId = config.COUNTRY_CODE_USA;
    this._city = null;
    this._stateId = null;
    this._streetAddress1 = null;
    this._streetAddress2 = null;
    this._zip = null;
    this._isMasterOrder = higherOrderModel instanceof CustomerOrderModel;
    this._quotesList = higherOrderModel.findSibling(s => s instanceof QuotesCollectionModel);
    this._masterOrder = higherOrderModel.findSibling(s => s instanceof CustomerOrderModel);
    this.invalidFields = [];

    this.onChange = this.onChange.bind(this);
  }

  _getClonedValueOrProtectedProp(propName) {
    return this.cloneFromModel ? this.cloneFromModel[propName] : this['_' + propName];
  }

  _syncData(key) {
    return value => {
      this._quotesList.forEach(q => {
        if (q instanceof LocationQuoteModel) {
          q.serviceInfo[key] = value;
        }
      });
    };
  }

  _synchronizeWithCloneModel() {
    if (this.cloneFromModel === null) {
      return false;
    }

    this.city = this.cloneFromModel.city;
    this.contactFirstName = this.cloneFromModel.contactFirstName;
    this.contactLastName = this.cloneFromModel.contactLastName;
    this.countryId = this.cloneFromModel.countryId;
    this.stateId = this.cloneFromModel.stateId;
    this.streetAddress1 = this.cloneFromModel.streetAddress1;
    this.streetAddress2 = this.cloneFromModel.streetAddress2;
    this.zip = this.cloneFromModel.zip;

    return true;
  }

  get syncCheckBoxValue() {
    return this._syncCheckBoxValue;
  }

  set syncCheckBoxValue(value) {
    if (!value) {
      this._synchronizeWithCloneModel();
      this.cloneFromModel = null;
      this.copyFromValue = null;
    }

    this._syncCheckBoxValue = value;
  }

  get contactFirstName() {
    return this._getClonedValueOrProtectedProp('contactFirstName');
  }

  set contactFirstName(value) {
    this._contactFirstName = value;
  }

  get contactLastName() {
    return this._getClonedValueOrProtectedProp('contactLastName');
  }

  set contactLastName(value) {
    this._contactLastName = value;
  }

  get streetAddress1() {
    return this._getClonedValueOrProtectedProp('streetAddress1');
  }

  set streetAddress1(value) {
    this._streetAddress1 = value;
  }

  get streetAddress2() {
    return this._getClonedValueOrProtectedProp('streetAddress2');
  }

  set streetAddress2(value) {
    this._streetAddress2 = value;
  }

  get city() {
    return this._getClonedValueOrProtectedProp('city');
  }

  set city(value) {
    this._city = value;
  }

  get stateId() {
    return this._getClonedValueOrProtectedProp('stateId');
  }

  set stateId(value) {
    this._stateId = value;
  }

  get zip() {
    return this._getClonedValueOrProtectedProp('zip');
  }

  set zip(value) {
    this._zip = value;
  }

  get countryId() {
    return this._getClonedValueOrProtectedProp('countryId');
  }

  set countryId(value) {
    return;
  }

  get cloneFromModel() {
    return this._cloneFromModel;
  }

  set cloneFromModel(value) {
    if (value !== null && this.contactEmail !== undefined && value.contactEmail !== null) {
      this.contactEmail = value.contactEmail;
    }

    this._cloneFromModel = value;
  }

  handleInvalidFields(field, value) {
    const { invalidFields } = this;
    const indexOfField = invalidFields.indexOf(field);
    const error = Validate.customerContact(value, field).text;

    if (error === '' && indexOfField !== -1) {
      invalidFields.splice(indexOfField, 1);
    }

    if (error.length > 0 && indexOfField === -1) {
      invalidFields.push(field);
    }
  }

  onChange(prop, value) {
    if (this.canSet(prop)) {
      this[prop] = value;
      this.handleInvalidFields(prop, value);

      this.modelChanged(this);
    }
  }
}

export default AbstractContactModel;
