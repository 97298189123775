import React from 'react';
import { func, bool } from 'prop-types';
import { hudPackagePropType } from './propTypes';
import Package from './nested/Package';
import SummaryInfo from './nested/SummaryInfo';
import './css/summary.css';

const desc = {
  nrc: 'NRC',
  rc: 'RC',
};

const Summary = ({
  onDetailsClick = () => {},
  onSelectPackage = () => {},
  packageOne = {},
  packageTwo = {},
  packageThree = {},
  packageFour = {},
  isSelectedPackageOverride = false,
  disableDetailsClick,
}) => {
  const descKeys = Object.keys(desc);

  return (
    <div className="summary-component">
      <SummaryInfo desc={desc} onDetailsClick={onDetailsClick} disableDetailsClick={disableDetailsClick} />

      <Package
        {...packageOne}
        keys={descKeys}
        onDetailsClick={onDetailsClick}
        onSelect={onSelectPackage}
        isSelectedPackageOverride={isSelectedPackageOverride}
        disableDetailsClick={disableDetailsClick}
      />
      <Package
        {...packageTwo}
        keys={descKeys}
        onDetailsClick={onDetailsClick}
        onSelect={onSelectPackage}
        isSelectedPackageOverride={isSelectedPackageOverride}
        disableDetailsClick={disableDetailsClick}
      />
      <Package
        {...packageThree}
        keys={descKeys}
        onDetailsClick={onDetailsClick}
        onSelect={onSelectPackage}
        isSelectedPackageOverride={isSelectedPackageOverride}
        disableDetailsClick={disableDetailsClick}
      />
      <Package
        {...packageFour}
        keys={descKeys}
        onDetailsClick={onDetailsClick}
        onSelect={onSelectPackage}
        isSelectedPackageOverride={isSelectedPackageOverride}
        disableDetailsClick={disableDetailsClick}
      />
    </div>
  );
};

Summary.propTypes = {
  onDetailsClick: func,
  onSelectPackage: func,
  packageOne: hudPackagePropType,
  packageTwo: hudPackagePropType,
  packageThree: hudPackagePropType,
  packageFour: hudPackagePropType,
  isSelectedPackageOverride: bool,
};

export default Summary;
