import React from 'react';
import { string, bool, arrayOf, object, number, func } from 'prop-types';
import classNames from 'classnames';
import AbstractControl from '../AbstractControl';
import '../css/radio-button-input.css';
import Input from './nested/Input';

const RadioButtonControl = ({
  className = '',
  customStyles = false,
  disabledState = false,
  errorText = '',
  focus = false,
  hint = '',
  id,
  label = '',
  lockedOptions = [],
  lockedState,
  onChange,
  options = {},
  parentClassName = '',
  required = false,
  requiredBlue = false,
  style = null,
  tooltipText = '',
  value = null,
}) => {
  const componentClassName = classNames('radio-input-group-component', className);
  const handleOnChange = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <AbstractControl
      className={componentClassName}
      errorText={errorText}
      focus={focus}
      hint={hint}
      label={label}
      parentClassName={parentClassName}
      required={required}
      requiredBlue={requiredBlue}
      style={style}
      tooltipText={tooltipText}
    >
      <div className="radio-input-group" onChange={handleOnChange}>
        {Object.entries(options).map(([key, label]) => (
          <Input
            checked={value === Number(key)}
            customStyles={customStyles}
            disabled={lockedOptions.includes(Number(key)) || lockedState || disabledState}
            id={`${id}-${key}`}
            key={key}
            label={label}
            value={Number(key)}
          />
        ))}
      </div>
    </AbstractControl>
  );
};

RadioButtonControl.propTypes = {
  className: string,
  customStyles: bool,
  disabledState: bool,
  errorText: string,
  focus: bool,
  hint: string,
  id: string.isRequired,
  label: string,
  lockedOptions: arrayOf(number),
  onChange: func.isRequired,
  options: object,
  parentClassName: string,
  required: bool,
  requiredBlue: bool,
  style: object,
  tooltipText: string,
  value: number,
};

export default RadioButtonControl;
