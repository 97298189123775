import React, { Component, PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import classNames from 'classnames';
import './css/location-action-bar.css';
import { getTranslateString } from '../../common/helpers/getTranslateString';
import { getTranslateElement } from '../../common/helpers/getTranslateElement';

class LocationConfirmModal extends PureComponent {
  static propTypes = {
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    confirmText: PropTypes.string,
    actionLabel: PropTypes.object,
  };

  static defaultProps = {
    isModalOpen: true,
  };

  constructor(props) {
    super(props);

    this.closeModalHandlerCancel = this.closeModalHandlerCancel.bind(this);
    this.closeModalHandlerConfirm = this.closeModalHandlerConfirm.bind(this);
  }

  closeModalHandlerCancel() {
    this.props.closeModal(false);
  }

  closeModalHandlerConfirm() {
    this.props.closeModal(true);
  }

  render() {
    const { isModalOpen, confirmText, actionLabel, critical } = this.props;

    const labelText = typeof actionLabel === 'string' ? getTranslateElement(actionLabel) : actionLabel;
    const confirmButtonClassName = classNames({
      'close-location-modal-btn': true,
      critical: critical,
    });

    return (
      <Modal
        key="location-tabs-modal-dialog-component"
        isOpen={isModalOpen}
        overlayClassName="default-overlay-modal"
        className="default-confirm-modal"
        onRequestClose={this.closeModalHandlerCancel}
      >
        <div className="default-modal-label">{confirmText || ''}</div>
        <div className="default-confirm-modal-actions">
          <button className="close-location-modal-btn text-btn" onClick={this.closeModalHandlerCancel}>
            Cancel
          </button>
          <button className={confirmButtonClassName} onClick={this.closeModalHandlerConfirm}>
            {labelText || 'OK'}
          </button>
        </div>
      </Modal>
    );
  }
}

class LocationActionBar extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      isModalOpen: false,
      closeModal: () => {},
    };

    this.getBtns = this.getBtns.bind(this);
    this.getAction = this.getAction.bind(this);
  }

  render() {
    const actions = this.getBtns(this.props);

    return (
      <div className="location-action-bar-component">
        {actions}

        {this.state.isModalOpen ? (
          <LocationConfirmModal
            actionLabel={this.state.actionLabel}
            confirmText={this.state.confirmText}
            critical={this.state.critical}
            closeModal={this.state.closeModal}
          />
        ) : null}
      </div>
    );
  }

  getBtns(props) {
    const buttons = Array.isArray(props.children)
      ? props.children.filter(child => typeof child === 'object' && child !== null)
      : [props.children];

    return buttons.map(btn => {
      const title =
        typeof btn.props.title === 'string'
          ? getTranslateString(btn.props.title, this.props.intl)
          : btn.props.title && btn.props.title.props && this.props.intl.formatMessage({ id: btn.props.title.props.id });

      const onClickCallback =
        btn.props.id === 'duplicate-location-button'
          ? btn.props.action
          : this.getAction(btn.props.action, btn.props.confirmText, btn.props.actionLabel, btn.props.critical);

      return (
        <button
          key={btn.props.id}
          type="button"
          className={'location-action-btn ' + btn.props.className}
          onClick={this.handleButtonClick.bind(this, onClickCallback)}
          disabled={btn.props.disabled}
          title={title}
        >
          {btn.props.children}
        </button>
      );
    });
  }

  getAction(action, confirmText, actionLabel, critical) {
    if (confirmText) {
      return () => {
        this.setState({
          actionLabel,
          confirmText,
          critical,
          isModalOpen: true,
          closeModal: this.closeModalFunction(action),
        });
      };
    } else {
      return action;
    }
  }

  closeModalFunction(callback) {
    return confirm => {
      this.setState({ isModalOpen: false }, confirm ? callback : () => {});
    };
  }

  handleButtonClick(callback, ev) {
    ev.stopPropagation();

    callback();
  }
}

export default injectIntl(LocationActionBar);
