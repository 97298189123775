import CollapsableCard from '../../../../CollapsableCard';
import PartnerInfo from '../../../../PartnerInfo';

const PartnerInformationWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //PartnerInfo props
  customerOrder,
  ...partnerInformationModelProps
}) => {
  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      <PartnerInfo customerOrder={customerOrder} {...partnerInformationModelProps} />
    </CollapsableCard>
  );
};

export default PartnerInformationWrapper;
