import React, { Component } from 'react';
import { FrameComponent, LandingComponent } from '../components/views';

class LandingPageLayout extends Component {
  render() {
    return (
      <FrameComponent {...this.props}>
        <LandingComponent {...this.props} />
      </FrameComponent>
    );
  }
}

export default LandingPageLayout;
