import { ModelAbstract } from './';

class CollectionAbstract extends ModelAbstract {
  _collection = [];

  _proxyGet(prop) {
    return this._collection[prop];
  }

  get items() {
    return this._collection;
  }

  get length() {
    return this._collection.length;
  }

  toJS(skipFunctions = false, level = 0, parentUuids = [], cache = {}) {
    this.exportingProps = true;
    const nextLevel = level + 1;

    let result = [];
    const getterFunction = uuid => {
      return cache[uuid];
    };

    for (let i = 0; i < this._collection.length; i++) {
      const model = this._collection[i];

      if (model instanceof ModelAbstract) {
        Object.defineProperty(result, i, {
          enumerable: true,
          configurable: true,
          get: getterFunction.bind(null, model.uuid),
        });

        model.toJS(skipFunctions, nextLevel, parentUuids.concat(this._uuid), cache);
      } else {
        throw new Error(this._className + ' should have Model elements only');
      }
    }

    this.exportingProps = false;

    return result;
  }

  wipe() {
    this._collection = [];

    return true;
  }
}

export default CollectionAbstract;
