import { QUOTE_STATUS } from '../../common/enums';
import ToolbarButtonModel from './ToolbarButtonModel';

class ApprovalModel extends ToolbarButtonModel {
  get disabled() {
    return this.submitForApproval ? this.submitForApprovalBtnDisabled : this.retractApprovalBtnDisabled;
  }

  get errors() {
    return this.submitForApproval ? this.submitForApprovalBtnErrors : this.retractApprovalBtnErrors;
  }

  get errorsCnt() {
    return this.submitForApproval ? this.submitForApprovalBtnErrorsCnt : this.retractApprovalBtnErrorsCnt;
  }

  get label() {
    return this.submitForApproval ? 'msg_submit_for_approval' : 'msg_retract_approval';
  }

  get visible() {
    return (
      this.isNotFinalizedQuoteHasExeptions &&
      !this.areLocationsDone &&
      (!this.isESignatureFlowAllowed ||
        (this.isQuoteAmendingByInternalUser && this.hasSalesExceptionsForNotSeApproved)) &&
      this.isNotQuoteLoading
    );
  }

  get submitForApproval() {
    const { quoteStatus } = this.masterOrder;

    return [QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(quoteStatus);
  }

  get submitForApprovalBtnErrors() {
    const errors = this.errorsForDisabledSubmitForApprovalBtn;

    if (this.appState.errors.salesExceptionsRules.length > 0) {
      errors.push('@msg_items_require_for_approval@', ...this.appState.errors.salesExceptionsRules);
    }

    return errors;
  }

  get errorsForDisabledSubmitForApprovalBtn() {
    const errors = [];

    if (!this.masterOrder.isSpiffConfirmationValid) {
      errors.push('msg_please_acknowledge_customer_discount');
    }

    if (!this.appState.isSaved) {
      errors.push('msg_please_save_before_submitting_for_approval');
    }

    return errors;
  }

  get submitForApprovalBtnErrorsCnt() {
    return this.errorsForDisabledSubmitForApprovalBtn.length + this.appState.errors.salesExceptionsRules.length;
  }

  get submitForApprovalBtnDisabled() {
    return Boolean(this.errorsForDisabledSubmitForApprovalBtn.length || this.appState.disabled);
  }

  get retractApprovalBtnDisabled() {
    return Boolean(this.masterOrder.quoteStatus !== QUOTE_STATUS.SE_PENDING_APPROVAL || this.appState.disabled);
  }

  get retractApprovalBtnErrors() {
    if (this.retractApprovalBtnDisabled && !this.appState.disabled) {
      return '';
    }

    if (this.appState.errors.salesExceptionsRules.length > 0) {
      return ['@msg_items_require_for_approval@', ...this.appState.errors.salesExceptionsRules];
    }

    return '';
  }

  get retractApprovalBtnErrorsCnt() {
    if (!this.appState.disabled && !this.appState.errors.salesExceptionsRules.length) {
      return 0;
    }

    return this.appState.errors.salesExceptionsRules.length;
  }
}

export default ApprovalModel;
