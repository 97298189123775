import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../../common/translate';
import { LabelField, SelectControl } from '../../Controls';

class DealerContactName extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dealerContacts: PropTypes.arrayOf(PropTypes.object),
    dealerName: PropTypes.string,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
  };

  static defaultProps = {
    dealerName: '',
    lockedState: false,
    disabledState: false,
    value: 0,
  };

  render() {
    const { id, onChange, dealerContacts, dealerName, lockedState, disabledState, value } = this.props;
    let selectComponent = null;

    if (dealerContacts.length <= 1) {
      selectComponent = (
        <LabelField
          key="dealerContactNameComponentLabel"
          id="dealerContactName"
          label="msg_quote_owner_name"
          value={dealerName}
        />
      );
    } else {
      selectComponent = (
        <SelectControl
          {...{ id, lockedState, disabledState, value, onChange }}
          label={translator.getMessage('msg_quote_owner_name')}
          tooltipText="Select Quote Owner Name"
          required={true}
          options={this.getDealerOptions(dealerContacts)}
          isReadOnly={false}
        />
      );
    }

    return selectComponent;
  }

  getDealerOptions(dealerContacts) {
    if (Array.isArray(dealerContacts)) {
      return dealerContacts.map(contact => {
        return {
          value: contact.userId,
          text: `${contact.firstName} ${contact.lastName}`,
        };
      });
    } else {
      return [];
    }
  }
}

export default DealerContactName;
