import { ModelAbstract, QuoteModel } from './index';

// TODO: Move it into enums and rename to CALC_RESULT_TYPES
export const RESULT_TYPES = {
  integer: 'integer',
  float: 'float',
  boolean: 'boolean',
  string: 'string',
};

// TODO: Move it into enums
export const CALC_RESULT_PACKAGED = true;

const isNumeric = value => !isNaN(value);

class CalcResultsModel extends ModelAbstract {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this._results = {};
    this.lineItemsWithTieredPrices = {};
    this.getValue = this.getValue.bind(this);

    this._ignoreOnExport.push('quote');
  }

  getValue(prop, type = RESULT_TYPES.string, isPackaged = false) {
    if (isPackaged) {
      const quote = this.quote;

      if (!quote) {
        return null;
      }

      prop += String(quote.packageIdSelected);
    }

    const value = this._results[prop];

    // Return raw null value despite what the type parameter is
    if (value === null) {
      return value;
    }

    // All calculation results comes as a string
    // No need to convert
    if (type === RESULT_TYPES.string) {
      return value;
    }

    if (type === RESULT_TYPES.integer) {
      return isNumeric(value) ? parseInt(Number(value).toFixed(0), 10) : 0;
    }

    if (type === RESULT_TYPES.float) {
      return isNumeric(value) ? parseFloat(Number(value).toFixed(4)) : 0;
    }

    if (type === RESULT_TYPES.boolean) {
      if (isNumeric(value)) {
        return Boolean(Number(value));
      } else if (typeof value === 'string') {
        return value.toLowerCase() === 'true';
      }

      return false;
    }
  }

  defined(prop) {
    return this.results[prop] !== undefined;
  }

  get results() {
    return this._results;
  }

  set results(value) {
    this._results = value;
    this.lineItemsWithTieredPrices = value.lineItemsWithTieredPrices || {};

    delete this._results.lineItemsWithTieredPrices;
  }

  /**
   * Returns quote intstance
   * @returns {(CustomerOrderModel|LocationQuoteModel)}
   */
  get quote() {
    return this.findSibling(d => d instanceof QuoteModel);
  }
}

export default CalcResultsModel;
