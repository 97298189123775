import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { CheckboxControl, SelectControl } from '../Controls';
import { COPY_PROPS } from './ContactSectionProps';
import './css/copy-contacts.css';
import { injectIntl } from 'react-intl';

class CopyContacts extends PureComponent {
  constructor() {
    super();

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleCopyContact = this.handleCopyContact.bind(this);
  }
  static propTypes = {
    handleCopyContacts: PropTypes.func,
    handleCopyBillingWlw: PropTypes.func,
    onChange: PropTypes.func,
    modelName: PropTypes.string,
    copyFromValue: PropTypes.number,
    section: PropTypes.string,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    syncCheckBoxValue: PropTypes.bool,
    isWhiteLabel: PropTypes.bool,
  };

  render() {
    const { section, lockedState, disabledState, contactModel, isWhiteLabel } = this.props;
    const disableSelect = section === 'MASTER_ORDER_BILLING' && isWhiteLabel;

    const { syncCheckBoxValue, copyFromValue } = contactModel;

    return (
      <div className="copy-contacts-wrapper">
        <div className="copy-contacts-input">
          <SelectControl
            id={`copy-contact-select-${section}`}
            key={`copy-contact-select-${section}`}
            value={copyFromValue}
            label={'msg_copy_from'}
            placeholder={'msg_select_source'}
            options={this.getOptions()}
            lockedState={!syncCheckBoxValue || lockedState}
            disabledState={!syncCheckBoxValue || disabledState || disableSelect}
            onChange={this.handleCopyContact}
          />
        </div>
        <div className="copy-contacts-checkbox">
          <CheckboxControl
            id={`copy-contact-checkbox-${section}`}
            key={`copy-contact-checkbox-${section}`}
            value={syncCheckBoxValue}
            label={'msg_sync_from_source'}
            onChange={this.handleOnChange}
            lockedState={lockedState}
            disabledState={disabledState}
          />
        </div>
      </div>
    );
  }

  handleCopyContact(value) {
    this.props.handleCopyContacts(value, this.props.contactModel.uuid, this.props.modelName);
  }

  handleOnChange(value) {
    if (this.props.isWhiteLabel && this.props.section === 'MASTER_ORDER_BILLING' && value) {
      return this.props.handleCopyBillingWlw();
    }

    this.props.onChange('syncCheckBoxValue', value);
  }

  getOptions() {
    return COPY_PROPS[this.props.section].map(({ value, text }) => ({
      value: value,
      text: this.props.intl.formatMessage({ id: text }),
    }));
  }
}

export default injectIntl(CopyContacts);
