import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput';

class NumberInput extends PureComponent {
  static propTypes = {
    ...TextInput.propTypes,
    value: PropTypes.number,
    allowNullValue: PropTypes.bool,
    showScroller: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    forceNegativeValue: PropTypes.bool,
    allowNegativeValue: PropTypes.bool,
  };

  static defaultProps = {
    allowNullValue: false,
    showScroller: true,
    minValue: 0,
    maxValue: 99999,
    forceNegativeValue: false,
    allowNegativeValue: false,
  };

  constructor(props) {
    super(props);

    this.handleBeforeCommit = this.handleBeforeCommit.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
    this.handleBeforeStateChange = this.handleBeforeStateChange.bind(this);
    this.handleValueIncrement = this.handleValueIncrement.bind(this);
    this.handleValueDecrement = this.handleValueDecrement.bind(this);
  }

  __getValue(stringValue) {
    return this.props.allowNegativeValue ? Number(stringValue) : Math.abs(Number(stringValue));
  }

  handleBeforeStateChange(stringValue) {
    // Override behaviour by HoC
    if (typeof this.props.onBeforeStateChange === 'function') {
      return this.props.onBeforeStateChange(stringValue);
    }

    // Else use default
    return this.isValidValue(stringValue);
  }

  handleBeforeCommit(stringValue) {
    // Override behaviour by HoC
    if (typeof this.props.onBeforeCommit === 'function') {
      return this.props.onBeforeCommit(stringValue);
    }

    // Else use default
    if (stringValue === '' && this.props.allowNullValue) {
      return true;
    }

    return this.isValidValue(stringValue);
  }

  handleCommit(stringValue) {
    // Override behaviour by HoC
    if (typeof this.props.onCommit === 'function') {
      return this.props.onCommit(stringValue);
    }

    // Else use default
    if (typeof this.props.onChange === 'function') {
      let numberValue = stringValue === '' && this.props.allowNullValue ? null : this.__getValue(stringValue) || 0;

      if (this.props.forceNegativeValue && numberValue !== null) {
        numberValue = -1 * numberValue;
      }

      this.props.onChange(numberValue);

      return true;
    }

    return false;
  }

  /**
   * @param {number} factor
   * @returns {boolean}
   */
  handleScrollerClick(factor) {
    const value = this.props.value;
    let stringValue = '';

    if (this.props.forceNegativeValue) {
      factor *= -1;
    }

    if (value === null && factor > 0) {
      stringValue = '0';
    } else if (typeof value === 'number' && !(value === 0 && factor === -1)) {
      stringValue = (this.__getValue(value) + factor).toString();
    } else if (typeof value === 'number' && this.props.allowNegativeValue && value === 0 && factor === -1) {
      stringValue = '-1';
    }
    // Unexpected behaviour. "value" prop is not number or null
    else {
      return false;
    }

    if (this.handleBeforeCommit(stringValue)) {
      this.handleCommit(stringValue);
    }

    return true;
  }

  handleValueIncrement() {
    return this.handleScrollerClick(+1);
  }

  handleValueDecrement() {
    return this.handleScrollerClick(-1);
  }

  renderScroller() {
    const ariaLabel = this.props.ariaLabel ? this.props.ariaLabel + ' ' : '';

    return (
      <div className="number-input-btn-wrapper">
        <button
          type="button"
          tabIndex="-1"
          className="number-input-btn no-border no-bg tertiary"
          onClick={this.handleValueIncrement}
          disabled={this.props.disabled}
          aria-label={ariaLabel + 'increase value'}
        >
          <span className="icon-expand-less" />
        </button>
        <button
          type="button"
          tabIndex="-1"
          className="number-input-btn no-border no-bg tertiary"
          onClick={this.handleValueDecrement}
          disabled={this.props.disabled}
          aria-label={ariaLabel + 'decrease value'}
        >
          <span className="icon-expand-more" />
        </button>
      </div>
    );
  }

  isValidValue(stringValue) {
    const numberValue = this.__getValue(stringValue);

    return (
      (!isNaN(numberValue) && numberValue <= this.props.maxValue && numberValue >= this.props.minValue) ||
      (this.props.allowNegativeValue && stringValue === '-')
    );
  }

  render() {
    let value = typeof this.props.value === 'number' ? this.props.value.toString() : this.props.value;
    const className = ['number-input-field'];

    if (typeof this.props.className === 'string') {
      className.push(this.props.className);
    }

    return (
      <React.Fragment>
        <TextInput
          {...this.props}
          className={className.join(' ')}
          value={value}
          type={'number'}
          onBeforeStateChange={this.handleBeforeStateChange}
          onBeforeCommit={this.handleBeforeCommit}
          onCommit={this.handleCommit}
        />
        {this.props.showScroller && this.renderScroller()}
      </React.Fragment>
    );
  }
}

export default NumberInput;
