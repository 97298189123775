import React from 'react';
import classNames from 'classnames';
import { string, bool, func } from 'prop-types';
import translator from '../../../../../common/translate';

const HeaderCell = ({
  className = '',
  formatMessage,
  hoverLabel = '',
  id,
  label,
  onChangeSort,
  sortAsc = false,
  sortBy = '',
  visible = false,
}) => {
  const titleText = formatMessage({ id: hoverLabel || label });
  const cellClassName = classNames({
    'quotes-table-header-cell': true,
    sortable: true,
    asc: sortBy === id && sortAsc,
    desc: sortBy === id && !sortAsc,
    [className]: true,
    'align-right': ['masterOrderId', 'id', 'upfrontTotal', 'recurringTotal'].includes(id),
  });

  if (!visible) {
    return null;
  }

  return (
    <th className={cellClassName} data-column-id={id} onClick={onChangeSort} title={titleText}>
      {translator.getMessage(label)}
    </th>
  );
};

HeaderCell.propTypes = {
  className: string,
  formatMessage: func.isRequired,
  hoverLabel: string,
  id: string.isRequired,
  label: string.isRequired,
  onChangeSort: func.isRequired,
  sortAsc: bool,
  sortBy: string,
  visible: bool,
};

export default HeaderCell;
