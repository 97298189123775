import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HiddenFormHelper from '../HiddenFormHelper';
import { getUserUTCTimezone } from '../../common/helpers/getUserUTCTimezone';
import config from '../../config';
import { AuthContext } from '../../Context';

class PdfHiddenForm extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
  };

  render() {
    const { id, endpoint, onSubmit } = this.props;

    return (
      <HiddenFormHelper
        id={id}
        action={config.api.url + endpoint}
        method={HiddenFormHelper.methods.get}
        values={{
          token: AuthContext.model.token,
          userTimezone: getUserUTCTimezone(),
        }}
        target={HiddenFormHelper.targets.blank}
        onSubmit={onSubmit}
      />
    );
  }
}

export default PdfHiddenForm;
