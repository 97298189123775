import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AmountInput } from '../../Inputs';
import AbstractControl from '../AbstractControl';
import '../css/amount-input.css';

class AmountControl extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isChecked: PropTypes.bool,
    isShowArrow: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    allowBlankField: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    checkbox: PropTypes.bool,
    valueType: PropTypes.string,
    marker: PropTypes.string,
    ariaLabel: PropTypes.string,
    decimals: PropTypes.number,
  };

  static defaultProps = {
    isShowArrow: true,
    allowBlankField: false,
    isChecked: true,
    marker: '$',
    decimals: 2,
  };

  render() {
    const className = classNames(
      {
        'amount-input-component': true,
        'with-arrows': this.props.isShowArrow,
      },
      this.props.className
    );

    const inputAbstractProps = {
      hint: this.props.hint,
      style: this.props.style,
      label: this.props.label,
      errorText: this.props.errorText,
      required: this.props.required,
      requiredBlue: this.props.requiredBlue,
      disabledState: this.props.disabledState,
      tooltipText: this.props.tooltipText,
      parentClassName: this.props.parentClassName,
      checkbox: this.props.checkbox,
      className: className,
      labelFor: this.props.id,
    };

    return (
      <AbstractControl {...inputAbstractProps}>
        <AmountInput
          id={this.props.id}
          onChange={this.props.onChange}
          disabled={this.props.lockedState || this.props.disabledState || !this.props.isChecked}
          value={this.props.value}
          marker={this.props.marker}
          doFocus={this.props.focus}
          allowNullValue={this.props.allowBlankField}
          minValue={this.props.min}
          maxValue={this.props.max}
          showScroller={this.props.isShowArrow}
          forceNegativeValue={this.props.valueType === 'negative'}
          ariaLabel={this.props.ariaLabel}
          decimals={this.props.decimals}
        />
      </AbstractControl>
    );
  }
}

export default AmountControl;
