export function getUserUTCTimezone() {
  // UTC timezone offset in minutes
  let offset = new Date().getTimezoneOffset();
  const divider = offset < 0 ? '+' : '-';

  offset = Math.abs(offset);

  let h = Math.floor(offset / 60);
  let m = offset % 60;

  h = (h < 10 ? '0' + h : h).toString();
  m = (m < 10 ? '0' + m : m).toString();

  return 'UTC' + divider + h + m;
}
