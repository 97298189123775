import { shape, string, bool, number } from 'prop-types';

const rcPropType = shape({
  className: string,
  value: string,
});

export const hudPackagePropType = shape({
  actionColor: string,
  available: bool,
  color: string,
  id: number,
  label: string,
  nrc: rcPropType,
  packageClassName: string,
  rc: rcPropType,
});
