import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export default class Page extends Component {
  static propTypes = {
    pageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    pageNumber: PropTypes.number.isRequired,
    onClick: PropTypes.func,
    isActive: PropTypes.bool.isRequired,
    className: PropTypes.string,
    icon: PropTypes.object,
    ariaLabel: PropTypes.string,
  };

  static defaultProps = {
    activeClass: 'active',
  };

  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(ev) {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick(Number(ev.currentTarget.value) || 0);
    }
  }

  render() {
    const text = this.props.pageText || this.props.pageNumber;
    const activeClass = this.props.activeClass;
    const className = this.props.className;

    if (React.isValidElement(text)) {
      return text;
    }

    return (
      <button
        className={cx({
          [activeClass]: this.props.isActive,
          [className]: !!className,
          tertiary: !this.props.isActive,
        })}
        value={this.props.pageNumber}
        onClick={this.handleClick}
        aria-label={this.props.ariaLabel}
      >
        {(this.props.icon || text.trim()) && (
          <span className="page-button-text">{this.props.icon ? this.props.icon : text}</span>
        )}
      </button>
    );
  }
}
