import React from 'react';
import PropTypes from 'prop-types';
import PureViewComponent from '../../../components/PureViewComponent';
import translator from '../../../common/translate';
import * as flags from '../../../assets/flags';
import { LOCALES } from '../../../common/enums';

class DropDown extends PureViewComponent {
  static propTypes = {
    signOut: PropTypes.func,
    switchLanguage: PropTypes.func,
    initials: PropTypes.string,
    userName: PropTypes.string,
    locale: PropTypes.string,
  };

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.handleLogout = this.handleLogout.bind(this);
    this.showDropDown = this.showDropDown.bind(this);
    this.hideDropdown = this.hideDropdown.bind(this);
    this.handleSwitchLanguage = this.handleSwitchLanguage.bind(this);
  }

  componentDidMount() {
    this.dropDownElem = document.querySelector('.header-dropdown-menu');
  }

  handleLogout() {
    if (typeof this.props.signOut === 'function') {
      this.props.signOut();
    }
  }

  handleSwitchLanguage(locale) {
    if (typeof this.props.switchLanguage === 'function') {
      this.props.switchLanguage(locale);
    }
  }

  showDropDown(e) {
    e.stopPropagation();

    this.dropDownElem.classList.toggle('open', true);
    this.dropDownElem.querySelector('.header-dropdown-menu-item').parentNode.focus();

    document.addEventListener('click', this.hideDropdown);
  }

  hideDropdown() {
    this.dropDownElem.classList.toggle('open', false);

    document.removeEventListener('click', this.hideDropdown);
  }

  render() {
    const { initials, userName } = this.props;

    return (
      <>
        <div className="header-avatar" title={userName} onClick={this.showDropDown}>
          {initials.toUpperCase()}
        </div>
        <div className="header-dropdown-menu">
          <div
            className={`header-dropdown-menu-item ${this.props.locale === LOCALES.enUS && 'selected'}`}
            onClick={() => this.handleSwitchLanguage(LOCALES.enUS)}
          >
            <div>
              <img src={flags.UnitedStates} alt="English" />
              <span>English</span>
            </div>
          </div>
          <div
            className={`header-dropdown-menu-item ${this.props.locale === LOCALES.esES && 'selected'}`}
            onClick={() => this.handleSwitchLanguage(LOCALES.esES)}
          >
            <div>
              <img src={flags.Spain} alt="Español" />
              <span>Español</span>
            </div>
          </div>
          <div
            className={`header-dropdown-menu-item ${this.props.locale === LOCALES.frFR && 'selected'}`}
            onClick={() => this.handleSwitchLanguage(LOCALES.frFR)}
          >
            <div>
              <img src={flags.France} alt="Français" />
              <span>Français</span>
            </div>
          </div>
          <div className="separator" />
          <div className="header-dropdown-menu-item" onClick={this.handleLogout}>
            <span className="icon-exit" />
            {translator.getMessage('msg_sign_out')}
          </div>
        </div>
      </>
    );
  }
}

export default DropDown;
