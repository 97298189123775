import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextControl, ToggleControl } from '../Controls';
import { TextInput } from '../Inputs';
import bindMultiple from '../../common/helpers/bindMultiple';
import './css/leaseOptionsEmailForm.css';
import BadgeButton from '../BadgeButton';
import Dialog from '../Dialog';
import isValidEmail from '../../common/helpers/isValidEmail';
import { injectIntl } from 'react-intl';
import translator from '../../common/translate';

const ID_PREFIX = 'credit-application-';

class LeaseOptionEmailForm extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    isQuickQuoteAvailable: PropTypes.bool,
  };

  static defaultProps = {
    isOpen: true,
  };

  constructor(props) {
    super(props);

    const intl = props.intl;

    this.state = {
      subject: intl.formatMessage({ id: 'msg_lease_form_subject_value' }),
      bodyPart1: intl.formatMessage({ id: 'msg_lease_form_body_part_one_value' }),
      bodyPart2: intl.formatMessage({ id: 'msg_lease_form_body_part_two_value' }),
      customerEmail: null,
      additionalCCEmail1: '',
      additionalCCEmail2: '',
      includeQuickQuote: false,
    };

    bindMultiple(
      this,
      this.handleSubmit,
      this.handleMessageChange,
      this.handleEmailChange,
      this.handleCcEmailOneChange,
      this.handleCcEmailTwoChange,
      this.handleQuickQuoteToggle
    );
  }

  handleSubmit() {
    if (!isValidEmail(this.state.customerEmail)) {
      return false;
    }

    if (typeof this.props.onSubmit === 'function') {
      this.props.onSubmit({
        ...this.state,
        includeQuickQuote: !this.props.isQuickQuoteAvailable ? false : this.state.includeQuickQuote,
      });
    }

    return true;
  }

  handleChange(propName, value) {
    this.setState(state => ({ [propName]: value }));
  }

  handleMessageChange(ev) {
    return this.handleChange('bodyPart1', ev.target.value);
  }

  handleEmailChange(value) {
    return this.handleChange('customerEmail', value);
  }

  handleCcEmailOneChange(value) {
    return this.handleChange('additionalCCEmail1', value);
  }

  handleCcEmailTwoChange(value) {
    return this.handleChange('additionalCCEmail2', value);
  }

  handleQuickQuoteToggle() {
    this.setState(state => ({ includeQuickQuote: !state.includeQuickQuote }));
  }

  render() {
    if (!this.props.isOpen) {
      return null;
    }

    const errors = [];

    if (!isValidEmail(this.state.customerEmail)) {
      errors.push('msg_lease_form_submit_error_invalid_customer_email');
    }

    return (
      <Dialog
        isOpen={true}
        title={this.props.intl.formatMessage({ id: 'msg_lease_form_title' })}
        actions={[
          <BadgeButton
            key={ID_PREFIX + 'submit'}
            btnClassName="primary"
            onClick={this.handleSubmit}
            label={this.props.intl.formatMessage({ id: 'msg_lease_form_submit' })}
            errors={errors}
            errorsCnt={errors.length}
            disabled={Boolean(errors.length)}
          />,
          <button key={ID_PREFIX + 'cancel'} className="tertiary" onClick={this.props.onCancel}>
            {translator.getMessage('msg_lease_form_cancel')}
          </button>,
        ]}
      >
        <form className="lease-application-form" onSubmit={this.handleSubmit}>
          <div className="fieldset">
            <label>{translator.getMessage('msg_lease_form_subject')}</label>
            <span className="input-like-string disabled">{this.state.subject}</span>

            <label htmlFor={ID_PREFIX + 'message'}>
              <span>{translator.getMessage('msg_lease_form_body_part_one')}</span>
              <small>{translator.getMessage('msg_lease_form_body_part_one_sub_label')}</small>
            </label>
            <textarea id={ID_PREFIX + 'message'} onChange={this.handleMessageChange} value={this.state.bodyPart1} />

            <label>
              <span>{translator.getMessage('msg_lease_form_body_part_two')}</span>
              <small>{translator.getMessage('msg_lease_form_body_part_two_sub_label')}</small>
            </label>
            <span className="textarea-like-string disabled">{this.state.bodyPart2}</span>
          </div>

          <div className="emails-section">
            <label className="main" htmlFor={ID_PREFIX + 'customer-email'}>
              <span>{translator.getMessage('msg_lease_form_customer_email')}</span>
              <small>{translator.getMessage('msg_lease_form_customer_email_sub_label')}</small>
            </label>
            <TextControl
              parentClassName="email"
              id={ID_PREFIX + 'customer-email'}
              type="email"
              onChange={this.handleEmailChange}
              value={this.state.customerEmail}
              errorText={
                this.state.customerEmail !== null && !isValidEmail(this.state.customerEmail)
                  ? 'msg_lease_form_customer_email_error'
                  : null
              }
            />

            <label className="cc-emails-label">{translator.getMessage('msg_lease_form_additional_cc_emails')}</label>
            <TextInput
              className="cc-email-one"
              id={ID_PREFIX + 'cc-email-one'}
              type="email"
              onChange={this.handleCcEmailOneChange}
              value={this.state.additionalCCEmail1}
            />
            <TextInput
              className="cc-email-two"
              id={ID_PREFIX + 'cc-email-two'}
              type="email"
              onChange={this.handleCcEmailTwoChange}
              value={this.state.additionalCCEmail2}
            />

            <ToggleControl
              parentClassName="quick-quote-toggle"
              id={ID_PREFIX + 'quick-quote'}
              value={!this.props.isQuickQuoteAvailable ? false : this.state.includeQuickQuote}
              onChange={this.handleQuickQuoteToggle}
              label={'msg_lease_form_include_quick_quote'}
              disabledState={!this.props.isQuickQuoteAvailable}
            />
          </div>
        </form>
      </Dialog>
    );
  }
}

export default injectIntl(LeaseOptionEmailForm);
