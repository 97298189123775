import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const formsContainerId = 'hidden-form-helper-container';
const containerNode = document.createElement('div');

containerNode.setAttribute('id', formsContainerId);
containerNode.style.display = 'none';

if (!document.getElementById(formsContainerId)) {
  document.body.appendChild(containerNode);
}

class HiddenFormHelper extends Component {
  static methods = {
    get: 'get',
    post: 'post',
  };

  static targets = {
    blank: '_blank',
    self: '_self',
    parent: '_parent',
    top: '_top',
  };

  static propTypes = {
    id: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    target: PropTypes.string,
    method: PropTypes.oneOf([this.methods.get, this.methods.post]),
    values: PropTypes.object,
    onSubmit: PropTypes.func,
    enctype: PropTypes.string,
  };

  constructor(...args) {
    super(...args);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static defaultProps = {
    method: this.methods.get,
    target: this.targets.blank,
    values: {},
    enctype: 'application/x-www-form-urlencoded',
  };

  handleSubmit(event) {
    if (typeof this.props.onSubmit === 'function') {
      return this.props.onSubmit(event);
    }

    return true;
  }

  getNameValuePairs(value, inputName = '') {
    let names = [];
    let values = [];

    if (Array.isArray(value)) {
      for (let i = 0; i < value.length; i++) {
        const childPairs = this.getNameValuePairs(value[i], inputName);

        names = names.concat(childPairs.names);
        values = values.concat(childPairs.values);
      }

      return { names, values };
    }

    if (typeof value === 'object' && value !== null) {
      const objectKeys = Object.keys(value);

      for (let i = 0; i < objectKeys.length; i++) {
        const objectKey = objectKeys[i];
        const childPairs = this.getNameValuePairs(
          value[objectKey],
          inputName ? inputName + '[' + objectKey + ']' : objectKey
        );

        names = names.concat(childPairs.names);
        values = values.concat(childPairs.values);
      }

      return { names, values };
    }

    names.push(inputName);
    values.push(typeof value === 'number' ? value.toString() : value);

    return { names, values };
  }

  getInputs(inputData) {
    const { names, values } = this.getNameValuePairs(inputData);
    const inputsArray = [];

    for (let i = 0; i < names.length; i++) {
      inputsArray.push(
        <input key={'hidden-form-helper-input-' + i} name={names[i]} value={values[i]} type="hidden" hidden />
      );
    }

    return inputsArray;
  }

  render() {
    const { id, action, method, target, values, enctype } = this.props;

    const form = (
      <form id={id} action={action} method={method} target={target} onSubmit={this.handleSubmit} encType={enctype}>
        {this.getInputs(values)}
      </form>
    );

    return createPortal(form, document.getElementById(formsContainerId));
  }
}

export default HiddenFormHelper;
