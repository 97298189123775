import React from 'react';
import {
  ADMIN_SECTION_SWITCHVOX_ON_PREM_TERM_OPTIONS,
  ADMIN_SECTION_SWITCHVOX_ON_PREM_TYPES_OPTIONS,
  FINANCING_DROPDOWN_OPTIONS,
  SOLUTION_TYPES_DROPDOWN,
  SOLUTION_TYPES_W_SWITCHVOX_DROPDOWN,
  SOLUTION_TYPES_W_STANDALONE_DROPDOWN,
  SOLUTION_TYPES_W_SANGOMA_CX_DROPDOWN,
} from '../../common/enums';
import { DatePickerControl, LabelField, SelectControl, TextControl, ToggleControl } from '../Controls';
import './nested/admin-section.css';
import HiddenFormHelper from '../HiddenFormHelper';
import translator from '../../common/translate';
import BadgeButton from '../BadgeButton';
import { injectIntl } from 'react-intl';
import {
  ADMIN_SECTION_OVERRIDE_OPTIONS,
  ADMIN_SECTION_DAAS_OVERRIDE_OPTIONS,
  ADMIN_SECTION_SANGOMA_CX_OVERRIDE_OPTIONS,
  EXISTING_SPEECH_ANALYTICS_OVERRIDE_OPTIONS,
} from '../../common/enums/adminSectionOverrideOptions';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { PriceBookContextModel } from '../../Context';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const AdminSection = ({
  intl,
  customerOrder,
  lockedState,
  disabledState,
  showSpeechAnalyticsControls,
  showAddOnFinancingTermMonths,
  solutionTypeDropdownDisabled,
  showCustomerSangomaCxControls,
  showSwitchvoxOverrides,
  showContractRewrite,
  showCustomerDaasOverride,
  customerOverridesDisabled,
  showRentalAgreementFields,
  showCreditDocumentsFields,
  expirationOverrideDisabled,
  showFinancingOption,
  financingOptionDisabled,
  showAmendmentFields,
  showEsigFlowToggle,
  lineItemsLength,
  showInHouseSystemToggle,
  showDealerDemoKitToggleControl,
  showSkipProrationLabel,
  showSaSigningDateToggleControl,
  showSolutionTypeIdSelect,
  showExpirationDateControls,
  onExpirationDateOverrideChange,
  unsetExpirationOverride,
  onChangeDealerDemoKit,
}) => {
  const __getLabel = prop => {
    return customerOrder[prop]
      ? intl.formatMessage({ id: 'msg_yes_set_by_rqqid' }) + ' ' + customerOrder[prop]
      : intl.formatMessage({ id: 'msg_no' });
  };

  const __getSolutionTypeOptions = () => {
    const { isSupportsSwitchvoxSIPStation } = PriceBookContextModel;
    const { standaloneServiceNonUCaaS, sangomaCXStandalone } = customerOrder;

    if (isSupportsSwitchvoxSIPStation) {
      return SOLUTION_TYPES_W_SWITCHVOX_DROPDOWN;
    }

    if (standaloneServiceNonUCaaS) {
      return SOLUTION_TYPES_W_STANDALONE_DROPDOWN;
    }

    if (sangomaCXStandalone) {
      return SOLUTION_TYPES_W_SANGOMA_CX_DROPDOWN;
    }

    return SOLUTION_TYPES_DROPDOWN;
  };

  const customerCreditDocumentsLabel = __getLabel('creditDocumentsFromQuoteId');
  const customerRentalAgreementLabel = __getLabel('greatAmericaRentalAgreementFromQuoteId');
  const customerDaasLabel = __getLabel('existingDaasCustomerFromQuoteId');
  const customerSangomaCxLabel = __getLabel('existingSangomaCXCustomerFromQuoteId');
  const existingSpeechAnalyticsCustomerLabel = __getLabel('existingSpeechAnalyticsCustomerFromQuoteId');

  return (
    <div className="admin-section-content">
      {/*LEFT SECTION START*/}
      <div className="left-section">
        {showContractRewrite ? (
          <ToggleControl
            id="contractRewrite"
            key="contractRewriteToggleControl"
            label="msg_contract_rewrite"
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder.contractRewrite}
            onChange={customerOrder.onContractRewriteChange}
          />
        ) : null}
        {showInHouseSystemToggle && (
          <ToggleControl
            id="inHouseSystem"
            key="inHouseSystemToggleControl"
            label="msg_in_house_system"
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder.inHouseSystem}
            onChange={customerOrder.onInHouseSystemChange}
          />
        )}
        {showDealerDemoKitToggleControl && (
          <ToggleControl
            id="dealerDemoKit"
            key="dealerDemoKitToggleControl"
            label="msg_dealer_demo_kit"
            lockedState={lockedState}
            disabledState={disabledState || customerOrder.inHouseSystem || !lineItemsLength}
            value={customerOrder.dealerDemoKit}
            onChange={onChangeDealerDemoKit}
          />
        )}
        {showEsigFlowToggle && (
          <ToggleControl
            id="useESigDocumentsFlow"
            key="useESigDocumentsFlowToggleControl"
            label="msg_use_electronic_signature_flow"
            lockedState={lockedState}
            disabledState={customerOrder.eSigFlowToggleDisabled || disabledState}
            value={customerOrder.useESigDocumentsFlow}
            onChange={customerOrder.onUseESigDocumentsFlowChange}
          />
        )}
        {showSkipProrationLabel && (
          <ToggleControl
            id="skipProration"
            key="skipProration"
            label="msg_skip_proration"
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder.skipProration}
            onChange={customerOrder.onSkipProrationChange}
          />
        )}
        <ToggleControl
          id="overrideFreeMonthPromo"
          key="overrideFreeMonthPromo"
          label="msg_override_free_month_promo"
          lockedState={lockedState}
          disabledState={disabledState}
          value={customerOrder.overrideFreeMonthPromoRules}
          onChange={customerOrder.onOverrideFreeMonthPromoRulesChange}
        />
      </div>

      {/*RIGHT SECTION*/}
      <div className="right-section">
        {showAmendmentFields && (
          <>
            <TextControl
              id="amendmentTaskNumber"
              key="amendmentTaskNumberToggleControl"
              label="msg_amendment_task_number"
              disabledState={disabledState}
              lockedState={lockedState}
              value={customerOrder.amendmentTaskNumber}
              onChange={customerOrder.onAmendmentTaskNumberChange}
            />
            {showSaSigningDateToggleControl && (
              <TextControl
                id="saSigningDate"
                key="saSigningDateToggleControl"
                label="msg_sa_signed_date"
                disabledState={disabledState}
                lockedState={lockedState}
                value={customerOrder.saSigningDate}
                onChange={customerOrder.onSaSigningDateChange}
              />
            )}
          </>
        )}
        {showFinancingOption && (
          <SelectControl
            id="financingOption"
            key="financingOptionSelectControl"
            label="msg_financing_option"
            lockedState={lockedState}
            disabledState={disabledState || financingOptionDisabled}
            value={customerOrder.financingOption}
            options={FINANCING_DROPDOWN_OPTIONS}
            onChange={customerOrder.onFinancingOptionChange}
          />
        )}
        {showAddOnFinancingTermMonths && (
          <SelectControl
            id="addOnFinancingTermMonthsLabel"
            key="addOnFinancingTermMonthsLabel"
            label="msg_financing_term_length"
            parentClassName="short-input"
            className="right-align"
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder.addOnFinancingTermMonths}
            options={customerOrder.contractTermLengthOptions}
            onChange={customerOrder.onAddOnFinancingTermMonthsChange}
            fullWidth
          />
        )}
        {showSolutionTypeIdSelect && (
          <SelectControl
            id="solutionTypeId"
            label="msg_solution_type"
            key="solutionTypeIdSelectControl"
            lockedState={lockedState}
            disabledState={solutionTypeDropdownDisabled}
            value={customerOrder.solutionTypeId}
            options={__getSolutionTypeOptions()}
            onChange={customerOrder.onSolutionTypeIdChange}
          />
        )}
        {showExpirationDateControls && (
          <>
            <LabelField
              id="expirationDateLabelField"
              key="expirationDateLabelField"
              label="msg_expiration_date"
              value={
                customerOrder.globalExpirationDate
                  ? dayjs.utc(customerOrder.globalExpirationDate).tz('America/New_York').format('MMMM Do, YYYY')
                  : 'N/A'
              }
              textRight
            />
            <DatePickerControl
              id="expirationOverride"
              label="msg_expiration_date_override"
              className="expiration-override"
              value={customerOrder.expirationDateOverride}
              lockedState={lockedState}
              disabledState={disabledState || expirationOverrideDisabled}
              onUnsetDate={unsetExpirationOverride}
              onChange={onExpirationDateOverrideChange}
              timezone="America/New_York"
              minDate={0}
            />
          </>
        )}
        <div className="admin-section-csv-buttons">
          {customerOrder.billedServiceCsvDownloadButton.visible && (
            <React.Fragment>
              <BadgeButton
                {...customerOrder.billedServiceCsvDownloadButton}
                disabled={disabledState}
                id="billed-services-csv-download-button"
                form="billed-services-csv-download-form"
                label={translator.getMessage(customerOrder.billedServiceCsvDownloadButton.label)}
              />
              <HiddenFormHelper
                id="billed-services-csv-download-form"
                action={customerOrder.billedServiceCsvDownloadButton.downloadUrl}
                method={HiddenFormHelper.methods.post}
                values={customerOrder.billedServiceCsvDownloadButton.locationIdsAndNames}
              />
            </React.Fragment>
          )}
          {customerOrder.extensionsCsvDownloadButton.visible && (
            <React.Fragment>
              <BadgeButton
                {...customerOrder.extensionsCsvDownloadButton}
                disabled={disabledState}
                id="extensions-csv-download-button"
                form="extensions-csv-download-form"
                label={translator.getMessage(customerOrder.extensionsCsvDownloadButton.label)}
              />
              <HiddenFormHelper
                id="extensions-csv-download-form"
                action={customerOrder.extensionsCsvDownloadButton.downloadUrl}
                method={HiddenFormHelper.methods.post}
                values={customerOrder.extensionsCsvDownloadButton.locationIds}
              />
            </React.Fragment>
          )}
          {showCreditDocumentsFields && (
            <>
              <LabelField
                id="customerCreditDocumentOnFile"
                label="msg_customer_credit_document"
                value={customerCreditDocumentsLabel}
                textRight
              />
              <SelectControl
                id="creditDocumentsOnFileOverride"
                label="msg_credit_document_override"
                key="creditDocumentsOnFileOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.creditDocumentsOnFileOverride}
                options={ADMIN_SECTION_OVERRIDE_OPTIONS}
                onChange={customerOrder.onCreditDocumentsOnFileOverrideChange}
                autoColumns
              />
            </>
          )}
          {showRentalAgreementFields && (
            <>
              <LabelField
                id="customerGreatAmericaRentalAgreementOnFile"
                label="msg_customer_rental_agreement"
                value={customerRentalAgreementLabel}
                textRight
              />
              <SelectControl
                id="greatAmericaRentalAgreementOnFileOverride"
                label="msg_rental_agreement_override"
                key="greatAmericaRentalAgreementOnFileOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.greatAmericaRentalAgreementOnFileOverride}
                options={ADMIN_SECTION_OVERRIDE_OPTIONS}
                onChange={customerOrder.onGreatAmericaRentalAgreementOnFileOverrideChange}
                autoColumns
              />
            </>
          )}
          {showCustomerDaasOverride && (
            <>
              <LabelField
                id="customerDaasLabel"
                label="msg_customer_level_existing_daas_customer"
                value={customerDaasLabel}
                textRight
              />
              <SelectControl
                id="existingDaasCustomerOverride"
                label="msg_existing_daas_customer_override"
                key="existingDaasCustomerOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.existingDaasCustomerOverride}
                options={ADMIN_SECTION_DAAS_OVERRIDE_OPTIONS}
                onChange={customerOrder.onExistingDaasCustomerOverrideChange}
                autoColumns
              />
            </>
          )}
          {showSwitchvoxOverrides && (
            <>
              <SelectControl
                id="switchvoxOnPremMaintTypeOverride"
                label="msg_switchvox_type_override"
                key="switchvoxOnPremMaintTypeOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.switchvoxOnPremMaintTypeOverride}
                options={ADMIN_SECTION_SWITCHVOX_ON_PREM_TYPES_OPTIONS}
                onChange={customerOrder.onSwitchvoxOnPremMaintTypeOverrideChange}
                autoColumns
              />
              <SelectControl
                id="switchvoxOnPremMaintTermYearsOverride"
                label="msg_switchvox_term_override"
                key="switchvoxOnPremMaintTermYearsOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.switchvoxOnPremMaintTermYearsOverride}
                options={ADMIN_SECTION_SWITCHVOX_ON_PREM_TERM_OPTIONS}
                onChange={customerOrder.onSwitchvoxOnPremMaintTermYearsOverrideChange}
                autoColumns
              />
            </>
          )}
          {showCustomerSangomaCxControls && (
            <>
              <LabelField
                id="customerSangomaCxLabel"
                label="msg_customer_level_existing_sangoma_cx"
                value={customerSangomaCxLabel}
                textRight
              />
              <SelectControl
                id="customerSangomaCxOverride"
                label="msg_existing_sangoma_cx_override"
                key="customerSangomaCxOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.existingSangomaCXCustomerOverride}
                options={ADMIN_SECTION_SANGOMA_CX_OVERRIDE_OPTIONS}
                onChange={customerOrder.onExistingSangomaCXCustomerOverrideChange}
                autoColumns
              />
            </>
          )}
          {showSpeechAnalyticsControls && (
            <>
              <LabelField
                id="existingSpeechAnalyticsCustomerLabel"
                label="msg_customer_level_existing_speech_analytics_customer"
                value={existingSpeechAnalyticsCustomerLabel}
                textRight
              />
              <SelectControl
                id="existingSpeechAnalyticsCustomerOverride"
                label="msg_existing_speech_analytics_customer_override"
                key="existingSpeechAnalyticsCustomerOverride"
                lockedState={lockedState}
                disabledState={disabledState || customerOverridesDisabled}
                value={customerOrder.existingSpeechAnalyticsCustomerOverride}
                options={EXISTING_SPEECH_ANALYTICS_OVERRIDE_OPTIONS}
                onChange={customerOrder.onExistingSpeechAnalyticsCustomerOverrideChange}
                autoColumns
              />
            </>
          )}
        </div>
        <TextControl
          id="seJustification"
          key="seJustification"
          label="msg_se_justification"
          disabledState={disabledState}
          lockedState={lockedState}
          value={customerOrder.salesExceptionJustification}
          onChange={customerOrder.onSalesExceptionJustificationChange}
        />
      </div>
    </div>
  );
};

export default injectIntl(AdminSection);
