import React from 'react';
import config from '../config';
import { ContextConnect } from './index';
import ContextModel from '../Context/ContextModel';
import { LOCALES } from '../common/enums';

const context = React.createContext();

class IntlContextModel extends ContextModel {
  constructor() {
    super();

    if (!localStorage.getItem('locale')) {
      localStorage.setItem('locale', config.locale);
    }

    this._locale = localStorage.getItem('locale');

    this.isEnglishLocale = this.isEnglishLocale.bind(this);
  }

  get locale() {
    return this._locale;
  }

  set locale(v) {
    localStorage.setItem('locale', v);

    this._locale = v;
  }

  isEnglishLocale() {
    return this.locale === LOCALES.enUS;
  }
}

const model = new IntlContextModel();
const Provider = ContextConnect(context, model);
const Consumer = context.Consumer;

const IntlContext = {
  Consumer,
  Provider,
  model,
};

export default IntlContext;
