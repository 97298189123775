import React from 'react';
import { func, number } from 'prop-types';
import translator from '../../../common/translate';
import '../css/results-count.css';

const ResultsCount = ({ clearFieldHandler = () => {}, count = 0 }) => (
  <button className="field-clear-btn" onClick={clearFieldHandler}>
    <span className="clear-btn-text">
      {count}
      {'\u00a0'}
      {translator.getMessage('msg_results')}
    </span>
    <span className="icon-close" />
  </button>
);

ResultsCount.propTypes = {
  clearFieldHandler: func,
  count: number,
};

export default ResultsCount;
