import React, { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tooltip from './nested/Tooltip';
import './css/badge-button.css';

class BadgeButton extends PureComponent {
  static propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    onClick: PropTypes.func,
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    errorsCnt: PropTypes.number,
    blueErrorWarning: PropTypes.bool,
    infoTooltip: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    btnClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    hideInfoBadge: PropTypes.bool,
    visible: PropTypes.bool,
    tooltipAlign: PropTypes.oneOf(['left', 'right']),
    tooltipVerticalAlign: PropTypes.oneOf(['top', 'bottom']),
    form: PropTypes.string,
  };

  static defaultProps = {
    hideInfoBadge: false,
    errors: '',
    errorsCnt: 0,
    visible: true,
  };

  constructor(props) {
    super(props);

    this.buttonRef = createRef();

    this.state = {
      tooltipVerticalAlign: null,
    };

    this.setTooltipVerticalAlign = this.setTooltipVerticalAlign.bind(this);
  }

  componentDidMount() {}

  setTooltipVerticalAlign(value) {
    this.setState({ tooltipVerticalAlign: value });
  }

  render() {
    const {
      id,
      label,
      errors,
      errorsCnt,
      blueErrorWarning,
      infoTooltip,
      onClick,
      disabled,
      className,
      btnClassName,
      hideInfoBadge,
      visible,
      tooltipAlign,
      form,
    } = this.props;

    const componentClassName = classNames('badge-btn-component', className);
    const buttonClassName = classNames('badge-btn', btnClassName);
    const showErrorsTooltip = !infoTooltip && !!errors.length;
    const badgeClassName = classNames({
      'badge-btn-badge': true,
      disabled: disabled && buttonClassName.includes('secondary'),
    });

    if (!visible) {
      return null;
    }

    return (
      <div className={componentClassName}>
        {errorsCnt > 0 && <div className={badgeClassName}>{errorsCnt}</div>}

        {infoTooltip && !errors.length && !hideInfoBadge && (
          <div className="badge-btn-badge info-tooltip">
            <span>?</span>
          </div>
        )}

        <button
          id={id}
          ref={this.buttonRef}
          form={form}
          type={form ? 'submit' : 'button'}
          className={buttonClassName}
          onClick={onClick}
          disabled={disabled}
        >
          <span>{label}</span>
        </button>

        {showErrorsTooltip && (
          <Tooltip
            errors={errors}
            setVerticalAlign={this.setTooltipVerticalAlign}
            tooltipVerticalAlign={this.state.tooltipVerticalAlign || this.props.tooltipVerticalAlign}
            node={() => ({ button: this.buttonRef.current })}
            blueErrorWarning={blueErrorWarning}
            tooltipAlign={tooltipAlign}
          />
        )}

        {infoTooltip && (
          <Tooltip
            errors={errors}
            infoTooltip={infoTooltip}
            setVerticalAlign={this.setTooltipVerticalAlign}
            tooltipVerticalAlign={this.state.tooltipVerticalAlign || this.props.tooltipVerticalAlign}
            node={() => ({ button: this.buttonRef.current })}
            tooltipAlign={tooltipAlign}
          />
        )}
      </div>
    );
  }
}

export default BadgeButton;
