import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import translator from '../../common/translate';
import addLineItemsDialogSettings from '../../Storage/AddLineItemDialog';
import bindMultiple from '../../common/helpers/bindMultiple';
import { TextControl, SelectControl } from '../Controls';
import CatalogListItem from './nested/CatalogListItem';
import './css/quote-device-catalog.css';
import { SOLUTION_TYPES } from '../../common/enums';
import { PriceBookContext } from '../../Context';
import apiData from '../../Storage/apiData';
import { SortArrayOfObjects } from '../../common/helpers/SortArrayOfObjects';

const UNGROUPED = 'ungrouped';
const ALIASES = {
  ungrouped: null,
  brandName: 'brandName',
  type: 'lineItemSubCategoryName',
};
const GROUP_BY_MAPPING = [
  {
    alias: null,
    text: 'Ungrouped',
    value: 1,
  },
  {
    alias: 'brandName',
    text: 'Brand Name',
    value: 2,
  },
  {
    alias: 'lineItemSubCategoryName',
    text: 'Type',
    value: 3,
  },
];
const SOLUTION_TYPE_FILTER_MAPPING = [
  {
    solutionTypes: [0, ...Object.values(SOLUTION_TYPES)],
    text: 'All',
    value: 1,
  },
  {
    solutionTypes: [SOLUTION_TYPES.BUSINESS_VOICE],
    text: 'Business Voice',
    value: 2,
  },
  {
    solutionTypes: [SOLUTION_TYPES.BUSINESS_VOICE_PLUS],
    text: 'Business Voice+',
    value: 3,
  },
  {
    solutionTypes: [SOLUTION_TYPES.SWITCHVOX_SIP_STATION],
    text: 'Switchvox On-Prem and/or SIPStation',
    value: 4,
  },
];

const PRE_SELECTED_ITEMS = {
  SLMETERED: 'S2S-B-SU-SFMETERED',
  SLUNLTD: 'S2S-B-SU-SLUNLTD',
};

class QuoteDeviceCatalog extends Component {
  static propTypes = {
    categoryId: PropTypes.number.isRequired,
    headers: PropTypes.array.isRequired,
    catalog: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCheckItem: PropTypes.func,
    hasBundles: PropTypes.bool,
    isRecurring: PropTypes.bool,
    showSolutionTypeMark: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    onSuppressChange: PropTypes.func,
    suppressedIds: PropTypes.arrayOf(PropTypes.number),
  };

  static defaultProps = {
    suppressedIds: [],
  };

  constructor(props) {
    super(props);

    const settings = addLineItemsDialogSettings.getByCategoryId(this.props.categoryId);

    this.state = {
      filter: '',
      sortBy: 'sequence',
      sortType: 'number',
      // 1 for all solution types
      solutionTypeFilter: 1,
      // 1 for ascending sort order
      sortDirection: 1,
      // TODO: Refactor addLineItemsDialogSettings to have an option set overrides based on conditions
      groupBy: PriceBookContext.model.isSupportsSwitchvoxSIPStation ? 'lineItemSubCategoryName' : settings.groupBy,
      expandedGroups: settings.expandedGroups,
      // TODO: 1. Move add/remove callbacks into AddLineItemDialog
      // TODO: 2. Remove state.suppressedIds in this component
      suppressedIds: new Set(this.props.suppressedIds),
    };

    this.solutionTypeOptions = [];
    const solutionTypeIdsInCatalog = new Set();

    this.props.catalog.forEach(lineItem => {
      lineItem.applicableSolutionTypes.forEach(solutionType => solutionTypeIdsInCatalog.add(solutionType));
    });

    solutionTypeIdsInCatalog.forEach(solutionTypeId => {
      const option = SOLUTION_TYPE_FILTER_MAPPING.find(
        option => option.value !== 1 && option.solutionTypes.includes(solutionTypeId)
      );

      if (option) {
        this.solutionTypeOptions.push(option);
      }
    });

    // Always add "All" option
    this.solutionTypeOptions.unshift(SOLUTION_TYPE_FILTER_MAPPING.find(option => option.value === 1));

    // Set solution type drop-down preselected option based on available options
    this.state.solutionTypeFilter = this.solutionTypeOptions[0].value;

    bindMultiple(
      this,
      this.handleClearFilter,
      this.handleGroupByChange,
      this.handleSearchInputChange,
      this.handleSolutionTypeChange,
      this.handleSortChange,
      this.handleSuppressAdd,
      this.handleSuppressChange,
      this.handleSuppressRemove
    );
  }

  componentWillUnmount() {
    addLineItemsDialogSettings.setPropByCategoryId(this.props.categoryId, 'groupBy', this.state.groupBy);
    addLineItemsDialogSettings.setPropByCategoryId(this.props.categoryId, 'expandedGroups', this.state.expandedGroups);
  }

  handleClearFilter() {
    this.setState({
      filter: '',
    });
  }

  handleSearchInputChange(value) {
    this.setState({
      filter: value,
    });
  }

  handleSortChange(name, type) {
    let direction = this.state.sortDirection;

    if (this.state.sortBy === name) {
      direction *= -1;
    }

    this.setState({
      sortBy: name,
      sortType: type,
      sortDirection: direction,
    });
  }

  handleGroupByChange(value) {
    const mapping = GROUP_BY_MAPPING.find(d => d.value === value);

    if (mapping) {
      this.setState({
        groupBy: mapping.alias,
        expandedGroups: [],
      });
    }
  }

  handleSolutionTypeChange(value) {
    this.setState({
      solutionTypeFilter: value,
    });
  }

  handleGroupLabelClick(groupName, event) {
    let expandedGroups = this.state.expandedGroups;
    const index = expandedGroups.indexOf(groupName);

    // Group is expanded
    if (index !== -1) {
      // Collapse group
      expandedGroups.splice(index, 1);
    } else {
      expandedGroups.push(groupName);
    }

    this.setState({
      expandedGroups,
    });
  }

  handleSuppressAdd(lineItemId) {
    this.setState(state => {
      state.suppressedIds = new Set(state.suppressedIds);
      state.suppressedIds.add(lineItemId);

      return state;
    }, this.handleSuppressChange);
  }

  handleSuppressRemove(lineItemId) {
    this.setState(state => {
      state.suppressedIds = new Set(state.suppressedIds);
      state.suppressedIds.delete(lineItemId);

      return state;
    }, this.handleSuppressChange);
  }

  handleSuppressChange() {
    if (typeof this.props.onSuppressChange === 'function') {
      this.props.onSuppressChange([...this.state.suppressedIds]);
    }
  }

  filterBySolutionType(flatCatalog, solutionTypes) {
    let filteredCatalog = [];

    for (let i = 0; i < flatCatalog.length; i++) {
      const lineItem = flatCatalog[i];
      const isApplicable = solutionTypes.some(solutionType => lineItem.applicableSolutionTypes.includes(solutionType));

      if (isApplicable) {
        filteredCatalog.push(lineItem);
      }
    }

    return filteredCatalog;
  }

  groupFlatCatalog(flatCatalog, alias) {
    let groupKey = 'group-ungrouped';
    const groups = [];
    const getGroupIndex = (groups, groupKey) => groups.findIndex(group => group.key === groupKey);

    for (let i = 0; i < flatCatalog.length; i++) {
      const lineItem = flatCatalog[i];
      let groupLabel = '';
      let sequence = 0;

      if (alias === ALIASES.type) {
        const subCategoryData = apiData.categories
          .find(category => category.id === lineItem.lineItemCategoryId)
          .subCategories.find(subCategory => subCategory.id === lineItem.lineItemSubCategoryId);

        groupLabel = subCategoryData.name;
        groupKey = 'group-' + subCategoryData.id;
        sequence = subCategoryData.sequence;
      }

      if (alias === ALIASES.brandName) {
        const brandName = apiData.brandNames.find(brandNameObj => brandNameObj.id === lineItem.brandNameId);

        groupLabel = brandName.description;
        sequence = brandName.sequence;
        groupKey = 'group-' + brandName.id;
      }

      let groupIndex = getGroupIndex(groups, groupKey);

      if (groupIndex === -1) {
        groupIndex =
          groups.push({
            label: groupLabel,
            key: groupKey,
            lineItems: [],
            sequence,
          }) - 1;
      }

      groups[groupIndex].lineItems.push(lineItem);
    }

    if (alias !== ALIASES.ungrouped) {
      SortArrayOfObjects(groups, 'sequence');
    }

    // Reformat data for backward compatibility
    const groupsObject = {};

    groups.forEach(group => {
      groupsObject[group.label] = group.lineItems;
    });

    return groupsObject;
  }

  filterCatalog(catalog, searchStr) {
    const searchBy = [ALIASES.brandName, 'lineItemName', 'lineItemSubCategoryName'];
    const searchStrLowerCased = searchStr.toLowerCase();
    let filteredCatalog = {};

    for (let alias in catalog) {
      for (let i = 0; i < catalog[alias].length; i++) {
        const d = catalog[alias][i];
        const brandName = apiData.brandNames.find(brandName => brandName.id === d.brandNameId).description;
        let match = false;

        for (let j = 0; j < searchBy.length; j++) {
          const searchByAlias = searchBy[j];
          const value = ((searchByAlias === ALIASES.brandName ? brandName : d[searchByAlias]) || '').toLowerCase();

          if (value.indexOf(searchStrLowerCased) !== -1) {
            match = true;

            break;
          }
        }

        if (match) {
          if (filteredCatalog[alias] === undefined) {
            filteredCatalog[alias] = [];
          }

          filteredCatalog[alias].push(d);
        }
      }
    }

    return filteredCatalog;
  }

  sortCatalog(catalog, sortBy, type, direction) {
    let sortFunc = (a, b) => {
      let aValue = a[sortBy];
      let bValue = b[sortBy];

      // KM-5155: Bundles without own dealerNet amount should be sorted properly
      if (sortBy === 'dealerNet') {
        if (a.dealerNet === 0 && a._bundleDealerNet) {
          aValue = a._bundleDealerNet;
        }

        if (b.dealerNet === 0 && b._bundleDealerNet) {
          bValue = b._bundleDealerNet;
        }
      }

      return direction * (aValue - bValue);
    };

    if (type === 'string') {
      sortFunc = (a, b) => {
        let value1 = a[sortBy];
        let value2 = b[sortBy];

        if (typeof value1 !== 'string') {
          value1 = '';
        }

        if (typeof value2 !== 'string') {
          value2 = '';
        }

        return direction * value1.localeCompare(value2);
      };
    }

    for (let groupName in catalog) {
      catalog[groupName].sort(sortFunc);
    }

    return catalog;
  }

  renderCatalogItems(catalog) {
    const settings = addLineItemsDialogSettings.getByCategoryId(this.props.categoryId);
    const groupComponents = [];
    const singleGroupInCatalog = Object.keys(catalog).length === 1;

    for (let groupName in catalog) {
      const itemComponents = [];
      const catalogClassName = classnames({
        'catalog-list-brand': true,
        'list-open':
          this.state.filter !== '' ||
          singleGroupInCatalog ||
          this.state.expandedGroups.includes(groupName) ||
          settings.alwaysExpanded,
        'single-group': singleGroupInCatalog,
        'always-expanded': settings.alwaysExpanded,
        'hide-group-name': UNGROUPED === groupName,
      });

      if (catalog[groupName].length) {
        catalog[groupName].forEach(item => {
          const isSelected = this.isSelectedItem(item.lineItemId);
          const isChecked = this.isCheckedItem(item.lineItemId);
          let preSelected = false;

          if (Object.values(PRE_SELECTED_ITEMS).includes(item.partNumber)) {
            for (let KEY in PRE_SELECTED_ITEMS) {
              if (this.props.partNumbersFromOriginalQuote.includes(KEY) && item.partNumber === PRE_SELECTED_ITEMS[KEY]) {
                preSelected = true;
              }
            }
          }

          itemComponents.push(
            <CatalogListItem
              key={item.lineItemId}
              item={item}
              isSelected={isSelected}
              isChecked={isChecked}
              onCheckItem={this.props.onCheckItem}
              headers={this.props.headers}
              showSolutionTypeMark={this.props.showSolutionTypeMark}
              preSelected={preSelected}
              isSalesOps={this.props.isSalesOps}
              onSuppressAdd={this.handleSuppressAdd}
              onSuppressRemove={this.handleSuppressRemove}
              isSuppressed={this.state.suppressedIds.has(item.lineItemId)}
              isCheckboxDisabled={item._forceDisableItemSelection}
            />
          );
        });

        groupComponents.push(
          <div className={catalogClassName} key={`brand-name-${groupName}`}>
            <input type="checkbox" id={`${groupName}-title-checkbox`} />
            <label
              onClick={this.handleGroupLabelClick.bind(this, groupName)}
              htmlFor={`${groupName}-title-checkbox`}
              className="catalog-list-brand-title"
            >
              <span className="icon-expand-less" />
              {groupName}
            </label>

            <div className="catalog-list">{itemComponents}</div>
          </div>
        );
      }
    }

    return groupComponents;
  }

  isSelectedItem(id) {
    return this.props.selectedDevices.find(({ lineItemId }) => lineItemId === id) != null;
  }

  isCheckedItem(id) {
    return this.props.selectedItems.find(({ lineItemId }) => lineItemId === id) != null;
  }

  getHeaderItems() {
    return this.props.headers.map((item, index) => {
      const sortDirections = {
        '-1': 'desc',
        1: 'asc',
      };

      const sortableItemClassName = classnames({
        sorted: item.name === this.state.sortBy,
        [item.className]: true,
        'header-item': true,
      });

      const itemClassName = classnames({
        [item.className]: true,
        'header-item': true,
      });

      const btnGroupClassName = classnames({
        'item-icon-group': true,
        [sortDirections[this.state.sortDirection]]: item.name === this.state.sortBy,
      });

      return (
        <Fragment key={item.name + '-' + index}>
          {!item.label ? (
            <div>&nbsp;</div>
          ) : item.sortable ? (
            <span className={sortableItemClassName} onClick={this.handleSortChange.bind(this, item.name, item.type)}>
              {translator.getMessage(item.label)}
              <div className={btnGroupClassName}>
                <span className="asc-btn icon-chevron-left" />
                <span className="desc-btn icon-chevron-right" />
              </div>
            </span>
          ) : (
            <div className={itemClassName}>{translator.getMessage(item.label)}</div>
          )}
        </Fragment>
      );
    });
  }

  render() {
    const bundleNotationLine1 = translator.getMessage('msg_bundle_notation_line_1');
    const bundleNotationLine2 = translator.getMessage(
      this.props.isRecurring ? 'msg_bundle_notation_line_mrc' : 'msg_bundle_notation_line_nrc'
    );
    const solutionTypeFilterMapping = this.solutionTypeOptions.find(d => d.value === this.state.solutionTypeFilter);
    const filterControlsClassNames = classnames({
      'filter-controls': true,
      'with-solution-type': this.props.showSolutionTypeMark,
    });
    const catalogListHeader = classnames({
      'catalog-list-header': true,
      // TODO: This selector affects columns width to have extra space for suppressed section.
      //       But existing line items dialog is for SalesOps only and there is no suppressed column
      //       which results in UI to has some issues with columns width.
      //       1. Rename CSS class. And do actual lookup for suppressed column instead of relying on user role.
      //       2. Inform QA test automation with class name change.
      'catalog-list-header-sales-ops': this.props.isSalesOps,
    });

    let catalog = this.props.catalog;

    if (this.props.showSolutionTypeMark) {
      catalog = this.filterBySolutionType(this.props.catalog, solutionTypeFilterMapping.solutionTypes);
    }

    catalog = this.groupFlatCatalog(catalog, this.state.groupBy);

    if (this.state.filter) {
      catalog = this.filterCatalog(catalog, this.state.filter);
    }

    if (this.state.sortBy) {
      catalog = this.sortCatalog(catalog, this.state.sortBy, this.state.sortType, this.state.sortDirection);
    }

    let itemsCount = 0;

    Object.keys(catalog).forEach(groupName => (itemsCount += catalog[groupName].length));

    let catalogComponents = this.renderCatalogItems(catalog);
    let catalogListClassName = 'catalog-list-content';
    let clearBtnClassName = 'clear-btn';

    let cancelBtnText = '';

    if (this.state.filter) {
      if (!itemsCount) {
        catalogListClassName += ' no-items';
        clearBtnClassName += ' no-items';
        catalogComponents = translator.getMessage('msg_no_results_found');

        cancelBtnText = this.props.intl.formatMessage({ id: 'msg_no_result' });
      } else {
        cancelBtnText =
          String(itemsCount) +
          ' ' +
          this.props.intl.formatMessage({
            id: itemsCount > 1 ? 'msg_results' : 'msg_result',
          });
      }
    }

    const groupByMapping = GROUP_BY_MAPPING.find(d => d.alias === this.state.groupBy);

    return (
      <div className="quote-device-catalog">
        <div className={filterControlsClassNames}>
          <div className="catalog-filter-field">
            <span className="icon-search" />
            <TextControl
              id="filterField"
              className="filter-input"
              parentClassName="filter-input-component"
              placeholder="msg_search_for_a_model_or_type"
              value={this.state.filter}
              onChange={this.handleSearchInputChange}
              focus={true}
            />
            {this.state.filter && (
              <button className={clearBtnClassName} onClick={this.handleClearFilter}>
                {cancelBtnText}
                <span className="icon-close" />
              </button>
            )}
          </div>
          <div className="group-by-wrapper">
            <span>{translator.getMessage('msg_group_by')}</span>
            <SelectControl
              id="groupByField"
              className="group-by-select"
              parentClassName="group-by-select-component"
              value={groupByMapping.value}
              options={GROUP_BY_MAPPING}
              onChange={this.handleGroupByChange}
            />
            {this.props.showSolutionTypeMark && (
              <SelectControl
                id="solutionTypeFilter"
                className="solution-type-filter"
                parentClassName="solution-type-filter-component"
                value={this.state.solutionTypeFilter}
                options={this.solutionTypeOptions}
                onChange={this.handleSolutionTypeChange}
              />
            )}
          </div>
        </div>
        <div className="catalog-list-wrapper">
          <div className={catalogListHeader}>{this.getHeaderItems()}</div>

          <div className={catalogListClassName}>{catalogComponents}</div>
        </div>

        {this.props.hasBundles && (
          <div className="bundle-price-notation">
            {bundleNotationLine1} {bundleNotationLine2}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(QuoteDeviceCatalog);
