import React, { Component } from 'react';
import SubmitOnBehalfOfInput from './SubmitOnBehalfOfInput';
import SubmitOnBehalfOfToggle from './SubmitOnBehalfOfToggle';

class SubmitOnBehalfOf extends Component {
  render() {
    const { useSOBO, subAgentName, lockedState, disabledState, disableSOBO, onChange } = this.props;

    return (
      <React.Fragment>
        <SubmitOnBehalfOfToggle
          key="SOBO-toggle-field"
          id="SOBO-toggle"
          value={useSOBO}
          onChange={onChange}
          lockedState={lockedState}
          disabledState={disabledState || disableSOBO}
        />
        {useSOBO && (
          <SubmitOnBehalfOfInput
            key="SOBO-field"
            id="SOBO"
            value={subAgentName}
            onChange={onChange}
            lockedState={lockedState}
            disabledState={disabledState || disableSOBO || !useSOBO}
          />
        )}
      </React.Fragment>
    );
  }
}

export default SubmitOnBehalfOf;
