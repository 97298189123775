import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import '../css/calendar-toolbar.css';

class CalendarToolbar extends PureComponent {
  static propTypes = {
    displayDate: PropTypes.string.isRequired,
    onNext: PropTypes.func,
    onPrev: PropTypes.func,
    onToday: PropTypes.func,
  };

  render() {
    const { onNext, onPrev, onToday, displayDate } = this.props;

    return (
      <div className="calendar-toolbar-component">
        <div className="calendar-toolbar-actions">
          <button className="prev-month-btn square no-border tertiary" onClick={onPrev}>
            <span className="icon-chevron-left" />
          </button>
          <button className="next-month-btn square no-border tertiary" onClick={onNext}>
            <span className="icon-chevron-right" />
          </button>
        </div>
        <div className="current-month">{displayDate}</div>
        <button className="today-btn tertiary" onClick={onToday}>
          Today
        </button>
      </div>
    );
  }
}

export default CalendarToolbar;
