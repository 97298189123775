import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AbstractControl from '../AbstractControl';
import '../css/select-input.css';
import { SelectInput } from '../../Inputs';

class SelectControl extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    style: PropTypes.object,
    focus: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    clearInvalidOnBlur: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
  };

  render() {
    const {
      // Props for current component
      id,
      value,
      lockedState,
      disabledState,
      className,
      placeholder,
      options,

      // TODO: Add support for ariaLabel
      // ariaLabel,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      required,
      errorText,
      requiredBlue,
      tooltipText,
      parentClassName,
      autoColumns,
    } = this.props;

    const componentClassName = classNames('select-input-component', className);
    const parentClassNames = classNames({ 'auto-columns': autoColumns }, parentClassName);

    const inputAbstractProps = {
      hint,
      style,
      focus,
      label,
      lockedState,
      disabledState,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      parentClassName: parentClassNames,
      className: componentClassName,
      labelFor: id,
    };

    return (
      <AbstractControl {...inputAbstractProps}>
        <SelectInput
          id={id}
          value={value}
          options={options}
          placeholder={placeholder}
          onChange={this.props.onChange}
          disabled={disabledState || lockedState}
          // TODO: Add support for ariaLabel
          // ariaLabel={ariaLabel}
        />
      </AbstractControl>
    );
  }
}

export default injectIntl(SelectControl);
