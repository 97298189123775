import AbstractContactModel from './AbstractContactModel';
import {
  BILLING_EMAIL_VALIDATION_STATUSES,
  VALIDATED_STATUSES,
} from '../../common/enums/billingEmailValidationStatuses';
import isValidEmail from '../../common/helpers/isValidEmail';
import { QUOTE_STATUS } from '../../common/enums';
import { PartnerContext } from '../../Context';

const BILL_TO_MAP = {
  BILL_TO_DEALER: 1,
  BILL_TO_CUSTOMER: 2,
};

class BillingInfoModel extends AbstractContactModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    this._disableBillToId = false;
    this._decentralizedAccounting = false;
    this._billToId = null;
    this._contactOfficePhone = null;
    this._contactMobilePhone = null;
    this._contactEmail = null;
    this._contactEmailValidationStatus = BILLING_EMAIL_VALIDATION_STATUSES.NONE;
    this._contactEmailConfirmedBy = null;
    this._contactEmailConfirmedOn = null;
  }

  __updateValidationDataFromExistingQuote(email) {
    const quotes = this.controller.quotes.items;
    const length = quotes.length;

    for (let i = 0; i < length; i++) {
      if (i === this.controller.selectedQuote.index) {
        continue;
      }

      if (
        quotes[i].billingInfo.contactEmail === email &&
        VALIDATED_STATUSES.includes(quotes[i].billingInfo.contactEmailValidationStatus)
      ) {
        this._contactEmailValidationStatus = quotes[i].billingInfo.contactEmailValidationStatus;
        this.contactEmailConfirmedBy = quotes[i].billingInfo.contactEmailConfirmedBy;
        this.contactEmailConfirmedOn = quotes[i].billingInfo.contactEmailConfirmedOn;

        break;
      }
    }
  }

  __updateExistingQuotesValidationData(status) {
    if (!VALIDATED_STATUSES.includes(status)) {
      return false;
    }
    const quotes = this.controller.quotes.items;
    const length = quotes.length;

    for (let i = 0; i < length; i++) {
      if (i === this.controller.selectedQuote.index || quotes[i].status === QUOTE_STATUS.FINALIZED) {
        continue;
      }

      if (
        quotes[i].billingInfo.contactEmail === this.contactEmail &&
        quotes[i].billingInfo.contactEmailValidationStatus !== status
      ) {
        quotes[i].billingInfo._contactEmailValidationStatus = status;
        quotes[i].billingInfo._contactEmailConfirmedBy = this.contactEmailConfirmedBy;
        quotes[i].billingInfo._contactEmailConfirmedOn = this.contactEmailConfirmedOn;
      }
    }
  }

  _synchronizeWithCloneModel() {
    if (!super._synchronizeWithCloneModel()) {
      return false;
    }

    this.contactOfficePhone = this.cloneFromModel.contactOfficePhone;
    this.contactMobilePhone = this.cloneFromModel.contactMobilePhone;

    if (this.cloneFromModel.contactEmail !== null && this.contactEmail === null) {
      this.contactEmail = this.cloneFromModel.contactEmail;
    }

    if (this.cloneFromModel.contactEmailValidationStatus !== undefined) {
      this.contactEmailValidationStatus = this.cloneFromModel.contactEmailValidationStatus;
      this.contactEmailConfirmedBy = this.cloneFromModel.contactEmailConfirmedBy;
      this.contactEmailConfirmedOn = this.cloneFromModel.contactEmailConfirmedOn;
    }

    return true;
  }

  get decentralizedAccounting() {
    return this._decentralizedAccounting;
  }

  set decentralizedAccounting(value) {
    this._decentralizedAccounting = value;
  }

  get disableBillToId() {
    // KM-10078
    if (PartnerContext.model.isWhiteLabelWholesale) {
      return true;
    }

    return this._disableBillToId;
  }

  set disableBillToId(value) {
    this._disableBillToId = value;
  }

  get billToId() {
    // KM-10078
    if (PartnerContext.model.isWhiteLabelWholesale) {
      return BILL_TO_MAP.BILL_TO_DEALER;
    }

    this.__checkBillTo();

    return this._billToId;
  }

  set billToId(value) {
    this._billToId = value;
  }

  get contactEmail() {
    if (PartnerContext.model.isWhiteLabelWholesale && !this._isMasterOrder) {
      return this.cloneFromModel._contactEmail;
    }

    return this._contactEmail;
  }

  set contactEmail(value) {
    if (this._contactEmail !== value) {
      if (isValidEmail(value)) {
        this.contactEmailValidationStatus = BILLING_EMAIL_VALIDATION_STATUSES.UNKNOWN;

        this.__updateValidationDataFromExistingQuote(value);
      } else {
        this.contactEmailValidationStatus = BILLING_EMAIL_VALIDATION_STATUSES.NONE;
      }

      if (this.contactEmailValidationStatus !== BILLING_EMAIL_VALIDATION_STATUSES.CONFIRMED_AS_VALID) {
        this.contactEmailConfirmedBy = null;
        this.contactEmailConfirmedOn = null;
      }
    }

    this._contactEmail = value;
  }

  get contactOfficePhone() {
    return this._getClonedValueOrProtectedProp('contactOfficePhone');
  }

  set contactOfficePhone(value) {
    this._contactOfficePhone = value;
  }

  get contactMobilePhone() {
    return this._getClonedValueOrProtectedProp('contactMobilePhone');
  }

  set contactMobilePhone(value) {
    this._contactMobilePhone = value;
  }

  get contactEmailValidationStatus() {
    if (PartnerContext.model.isWhiteLabelWholesale && !this._isMasterOrder) {
      return this.cloneFromModel._contactEmailValidationStatus;
    }

    return this._contactEmailValidationStatus;
  }

  set contactEmailValidationStatus(value) {
    this.__updateExistingQuotesValidationData(value);

    this._contactEmailValidationStatus = value;
  }

  get contactEmailConfirmedBy() {
    if (PartnerContext.model.isWhiteLabelWholesale && !this._isMasterOrder) {
      return this.cloneFromModel._contactEmailConfirmedBy;
    }

    return this._contactEmailConfirmedBy;
  }

  set contactEmailConfirmedBy(value) {
    this._contactEmailConfirmedBy = value;
  }

  get contactEmailConfirmedOn() {
    if (PartnerContext.model.isWhiteLabelWholesale && !this._isMasterOrder) {
      return this.cloneFromModel._contactEmailConfirmedOn;
    }

    return this._contactEmailConfirmedOn;
  }

  set contactEmailConfirmedOn(value) {
    this._contactEmailConfirmedOn = value;
  }

  get cloneFromModel() {
    if (PartnerContext.model.isWhiteLabelWholesale && !this._isMasterOrder && this._quotesList) {
      this._cloneFromModel = this._quotesList.masterOrder.billingInfo;
      this._syncCheckBoxValue = true;
    }

    return this._cloneFromModel;
  }

  set cloneFromModel(value) {
    super.cloneFromModel = value;
  }

  __checkBillTo() {
    if (this._quotesList) {
      const masterOrder = this._quotesList.masterOrder;

      if (PartnerContext.model.isWhiteLabelWholesale) {
        this._billToId = BILL_TO_MAP.BILL_TO_DEALER;
        this._disableBillToId = true;

        return;
      }

      // KM-7629: Force Customer Bill To option for Financed packages
      if (masterOrder.isFinanced) {
        this._billToId = BILL_TO_MAP.BILL_TO_CUSTOMER;
        this._disableBillToId = true;
      } else if (masterOrder.dealerNetToggle) {
        this._billToId = BILL_TO_MAP.BILL_TO_DEALER;
        this._disableBillToId = true;
      } else {
        this._disableBillToId = false;
      }
    }
  }
}

export default BillingInfoModel;
