import CollapsableCard from '../../../../CollapsableCard';
import React from 'react';
import { PriceBookContextModel } from '../../../../../Context';
import { LabelField, NumberControl, SelectControl, TextControl, ToggleControl } from '../../../../Controls';
import NumLines from '../../../../NumLines';
import { Validate } from '../../../../../Models';
import BulkMarkup from '../../../../BulkMarkup/BulkMarkup';

const QuoteInformationWrapper = ({
  // CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  // Quote info inputs props
  customerOrder,
  controller,
  // QuoteInformationSectionModel props
  lockedState,
  disabledState,
  showUseNumLinesDefault,
  showNumLines,
  showContractTermSelect,
  isContractTermDisabled,
  contractTermHint,
  contractTermAutoColumns,
  showLocationsNumber,
  showLocationsNumberAsLabel,
  showStarCloudLocations,
  showStarCloudLocationsAsLabel,
  showBusinessVoicePlusLocations,
  businessVoicePlusLocationsValue,
  showSwitchvoxSIPStationLocations,
  showLineType,
  lineTypeDisabled,
  lineTypeValue,
  showDealerNetToggle,
  dealerNetToggleDisabled,
  showBulkMarkup,
  showNrcInputs,
  showRcInputs,
  showBusinessContinuity,
  showCoBrandingToggle,
  coBrandingToggleDisabled,
  showSwitchvoxSipStationToggles,
  switchvoxSipStationTogglesDisabled,
  showSwitchvoxAsLabel,
  showSipStationAsLabel,
  showSwitchvoxOnPremMaintType,
  showSwitchvoxOnPremMaintTypeAsLabel,
  showSwitchvoxOnPremMaintTermYears,
  showSwitchvoxOnPremMaintTermYearsAsLabel,
  showNumSwitchvoxOnPremUsers,
  numSwitchvoxOnPremUsersDisabled,
  showStandaloneServiceNonUCaaS,
  showSmartOfficeLocationsQty,
  smartOfficeLocationsQtyDisabled,
  showSmartOfficeLocation,
  smartOfficeLocationDisabled,
  showSwitchvoxApplianceProvider,
  switchvoxApplianceProviderDisabled,
  showOpportunityProductType,
  autoSetOpportunityProductTypeDisabled,
  showOpportunityProductTypeAsLabel,
  opportunityProductTypeDropdownDisabled,
  // QuoteInformationSectionModel methods
  getSwitchvoxOnPremMaintTypeLabelValue,
  isSwitchvoxOnPremMaintTypeDisabled,
  getSwitchvoxOnPremMaintTermYearsLabelValue,
  isSwitchvoxOnPremMaintTermYearsDisabled,
  // QuoteInformationSectionModel change handlers
  handleUseNumLinesDefaultChange,
  handleNumLinesChange,
  handleContractTermMonthsChange,
  handleNumLocationsChange,
  handleNumStarCloudLocationsChange,
  handleLineTypeChange,
  handleDealerNetToggleChange,
  handleCobrandingEnabledChange,
  handleQuoteTitleChange,
  handleSwitchvoxOnPremEnabledChange,
  handleSipStationEnabledChange,
  handleSwitchvoxOnPremMaintTypeChange,
  handleSwitchvoxOnPremMaintTermYearsChange,
  handleNumSwitchvoxOnPremUsersChange,
  handleSmartOfficeLocationsQtyChange,
  handleSwitchvoxApplianceProviderChange,
  handleAutoSetOpportunityProductTypeChange,
  handleOpportunityProductTypeChange,
}) => {
  /**
   * Returns component for requested parameters
   * @param {object} item - Object parameters, imported from src/common/constants/quote-info-inputs.js.
   * @return {object} React component according to requested params.
   */
  const getComponent = item => {
    switch (item.type) {
      case 'useNumLinesDefault':
        return (
          showUseNumLinesDefault && (
            <ToggleControl
              {...item.props}
              key={'use-num-lines-default-' + customerOrder.uuid}
              lockedState={lockedState}
              disabledState={disabledState}
              value={customerOrder.useNumLinesDefault}
              onChange={handleUseNumLinesDefaultChange}
            />
          )
        );
      case 'NumLines':
        return (
          showNumLines && (
            <NumLines
              lockedState={lockedState}
              disabledState={disabledState}
              key={'num-lines-' + customerOrder.uuid}
              id={'master-order-' + customerOrder.uuid}
              tooltipText={'Default value is ' + customerOrder.numLinesDefault}
              editable={!customerOrder.useNumLinesDefault}
              value={customerOrder.numLines}
              onChange={handleNumLinesChange}
              label="msg_number_of_lines"
            />
          )
        );
      case 'contractTermSelect':
        return (
          showContractTermSelect && (
            <SelectControl
              {...item.props}
              parentClassName={contractTermAutoColumns ? '' : 'short-input'}
              className={contractTermAutoColumns ? '' : 'right-align'}
              key={item.props.id + 'SelectControl'}
              lockedState={lockedState}
              disabledState={isContractTermDisabled}
              value={customerOrder[item.props.id]}
              options={customerOrder.contractTermLengthOptions}
              onChange={handleContractTermMonthsChange}
              hint={contractTermHint}
              autoColumns={contractTermAutoColumns}
              fullWidth
            />
          )
        );
      case 'LocationsNumber':
        if (!showLocationsNumber) {
          return null;
        }

        if (showLocationsNumberAsLabel) {
          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={customerOrder[item.id]}
              textRight
            />
          );
        }

        return (
          <NumberControl
            {...item.props}
            parentClassName="short-input"
            key={item.id + 'LocationsNumber'}
            id={item.id + 'LocationsNumber'}
            lockedState={lockedState}
            disabledState={disabledState || customerOrder.sangomaCXStandalone}
            value={customerOrder[item.id]}
            onChange={handleNumLocationsChange}
            errorText={Validate.numLocations(customerOrder).text}
            commitOnBlur
          />
        );
      case 'StarCloudLocations': {
        if (!showStarCloudLocations) {
          return null;
        }

        if (showStarCloudLocationsAsLabel) {
          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={customerOrder[item.id]}
              textRight
            />
          );
        }

        return (
          <NumberControl
            {...item.props}
            parentClassName="star-cloud-locations short-input"
            key={item.id + 'LocationsNumber'}
            id={item.id + 'LocationsNumber'}
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder[item.id]}
            onChange={handleNumStarCloudLocationsChange}
          />
        );
      }
      case 'BusinessVoicePlusLocations':
        return (
          showBusinessVoicePlusLocations && (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={businessVoicePlusLocationsValue}
              textRight
            />
          )
        );
      case 'switchvoxSIPStationLocations':
        return (
          showSwitchvoxSIPStationLocations && (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={customerOrder.numSwitchvoxOnPremSIPStationLocations}
              textRight
            />
          )
        );
      case 'lineType':
        return (
          showLineType && (
            <SelectControl
              {...item.props}
              key={item.props.id + 'SelectControl'}
              lockedState={lockedState}
              disabledState={lineTypeDisabled}
              value={lineTypeValue}
              options={item.props.options}
              onChange={handleLineTypeChange}
            />
          )
        );
      case 'dealerNetToggle':
        return (
          showDealerNetToggle && (
            <ToggleControl
              {...item.props}
              key={item.props.id + 'ToggleControl'}
              lockedState={lockedState}
              disabledState={dealerNetToggleDisabled}
              value={customerOrder[item.props.id]}
              onChange={handleDealerNetToggleChange}
            />
          )
        );
      case 'bulkMarkup':
        return (
          showBulkMarkup && (
            <BulkMarkup
              {...item.props}
              onChange={customerOrder.onChange}
              onApply={controller.setBulkMarkup}
              key={item.id}
              bulkRecurring={customerOrder.bulkRecurring}
              bulkNonRecurring={customerOrder.bulkNonRecurring}
              disabledState={disabledState}
              lockedState={lockedState}
              showNrcInputs={showNrcInputs}
              showRcInputs={showRcInputs}
            />
          )
        );
      case 'businessContinuity':
        return (
          showBusinessContinuity && (
            <ToggleControl
              id={item.props.id}
              key={item.props.id}
              label={item.props.label}
              value={customerOrder.businessContinuity}
              lockedState
              disabledState
            />
          )
        );
      case 'coBrandingEnabled':
        return (
          showCoBrandingToggle && (
            <ToggleControl
              disabledState={coBrandingToggleDisabled}
              id={item.props.id}
              key={item.props.id}
              label={item.props.label}
              lockedState={lockedState}
              value={customerOrder.cobrandingEnabled}
              onChange={handleCobrandingEnabledChange}
            />
          )
        );
      case 'quoteTitle':
        return (
          <TextControl
            id="customerTitleField"
            key="customerTitleField"
            label={item.props.label}
            lockedState={lockedState}
            disabledState={disabledState}
            value={customerOrder[item.props.id]}
            errorText={Validate.title(customerOrder).text}
            onChange={handleQuoteTitleChange}
            required
          />
        );
      case 'switchvoxOnPremEnabled':
        if (!showSwitchvoxSipStationToggles) {
          return null;
        }

        if (showSwitchvoxAsLabel) {
          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value="msg_yes"
              textRight
            />
          );
        }

        return (
          <ToggleControl
            id={item.id}
            key={item.id}
            label={item.props.label}
            disabledState={switchvoxSipStationTogglesDisabled}
            lockedState={lockedState}
            value={customerOrder[item.id]}
            onChange={handleSwitchvoxOnPremEnabledChange}
          />
        );
      case 'sipStationEnabled':
        if (!showSwitchvoxSipStationToggles) {
          return null;
        }

        if (showSipStationAsLabel) {
          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value="msg_yes"
              textRight
            />
          );
        }

        return (
          <ToggleControl
            id={item.id}
            key={item.id}
            label={item.props.label}
            disabledState={switchvoxSipStationTogglesDisabled}
            lockedState={lockedState}
            value={customerOrder[item.id]}
            onChange={handleSipStationEnabledChange}
          />
        );
      case 'switchvoxOnPremMaintType':
        if (!showSwitchvoxOnPremMaintType) {
          return null;
        }

        if (showSwitchvoxOnPremMaintTypeAsLabel) {
          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={getSwitchvoxOnPremMaintTypeLabelValue()}
              textRight
            />
          );
        }

        return (
          <SelectControl
            {...item.props}
            key={item.id + 'SelectControl'}
            id={item.id + 'SelectControl'}
            lockedState={lockedState}
            disabledState={isSwitchvoxOnPremMaintTypeDisabled()}
            value={customerOrder[item.id]}
            onChange={handleSwitchvoxOnPremMaintTypeChange}
            autoColumns
          />
        );
      case 'switchvoxOnPremMaintTermYears':
        if (!showSwitchvoxOnPremMaintTermYears) {
          return null;
        }

        if (showSwitchvoxOnPremMaintTermYearsAsLabel) {
          return (
            <LabelField
              id={item.id + 'LabelField'}
              key={item.id + 'LabelField'}
              parentClassName="short-input"
              label={item.props.label}
              value={getSwitchvoxOnPremMaintTermYearsLabelValue()}
              textRight
            />
          );
        }

        return (
          <SelectControl
            {...item.props}
            key={item.id + 'SelectControl'}
            id={item.id + 'SelectControl'}
            lockedState={lockedState}
            disabledState={isSwitchvoxOnPremMaintTermYearsDisabled()}
            value={customerOrder[item.id]}
            onChange={handleSwitchvoxOnPremMaintTermYearsChange}
            autoColumns
          />
        );
      case 'numSwitchvoxOnPremUsers':
        return (
          showNumSwitchvoxOnPremUsers && (
            <NumberControl
              {...item.props}
              parentClassName="short-input"
              key={item.id}
              id={item.id}
              lockedState={lockedState}
              disabledState={numSwitchvoxOnPremUsersDisabled}
              value={customerOrder[item.id]}
              onChange={handleNumSwitchvoxOnPremUsersChange}
            />
          )
        );
      case 'standaloneServiceNonUCaaS':
        return (
          showStandaloneServiceNonUCaaS && (
            <ToggleControl
              id={item.id}
              key={item.id}
              label={item.props.label}
              parentClassName="short-input"
              lockedState={lockedState}
              value={customerOrder[item.id]}
              disabledState
            />
          )
        );
      case 'smartOfficeLocationsQty':
        return (
          showSmartOfficeLocationsQty && (
            <NumberControl
              {...item.props}
              parentClassName="smart-office-locations short-input"
              key={item.id + 'LocationsNumber'}
              id={item.id + 'LocationsNumber'}
              disabledState={smartOfficeLocationsQtyDisabled}
              lockedState={lockedState}
              value={customerOrder[item.id]}
              max={customerOrder.numLocations}
              onChange={handleSmartOfficeLocationsQtyChange}
            />
          )
        );
      case 'smartOfficeLocation':
        return (
          showSmartOfficeLocation && (
            <ToggleControl
              {...item.props}
              key={item.props.id}
              parentClassName="short-input"
              disabledState={smartOfficeLocationDisabled}
              lockedState={lockedState}
              value={Boolean(customerOrder[item.props.id])}
              onChange={handleSmartOfficeLocationsQtyChange}
            />
          )
        );
      case 'switchvoxApplianceProvider':
        return (
          showSwitchvoxApplianceProvider && (
            <SelectControl
              {...item.props}
              key={item.id + 'SelectControl'}
              id={item.id + 'SelectControl'}
              lockedState={lockedState}
              disabledState={switchvoxApplianceProviderDisabled}
              value={customerOrder[item.id]}
              onChange={handleSwitchvoxApplianceProviderChange}
              autoColumns
            />
          )
        );
      case 'opportunityProductType':
        return (
          showOpportunityProductType && (
            <React.Fragment key={'opportunityProductType'}>
              <ToggleControl
                id={item.autoSetTogglePropName}
                key={item.autoSetTogglePropName}
                label="msg_opportunity_product_auto_set_toggle_label"
                disabledState={autoSetOpportunityProductTypeDisabled}
                lockedState={lockedState}
                value={customerOrder[item.autoSetTogglePropName]}
                onChange={handleAutoSetOpportunityProductTypeChange}
              />
              {showOpportunityProductTypeAsLabel ? (
                <LabelField
                  id={item.dropDownPropName + '-' + customerOrder.uuid}
                  label="msg_opportunity_product_type_drop_down_label"
                  parentClassName="text-right"
                  value="msg_not_available_abbreviation"
                />
              ) : (
                <SelectControl
                  id={item.dropDownPropName + '-' + customerOrder.uuid}
                  key={item.dropDownPropName + '-' + customerOrder.uuid}
                  label="msg_opportunity_product_type_drop_down_label"
                  disabledState={opportunityProductTypeDropdownDisabled}
                  lockedState={lockedState}
                  value={customerOrder[item.dropDownPropName]}
                  onChange={handleOpportunityProductTypeChange}
                  options={PriceBookContextModel.availableOpportunityProductTypeSelectControlOptions}
                />
              )}
            </React.Fragment>
          )
        );
      default:
        return null;
    }
  };

  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      {controller.constantsModel.getQuoteInfoInputs.map(item => getComponent(item))}
    </CollapsableCard>
  );
};

export default QuoteInformationWrapper;
