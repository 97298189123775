import $http from '../common/$http';
import config from '../config';

const IntegrationApi = {
  getPartnersByUserId({ userId }) {
    return $http.instance.api
      .get(`${config.api.url}/integration/users/${userId}/partners`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getCustomersByUserId({ userId }) {
    return $http.instance.api
      .get(`${config.api.url}/integration/users/${userId}/customers`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getCustomersByPartnerId({ partnerId }) {
    return $http.instance.api
      .get(`${config.api.url}/integration/dealerships/${partnerId}/customers`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getLocationsByCustomerId({ customerId }) {
    return $http.instance.api
      .get(`${config.api.url}/integration/customers/${customerId}/locations`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getShippingInfoByPartner({ partnerId }) {
    return $http.instance.api
      .get(`${config.api.url}/integration/partnerinfo/${partnerId}`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getDealerContacts({ billingDealerId }) {
    return $http.instance.api
      .get(`${$http.instance.apiDomainURI}/integration/dealerships/${billingDealerId}/users`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getQuoteDealerContacts({ billingDealerId, quoteId }) {
    return $http.instance.api
      .get(`${$http.instance.apiDomainURI}/integration/dealerships/${billingDealerId}/users/quote/${quoteId}`)
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getLocationsInitialInfo({ customerId, locationIds }) {
    return $http.instance.api
      .post(config.api.url + '/quote/quotes/cr/rewrite/locationsInfo', { customerId, locationIds })
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
  getSalesForceOpportunity(opportunityId) {
    return $http.instance.api
      .get(config.api.url + '/quote/quotes/salesforce-initial-quote-data/' + encodeURIComponent(opportunityId))
      .then(({ data }) => data.content)
      .catch(({ response }) => response.data);
  },
};

export default IntegrationApi;
