export const SCREEN_SIZE = {
  small: 800,
  medium: 1200,
  large: 1600,
};

export const QUOTE_STATUS_CLASS_NAME = {
  CLOSED: 'quotes-table-status_closed',
  SUBMITTED: 'quotes-table-status_submitted',
  SUBMITTING: 'quotes-table-status_submitting',
  FINALIZED: 'quotes-table-status_finalized',
  SE_PENDING_APPROVAL: 'quotes-table-status_finalized',
  SIGNED_AND_FINALIZED: 'quotes-table-status_finalized',
  AMENDING: 'quotes-table-status_finalized',
  UNKNOWN: 'quotes-table-status_wrong',
};

export const DEFAULT_QUOTE_CLASS_NAME = 'quotes-table-status_open';
export const DEFAULT_PAGE_RANGE = 5;
export const DEFAULT_ROWS_LIMIT = 10;
export const DEFAULT_ROW_HEIGHT = 60;
export const MIN_INNER_HEIGHT_TO_SCROLL = 495;
