import { Component } from 'react';
import isVariablesEqual from '../../common/helpers/isVariablesEqual';

class PureViewComponent extends Component {
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !Boolean(
      isVariablesEqual(this.props, nextProps) &&
        isVariablesEqual(this.state, nextState) &&
        isVariablesEqual(this.context, nextContext)
    );
  }
}

export default PureViewComponent;
