import React, { createRef, PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import '../css/input-abstract.css';
import ExternalLink from '../../ExternalLink';
import replaceStringUrlsWithLinkTag from '../../../common/helpers/format/replaceStringUrlsWithLinkTag';
import { getTranslateString } from '../../../common/helpers/getTranslateString';
import { getTranslateElement } from '../../../common/helpers/getTranslateElement';

class AbstractControl extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.string,
    hintLink: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    errorText: PropTypes.string,
    tooltipText: PropTypes.string,
    errorStyleDates: PropTypes.bool,
    containerProps: PropTypes.object,
    checkbox: PropTypes.bool,
    showErrorOnDisabled: PropTypes.bool,
    labelFor: PropTypes.string,
    forwardWrapperRef: PropTypes.func,
  };

  static defaultProps = {
    containerProps: {},
  };

  constructor(props) {
    super(props);

    this.isFocused = false;
    this.wrapperRef = createRef();
    this.inputElement = null;

    this.handleInitialFocus = this.handleInitialFocus.bind(this);
  }

  componentDidUpdate() {
    this.handleInitialFocus();
  }

  componentDidMount() {
    if (typeof this.props.forwardWrapperRef === 'function') {
      this.props.forwardWrapperRef(this.wrapperRef);
    }

    this.handleInitialFocus();
  }

  handleInitialFocus() {
    const wrapper = this.wrapperRef.current;

    if (!this.isFocused) {
      if (wrapper && !this.inputElement) {
        this.inputElement = wrapper.querySelector('input');
      }

      if (this.props.focus && !this.props.lockedState && this.inputElement) {
        this.inputElement.focus();
        this.isFocused = true;
      }
    }
  }

  render() {
    const {
      label,
      hint,
      hintLink,
      errorText,
      children,
      className,
      containerProps,
      parentClassName,
      required,
      requiredBlue,
      tooltipText,
      errorStyleDates,
      style,
      intl,
      disabledState,
      checkbox,
      showErrorOnDisabled,
      labelFor,
    } = this.props;

    const labelText = typeof label === 'string' ? getTranslateElement(label) : label;

    const labelTextStr =
      typeof label === 'string'
        ? getTranslateString(label, intl)
        : label && label.props && intl.formatMessage({ id: label.props.id });

    const hintTextStr =
      typeof hint === 'string'
        ? getTranslateString(hint, intl)
        : hint && hint.props && intl.formatMessage({ id: hint.props.id });

    const errorMessage = typeof errorText === 'string' ? getTranslateElement(errorText) : errorText;

    const tooltipTextParsed =
      typeof tooltipText === 'string' && tooltipText.substring(0, 4) === 'msg_'
        ? intl.formatMessage({ id: tooltipText })
        : tooltipText;

    const componentClassName = classNames({
      [parentClassName || '']: true,
      'input-abstract-component': true,
    });

    const fieldClassName = classNames({
      [className || '']: true,
      'input-component-wrapper': true,
      'required-red': !label && required,
      'has-error': errorText && !disabledState,
    });
    const labelClassName = classNames({
      'input-abstract-label': true,
      'required-red': required,
      'required-blue': requiredBlue && !required,
      'check-box': checkbox,
    });
    const errorClassName = classNames({
      'input-abstract-error': true,
      'error-for-dates': errorStyleDates && !disabledState,
    });

    return (
      <div ref={this.wrapperRef} className={componentClassName} style={style} title={tooltipTextParsed}>
        {(label || hint) && (
          <div className="input-abstract-label-wrapper">
            {/* Field label */}
            {label && (
              <label htmlFor={labelFor} className={labelClassName} title={labelTextStr} data-test="input-abstract-label">
                {labelText}
              </label>
            )}

            {/* Field hint */}
            {hint && (
              <div className="input-abstract-hint" title={hintTextStr} data-test="input-abstract-hint">
                {hintLink ? (
                  <ExternalLink path={hintLink} title={hintTextStr} />
                ) : (
                  replaceStringUrlsWithLinkTag(hintTextStr)
                )}
              </div>
            )}
          </div>
        )}

        {/* Field input component */}
        <div {...containerProps} className={fieldClassName}>
          {children}
        </div>

        {/* Field errors */}
        {(!disabledState || showErrorOnDisabled) && errorText && (
          <div className={errorClassName} data-test="input-abstract-error">
            <span className="icon-warning input-abstract-error-icon" />
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(AbstractControl);
