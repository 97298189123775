import React, { Component } from 'react';
import propTypes from 'prop-types';
import { SelectControl } from '../Controls';
import { LOCATION_SCHEDULE_TYPE_DROPDOWN_OPTIONS } from '../../common/enums';

class LocationDaasScheduleType extends Component {
  static propTypes = {
    lockedState: propTypes.bool,
    disabledState: propTypes.bool,
    onChange: propTypes.func,
    value: propTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange('daasScheduleType', value);
    }
  }

  render() {
    const { lockedState, disabledState, value } = this.props;

    return (
      <div className="location-name-wrapper">
        <SelectControl
          id="locationDaasScheduleTypeSelect"
          label="msg_daas_schedule_type"
          key="locationDaasScheduleTypeSelect"
          lockedState={lockedState}
          disabledState={disabledState}
          value={value}
          options={LOCATION_SCHEDULE_TYPE_DROPDOWN_OPTIONS}
          onChange={this.handleOnChange}
        />
      </div>
    );
  }
}

export default LocationDaasScheduleType;
