import CommonDownloadButtonModel from './CommonDownloadButtonModel';
import { ORDER_TYPES } from '../../common/enums';
import config from '../../config';
import { AuthContext } from '../../Context';

class ExtensionsCsvDownloadButtonModel extends CommonDownloadButtonModel {
  constructor(customerOrderInstance) {
    super(customerOrderInstance);

    this._ignoreOnExport.push('masterOrder');

    this.label = 'msg_download_extensions_csv';
  }

  get visible() {
    return this.masterOrder.orderType && this.masterOrder.orderType === ORDER_TYPES.REWRITE;
  }

  get downloadUrl() {
    return [config.api.url, '/locations/extensions?token=', AuthContext.model.token].join('');
  }

  get locationIds() {
    let result = {
      star2starLocationIds: [],
    };
    const locations = this.masterOrder.locations;

    for (let i = 0; i < locations.length; i++) {
      /** @type {LocationQuoteModel} */
      const locationModel = locations[i];
      const { star2starLocationId } = locationModel;

      result.star2starLocationIds.push(star2starLocationId);
    }

    return result;
  }
}

export default ExtensionsCsvDownloadButtonModel;
