import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AbstractControl from '../AbstractControl';
import '../css/toggle-input.css';

class ToggleControl extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.bool.isRequired,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorText: PropTypes.string,
    tooltipText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    showErrorOnDisabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  render() {
    const {
      // Props for current component
      id,
      value,
      lockedState,
      disabledState,
      className,
      parentClassName,
      intl,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      showErrorOnDisabled,
    } = this.props;

    const inputAbstractProps = {
      hint,
      style,
      focus,
      label,
      errorText,
      required,
      requiredBlue,
      disabledState,
      tooltipText,
      showErrorOnDisabled,
      className: classNames('toggle-input-component', className),
      parentClassName: classNames(
        { 'toggle-input-abstract': true, disabled: lockedState || disabledState },
        parentClassName
      ),
      labelFor: id,
    };

    return (
      <AbstractControl {...inputAbstractProps}>
        <React.Fragment>
          <input
            id={id}
            className="toggle-checkbox"
            checked={value}
            onChange={this.handleOnChange}
            type="checkbox"
            disabled={lockedState || disabledState}
          />
          <label htmlFor={id} className="toggle-switch">
            <div className="toggle-circle" />
            <span className="toggle-on-text" id={id + 'Yes'}>
              {intl.formatMessage({ id: 'msg_yes' })}
            </span>
            <span className="toggle-off-text" id={id + 'No'}>
              {intl.formatMessage({ id: 'msg_no' })}
            </span>
          </label>
        </React.Fragment>
      </AbstractControl>
    );
  }

  handleOnChange() {
    if (typeof this.props.onChange !== 'function') {
      return false;
    }

    this.props.onChange(!this.props.value);

    return true;
  }
}

export default injectIntl(ToggleControl);
