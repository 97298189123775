import React, { PureComponent } from 'react';
import Tooltip from './nested/Tooltip';
import PropTypes from 'prop-types';
import './css/icon-button.css';

class IconButtonWithTooltip extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    tooltipVerticalAlign: PropTypes.oneOf(['top', 'bottom']),
    onClick: PropTypes.func,
    showTooltip: PropTypes.bool,
    title: PropTypes.string,
    tooltipAlign: PropTypes.oneOf(['left', 'right']),
    form: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    tooltipVerticalAlign: 'bottom',
  };

  render() {
    const { iconClass, showTooltip, tooltipAlign, tooltipText, form, intl, tooltipVerticalAlign, ...btnProps } =
      this.props;

    return (
      <div className="iconButton-with-tooltip-wrapper">
        <button {...btnProps} form={form} type={form ? 'submit' : 'button'}>
          <span className={iconClass} />
        </button>

        {showTooltip && <Tooltip tooltipAlign={tooltipAlign} text={tooltipText} verticalAlign={tooltipVerticalAlign} />}
      </div>
    );
  }
}

export default IconButtonWithTooltip;
