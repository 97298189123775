import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import '../css/tooltip.css';
import classNames from 'classnames';

class Tooltip extends PureComponent {
  static propTypes = {
    tooltipAlign: PropTypes.oneOf(['left', 'right']),
    verticalAlign: PropTypes.oneOf(['top', 'bottom']),
  };

  static defaultProps = {
    tooltipAlign: 'left',
  };

  render() {
    const { text, tooltipAlign, intl, verticalAlign } = this.props;

    const tooltipClassName = classNames({
      'iconButton-tooltip-wrapper': true,
      'right-align-tooltip': tooltipAlign === 'right',
      'left-align-tooltip': tooltipAlign === 'left',
      'top-align': verticalAlign === 'top',
    });

    return (
      <div className={tooltipClassName}>
        <span className="iconButton-tooltip">
          <span>{intl.formatMessage({ id: text })}</span>
        </span>
      </div>
    );
  }
}

export default injectIntl(Tooltip);
