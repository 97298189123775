export const APPLIANCE_PROVIDERS = {
  SANGOMA: 'SANGOMA',
  CUSTOMER: 'CUSTOMER',
};

export const APPLIANCE_PROVIDERS_OPTIONS = [
  {
    key: APPLIANCE_PROVIDERS.SANGOMA,
    value: APPLIANCE_PROVIDERS.SANGOMA,
    text: 'msg_sangoma',
  },
  {
    key: APPLIANCE_PROVIDERS.CUSTOMER,
    value: APPLIANCE_PROVIDERS.CUSTOMER,
    text: 'msg_customer',
  },
];
