import React, { Component, Fragment } from 'react';
import NumberControl from '../Controls/NumberControl';
import translator from '../../common/translate';
import BadgeButton from '../BadgeButton';
import PropTypes from 'prop-types';
import './bulkMarkup.css';

class BulkMarkupRow extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    value: PropTypes.number,
  };

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleApply = this.handleApply.bind(this);
  }

  handleOnChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.props.id, value);
    }
  }

  handleApply() {
    if (typeof this.props.onChange === 'function') {
      this.props.onApply(this.props.id, this.props.value);
    }
  }

  render() {
    const { id, label, value, disabledState, lockedState } = this.props;

    return (
      <Fragment>
        <div id={id} className="bulk-markup-row">
          <NumberControl
            parentClassName="bulk-markup-input"
            id={id + '-input'}
            label={label}
            value={value}
            disabledState={disabledState}
            lockedState={lockedState}
            onChange={this.handleOnChange}
          />
          <span className="bulk-markup-label">%</span>
          <BadgeButton
            id={id + '-button'}
            label={translator.getMessage('msg_apply_markup')}
            btnClassName="primary large"
            disabled={disabledState || lockedState}
            onClick={this.handleApply}
          />
        </div>
      </Fragment>
    );
  }
}

export default BulkMarkupRow;
