import AppController from './AppController';
import { AppStateContext } from '../Context';

class LandingPageController extends AppController {
  constructor() {
    super();

    this.listenAppStateContext = this.listenAppStateContext.bind(this);

    AppStateContext.model.addBroadcastListener(this.listenAppStateContext);
  }

  listenAppStateContext() {
    if (AppStateContext.model.appInitiated) {
      return true;
    }
  }
}

export default LandingPageController;
