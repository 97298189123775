import React from 'react';
import classNames from 'classnames';
import { func, string, number, bool, arrayOf } from 'prop-types';
import { injectIntl } from 'react-intl';
import PackageData from './PackageData';
import translator from '../../../common/translate';

const Package = props => {
  const {
    actionColor = '',
    available = false,
    color = '',
    disabledState = false,
    hidePackage = false,
    id = null,
    intl,
    isPackageChangeEnabled = false,
    keys,
    lockedState = false,
    onSelect,
    onDetailsClick,
    packageClassName = '',
    packageShortName = '',
    selectedPackage = null,
    isSelectedPackageOverride = false,
    disableDetailsClick,
  } = props;

  if (hidePackage) {
    return null;
  }

  const title = !isPackageChangeEnabled ? intl.formatMessage({ id: 'msg_quote_options_cannot_be_changed' }) : '';

  const style = { backgroundColor: color };
  const className = classNames(
    {
      'package-component': true,
      'package-locked': lockedState || disabledState || !available,
      selected: selectedPackage === id,
      'package-override': isSelectedPackageOverride,
    },
    packageClassName
  );

  const onClick = () => {
    if (lockedState || disabledState || !available) {
      return;
    }

    onSelect(id);
  };

  const onDetailsClickHandler = () => {
    if (!disableDetailsClick && typeof onDetailsClick === 'function') {
      onDetailsClick(id);
    }
  };

  const detailsClassName = classNames({
    'package-action': true,
    'quote-initializing': disableDetailsClick,
  });

  return (
    <div className={className} title={title}>
      <div className="package-header" onClick={onClick} style={style}>
        <div className="package-select-circle" />
        <span className="package-label">{packageShortName}</span>
      </div>

      <div className="package-content" style={style}>
        {keys.map(key => (
          <PackageData {...props[key]} key={`package-data-${id}-${key}`} />
        ))}
        <div className={detailsClassName} onClick={onDetailsClickHandler} style={{ backgroundColor: actionColor }}>
          {translator.getMessage('msg_view_details')}
        </div>
      </div>
    </div>
  );
};

Package.propTypes = {
  actionColor: string,
  available: bool,
  color: string,
  disabledState: bool,
  hidePackage: bool,
  id: number,
  isPackageChangeEnabled: bool,
  keys: arrayOf(string).isRequired,
  lockedState: bool,
  onSelect: func.isRequired,
  onDetailsClick: func.isRequired,
  packageClassName: string,
  packageShortName: string,
  selectedPackage: number,
  isSelectedPackageOverride: bool,
};

export default injectIntl(Package);
