import { v4 as UUID } from 'uuid';

function AutocompleteValue() {
  // The "off" value was introduces by KM-9662 accessibility improvements ticket
  // But it seems like Chrome does not respect "off" value
  // return 'off';

  // KM-9689: This work around disables Chrome's auto complete feature
  return 'new-password-' + UUID();
}

export default AutocompleteValue;
