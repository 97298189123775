import React from 'react';

export default function Option({ id, text, disabled = false, highlighted = false, onClick, onMouseOver }) {
  const classNames = ['option'];

  if (disabled) {
    classNames.push('disabled');
  }

  if (highlighted && !disabled) {
    classNames.push('highlight');
  }

  return (
    <li
      id={id}
      className={classNames.join(' ')}
      tabIndex="-1"
      onClick={disabled ? null : onClick}
      onMouseOver={disabled ? null : onMouseOver}
      // &nbsp; added to preserve option item height in case of empty text value
    >
      {typeof text === 'string' && text.length === 0 ? <span>&nbsp;</span> : text}
    </li>
  );
}
