import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { v4 as UUID } from 'uuid';
import config from '../../../config';
import AutocompleteValue from '../../../common/helpers/AutocompleteValue';
import { AppStateContext } from '../../../Context';
import { getTranslateString } from '../../../common/helpers/getTranslateString';
import EscapeRegExp from '../../../common/helpers/EscapeRegExp';

class AbstractInput extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    value: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,

    onBeforeStateChange: PropTypes.func,
    onStateChange: PropTypes.func,
    onBeforeCommit: PropTypes.func,
    onChange: PropTypes.func,
    onCommit: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,

    commitOnBlur: PropTypes.bool,
    commitDelay: PropTypes.number,

    type: PropTypes.oneOf(['text', 'email', 'number', 'password', 'search', 'tel', 'url']),
    doFocus: PropTypes.bool,
    className: PropTypes.string,
    maxLength: PropTypes.number,
    placeholder: PropTypes.string,
    ariaLabel: PropTypes.string,
    formatter: PropTypes.func,
    forbiddenCharactersList: PropTypes.string,

    inputElement: PropTypes.element,
  };

  static defaultProps = {
    type: 'text',
    doFocus: false,
    commitOnBlur: config.commitInputsOnBlur,
    commitDelay: 5000,
    className: 'abstract-input-field',
    required: false,
    inputElement: React.createElement('input'),
  };

  static getDerivedStateFromProps(props, state) {
    if (state._preventDerivedStateFromProps) {
      return null;
    }

    if (props.value !== state.value && !state.isFocused) {
      return {
        value: props.value === null || props.value === undefined ? '' : String(props.value),
      };
    }

    return null;
  }

  constructor(props) {
    super(props);

    this.state = {
      value: props.value === null || props.value === undefined ? '' : String(props.value),
      isFocused: false,
      _preventDerivedStateFromProps: false,
    };

    this.inputAutocompleteValue = AutocompleteValue();
    this.inputNameValue = 'input-' + UUID();

    this.commitTimeout = null;
    this.domNode = React.createRef();

    this.lastCommitedValue = null;

    this.handleBlur = this.handleBlur.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleStateChange = this.handleStateChange.bind(this);
    this.commitValue = this.commitValue.bind(this);
  }

  get commitOnBlur() {
    // KM-11209: Force commit on blur if auto-calculation is turned off
    return this.props.commitOnBlur || !AppStateContext.model.isAutoCalculateEnabled;
  }

  componentDidMount() {
    if (this.props.doFocus) {
      this.domNode.current.focus();
    }
  }

  componentDidUpdate(prevProps) {
    // TODO: Debug this. If this causes multiple focus events - props are invalid.
    if (prevProps.doFocus === false && this.props.doFocus === true && this.state.isFocused === false) {
      this.domNode.current.focus();
    }
  }

  handleFocus(ev) {
    this.setState({
      isFocused: true,
    });

    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(ev);
    }

    return true;
  }

  handleBlur(ev) {
    this.setState({
      isFocused: false,
    });

    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(ev);
    }

    if (this.commitOnBlur && this.lastCommitedValue !== ev.target.value) {
      this.commitValue(ev.target.value);
    }

    return true;
  }

  handleChange(ev) {
    let stringValue = ev.target.value;

    if (this.props.forbiddenCharactersList) {
      const regex = new RegExp(`[${EscapeRegExp(this.props.forbiddenCharactersList)}]`, 'gi');

      stringValue = stringValue.replace(regex, '');
    }

    if (typeof this.props.onBeforeStateChange !== 'function' || this.props.onBeforeStateChange(stringValue)) {
      this.setState(
        () => ({
          value: stringValue,
          _preventDerivedStateFromProps: true,
        }),
        this.handleStateChange
      );
    }

    return true;
  }

  handleStateChange() {
    const commitDelay = Number(this.props.commitDelay) || 0;

    if (!this.commitOnBlur || commitDelay === 0) {
      this.commitValue(this.state.value);

      return;
    }

    this.clearCommitTimeout();

    if (typeof this.props.onStateChange === 'function') {
      this.props.onStateChange(this.state.value);
    }

    this.commitTimeout = setTimeout(this.commitValue, commitDelay, this.state.value);
  }

  commitValue(stringValue) {
    this.clearCommitTimeout();

    if (typeof this.props.onBeforeCommit === 'function' && !this.props.onBeforeCommit(stringValue)) {
      return false;
    }

    if (typeof this.props.onCommit === 'function') {
      this.lastCommitedValue = stringValue;

      this.props.onCommit(stringValue);
    }

    this.setState(() => ({ _preventDerivedStateFromProps: false }));

    return true;
  }

  clearCommitTimeout() {
    if (this.commitTimeout) {
      clearTimeout(this.commitTimeout);
    }
  }

  render() {
    const placeholder = this.props.placeholder;

    const placeholderTextStr =
      typeof placeholder === 'string'
        ? getTranslateString(placeholder, this.props.intl)
        : placeholder && placeholder.props && this.props.intl.formatMessage({ id: placeholder.props.id });

    const value = this.state.value;

    let props = {
      autoComplete: this.inputAutocompleteValue,
      name: this.inputNameValue,
      id: this.props.id,
      // TODO: Investigate this
      // type === 'number' prevents formatted amount to be displayed
      // force type = 'text' for all inputs except password
      type: this.props.type === 'password' ? 'password' : 'text', //this.props.type,
      value: typeof this.props.formatter === 'function' && !this.state.isFocused ? this.props.formatter(value) : value,
      disabled: this.props.disabled,
      required: this.props.required,
      className: this.props.className,
      placeholder: placeholderTextStr,
      onFocus: this.handleFocus,
      onChange: this.handleChange,
      onBlur: this.handleBlur,
      ref: this.domNode,
      'aria-label': this.props.ariaLabel,
      maxLength: this.props.maxLength,
    };

    const inputElement = this.props.inputElement;

    return <inputElement.type {...props} {...inputElement.props} />;
  }
}

export default injectIntl(AbstractInput);
