import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import bindMultiple from '../../common/helpers/bindMultiple';
import Dialog from '../Dialog';
import QuoteDeviceCatalog from '../QuoteDeviceCatalog';
import './css/add-line-item-dialog.css';
import BadgeButton from '../BadgeButton';

const dialogProps = {
  modal: true,
  contentStyle: {
    width: 900,
    zIndex: 9999,
  },
  bodyStyle: {
    background: 'rgb(233, 236, 239)',
    padding: 30,
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  titleStyle: {
    boxShadow: '0 2px 4px 0 rgba(32, 36, 40, 0.48)',
    height: 80,
    boxSizing: 'border-box',
    background: 'rgb(0, 84, 150)',
    color: '#fff',
  },
  titleClassName: 'dialog-header-left',
  actionsContainerStyle: {
    height: 64,
    backgroundColor: '#00213c',
    padding: '12px 23px',
  },
  autoScrollBodyContent: true,
  modalClassName: 'add-line-item-modal-dialog',
  bodyClassName: 'quote-devices-dialog',
  contentClassName: 'quote-devices-dialog-content',
  actionsContainerClassName: 'quote-devices-dialog-actions',
  dialogWithErrors: true,
};

class AddLineItemDialog extends Component {
  static propTypes = {
    categoryId: PropTypes.number,
    title: PropTypes.string,
    catalog: PropTypes.arrayOf(PropTypes.object),
    headers: PropTypes.arrayOf(PropTypes.object),
    selectedDevices: PropTypes.arrayOf(PropTypes.object),
    opened: PropTypes.bool,
    onClose: PropTypes.func,
    onSelect: PropTypes.func,
    hasBundles: PropTypes.bool,
    isRecurring: PropTypes.bool,
    showSolutionTypeMark: PropTypes.bool,
    partNumbersFromOriginalQuote: PropTypes.arrayOf(PropTypes.string),
    showEmptyDialogHint: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    suppressedIds: PropTypes.arrayOf(PropTypes.number),
    isForExistingItems: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    catalog: [],
    selectedDevices: [],
    opened: true,
    showEmptyDialogHint: false,
    suppressedIds: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      suppressedIds: this.props.suppressedIds,
      checkedLineItems: [],
    };

    bindMultiple(this, this.handleCheckItem, this.handleClose, this.handleSelect, this.handleSuppressChange);
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose(this.state.suppressedIds);
    }
  }

  handleSelect() {
    if (typeof this.props.onSelect === 'function') {
      const suppressedIds = this.state.suppressedIds;
      const lineItemIds = this.state.checkedLineItems.map(d => d.lineItemId);

      this.props.onSelect({ lineItemIds, suppressedIds });
    }
  }

  handleCheckItem(item) {
    const checkedLineItems = [...this.state.checkedLineItems];
    const index = checkedLineItems.findIndex(d => d.lineItemId === item.lineItemId);

    if (index > -1) {
      checkedLineItems.splice(index, 1);
    } else {
      checkedLineItems.push(item);
    }

    this.setState(() => ({ checkedLineItems }));
  }

  handleSuppressChange(suppressedIds) {
    this.setState(() => ({ suppressedIds }));
  }

  render() {
    const {
      title,
      opened,
      catalog,
      headers,
      selectedDevices,
      hasBundles,
      isRecurring,
      categoryId,
      partNumbersFromOriginalQuote,
      showEmptyDialogHint,
      showSolutionTypeMark,
      isSalesOps,
      isForExistingItems,
      intl,
    } = this.props;

    const catalogProps = {
      categoryId,
      catalog,
      headers,
      selectedDevices,
      onCheckItem: this.handleCheckItem,
      hasBundles,
      isRecurring,
      selectedItems: this.state.checkedLineItems,
      showSolutionTypeMark,
      partNumbersFromOriginalQuote,
      isSalesOps,
      suppressedIds: this.state.suppressedIds,
      onSuppressChange: this.handleSuppressChange,
    };

    let addButtonLabel = this.props.intl.formatMessage({ id: 'msg_add' });

    if (this.state.checkedLineItems.length) {
      addButtonLabel += ' ' + this.state.checkedLineItems.length + ' item';

      if (this.state.checkedLineItems.length > 1) {
        addButtonLabel += 's';
      }
    }

    return (
      <Dialog
        {...dialogProps}
        contentClassName={showEmptyDialogHint ? 'empty-dialog-hint' : dialogProps.contentClassName}
        isOpen={opened}
        actions={[
          <button
            key="quote-devices-modal-cancel-button"
            id="quote-devices-modal-cancel-button"
            className="btn-cancel text-btn"
            onClick={this.handleClose}
          >
            {translator.getMessage('msg_cancel')}
          </button>,
          !showEmptyDialogHint ? (
            <BadgeButton
              key="quote-devices-modal-add-button"
              id="quote-devices-modal-add-button"
              btnClassName="btn-add primary"
              onClick={this.handleSelect}
              disabled={!this.state.checkedLineItems.length}
              label={addButtonLabel}
              infoTooltip={
                !this.state.checkedLineItems.length ? 'msg_add_line_item_dialog_add_button_no_items_tooltip' : null
              }
              tooltipAlign="left"
              tooltipVerticalAlign={'top'}
              hideInfoBadge
            />
          ) : null,
        ]}
        onRequestClose={this.handleClose}
        title={
          <div>
            <header className="text-ellipsis" title={title}>
              {isForExistingItems ? intl.formatMessage({ id: 'msg_existing_line_item_prefix' }) + ' - ' + title : title}
            </header>
          </div>
        }
      >
        {showEmptyDialogHint ? (
          <div className="quote-device-catalog">
            <span>
              {translator.getMessage('msg_empty_line_item_dialog_hint')}
              <a href="mailto:AskRQ@star2star.com"> AskRQ@star2star.com</a>
            </span>
          </div>
        ) : (
          <QuoteDeviceCatalog {...catalogProps} />
        )}
      </Dialog>
    );
  }
}

export default injectIntl(AddLineItemDialog);
