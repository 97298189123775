import React, { PureComponent } from 'react';
import { TextControl } from '../Controls';

class LocationName extends PureComponent {
  constructor() {
    super();

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange('locationName', value);
    }
  }

  render() {
    return (
      <div className="location-name-wrapper">
        <TextControl {...this.props} label="msg_location_name" onChange={this.handleOnChange} changeOnBlur={true} />
      </div>
    );
  }
}

export default LocationName;
