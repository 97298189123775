import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { UpperCaseEachWord } from '../../../../common/helpers/format';
import { CUSTOMIZE_PDF_OPTION_LABELS, CUSTOMIZE_PDF_OPTION_LIST, ESIG_DOCS_LABELS } from '../../../../common/enums';
import translator from '../../../../common/translate/index';
import { ToggleControl } from '../../../Controls';
import PureViewComponent from '../../../PureViewComponent';

class DetailsTable extends PureViewComponent {
  static propTypes = {
    data: PropTypes.object,
    showPdf: PropTypes.bool,
  };

  static defaultProps = {
    data: {},
    showPdf: true,
  };

  renderDetailsHeader(customPdf, _showPdf, showPdfContextHeader) {
    const showPdf = customPdf && _showPdf;
    const headerClassNames = classNames({
      'signature-details-table-header': true,
      'details-has-pdf': showPdf,
      'has-pdf-context': showPdfContextHeader,
    });
    const pdfHeader = showPdf ? translator.getMessage('msg_custom_pdf') : '';

    return (
      <div className={headerClassNames}>
        <div className="envelope-header">{translator.getMessage('msg_envelope')}</div>
        <div className="contents-header">{translator.getMessage('msg_esig_history_dialog_table_header_contents')}</div>
        {showPdfContextHeader && (
          <div className="pdf-context-header">{translator.getMessage('msg_esig_details_dialog_context_pdf_header')}</div>
        )}
        {showPdf && <div className="custom-pdf-header">{pdfHeader}</div>}
      </div>
    );
  }

  renderDetailsBody(data, customPdf, _showPdf) {
    const eSignaturePdfContext = data.eSignaturePdfContext;
    const contents = data.eSignatureDocuments ? this.getContents(data.eSignatureDocuments) : null;
    const showPdf = customPdf && _showPdf;
    const bodyClassNames = classNames({
      'signature-details-table-body': true,
      'details-has-pdf': showPdf,
      'has-pdf-context': eSignaturePdfContext,
    });

    return (
      <div className={bodyClassNames}>
        <div className="envelope-body">
          <div className="envelope-body-row">
            <span>{translator.getMessage('msg_subject')}:</span>
            <span>
              {data.subject} (Quote #{data.quoteId})
            </span>
          </div>
          <div className="envelope-body-row">
            <span>{translator.getMessage('msg_message')}:</span>
            <span>{data.message}</span>
          </div>
        </div>
        <div className="contents-body">{contents}</div>
        {eSignaturePdfContext && <div className="context-body">{this.getPdfContext(eSignaturePdfContext)}</div>}
        {showPdf && (
          <div className="custom-pdf-body">
            {customPdf && (
              <div className="custom-pdf-body-row">
                <span>{translator.getMessage('msg_name')}:</span>
                <span>{customPdf.fileName}</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }

  getDocumentLabel(type) {
    return ESIG_DOCS_LABELS[type]
      ? this.props.intl.formatMessage({ id: ESIG_DOCS_LABELS[type] })
      : UpperCaseEachWord(type, '_');
  }

  getContents(selectedDocuments) {
    return selectedDocuments.map(d => (
      <div className="contents-body-row" key={d.documentType + '-signature-details'}>
        {this.getDocumentLabel(d.documentType)}
      </div>
    ));
  }

  getPdfContext(context) {
    const toggles = [];

    for (let i = 0; i < CUSTOMIZE_PDF_OPTION_LIST.length; i++) {
      const key = CUSTOMIZE_PDF_OPTION_LIST[i];
      const value = Boolean(context[key]);

      toggles.push(
        <div className="pdf-context-body-row" key={'pdf-context-' + key}>
          <ToggleControl
            id={'pdf-context-' + key}
            parentClassName="pdf-context-toggle-input"
            className="compact"
            value={value}
            label={translator.getMessage(CUSTOMIZE_PDF_OPTION_LABELS[key])}
            lockedState={true}
          />
        </div>
      );
    }

    return toggles;
  }

  render() {
    const { data, showPdf, customPdf } = this.props;

    return (
      <div className="signature-details-table">
        {this.renderDetailsHeader(customPdf, showPdf, Boolean(data.eSignaturePdfContext))}
        {this.renderDetailsBody(data, customPdf, showPdf)}
      </div>
    );
  }
}

export default injectIntl(DetailsTable);
