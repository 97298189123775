import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../../common/translate';
import Dialog from '../../Dialog';
import BadgeButton from '../../BadgeButton';
import HistoryTable from './HistoryTable';
import './signature-history.css';
import PdfHiddenForm from '../../PdfHiddenForm';

class History extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    isInternalUser: PropTypes.bool,
    quoteStatus: PropTypes.string,
    isExistingAls: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
    onClickClose: PropTypes.func,
    onClickRefresh: PropTypes.func,
    partnerPdfEndpoint: PropTypes.string,
    quickQuoteEndpoint: PropTypes.string,
    onClickSignatureCancel: PropTypes.func,
    getSignaturePreviewUrlAndValues: PropTypes.func,
    onClickSignatureDetails: PropTypes.func,
    onClickReSubmitSignature: PropTypes.func,
    onClickVoidSignature: PropTypes.func,
    onClickAdditionalCredit: PropTypes.func,
    getSignatureDownloadEndpoint: PropTypes.func,

    quickQuoteDisabled: PropTypes.bool,
    quickQuoteErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    quickQuoteErrorsCount: PropTypes.number,

    partnerPdfDisabled: PropTypes.bool,
    partnerPdfErrors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    partnerPdfErrorsCount: PropTypes.number,

    signatureCancelDisabled: PropTypes.bool,
    signatureReSubmitAllowed: PropTypes.bool,
    allowToVoid: PropTypes.bool,

    additionalCreditDisabled: PropTypes.bool,
    additionalCreditVisible: PropTypes.bool,
    additionalCreditInfoTooltip: PropTypes.string,

    additionalCreditEnvelope: PropTypes.object,
    availableEnvelopeTypes: PropTypes.array,

    showPartnerPdf: PropTypes.bool,
  };

  static defaultProps = {
    quickQuoteDisabled: false,
    partnerPdfDisabled: false,
    additionalCreditEnvelope: {},
    availableEnvelopeTypes: [],
    additionalCreditDisabled: true,
    additionalCreditVisible: false,
    additionalCreditInfoTooltip: '',
    isVisible: true,
  };

  constructor(props) {
    super(props);

    this.handleClickClose = this.handleClickClose.bind(this);
    this.handleClickRefresh = this.handleClickRefresh.bind(this);
    this.handleClickAdditionalCredit = this.handleClickAdditionalCredit.bind(this);
  }

  handleClickClose() {
    if (typeof this.props.onClickClose === 'function') {
      this.props.onClickClose();
    }
  }

  handleClickRefresh() {
    if (typeof this.props.onClickRefresh === 'function') {
      this.props.onClickRefresh(true);
    }
  }

  handleClickAdditionalCredit() {
    if (typeof this.props.onClickAdditionalCredit === 'function') {
      this.props.onClickAdditionalCredit(this.props.additionalCreditEnvelope);
    }
  }

  render() {
    let dialogProps = {
      modalClassName: 'signature-history-modal',
      actionsContainerClassName: 'submit-dialog-actions',
    };

    dialogProps.actions = [
      <button key="signature-close" id="signature-close" className="btn-signature-close" onClick={this.handleClickClose}>
        {translator.getMessage('msg_close')}
      </button>,

      <button
        key="signature-refresh"
        id="signature-refresh"
        className="btn-signature-refresh text-btn"
        onClick={this.handleClickRefresh}
      >
        {translator.getMessage('msg_refresh')}
      </button>,

      <BadgeButton
        key="signature-preview-quick-quote"
        id="signature-preview-quick-quote"
        className="btn-signature-preview-quick-quote"
        btnClassName="tertiary"
        label={translator.getMessage('msg_esig_history_dialog_preview_quick_quote')}
        infoTooltip={'msg_quick_quote_tooltip'}
        hideInfoBadge={true}
        disabled={this.props.quickQuoteDisabled}
        errors={this.props.quickQuoteErrors}
        errorsCnt={this.props.quickQuoteErrorsCount}
        form="signature-preview-quick-quote-form"
        tooltipVerticalAlign={'top'}
      />,
      <PdfHiddenForm
        id="signature-preview-quick-quote-form"
        key="signature-preview-quick-quote-form"
        endpoint={this.props.quickQuoteEndpoint}
      />,

      this.props.showPartnerPdf && (
        <BadgeButton
          key="signature-print-partner-pdf"
          id="signature-print-partner-pdf"
          className="btn-signature-print-partner-pdf"
          btnClassName="tertiary"
          label={translator.getMessage('msg_esig_history_dialog_print_partner_pdf')}
          infoTooltip={''}
          hideInfoBadge={true}
          disabled={this.props.partnerPdfDisabled}
          errors={this.props.partnerPdfErrors}
          errorsCnt={this.props.partnerPdfErrorsCount}
          form="signature-print-partner-pdf-form"
          tooltipVerticalAlign={'top'}
        />
      ),
      <PdfHiddenForm
        id="signature-print-partner-pdf-form"
        key="signature-print-partner-pdf-form"
        endpoint={this.props.partnerPdfEndpoint}
      />,

      this.props.additionalCreditVisible && (
        <BadgeButton
          key="signature-new-additional-credit"
          id="signature-new-additional-credit"
          className="btn-signature-new-additional-credit"
          btnClassName="tertiary"
          onClick={this.handleClickAdditionalCredit}
          label={translator.getMessage('msg_additional_credit')}
          hideInfoBadge={true}
          infoTooltip={this.props.additionalCreditInfoTooltip}
          disabled={this.props.additionalCreditDisabled}
          tooltipAlign="left"
        />
      ),
    ];

    dialogProps.title = (
      <div className="submit-title-wrapper">
        <header className="submit-title-header text-ellipsis">{translator.getMessage('msg_signatures')}</header>
      </div>
    );

    dialogProps.onRequestClose = this.handleClickClose;

    return (
      <Dialog {...dialogProps} isOpen={this.props.isVisible}>
        <HistoryTable
          data={this.props.data}
          onClickCancel={this.props.onClickSignatureCancel}
          getPreviewUrlAndValues={this.props.getSignaturePreviewUrlAndValues}
          onClickDetails={this.props.onClickSignatureDetails}
          onClickReSubmit={this.props.onClickReSubmitSignature}
          onClickVoid={this.props.onClickVoidSignature}
          getSignatureDownloadEndpoint={this.props.getSignatureDownloadEndpoint}
          cancelDisabled={this.props.signatureCancelDisabled}
          reSubmitAllowed={this.props.signatureReSubmitAllowed}
          allowToVoid={this.props.allowToVoid}
          isSalesOps={this.props.isSalesOps}
          quoteStatus={this.props.quoteStatus}
          isExistingAls={this.props.isExistingAls}
          availableEnvelopeTypes={this.props.availableEnvelopeTypes}
        />
      </Dialog>
    );
  }
}

export default History;
