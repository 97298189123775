import React from 'react';
import { EMAIL_REGEX, SIGNER_STATUS, SIGNER_STATUS_LIST } from '../../../../common/enums';
import { TextControl } from '../../../Controls';
import classNames from 'classnames';
import { bool, number, string, func } from 'prop-types';

const EmailCell = ({
  status,
  s2sSigner,
  email,
  id,
  isSalesOps,
  isEmailEdit,
  onEmailChange,
  onApproveEmailEditClick,
  onCancelEmailEditClick,
  onEditEmailClick,
}) => {
  const allowEmailEdit =
    isSalesOps &&
    s2sSigner &&
    SIGNER_STATUS_LIST.includes(status) &&
    [SIGNER_STATUS.AWAITING_SIGNATURE, SIGNER_STATUS.ON_HOLD].includes(status);
  const errorText = !email.match(EMAIL_REGEX) ? 'Enter correct email' : '';
  const onEmailChangeHandler = React.useCallback(value => onEmailChange(id, value), [id, onEmailChange]);
  const onApproveEmailEditHandler = React.useCallback(
    () => onApproveEmailEditClick(email),
    [email, onApproveEmailEditClick]
  );
  const onEditEmailHandler = React.useCallback(() => onEditEmailClick(id, email), [email, id, onEditEmailClick]);

  return isEmailEdit ? (
    <span className="inline-grid-columns email-cell-editable">
      <TextControl
        id={'signer-email-' + id}
        value={email}
        onChange={onEmailChangeHandler}
        errorText={errorText}
        parentClassName="compact"
      />
      <span className="buttons">
        <button
          disabled={Boolean(errorText)}
          onClick={onApproveEmailEditHandler}
          className={classNames({
            'icon icon-check text-btn small no-border': true,
            disabled: Boolean(errorText),
          })}
        />
        <button onClick={onCancelEmailEditClick} className="icon icon-close text-btn small no-border" />
      </span>
    </span>
  ) : (
    <span className="inline-grid-columns email-cell">
      <span className="email-label" title={email}>
        {email}
      </span>
      {allowEmailEdit && <button onClick={onEditEmailHandler} className="icon icon-edit text-btn small no-border" />}
    </span>
  );
};

EmailCell.propTypes = {
  status: string,
  s2sSigner: bool,
  email: string,
  id: number,
  isSalesOps: bool,
  isEmailEdit: bool,
  onEmailChange: func,
  onApproveEmailEditClick: func,
  onCancelEmailEditClick: func,
  onEditEmailClick: func,
};

export default EmailCell;
