import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AbstractControl from '../AbstractControl';
import '../css/label-field.css';

class LabelField extends PureComponent {
  static propTypes = {
    style: PropTypes.object,
    textRight: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    id: PropTypes.string,
    hint: PropTypes.string,
    value: PropTypes.node,
    visibleValue: PropTypes.node,
    marker: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  render() {
    const {
      // Props for current component
      id,
      value,
      visibleValue,
      marker,
      className,
      textRight,
      // Props for abstract component
      hint,
      style,
      label,
      required,
      requiredBlue,
      tooltipText,
      parentClassName,
      intl,
    } = this.props;

    const componentClassName = classNames(
      {
        'label-field-component': true,
      },
      className
    );

    const inputAbstractProps = {
      hint,
      style,
      label,
      required,
      requiredBlue,
      tooltipText,
      parentClassName: classNames({ 'text-right': textRight }, parentClassName),
      className: componentClassName,
    };

    const valueWithTranslation =
      typeof value === 'string' && value.trim().substr(0, 4) === 'msg_' ? intl.formatMessage({ id: value }) : value;

    return (
      <AbstractControl {...inputAbstractProps}>
        <div id={id} title={visibleValue || valueWithTranslation}>
          {marker ? marker : ''}
          {visibleValue || valueWithTranslation}
        </div>
      </AbstractControl>
    );
  }
}

export default injectIntl(LabelField);
