import React, { Component, createRef } from 'react';
import { injectIntl } from 'react-intl';
import { throttle } from 'lodash';
import bindMultiple from './../../../../common/helpers/bindMultiple';
import Loader from '../../../Loader';
import Paginator from './nested/Paginator';
import Toolbar from './nested/Toolbar';
import Table from './nested/Table';
import getPageRange from './helpers/getPageRange';
import getRowsLimit from './helpers/getRowsLimit';
import propTypes from './propTypes';
import './quote.wizard.style.css';

class QuoteTableListComponent extends Component {
  static propTypes = propTypes;

  constructor(props, context) {
    super(props, context);

    this.tableWrapperRef = createRef();
    this.tableHeaderRef = createRef();
    this.tableRowRef = createRef();
    this.paginatorRef = createRef();
    this.tableRefs = {
      headerRef: this.tableHeaderRef,
      rowRef: this.tableRowRef,
    };

    bindMultiple(
      this,
      this.handleChangeLimit,
      this.handleChangePage,
      this.handleChangeSearch,
      this.handleChangeSort,
      this.handleSearchClear,
      this.updateDimensions,
      this.handleChangeColumnFilter,
      this.handleClearColumnFilter
    );
  }

  get pageRange() {
    return getPageRange(this.tableWrapperRef);
  }

  handleChangePage(pageNumber) {
    if (typeof this.props.onChangePage === 'function') {
      this.props.onChangePage(pageNumber);
    }
  }

  handleChangeSort(ev) {
    if (typeof this.props.onChangeSort === 'function') {
      this.props.onChangeSort(ev.currentTarget.dataset.columnId);
    }
  }

  handleChangeSearch(searchString) {
    if (typeof this.props.onChangeSearch === 'function') {
      this.props.onChangeSearch(searchString);
    }
  }

  handleChangeLimit(limit) {
    if (typeof this.props.onChangeLimit === 'function') {
      this.props.onChangeLimit(limit);
    }
  }

  handleChangeColumnFilter(columnType, value) {
    if (typeof this.props.onChangeColumnFilter === 'function') {
      this.props.onChangeColumnFilter(columnType, value);
    }
  }

  handleClearColumnFilter(columnType) {
    if (typeof this.props.onClearColumnFilter === 'function') {
      this.props.onClearColumnFilter(columnType);
    }
  }

  handleSearchClear() {
    this.handleChangeSearch('');
  }

  componentDidMount() {
    this.trottledUpdateDimensions = throttle(this.updateDimensions, 1000);
    window.addEventListener('resize', this.trottledUpdateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.quotes.length && this.props.quotes.length) {
      this.updateDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.trottledUpdateDimensions);
  }

  updateDimensions() {
    const limit = getRowsLimit(this.paginatorRef, this.tableHeaderRef, this.tableRowRef);
    this.handleChangeLimit(limit);
  }

  render() {
    const { quotes, intl, isLoading, page, limit, searchString, totalCount, sortOrder, sortBy, columnFilters } =
      this.props;

    const inputIconClassName = isLoading ? 'loader' : 'icon-search';
    const noResultsMessage = searchString ? 'msg_no_matching_quotes_found' : 'msg_no_quotes_created_yet';

    return (
      <main>
        <div className="retrieve-quotes" ref={this.tableWrapperRef}>
          <Toolbar
            inputIconClassName={inputIconClassName}
            onChangeSearch={this.handleChangeSearch}
            onSearchClear={this.handleSearchClear}
            searchString={searchString}
            totalItemsCount={totalCount}
            columnFilters={columnFilters}
            onChangeColumnFilter={this.handleChangeColumnFilter}
            onClearColumnFilter={this.handleClearColumnFilter}
          />
          <Table
            intl={intl}
            isLoading={isLoading}
            noResultsMessage={noResultsMessage}
            onChangeSort={this.handleChangeSort}
            quotes={quotes}
            ref={this.tableRefs}
            sortAsc={sortOrder === 'asc'}
            sortBy={sortBy}
          />
          <Loader isVisible={!quotes.length && isLoading} parentClassName="quote-table-loader" />
        </div>
        <Paginator
          activePage={page}
          itemsCountPerPage={limit}
          onChange={this.handleChangePage}
          pageRangeDisplayed={this.pageRange}
          ref={this.paginatorRef}
          totalItemsCount={totalCount}
          visible={totalCount > limit}
        />
      </main>
    );
  }
}

export default injectIntl(QuoteTableListComponent);
