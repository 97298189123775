import React from 'react';
import { createRoot } from 'react-dom/client';
import './assets/styles.css';
import './AppRouter/AppRouter';
import AppRouterComponent from './AppRouter/AppRouterComponent';
import { IntlProvider } from 'react-intl';
import formats from './common/translate/formats';
import * as messages from './common/translate/languages/';
import { AppStateContext, AuthContext, ErrorContext, IntlContext, PartnerContext } from './Context';

const dashToUScore = str => str.replace('-', '_');

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <IntlContext.Provider>
    <IntlContext.Consumer>
      {locale => {
        return (
          <IntlProvider locale={locale.locale} messages={messages[dashToUScore(locale.locale)]} formats={formats}>
            <AuthContext.Provider>
              <ErrorContext.Provider>
                <AppStateContext.Provider>
                  <PartnerContext.Provider>
                    <AppRouterComponent />
                  </PartnerContext.Provider>
                </AppStateContext.Provider>
              </ErrorContext.Provider>
            </AuthContext.Provider>
          </IntlProvider>
        );
      }}
    </IntlContext.Consumer>
  </IntlContext.Provider>
);
