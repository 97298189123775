import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import translator from '../../../common/translate';
import { CheckboxControl } from '../../Controls';
import PureViewComponent from '../../PureViewComponent';
import '../css/providers.css';
import Provider from './Provider';

class Providers extends PureViewComponent {
  static propTypes = {
    providers: PropTypes.array,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isLocationQuote: PropTypes.bool,
    showOverrideColumn: PropTypes.bool,
    setMixed: PropTypes.func,
    salesOps: PropTypes.bool,
    confirmationBoxVisible: PropTypes.bool,
    confirmationBoxDisabled: PropTypes.bool,
  };

  static defaultProps = {
    providers: [],
    lockedState: false,
    disabledState: false,
    showOverrideColumn: false,
    confirmationBoxVisible: false,
    confirmationBoxDisabled: false,
  };

  constructor(props) {
    super(props);

    this.onCheck = this.onCheck.bind(this);
  }

  renderProvidersHeader(props) {
    const ictpProvidersHeader = classNames({
      'ictp-providers-header-sales-ops': this.props.salesOps,
      'ictp-providers-header': !this.props.salesOps,
    });
    return (
      <div className={ictpProvidersHeader}>
        <div className="ictp-providers-label">{translator.getMessage('msg_type')}</div>
        <div className="ictp-providers-options">{translator.getMessage('msg_party')}</div>
        {props.isLocationQuote && (
          <Fragment>
            <div className="ictp-providers-price">{translator.getMessage('msg_total')}</div>
            <div className="ictp-providers-price">{translator.getMessage('msg_available')}</div>
          </Fragment>
        )}
        <div className="ictp-providers-price">{translator.getMessage('msg_price')}</div>
        {props.showOverrideColumn ? (
          <div className="ictp-providers-price" id="ictp-override-header">
            {translator.getMessage('msg_override')}
          </div>
        ) : (
          this.props.salesOps && <div />
        )}
        {!props.isLocationQuote && (
          <div
            className="ictp-providers-price"
            title={this.props.intl.formatMessage({
              id: 'msg_partner_service_tooltip',
            })}
          >
            {translator.getMessage('msg_partner_services_estimates')}
          </div>
        )}
      </div>
    );
  }

  renderProvidersBody(props) {
    return props.providers.map(provider => {
      return (
        <Provider
          {...provider}
          key={provider.uuid}
          lockedState={props.lockedState}
          disabledState={props.disabledState}
          showOverrideColumn={props.showOverrideColumn}
          setMixed={props.setMixed}
          mixed={props.mixed}
          mixedDealerNet={props.mixedDealerNet}
          isLocationQuote={props.isLocationQuote}
          salesOps={props.salesOps}
        />
      );
    });
  }

  onCheck(isChecked) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange('isConfirmed', isChecked);
    }
  }

  render() {
    const { confirmationBoxVisible, confirmationBoxDisabled } = this.props;

    let confirmationClassName = classNames({
      'ictp-acknowledgement': true,
      locked: confirmationBoxDisabled,
    });

    return (
      <div className="ictp-providers">
        {this.renderProvidersHeader(this.props)}
        {this.renderProvidersBody(this.props)}
        {confirmationBoxVisible && (
          <div className={confirmationClassName}>
            <CheckboxControl
              id="addOnIctpConfirmation"
              label={translator.getMessage('msg_add_on_ictp_confirmation')}
              value={this.props.isConfirmed}
              disabledState={confirmationBoxDisabled}
              onChange={this.onCheck}
            />
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(Providers);
