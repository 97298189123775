export const SIGNATURE_STATUS = {
  SENT: 'SENT',
  PENDING: 'PENDING',
  ON_HOLD: 'ON_HOLD',
  COMPLETED: 'COMPLETED',
  DECLINED: 'DECLINED',
  CANCEL_REQUESTED: 'CANCEL_REQUESTED',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
  VOIDED: 'VOIDED',
  BOUNCED: 'BOUNCED',
};

export const SIGNATURE_STATUS_LIST = Object.keys(SIGNATURE_STATUS);
