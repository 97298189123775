import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import translator from '../../../common/translate';
import ErrorsArray from './ErrorsArray';
import '../css/tooltip.css';
import formatString from '../../../common/helpers/format/formatString';

class Tooltip extends PureComponent {
  tooltip = null;

  static propTypes = {
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    blueErrorWarning: PropTypes.bool,
    tooltipVerticalAlign: PropTypes.oneOf(['top', 'bottom', null]),
    setVerticalAlign: PropTypes.func,
    node: PropTypes.func,
    tooltipAlign: PropTypes.oneOf(['left', 'right']),
  };

  static defaultProps = {
    tooltipAlign: 'right',
  };

  componentDidMount() {
    if (this.props.tooltipVerticalAlign === null) {
      if (this.tooltip && this.props.node().button !== null) {
        const button = this.props.node().button.getBoundingClientRect();
        let tooltip = this.tooltip.getBoundingClientRect();
        let infoTooltipHeight = this.infoTooltip ? this.infoTooltip.getBoundingClientRect().height : 0;
        let windowHeight = window.innerHeight;

        if (windowHeight < button.bottom + tooltip.height + infoTooltipHeight) {
          this.props.setVerticalAlign('top');
        } else {
          this.props.setVerticalAlign('bottom');
        }
      }
    }
  }

  render() {
    const { errors, blueErrorWarning, tooltipVerticalAlign, infoTooltip, tooltipAlign } = this.props;

    const tooltipClassName = classNames({
      'badge-btn-errors-list': true,
      'list-array': Array.isArray(errors),
      'blue-error-warning': blueErrorWarning,
      'top-align': tooltipVerticalAlign === 'top',
      'calc-revers': tooltipVerticalAlign === null && !Array.isArray(errors),
      'right-align-tooltip': tooltipAlign === 'right',
      'left-align-tooltip': tooltipAlign === 'left',
    });

    let errorsTooltip = null;

    if (Array.isArray(errors)) {
      errorsTooltip = <ErrorsArray errors={errors} formatMessage={this.props.intl.formatMessage} />;
    } else if (typeof errors === 'string' && errors.trim() !== '') {
      errorsTooltip = (
        <span className="badge-btn-tooltip">
          <span>{formatString(errors, this.props.intl.formatMessage)}</span>
        </span>
      );
    }

    return (
      <div className={tooltipClassName} ref={el => (this.tooltip = el)}>
        {errorsTooltip}
        {infoTooltip && (
          <span className="badge-btn-tooltip info-tooltip" ref={el => (this.infoTooltip = el)}>
            {infoTooltip.split('@').map((el, i) => {
              if (el !== '') {
                return el.trim().substr(0, 4) === 'msg_' ? (
                  <span key={el}>{translator.getMessage(el.trim())}</span>
                ) : (
                  <span key={el}>{el}</span>
                );
              } else {
                return null;
              }
            })}
          </span>
        )}
      </div>
    );
  }
}

export default injectIntl(Tooltip);
