import EscapeRegExp from '../EscapeRegExp';

export default function TemplateStringReplace(
  templateString,
  props = {},
  wrapperSymbolStart = '%',
  wrapperSymbolEnd = wrapperSymbolStart
) {
  let output = templateString;
  const keys = Object.keys(props);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const value = props[key];
    const pattern = new RegExp(EscapeRegExp(wrapperSymbolStart + key + wrapperSymbolEnd), 'g');

    output = output.replace(pattern, value);
  }

  return output;
}
