import deepFreeze from '../common/helpers/objectDeepFreeze';
import config from '../config.js';
import { apiPropertiesAssign } from './apiPropertiesAssign';
import { API_PROPERTIES_MAPPING } from './apiPropertiesMapping';

// TODO: Implement API cache
// This is a temporary storage for long-live data
class ApiData {
  constructor() {
    this._version = '';
    this._lineItems = [];
    this._categories = [];
    this._partnerCategoryIds = [];
    this._countries = [];
    this._states = [];
    this._dealerships = [];
    this._industryTypes = [];
    this._apiSchemas = {};
    this._ictppsDetails = [];
    this._partnerCanSOBO = null;
    this._altDescriptionOptions = null;
    this._properties = API_PROPERTIES_MAPPING;
    this._announcement = {};
    this._brandNames = [];
  }

  get version() {
    return this._version;
  }

  set version(value) {
    this._version = value;
  }

  get lineItems() {
    return this._lineItems;
  }

  set lineItems(lineItems) {
    // Sort by DB sequence field
    lineItems.sort((a, b) => a.sequence - b.sequence);

    // With KM-XXXX back-end response JSON were refactored.
    // Duplicated data were removed to optimize response body size
    // Replicate that data once on UI side because UI code rely on it
    lineItems.forEach(lineItem => {
      lineItem.partnerAdd = apiData.partnerCategoryIds.includes(lineItem.lineItemCategoryId);

      for (let i = 0; i < apiData._categories.length; i++) {
        const category = apiData._categories[i];

        if (category.id !== lineItem.lineItemCategoryId) {
          continue;
        }

        for (let j = 0; j < category.subCategories.length; j++) {
          const subCategory = category.subCategories[j];

          if (subCategory.id !== lineItem.lineItemSubCategoryId) {
            continue;
          }

          lineItem.lineItemSubCategoryName = subCategory.name;
          lineItem.maxItemCountPerLocation = subCategory.maxItemCountPerLocation;
          break;
        }
      }
    });

    this._lineItems = deepFreeze(lineItems);
  }

  get categories() {
    return this._categories;
  }

  set categories(value) {
    this._partnerCategoryIds = [];

    for (let i = 0; i < value.length; i++) {
      const c = value[i];

      if (c.partnerAdd && this._partnerCategoryIds.includes(c.id) === false) {
        this._partnerCategoryIds.push(c.id);
      }
    }

    this._categories = deepFreeze(value);
  }

  get partnerCategoryIds() {
    return this._partnerCategoryIds;
  }

  get countries() {
    return this._countries;
  }

  set countries(value) {
    this._countries = deepFreeze(value);
  }

  get states() {
    return this._states;
  }

  set states(value) {
    this._states = deepFreeze(value);
  }

  get dealerships() {
    return this._dealerships;
  }

  set dealerships(value) {
    this._dealerships = deepFreeze(value);
  }

  get industryTypes() {
    return this._industryTypes;
  }

  set industryTypes(value) {
    this._industryTypes = deepFreeze(value);
  }

  get apiSchemas() {
    return this._apiSchemas;
  }

  set ictppsDetails(value) {
    this._ictppsDetails = deepFreeze(value);
  }

  get ictppsDetails() {
    return this._ictppsDetails;
  }

  set apiSchemas(apiMetaResponse) {
    this._apiSchemas = apiMetaResponse;
  }

  get partnerCanSOBO() {
    return this._partnerCanSOBO;
  }

  set partnerCanSOBO(value) {
    this._partnerCanSOBO = value;
  }

  get altDescriptionOptions() {
    return this._altDescriptionOptions;
  }

  set altDescriptionOptions(value) {
    this._altDescriptionOptions = value;
  }

  get properties() {
    return this._properties;
  }

  set properties(apiResponseObject) {
    this._properties = deepFreeze(apiPropertiesAssign(API_PROPERTIES_MAPPING, apiResponseObject));
  }

  /**
   * @returns {{id: number, body: string, headline: string}}
   */
  get announcement() {
    return this._announcement;
  }

  set announcement(apiData) {
    this._announcement = deepFreeze(apiData);
  }

  get brandNames() {
    return this._brandNames;
  }

  set brandNames(brandNames) {
    this._brandNames = deepFreeze(brandNames);
  }
}

const apiData = new ApiData();

if (config.showConsoleLog) {
  console.groupCollapsed('apiData (singleton, updates interactively)');
  console.log(apiData);
  console.groupEnd();
}

export default apiData;
