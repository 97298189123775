import { CategoryModel, ModelAbstract, QuotesCollectionModel } from '../index';
import { AddExistingButtonModel } from './index';

class AddButtonModel extends ModelAbstract {
  constructor(higherOrderInstance) {
    if (!(higherOrderInstance instanceof CategoryModel)) {
      throw Error();
    }

    super(higherOrderInstance);

    this._ignoreOnExport.push('masterOrder', 'appState');
    /** @type {CategoryModel} */
    this._category = this._sibling;

    this.id = 'li-category-add-line-item-button_' + this._uuid;
    this.handleClick = this.handleClick.bind(this);
  }

  /**
   * @returns {CustomerOrderModel|null}
   */
  get masterOrder() {
    /** @type {QuotesCollectionModel} */
    const quotesCollection = this.findSibling(instance => instance instanceof QuotesCollectionModel);

    return quotesCollection.masterOrder;
  }

  /**
   * @returns {AppStateModel|null}
   */
  get appState() {
    return this.controller.appState;
  }

  get disabled() {
    return this.appState.disabled || this.appState.locked;
  }

  get visible() {
    return (
      !this.appState.locked &&
      this._category.selectable &&
      !this._category.hideAddButtonAndTotals &&
      !this.appState.switchvoxWlwDisabled
    );
  }

  getDialogModel() {
    return this.masterOrder.addLineItemDialog;
  }

  getSelectedDevices() {
    const isExisting = this instanceof AddExistingButtonModel;

    // KM-6355: Partner line items should always be unchecked in Add Line Item dialog
    // So customer will have an option to add multiple items of same type
    return this.masterOrder.lineItems.items.filter(
      lineItem => lineItem.isUniqueByLineItemId && lineItem.existing === isExisting
    );
  }

  handleClick() {
    const addLineItemDialog = this.getDialogModel();

    addLineItemDialog.categoryId = this._category.id;
    addLineItemDialog.selectedDevices = this.getSelectedDevices();
    addLineItemDialog.opened = true;

    this.forceRender();
  }
}

export default AddButtonModel;
