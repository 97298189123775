import { ModelAbstract } from '../';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY_IN_MS = ONE_HOUR * 24;
const SEVEN_DAYS_IN_MS = ONE_DAY_IN_MS * 7;

class DateModel extends ModelAbstract {
  _locked = false;
  _dateIsSelected = false;
  _dateShippingRequested = null;
  _dateInstall = null;
  _minDateInstall = null;
  _timezone = 'America/New_York';

  constructor(parent) {
    super(parent);

    this.unset = this.unset.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  _getTimestamp(v) {
    return dayjs.utc(v).tz(this._timezone).valueOf();
  }

  onChange(value, prop) {
    if (this.canSet(prop)) {
      this[prop] = this._getTimestamp(value);

      this._dateIsSelected = true;
      this.modelChanged(this);
    }
  }

  get dateShippingRequested() {
    return this._dateShippingRequested;
  }

  set dateShippingRequested(value) {
    if (value !== null && value !== 0) {
      this._dateShippingRequested = value;

      if (this._minDateInstall < this._dateShippingRequested + SEVEN_DAYS_IN_MS) {
        this._dateInstall = value + SEVEN_DAYS_IN_MS;
      } else if (this._dateInstall === null) {
        this._dateInstall = this._minDateInstall;
      }

      this._minDateInstall = value + SEVEN_DAYS_IN_MS;
    } else {
      this._dateShippingRequested = null;
      this._dateInstall = null;
    }
  }

  get dateInstall() {
    return this._dateInstall;
  }

  set dateInstall(value) {
    if (value !== null && value !== 0) {
      if (this._dateShippingRequested === null) {
        this._dateInstall = value;
        this._dateShippingRequested = value - SEVEN_DAYS_IN_MS;
        this._minDateInstall = value;
      } else if (value > this._dateShippingRequested - SEVEN_DAYS_IN_MS) {
        this._dateInstall = value;
      }
    } else {
      this._dateInstall = null;
    }
  }

  get dateSelected() {
    return this._dateIsSelected;
  }

  get locked() {
    return this._locked;
  }

  set locked(value) {
    this._locked = value;
  }

  get minDateInstall() {
    if (this._dateShippingRequested) {
      this._minDateInstall = this._dateShippingRequested + SEVEN_DAYS_IN_MS;

      return this._minDateInstall;
    } else {
      this._minDateInstall = new Date().getTime() + ONE_DAY_IN_MS + SEVEN_DAYS_IN_MS;

      return this._minDateInstall;
    }
  }

  get minDateShip() {
    return new Date().getTime() + ONE_DAY_IN_MS;
  }

  get timezone() {
    return this._timezone;
  }

  set timezone(v) {
    this._timezone = v;
  }

  unset() {
    this._dateInstall = null;
    this._dateShippingRequested = null;
    this._dateIsSelected = false;

    this.modelChanged(this);
  }
}

export default DateModel;
