import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.css';

class CollapsableCard extends PureComponent {
  static propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    toggleCollapse: PropTypes.func,
    collapsedSections: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.string,
    className: PropTypes.string,
    contentWrapperClassName: PropTypes.string,
    cardContentClassName: PropTypes.string,
    copyContacts: PropTypes.func,
    grandTotal: PropTypes.string,
  };

  static defaultProps = {
    cardContentClassName: '',
  };

  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
  }

  render() {
    const { id, title, className, collapsedSections, contentWrapperClassName, grandTotal, intl } = this.props;

    const isCollapsed = collapsedSections.includes(id);

    const cardBlockClassName = classNames({
      'card-block': true,
      'collapse-block': isCollapsed,
    });
    const cardContentClassName = 'card-content ' + this.props.cardContentClassName;
    const collapseButtonAriaLabel = [isCollapsed ? 'Expand' : 'Collapse'];
    const formattedTitle = intl.formatMessage({ id: title });

    if (typeof title === 'string') {
      collapseButtonAriaLabel.push(formattedTitle);
    }

    if (typeof title === 'object' && title.props && title.props.id) {
      const sectionTitleStr = intl.formatMessage({ id: title.props.id });

      if (sectionTitleStr) {
        collapseButtonAriaLabel.push(sectionTitleStr);
      }
    }

    collapseButtonAriaLabel.push('section');

    return (
      <section className={className} id={id}>
        <div className={contentWrapperClassName}>
          <div className={cardBlockClassName}>
            <div className="card-header location-content-header">
              <div className="card-header-title" onClick={this.handleOnClick}>
                <button
                  className="card-block-expand-btn square large"
                  tabIndex="0"
                  aria-label={collapseButtonAriaLabel.join(' ')}
                >
                  <span className="icon-expand-less" />
                </button>
                {typeof title === 'string' ? formattedTitle : title}
              </div>
              {grandTotal ? (
                <div title={grandTotal} id={`grand-total-${id}`} className="grand-total-block">
                  <span className="grand-total-value">
                    {`${intl.formatMessage({
                      id: 'msg_grand_total',
                    })} ${grandTotal}`}
                  </span>
                </div>
              ) : null}
            </div>
            <div className={cardContentClassName}>{this.props.children}</div>
          </div>
        </div>
      </section>
    );
  }

  handleOnClick() {
    if (typeof this.props.toggleCollapse === 'function') {
      this.props.toggleCollapse(this.props.id);
    }
  }
}

export default injectIntl(CollapsableCard);
