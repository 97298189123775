import React, { Component } from 'react';
import translator from '../../common/translate';
import bindMultiple from '../../common/helpers/bindMultiple';
import { array, func } from 'prop-types';
import { CheckboxControl, TextControl } from '../Controls';
import Modal from 'react-modal';
import classNames from 'classnames';
import BadgeButton from '../BadgeButton';
import './css/add-locations-modal.css';

const TableRow = ({ selectedLocationIds, existingLocationIds, name, id, setSelectedLocationIds }) => {
  const isExistingLocation = existingLocationIds.includes(id);
  const checked = selectedLocationIds.includes(id) || isExistingLocation;
  const rowClassName = classNames({
    checked,
    existing: isExistingLocation,
  });
  let selectedLocationIdsRef = React.useRef(selectedLocationIds);

  const handleChange = React.useCallback(() => {
    if (isExistingLocation) {
      return false;
    }

    if (!checked) {
      selectedLocationIdsRef.current.push(id);
    } else {
      selectedLocationIdsRef.current = selectedLocationIdsRef.current.filter(loc => loc !== id);
    }

    setSelectedLocationIds(selectedLocationIdsRef.current);
  }, [checked, id, isExistingLocation, setSelectedLocationIds]);

  return (
    <tr className={rowClassName} key={'select-location-row-' + id}>
      <td>
        <CheckboxControl
          id={'select-location-' + id}
          parentClassName="small"
          onChange={handleChange}
          value={checked}
          disabledState={isExistingLocation}
        />
      </td>
      <td>
        <span onClick={handleChange}>{name}</span>
      </td>
    </tr>
  );
};

class AddLocationsModal extends Component {
  static propTypes = {
    locations: array,
    existingLocationIds: array,
    addLocations: func,
    closeDialog: func,
  };

  constructor(props) {
    super(props);

    this.state = {
      locations: props.locations,
      selectedLocationIds: [],
      filterString: '',
    };

    bindMultiple(
      this,
      this.handleAdd,
      this.handleClose,
      this.handleSelectAll,
      this.onChangeFilter,
      this.onFilterClear,
      this.setSelectedLocationIds
    );
  }

  handleAdd() {
    if (typeof this.props.addLocations === 'function') {
      this.props.addLocations(this.state.selectedLocationIds);
    }
  }

  handleClose() {
    if (typeof this.props.closeDialog === 'function') {
      this.props.closeDialog();
    }
  }

  handleSelectAll(val) {
    let selectedLocationIds = [];

    if (val) {
      selectedLocationIds = this.props.locations.reduce((res, val) => {
        if (!this.props.existingLocationIds.includes(val.id)) {
          res.push(val.id);
        }

        return res;
      }, []);
    }

    this.setState({ selectedLocationIds });
  }

  onChangeFilter(filterString) {
    let { locations } = this.props;
    const isFilterNeeded = filterString !== '';

    if (isFilterNeeded) {
      locations = locations.filter(l => l.name.toLowerCase().trim().includes(filterString.toLowerCase().trim()));
    }

    this.setState({
      locations,
      filterString,
    });
  }

  onFilterClear() {
    this.setState({
      locations: this.props.locations,
      filterString: '',
    });
  }

  setSelectedLocationIds(selectedLocationIds) {
    this.setState({ selectedLocationIds });
  }

  render() {
    const { selectedLocationIds, filterString, locations } = this.state;
    const addButtonDisabled = selectedLocationIds.length === 0;
    const selectedLocationsCount = selectedLocationIds.length + this.props.existingLocationIds.length;

    return (
      <Modal
        overlayClassName="default-overlay-modal"
        className="default-confirm-modal add-locations-modal"
        bodyOpenClassName="body-overflow-hidden"
        ariaHideApp={false}
        onRequestClose={this.handleClose}
        isOpen
      >
        <div className="default-modal-label">
          <p className="light-bold">{translator.getMessage('msg_chose_locations')}</p>
        </div>
        <div className="filter-location">
          <TextControl
            ariaLabel="Locations filter"
            className="filter-input"
            id="add-locations-table-filter"
            placeholder="msg_search_for_location"
            onChange={this.onChangeFilter}
            clearFieldHandler={this.onFilterClear}
            value={filterString}
            iconClassName="icon-search"
            resultsCount={locations.length}
            showResultCnt={filterString !== ''}
          />
        </div>
        <div className="add-locations-table-wrapper">
          <table className="rq-table sticky add-locations-table" key="add-locations-table">
            <thead>
              <tr>
                <th>
                  <CheckboxControl
                    id="add-locations-select-all"
                    parentClassName="small"
                    onChange={this.handleSelectAll}
                    value={locations.length && locations.length === selectedLocationsCount}
                  />
                </th>
                <th>{translator.getMessage('msg_location_name')}</th>
              </tr>
            </thead>
            <tbody>
              {locations.map(loc => (
                <TableRow
                  selectedLocationIds={this.state.selectedLocationIds}
                  setSelectedLocationIds={this.setSelectedLocationIds}
                  existingLocationIds={this.props.existingLocationIds}
                  name={loc.name}
                  id={loc.id}
                  key={loc.id}
                />
              ))}
            </tbody>
          </table>
          {Boolean(filterString) && locations.length === 0 && (
            <div className="no-results">
              <span>{translator.getMessage('msg_no_results_found')}</span>
            </div>
          )}
        </div>
        <div className="default-confirm-modal-actions">
          <button
            id="add-locations-cancel-button"
            key="add-locations-cancel-button"
            className="add-locations-cancel-button text-btn"
            onClick={this.handleClose}
          >
            {translator.getMessage('msg_cancel')}
          </button>
          <BadgeButton
            id="add-locations-add-button"
            key="add-locations-add-button"
            className="add-locations-add-button"
            onClick={this.handleAdd}
            disabled={addButtonDisabled}
            errors={addButtonDisabled ? 'msg_at_least_location_should_be_selected' : ''}
            label={translator.getMessage('msg_add')}
            tooltipVerticalAlign={'top'}
            hideInfoBadge
            blueErrorWarning
          />
        </div>
      </Modal>
    );
  }
}

export default AddLocationsModal;
