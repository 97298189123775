import { QUOTE_STATUS_OPTIONS } from './quoteStatusEnums';
import { ORDER_TYPE_DROPDOWN_OPTIONS } from './orderTypes.enums';

export const FILTERABLE_COLUMNS = {
  STATUS: 'STATUS',
  ORDER_TYPE: 'ORDER_TYPE',
  QUOTE_TYPE: 'QUOTE_TYPE',
};

export const FILTERABLE_COLUMNS_LIST = Object.keys(FILTERABLE_COLUMNS);

export const COLUMN_FILTERS = {
  [FILTERABLE_COLUMNS.STATUS]: {
    label: 'msg_status',
    type: FILTERABLE_COLUMNS.STATUS,
    control: 'multiSelectMinimal',
    options: QUOTE_STATUS_OPTIONS,
  },
  [FILTERABLE_COLUMNS.ORDER_TYPE]: {
    label: 'msg_order_type',
    type: FILTERABLE_COLUMNS.ORDER_TYPE,
    control: 'multiSelectMinimal',
    options: ORDER_TYPE_DROPDOWN_OPTIONS,
  },
  [FILTERABLE_COLUMNS.QUOTE_TYPE]: {
    label: 'msg_quote_type',
    type: FILTERABLE_COLUMNS.QUOTE_TYPE,
    control: 'multiSelectMinimal',
    options: [],
  },
};
