import { finalizeBtnTooltipText } from '../common/check-required-for-finalize';
import { checkRequiredFieldsForPrint } from '../common/check-required-for-print';
import ModelAbstract from './ModelAbstract';

class ToolbarModel extends ModelAbstract {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    this._ignoreOnExport.push('appState', 'masterOrder', 'calcResults');

    this.onChange = this.onChange.bind(this);
  }

  /**
   * @return {AppStateModel}
   */
  get appState() {
    return this.controller.appState;
  }

  /**
   * @return {CustomerOrderModel}
   */
  get masterOrder() {
    return this.appState.masterOrder;
  }

  /**
   * @return {CalcResultsModel}
   */
  get calcResults() {
    return this.masterOrder.calcResults;
  }

  get invalidFieldsCnt() {
    return this.appState.errors.invalidCodes.length;
  }

  get requiredPartnerPrintFields() {
    return checkRequiredFieldsForPrint(this.masterOrder, this.appState.errors.salesExceptionsRules);
  }

  get printPartnerBtnDisabled() {
    return Boolean(this.requiredPartnerPrintFields.fields.length || !this.appState.isPrintAllowed);
  }

  get printPartnerBtnErrors() {
    if (
      !this.appState.isSaved ||
      (this.requiredPartnerPrintFields &&
        (this.requiredPartnerPrintFields.fields.length || this.requiredPartnerPrintFields.seRulesMessage !== '')) ||
      this.appState.isSaveAllowed
    ) {
      return finalizeBtnTooltipText(this.requiredPartnerPrintFields);
    } else {
      return '';
    }
  }

  onChange(prop, value) {
    if (this.canSet(prop)) {
      this[prop] = value;
    }

    this.modelChanged(this);
  }

  get packageIdSelected() {
    return this.masterOrder.packageIdSelected;
  }
}

export default ToolbarModel;
