import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToggleControl } from '../Controls';

class PciCompliant extends Component {
  constructor() {
    super();
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  static propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabledState: PropTypes.bool,
    lockedState: PropTypes.bool,
  };

  handleOnChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange('pciCompliant', value);
    }
  }

  render() {
    return (
      <div className="location-pci-compliant">
        <ToggleControl {...this.props} onChange={this.handleOnChange} label="msg_pci_compliant" />
      </div>
    );
  }
}

export default PciCompliant;
