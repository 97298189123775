import React from 'react';
import { string, bool, func, number } from 'prop-types';
import { UpperCaseEachWord } from '../../../../common/helpers/format';

const StatusCell = ({
  displayReminderIcon = false,
  sendReminderToSigner,
  signatureId = 0,
  signerId = 0,
  status = '-',
}) => {
  const onClick = () => {
    if (typeof sendReminderToSigner === 'function') {
      sendReminderToSigner(signatureId, signerId);
    }
  };

  return (
    <span className="signer-status">
      <span>{UpperCaseEachWord(status)}</span>
      {displayReminderIcon && <button onClick={onClick} className="icon icon-notification text-btn small no-border" />}
    </span>
  );
};

StatusCell.propTypes = {
  displayReminderIcon: bool,
  sendReminderToSigner: func,
  signatureId: number,
  signerId: number,
  status: string,
};

export default StatusCell;
