import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import config from '../config.js';

/**
 * @param context {Object}
 * @param model {ContextModel}
 * @return {Object}
 * @constructor
 */
const ContextConnect = (context, model) => {
  return class ContextProvider extends PureComponent {
    static propTypes = {
      children: PropTypes.any,
    };

    _model = null;

    constructor(...args) {
      super(...args);

      this._model = model;

      this.state = {
        value: this._model.toJS(),
      };

      this._model.linkContext(this);

      if (config.showConsoleLog) {
        console.groupCollapsed('ContextProvider::' + this._model.className);
        console.log(this._model);
        console.groupEnd();
      }
    }

    componentDidMount() {
      this._model.providerMounted = true;
    }

    componentWillUnmount() {
      this._model.providerMounted = false;
    }

    render() {
      const Provider = context.Provider;

      return <Provider value={this.state.value}>{this.props.children}</Provider>;
    }
  };
};

export default ContextConnect;
