import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NumberControl, LabelField } from '../Controls';

class NumLines extends Component {
  static propTypes = {
    tooltipText: PropTypes.string,
    errorText: PropTypes.string,
    editable: PropTypes.bool,
    onChange: PropTypes.func,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    value: PropTypes.number,
    id: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = {
    editable: false,
    lockedState: false,
    disabledState: false,
    id: 'NumLines',
    label: 'msg_number_of_lines',
  };

  render() {
    let value = this.props.value;

    if (!this.props.editable) {
      value = value.toLocaleString('en-US');
    }

    return this.props.editable ? (
      <NumberControl
        id={'num-lines-input-' + this.props.id}
        label={this.props.label}
        value={value}
        lockedState={this.props.lockedState}
        disabledState={this.props.disabledState}
        onChange={this.props.onChange}
        errorText={this.props.errorText}
        tooltipText={this.props.tooltipText}
        isShowArrow={true}
        parentClassName="short-input"
      />
    ) : (
      <LabelField id={'num-lines-label-' + this.props.id} label={this.props.label} value={value} textRight={true} />
    );
  }
}

export default NumLines;
