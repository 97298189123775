export default function deepFreeze(variable) {
  // Use try catch to prevent throwing exception in strict mode
  try {
    Object.freeze(variable);
  } catch {
    return variable;
  }

  if (typeof variable !== 'object' || variable === null) {
    return variable;
  }

  // Deep Freeze each element of the array
  if (Array.isArray(variable)) {
    variable.forEach(d => deepFreeze(d));
    return variable;
  }

  Object.getOwnPropertyNames(variable).forEach(d => deepFreeze(d));

  return variable;
}
