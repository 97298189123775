import { CustomerOrderModel, ModelAbstract } from '../index';
import bindMultiple from '../../common/helpers/bindMultiple';

class RemoveDialogModel extends ModelAbstract {
  constructor(customerOrderInstance) {
    if (!(customerOrderInstance instanceof CustomerOrderModel)) {
      throw new Error('LineItems/RemoveDialogModel only accept CustomerOrderModel as higher order instance');
    }

    super(customerOrderInstance);

    this.isOpen = false;
    this.allocated = 0;
    this.name = '';
    this.lineItemUuid = null;

    this._ignoreOnExport.push('masterOrder');

    bindMultiple(this, this.onYes, this.onCancel);
  }

  /**
   * @returns {CustomerOrderModel}
   */
  get masterOrder() {
    return this._sibling;
  }

  _closeModal(doRender = true) {
    this.isOpen = false;
    this.name = '';
    this.allocated = 0;
    this.lineItemUuid = null;

    if (doRender) {
      this.forceRender();
    }
  }

  onYes() {
    this.masterOrder.lineItems.remove(this.lineItemUuid);
    this._closeModal(false);
    this.modelChanged(this);
  }

  onCancel() {
    this._closeModal();
  }
}

export default RemoveDialogModel;
