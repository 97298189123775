import React from 'react';
import propTypes from 'prop-types';
import bindMultiple from '../../../common/helpers/bindMultiple';
import translator from '../../../common/translate';
import CardContent from './nested/CardContent';
import PartnersContext from './nested/PartnersContext';
import './order.initiation.style.css';
import AppRouter from '../../../AppRouter/AppRouter';
import { OrderInitiationController } from '../../../Controllers';

class OrderInitiation extends React.Component {
  static propTypes = {
    partnersFetching: propTypes.bool,
    customersFetching: propTypes.bool,
    locationsFetching: propTypes.bool,
    isDataFetching: propTypes.bool,
    doInitialInputFocus: propTypes.bool,

    isLabelPartnerField: propTypes.bool,
    isLabelOrderTypeField: propTypes.bool,
    isLabelCustomerField: propTypes.bool,
    isLabelLocationField: propTypes.bool,

    onChangeCustomer: propTypes.func,
    onChangePartner: propTypes.func,
    onOrderPropChange: propTypes.func,
    onStartQuoteButtonClick: propTypes.func,
    onChangeOrderType: propTypes.func,
    onChangeLocationIds: propTypes.func,
  };

  static defaultProps = {
    partnersFetching: false,
    customersFetching: false,
    locationsFetching: false,
    isDataFetching: false,
    doInitialInputFocus: false,
    isLabelPartnerField: false,
    isLabelOrderTypeField: false,
    isLabelCustomerField: false,
    isLabelLocationField: false,
  };

  constructor(props, context) {
    super(props, context);

    bindMultiple(
      this,
      this.handleChangeCustomer,
      this.handleChangePartner,
      this.handleOrderPropChange,
      this.handleStartQuoteButtonClick,
      this.handleChangeOrderType,
      this.handleChangeLocationIds
    );
  }

  handleChange(func, value) {
    if (typeof func === 'function') {
      func(value);
    }
  }

  onCancelButtonClick() {
    AppRouter.push('/landing');
  }

  handleChangePartner(v) {
    this.handleChange(this.props.onChangePartner, v);
  }

  handleChangeOrderType(v) {
    this.handleChange(this.props.onChangeOrderType, v);
  }

  handleChangeCustomer(v) {
    this.handleChange(this.props.onChangeCustomer, v);
  }

  handleOrderPropChange(prop, value) {
    if (typeof this.props.onOrderPropChange === 'function') {
      this.props.onOrderPropChange(prop, value);
    }
  }

  handleChangeLocationIds(v) {
    this.handleChange(this.props.onChangeLocationIds, v);
  }

  handleStartQuoteButtonClick() {
    if (typeof this.props.onStartQuoteButtonClick === 'function') {
      this.props.onStartQuoteButtonClick();
    }
  }

  render() {
    const {
      customersFetching,
      locationsFetching,
      order,
      partnersFetching,
      isDataFetching,
      doInitialInputFocus,
      isLabelPartnerField,
      isLabelOrderTypeField,
      isLabelCustomerField,
      isLabelLocationField,
    } = this.props;
    const {
      actionOptionsLocked,
      actions,
      customerDisabled,
      customers,
      invalidFieldsCnt,
      locationDisabled,
      locations,
      partners,
      selectedAction,
      selectedCustomerId,
      selectedLocationId,
      selectedPartnerId,
      startBtnDisabled,
      startErrorsText,
      partnerLocked,
      selectedLocationIds,
      partnerInfoLabel,
      isCustomerRequired,
      isLocationRequired,
      serviceType,
      onChangeServiceType,
      serviceTypeOptions,
      serviceTypeDisabled,
      industryTypes,
      onChangeIndustryTypeId,
      selectedIndustryTypeId,
      quoteTitle,
    } = order;

    const selectedPartnerLabel = partners[0] ? partners[0].dealerName : '';
    const isRewriteSelected = selectedAction === OrderInitiationController.quoteTypeIndexes['rewrite'];

    const partnersContext = {
      partners,
      partnersFetching,
      selectedPartnerId,
      selectedPartnerLabel,
    };

    return (
      <section className="card-section order-initiation-wrapper">
        <section className="order-initiation">
          <div className="quote-card-container">
            <div className="card-block">
              <div className="card-header">
                <div className="card-header-title">{translator.getMessage('msg_create_quote')}</div>
              </div>
              <PartnersContext.Provider value={partnersContext}>
                <CardContent
                  actionOptionsLocked={actionOptionsLocked}
                  actions={actions}
                  customerDisabled={customerDisabled}
                  customers={customers}
                  customersFetching={customersFetching}
                  locationDisabled={locationDisabled}
                  locations={locations}
                  locationsFetching={locationsFetching}
                  onChangePartner={this.handleChangePartner}
                  onChangeOrderType={this.handleChangeOrderType}
                  onChangeCustomer={this.handleChangeCustomer}
                  onPropChange={this.handleOrderPropChange}
                  selectedAction={selectedAction}
                  selectedCustomerId={selectedCustomerId}
                  selectedLocationId={selectedLocationId}
                  partnerLocked={partnerLocked}
                  onCancelButtonClick={this.onCancelButtonClick}
                  onStartQuoteButtonClick={this.handleStartQuoteButtonClick}
                  startBtnDisabled={startBtnDisabled}
                  startErrorsText={startErrorsText}
                  invalidFieldsCnt={invalidFieldsCnt}
                  isRewriteSelected={isRewriteSelected}
                  selectedLocationIds={selectedLocationIds}
                  onChangeLocationIds={this.handleChangeLocationIds}
                  partnerInfoLabel={partnerInfoLabel}
                  serviceType={serviceType}
                  onChangeServiceType={onChangeServiceType}
                  serviceTypeOptions={serviceTypeOptions}
                  serviceTypeDisabled={serviceTypeDisabled}
                  isCustomerRequired={isCustomerRequired}
                  isLocationRequired={isLocationRequired}
                  industryTypes={industryTypes}
                  onChangeIndustryTypeId={onChangeIndustryTypeId}
                  selectedIndustryTypeId={selectedIndustryTypeId}
                  partnersFetching={partnersFetching}
                  isDataFetching={isDataFetching}
                  doInitialInputFocus={doInitialInputFocus}
                  isLabelPartnerField={isLabelPartnerField}
                  isLabelOrderTypeField={isLabelOrderTypeField}
                  isLabelCustomerField={isLabelCustomerField}
                  isLabelLocationField={isLabelLocationField}
                  quoteTitle={quoteTitle}
                />
              </PartnersContext.Provider>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default OrderInitiation;
