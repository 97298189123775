import React from 'react';
import ReassignmentsTooltip from './ReassignmentsTooltip';

const ReassignmentsCell = ({ reassignments }) => {
  const reassignmentsCount = reassignments && reassignments.length;

  return (
    <div className="signer-reassignments">
      {reassignmentsCount ? (
        <div>
          <span className="reassignments-count">{reassignmentsCount}</span>
          <span className="icon icon-info" />
          <ReassignmentsTooltip reassignments={reassignments.sort((a, b) => b.creationDate - a.creationDate)} />
        </div>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

export default ReassignmentsCell;
