import React, { PureComponent } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as UUID } from 'uuid';
import AbstractControl from '../AbstractControl';
import AutocompleteValue from '../../../common/helpers/AutocompleteValue';

class MaskControl extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    changeOnBlur: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
  };

  static defaultProps = {
    value: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.inputAutocompleteValue = AutocompleteValue();
    this.inputNameValue = 'input-' + UUID();

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
    this.beforeMaskedValueChange = this.beforeMaskedValueChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.changeOnBlur && nextProps.value !== this.state.value) {
      this.setState({ value: nextProps.value });
    }
  }

  render() {
    const {
      // Props for current component
      id,
      mask,
      lockedState,
      disabledState,
      className,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      parentClassName,
      changeOnBlur,
    } = this.props;

    const componentClassName = classNames('mask-input-component', className);

    const inputAbstractProps = {
      hint,
      style,
      focus,
      label,
      lockedState,
      disabledState,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      parentClassName,
      className: componentClassName,
      labelFor: id,
    };

    const value = changeOnBlur ? this.state.value : this.props.value;

    return (
      <AbstractControl {...inputAbstractProps}>
        <InputMask
          autoComplete={this.inputAutocompleteValue}
          name={this.inputNameValue}
          id={id}
          type="tel"
          mask={mask}
          maskChar={null}
          disabled={lockedState || disabledState}
          value={value || ''}
          onBlur={this.handleOnBlur}
          onChange={this.handleOnChange}
          beforeMaskedValueChange={this.beforeMaskedValueChange}
        />
      </AbstractControl>
    );
  }

  beforeMaskedValueChange(newState) {
    let { value, selection } = newState;
    let cursorPosition = selection ? selection.start : null;

    if (value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = {
          start: cursorPosition,
          end: cursorPosition,
        };
      }

      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  }

  handleOnChange(event) {
    let value = event.target.value.replace(/-/g, '');

    if (this.props.changeOnBlur) {
      this.setState({ value: value });
    } else {
      this.props.onChange(value);
    }
  }

  handleOnBlur() {
    let value = this.props.changeOnBlur ? this.state.value : this.props.value;

    if (typeof value === 'string') {
      value = value.replace(/-/g, '');
    }

    this.props.onChange(value);
  }
}

export default MaskControl;
