import React from 'react';
import ErrorHandler from '../../components/ErrorHandlerComponent';
import { AppStateContext, AuthContext, ErrorContext } from '../../Context';
import AboutComponent from '../../components/AboutModal';
import Login from '../../components/Login';
import Header from './Header';
import Footer from './Footer';
import Sidebar from './Sidebar';
import VersionsMismatchModal from '../../components/VersionsMismatchModal';
import { KEY_CODES } from '../../common/enums';
import bindMultiple from '../../common/helpers/bindMultiple';

export default class Frame extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      drawerOpened: false,
      aboutOpened: false,
    };

    bindMultiple(this, this.toggleAbout, this.toggleDrawer, this.handleSidebarEscPress);
  }

  toggleDrawer(drawerOpened) {
    this.setState({ drawerOpened });
  }

  toggleAbout(aboutOpened) {
    this.setState({ aboutOpened });
  }

  handleSidebarEscPress(ev) {
    if (ev.code === KEY_CODES.Escape) {
      ev.stopPropagation();
      return this.toggleDrawer(false);
    }
  }

  render() {
    const { hasInternalPermissions, isAgent } = AuthContext.model;

    return (
      <section>
        <AuthContext.Consumer>
          {auth =>
            auth.showLoginModal ? (
              <Login open={auth.showLoginModal} email={auth.email} doLogin={this.props.controller.doLogin} />
            ) : null
          }
        </AuthContext.Consumer>
        <AuthContext.Consumer>
          {user => (
            <Header
              toggleDrawer={this.toggleDrawer}
              firstName={user.firstName}
              lastName={user.lastName}
              signOut={this.props.controller.signOutApp}
              switchLanguage={this.props.controller.switchLanguage}
              handleSidebarEscPress={this.handleSidebarEscPress}
            />
          )}
        </AuthContext.Consumer>
        {this.props.children}
        <Footer />
        <Sidebar
          drawerOpened={this.state.drawerOpened}
          toggleDrawer={this.toggleDrawer}
          toggleAbout={this.toggleAbout}
          signOut={this.props.controller.signOutApp}
          exit={this.props.controller.exitApp}
          handleSidebarEscPress={this.handleSidebarEscPress}
        />
        <ErrorContext.Consumer>{errorProps => <ErrorHandler {...errorProps} />}</ErrorContext.Consumer>
        <AppStateContext.Consumer>
          {({ versionsMismatch }) => <VersionsMismatchModal isShown={versionsMismatch} />}
        </AppStateContext.Consumer>
        {this.state.aboutOpened ? (
          <AboutComponent internalUser={hasInternalPermissions} isAgent={isAgent} toggleAbout={this.toggleAbout} />
        ) : null}
      </section>
    );
  }
}
