import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const VALID_STEPS = [
  'day',
  'd',
  'week',
  'w',
  'month',
  'M',
  'year',
  'y',
  'hour',
  'h',
  'minute',
  'm',
  'second',
  's',
  'millisecond',
  'ms',
];

export function dayjsRange(start, end, step = 'd', stepIncrementBy = 1) {
  if (!dayjs.isDayjs(start) && typeof start !== 'number') {
    throw new Error('dayjsRange: Invalid "start" parameter format. Should be dayjs object of UTC timestamp');
  }

  if (!dayjs.isDayjs(end) && typeof end !== 'number') {
    throw new Error('dayjsRange: Invalid "end" parameter format. Should be dayjs object of UTC timestamp');
  }

  if (!VALID_STEPS.includes(step)) {
    throw new Error('dayjsRange: Invalid "step" parameter');
  }

  if (!dayjs.isDayjs(start)) {
    start = dayjs.clone().utc(start);
  }

  if (!dayjs.isDayjs(end)) {
    end = dayjs.clone().utc(end);
  }

  const range = [];
  let current = start.clone();

  while (current.isBefore(end)) {
    range.push(current.clone());
    current = current.add(stepIncrementBy, step);
  }

  return range;
}
