import React from 'react';
import { ContextConnect, ContextModel } from './';
import { ERROR_CONTEXT_TYPES } from '../common/enums';

const context = React.createContext();

const DEFAULTS = {
  id: null,
  message: '',
  details: '',
  isShown: false,
  autoHideDelay: 8000,
  onClose: null,
  showCloseButton: true,
  type: ERROR_CONTEXT_TYPES.ERROR,
  msgTemplateStringVariables: {},
  priority: 10,
};

class ErrorContextModel extends ContextModel {
  constructor() {
    super();

    this._isShown = DEFAULTS.isShown;

    this.id = DEFAULTS.id;
    this.message = DEFAULTS.message;
    this.details = DEFAULTS.details;
    this.autoHideDelay = DEFAULTS.autoHideDelay;
    this.onClose = DEFAULTS.onClose;
    this.showCloseButton = DEFAULTS.showCloseButton;
    this.priority = DEFAULTS.priority;
    this.type = DEFAULTS.type;
    this.msgTemplateStringVariables = DEFAULTS.msgTemplateStringVariables;

    this._onDialogClose = this._onDialogClose.bind(this);
  }

  setProps(props) {
    const priority = typeof props.priority === 'number' ? props.priority : DEFAULTS.priority;

    if (priority < this.priority && props.isShown && this.isShown) {
      return null;
    }

    if (!props.isShown && props.id && props.id !== this.id) {
      return null;
    }

    this.reset();
    return super.setProps(props);
  }

  reset() {
    for (let name in DEFAULTS) {
      if (!DEFAULTS.hasOwnProperty(name)) {
        continue;
      }

      this[name] = DEFAULTS[name];
    }
  }

  get isShown() {
    return this._isShown;
  }

  set isShown(value) {
    this._isShown = Boolean(value);

    // Reset priority to default value on banner close
    if (!this._isShown) {
      this.priority = DEFAULTS.priority;
    }
  }

  _onDialogClose() {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }

    this.reset();
    this.broadcast(true);
  }

  get onDialogClose() {
    return this._onDialogClose;
  }
}

const model = new ErrorContextModel();
const Provider = ContextConnect(context, model);
const Consumer = context.Consumer;

const ErrorContext = {
  Provider,
  Consumer,
  model,
};

export default ErrorContext;
