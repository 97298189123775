import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import * as formatter from '../../../common/helpers/format';
import translator from '../../../common/translate';
import { CELL_TYPES } from '../../LineItems/enums';
import { AmountControl, SelectControl } from '../../Controls';
import PureViewComponent from '../../PureViewComponent';
import '../css/provider.css';

const translatorMap = {
  1010: 'msg_installation',
  1020: 'msg_configuration',
  1030: 'msg_training',
  1040: 'msg_porting_management',
  1050: 'msg_provisioning',
  1060: 'msg_shipping',
  1070: 'msg_advanced_services_fee',
};

const SELECTABLE_PARTY_SUBCATEGORY_IDS = [1010, 1020, 1030, 1040];

class Provider extends PureViewComponent {
  static propTypes = {
    label: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    subCategoryId: PropTypes.number,
    selectedOption: PropTypes.object,
    selectOption: PropTypes.func,
    onChange: PropTypes.func,
    showOverrideColumn: PropTypes.bool,
    mixed: PropTypes.bool,
    mixedDealerNet: PropTypes.object,
    isLocationQuote: PropTypes.bool,
    salesOps: PropTypes.bool,
  };

  static defaultProps = {
    label: '',
    options: [],
    lockedState: false,
    disabledState: false,
    selectedOption: {},
    selectOption: () => {},
    onChange: () => {},
    showOverrideColumn: false,
    mixed: false,
  };

  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleDealerNetChange = this.handleDealerNetChange.bind(this);
    this.handleOverrideValueChange = this.handleOverrideValueChange.bind(this);
  }

  getSelectOptions(props) {
    let menuItems = [];

    props.options.forEach(opt => {
      if ((props.isAgent && opt.hasFlag('allowedForAgentIctppsItem')) || !props.isAgent) {
        menuItems.push({ value: opt.lineItemId, text: opt.providerName });
      }
    });

    return menuItems;
  }

  getPriceDisplay(alias, props, onChangeHandler) {
    const ictpLabelValue = formatter.currency(props.selectedOption[alias]);

    if (props.mixed) {
      let value = 0;

      if (alias !== 'overrideValue') {
        value = props.mixedDealerNet[props.selectedOption.lineItemSubCategoryId];
      }

      return <span>{formatter.currency(value)}</span>;
    } else if (props.selectedOption.cellTypes) {
      switch (props.selectedOption.cellTypes[alias]) {
        case CELL_TYPES.amount:
          return (
            <AmountControl
              id={props.uuid + '-price'}
              lockedState={props.lockedState}
              disabledState={props.disabledState}
              value={props.selectedOption[alias]}
              style={{ marginBottom: 0 }}
              onChange={onChangeHandler}
              allowBlankField={true}
              ariaLabel={props.selectedOption.lineItemName + ' ' + (alias === 'overrideValue' ? 'Override' : 'Price')}
            />
          );
        case CELL_TYPES.label:
          return (
            <Fragment>
              <span className="ictp-price-label-name">{translator.getMessage('msg_price')}</span>
              <span>{ictpLabelValue}</span>
            </Fragment>
          );
        case CELL_TYPES.empty:
        default:
      }
    }
  }

  getPartnerServicesEstimates(props) {
    const starOption = props.options.find(el => el.isStar);

    if (SELECTABLE_PARTY_SUBCATEGORY_IDS.includes(props.subCategoryId) && starOption) {
      return starOption.dealerNet;
    }

    return false;
  }

  handleOnChange(value) {
    if (typeof this.props.setMixed === 'function') {
      this.props.setMixed();
    }
    this.props.selectOption(value);
  }

  handleDealerNetChange(value) {
    this.props.onChange('dealerNet', value);
  }

  handleOverrideValueChange(value) {
    this.props.onChange('overrideValue', value);
  }

  render() {
    const {
      label,
      lockedState,
      disabledState,
      subCategoryId,
      selectedOption,
      showOverrideColumn,
      isLocationQuote,
      salesOps,
      intl,
    } = this.props;

    const menuItems = this.getSelectOptions(this.props);
    const dealerNet = this.getPriceDisplay('dealerNet', this.props, this.handleDealerNetChange);
    const override = this.getPriceDisplay('overrideValue', this.props, this.handleOverrideValueChange);
    const overrideValue = selectedOption.overrideValue;
    const partnerServicesEstimates = this.getPartnerServicesEstimates(this.props);

    const dealerNetClassName = classNames({
      'ictp-provider-price': selectedOption.cellTypes['dealerNet'] === CELL_TYPES.amount,
      'ictp-provider-label': selectedOption.cellTypes['dealerNet'] === CELL_TYPES.label,
    });
    const overrideClassName = classNames({
      'ictp-override-price': true,
      'ictp-provider-price': selectedOption.cellTypes['overrideValue'] === CELL_TYPES.amount,
      'ictp-provider-label': selectedOption.cellTypes['overrideValue'] === CELL_TYPES.label,
    });
    const ictpProvider = classNames({
      'ictp-provider-sales-ops': salesOps,
      'ictp-provider': !salesOps,
    });
    let title = null;

    // selectedOption.locationSiblings indicates it is a Provider on Master Order level
    if (selectedOption.locationSiblings && selectedOption.locationSiblings.length && selectedOption.allocatedFinalized) {
      title = intl.formatMessage({ id: 'msg_finalized_ictpps_tooltip' });
    }

    if (isLocationQuote) {
      title = intl.formatMessage({
        id: 'msg_ictpps_components_can_only_be_updated_at_the_master_order_level',
      });
    }

    const labelJsSafe = label.replace(/\s/g, '-').toLowerCase();
    const selectInputId = 'ictp-' + labelJsSafe + '-select';

    return (
      <div className={ictpProvider}>
        <div className="ictp-provider-label">
          <label htmlFor={selectInputId}>{translator.getMessage(translatorMap[subCategoryId])}</label>
        </div>
        <div className="ictp-provider-options">
          {menuItems.length > 1 ? (
            <SelectControl
              id={selectInputId}
              className="ictp-provider-select-field"
              fullWidth={true}
              options={menuItems}
              lockedState={lockedState || isLocationQuote}
              disabledState={Boolean(selectedOption.allocatedFinalized) || disabledState}
              placeholder={label}
              value={selectedOption.lineItemId}
              onChange={this.handleOnChange}
              style={{ marginBottom: 0 }}
              tooltipText={title}
            />
          ) : (
            <span>{SELECTABLE_PARTY_SUBCATEGORY_IDS.includes(subCategoryId) ? label : ''}</span>
          )}
        </div>
        {isLocationQuote && (
          <Fragment>
            <div className="ictp-provider-label ictp-provider-total">
              <span className="ictp-price-label-name">{translator.getMessage('msg_total')}</span>
              <span>{formatter.currency(selectedOption.total)}</span>
            </div>
            <div className="ictp-provider-label ictp-provider-allocated">
              <span className="ictp-price-label-name">{translator.getMessage('msg_available')}</span>
              <span>{formatter.currency(selectedOption.available)}</span>
            </div>
          </Fragment>
        )}

        <div className={dealerNetClassName}>
          {!showOverrideColumn && overrideValue !== null ? <b>{formatter.currency(overrideValue)}</b> : dealerNet}
        </div>
        {showOverrideColumn && override ? (
          <div className={overrideClassName}>
            <label className="ictp-price-label">
              <span className="ictp-price-label-name">{translator.getMessage('msg_override')}</span>
            </label>
            {override}
          </div>
        ) : (
          salesOps && <div />
        )}
        {!isLocationQuote && SELECTABLE_PARTY_SUBCATEGORY_IDS.includes(this.props.subCategoryId) ? (
          <div
            className="ictp-provider-label"
            title={this.props.intl.formatMessage({
              id: 'msg_partner_service_tooltip',
            })}
          >
            <span>{formatter.currency(partnerServicesEstimates)}</span>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}

export default injectIntl(Provider);
