import React from 'react';
import { PriceBookContext } from '../../Context';

class PriceBookInfo extends React.Component {
  render() {
    return (
      <PriceBookContext.Provider>
        <PriceBookContext.Consumer>
          {pb =>
            pb.pricebookId && (
              <section className="pb-version-container">
                <label>PB</label>
                <strong className="pb-version">{[pb.pricebookId, pb.version, pb.draftNo].join('.')}</strong>
              </section>
            )
          }
        </PriceBookContext.Consumer>
      </PriceBookContext.Provider>
    );
  }
}

export default PriceBookInfo;
