import React, { PureComponent } from 'react';
import { injectIntl } from 'react-intl';
import RocketLogo from '../../assets/images/rocket-logo.svg';
import './emblem.style.css';

class EmblemComponent extends PureComponent {
  render() {
    const { intl } = this.props;
    const appName = intl.formatMessage({ id: 'msg_rocket_quote' }).split('®');

    // Replace "®" sign by <sup>&reg;</sup>
    for (let i = 1, length = appName.length, counter = 0; i < length; i++) {
      appName.splice(i + counter, 0, <sup key={'app-name-reg-icon-' + i}>&reg;</sup>);
      counter++;
    }

    return (
      <div className="rocket-emblem">
        <img src={RocketLogo} alt="logo" />
        <h2>{appName}</h2>
        <h3>
          <span>{intl.formatMessage({ id: 'msg_our_quick_and_easy_platform' })}</span>
        </h3>
      </div>
    );
  }
}

export default injectIntl(EmblemComponent);
