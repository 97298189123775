import React from 'react';
import { string, element, oneOfType, number } from 'prop-types';

const ExternalLink = ({ className = '', path, title = path }) => (
  <a className={className} href={path} target="_blank" rel="noopener noreferrer">
    {title}
  </a>
);

ExternalLink.propTypes = {
  path: string.isRequired,
  title: oneOfType([string, element, number]),
  className: string,
};

export default ExternalLink;
