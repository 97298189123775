import React from 'react';

function SectionsNavigationItem({
  id,
  isGeneralSection,
  title,
  onSelectGeneralSectionItemCallback,
  onSelectCallback,
  closeNavigation,
  listRef,
}) {
  const viewUpdateCallback = () => {
    let elem = document.querySelector(`#${id}`);

    if (elem) {
      let elTop = elem.getBoundingClientRect().top;
      // 190px is height of the header and location tabs toolbar
      let scrollY = window.scrollY + elTop - 190;
      window.scrollTo({ top: scrollY });
    }

    closeNavigation();
  };

  const handleOnClickItem = () => {
    if (typeof onSelectGeneralSectionItemCallback === 'function' && isGeneralSection) {
      onSelectGeneralSectionItemCallback(id, viewUpdateCallback, false);
    }

    if (typeof onSelectCallback === 'function') {
      onSelectCallback(id, viewUpdateCallback, false);
    } else {
      viewUpdateCallback();
    }
  };

  const handleKeyDown = event => {
    const key = event.key;
    const element = event.target;

    switch (key) {
      case 'ArrowDown':
        element.nextElementSibling ? element.nextElementSibling.focus() : listRef.current.firstChild.focus();
        event.preventDefault();
        break;
      case 'ArrowUp':
        element.previousElementSibling ? element.previousElementSibling.focus() : listRef.current.lastChild.focus();
        event.preventDefault();
        break;
      case 'Enter':
        handleOnClickItem();
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        closeNavigation();
        event.preventDefault();
        break;
      case 'Tab':
        if (!element.nextElementSibling) {
          closeNavigation();
        }
        break;
      default:
        break;
    }
  };

  return (
    <div
      tabIndex={0}
      key={`nav-item-${id}`}
      className="nav-item-btn"
      onClick={handleOnClickItem}
      onKeyDown={handleKeyDown}
    >
      {title}
    </div>
  );
}

export default SectionsNavigationItem;
