import { CustomerOrderModel, ModelAbstract } from '../index';
import { SCHEDULE_TYPE_OPTIONS, WORKSPACE_TYPE_OPTIONS } from '../../common/enums';

class ExchangeMigrationToggleModel extends ModelAbstract {
  constructor(masterOrder) {
    if (!(masterOrder instanceof CustomerOrderModel)) {
      throw new Error("ExchangeMigrationToggleModel only accept CustomerOrderModel instance as it's parent sibling.");
    }

    super(masterOrder);

    this._value = false;

    /** @type {CustomerOrderModel} */
    this.masterOrder = masterOrder;
    this.label = 'msg_select_on_premises_exchange_is_required';

    this.onChange = this.onChange.bind(this);

    this._ignoreOnExport.push('masterOrder', 'appState');
  }

  onChange(newValue) {
    this.value = newValue;
    this.modelChanged(this);
  }

  get appState() {
    if (!this.controller) {
      return null;
    }

    return this.controller.appState;
  }

  get value() {
    if (
      this.masterOrder.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.NONE ||
      this.masterOrder.daasScheduleType === SCHEDULE_TYPE_OPTIONS.NONE
    ) {
      return false;
    }

    return this._value;
  }

  set value(newValue) {
    this._value = Boolean(newValue);
  }

  get disabled() {
    return (
      this.appState.disabled ||
      this.masterOrder.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.NONE ||
      this.masterOrder.daasScheduleType === SCHEDULE_TYPE_OPTIONS.NONE
    );
  }

  get locked() {
    return this.appState.locked;
  }
}

export default ExchangeMigrationToggleModel;
