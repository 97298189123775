import {
  APPLIANCE_PROVIDERS_OPTIONS,
  LINE_TYPES,
  SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
  SWITCHVOX_ON_PREM_TYPES_OPTIONS,
} from '../enums';

const opportunityProductType = {
  type: 'opportunityProductType',
  autoSetTogglePropName: 'autoSetOpportunityProductType',
  dropDownPropName: 'opportunityProductType',
};

export const QUOTE_INFO_INPUTS_NEW_NEW = [
  {
    type: 'quoteTitle',
    props: {
      id: 'quoteTitle',
      label: 'msg_quote_title',
    },
  },
  {
    type: 'switchvoxOnPremEnabled',
    id: 'switchvoxOnPremEnabled',
    props: {
      label: 'msg_switchvox_on_prem',
    },
  },
  {
    type: 'sipStationEnabled',
    id: 'sipStationEnabled',
    props: {
      label: 'msg_sip_station',
    },
  },
  {
    type: 'standaloneServiceNonUCaaS',
    id: 'standaloneServiceNonUCaaS',
    props: {
      label: 'msg_standalone_service_non_ucaas',
      hint: 'msg_standalone_service_non_ucaas_sub_label',
    },
  },
  {
    type: 'contractTermSelect',
    props: {
      id: 'contractTermMonths',
      label: 'msg_contract_length_month',
    },
  },
  {
    type: 'LocationsNumber',
    id: 'numLocations',
    props: {
      required: true,
      hint: '',
      label: 'msg_number_of_locations',
      id: 'numLocations',
      max: 99999,
      textFieldProps: {
        type: 'Number',
        inputStyle: {
          textAlign: 'right',
          paddingRight: '10px',
          boxSizing: 'border-box',
        },
      },
    },
  },
  {
    type: 'StarCloudLocations',
    id: 'numStarCloudLocations',
    props: {
      label: 'msg_business_voice_locations_from_number_above',
      textRight: true,
      max: 99999,
    },
  },
  {
    type: 'BusinessVoicePlusLocations',
    id: 'BusinessVoicePlusLocations',
    props: {
      label: 'msg_business_voice_plus_locations_from_number_above',
    },
  },
  {
    type: 'smartOfficeLocationsQty',
    id: 'smartOfficeLocationsQty',
    props: {
      label: 'msg_smart_office_locations_qty',
      textRight: true,
      max: 99999,
    },
  },
  {
    type: 'switchvoxSIPStationLocations',
    id: 'switchvoxSIPStationLocations',
    props: {
      label: 'msg_switchvox_sip_station_locations',
    },
  },
  {
    type: 'switchvoxApplianceProvider',
    id: 'switchvoxApplianceProvider',
    props: {
      label: 'msg_appliance_provided_by',
      options: APPLIANCE_PROVIDERS_OPTIONS,
    },
  },
  {
    type: 'switchvoxOnPremMaintType',
    id: 'switchvoxOnPremMaintType',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_type',
      options: SWITCHVOX_ON_PREM_TYPES_OPTIONS,
    },
  },
  {
    type: 'switchvoxOnPremMaintTermYears',
    id: 'switchvoxOnPremMaintTermYears',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_term',
      options: SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
    },
  },
  {
    type: 'numSwitchvoxOnPremUsers',
    id: 'numSwitchvoxOnPremUsers',
    props: {
      label: 'msg_qty_switchvox_on_prem_users',
      min: 1,
    },
  },
  {
    type: 'useNumLinesDefault',
    id: 'useNumLinesDefault',
    props: {
      id: 'useNumLinesDefault',
      hint: 'msg_set_to_no_to_override',
      label: 'msg_use_recommended_value_for_number_of_lines',
      textFieldProps: {},
    },
  },
  {
    type: 'NumLines',
    id: 'NumLines',
    props: { id: 'NumLines' },
  },
  {
    type: 'lineType',
    props: {
      id: 'lineType',
      label: 'msg_line_type',
      options: [
        {
          key: LINE_TYPES.UNLIMITED,
          value: 1,
          text: 'Unlimited',
        },
        {
          key: LINE_TYPES.METERED,
          value: 2,
          text: 'Metered',
        },
      ],
    },
  },
  {
    type: 'dealerNetToggle',
    id: 'dealerNetToggle',
    props: {
      id: 'dealerNetToggle',
      label: 'msg_quote_info_dealer_net_toggle',
      textFieldProps: {},
    },
  },
  {
    type: 'bulkMarkup',
    id: 'bulkMarkup',
  },
  {
    type: 'businessContinuity',
    props: {
      id: 'businessContinuity',
      label: 'msg_business_continuity',
    },
  },
  {
    type: 'coBrandingEnabled',
    props: {
      id: 'cobrandingEnabled',
      label: 'msg_co_branding',
    },
  },
  opportunityProductType,
];

export const QUOTE_INFO_INPUTS_NEW_LOCATIONS = [
  {
    type: 'quoteTitle',
    props: {
      id: 'quoteTitle',
      label: 'msg_quote_title',
    },
  },
  {
    type: 'switchvoxOnPremEnabled',
    id: 'switchvoxOnPremEnabled',
    props: {
      label: 'msg_switchvox_on_prem',
    },
  },
  {
    type: 'sipStationEnabled',
    id: 'sipStationEnabled',
    props: {
      label: 'msg_sip_station',
    },
  },
  {
    type: 'standaloneServiceNonUCaaS',
    id: 'standaloneServiceNonUCaaS',
    props: {
      label: 'msg_standalone_service_non_ucaas',
      hint: 'msg_standalone_service_non_ucaas_sub_label',
    },
  },
  {
    type: 'contractTermSelect',
    props: {
      id: 'contractTermMonths',
      label: 'msg_contract_length_month',
    },
  },
  {
    type: 'LocationsNumber',
    id: 'numLocations',
    props: {
      required: true,
      hint: '',
      label: 'msg_number_of_locations',
      id: 'numLocations',
      max: 99999,
      textFieldProps: {
        type: 'Number',
        inputStyle: {
          textAlign: 'right',
          paddingRight: '10px',
          boxSizing: 'border-box',
        },
      },
    },
  },
  {
    type: 'StarCloudLocations',
    id: 'numStarCloudLocations',
    props: {
      label: 'msg_business_voice_locations_from_number_above',
      textRight: true,
      max: 99999,
    },
  },
  {
    type: 'BusinessVoicePlusLocations',
    id: 'BusinessVoicePlusLocations',
    props: {
      label: 'msg_business_voice_plus_locations_from_number_above',
    },
  },
  {
    type: 'smartOfficeLocationsQty',
    id: 'smartOfficeLocationsQty',
    props: {
      label: 'msg_smart_office_locations_qty',
      textRight: true,
      max: 99999,
    },
  },
  {
    type: 'switchvoxSIPStationLocations',
    id: 'switchvoxSIPStationLocations',
    props: {
      label: 'msg_switchvox_sip_station_locations',
    },
  },
  {
    type: 'switchvoxApplianceProvider',
    id: 'switchvoxApplianceProvider',
    props: {
      label: 'msg_appliance_provided_by',
      options: APPLIANCE_PROVIDERS_OPTIONS,
    },
  },
  {
    type: 'switchvoxOnPremMaintType',
    id: 'switchvoxOnPremMaintType',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_type',
      options: SWITCHVOX_ON_PREM_TYPES_OPTIONS,
    },
  },
  {
    type: 'switchvoxOnPremMaintTermYears',
    id: 'switchvoxOnPremMaintTermYears',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_term',
      options: SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
    },
  },
  {
    type: 'numSwitchvoxOnPremUsers',
    id: 'numSwitchvoxOnPremUsers',
    props: {
      label: 'msg_qty_switchvox_on_prem_users',
      min: 1,
    },
  },
  {
    type: 'useNumLinesDefault',
    id: 'useNumLinesDefault',
    props: {
      id: 'useNumLinesDefault',
      hint: 'msg_set_to_no_to_override',
      label: 'msg_use_recommended_value_for_number_of_lines',
      textFieldProps: {},
    },
  },
  {
    type: 'NumLines',
    id: 'NumLines',
    props: { id: 'NumLines' },
  },
  {
    type: 'lineType',
    props: {
      id: 'lineType',
      label: 'msg_line_type',
      options: [
        {
          key: LINE_TYPES.UNLIMITED,
          value: 1,
          text: 'Unlimited',
        },
        {
          key: LINE_TYPES.METERED,
          value: 2,
          text: 'Metered',
        },
      ],
    },
  },
  {
    type: 'dealerNetToggle',
    id: 'dealerNetToggle',
    props: {
      id: 'dealerNetToggle',
      label: 'msg_quote_info_dealer_net_toggle',
      textFieldProps: {},
    },
  },
  {
    type: 'bulkMarkup',
    id: 'bulkMarkup',
  },
  {
    type: 'businessContinuity',
    props: {
      id: 'businessContinuity',
      label: 'msg_business_continuity',
    },
  },
  {
    type: 'coBrandingEnabled',
    props: {
      id: 'cobrandingEnabled',
      label: 'msg_co_branding',
    },
  },
  opportunityProductType,
];

export const QUOTE_INFO_INPUTS_ADD_ON = [
  {
    type: 'quoteTitle',
    props: {
      id: 'quoteTitle',
      label: 'msg_quote_title',
    },
  },
  {
    type: 'switchvoxOnPremEnabled',
    id: 'switchvoxOnPremEnabled',
    props: {
      label: 'msg_switchvox_on_prem',
    },
  },
  {
    type: 'sipStationEnabled',
    id: 'sipStationEnabled',
    props: {
      label: 'msg_sip_station',
    },
  },
  {
    type: 'standaloneServiceNonUCaaS',
    id: 'standaloneServiceNonUCaaS',
    props: {
      label: 'msg_standalone_service_non_ucaas',
      hint: 'msg_standalone_service_non_ucaas_sub_label',
    },
  },
  {
    type: 'contractTermSelect',
    props: {
      id: 'contractTermMonths',
      label: 'msg_contract_length_month',
    },
  },
  {
    type: 'smartOfficeLocation',
    props: {
      id: 'smartOfficeLocationsQty',
      label: 'msg_smart_office_location',
    },
  },
  {
    type: 'switchvoxOnPremMaintType',
    id: 'switchvoxOnPremMaintType',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_type',
      options: SWITCHVOX_ON_PREM_TYPES_OPTIONS,
    },
  },
  {
    type: 'switchvoxOnPremMaintTermYears',
    id: 'switchvoxOnPremMaintTermYears',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_term',
      options: SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
    },
  },
  {
    type: 'numSwitchvoxOnPremUsers',
    id: 'numSwitchvoxOnPremUsers',
    props: {
      label: 'msg_qty_switchvox_on_prem_users',
      min: 0,
    },
  },
  {
    type: 'bulkMarkup',
    id: 'bulkMarkup',
  },
  {
    type: 'businessContinuity',
    props: {
      id: 'businessContinuity',
      label: 'msg_business_continuity',
    },
  },
  {
    type: 'coBrandingEnabled',
    props: {
      id: 'cobrandingEnabled',
      label: 'msg_co_branding',
    },
  },
  opportunityProductType,
];

export const QUOTE_INFO_INPUTS_REWRITE = [
  {
    type: 'quoteTitle',
    props: {
      id: 'quoteTitle',
      label: 'msg_quote_title',
    },
  },
  {
    type: 'switchvoxOnPremEnabled',
    id: 'switchvoxOnPremEnabled',
    props: {
      label: 'msg_switchvox_on_prem',
    },
  },
  {
    type: 'sipStationEnabled',
    id: 'sipStationEnabled',
    props: {
      label: 'msg_sip_station',
    },
  },
  {
    type: 'standaloneServiceNonUCaaS',
    id: 'standaloneServiceNonUCaaS',
    props: {
      label: 'msg_standalone_service_non_ucaas',
      hint: 'msg_standalone_service_non_ucaas_sub_label',
    },
  },
  {
    type: 'contractTermSelect',
    props: {
      id: 'contractTermMonths',
      label: 'msg_contract_length_month',
    },
  },
  {
    type: 'LocationsNumber',
    id: 'numLocations',
    props: {
      required: true,
      hint: '',
      label: 'msg_number_of_locations',
      id: 'numLocations',
      max: 99999,
      textFieldProps: {
        type: 'Number',
        inputStyle: {
          textAlign: 'right',
          paddingRight: '10px',
          boxSizing: 'border-box',
        },
      },
    },
  },
  {
    type: 'StarCloudLocations',
    id: 'numStarCloudLocations',
    props: {
      label: 'msg_business_voice_locations_from_number_above',
      textRight: true,
      max: 99999,
    },
  },
  {
    type: 'BusinessVoicePlusLocations',
    id: 'BusinessVoicePlusLocations',
    props: {
      label: 'msg_business_voice_plus_locations_from_number_above',
    },
  },
  {
    type: 'smartOfficeLocationsQty',
    id: 'smartOfficeLocationsQty',
    props: {
      label: 'msg_smart_office_locations_qty',
      textRight: true,
      max: 99999,
    },
  },
  {
    type: 'switchvoxSIPStationLocations',
    id: 'switchvoxSIPStationLocations',
    props: {
      label: 'msg_switchvox_sip_station_locations',
    },
  },
  {
    type: 'switchvoxOnPremMaintType',
    id: 'switchvoxOnPremMaintType',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_type',
      options: SWITCHVOX_ON_PREM_TYPES_OPTIONS,
    },
  },
  {
    type: 'switchvoxOnPremMaintTermYears',
    id: 'switchvoxOnPremMaintTermYears',
    props: {
      label: 'msg_switchvox_on_prem_support_maintenance_term',
      options: SWITCHVOX_ON_PREM_TERM_YEARS_OPTIONS,
    },
  },
  {
    type: 'numSwitchvoxOnPremUsers',
    id: 'numSwitchvoxOnPremUsers',
    props: {
      label: 'msg_qty_switchvox_on_prem_users',
      min: 1,
    },
  },
  {
    type: 'useNumLinesDefault',
    id: 'useNumLinesDefault',
    props: {
      id: 'useNumLinesDefault',
      hint: 'msg_set_to_no_to_override',
      label: 'msg_use_recommended_value_for_number_of_lines',
      textFieldProps: {},
    },
  },
  {
    type: 'NumLines',
    id: 'NumLines',
    props: { id: 'NumLines' },
  },
  {
    type: 'lineType',
    props: {
      id: 'lineType',
      label: 'msg_line_type',
      options: [
        {
          key: LINE_TYPES.UNLIMITED,
          value: 1,
          text: 'Unlimited',
        },
        {
          key: LINE_TYPES.METERED,
          value: 2,
          text: 'Metered',
        },
      ],
    },
  },
  {
    type: 'dealerNetToggle',
    id: 'dealerNetToggle',
    props: {
      id: 'dealerNetToggle',
      label: 'msg_quote_info_dealer_net_toggle',
      textFieldProps: {},
    },
  },
  {
    type: 'bulkMarkup',
    id: 'bulkMarkup',
  },
  {
    type: 'businessContinuity',
    props: {
      id: 'businessContinuity',
      label: 'msg_business_continuity',
    },
  },
  {
    type: 'coBrandingEnabled',
    props: {
      id: 'cobrandingEnabled',
      label: 'msg_co_branding',
    },
  },
  opportunityProductType,
];

export const QUOTE_INFO_INPUTS_REDUCTION = [
  {
    type: 'useNumLinesDefault',
    id: 'useNumLinesDefault',
    props: {
      id: 'useNumLinesDefault',
      hint: 'msg_set_to_no_to_override',
      label: 'msg_use_recommended_value_for_number_of_lines',
      textFieldProps: {},
    },
  },
  {
    type: 'NumLines',
    id: 'NumLines',
    props: { id: 'NumLines' },
  },
];
