import { CALC_RESULT_PACKAGED, RESULT_TYPES } from '../Models/CalcResultsModel';
import { QUOTE_STATUS, ORDER_TYPES, USER_ROLES, LEASE_OPTIONS, SCHEDULE_TYPE_OPTIONS, PRICEBOOK_TAGS } from './enums';
import { AuthContext, PartnerContext, PriceBookContext } from '../Context';
import { CONTACT_PROPS } from '../components/CustomerContactInfo/ContactSectionProps';
import constants from './enums/constants.enums';
import { BILLING_EMAIL_VALIDATION_STATUSES } from './enums/billingEmailValidationStatuses';
import apiData from '../Storage/apiData';
import isValidEmail from './helpers/isValidEmail';

// TODO: This logic has to replaced by BE validation configs
const REQUIRED_FOR_FINALIZE_LOC = {
  serviceInfo: {
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
    btn: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactOfficePhone: '',
  },
  billingInfo: {
    billToId: null,
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: '',
    zip: '',
    countryId: '',
    contactOfficePhone: '',
    contactEmail: '',
  },
  shippingInfo: {
    companyName: '',
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
  },
  dateShippingRequested: false,
  trackingLocationId: false,
};
const WLW_EXCLUDED_FIELDS = ['contactOfficePhone', 'contactEmail'];

const VALID_EMAIL_STATUSES = [
  BILLING_EMAIL_VALIDATION_STATUSES.VALID,
  BILLING_EMAIL_VALIDATION_STATUSES.CONFIRMED_AS_VALID,
];

function checkAvailableCnt(lineItemsCatalog) {
  for (let i = 0; i < lineItemsCatalog.length; i++) {
    if (lineItemsCatalog[i].active && !lineItemsCatalog[i].hidden) {
      const lineItems = lineItemsCatalog[i].lineItems;

      for (let j = 0; j < lineItems.length; j++) {
        if (
          (lineItemsCatalog[i].id === constants.MAINTENANCE_CATEGORY_ID && lineItems[j].availablePrice !== 0) ||
          (lineItems[j].active && !lineItems[j].hidden && lineItems[j].selectable && lineItems[j].available > 0)
        ) {
          return true;
        }
      }
    }
  }
  return false;
}

export const checkRequiredFieldsForFinalize = (
  quoteModel,
  isNeedRollUp,
  REQUIRED_FOR_FINALIZE,
  addOnFinalizeAllow,
  isFinalizeBtn = true
) => {
  const missingFields = [];
  const separateLineFields = [];

  if (quoteModel.calcResults.getValue('ipBlockFiveMinimumForHighAvailabilitySdWanWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_ip_block_5_minimum_is_required_for_high_availability_sd_wan@',
    });
  }

  if (quoteModel.calcResults.getValue('ipBlockRequiresSdWanWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_ip_block_requires_sd_wan@',
    });
  } else if (quoteModel.calcResults.getValue('sdWanRequiresIpBlockWarning', RESULT_TYPES.boolean)) {
    missingFields.push({
      fieldLabel: '@msg_sd_wan_requires_ip_block@',
    });
  }

  if (quoteModel.orderType === ORDER_TYPES.ADD_ON && !addOnFinalizeAllow) {
    missingFields.push({
      fieldLabel: '@msg_the_original_location_order_must_be_finalized@',
    });
  }

  if (
    isFinalizeBtn &&
    !quoteModel.useESigDocumentsFlow &&
    !quoteModel.lockedInPrintFlow &&
    quoteModel.requiresPrintFlowConfirmation
  ) {
    missingFields.push({
      fieldLabel: '@msg_print_flow_not_confirmed_finalize_error@',
      singleMessage: true,
    });
  }

  if (
    quoteModel.orderType === ORDER_TYPES.ADD_ON &&
    AuthContext.model.role === USER_ROLES.REGULAR_USER &&
    quoteModel.locationPackageType === null
  ) {
    missingFields.push({
      fieldLabel: '@msg_this_order_cannot_be_finalized_since_the_location_is_not_yet_installed@',
    });
  }

  // Get required For Finalize
  for (let el in REQUIRED_FOR_FINALIZE) {
    if (REQUIRED_FOR_FINALIZE.hasOwnProperty(el) && typeof REQUIRED_FOR_FINALIZE[el] === 'object') {
      const requiredFields = Object.keys(REQUIRED_FOR_FINALIZE[el]);
      const missing = requiredFields.filter(subEl => !quoteModel[el][subEl]);

      if (missing.length && el === 'serviceInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_service_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_service_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      }

      if (missing.length && el === 'billingInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_billing_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_billing_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      }

      if (missing.length && el === 'shippingInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel:
              '@msg_shipping_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_shipping_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      }
    }
    // Get Spiff / Discount Confirmation
    else if (!quoteModel[el] && el === 'isSpiffConfirmationValid') {
      missingFields.push({
        fieldName: el,
        fieldLabel: '@msg_customer_discount_spiff_confirmation@',
      });
    }
    // Get Ictp dates
    if (REQUIRED_FOR_FINALIZE.hasOwnProperty(el) && !quoteModel[el] && el === 'dateShippingRequested') {
      missingFields.push({
        fieldName: el,
        fieldLabel: '@msg_ictp_ship_install_dates@',
      });
    }
  }

  const skipSendgridValidation =
    quoteModel.orderType === ORDER_TYPES.ADD_ON || PartnerContext.model.isWhiteLabelWholesale;

  if (!skipSendgridValidation) {
    if (
      !isValidEmail(quoteModel.billingInfo.contactEmail) ||
      (apiData.properties.sendgrid.enabled &&
        !VALID_EMAIL_STATUSES.includes(quoteModel.billingInfo.contactEmailValidationStatus))
    ) {
      missingFields.push({
        fieldLabel: '@msg_validate_email_address_message@',
      });
    }
  }

  if (
    quoteModel.taxExempt === null &&
    ORDER_TYPES.ADD_ON !== quoteModel.orderType &&
    !PartnerContext.model.isWhiteLabelWholesale
  ) {
    missingFields.push({
      fieldLabel: '@msg_tax_exempt_validation@',
    });
  }

  if (
    quoteModel.expired &&
    [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(quoteModel.orderType)
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_expired_warning@',
    });
  }

  if (
    !quoteModel.useESigDocumentsFlow &&
    quoteModel.calcResults.getValue('estimatedLeasePayment', RESULT_TYPES.float, CALC_RESULT_PACKAGED) <= 0 &&
    quoteModel.leaseOption !== LEASE_OPTIONS.NONE
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_lease_options_not_eligible@',
    });
  }

  if (
    quoteModel.orderType === ORDER_TYPES.ADD_ON &&
    quoteModel.businessContinuity &&
    !AuthContext.model.hasSalesOpsPermissions
  ) {
    separateLineFields.push({
      fieldLabel: '@msg_contact_sales_ops_to_finalize_bc_addon@',
    });
  }

  const message = missingFields.length ? missingFields.map(m => m.fieldLabel).join('@\n') : '';

  if (isNeedRollUp && missingFields.length < 1) {
    return {
      message: message,
      fields: [{}],
      separateLineFields,
    };
  }

  return {
    message: message,
    fields: missingFields,
    separateLineFields,
  };
};

export const checkRequiredFieldsForFinalizeLoc = (locationModel, quoteModel, isQuoteOverAllocated, isSaveAllowed) => {
  const missingFields = [];

  for (let el in REQUIRED_FOR_FINALIZE_LOC) {
    if (typeof REQUIRED_FOR_FINALIZE_LOC[el] === 'object') {
      const requiredFields = Object.keys(REQUIRED_FOR_FINALIZE_LOC[el]);
      let missing = requiredFields.filter(subEl => !locationModel[el][subEl]);

      if (el === 'serviceInfo' && PartnerContext.model.isWhiteLabelWholesale) {
        missing = missing.filter(field => !WLW_EXCLUDED_FIELDS.includes(field));
      }

      if (missing.length && el === 'serviceInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_service_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_service_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      } else if (missing.length && el === 'billingInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_billing_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_billing_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      } else if (missing.length && el === 'shippingInfo') {
        if (missing.length === 1) {
          missingFields.push({
            fieldName: el,
            fieldLabel:
              '@msg_shipping_information @ @ ' + CONTACT_PROPS[el][missing].label + ' @ @ msg_field_remaining@',
          });
        } else {
          missingFields.push({
            fieldName: el,
            fieldLabel: '@msg_shipping_information @ ' + missing.length + ' @ msg_fields_remaining@',
          });
        }
      }
    } else if (!locationModel[el] && el === 'dateShippingRequested') {
      missingFields.push({
        fieldName: el,
        fieldLabel: '@msg_ictp_ship_install_dates@',
      });
    } else if (!locationModel[el] && el === 'trackingLocationId') {
      const fieldLabel =
        quoteModel.orderType === ORDER_TYPES.REWRITE
          ? '@msg_tracking_location_id_rewrites@'
          : '@msg_tracking_location_id@';

      missingFields.push({
        fieldName: el,
        fieldLabel,
      });
    }
  }

  if (quoteModel.trackingLocationId === null) {
    missingFields.push({
      fieldName: 'trackingLocationId',
      fieldLabel: '@msg_finalize_master_order@',
    });
  }

  if (locationModel.calcResults.getValue('sdWanPrerequisite4gWarning', 'boolean')) {
    missingFields.push({
      fieldName: '4gWarning',
      fieldLabel: '@msg_star_cloud_locations_ordering@',
    });
  }

  if (
    PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_NEW_DAAS) &&
    quoteModel.daasScheduleType === SCHEDULE_TYPE_OPTIONS.MIXED &&
    locationModel.calcResults.getValue('numScheduledDaaS', 'integer') > 0 &&
    locationModel.daasScheduleType === SCHEDULE_TYPE_OPTIONS.NONE
  ) {
    missingFields.push({
      fieldName: 'daasScheduleTypeOption',
      fieldLabel: '@msg_select_daas_schedule_type@',
    });
  }

  const fieldsMessage = missingFields.length ? missingFields.map(m => m.fieldLabel).join('@\n') : '';
  let message = missingFields.length !== 0 ? `@msg_enter_required_fields@:\n @${fieldsMessage}@\n` : '';

  if (isQuoteOverAllocated || quoteModel.ictpProviders.priceIsExceeded()) {
    message += ' @msg_locations_exceed_order_totals@\n';
    missingFields.push({});
  }

  if (isSaveAllowed) {
    message += ' @msg_save_changes@\n';
    missingFields.push({});
  }

  const skipSendgridValidation = PartnerContext.model.isWhiteLabelWholesale;

  if (!skipSendgridValidation) {
    if (!VALID_EMAIL_STATUSES.includes(locationModel.billingInfo.contactEmailValidationStatus)) {
      message += '@msg_validate_email_address_message@\n';
      missingFields.push({});
    }
  }

  return {
    message: message,
    fields: missingFields,
  };
};

export const checkLastLocationFinalize = (locationModel, quoteModel, locations, isQuoteOverAllocated) => {
  let message = '';
  let fields = [];

  if (quoteModel.numLocations === locations.length - 1) {
    const isLastFinalize = locations.slice(1).filter(l => l.status !== QUOTE_STATUS.FINALIZED).length === 1;

    if (isLastFinalize) {
      let isBlocked = isQuoteOverAllocated;

      if ([2, 4].includes(quoteModel.packageIdSelected)) {
        isBlocked = quoteModel.numLines !== quoteModel.numLinesAllocated;
      }

      if (!isBlocked) {
        isBlocked =
          checkAvailableCnt(locationModel.lineItemsCatalog.nonRecurring) ||
          checkAvailableCnt(locationModel.lineItemsCatalog.recurring) ||
          checkAvailableCnt(locationModel.lineItemsCatalog.categories) ||
          !quoteModel.ictpProviders.isAllPricesAllocated();
      }

      if (!isBlocked) {
        isBlocked = quoteModel.numSwitchvoxOnPremUsersAllocated !== quoteModel.numSwitchvoxOnPremUsers;
      }

      if (isBlocked) {
        message = '@msg_location_totals_must_match_mo';
        fields = [{}];
      }
    }
  }

  return {
    message,
    fields,
  };
};

export const finalizeBtnTooltipText = (
  { message, seRulesMessage, separateLineFields },
  internalUser,
  saved,
  isNeedRollUp,
  singleMessage = false
) => {
  if (singleMessage && message) {
    return message;
  }

  const separateLineFieldsMessage = separateLineFields.length
    ? separateLineFields.map(m => m.fieldLabel).join('@\n')
    : '';

  let tooltip = '';

  if (message) {
    tooltip += `@msg_enter_required_fields@:\n @${message}\n @@msg_and_save@\n`;
  }

  if (separateLineFieldsMessage) {
    tooltip += separateLineFieldsMessage;
  }

  if (seRulesMessage) {
    tooltip += `@msg_items_require_for_approval@\n ${seRulesMessage}\n`;
  }

  if (isNeedRollUp) {
    tooltip += '@msg_order_must_be_roll_up@\n';
  }

  if (tooltip === '' && !saved) {
    tooltip += '@msg_save_changes@';
  }

  return tooltip;
};
