export const SCHEDULE_TYPE_OPTIONS = {
  NONE: 'NONE',
  MON_FRI: 'MON_FRI',
  MON_SAT: 'MON_SAT',
  MON_SUN: 'MON_SUN',
  MIXED: 'MIXED',
};

export const SCHEDULE_TYPE_DROPDOWN_OPTIONS = [
  {
    key: '1',
    value: 'NONE',
    text: 'msg_lease_options_label_none',
  },
  {
    key: '2',
    value: 'MON_FRI',
    text: 'msg_monday_friday',
  },
  {
    key: '3',
    value: 'MON_SAT',
    text: 'msg_monday_saturday',
  },
  {
    key: '4',
    value: 'MON_SUN',
    text: 'msg_monday_sunday',
  },
  {
    key: '5',
    value: 'MIXED',
    text: 'msg_mixed',
  },
];

export const LOCATION_SCHEDULE_TYPE_DROPDOWN_OPTIONS = [
  {
    key: '1',
    value: 'NONE',
    text: 'msg_lease_options_label_none',
  },
  {
    key: '2',
    value: 'MON_FRI',
    text: 'msg_monday_friday',
  },
  {
    key: '3',
    value: 'MON_SAT',
    text: 'msg_monday_saturday',
  },
  {
    key: '4',
    value: 'MON_SUN',
    text: 'msg_monday_sunday',
  },
];
