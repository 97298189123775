import React from 'react';
import { v4 as UUID } from 'uuid';
import ExternalLink from '../../../components/ExternalLink';

export default function replaceStringUrlsWithLinkTag(string) {
  if (typeof string !== 'string') {
    return string;
  }

  const uuid = UUID();
  const pattern =
    /((?:(?:https?|ftp|file):\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#/%=~_|$?!:,.]*\)|[A-Z0-9+&@#/%=~_|$]))/i;

  return string
    .split(pattern)
    .map((chunk, index) => (chunk.match(pattern) ? <ExternalLink key={uuid + '-' + index} path={chunk} /> : chunk));
}
