import React from 'react';
import { SelectControl } from '../../../Controls';

const IndustryType = ({ label, industryTypes, value, onChange }) => {
  const options = industryTypes.map(industryType => {
    return {
      key: industryType.id,
      value: industryType.id,
      text: industryType.name,
    };
  });

  return (
    <SelectControl
      id="industry-type-select"
      className="industry-type-select"
      value={value}
      label={label}
      options={options}
      onChange={onChange}
      required
    />
  );
};

export default IndustryType;
