import React, { Fragment } from 'react';
import { string, number, bool, oneOfType } from 'prop-types';
import translator from '../../../common/translate';
import { injectIntl } from 'react-intl';
import classNames from 'classnames';
import formatString from '../../../common/helpers/format/formatString';
import IconButtonWithTooltip from '../../IconButtonWithTooltip';

const TableRow = ({
  title = '',
  nrcValue = '',
  rcValue = '',
  isBold = false,
  titleFullRow = false,
  intl,
  errorSubTitle,
  nrcIcon,
  rcIcon,
}) => {
  const isEmptyRow = title.trim() === '' && !nrcValue && !rcValue;
  const isFullWidth = isEmptyRow || titleFullRow;
  const rowClassName = classNames({
    strong: isBold,
  });

  return (
    <tr className={rowClassName} key={title}>
      <td colSpan={isFullWidth ? 3 : null}>
        {isEmptyRow ? <span>&nbsp;</span> : formatString(title, intl.formatMessage)}
        {errorSubTitle ? (
          <span className="error-sub-title">
            <IconButtonWithTooltip
              className="square tax-error-icon"
              iconClass={errorSubTitle.icon}
              tooltipText={formatString(errorSubTitle.iconTitle, intl.formatMessage)}
              tooltipAlign="left"
              tooltipVerticalAlign={'top'}
              showTooltip
            />
            <span title={formatString(errorSubTitle.iconTitle, intl.formatMessage)}>
              {translator.getMessage(errorSubTitle.title)}
            </span>
          </span>
        ) : null}
      </td>
      {!isFullWidth && (
        <Fragment>
          <td className="align-right">
            {nrcIcon} {nrcValue}
          </td>
          <td className="align-right">
            {rcIcon} {rcValue}
          </td>
        </Fragment>
      )}
    </tr>
  );
};

TableRow.propTypes = {
  title: string,
  nrcValue: oneOfType([string, number]),
  rcValue: oneOfType([string, number]),
  isBold: bool,
  titleFullRow: bool,
};

export default injectIntl(TableRow);
