import { ORDER_TYPES } from './index';

export const MANDATORY_SF_QUOTE_FIELDS_BY_QUOTE_TYPE = {
  [ORDER_TYPES.NEW_CUSTOMER]: [
    'dealerId',
    'billingDealerId',
    'opportunityId',
    'dealerName',
    'customerAccountId',
    'serviceInfo',
    'name',
  ],
  [ORDER_TYPES.NEW_LOCATIONS]: [
    'dealerId',
    'billingDealerId',
    'opportunityId',
    'dealerName',
    'customerAccountId',
    'serviceInfo',
    'name',
    'customerId',
    'customerName',
  ],
  [ORDER_TYPES.ADD_ON]: [
    'dealerId',
    'billingDealerId',
    'opportunityId',
    'dealerName',
    'customerAccountId',
    'serviceInfo',
    'name',
    'customerId',
    'customerName',
    'locationName',
    'locationId',
  ],
};
