import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import translator from '../../../common/translate';
import {
  SIGNATURE_STATUS,
  USER_ROLES,
  ESIG_ENVELOPE_TYPES,
  ESIG_ENVELOPE_TYPE_LABELS,
  SIGNER_STATUS,
} from '../../../common/enums';
import { AuthContext } from '../../../Context';
import bindMultiple from '../../../common/helpers/bindMultiple';
import Dialog from '../../Dialog';
import BadgeButton from '../../BadgeButton';
import DetailsTable from './nested/DetailsTable';
import DetailsSignersTable from './nested/DetailsSignersTable';
import './signature-details.css';
import { UpperCaseEachWord } from '../../../common/helpers/format';
import HiddenFormHelper from '../../HiddenFormHelper';
import PdfHiddenForm from '../../PdfHiddenForm';
import dayjs from 'dayjs';

const CUSTOM_DOCUMENT = 'CUSTOM_DOCUMENT';

class Details extends Component {
  static propTypes = {
    isVisible: PropTypes.bool,
    data: PropTypes.object,
    onClickClose: PropTypes.func,
    onClickBack: PropTypes.func,
    onClickRefresh: PropTypes.func,
    getSignaturePreviewUrlAndValues: PropTypes.func,
    onClickReSubmitSignature: PropTypes.func,
    getSignatureDownloadEndpoint: PropTypes.func,
    onChangeSignerEmail: PropTypes.func,
    requestEmailChange: PropTypes.func,
    isSalesOps: PropTypes.bool,
    signatureReSubmitAllowed: PropTypes.bool,
    isExistingAls: PropTypes.bool,
    isEnvelopeAvailable: PropTypes.bool,
    sendReminderToSigner: PropTypes.func,
  };

  static defaultProps = {
    isVisible: true,
  };

  constructor(props) {
    super(props);

    bindMultiple(this, this.handleClickClose, this.handleClickReSubmit);
  }

  handleClickClose() {
    if (typeof this.props.onClickClose === 'function') {
      this.props.onClickClose();
    }
  }

  handleClickReSubmit() {
    if (typeof this.props.onClickReSubmitSignature === 'function') {
      this.props.onClickReSubmitSignature(this.props.data, this.props.data.bundleType);
    }
  }

  pdfSection(customPdf) {
    const pdfHeader = customPdf ? translator.getMessage('msg_custom_pdf') : '';

    return (
      <div className="signature-details-pdf-section">
        <div className="details-pdf-header">{pdfHeader}</div>
        <div className="details-pdf-body">
          {customPdf && (
            <div className="pdf-body-row">
              <span>{translator.getMessage('msg_name')}</span>
              <span>{customPdf.fileName}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  __getBundleLabel(bundleType) {
    if (!bundleType) {
      return '';
    }

    const agreementAndOrderLabel = this.props.isExistingAls ? 'ORDER' : 'QUOTE';
    const labelType = bundleType === ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER ? agreementAndOrderLabel : bundleType;

    return this.props.intl.formatMessage({ id: ESIG_ENVELOPE_TYPE_LABELS[labelType] });
  }

  render() {
    const {
      data,
      isVisible,
      isSalesOps,
      intl,
      onChangeSignerEmail,
      requestEmailChange,
      signatureReSubmitAllowed,
      isEnvelopeAvailable,
      sendReminderToSigner,
      getSignaturePreviewUrlAndValues,
    } = this.props;

    const {
      title,
      status,
      bundleType,
      creationDate,
      lastModified,
      id,
      eSigners,
      eSignatureDocuments,
      bounced,
      bouncedEmailAddress,
    } = data;

    let dialogProps = {
      modalClassName: 'signature-details-modal',
      actionsContainerClassName: 'submit-dialog-actions',
    };

    // Force reorder signers by signerOrder but don't blindly rely on API response order
    eSigners.sort((a, b) => a.signerOrder - b.signerOrder);

    const customerSigners = eSigners.filter(d => d.s2sSigner === false);
    const counterSigners = eSigners.filter(d => d.s2sSigner === true);
    const signerWithReminderId = eSigners.reduce((result, current) => {
      if (current.status !== SIGNER_STATUS.AWAITING_SIGNATURE) {
        return result;
      }

      if (Object.keys(result).length === 0 || current.signerOrder < result.signerOrder) {
        return current;
      }

      return result;
    }, {}).id;

    const showCounterSigner = AuthContext.model.hasInternalPermissions && counterSigners.length > 0;
    const showCustomPdf = AuthContext.model.hasSalesOpsPermissions;

    const customPdf = showCustomPdf && eSignatureDocuments.find(doc => doc.documentType === CUSTOM_DOCUMENT);
    const showPdfWithCustomerSigner = customerSigners.length === 1 && !showCounterSigner && customPdf;

    const refreshBtnDisabled = status === 'COMPLETED';
    const reSubmitBtnDisabled = status === 'COMPLETED';

    const displayReSubmitButton =
      signatureReSubmitAllowed &&
      isEnvelopeAvailable &&
      [SIGNATURE_STATUS.FAILED, SIGNATURE_STATUS.CANCELED, SIGNATURE_STATUS.DECLINED].includes(status);

    const displayDownloadButton =
      AuthContext.model.hasInternalPermissions &&
      [ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER, ESIG_ENVELOPE_TYPES.AMENDMENT].includes(bundleType) &&
      SIGNATURE_STATUS.CANCELED !== status;
    const downloadDisabled =
      status !== SIGNATURE_STATUS.COMPLETED && AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER;

    const isPreviewAllowed = [SIGNATURE_STATUS.PENDING, SIGNATURE_STATUS.SENT].includes(status);
    const signaturePreviewUrlAndValues = getSignaturePreviewUrlAndValues(this.props.data);

    dialogProps.actions = [
      <button key="signature-back" id="signature-back" className="btn-signature-back" onClick={this.props.onClickBack}>
        <span className="icon-arrow-back" />
        {translator.getMessage('msg_back')}
      </button>,

      isSalesOps && (
        <button
          key="signature-refresh"
          id="signature-refresh"
          className="btn-signature-refresh text-btn"
          disabled={refreshBtnDisabled}
          onClick={() => this.props.onClickRefresh(id)}
        >
          {translator.getMessage('msg_refresh')}
        </button>
      ),

      isPreviewAllowed && [
        <button
          id="signature-preview-pdf"
          key="signature-preview-pdf"
          className="btn-signature-preview-pdf tertiary"
          type="submit"
          form="signature-preview-pdf-form"
        >
          {intl.formatMessage({
            id: 'msg_esig_history_dialog_row_buttons_preview',
          }) +
            ' ' +
            this.__getBundleLabel(bundleType) +
            ' PDF'}
        </button>,
        <HiddenFormHelper
          id="signature-preview-pdf-form"
          key="signature-preview-pdf-form"
          action={signaturePreviewUrlAndValues.url}
          values={signaturePreviewUrlAndValues.values}
        />,
      ],

      displayReSubmitButton ? (
        <button
          key="signature-re-submit"
          id="signature-re-submit"
          className="btn-signature-re-submit"
          disabled={reSubmitBtnDisabled}
          onClick={this.handleClickReSubmit}
        >
          {translator.getMessage('msg_re_submit')}
        </button>
      ) : null,

      displayDownloadButton
        ? [
            <BadgeButton
              key={'signature-download-' + this.props.id}
              id={'signature-download-' + this.props.id}
              className="btn-signature-details-download"
              btnClassName="tertiary"
              disabled={downloadDisabled}
              label={translator.getMessage('msg_download')}
              infoTooltip={downloadDisabled ? 'msg_available_after_all_signed' : ''}
              form={'signature-download-' + this.props.id + '-form'}
              hideInfoBadge
            />,
            <PdfHiddenForm
              id={'signature-download-' + this.props.id + '-form'}
              key={'signature-download-' + this.props.id + '-form'}
              endpoint={this.props.getSignatureDownloadEndpoint(this.props.data.id)}
            />,
          ]
        : null,
    ];

    dialogProps.title = (
      <div className="signature-details-title-wrapper">
        <header className="signature-details-title-header text-ellipsis">
          <span>
            {title} :: {UpperCaseEachWord(status)}
          </span>
          <br />
          <small>
            {intl.formatMessage({ id: 'msg_type' })}: <span>{this.__getBundleLabel(bundleType)}</span>
            {intl.formatMessage({ id: 'msg_updated' })}:{' '}
            <span>{dayjs(lastModified).format('MM/DD/YYYY h:mm:ss A')}</span>
            {intl.formatMessage({
              id: 'msg_esig_history_dialog_table_header_sent',
            })}
            : <span>{dayjs(creationDate).format('MM/DD/YYYY h:mm:ss A')}</span>
          </small>
        </header>
      </div>
    );

    dialogProps.onRequestClose = this.handleClickClose;

    const detailsSignersTableProps = {
      bounced,
      bouncedEmailAddress,
      bundleType,
      counterSigners,
      customerSigners,
      isSalesOps,
      onChangeSignerEmail,
      requestEmailChange,
      sendReminderToSigner,
      showCounterSigner,
      signerWithReminderId,
      signatureId: id,
      signatureStatus: status,
    };

    return (
      <Dialog {...dialogProps} isOpen={isVisible}>
        <DetailsTable data={data} showPdf={!showPdfWithCustomerSigner} customPdf={customPdf} />
        {showPdfWithCustomerSigner ? (
          <div className="signature-details customer-signer details-pdf">
            <DetailsSignersTable {...detailsSignersTableProps} />
            {this.pdfSection(customPdf)}
          </div>
        ) : (
          <DetailsSignersTable {...detailsSignersTableProps} />
        )}
      </Dialog>
    );
  }
}

export default injectIntl(Details);
