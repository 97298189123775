import { AppController } from './index';
import { AppStateContext, ErrorContext } from '../Context';
import IntegrationApi from '../api/IntegrationApi';
import AppRouter from '../AppRouter/AppRouter';
import { OrderInitiationModel, Validate } from '../Models';
import { ERROR_CONTEXT_TYPES, ORDER_TYPE_OPTIONS, ORDER_TYPES } from '../common/enums';
import bindMultiple from '../common/helpers/bindMultiple';
import config from '../config';
import $http from '../common/$http';

export default class SalesForceQuoteInitController extends AppController {
  static orderTypeOptionsMapping = {
    [ORDER_TYPES.NEW_CUSTOMER]: ORDER_TYPE_OPTIONS.NEW_CUSTOMER,
    [ORDER_TYPES.NEW_LOCATIONS]: ORDER_TYPE_OPTIONS.NEW_LOCATIONS,
    [ORDER_TYPES.ADD_ON]: ORDER_TYPE_OPTIONS.ADD_ON,
  };

  constructor(opportunityId) {
    super();

    this.opportunityId = opportunityId;
    this.opportunity = {};
    this.order = new OrderInitiationModel(this);

    this.order.isSalesForceOrder = true;

    bindMultiple(this, this.listenAppStateContext, this.onStartQuoteButtonClick);

    AppStateContext.model.addBroadcastListener(this.listenAppStateContext);
  }

  listenAppStateContext() {
    if (AppStateContext.model.appInitiated) {
      this.asyncInit();

      return true;
    }
  }

  get props() {
    return {
      order: this.order.toJS(),
      isLabelPartnerField: true,
      isLabelOrderTypeField: true,
      isLabelCustomerField: true,
      isLabelLocationField: true,
      onStartQuoteButtonClick: this.onStartQuoteButtonClick,
    };
  }

  async asyncInit() {
    // TODO: Implement proper error handling with try / catch blocks
    const [industryTypesResponse, opportunity] = await Promise.all([
      $http.instance.api.get(config.api.url + '/location/industryCodes'),
      IntegrationApi.getSalesForceOpportunity(this.opportunityId),
    ]);

    if (opportunity.existingQuoteId) {
      AppRouter.replace('/quotes/' + opportunity.existingQuoteId);

      return false;
    }

    if (industryTypesResponse?.data?.status === 200) {
      this.order.industryTypes = industryTypesResponse.data.content
        .filter(industryType => industryType.active)
        .sort((a, b) => a.sort - b.sort);
    }

    this.opportunity = opportunity;

    const {
      billingDealerId,
      customerId,
      customerName,
      dealerId,
      dealerName,
      locationId,
      locationName,
      quoteOrderType,
      name,
    } = opportunity;

    this.order.partners.push({
      agent: false,
      billingDealerId,
      dealerId,
      dealerName,
      text: dealerName,
      value: billingDealerId,
    });

    this.order.selectedPartnerId = billingDealerId;

    this.order.selectedAction = SalesForceQuoteInitController.orderTypeOptionsMapping[quoteOrderType];
    this.order.actions = {
      [this.order.selectedAction]: OrderInitiationModel.ACTIONS_MSG_LIST[this.order.selectedAction],
    };

    if (customerName) {
      this.order.customers.push({
        id: customerId,
        name: customerName,
        value: customerId,
        text: customerName,
      });

      this.order.selectedCustomerId = customerId;
    }

    if (locationId) {
      this.order.locations.push({
        id: locationId,
        customerId: customerId,
        name: locationName,
        value: locationId,
        text: locationName,
      });

      this.order.selectedLocationId = locationId;
    }

    await this.order.asyncLoadPartnerInfo({ dealerId });

    if ([ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON].includes(quoteOrderType)) {
      await Promise.all([
        this.order.asyncLoadSwitchvoxSipStation({ billingDealerId, customerId }),
        this.order.asyncLoadIsSangomaCXStandalone({ billingDealerId, customerId }),
      ]);
    }

    if (ORDER_TYPES.ADD_ON === quoteOrderType && locationId) {
      await Promise.all([
        this.order.asyncLoadLocationBusinessContinuity(locationId),
        this.order.asyncLoadIsNewLocationFinalized(locationId),
        this.order.asyncLoadIsStandaloneServiceNonUCaaS(locationId),
      ]);
    }

    if (name) {
      this.order.quoteTitle = name;
    }

    const { details, msgTemplateStringVariables } = Validate.validateSfResponse(opportunity);

    if (details) {
      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_insufficient_sf_data',
        details,
        msgTemplateStringVariables,
        autoHideDelay: 0,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.ERROR,
        priority: 9999,
      });
    }

    this.order.updateValidFields();
    this.renderView();
  }

  onStartQuoteButtonClick() {
    const { bcQuote, serviceType, selectedIndustryTypeId } = this.order;

    const search = ['serviceType=' + serviceType];

    if (bcQuote) {
      search.push('bc=1');
    }

    if (this.opportunity.quoteOrderType === ORDER_TYPES.NEW_CUSTOMER) {
      search.push('industryCodeId=' + selectedIndustryTypeId);
    }

    AppRouter.push({
      pathname: '/opportunity/' + encodeURIComponent(this.opportunityId) + '/quote',
      search: search.length ? '?' + search.join('&') : '',
    });
  }
}
