import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextControl } from '../../Controls';

class CustomerName extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onStateChange: PropTypes.func,
    value: PropTypes.string,
    errorText: PropTypes.string,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    tooltipText: PropTypes.string,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
  };

  render() {
    const { value, errorText, lockedState, disabledState } = this.props;

    return (
      <div className="customer-name-component">
        <TextControl
          {...{ lockedState, disabledState, value, errorText }}
          id="customerName"
          label="msg_customer"
          maxLength={65}
          onChange={this.props.onChange}
          onStateChange={this.props.onStateChange}
          tooltipText={this.props.tooltipText}
          changeOnBlur
          required
        />
      </div>
    );
  }
}

export default CustomerName;
