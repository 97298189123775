import React from 'react';
import Cell from './Cell';

const CELL_TYPES = ['id', 'description', 'cost', 'usagecap'];

const Row = ({ data }) => (
  <tr className="markers-table-row" key={'marker-row-' + data.markerType}>
    {CELL_TYPES.map(cellType => (
      <Cell key={`marker-cell-${cellType}-${data.markerType}`} data={data} cellType={cellType} />
    ))}
  </tr>
);

export default Row;
