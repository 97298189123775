const SORT_DIRECTION = {
  asc: 1,
  desc: -1,
};

function sortNumberFunction(a, b, direction) {
  return direction * (a - b);
}

function sortStringFunction(a, b, direction) {
  if (typeof a !== 'string') {
    a = '';
  }

  if (typeof b !== 'string') {
    b = '';
  }

  return direction * a.localeCompare(b);
}

function sortObject(sortBy, direction, a, b) {
  a = a[sortBy];
  b = b[sortBy];

  if (!['string', 'number'].includes(typeof a) && !['string', 'number'].includes(b)) {
    return 0;
  }

  return typeof a === 'string' || typeof b === 'string'
    ? sortStringFunction(a, b, direction)
    : sortNumberFunction(a, b, direction);
}

export const SortArrayOfObjects = (array, sortBy, direction = SORT_DIRECTION.asc) => {
  if (typeof direction === 'string') {
    direction = SORT_DIRECTION[direction.toLowerCase()] || SORT_DIRECTION.asc;
  }

  return array.sort(sortObject.bind(null, sortBy, direction));
};
