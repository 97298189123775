import React, { Component } from 'react';
import { object, func, array, string, bool } from 'prop-types';
import ORDER_TYPES from '../../common/enums/orderTypes.enums';
import Validate from '../../Models/Validate';
import translator from '../../common/translate';
import CopyContacts from './CopyContacts';
import { CONTACT_PROPS, PROPS_TO_SKIP, PROPS_TO_SKIP_WLW } from './ContactSectionProps';
import getFieldVisibility from './helpers/getFieldVisibility';
import getControlComponentClass from './helpers/getControlComponentClass';
import ValidateEmail from './nested/ValidateEmail';
import './css/contact-sections.css';
import ExternalLink from '../ExternalLink';
import apiData from '../../Storage/apiData';
import { SelectControl } from '../Controls';

const notSyncedFields = ['decentralizedAccounting', 'contactEmail'];

class ContactSection extends Component {
  static defaultProps = {
    copyToAllLocactions: () => {},
    disabledState: false,
    handleCopyContacts: () => {},
    isSalesOps: false,
    lockedState: false,
    modelName: '',
    optionsData: {},
    orderType: '',
    requiredForSubmission: [],
    section: '',
    validateEmail: () => {},
  };

  static propTypes = {
    contactModel: object.isRequired,
    copyToAllLocactions: func,
    disabledState: bool,
    handleCopyContacts: func,
    isSalesOps: bool,
    lockedState: bool,
    modelName: string,
    optionsData: object,
    orderType: string,
    requiredForSubmission: array,
    section: string,
    validateEmail: func,
  };

  constructor(props) {
    super(props);

    this.handleCopyToAllLocations = this.handleCopyToAllLocations.bind(this);
  }

  getFieldProps(key, field) {
    const { contactModel, disabledState, isSalesOps, lockedState, orderType, isWhiteLabel, section, modelName } =
      this.props;
    let _notSyncedFields = notSyncedFields;

    if (isWhiteLabel && section === 'LOCATION_BILLING') {
      _notSyncedFields = notSyncedFields.filter(f => f !== 'contactEmail');
    }

    const _lockedState = _notSyncedFields.includes(key)
      ? field.locked || lockedState
      : contactModel.syncCheckBoxValue || field.locked || lockedState;
    const _disabledState = _notSyncedFields.includes(key)
      ? disabledState
      : contactModel.syncCheckBoxValue || disabledState;
    const value = contactModel[key];
    const controlProps = Object.assign({}, field, {
      value,
      changeOnBlur: true,
      disabledState: _disabledState,
      lockedState: _lockedState,
      onChange: this.handleOnChange.bind(this, key),
      options: this.getOptions(field.optionsType),
    });

    if (field.id === 'billToId') {
      controlProps.lockedState = field.locked || lockedState;
      controlProps.disabledState = field.disabled || disabledState || contactModel.disableBillToId;
    } else if (field.id === 'decentralizedAccounting' && ORDER_TYPES.NEW_LOCATIONS === orderType && !isSalesOps) {
      controlProps.lockedState = true;
    }

    if (modelName === 'shippingInfo') {
      controlProps.forbiddenCharactersList = '|';
    }

    return controlProps;
  }

  getControlContent(key, field) {
    const {
      contactModel,
      orderType,
      requiredForSubmission,
      section,
      validateEmail,
      openConfirmModal,
      disabledState,
      lockedState,
      isEmailValidationRequested,
    } = this.props;

    const value = contactModel[key];
    const fieldProps = this.getFieldProps(key, field);
    const Input = getControlComponentClass(field.type);
    const showField = getFieldVisibility({
      orderType,
      fieldId: field.id,
      isMOBillingSection: section === 'MASTER_ORDER_BILLING',
    });

    if (!showField) {
      return null;
    }

    if (key === 'validateEmail') {
      if (!apiData.properties.sendgrid.enabled) {
        return null;
      }

      return (
        <ValidateEmail
          email={contactModel.contactEmail}
          validateEmail={validateEmail}
          openConfirmModal={openConfirmModal}
          contactModel={contactModel}
          key={key}
          disabledState={disabledState}
          lockedState={lockedState}
          isEmailValidationRequested={isEmailValidationRequested}
        />
      );
    }

    let label = field.label;

    if (key === 'zip') {
      // ID prop is set to msg_ string which is read in AbstractControl later
      label = (
        <ExternalLink
          id={field.label}
          path="https://tools.usps.com/zip-code-lookup.htm?byaddress"
          title={translator.getMessage(label)}
        />
      );
    }

    return (
      <Input
        {...fieldProps}
        errorText={Validate.customerContact(value, key).text}
        label={label}
        requiredBlue={requiredForSubmission.includes(field.id)}
        type={field.type}
        key={key}
      />
    );
  }

  getOptions(optionsType) {
    const { optionsData } = this.props;

    if (optionsType === 'billTo') {
      return optionsData[optionsType];
    }

    return (optionsData[optionsType] || []).map(({ id, name }) => ({
      value: id,
      text: name,
    }));
  }

  handleCopyToAllLocations() {
    const { contactModel, handleCopyToAllLocations, modelName, section } = this.props;

    const sectionToUse = section ? section.split('_').pop() : 'SERVICE';
    const copyFromValue = contactModel.copyFromValue || 'COPY_FROM_MASTER_ORDER_' + sectionToUse;

    handleCopyToAllLocations(modelName, copyFromValue);
  }

  handleOnChange(parameterName, parameterValue) {
    const { contactModel } = this.props;

    if (contactModel[parameterName] !== parameterValue) {
      contactModel.onChange(parameterName, parameterValue);
    }
  }

  render() {
    const {
      contactModel,
      disabledState,
      handleCopyContacts,
      handleCopyBillingWlw,
      lockedState,
      modelName,
      orderType,
      section,
      isWhiteLabel,
    } = this.props;

    const controls = [];
    const isAddOnOrder = orderType === ORDER_TYPES.ADD_ON;
    const isMOBillingSection = section === 'MASTER_ORDER_BILLING';
    const isServiceSection = modelName === 'serviceInfo';

    // undefined === serviceInfo Master Order
    if ((!section || isMOBillingSection) && !isAddOnOrder && !(section === 'MASTER_ORDER_BILLING' && isWhiteLabel)) {
      controls.push(
        <div key="button-copy-all" className="button-wrapper">
          <button className="button-copy-all" disabled={disabledState} onClick={this.handleCopyToAllLocations}>
            {translator.getMessage('msg_copy_to_all_locations')}
          </button>
        </div>
      );
    }

    const billToField = CONTACT_PROPS.billingInfo.billToId;

    if (['MASTER_ORDER_BILLING', 'LOCATION_BILLING'].includes(section)) {
      controls.push(
        <div className="input-abstract-component" key="recurringServiceBillingCharges">
          <h5>{translator.getMessage('msg_non_recurring_charges')}</h5>
        </div>
      );

      if (!PROPS_TO_SKIP.includes(billToField.id) && !PROPS_TO_SKIP_WLW.includes(billToField.id)) {
        controls.push(
          <SelectControl
            {...this.getFieldProps(billToField.id, billToField)}
            key={billToField.id}
            requiredBlue={
              section === 'LOCATION_BILLING' || (section === 'MASTER_ORDER_BILLING' && orderType === ORDER_TYPES.ADD_ON)
            }
          />
        );
      }

      if (!isAddOnOrder) {
        controls.push(
          <div className="input-abstract-component" key="recurringServiceBillingInfo">
            <h5>{translator.getMessage('msg_recurring_service_billing_information')}</h5>
          </div>
        );
      }
    }

    if (section === 'MASTER_ORDER_BILLING') {
      const { decentralizedAccounting: field } = CONTACT_PROPS[modelName];
      const content = this.getControlContent('decentralizedAccounting', field);
      controls.push(content);
    }

    if (section && !(isMOBillingSection && isAddOnOrder) && !(section === 'LOCATION_BILLING' && isWhiteLabel)) {
      controls.push(
        <CopyContacts
          key="copy-contacts"
          contactModel={contactModel}
          disabledState={disabledState}
          handleCopyContacts={handleCopyContacts}
          handleCopyBillingWlw={handleCopyBillingWlw}
          lockedState={lockedState}
          modelName={modelName}
          onChange={contactModel.onChange}
          section={section}
          isWhiteLabel={isWhiteLabel}
        />
      );
    }

    Object.entries(CONTACT_PROPS[modelName]).forEach(([key, value]) => {
      if (billToField.id === key) {
        return;
      }

      if (PROPS_TO_SKIP.includes(key) || (isServiceSection && isWhiteLabel && PROPS_TO_SKIP_WLW.includes(key))) {
        return;
      }

      controls.push(this.getControlContent(key, value));
    });

    return <div>{controls}</div>;
  }
}

export default ContactSection;
