import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import translator from '../../common/translate/index';
import { getTranslateString } from '../../common/helpers/getTranslateString';

class MenuItem extends React.Component {
  static propTypes = {
    key: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
    className: PropTypes.string,
    primaryText: PropTypes.string,
    disabled: PropTypes.bool,
    dropDownItems: PropTypes.object,
  };

  render() {
    const { primaryText, intl, id, key, onClick, className } = this.props;

    const title =
      typeof primaryText === 'string'
        ? getTranslateString(primaryText, intl)
        : primaryText && primaryText.props && intl.formatMessage({ id: primaryText.props.id });

    const itemProps = {
      id,
      onClick,
      className,
      title,
    };

    return (
      <div>
        <div key={key} {...itemProps}>
          <span>{translator.getMessage(primaryText)}</span>
        </div>
      </div>
    );
  }
}

export default injectIntl(MenuItem);
