import React from 'react';
import apiData from '../../Storage/apiData';
import { AppStateContext } from '../../Context';
import config from '../../config';

export default class VersionInfoComponent extends React.Component {
  formatApiVersion(versionString) {
    const matches = versionString.match(/^([A-z]+):\s(.*)/);
    const versionParsed = Array.isArray(matches) && matches[1] && matches[2];

    if (!versionParsed) {
      return null;
    }

    const [, label, version] = matches;

    return (
      <label>
        {label}:&nbsp;{version}
      </label>
    );
  }

  render() {
    return (
      <AppStateContext.Consumer>
        {() => (
          <React.Fragment>
            <section className="ui-version-container">
              <label>UI:&nbsp;{config.version}</label>&nbsp;
              {this.formatApiVersion(apiData.version)}
            </section>
          </React.Fragment>
        )}
      </AppStateContext.Consumer>
    );
  }
}
