import React from 'react';
import { AuthContext, ContextConnect, ContextModel } from './';
import { PRICEBOOK_TAGS } from '../common/enums';
import TypeConverter from '../common/helpers/TypeConverter';
import config from '../config';

const context = React.createContext();
const FLAGS_WITH_MULTIPLE_VALUES = ['pricebookTag', 'suppressWarningByName', 'availableOpportunityProductType'];
const DEFAULT_PROPERTIES_FLAG = 'defaultUiPropertyToValue';
const DEFAULT_VALUE_REGEX = /^([^_\d][A-z0-9_]+),(.+)$/;

class PriceBookContextModel extends ContextModel {
  static PACKAGE_IDS_MAPPING_IF_NOT_AVAILABLE = {
    1: [2, 3],
    2: [1, 3],
    3: [4, 1],
    4: [3, 1],
  };

  constructor() {
    super();

    this._availableOpportunityProductTypeSelectControlOptions = [];
    this._listOfAvailablePackageIds = [];

    this._flags = {};
    this.pricebookId = null;
    this.version = null;
    this.pricebookVersionId = null;
    this.draftNo = null;
    this.defaultCustomerOrderModelValues = {};

    // This will trigger flags setter to initiate an empty arrays for multi-value flags
    this.flags = {};
  }

  get flags() {
    return this._flags;
  }

  set flags(flagsDataArray) {
    this._flags = {};

    for (let i = 0; i < FLAGS_WITH_MULTIPLE_VALUES.length; i++) {
      const flagName = FLAGS_WITH_MULTIPLE_VALUES[i];

      this._flags[flagName] = [];

      if (flagName === 'availableOpportunityProductType') {
        this._availableOpportunityProductTypeSelectControlOptions = [];
      }
    }

    for (let i = 0; i < flagsDataArray.length; i++) {
      const flagData = flagsDataArray[i];
      const flagName = flagData.flag.name;
      const value = TypeConverter(flagData.valueAsString);

      if (flagName === DEFAULT_PROPERTIES_FLAG) {
        const [, propertyName, propertyValue] = flagData.valueAsString.match(DEFAULT_VALUE_REGEX);
        this.defaultCustomerOrderModelValues[propertyName] = TypeConverter(propertyValue);

        continue;
      }

      if (FLAGS_WITH_MULTIPLE_VALUES.includes(flagName)) {
        this._flags[flagName].push(value);

        continue;
      }

      this._flags[flagName] = value;
    }

    this._flags.availableOpportunityProductType.sort();
  }

  clearPBContext() {
    this.pricebookId = null;
    this.version = null;
    this.pricebookVersionId = null;
    this.draftNo = null;
    this.flags = {};
    this.defaultCustomerOrderModelValues = {};
    this._listOfAvailablePackageIds = [];
  }

  get isSupportsRental() {
    return this.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_RENTAL_GREAT_AMERICA);
  }

  get isSupportsSwitchvoxSIPStation() {
    return this.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SWITCHVOX_SIP_STATION);
  }

  get isSupportsSangomaCx() {
    return this.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SANGOMA_CX);
  }

  get availableOpportunityProductTypeSelectControlOptions() {
    if (!this._availableOpportunityProductTypeSelectControlOptions.length) {
      this._availableOpportunityProductTypeSelectControlOptions = this.flags.availableOpportunityProductType.map(
        opportunityProductType => ({
          text: opportunityProductType,
          value: opportunityProductType,
        })
      );
    }

    return this._availableOpportunityProductTypeSelectControlOptions;
  }

  get listOfAvailablePackageIds() {
    if (this._listOfAvailablePackageIds.length) {
      return this._listOfAvailablePackageIds;
    }

    for (let packageId = 1; packageId <= 4; packageId++) {
      if (typeof this.flags['hidePackage' + packageId] === 'string') {
        const flagValueLowercase = this.flags['hidePackage' + packageId].toLowerCase();

        if (
          flagValueLowercase === 'force' ||
          (flagValueLowercase === 'true' && !AuthContext.model.hasSalesOpsPermissions)
        ) {
          continue;
        }
      }

      this._listOfAvailablePackageIds.push(packageId);
    }

    return this._listOfAvailablePackageIds;
  }

  mapPackageIdIfNotAvailable(packageId) {
    if (typeof packageId !== 'number' || packageId < 1 || packageId > 4) {
      throw new Error('packageId should be numeric value in range 1...4');
    }

    if (this.listOfAvailablePackageIds.includes(packageId)) {
      return packageId;
    }

    const options = PriceBookContextModel.PACKAGE_IDS_MAPPING_IF_NOT_AVAILABLE[packageId];

    for (let i = 0; i < options.length; i++) {
      const packageId = options[i];

      if (this.listOfAvailablePackageIds.includes(packageId)) {
        return packageId;
      }
    }

    if (config.showConsoleLog) {
      console.warn('All of packageIds mapping options are unavailable in current Pricebook');
    }

    return this.getDefaultPackageId();
  }

  getDefaultPackageId() {
    const defaultPackage = this.flags.defaultPackage;

    if (this.listOfAvailablePackageIds.includes(defaultPackage)) {
      return defaultPackage;
    }

    return this.listOfAvailablePackageIds[0];
  }

  hasPricebookTag(tagName) {
    return this.flags.pricebookTag.includes(tagName);
  }
}

export const model = new PriceBookContextModel();
const Provider = ContextConnect(context, model);
const Consumer = context.Consumer;

const PriceBookContext = {
  Provider,
  Consumer,
  model,
};

export default PriceBookContext;
