import React, { useEffect, useRef } from 'react';
import translator from '../../../../common/translate/index';
import Reassignment from './Reassignment';

const ReassignmentsTooltip = ({ reassignments }) => {
  const tooltipRef = useRef();

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const { left, width } = tooltipRef.current.parentNode.getBoundingClientRect();

    // reverse tooltip if it is located on second half of screen
    if (windowWidth / 2 < left) {
      tooltipRef.current.style.transform = 'translateX(-' + width + 'px)';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="reassignments-tooltip" ref={tooltipRef}>
      <span>{translator.getMessage('msg_reassignments_title')}</span>
      {reassignments.map(reassignment => (
        <Reassignment reassignment={reassignment} key={'reassignment-' + reassignment.id} />
      ))}
    </div>
  );
};

export default ReassignmentsTooltip;
