import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { v4 as UUID } from 'uuid';
import './select-input.css';
import bindMultiple from '../../../common/helpers/bindMultiple';
import { getTranslateString } from '../../../common/helpers/getTranslateString';
import { KEY_CODES } from '../../../common/enums';
import Option from './Option';

class SelectInput extends Component {
  static propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
        text: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ),
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    value: null,
    options: [],
  };

  static blurDelay = 60;

  static searchDelay = 1500;

  // ev.keyCode is deprecated.
  // Use an alphabet instead for limiting search to certain characters
  static searchAlphabet = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ' ',
    '-',
    '_',
    '!',
    '@',
    // English
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    // Spanish
    'á',
    'à',
    'ä',
    'é',
    'è',
    'ë',
    'í',
    'ì',
    'ï',
    'ñ',
    'ó',
    'ò',
    'ö',
    'ú',
    'ù',
    'ü',
    // French
    'â',
    'ç',
    'ê',
    'é',
    'è',
    'ë',
    'î',
    'ï',
    'ô',
    'ù',
    '¿',
    '¡',
  ];

  constructor() {
    super(...arguments);

    this.state = {
      expanded: false,
      highlightIndex: null,
    };

    this.blurTimeout = null;

    this.searchPhrase = '';
    this.searchTimeout = null;

    bindMultiple(
      this,
      this.handleChange,
      this.handleKeyDown,
      this.handleSelectFocus,
      this.handleFocus,
      this.handleBlur,
      this.triggerBlur,
      this.resetSearchPhrase
    );
  }

  get optionIdPrefix() {
    return this.props.id + '-option-';
  }

  clearBlurTimeout() {
    if (this.blurTimeout !== null) {
      clearTimeout(this.blurTimeout);
    }
  }

  handleSelectFocus() {
    this.clearBlurTimeout();
    this.showDropDown();
  }

  handleFocus() {
    this.clearBlurTimeout();
  }

  handleBlur() {
    if (this.blurTimeout !== null) {
      clearTimeout(this.blurTimeout);
    }

    this.blurTimeout = setTimeout(this.triggerBlur, SelectInput.blurDelay);
  }

  triggerBlur() {
    this.hideDropDown();
  }

  handleKeyDown(ev) {
    if (ev.code === KEY_CODES.Escape) {
      // Prevent event propagation only in case if drop-down menu is visible
      // On second ESC tap it will propagate event to higher order components
      // and for Ex. will close an opened Dialog
      if (this.state.expanded) {
        ev.stopPropagation();
      }

      return this.hideDropDown();
    }

    const key = ev.key.toLowerCase();

    if (SelectInput.searchAlphabet.includes(key)) {
      ev.preventDefault();

      this.handleSearchInput(key);

      return false;
    }

    const optionsLength = this.props.options.length;
    const firstActiveOptionIndex = this.getNextActiveOptionIndex(null);
    const lastActiveOptionIndex = this.getPrevActiveOptionIndex(optionsLength);

    if (ev.code === KEY_CODES.Tab) {
      // Trigger default Shift+Tab event - jump to previous focusable element
      // if no options "focused" yet
      if (ev.shiftKey && this.state.highlightIndex === null) {
        return true;
      }

      // Trigger default Tab event - jump to focusable element
      // if very last active option is "focused" or drop-down collapsed
      if ((this.state.highlightIndex === lastActiveOptionIndex && !ev.shiftKey) || !this.state.expanded) {
        return true;
      }

      ev.preventDefault();

      // Remove option "focus" on Shift+Tab if very first option selected
      if (this.state.highlightIndex === 0 && ev.shiftKey) {
        return this.setState(() => ({ highlightIndex: null }));
      }

      // "Focus" on very first option with Tab if no options are focused yet
      if (this.state.highlightIndex === null) {
        return this.setState(state => ({ highlightIndex: this.getNextActiveOptionIndex(state.highlightIndex) }));
      }

      // Move "focus" either up or down on Shift+Tab or Tab press respectively
      if (this.state.highlightIndex > 0 || this.state.highlightIndex < lastActiveOptionIndex) {
        return this.setState(
          state => ({
            highlightIndex: ev.shiftKey
              ? this.getPrevActiveOptionIndex(state.highlightIndex)
              : this.getNextActiveOptionIndex(state.highlightIndex),
          }),
          this.scrollIntoView
        );
      }

      return false;
    }

    ev.preventDefault();

    if (ev.code === KEY_CODES.Arrow.Down) {
      const nextIndex = this.getNextActiveOptionIndex();

      return this.setState(() => ({ highlightIndex: nextIndex }), this.scrollIntoView);
    }

    if (ev.code === KEY_CODES.Arrow.Up) {
      // "Focus" on very last option with Arrow Up if no options are focused yet
      if (this.state.highlightIndex === null) {
        return this.setState(() => ({ highlightIndex: lastActiveOptionIndex }), this.scrollIntoView);
      }

      const prevIndex = this.getPrevActiveOptionIndex();

      return this.setState(() => ({ highlightIndex: prevIndex }), this.scrollIntoView);
    }

    if (ev.code === KEY_CODES.Arrow.Left) {
      return this.setState(() => ({ highlightIndex: firstActiveOptionIndex }), this.scrollIntoView);
    }

    if (ev.code === KEY_CODES.Arrow.Right) {
      return this.setState(() => ({ highlightIndex: lastActiveOptionIndex }), this.scrollIntoView);
    }

    if (ev.code === KEY_CODES.Enter) {
      // Expand drop-down on Enter if Select Input component has focus
      if (!this.state.expanded) {
        return this.showDropDown();
      }

      // Hide drop-down on Enter if no options are highlighted yet
      if (this.state.highlightIndex === null) {
        return this.hideDropDown();
      }

      // Change value to highlighted option
      return this.handleChange(this.props.options[this.state.highlightIndex].value);
    }
  }

  scrollIntoView() {
    const highlightIndex = this.state.highlightIndex;

    if (highlightIndex === null) {
      return null;
    }

    const option = document.getElementById(this.optionIdPrefix + highlightIndex);

    // Shall not happen may be used as debugger breakpoint
    if (!option) {
      return false;
    }

    const container = option.parentElement;

    // Shall not happen may be used as debugger breakpoint
    if (!container) {
      return false;
    }

    if (container.offsetHeight < container.scrollHeight) {
      option.scrollIntoView();

      return true;
    }

    return false;
  }

  handleChange(nextValue) {
    if (typeof this.props.onChange === 'function' && nextValue !== this.props.value) {
      this.props.onChange(nextValue);
    }

    this.hideDropDown();
  }

  showDropDown() {
    this.setState(() => ({ expanded: true }));
  }

  hideDropDown() {
    this.setState(() => ({
      expanded: false,
      highlightIndex: null,
    }));
  }

  handleMouseOverOption(optionIndex) {
    this.setState(() => ({ highlightIndex: optionIndex }));
  }

  handleSearchInput(key) {
    if (this.searchTimeout !== null) {
      clearTimeout(this.searchTimeout);
    }

    this.searchPhrase += key;

    const optionIndex = this.props.options.findIndex(option =>
      getTranslateString(option.text, this.props.intl).toLowerCase().startsWith(this.searchPhrase.toLowerCase())
    );

    if (optionIndex !== -1) {
      this.setState(() => ({ highlightIndex: optionIndex }), this.scrollIntoView);
    }

    this.searchTimeout = setTimeout(this.resetSearchPhrase, SelectInput.searchDelay);
  }

  resetSearchPhrase() {
    this.searchPhrase = '';
  }

  getNextActiveOptionIndex(startIndex = this.state.highlightIndex) {
    if (startIndex === null) {
      startIndex = -1;
    }

    if (startIndex === this.props.options.length - 1) {
      return startIndex;
    }

    const index = this.props.options.findIndex((option, index) => !option.disabled && index > startIndex);

    return index !== -1 ? index : startIndex;
  }

  getPrevActiveOptionIndex(startIndex = this.state.highlightIndex) {
    if (startIndex === null) {
      return null;
    }

    if (startIndex === 0) {
      return 0;
    }

    const index = this.props.options.findLastIndex((option, index) => !option.disabled && index < startIndex);

    return index !== -1 ? index : startIndex;
  }

  render() {
    const id = this.props.id || 'select-input-' + UUID();
    const { value, options, placeholder, intl, disabled } = this.props;
    const selectedOption = Array.isArray(options) ? options.find(option => option.value === value) : null;
    const classNames = ['select-input'];

    if (this.state.expanded && !disabled) {
      classNames.push('expanded');
    }

    if (disabled) {
      classNames.push('disabled');
    }

    return (
      <div
        id={id}
        className={classNames.join(' ')}
        tabIndex={disabled ? -1 : 0}
        onFocus={disabled ? undefined : this.handleSelectFocus}
        onBlur={disabled ? undefined : this.handleBlur}
        onKeyDown={disabled ? undefined : this.handleKeyDown}
      >
        {this.state.expanded && !disabled ? (
          <ul className="options">
            {Array.isArray(options)
              ? options.map((option, index) => (
                  <Option
                    key={this.optionIdPrefix + index}
                    id={this.optionIdPrefix + index}
                    text={getTranslateString(option.text, intl)}
                    disabled={option.disabled}
                    highlighted={this.state.highlightIndex === index}
                    onClick={() => this.handleChange(option.value)}
                    onMouseOver={() => this.handleMouseOverOption(index)}
                  />
                ))
              : null}
          </ul>
        ) : null}
        <div className="selected-option-field">
          {placeholder && !value ? (
            <span className="placeholder" title={getTranslateString(placeholder, intl)}>
              {getTranslateString(placeholder, intl)}
            </span>
          ) : null}
          {selectedOption ? (
            <span className="selected-option-label" title={getTranslateString(selectedOption.text, intl)}>
              {getTranslateString(selectedOption.text, intl)}
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}

export default injectIntl(SelectInput);
