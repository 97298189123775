import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ToggleControl } from '../Controls';

class SmartOfficeLocationToggle extends Component {
  constructor(props) {
    super(props);

    this.handleOnChange = this.handleOnChange.bind(this);
  }

  static propTypes = {
    value: PropTypes.bool,
    onChange: PropTypes.func,
    disabledState: PropTypes.bool,
    lockedState: PropTypes.bool,
  };

  handleOnChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange('smartOffice', value);
    }
  }

  render() {
    return (
      <div className="smart-office-location-toggle">
        <ToggleControl {...this.props} onChange={this.handleOnChange} label="msg_smart_office_location" />
      </div>
    );
  }
}

export default SmartOfficeLocationToggle;
