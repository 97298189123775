import CommonSectionModel from './CommonSectionModel';
import bindMultiple from '../../common/helpers/bindMultiple';
import { PartnerContextModel } from '../../Context';
import { PARTNER_TYPES, QUOTE_STATUS } from '../../common/enums';

class PartnerInformationSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'partner-information-section';
    this.title = 'msg_partner_section';
    this.isGeneralSection = true;

    bindMultiple(this, this.onDealerContactUserIdChange, this.onSOBOControlsChange);
  }

  get lockedState() {
    return super.lockedState || this.appState.switchvoxWlwDisabled;
  }

  get partnerType() {
    return PartnerContextModel.type === PARTNER_TYPES.STARCLOUD_PLUS_4_0 ? '' : PartnerContextModel.type;
  }

  get partnerTier() {
    return PartnerContextModel.tier;
  }

  get disableSOBO() {
    return this.customerOrder.quoteStatus !== QUOTE_STATUS.OPEN;
  }

  onDealerContactUserIdChange(value, prop) {
    this.customerOrder.onChange('dealerContactUserId', value);
  }

  onSOBOControlsChange(value, prop) {
    this.customerOrder.onChange(prop, value);
  }
}

export default PartnerInformationSectionModel;
