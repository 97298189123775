import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import AbstractInput from '../AbstractInput';

class TextInput extends PureComponent {
  static propTypes = {
    ...AbstractInput.propTypes,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.handleCommit = this.handleCommit.bind(this);
  }

  handleCommit(stringValue) {
    // Override behaviour by Higher order Component
    if (typeof this.props.onCommit === 'function') {
      return this.props.onCommit(stringValue);
    }

    // Else use default behaviour
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(stringValue.trim());

      return true;
    }

    return false;
  }

  render() {
    const classNames = ['text-input-field'];

    if (typeof this.props.className === 'string' && this.props.className.trim() !== '') {
      classNames.push(this.props.className);
    }

    return <AbstractInput {...this.props} className={classNames.join(' ')} onCommit={this.handleCommit} />;
  }
}

export default TextInput;
