import React, { Component } from 'react';
import { FrameComponent, NotFoundComponent } from '../components/views';

class NotFoundLayout extends Component {
  render() {
    return (
      <FrameComponent {...this.props}>
        <NotFoundComponent {...this.props} />
      </FrameComponent>
    );
  }
}

export default NotFoundLayout;
