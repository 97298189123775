import { ORDER_TYPES, QUOTE_STATUS } from '../../common/enums';
import { AuthContext } from '../../Context';
import ToolbarButtonModel from './ToolbarButtonModel';

class FinalizeModel extends ToolbarButtonModel {
  get disabled() {
    return this.isFinalizedAndSignedMO && AuthContext.model.hasInternalPermissions
      ? this.unFinalizeBtnDisabled || this.showShippedAddonTooltipOnUnFinalizeBtn
      : this.isActiveQuoteValidationRequested ||
          this.finalizeBtnDisabled ||
          super.disabled ||
          this.appState.switchvoxWlwDisabled;
  }

  get errors() {
    return this.isFinalizedAndSignedMO && AuthContext.model.hasInternalPermissions
      ? this.unFinalizeBtnErrors
      : this.finalizeBtnErrors;
  }

  get errorsCnt() {
    return !this.isFinalizedAndSignedMO
      ? this.requiredFinalizeFields &&
          this.requiredFinalizeFields.fields.length + this.requiredFinalizeFields.separateLineFields.length
      : 0;
  }

  get label() {
    if (!this.isFinalizedAndSignedMO) {
      return 'msg_finalize_order';
    }

    return AuthContext.model.hasInternalPermissions ? 'msg_un_finalize' : 'msg_finalized';
  }

  get visible() {
    return (
      !this.isNotFinalizedQuoteHasExeptions &&
      !this.areLocationsDone &&
      !this.isESignatureFlowAllowed &&
      this.isNotQuoteLoading &&
      this.masterOrder.quoteStatus !== QUOTE_STATUS.CLOSED
    );
  }

  get showShippedAddonTooltipOnUnFinalizeBtn() {
    const { orderType, shipped } = this.masterOrder;
    return AuthContext.model.hasSalesOpsPermissions && orderType === ORDER_TYPES.ADD_ON && this.isFinalizedMO && shipped;
  }

  get infoTooltip() {
    return this.showShippedAddonTooltipOnUnFinalizeBtn ? 'msg_shipped' : null;
  }

  get isFinalizedAndSignedMO() {
    const { quoteStatus } = this.masterOrder;
    return [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SIGNED_AND_FINALIZED].includes(quoteStatus);
  }

  get btnClassName() {
    let className = 'toolbar-btn finalize-btn large dark-bg';

    if (this.isFinalizedAndSignedMO && AuthContext.model.hasInternalPermissions) {
      className += ' critical';
    } else {
      className += ' primary';
    }

    return className;
  }
}

export default FinalizeModel;
