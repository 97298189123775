import SangomaCxSettings from '../../../../SangomaCxSettings';
import CollapsableCard from '../../../../CollapsableCard';

const SangomaCxSettingsWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //SangomaCxSettings props
  customerOrder,
  ...sangomaCxSettingsProps
}) => {
  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      <SangomaCxSettings customerOrder={customerOrder} {...sangomaCxSettingsProps} />
    </CollapsableCard>
  );
};

export default SangomaCxSettingsWrapper;
