import * as formatter from '../../common/helpers/format';
import { ORDER_TYPES, PACKAGE_TYPE_IDS } from '../../common/enums';
import CommonPackageModel from './CommonPackageModel';
import { AuthContext } from '../../Context';

class PackageOneModel extends CommonPackageModel {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this.id = PACKAGE_TYPE_IDS.PACKAGE_ONE;
    this.nrc = { value: 'NA', className: '' };
    this.packageClassName = 'package-1';
  }

  get available() {
    const { locationPackageType, orderType } = this.customerOrder;

    if (orderType !== ORDER_TYPES.ADD_ON || !locationPackageType) {
      return true;
    }

    return AuthContext.model.hasSalesOpsPermissions || locationPackageType === this.id;
  }

  get rc() {
    return {
      className: this.animatedClassName,
      value: formatter.currency(this.summaryValues['allInclusive1'], 0),
    };
  }
}

export default PackageOneModel;
