import React from 'react';
import formatString from '../../../common/helpers/format/formatString';

const ErrorsArray = ({ errors, formatMessage }) => (
  <ul>
    {errors.map((error, i) =>
      Array.isArray(error) ? (
        error.map(arrayError => <li key={arrayError}>{formatString(arrayError, formatMessage)}</li>)
      ) : (
        <li key={error}>{formatString(error, formatMessage)}</li>
      )
    )}
  </ul>
);

export default ErrorsArray;
