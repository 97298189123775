export const REQUIRED_FOR_FINALIZE_NEW_NEW = {
  serviceInfo: {
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
    btn: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactOfficePhone: '',
  },
  billingInfo: {
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: '',
    zip: '',
    countryId: '',
    contactOfficePhone: '',
    contactEmail: '',
  },
  isSpiffConfirmationValid: false,
};

export const REQUIRED_FOR_FINALIZE_NEW_LOCATIONS = {
  serviceInfo: {
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
    btn: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactOfficePhone: '',
  },
  billingInfo: {
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: '',
    zip: '',
    countryId: '',
    contactOfficePhone: '',
    contactEmail: '',
  },
  isSpiffConfirmationValid: false,
};

export const REQUIRED_FOR_FINALIZE_ADD_ON = {
  serviceInfo: {
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
    btn: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactOfficePhone: '',
  },
  billingInfo: {
    billToId: '',
  },
  shippingInfo: {
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: '',
    countryId: '',
    zip: '',
    companyName: '',
  },
  isSpiffConfirmationValid: false,
};

export const REQUIRED_FOR_FINALIZE_REWRITE = {
  serviceInfo: {
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
    btn: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactOfficePhone: '',
  },
  billingInfo: {
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: '',
    zip: '',
    countryId: '',
    contactOfficePhone: '',
    contactEmail: '',
  },
  isSpiffConfirmationValid: false,
};

export const REQUIRED_FOR_FINALIZE_REDUCTION = {
  serviceInfo: {
    streetAddress1: '',
    city: '',
    stateId: null,
    countryId: null,
    zip: '',
    btn: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactOfficePhone: '',
  },
  billingInfo: {
    contactFirstName: '',
    contactLastName: '',
    streetAddress1: '',
    city: '',
    stateId: '',
    zip: '',
    countryId: '',
    contactOfficePhone: '',
    contactEmail: '',
  },
  isSpiffConfirmationValid: false,
};
