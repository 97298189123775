export const ADMIN_SECTION_OVERRIDE_OPTIONS = [
  {
    key: '1',
    value: null,
    text: 'msg_no_override',
  },
  {
    key: '2',
    value: true,
    text: 'msg_yes',
  },
  {
    key: '3',
    value: false,
    text: 'msg_no',
  },
];

//TODO refactor to generic
export const ADMIN_SECTION_DAAS_OVERRIDE_OPTIONS = [
  {
    key: '1',
    value: null,
    text: 'msg_no_override',
  },
  {
    key: '3',
    value: false,
    text: 'msg_no',
  },
];

export const ADMIN_SECTION_SANGOMA_CX_OVERRIDE_OPTIONS = [
  {
    key: '1',
    value: null,
    text: 'msg_no_override',
  },
  {
    key: '3',
    value: false,
    text: 'msg_no',
  },
];

export const EXISTING_SPEECH_ANALYTICS_OVERRIDE_OPTIONS = [
  {
    key: '1',
    value: null,
    text: 'msg_no_override',
  },
  {
    key: '3',
    value: false,
    text: 'msg_no',
  },
];
