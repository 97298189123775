import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AmountControl from '../AmountControl';
import '../css/amount-checkbox-input.css';

class AmountCheckboxControl extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isShowArrow: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    min: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    max: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  };

  constructor(props) {
    super(props);
    this.state = {
      isChecked: !!props.value,
    };
    this.handleChecked = this.handleChecked.bind(this);
  }

  render() {
    const { id, onChange, label } = this.props;

    const checkboxClass = classNames({
      'checkbox-input': true,
      'checkbox-input-with-label': label,
    });

    return (
      <div className="amount-checkbox-input">
        <input
          data-test="amount-checkbox-input"
          id={id}
          type="checkbox"
          checked={this.state.isChecked}
          className={checkboxClass}
          onChange={this.handleChecked}
        />
        <AmountControl
          {...this.props}
          id={id + '-amount'}
          onChange={onChange}
          checkbox={true}
          allowBlankField={true}
          isChecked={this.state.isChecked}
        />
      </div>
    );
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
  }
}

export default AmountCheckboxControl;
