const defaultLocale = 'en-US';
const formatterOptions = {
  style: 'currency',
  currency: 'USD',
  currencyDisplay: 'symbol',
};

const formatter = (value, decimals = 2) => {
  const locale = defaultLocale;
  const options = Object.assign(formatterOptions, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  });
  const roundedValue = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);

  return parseFloat(Number(roundedValue) || 0).toLocaleString(locale, options);
};

export default formatter;
