import { LocationLineItemModel } from '../';
import { CELL_TYPES } from '../../components/LineItems/enums';

export default class LocationPartnerLineItemModel extends LocationLineItemModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.cellTypes = {
      quantity: CELL_TYPES.number,
      dealerNet: CELL_TYPES.label,
      partnerProductName: CELL_TYPES.label,
    };
  }

  get partnerAdd() {
    return true;
  }

  get dealerNet() {
    return this.parent.dealerNet;
  }

  set dealerNet(value) {
    // Do nothing
  }

  get partnerProductName() {
    return this.parent.partnerProductName;
  }

  set partnerProductName(value) {
    // Do nothing
  }
}
