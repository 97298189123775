import React, { forwardRef } from 'react';
import { ORDER_TYPES_TRANSLATE, QUOTE_STATUS } from '../../../../../common/enums';
import { quotePropType } from '../propTypes';
import * as formatter from '../../../../../common/helpers/format';
import '../styles/quotes-table-status.css';
import config from '../../../../../config';
import classNames from 'classnames';
import getQuoteStatusClassName from '../helpers/getQuoteStatusClassName';
import ExternalLink from '../../../../ExternalLink';
import { AuthContext } from '../../../../../Context';
import { getQuoteStatusTranslatedString } from '../../../../../common/helpers/getQuoteStatusTranslatedString';
import AppRouter from '../../../../../AppRouter/AppRouter';
import dayjs from 'dayjs';

const TableRow = forwardRef(({ intl, quote }, ref) => {
  const onClick = ({ target: { classList } }) => {
    if (classList.contains('not-for-select-quote')) {
      return;
    }

    AppRouter.push('/quotes/' + quote.id);
  };

  const formatMessage = intl.formatMessage;
  const orderTypeMessage = ORDER_TYPES_TRANSLATE[quote.orderType]
    ? formatMessage({ id: ORDER_TYPES_TRANSLATE[quote.orderType] })
    : quote.orderType;

  const upfrontTotal = formatter.currency(quote.upfrontTotal);
  const recurringTotal = formatter.currency(quote.recurringTotal);
  const quoteStatus = QUOTE_STATUS[quote.quoteStatus];
  const statusClassName = classNames('quotes-table-status', getQuoteStatusClassName(quoteStatus));
  const quoteStatusLabel = getQuoteStatusTranslatedString(quoteStatus, intl);
  const updatedAt = dayjs(quote.updated).format('MM/DD/YYYY h:mm:ss A');

  return (
    <tr id={'quotes-table-list-row_' + quote.id} onClick={onClick} ref={ref}>
      <td
        className="auto-width-nowrap align-right quotes-table-cell quotes-table-cell_number quotes-table-cell_master_order_id"
        title={quote.masterOrderId}
      >
        {quote.masterOrderId}
      </td>
      <td
        className="auto-width-nowrap align-right quotes-table-cell quotes-table-cell_number quotes-table-cell_id"
        title={quote.id}
      >
        {quote.id}
      </td>
      <td
        className="auto-width-ellipsis quotes-table-cell quotes-table-cell_text quotes-table-cell_customer_name"
        title={quote.customerName}
      >
        {quote.customerName}
      </td>
      <td
        className="auto-width-nowrap quotes-table-cell quotes-table-cell_text quotes-table-cell_package_type"
        title={quote.packageShortNameSelected}
      >
        {quote.packageShortNameSelected}
      </td>
      <td
        className="auto-width quotes-table-cell quotes-table-cell_text quotes-table-cell_order_type"
        title={orderTypeMessage}
      >
        {orderTypeMessage}
      </td>
      <td
        className="auto-width-nowrap align-right quotes-table-cell quotes-table-cell_currency quotes-table-cell_upfront_total"
        title={upfrontTotal}
      >
        {upfrontTotal}
      </td>
      <td
        className="auto-width-nowrap align-right quotes-table-cell quotes-table-cell_currency quotes-table-cell_recurring_total"
        title={recurringTotal}
      >
        {recurringTotal}
      </td>
      <td className="auto-width quotes-table-cell quotes-table-cell_status" title={quoteStatusLabel}>
        <div className={statusClassName}>
          <span>{quoteStatusLabel}</span>
          <AuthContext.Consumer>
            {
              /** @type {AuthContextModel} */ auth =>
                !quote.trackingLocationId || auth.isAgent ? null : (
                  <ExternalLink
                    path={config.trackingUrl + encodeURIComponent(quote.trackingLocationId)}
                    title={'#' + quote.trackingLocationId}
                    className="not-for-select-quote"
                  />
                )
            }
          </AuthContext.Consumer>
        </div>
      </td>
      <td className="auto-width quotes-table-cell quotes-table-cell_date quotes-table-cell_updated" title={updatedAt}>
        {updatedAt}
      </td>
      <td
        className="auto-width-ellipsis quotes-table-cell quotes-table-cell_text quotes-table-cell_title"
        title={quote.quoteTitle}
      >
        {quote.quoteTitle}
      </td>

      <AuthContext.Consumer>
        {
          /** @type {AuthContextModel} */ auth =>
            !auth.hasInternalPermissions ? null : (
              <React.Fragment>
                <td
                  className="auto-width-ellipsis quotes-table-cell quotes-table-cell_text quotes-table-cell_dealer_contact_name"
                  title={quote.dealerContactName}
                >
                  {quote.dealerContactName}
                </td>
                <td
                  className="auto-width-ellipsis quotes-table-cell quotes-table-cell_text quotes-table-cell_dealer_name"
                  title={quote.dealerName}
                >
                  {quote.dealerName}
                </td>
              </React.Fragment>
            )
        }
      </AuthContext.Consumer>
    </tr>
  );
});

TableRow.propTypes = {
  quote: quotePropType.isRequired,
};

export default TableRow;
