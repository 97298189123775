import React from 'react';
import { bool, object, string } from 'prop-types';
import classname from 'classnames';
import './loader.css';

const Loader = ({ isVisible = false, loaderStyles = null, parentClassName = '', title = '', className = '' }) => {
  if (!isVisible) {
    return null;
  }

  className = classname('loader', className);
  parentClassName = classname('loading-wrapper', parentClassName);

  return (
    <div className={parentClassName} title={title}>
      <div className={className} style={loaderStyles} />
    </div>
  );
};

Loader.propTypes = {
  isVisible: bool,
  loaderStyles: object,
  parentClassName: string,
  className: string,
  title: string,
};

export default Loader;
