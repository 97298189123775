import React, { Component } from 'react';
import PropTypes from 'prop-types';
import bindMultiple from '../../common/helpers/bindMultiple';
import translator from '../../common/translate';
import Modal from 'react-modal';
import { AppStateContext } from '../../Context';

class VersionsMismatchModal extends Component {
  static propTypes = {
    isShown: PropTypes.bool,
  };

  constructor(...args) {
    super(...args);

    bindMultiple(this, this.handleClose, this.handleReload);
  }

  handleClose() {
    AppStateContext.model.setProps({
      versionsMismatch: false,
    });
  }

  handleReload() {
    window.location.reload();
  }

  render() {
    if (!this.props.isShown) {
      return null;
    }

    return (
      <Modal
        isOpen={true}
        overlayClassName="default-overlay-modal"
        className="default-confirm-modal"
        ariaHideApp={false}
        shouldCloseOnEsc={false}
      >
        <div className="default-modal-label">
          <p className="light-bold">{translator.getMessage('msg_versions_mismatch_dialog_header')}</p>
          <p>{translator.getMessage('msg_versions_mismatch_dialog_message')}</p>
        </div>
        <div className="default-confirm-modal-actions">
          <button className="text-btn" onClick={this.handleClose}>
            {translator.getMessage('msg_cancel')}
          </button>
          <button onClick={this.handleReload}>{translator.getMessage('msg_reload')}</button>
        </div>
      </Modal>
    );
  }
}

export default VersionsMismatchModal;
