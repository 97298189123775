import React, { PureComponent } from 'react';
import VersionInfo from '../../components/VersionInfoComponent';
import PriceBookInfo from '../../components/PriceBookInfo';
import './css/footer.css';
import EnvBadge from '../../components/EnvBadge';
import config from '../../config';
import apiData from '../../Storage/apiData';
import { PriceBookContext } from '../../Context';

class Footer extends PureComponent {
  constructor(props) {
    super(props);

    this.handleVersionClick = this.handleVersionClick.bind(this);
  }

  handleVersionClick() {
    const pb = PriceBookContext.model;
    let versionString = 'UI: ' + config.version;

    if (apiData.version) {
      versionString += ' ' + apiData.version.toUpperCase();
    }

    if (pb.pricebookId) {
      versionString += ' PB: ' + [pb.pricebookId, pb.version, pb.draftNo].join('.');
    }

    this.copyToClipboard(versionString);

    console.info('RQ version copied to clipboard: ' + versionString);
  }

  copyToClipboard(str) {
    const el = document.createElement('textarea');

    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.opacity = '0';

    document.body.appendChild(el);

    el.select();

    document.execCommand('copy');
    document.body.removeChild(el);
  }

  render() {
    return (
      <footer id="rq-footer">
        {config.envLabel && <EnvBadge label={config.envLabel} />}
        <div className="versions-container" onClick={this.handleVersionClick} title="Click to copy">
          <VersionInfo />
          <PriceBookInfo />
        </div>
      </footer>
    );
  }
}

export default Footer;
