import { AuthContext } from '../../Context';
import { ORDER_TYPES, USER_ROLES } from '../../common/enums';
import ToolbarButtonModel from './ToolbarButtonModel';

class AmendmentModel extends ToolbarButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.label = 'msg_amend';
  }

  get disabled() {
    return Boolean(this.errors || super.disabled || this.appState.switchvoxWlwDisabled);
  }

  get errors() {
    if (AuthContext.model.role === USER_ROLES.REGULAR_USER) {
      return 'msg_reach_out_to_your_ram';
    }

    if (this.masterOrder.allLocationsAreFinalized) {
      return 'msg_at_least_one_location_must';
    }

    return '';
  }

  get errorsCnt() {
    return Number(Boolean(this.errors));
  }

  get visible() {
    return (
      this.isESignatureFlowAllowed &&
      this.isQuoteSubmittedAndAbleToUpdate &&
      !this.isQuoteAmendingByInternalUser &&
      this.isNotQuoteLoading &&
      this.masterOrder.orderType !== ORDER_TYPES.ADD_ON &&
      this.masterOrder.amendButtonEnabled
    );
  }
}

export default AmendmentModel;
