import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import translator from '../../../common/translate';
import isValidEmail from '../../../common/helpers/isValidEmail';
import { BILLING_EMAIL_VALIDATION_STATUSES } from '../../../common/enums/billingEmailValidationStatuses';
import '../css/validate-email.css';
import dayjs from 'dayjs';

class ValidateEmail extends PureComponent {
  static defaultProps = {
    email: '',
    lockedState: false,
    disabledState: false,
    validateEmail: () => {},
    contactModel: {},
  };

  static propTypes = {
    email: PropTypes.string,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    validateEmail: PropTypes.func,
    contactModel: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.handleOnClick = this.handleOnClick.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.confirmOrReValidateChunk = this.confirmOrReValidateChunk.bind(this);
  }

  handleOnClick() {
    if (typeof this.props.validateEmail === 'function') {
      this.props.validateEmail(this.props.email);
    }
  }

  handleConfirm() {
    if (typeof this.props.openConfirmModal === 'function') {
      this.props.openConfirmModal();
    }
  }

  confirmOrReValidateChunk() {
    return (
      <>
        <button type="button" className="primary" onClick={this.handleConfirm}>
          {translator.getMessage('msg_confirm_email')}
        </button>
      </>
    );
  }

  render() {
    const { email, contactModel, lockedState, disabledState, isEmailValidationRequested } = this.props;
    const disabled = lockedState || disabledState || !isValidEmail(email);
    const showValidateButton = [
      BILLING_EMAIL_VALIDATION_STATUSES.UNKNOWN,
      BILLING_EMAIL_VALIDATION_STATUSES.NONE,
      null,
    ].includes(contactModel.contactEmailValidationStatus);
    const showValidStatus = contactModel.contactEmailValidationStatus === BILLING_EMAIL_VALIDATION_STATUSES.VALID;
    const showRiskyStatus = contactModel.contactEmailValidationStatus === BILLING_EMAIL_VALIDATION_STATUSES.RISKY;
    const showInvalidStatus = contactModel.contactEmailValidationStatus === BILLING_EMAIL_VALIDATION_STATUSES.INVALID;
    const showConfirmedAsValidStatus =
      contactModel.contactEmailValidationStatus === BILLING_EMAIL_VALIDATION_STATUSES.CONFIRMED_AS_VALID;
    const showErrorStatus = contactModel.contactEmailValidationStatus === BILLING_EMAIL_VALIDATION_STATUSES.ERROR;
    const validationButtonDisable = disabled || isEmailValidationRequested;

    return (
      <div className="validate-email-wrapper">
        <div />
        {showValidateButton && (
          <button type="button" className="primary" onClick={this.handleOnClick} disabled={validationButtonDisable}>
            <span>{translator.getMessage('msg_validate_email_address_label')}</span>
          </button>
        )}
        {showValidStatus && (
          <div className="validation-status">
            <span className="icon icon-check" />
            <span className="validation-status-text">{translator.getMessage('msg_valid')}</span>
          </div>
        )}
        {showRiskyStatus && (
          <div className="validation-status">
            <span className="icon icon-error risky" />
            <span className="validation-status-text">
              {translator.getMessage('msg_risky_please')} {this.confirmOrReValidateChunk()}
            </span>
          </div>
        )}
        {showInvalidStatus && (
          <div className="validation-status">
            <span className="icon icon-error invalid" />
            <span className="validation-status-text">
              {translator.getMessage('msg_invalid_please')} {this.confirmOrReValidateChunk()}
            </span>
          </div>
        )}
        {showConfirmedAsValidStatus && (
          <div className="validation-status">
            <span className="icon icon-info" />
            <span className="validation-status-text">
              {contactModel.contactEmailConfirmedBy}&nbsp;
              {translator.getMessage('msg_confirmed_email_address_on')}&nbsp;
              {dayjs(contactModel.contactEmailConfirmedOn).format('MM/DD/YYYY')}&nbsp;
              {translator.getMessage('msg_at')}&nbsp;
              {dayjs(contactModel.contactEmailConfirmedOn).format('h:mm:ss A')}
            </span>
          </div>
        )}
        {showErrorStatus && (
          <div className="validation-status">
            <span className="icon icon-error invalid" />
            <span className="validation-status-text">
              {translator.getMessage('msg_validation_attempt_failed')} {this.confirmOrReValidateChunk()}
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default injectIntl(ValidateEmail);
