import { ModelAbstract } from './';
import { PriceBookContext } from '../Context';

class CustomerAttributesModel extends ModelAbstract {
  constructor(sibling) {
    super(sibling);

    this._existingSC3ProfessionalCustomer = false;
    this.existingServiceInsightCustomer = false;
    this.onChange = this.onChange.bind(this);
  }

  onChange(prop, value) {
    if (this.canSet(prop)) {
      this[prop] = value;
      this.modelChanged(this);

      return true;
    }

    return false;
  }

  get existingSC3ProfessionalCustomer() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._existingSC3ProfessionalCustomer;
  }

  set existingSC3ProfessionalCustomer(v) {
    this._existingSC3ProfessionalCustomer = v;
  }
}

export default CustomerAttributesModel;
