import AddButtonModel from './AddButtonModel';
import { CATEGORY_IDS_TO_HIDE_ADD_EXISTING_BUTTON, ORDER_TYPES } from '../../common/enums';

class AddExistingButtonModel extends AddButtonModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.id = 'li-category-add-existing-line-item-button_' + this._uuid;
  }

  get visible() {
    return Boolean(
      this.masterOrder.orderType === ORDER_TYPES.REWRITE &&
        !this._category.recurring &&
        super.visible &&
        !CATEGORY_IDS_TO_HIDE_ADD_EXISTING_BUTTON.includes(this._category.id)
    );
  }

  getDialogModel() {
    return this.masterOrder.addExistingLineItemDialog;
  }
}

export default AddExistingButtonModel;
