import React from 'react';
import { func, object, bool, string } from 'prop-types';
import { LabelField } from '../Controls';
import Validate from '../../Models/Validate';
import DealerContactName from './nested/DealerContactName';
import SubmitOnBehalfOf from './nested/SubmitOnBehalfOf';
import apiData from '../../Storage/apiData';

const PartnerInfo = ({
  customerOrder,
  lockedState,
  disabledState,
  disableSOBO,
  partnerType,
  partnerTier,
  onDealerContactUserIdChange,
  onSOBOControlsChange,
}) => {
  const { partnerCanSOBO } = apiData;

  return (
    <div>
      <LabelField
        id="dealerContactLabel"
        label="msg_partner"
        value={customerOrder.dealerName}
        parentClassName={partnerType ? 'with-type-label' : ''}
      />
      {partnerType && (
        <div className="pricebook-info" title={partnerType}>
          Type: {partnerType}
        </div>
      )}
      {partnerTier && (
        <div className="pricebook-info" title={partnerTier}>
          Tier: {partnerTier}
        </div>
      )}

      <DealerContactName
        key={'dealerContactUserIdDealerSelect'}
        id={'dealerContactUserIdDealerSelect'}
        value={Number(customerOrder.dealerContactUserId)}
        lockedState={lockedState}
        disabledState={disabledState}
        dealerName={customerOrder.dealerContactName}
        dealerContacts={customerOrder.dealerContacts}
        onChange={onDealerContactUserIdChange}
        errorText={Validate.dealerContactUserId(customerOrder)}
      />

      <div key="dealerContactEmailStringInput" className="dealer-contact-email">
        <LabelField
          id="dealerContactEmail"
          label="msg_quote_owner_email"
          className="dealer-contact-email-value"
          value={customerOrder.dealerContactEmail}
        />
      </div>

      {partnerCanSOBO && (
        <SubmitOnBehalfOf
          useSOBO={customerOrder.useSOBO}
          subAgentName={customerOrder.subAgentName}
          lockedState={lockedState}
          disabledState={disabledState}
          disableSOBO={disableSOBO}
          onChange={onSOBOControlsChange}
        />
      )}
    </div>
  );
};

PartnerInfo.propTypes = {
  customerOrder: object,
  lockedState: bool,
  disabledState: bool,
  disableSOBO: bool,
  partnerType: string,
  partnerTier: string,
  onDealerContactUserIdChange: func,
  onSOBOControlsChange: func,
};

export default PartnerInfo;
