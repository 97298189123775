import { Component } from 'react';
import AppRouter from './AppRouter';

class appRouterComponent extends Component {
  constructor(...args) {
    super(...args);

    this.state = {
      routeComponent: null,
    };
  }

  componentDidMount() {
    AppRouter.appRouterComponent = this;

    AppRouter.PREVENT_MATCH_CALLBACK = true;
    AppRouter.PREVENT_CONTROLLER_CALLBACK = true;
    AppRouter.PREVENT_CONSOLE_LOG = true;

    AppRouter.replace(AppRouter.location);
  }

  componentWillUnmount() {
    AppRouter.appRouterComponent = null;
  }

  render() {
    return this.state.routeComponent;
  }
}

export default appRouterComponent;
