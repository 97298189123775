import React from 'react';
import { string, func, objectOf } from 'prop-types';
import translator from '../../../common/translate';
import classNames from 'classnames';

const SummaryInfo = ({ desc = {}, onDetailsClick = () => {}, disableDetailsClick }) => {
  const onDetailsClickHandler = () => {
    if (!disableDetailsClick && typeof onDetailsClick === 'function') {
      onDetailsClick();
    }
  };

  const detailsClassName = classNames({
    'summary-details': true,
    'quote-initializing': disableDetailsClick,
  });

  return (
    <div className="summary-rows">
      <div className="summary-details-wrapper">
        <div className={detailsClassName} id="detailsToolbarBtn" onClick={onDetailsClickHandler}>
          {translator.getMessage('msg_details')}
        </div>
      </div>
      <div className="summary-titles">
        {Object.entries(desc).map(([key, val]) => (
          <div key={`data-row-${key}`} className="row-name">
            <span>{val}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

SummaryInfo.propTypes = {
  desc: objectOf(string),
  onDetailsClick: func,
};

export default SummaryInfo;
