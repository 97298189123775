const TypeConverter = strValue => {
  if (!isNaN(strValue)) {
    return Number(strValue);
  }

  if (strValue === undefined) {
    return strValue;
  }

  const strLowerCased = strValue.toLowerCase();

  if (strLowerCased === 'true') {
    return true;
  } else if (strLowerCased === 'false') {
    return false;
  } else if (strLowerCased === 'null') {
    return null;
  } else {
    return strValue;
  }
};

export default TypeConverter;
