import React from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import { ORDER_TYPES } from '../../common/enums';
import Dialog from '../Dialog';
import { dialogProps } from './submit.wizard.props';
import './submit.style.css';
import dayjs from 'dayjs';

export default class SubmitWizardComponent extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    quote: PropTypes.object,
    onClose: PropTypes.func,
    onFinalize: PropTypes.func,
    confirmButtonLabel: PropTypes.string,
  };

  static defaultProps = {
    open: false,
  };

  constructor(props, context) {
    super(props, context);

    this.handleFinalize = this.handleFinalize.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const { quote, confirmButtonLabel, open } = this.props;
    const {
      dealerContactName,
      dealerName,
      customerName,
      quoteStatus,
      updated,
      trackingLocationId,
      packageIdSelected,
      orderType,
    } = quote;
    const titleHeader = dealerContactName + ' - ' + dealerName;
    const openQuoteDialogHeader =
      orderType === ORDER_TYPES.ADD_ON
        ? 'msg_finalization_will_begin_the_order_process'
        : 'msg_please_confirm_that_you_wish_to_finalize_this_quote';

    dialogProps.actions = [
      <button
        key="submit-wizard-cancel-button"
        id="submit-wizard-cancel-button"
        className="btn-cancel text-btn"
        onClick={this.handleClose}
      >
        {translator.getMessage('msg_close')}
      </button>,
      <button
        key="submit-wizard-button"
        id="submit-wizard-button"
        className="btn-submit primary"
        onClick={this.handleFinalize}
      >
        {translator.getMessage(confirmButtonLabel)}
      </button>,
    ];

    dialogProps.title = (
      <div className="submit-title-wrapper">
        <header className="submit-title-header text-ellipsis" title={titleHeader}>
          {titleHeader}
        </header>
        <span className="submit-title-text text-ellipsis">
          {quote.getPackageModelById(packageIdSelected).packageName}, {customerName}
        </span>
        <span className="submit-title-text">{dayjs(updated).format('MM/DD/YYYY h:mm:ss A')}</span>
      </div>
    );

    dialogProps.onRequestClose = this.handleClose;

    if (quoteStatus === 'SUBMITTED') {
      return null;
    }

    if (quoteStatus === 'SUBMITTING') {
      return open ? (
        <Dialog {...dialogProps}>
          <form>
            <header className="submit-header">
              {translator.getMessage('msg_information_to_complete_submission_for_quote')}
            </header>
            {this.props.trackingLocationId && (
              <div className="two-sections-wrapper">
                <div className="half-left-section">
                  <span className="submit-label">Tracking Location ID</span>
                </div>
                <div className="half-right-section">
                  <span className="submit-label">{trackingLocationId}</span>
                </div>
              </div>
            )}
            <div className="two-sections-wrapper">
              <div className="half-left-section">
                <span className="submit-label">{translator.getMessage('msg_selected_package')}</span>
              </div>
              <div className="half-right-section">
                <span className="submit-label">{quote.getPackageModelById(packageIdSelected).packageName}</span>
              </div>
            </div>
          </form>
        </Dialog>
      ) : null;
    }

    return open ? (
      <Dialog {...dialogProps}>
        <form>
          <header className="submit-header">
            {translator.getMessage(
              quoteStatus === 'FINALIZED'
                ? 'msg_please_confirm_that_you_wish_to_un_finalize_this_quote'
                : openQuoteDialogHeader
            )}
          </header>
          <div className="two-sections-wrapper">
            <div className="half-left-section">
              <span className="submit-label">{translator.getMessage('msg_new_customer_name')}</span>
            </div>
            <div className="half-right-section">
              <span className="submit-label">{customerName}</span>
            </div>
          </div>
          <div className="two-sections-wrapper">
            <div className="half-left-section">
              <span className="submit-label">{translator.getMessage('msg_selected_package')}</span>
            </div>
            <div className="half-right-section">
              <span className="submit-label">{quote.getPackageModelById(packageIdSelected).packageName}</span>
            </div>
          </div>
        </form>
      </Dialog>
    ) : null;
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  handleFinalize() {
    if (typeof this.props.onFinalize === 'function') {
      this.props.onFinalize();
    }
  }
}
