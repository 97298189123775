import React from 'react';
import PropTypes from 'prop-types';
import Paginator from 'paginator';
import Page from './nested/Page';
import './css/pagination.css';

export default class Pagination extends React.Component {
  static propTypes = {
    totalItemsCount: PropTypes.number.isRequired,
    onChange: PropTypes.func,
    activePage: PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
    itemsCountPerPage: PropTypes.number,
    prevPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    nextPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    lastPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    firstPageText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    isFirstPageStartsWithZero: PropTypes.bool,
  };

  static defaultProps = {
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    activePage: 1,
    prevPageText: ' ',
    firstPageText: '«',
    nextPageText: ' ',
    lastPageText: '»',
    isFirstPageStartsWithZero: false,
  };

  buildPages() {
    const pages = [];
    const {
      itemsCountPerPage,
      pageRangeDisplayed,
      prevPageText,
      nextPageText,
      firstPageText,
      lastPageText,
      totalItemsCount,
      onChange,
      activeClass,
      isFirstPageStartsWithZero,
    } = this.props;

    let activePage = this.props.activePage;

    if (isFirstPageStartsWithZero) {
      activePage += 1;
    }

    const paginationInfo = new Paginator(itemsCountPerPage, pageRangeDisplayed).build(totalItemsCount, activePage);

    if (paginationInfo.first_page !== paginationInfo.last_page) {
      pages.push(
        <Page
          isActive={false}
          key={'pagination-button-first'}
          pageNumber={isFirstPageStartsWithZero ? 0 : 1}
          onClick={onChange}
          pageText={firstPageText}
          className={'icon-first-page first-page tertiary'}
          ariaLabel="First page"
        />
      );

      if (paginationInfo.has_previous_page) {
        pages.push(
          <Page
            isActive={false}
            key={'pagination-button-prev'}
            pageNumber={isFirstPageStartsWithZero ? paginationInfo.previous_page - 1 : paginationInfo.previous_page}
            onClick={onChange}
            pageText={prevPageText}
            className={'icon-play-arrow prev-page tertiary'}
            ariaLabel="Previous page"
          />
        );
      }

      for (let i = paginationInfo.first_page; i <= paginationInfo.last_page; i++) {
        pages.push(
          <Page
            isActive={i === activePage}
            key={'pagination-button-' + i}
            pageNumber={isFirstPageStartsWithZero ? i - 1 : i}
            pageText={i.toString()}
            onClick={onChange}
            activeClass={activeClass}
            ariaLabel={'Page number ' + i}
          />
        );
      }

      if (paginationInfo.has_next_page) {
        pages.push(
          <Page
            isActive={false}
            key={'pagination-button-next'}
            pageNumber={isFirstPageStartsWithZero ? paginationInfo.next_page - 1 : paginationInfo.next_page}
            onClick={onChange}
            pageText={nextPageText}
            className={'icon-play-arrow next-page tertiary'}
            ariaLabel="Next page"
          />
        );
      }

      pages.push(
        <Page
          isActive={false}
          key={'pagination-button-last'}
          pageNumber={isFirstPageStartsWithZero ? paginationInfo.total_pages - 1 : paginationInfo.total_pages}
          onClick={onChange}
          pageText={lastPageText}
          className={'icon-last-page last-page tertiary'}
          ariaLabel="Last page"
        />
      );
    }

    return pages;
  }

  render() {
    const pages = this.buildPages();
    return <div className="pages-btn-wrapper btn-group large">{pages}</div>;
  }
}
