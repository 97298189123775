import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../Inputs';
import AbstractControl from '../AbstractControl';
import ResultsCount from '../nested/ResultsCount';

const MAX_LENGTH = 50;

class TextControl extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onStateChange: PropTypes.func,
    value: PropTypes.string,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    changeOnBlur: PropTypes.bool,
    hint: PropTypes.string,
    label: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    parentClassName: PropTypes.string,
    tooltipText: PropTypes.string,
    inputType: PropTypes.string,
    showResultCnt: PropTypes.bool,
    clearFieldHandler: PropTypes.func,
    resultsCount: PropTypes.number,
    iconClassName: PropTypes.string,
    ariaLabel: PropTypes.string,
    mask: PropTypes.string,
    maskChar: PropTypes.string,
    maskFormatChars: PropTypes.object,
    maskAlwaysShow: PropTypes.bool,
    forbiddenCharactersList: PropTypes.string,
  };

  render() {
    const parentClassNames = ['text-input-component'];

    if (typeof this.props.className === 'string' && this.props.className !== '') {
      parentClassNames.push(this.props.className);
    }

    const inputAbstractProps = {
      hint: this.props.hint,
      style: this.props.style,
      focus: this.props.focus,
      label: this.props.label,
      errorText: this.props.errorText,
      required: this.props.required,
      requiredBlue: this.props.requiredBlue,
      tooltipText: this.props.tooltipText,
      disabledState: this.props.disabledState,
      parentClassName: this.props.parentClassName,
      className: parentClassNames.join(' '),
      labelFor: this.props.id,
    };

    return (
      <AbstractControl {...inputAbstractProps}>
        <span className={this.props.iconClassName} />
        <TextInput
          id={this.props.id}
          placeholder={this.props.placeholder}
          disabled={this.props.lockedState || this.props.disabledState}
          value={this.props.value}
          maxLength={this.props.maxLength || MAX_LENGTH}
          doFocus={this.props.focus}
          onChange={this.props.onChange}
          commitOnBlur={this.props.changeOnBlur}
          type={this.props.inputType}
          onStateChange={this.props.onStateChange}
          ariaLabel={this.props.ariaLabel}
          mask={this.props.mask}
          maskChar={this.props.maskChar}
          maskFormatChars={this.props.maskFormatChars}
          maskAlwaysShow={this.props.maskAlwaysShow}
          forbiddenCharactersList={this.props.forbiddenCharactersList}
        />

        {this.props.showResultCnt && (
          <ResultsCount clearFieldHandler={this.props.clearFieldHandler} count={this.props.resultsCount} />
        )}
      </AbstractControl>
    );
  }
}

export default TextControl;
