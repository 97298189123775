import React from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import Dialog from '../Dialog';
import ExternalLink from '../ExternalLink';
import './slex.css';
import dayjs from 'dayjs';

export default class SlexComponent extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onRefresh: PropTypes.func,
    title: PropTypes.string,
    salesExceptions: PropTypes.array,
    forceHideContents: PropTypes.bool,
  };

  static defaultProps = {
    open: true,
  };

  constructor(props, context) {
    super(props, context);

    this.handleRefresh = this.handleRefresh.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const { title, salesExceptions, forceHideContents } = this.props;

    const dialogProps = {
      actionsContainerClassName: 'slex-dialog-actions',
      modalClassName: 'slex-dialog-content',
    };

    dialogProps.actions = [
      <button
        key="submit-for-approval-cancel"
        id="submit-for-approval-cancel"
        className="btn-cancel"
        onClick={this.handleClose}
      >
        {translator.getMessage('msg_close')}
      </button>,
      <button
        key="submit-for-approval"
        id="submit-for-approval"
        className="btn-submit tertiary"
        onClick={this.handleRefresh}
      >
        {translator.getMessage('msg_refresh')}
      </button>,
    ];

    dialogProps.title = (
      <div className="slex-title-wrapper">
        <header className="slex-title-header text-ellipsis">{translator.getMessage(title)}</header>
      </div>
    );

    dialogProps.onRequestClose = this.handleClose;

    return (
      <Dialog {...dialogProps} isOpen={this.props.open}>
        {forceHideContents ? null : (
          <table className="rq-table striped sticky slex-modal-table">
            <thead>
              <tr>
                <th className="id align-right">{translator.getMessage('msg_id')}</th>
                <th className="status">{translator.getMessage('msg_status')}</th>
                <th className="updated">{translator.getMessage('msg_updated')}</th>
                <th className="note">{translator.getMessage('msg_note')}</th>
                <th className="crm-link">{translator.getMessage('msg_crm_link')}</th>
              </tr>
            </thead>
            <tbody>{this.getSalesExceptionsData(salesExceptions)}</tbody>
          </table>
        )}
      </Dialog>
    );
  }

  getSalesExceptionsData(salesExceptions) {
    return salesExceptions.map(el => {
      const id = el.id || '';
      const status = el.status || '';
      const note = el.withdrawnNote || el.initialNote || '';
      const lastUpdated =
        dayjs(el.createdOn > el.finishedOn ? el.createdOn : el.finishedOn).format('MM/DD/YYYY h:mm:ss A') || '';
      const link = el.salesforceUrl || '';

      return (
        <tr key={id} className="slex-table-row">
          <td className="id auto-width-nowrap align-right" title={id}>
            {id}
          </td>
          <td className="status auto-width-nowrap" title={status}>
            {status}
          </td>
          <td className="updated auto-width-nowrap" title={lastUpdated}>
            {lastUpdated}
          </td>
          <td className="note" title={note}>
            {note}
          </td>
          <td className="crm-link" title={link}>
            {link ? <ExternalLink path={link} /> : null}
          </td>
        </tr>
      );
    });
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  handleRefresh() {
    if (typeof this.props.onRefresh === 'function') {
      this.props.onRefresh(true);
    }
  }
}
