import React from 'react';
import PropTypes from 'prop-types';
import * as formatter from '../../../common/helpers/format';
import config from '../../../config';
import { NumberControl, AmountControl, TextControl } from '../../Controls';
import PureViewComponent from '../../PureViewComponent';
import { CELL_TYPES, CELL_TYPES_ARRAY } from '../enums';
import '../css/line-item.css';
import { AuthContext } from '../../../Context';

const AMOUNT_PROPS = [
  'price',
  'dealerNet',
  'override',
  'overrideValue',
  'markupValue',
  'totalMarkup',
  'quotePrice',
  'total',
  'dealerNetPrice',
  'parentPrice',
  'availablePrice',
];

class LineItemCell extends PureViewComponent {
  static propTypes = {
    id: PropTypes.string,
    alias: PropTypes.string.isRequired,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.oneOf(CELL_TYPES_ARRAY),
    onChange: PropTypes.func,
    hasError: PropTypes.bool,
    title: PropTypes.string,
    amountValueType: PropTypes.string,
    numberValueType: PropTypes.string,
    ariaLabel: PropTypes.string,
  };

  static defaultProps = {
    value: '',
    lockedState: false,
    disabledState: false,
    type: CELL_TYPES.label,
    hasError: false,
    amountValueType: 'positive',
    numberValueType: 'positive',
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.props.alias, value);
    }
  }

  componentDidMount() {
    if (this.props.isFocused && typeof this.props.onLineItemMounted === 'function') {
      this.props.onLineItemMounted();
    }
  }

  render() {
    const {
      id,
      value,
      alias,
      title,
      lockedState,
      disabledState,
      isFocused,
      numberValueType,
      amountValueType,
      hasError,
      pdfPath,
      ariaLabel,
    } = this.props;

    const type = lockedState ? CELL_TYPES.label : this.props.type;
    const typeClass = type === 'label' ? type : 'input';
    const classNames = ['cell', alias, typeClass];
    let component = null;
    let inputProps = {};

    switch (type) {
      case CELL_TYPES.amountForceDisabled:
        inputProps.disabledState = true;
      // eslint-disable-next-line
      case CELL_TYPES.amount:
        inputProps = {
          id,
          value,
          lockedState,
          disabledState: inputProps.disabledState || disabledState,
          onChange: this.handleChange,
          style: { marginBottom: 0 },
          focus: isFocused,
          valueType: amountValueType,
          allowBlankField: ['override', 'overrideValue'].includes(alias),
          className: hasError ? 'has-error' : '',
          ariaLabel,
        };

        component = <AmountControl {...inputProps} />;
        break;

      case CELL_TYPES.numberForceDisabled:
        inputProps.disabledState = true;
      // eslint-disable-next-line
      case CELL_TYPES.number:
        inputProps = {
          id,
          value,
          lockedState,
          disabledState: inputProps.disabledState || disabledState,
          onChange: this.handleChange,
          style: { marginBottom: 0 },
          focus: isFocused,
          valueType: numberValueType,
          allowBlankField: ['numPortsOverride', 'overrideQuantity'].includes(alias),
          className: hasError ? 'has-error' : '',
          ariaLabel,
        };

        component = <NumberControl {...inputProps} />;
        break;

      case CELL_TYPES.textForceDisabled:
        inputProps.disabledState = true;
      // eslint-disable-next-line
      case CELL_TYPES.text:
        inputProps = {
          id,
          value,
          lockedState,
          disabledState: inputProps.disabledState || disabledState,
          onChange: this.handleChange,
          style: { marginBottom: 0 },
          focus: isFocused,
          className: hasError ? 'has-error' : '',
          ariaLabel,
        };

        component = <TextControl {...inputProps} />;
        break;
      case CELL_TYPES.label:
      default:
        let valueComponent = value;

        if (alias === 'lineItemName' && typeof pdfPath === 'string' && pdfPath !== '') {
          valueComponent = (
            <a
              target="_blank"
              className="slick-link"
              rel="noopener noreferrer"
              href={config.api.url + '/' + pdfPath + '?token=' + AuthContext.model.token}
            >
              {value}
            </a>
          );
        } else if (AMOUNT_PROPS.includes(alias)) {
          valueComponent = alias === 'overrideValue' && value === null ? '' : formatter.currency(value);
        }

        component = (
          <label aria-label={ariaLabel} id={id} className={value < 0 ? 'red-text' : ''}>
            {valueComponent}
          </label>
        );
    }

    return (
      <div className={classNames.join(' ')} title={title}>
        {component}
      </div>
    );
  }
}

export default LineItemCell;
