const texts = {
  Frame: {
    startNew: {
      title: 'msg_start_quote_for_new_customer',
      tooltip: 'Start Quote for New Customer',
    },
    startNewForExistingCustomer: {
      title: 'msg_start_new_quote_for_existing_customer_sidebar',
      tooltip: 'Start Quote for Existing Customer',
    },
    save: {
      title: 'msg_save_quote',
      tooltip: 'Save Quote',
    },
    addLocation: {
      title: 'msg_add_location',
      tooltip: 'Add Location',
    },
    retrieve: {
      title: 'msg_retrieve_quotes',
      tooltip: 'Retrieve Quotes',
    },
    exit: {
      title: 'msg_exit_rocket_quote',
      tooltip: 'Exit Rocket®',
    },
    about: {
      title: 'msg_about_rocket_quote',
      tooltip: 'About Rocket®',
    },
  },
};

export default texts;
