import ORDER_TYPES from '../common/enums/orderTypes.enums';
import constants from '../common/constants';
import { ModelAbstract } from './index';
import { PartnerContext } from '../Context';

// TODO: refactor getters into static methods/properties
class ConstantsModel extends ModelAbstract {
  get orderType() {
    return this.controller.customerOrder.orderType;
  }

  get getMOSections() {
    return {
      customerInfo: {
        id: 'customer-information-section',
        title: 'msg_customer_information',
        isGeneralSection: true,
      },
      quoteInfo: {
        id: 'quote-information-section',
        title: 'msg_quote_information',
        isGeneralSection: true,
      },
      partnerInfo: {
        id: 'partner-information-section',
        title: 'msg_partner_section',
        isGeneralSection: true,
      },
      daasSettingsSection: {
        id: 'daas-settings-section',
        title: 'msg_daas_settings_section',
        isGeneralSection: true,
      },
      adminSection: {
        id: 'admin-section',
        title: 'msg_admin_section',
        isGeneralSection: true,
      },
      addendumsSection: {
        id: 'addendums-section',
        title: 'msg_addendums',
        isGeneralSection: true,
      },
      sangomaCxSettings: {
        id: 'sangoma-cx-settings',
        title: 'msg_sangoma_cx_settings',
        isGeneralSection: true,
      },
      nrc: {
        id: 'customer-order-non-recurring-line-items-section',
        title: 'msg_non_recurring_line_items',
      },
      rc: {
        id: 'customer-order-recurring-line-items-section',
        title: 'msg_monthly_recurring_line_items',
      },
      ictp: {
        id: 'customer-order-ictp-section',
        title: 'msg_ictp_provisioning_and_shipping',
        extendedTitle: 'msg_ictp_extended_title',
      },
      spiff: {
        id: 'customer-order-spiff-discount-section',
        title: 'msg_customer_discount_spiff',
      },
      contactInfo: {
        id: 'customer-order-customer-contact-information-section',
        title: 'msg_customer_service_information',
      },
      billingInfo: {
        id: 'customer-order-customer-billing-information-section',
        title: 'msg_billing_information',
      },
      shippingInfo: {
        id: 'customer-order-customer-shipping-information-section',
        title: 'msg_shipping_information',
      },
      leaseOption: {
        id: 'lease-option-section-mo',
        title: 'msg_lease_section_header',
      },
      rentalInfo: {
        id: 'rental-info-section-mo',
        title: 'msg_rental_info',
      },
    };
  }

  get getLocationSections() {
    return {
      customerInfo: {
        id: 'customer-information-section',
        title: 'msg_customer_information',
        isGeneralSection: true,
      },
      quoteInfo: {
        id: 'quote-information-section',
        title: 'msg_quote_information',
        isGeneralSection: true,
      },
      partnerInfo: {
        id: 'partner-information-section',
        title: 'msg_partner_section',
        isGeneralSection: true,
      },
      daasSettingsSection: {
        id: 'daas-settings-section',
        title: 'msg_daas_settings_section',
        isGeneralSection: true,
      },
      adminSection: {
        id: 'admin-section',
        title: 'msg_admin_section',
        isGeneralSection: true,
      },
      addendumsSection: {
        id: 'addendums-section',
        title: 'msg_addendums',
        isGeneralSection: true,
      },
      sangomaCxSettings: {
        id: 'sangoma-cx-settings',
        title: 'msg_sangoma_cx_settings',
        isGeneralSection: true,
      },
      locName: {
        id: 'location-name-section',
        title: 'msg_location_data',
      },
      nrc: {
        id: 'location-non-recurring-line-items-section',
        title: 'msg_non_recurring_line_items',
      },
      rc: {
        id: 'location-recurring-line-items-section',
        title: 'msg_monthly_recurring_line_items',
      },
      ictp: {
        id: 'location-ictp-section',
        title: 'msg_ictp_provisioning_and_shipping',
        extendedTitle: 'msg_ictp_extended_title',
      },
      spiff: {
        id: 'location-spiff-discount-section',
        title: 'msg_customer_discount_spiff',
      },
      contactInfo: {
        id: 'location-customer-contact-information-section',
        title: 'msg_customer_service_information',
      },
      billingInfo: {
        id: 'location-billing-information-section',
        title: 'msg_billing_information',
      },
      shippingInfo: {
        id: 'location-shipping-information-section',
        title: 'msg_shipping_information',
      },
      rentalInfo: {
        id: 'rental-info-section-loc',
        title: 'msg_rental_info',
      },
    };
  }

  get getRequiredFields() {
    let requiredFieldsMO = [];
    let requiredFieldsLOC = [];

    switch (this.orderType) {
      case ORDER_TYPES.NEW_CUSTOMER:
        requiredFieldsMO = constants.NEW_NEW.REQUIRED_FIELDS_CONTACT_MO;
        requiredFieldsLOC = constants.NEW_NEW.REQUIRED_FIELDS_CONTACT_LOC;
        break;
      case ORDER_TYPES.ADD_ON:
        requiredFieldsMO = constants.ADD_ON.REQUIRED_FIELDS_CONTACT_MO;
        break;
      case ORDER_TYPES.REWRITE:
        requiredFieldsMO = constants.REWRITE.REQUIRED_FIELDS_CONTACT_MO;
        requiredFieldsLOC = constants.REWRITE.REQUIRED_FIELDS_CONTACT_LOC;
        break;
      case ORDER_TYPES.NEW_LOCATIONS:
        requiredFieldsMO = constants.NEW_LOCATIONS.REQUIRED_FIELDS_CONTACT_MO;
        requiredFieldsLOC = constants.NEW_LOCATIONS.REQUIRED_FIELDS_CONTACT_LOC;
        break;
      case ORDER_TYPES.REDUCTION:
        requiredFieldsMO = constants.REDUCTION.REQUIRED_FIELDS_CONTACT_MO;
        requiredFieldsLOC = constants.REDUCTION.REQUIRED_FIELDS_CONTACT_LOC;
        break;
      default:
        break;
    }

    if (PartnerContext.model.isWhiteLabelWholesale) {
      requiredFieldsMO = requiredFieldsMO.filter(field => !constants.WLW_EXCLUDED_FIELDS.includes(field));
      requiredFieldsLOC = requiredFieldsLOC.filter(field => !constants.WLW_EXCLUDED_FIELDS.includes(field));
    }

    return { requiredFieldsMO, requiredFieldsLOC };
  }

  get getSaveFields() {
    let saveFields = [];

    switch (this.orderType) {
      case ORDER_TYPES.NEW_CUSTOMER:
        saveFields = constants.NEW_NEW.SAVE_FIELDS;
        break;
      case ORDER_TYPES.ADD_ON:
        saveFields = constants.ADD_ON.SAVE_FIELDS;
        break;
      case ORDER_TYPES.REWRITE:
        saveFields = constants.REWRITE.SAVE_FIELDS;
        break;
      case ORDER_TYPES.NEW_LOCATIONS:
        saveFields = constants.NEW_LOCATIONS.SAVE_FIELDS;
        break;
      case ORDER_TYPES.REDUCTION:
        saveFields = constants.REDUCTION.SAVE_FIELDS;
        break;
      default:
        break;
    }

    return saveFields;
  }

  get getCalcFields() {
    let calcFields = [];

    switch (this.orderType) {
      case ORDER_TYPES.NEW_CUSTOMER:
        calcFields = constants.NEW_NEW.CALC_FIELDS;
        break;
      case ORDER_TYPES.ADD_ON:
        calcFields = constants.ADD_ON.CALC_FIELDS;
        break;
      case ORDER_TYPES.REWRITE:
        calcFields = constants.REWRITE.CALC_FIELDS;
        break;
      case ORDER_TYPES.NEW_LOCATIONS:
        calcFields = constants.NEW_LOCATIONS.CALC_FIELDS;
        break;
      case ORDER_TYPES.REDUCTION:
        calcFields = constants.REDUCTION.CALC_FIELDS;
        break;
      default:
        break;
    }

    return calcFields;
  }

  get getQuoteInfoInputs() {
    let quoteInfoInputs = [];

    switch (this.orderType) {
      case ORDER_TYPES.NEW_CUSTOMER:
        quoteInfoInputs = constants.NEW_NEW.QUOTE_INFO_INPUTS;
        break;
      case ORDER_TYPES.ADD_ON:
        quoteInfoInputs = constants.ADD_ON.QUOTE_INFO_INPUTS;
        break;
      case ORDER_TYPES.REWRITE:
        quoteInfoInputs = constants.REWRITE.QUOTE_INFO_INPUTS;
        break;
      case ORDER_TYPES.NEW_LOCATIONS:
        quoteInfoInputs = constants.NEW_LOCATIONS.QUOTE_INFO_INPUTS;
        break;
      case ORDER_TYPES.REDUCTION:
        quoteInfoInputs = constants.REDUCTION.QUOTE_INFO_INPUTS;
        break;
      default:
        quoteInfoInputs = constants.NEW_NEW.QUOTE_INFO_INPUTS;
        break;
    }

    return quoteInfoInputs;
  }

  get getRequiredForFinalize() {
    let requiredForFinalize = {};

    switch (this.orderType) {
      case ORDER_TYPES.NEW_CUSTOMER:
        requiredForFinalize = constants.NEW_NEW.REQUIRED_FOR_FINALIZE;
        break;
      case ORDER_TYPES.ADD_ON:
        requiredForFinalize = constants.ADD_ON.REQUIRED_FOR_FINALIZE;
        break;
      case ORDER_TYPES.REWRITE:
        requiredForFinalize = constants.REWRITE.REQUIRED_FOR_FINALIZE;
        break;
      case ORDER_TYPES.NEW_LOCATIONS:
        requiredForFinalize = constants.NEW_LOCATIONS.REQUIRED_FOR_FINALIZE;
        break;
      case ORDER_TYPES.REDUCTION:
        requiredForFinalize = constants.REDUCTION.REQUIRED_FOR_FINALIZE;
        break;
      default:
        break;
    }

    if (PartnerContext.model.isWhiteLabelWholesale) {
      for (let field in requiredForFinalize.serviceInfo) {
        if (requiredForFinalize.serviceInfo.hasOwnProperty(field) && constants.WLW_EXCLUDED_FIELDS.includes(field)) {
          delete requiredForFinalize.serviceInfo[field];
        }
      }
    }

    return requiredForFinalize;
  }
}

export default ConstantsModel;
