import React from 'react';
import PropTypes from 'prop-types';
import AbstractInput from '../AbstractInput';
import { MaskInput } from '../index';
import bindMultiple from '../../../common/helpers/bindMultiple';

class ZipInput extends React.Component {
  static propTypes = {
    ...AbstractInput.propTypes,
    onChange: PropTypes.func,
  };

  constructor(props) {
    super(props);

    bindMultiple(this, this.handleCommit, this.beforeMaskedValueChange);
  }

  handleCommit(stringValue) {
    // Override behaviour by Higher order Component
    if (typeof this.props.onCommit === 'function') {
      return this.props.onCommit(stringValue);
    }

    // Else use default behaviour
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(stringValue.trim());

      return true;
    }

    return false;
  }

  beforeMaskedValueChange(newState) {
    let { value, selection } = newState;
    let cursorPosition = selection ? selection.start : null;

    if (value.endsWith('-')) {
      if (cursorPosition === value.length) {
        cursorPosition--;
        selection = {
          start: cursorPosition,
          end: cursorPosition,
        };
      }

      value = value.slice(0, -1);
    }

    return {
      value,
      selection,
    };
  }

  render() {
    const classNames = ['zip-input-field'];

    if (typeof this.props.className === 'string' && this.props.className.trim() !== '') {
      classNames.push(this.props.className);
    }

    return (
      <MaskInput
        {...this.props}
        mask={'99999-9999'}
        maskChar={null}
        className={classNames.join(' ')}
        beforeMaskedValueChange={this.beforeMaskedValueChange}
        onCommit={this.handleCommit}
      />
    );
  }
}

export default ZipInput;
