export const LEASE_OPTIONS = {
  NONE: 'NONE',
  NAVITAS_90_DAY_DEFERRED: 'NAVITAS_90_DAY_DEFERRED',
  NAVITAS_PROMOTIONAL_RATE: 'NAVITAS_PROMOTIONAL_RATE',
  THIRD_PARTY_CUSTOM_AMOUNT: 'THIRD_PARTY_CUSTOM_AMOUNT',
};

export const LEASE_OPTIONS_LABELS = {
  [LEASE_OPTIONS.NONE]: 'msg_lease_options_label_none',
  [LEASE_OPTIONS.NAVITAS_90_DAY_DEFERRED]: 'msg_lease_options_label_navitas_90_day_deferred',
  [LEASE_OPTIONS.NAVITAS_PROMOTIONAL_RATE]: 'msg_lease_options_label_navitas_promotional_rate',
  [LEASE_OPTIONS.THIRD_PARTY_CUSTOM_AMOUNT]: 'msg_lease_options_label_third_party_custom_amount',
};

export const LEASE_OPTIONS_LIST = Object.values(LEASE_OPTIONS);
