import { useEffect, useState } from 'react';
import { MIN_INNER_HEIGHT_TO_SCROLL } from '../constants';

export default function usePaginatorStyles() {
  const [clientHeight, setClientHeight] = useState(window.innerHeight);

  // update clientHeight on window resize
  useEffect(() => {
    const handleResize = () => {
      setClientHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    position: clientHeight < MIN_INNER_HEIGHT_TO_SCROLL ? 'initial' : 'fixed',
  };
}
