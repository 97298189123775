export const SOLUTION_TYPES = {
  BUSINESS_VOICE: 1,
  BUSINESS_VOICE_PLUS: 2,
  BLUEFACE: 3,
  SWITCHVOX_SIP_STATION: 4,
  STANDALONE_NON_UCAAS: 5,
  SANGOMA_CX_STANDALONE: 6,
};

export const SOLUTION_TYPES_OPTIONS = {
  1: 'msg_business_voice',
  2: 'msg_business_voice_plus',
  // Blueface solution type should not be selectable
};

export const SOLUTION_TYPES_W_SWITCHVOX_OPTIONS = {
  1: 'msg_business_voice',
  2: 'msg_business_voice_plus',
  // Blueface solution type should not be selectable
  4: 'msg_switchvox_sip_station_solution_type',
};

export const SOLUTION_TYPES_W_STANDALONE_OPTIONS = {
  1: 'msg_business_voice',
  2: 'msg_business_voice_plus',
  5: 'msg_standalone_service_non_ucaas',
};

export const SOLUTION_TYPES_DROPDOWN = [
  {
    key: SOLUTION_TYPES.BUSINESS_VOICE,
    value: SOLUTION_TYPES.BUSINESS_VOICE,
    text: 'msg_business_voice',
  },
  {
    key: SOLUTION_TYPES.BUSINESS_VOICE_PLUS,
    value: SOLUTION_TYPES.BUSINESS_VOICE_PLUS,
    text: 'msg_business_voice_plus',
  },
];

export const SOLUTION_TYPES_W_SWITCHVOX_DROPDOWN = [
  ...SOLUTION_TYPES_DROPDOWN,
  {
    key: SOLUTION_TYPES.SWITCHVOX_SIP_STATION,
    value: SOLUTION_TYPES.SWITCHVOX_SIP_STATION,
    text: 'msg_switchvox_sip_station_solution_type',
  },
];

export const SOLUTION_TYPES_W_STANDALONE_DROPDOWN = [
  ...SOLUTION_TYPES_DROPDOWN,
  {
    key: SOLUTION_TYPES.STANDALONE_NON_UCAAS,
    value: SOLUTION_TYPES.STANDALONE_NON_UCAAS,
    text: 'msg_standalone_service_non_ucaas',
  },
];

export const SOLUTION_TYPES_W_SANGOMA_CX_DROPDOWN = [
  ...SOLUTION_TYPES_DROPDOWN,
  {
    key: SOLUTION_TYPES.SANGOMA_CX_STANDALONE,
    value: SOLUTION_TYPES.SANGOMA_CX_STANDALONE,
    text: 'msg_sangoma_cx_standalone',
  },
];
