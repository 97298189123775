import React from 'react';
import PropTypes from 'prop-types';
import BadgeButton from '../BadgeButton';
import translator from '../../common/translate';
import Modal from 'react-modal';
import './approvalComponent.css';

const MIN_LENGTH = 5;
const TEXT_BY_ACTION = {
  submit: {
    title: 'msg_submit_for_approval',
    hint: 'msg_please_be_sure_to_make_all_changes_prior_to_requesting_approval',
    hintWarning: 'msg_approval_request_details',
  },
  retract: {
    title: 'msg_retract_approval',
    hint: 'msg_this_change_will_retract_approval',
    hintWarning: 'msg_please_state_the_reason_for_retract_approval',
  },
};

class ApprovalComponent extends React.Component {
  static propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmitApproval: PropTypes.func,
    onRetractApproval: PropTypes.func,
    action: PropTypes.oneOf(['submit', 'retract']),
  };

  static defaultProps = {
    open: true,
  };

  constructor(props, context) {
    super(props, context);

    this.state = {
      textAreaValue: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  render() {
    const { action } = this.props;
    const disabled = this.state.textAreaValue.length < MIN_LENGTH;
    const errorCnt = this.state.textAreaValue.length < MIN_LENGTH ? 1 : 0;
    const errorText = errorCnt ? 'msg_add_approval_request_details' : '';

    return (
      <Modal
        isOpen={this.props.open}
        overlayClassName="default-overlay-modal"
        className="default-confirm-modal approval-modal"
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        onRequestClose={this.handleClose}
      >
        <div className="default-modal-label">
          <p className="light-bold">{translator.getMessage(TEXT_BY_ACTION[action].title)}</p>
          <br />
          <p>{translator.getMessage(TEXT_BY_ACTION[action].hint)}</p>
          <p className="required-red">{translator.getMessage(TEXT_BY_ACTION[action].hintWarning)}</p>
          <textarea
            aria-label={
              this.props.action === 'retract' ? 'Reason for retract approval' : 'Reason for the exception request'
            }
            onChange={e => this.handleOnChange(e)}
          />
        </div>
        <div className="default-confirm-modal-actions">
          <button
            key="submit-for-approval-cancel"
            id="submit-for-approval-cancel"
            className="btn-cancel text-btn"
            onClick={this.handleClose}
          >
            {translator.getMessage('msg_cancel')}
          </button>
          <BadgeButton
            key="submit-for-approval"
            id="submit-for-approval"
            btnClassName="btn-submit primary"
            errorsCnt={errorCnt}
            errors={errorText}
            disabled={disabled}
            label={translator.getMessage('msg_submit')}
            onClick={this.handleSubmit}
          />
        </div>
      </Modal>
    );
  }

  handleOnChange(e) {
    this.setState({
      textAreaValue: e.target.value,
    });
  }

  handleClose() {
    if (typeof this.props.onClose === 'function') {
      this.props.onClose();
    }
  }

  handleSubmit() {
    const submitMethod = this.props.action === 'submit' ? this.props.onSubmitApproval : this.props.onRetractApproval;

    if (typeof submitMethod === 'function') {
      submitMethod(this.state.textAreaValue);
      this.setState({ textAreaValue: '' });
    }
  }
}

export default ApprovalComponent;
