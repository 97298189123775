import { QUOTE_STATUS, USER_ROLES } from './enums';
import { PartnerContext } from '../Context';

export const checkRequiredFieldsForSignature = (errors, quote, role) => {
  if (
    !errors.fields.length &&
    quote.quoteStatus === QUOTE_STATUS.AMENDING &&
    [USER_ROLES.INTERNAL_USER, USER_ROLES.SALES_OPERATIONS_USER].includes(role)
  ) {
    if (!quote.amendmentTaskNumber) {
      if (role === USER_ROLES.SALES_OPERATIONS_USER) {
        errors.fields.push({
          fieldLabel: '@msg_amendment_task_number@',
          fieldName: 'amendmentTaskNumber',
        });
      } else {
        errors.fields.push({
          fieldLabel: '@msg_amendment_task_number@',
          fieldName: 'amendmentTaskNumber',
          altErrorWrapper: true,
        });
      }
    }

    if (
      !PartnerContext.model.isWhiteLabelWholesale &&
      ((typeof quote.saSigningDate === 'string' &&
        !quote.saSigningDate.match(/^(0[1-9]|1[0-2])\s?\/\s?(0[1-9]|[1-2][0-9]|3[0-1])\s?\/\s?(\d{4})$/)) ||
        !quote.saSigningDate)
    ) {
      if (role === USER_ROLES.SALES_OPERATIONS_USER) {
        errors.fields.push({
          fieldLabel: '@msg_sa_signed_date_format@',
          fieldName: 'saSigningDate',
        });
      } else {
        errors.fields.push({
          fieldLabel: '@msg_sa_signed_date@',
          fieldName: 'saSigningDate',
          altErrorWrapper: true,
        });
      }
    }
  }

  if (quote.numLocationsCountWarning) {
    errors.separateLineFields.push({
      fieldLabel: '@msg_num_locations_greater_than_allowed_warning@',
    });
  }

  errors.message = errors.fields.length ? errors.fields.map(f => f.fieldLabel).join('@\n') : '';

  return errors;
};
