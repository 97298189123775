import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import { v4 as UUID } from 'uuid';
import bindMultiple from '../../../../common/helpers/bindMultiple';
import AutocompleteValue from '../../../../common/helpers/AutocompleteValue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

const FORMAT_EDIT = 'MM-DD-YYYY';
const FORMAT_DISPLAY = 'MMMM DD, YYYY';

class InputField extends PureComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    onSet: PropTypes.func,
    onCancel: PropTypes.func,
    onSwitch: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.number,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      focused: false,
      value: this.props.value ? dayjs.utc(this.props.value).tz(this.props.timezone).format(FORMAT_EDIT) : '',
    };

    this.inputAutocompleteValue = AutocompleteValue();
    this.inputNameValue = 'input-' + UUID();

    bindMultiple(this, this.handleOnBlur, this.handleOnChange, this.handleOnFocus, this.handleOnKeyDown);
  }

  componentDidUpdate() {
    if (this.state.focused === false) {
      this.setState({
        value: this.props.value ? dayjs.utc(this.props.value).tz(this.props.timezone).format(FORMAT_EDIT) : '',
      });
    }
  }

  render() {
    const date = dayjs.utc(this.props.value).tz(this.props.timezone);
    const timestamp = dayjs.isDayjs(date) && date.isValid() ? date.valueOf() : null;
    const mask = this.state.focused ? '99-99-9999' : '';
    const unfocusedValue = timestamp !== null ? dayjs.utc(timestamp).tz(this.props.timezone).format(FORMAT_DISPLAY) : '';
    const value = this.state.focused ? this.state.value : unfocusedValue;

    return (
      <InputMask
        autoComplete={this.inputAutocompleteValue}
        name={this.inputNameValue}
        id={this.props.id}
        type="text"
        maskChar={null}
        mask={mask}
        value={value}
        placeholder={FORMAT_EDIT}
        onBlur={this.handleOnBlur}
        onFocus={this.handleOnFocus}
        onChange={this.handleOnChange}
        onKeyDown={this.handleOnKeyDown}
        disabled={this.props.disabled}
      />
    );
  }

  handleOnChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleOnKeyDown(e) {
    let timestamp;

    try {
      const date = dayjs.tz(this.state.value, FORMAT_EDIT, this.props.timezone);
      timestamp = dayjs.isDayjs(date) && date.isValid() ? date.valueOf() : null;
    } catch {
      timestamp = null;
    }

    const key = e.key;

    switch (key) {
      case 'Enter':
        e.preventDefault();
        e.target.blur();

        if (typeof this.props.onSet === 'function') {
          this.props.onSet(timestamp);
        }

        break;
      case 'Escape':
        e.preventDefault();
        e.target.blur();

        if (typeof this.props.onCancel === 'function') {
          this.props.onCancel();
        }

        break;
      case 'Tab':
        e.preventDefault();
        e.target.blur();

        if (typeof this.props.onSwitch === 'function') {
          this.props.onSwitch(timestamp);
        }

        break;
      default:
        break;
    }
  }

  handleOnFocus(e) {
    let value = this.state.value || '';

    this.setState({
      focused: true,
      value,
    });

    this.props.onFocus(e);
  }

  handleOnBlur(ev) {
    this.setState({
      focused: false,
    });

    if (typeof this.props.onBlur === 'function') {
      try {
        const date = dayjs.tz(this.state.value, FORMAT_EDIT, this.props.timezone);
        const timestamp = dayjs.isDayjs(date) && date.isValid() ? date.valueOf() : null;

        this.props.onBlur(timestamp, ev);
      } catch {
        this.props.onBlur(null, ev);
      }
    }
  }
}

export default InputField;
