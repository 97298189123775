export const SANGOMA_CX_USER_TYPE_OPTIONS = {
  NONE: 'NONE',
  STANDARD: 'STANDARD',
  ADVANCED: 'ADVANCED',
  PREMIUM: 'PREMIUM',
};

export const SANGOMA_CX_USER_TYPE_DROPDOWN_OPTIONS = [
  {
    key: '1',
    value: 'NONE',
    text: 'msg_lease_options_label_none',
  },
  {
    key: '2',
    value: 'STANDARD',
    text: 'msg_standard',
  },
  {
    key: '3',
    value: 'ADVANCED',
    text: 'msg_advanced',
  },
  {
    key: '4',
    value: 'PREMIUM',
    text: 'msg_premium',
  },
];

export const SANGOMA_CX_USER_TYPES_QUOTE_HISTORY = {
  NONE: 'msg_lease_options_label_none',
  STANDARD: 'msg_standard',
  ADVANCED: 'msg_advanced',
  PREMIUM: 'msg_premium',
};
