import React, { Component } from 'react';
import Modal from 'react-modal';
import { bool, func, number } from 'prop-types';
import bindMultiple from '../../common/helpers/bindMultiple';
import translator from '../../common/translate';
import { NumberControl } from '../Controls';
import './css/duplicate-location-modal.css';
import BadgeButton from '../BadgeButton';

class DuplicateLocationModal extends Component {
  static propTypes = {
    closeModal: func,
    duplicateLocations: func,
    numLocations: number,
    numExistingLocations: number,
    isQuoteAvailableForChanges: bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
    };

    bindMultiple(this, this.handleClose, this.handleDuplicate, this.handleChangeQuantity);
  }

  handleClose() {
    if (typeof this.props.closeModal === 'function') {
      this.props.closeModal();
    }
  }

  handleDuplicate() {
    if (typeof this.props.duplicateLocations === 'function') {
      this.props.duplicateLocations(this.state.quantity);
    }
  }

  handleChangeQuantity(quantity) {
    this.setState({ quantity });
  }

  render() {
    const { isQuoteAvailableForChanges, numLocations, numExistingLocations } = this.props;
    const isAddedLocationsExceedsNumLocations = !isQuoteAvailableForChanges
      ? numExistingLocations + this.state.quantity > numLocations
      : false;

    return (
      <Modal
        overlayClassName="default-overlay-modal"
        className="default-confirm-modal duplicate-location-modal"
        bodyOpenClassName="body-overflow-hidden"
        ariaHideApp={false}
        onRequestClose={this.handleClose}
        isOpen
      >
        <div className="default-modal-label">
          <p className="light-bold">{translator.getMessage('msg_clone_location')}</p>
        </div>
        <div className="quantity-of-locations">
          <form onSubmit={this.handleDuplicate}>
            <NumberControl
              allowNegativeValue={false}
              id="location-duplicates-quantity"
              label="msg_number_of_locations_to_clone"
              max={99}
              min={0}
              onChange={this.handleChangeQuantity}
              parentClassName="short-input"
              value={this.state.quantity}
              focus
            />
            <button type="submit" hidden />
          </form>
        </div>
        <div className="default-confirm-modal-actions">
          <button className="text-btn" onClick={this.handleClose}>
            {translator.getMessage('msg_cancel')}
          </button>
          <BadgeButton
            className="btn-yes"
            disabled={this.state.quantity < 1 || isAddedLocationsExceedsNumLocations}
            errors={isAddedLocationsExceedsNumLocations ? 'msg_added_locations_cant_be_greater_than_num_locations' : ''}
            onClick={this.handleDuplicate}
            label={translator.getMessage('msg_clone')}
          />
        </div>
      </Modal>
    );
  }
}

export default DuplicateLocationModal;
