import AddendumsSection from '../../../../AddendumsSection';
import CollapsableCard from '../../../../CollapsableCard';

const AddendumsSectionWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //AddendumsSection props
  customerOrder,
  ...addendumModelProps
}) => {
  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
      contentWrapperClassName="full-width"
    >
      <AddendumsSection customerOrder={customerOrder} {...addendumModelProps} />
    </CollapsableCard>
  );
};

export default AddendumsSectionWrapper;
