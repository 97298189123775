export const KEY_CODES = {
  Escape: 'Escape',
  Tab: 'Tab',
  Enter: 'Enter',
  Arrow: {
    Up: 'ArrowUp',
    Right: 'ArrowRight',
    Down: 'ArrowDown',
    Left: 'ArrowLeft',
  },
};
