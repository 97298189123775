import { PRICEBOOK_TAGS } from '../../common/enums';

export const quotePackageItems = {
  1: [
    {
      title: 'msg_hardware_and_setup',
      paymentType: {
        nrc: null,
        rc: 'hardwareMonthly1',
      },
    },
    {
      title: 'msg_recurring_service',
      paymentType: {
        nrc: null,
        rc: 'recurService1',
      },
    },
    {
      title: 'msg_promotions_and_discounts_mad',
      paymentType: {
        nrc: null,
        rc: 'totalRecurringDiscount1',
      },
    },
    {
      customRenderer: 'madMessageRow',
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      titleFullRow: true,
    },
    {
      title: 'msg_customer_total',
      paymentType: {
        nrc: null,
        rc: 'allInclusive1',
      },
      isBold: true,
    },
    {
      title: 'msg_amount_finance',
      paymentType: {
        nrc: 'proposalUpfront1',
        rc: null,
      },
      internalOnly: true,
      isBold: true,
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'partnerMarkup',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_items',
      paymentType: {
        nrc: 'lineSumNrcPartnerItemTotals1',
        rc: 'lineSumRcPartnerItemTotals1',
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_provided_ictp',
      paymentType: {
        nrc: 'ictppsPartnerLineItemTotal',
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_pass_through_total',
      paymentType: {
        nrc: 'upfrontPassthrough1',
        rc: 'recurringPassthrough1',
      },
      isBold: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'markupRatio',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_recurring_commissionable_base',
      paymentType: {
        nrc: null,
        rc: 'partnerComissBase1',
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_spiff@ @msg_spiff_eligibility_requirements_note',
      paymentType: {
        nrc: 'spiffAmount1',
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_sangoma_phone_incentive',
      paymentType: {
        nrc: 'checkpoint623',
        rc: null,
      },
      showForTags: [PRICEBOOK_TAGS.SUPPORTS_SANGOMA_PHONE_INCENTIVE],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'taxEstimates',
    },
  ],

  2: [
    {
      title: 'msg_hardware_and_setup',
      paymentType: {
        nrc: null,
        rc: 'hardwareMonthly2',
      },
    },
    {
      title: 'msg_recurring_service',
      paymentType: {
        nrc: null,
        rc: 'recurService2',
      },
    },
    {
      title: 'msg_promotions_and_discounts_mad',
      paymentType: {
        nrc: null,
        rc: 'totalRecurringDiscount2',
      },
    },
    {
      customRenderer: 'madMessageRow',
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      titleFullRow: true,
    },
    {
      title: 'msg_customer_total',
      paymentType: {
        nrc: null,
        rc: 'allInclusive2',
      },
      isBold: true,
    },
    {
      title: 'msg_amount_finance',
      paymentType: {
        nrc: 'proposalUpfront2',
        rc: null,
      },
      internalOnly: true,
      isBold: true,
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'partnerMarkup',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_items',
      paymentType: {
        nrc: 'lineSumNrcPartnerItemTotals2',
        rc: 'lineSumRcPartnerItemTotals2',
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_provided_ictp',
      paymentType: {
        nrc: 'ictppsPartnerLineItemTotal',
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_pass_through_total',
      paymentType: {
        nrc: 'upfrontPassthrough2',
        rc: 'recurringPassthrough2',
      },
      isBold: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'markupRatio',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_recurring_commissionable_base',
      paymentType: {
        nrc: null,
        rc: 'partnerComissBase2',
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_spiff@ @msg_spiff_eligibility_requirements_note',
      paymentType: {
        nrc: 'spiffAmount2',
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_sangoma_phone_incentive',
      paymentType: {
        nrc: 'checkpoint623',
        rc: null,
      },
      showForTags: [PRICEBOOK_TAGS.SUPPORTS_SANGOMA_PHONE_INCENTIVE],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'taxEstimates',
    },
  ],

  3: [
    {
      title: 'msg_hardware_and_setup',
      paymentType: {
        nrc: 'hardwareAndSetup3',
        rc: null,
      },
    },
    {
      title: 'msg_recurring_service',
      paymentType: {
        nrc: null,
        rc: 'recurService3',
      },
    },
    {
      title: 'msg_allocation_of_maximum_allowable_discount',
      paymentType: {
        nrc: store => parseFloat(100 - parseFloat(store['madRecurPrcnt3'])).toFixed(2),
        rc: 'madRecurPrcnt3',
      },
      marker: '%',
    },
    {
      title: 'msg_promotions_and_discounts_mad',
      paymentType: {
        nrc: 'totalNrcDiscount3',
        rc: 'totalRecurringDiscount3',
      },
    },
    {
      title: 'msg_customer_total',
      paymentType: {
        nrc: 'proposalUpfront3',
        rc: 'proposalRecur3',
      },
      isBold: true,
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'partnerMarkup',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_items',
      paymentType: {
        nrc: 'lineSumNrcPartnerItemTotals3',
        rc: 'lineSumRcPartnerItemTotals3',
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_provided_ictp',
      paymentType: {
        nrc: 'ictppsPartnerLineItemTotal',
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_pass_through_total',
      paymentType: {
        nrc: 'upfrontPassthrough3',
        rc: 'recurringPassthrough3',
      },
      isBold: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'markupRatio',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_recurring_commissionable_base',
      paymentType: {
        nrc: null,
        rc: 'partnerComissBase3',
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_spiff@ @msg_spiff_eligibility_requirements_note',
      paymentType: {
        nrc: 'spiffAmount3',
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_sangoma_phone_incentive',
      paymentType: {
        nrc: 'checkpoint623',
        rc: null,
      },
      showForTags: [PRICEBOOK_TAGS.SUPPORTS_SANGOMA_PHONE_INCENTIVE],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_dealer_net',
      paymentType: {
        nrc: null,
        rc: null,
      },
      isBold: true,
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'dealerNetSection',
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'estimatedLeasePayment',
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'taxEstimates',
    },
  ],

  4: [
    {
      title: 'msg_hardware_and_setup',
      paymentType: {
        nrc: 'hardwareAndSetup4',
        rc: null,
      },
    },
    {
      title: 'msg_recurring_service',
      paymentType: {
        nrc: null,
        rc: 'recurService4',
      },
    },
    {
      title: 'msg_allocation_of_maximum_allowable_discount',
      paymentType: {
        nrc: store => parseFloat(100 - parseFloat(store['madRecurPrcnt4'])).toFixed(2),
        rc: 'madRecurPrcnt4',
      },
      marker: '%',
    },
    {
      title: 'msg_promotions_and_discounts_mad',
      paymentType: {
        nrc: 'totalNrcDiscount4',
        rc: 'totalRecurringDiscount4',
      },
    },
    {
      title: 'msg_customer_total',
      paymentType: {
        nrc: 'proposalUpfront4',
        rc: 'proposalRecur4',
      },
      isBold: true,
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'partnerMarkup',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_items',
      paymentType: {
        nrc: 'lineSumNrcPartnerItemTotals4',
        rc: 'lineSumRcPartnerItemTotals4',
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_partner_provided_ictp',
      paymentType: {
        nrc: 'ictppsPartnerLineItemTotal',
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_pass_through_total',
      paymentType: {
        nrc: 'upfrontPassthrough4',
        rc: 'recurringPassthrough4',
      },
      isBold: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'markupRatio',
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_recurring_commissionable_base',
      paymentType: {
        nrc: null,
        rc: 'partnerComissBase4',
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_spiff@ @msg_spiff_eligibility_requirements_note',
      paymentType: {
        nrc: 'spiffAmount4',
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_sangoma_phone_incentive',
      paymentType: {
        nrc: 'checkpoint623',
        rc: null,
      },
      showForTags: [PRICEBOOK_TAGS.SUPPORTS_SANGOMA_PHONE_INCENTIVE],
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      hideIfWholesale: true,
      hideForTags: [PRICEBOOK_TAGS.HIDE_PARTNER_MARGIN_ELEMENTS],
    },
    {
      title: 'msg_dealer_net',
      paymentType: {
        nrc: null,
        rc: null,
      },
      isBold: true,
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'dealerNetSection',
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'estimatedLeasePayment',
    },
    {
      title: '',
      paymentType: {
        nrc: null,
        rc: null,
      },
      customRenderer: 'taxEstimates',
    },
  ],
};
