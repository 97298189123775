import classNames from 'classnames';

const SliderTooltip = ({ value, dragging }) => {
  const contentClassName = classNames({
    'rc-slider-tooltip-content': true,
    dragging: dragging,
    'is-narrow': parseFloat(value) < 10,
  });

  return (
    <div className={contentClassName}>
      <div className="rc-slider-tooltip-arrow"></div>
      <div className="rc-slider-tooltip-inner" role="tooltip">
        {value}
      </div>
    </div>
  );
};

export default SliderTooltip;
