import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translator from '../../../../common/translate/index';
import { SIGNATURE_STATUS } from '../../../../common/enums';
import ReassignmentsCell from './ReassignmentsCell';
import StatusCell from './StatusCell';
import EmailCell from './EmailCell';
import dayjs from 'dayjs';

const SIGNERS_TYPE = {
  CUSTOMER_SIGNER: translator.getMessage('msg_customer_signing'),
  COUNTER_SIGNER: translator.getMessage('msg_counter_signing'),
};

class DetailsSignersTable extends Component {
  static propTypes = {
    customerSigners: PropTypes.arrayOf(PropTypes.object),
    counterSigners: PropTypes.arrayOf(PropTypes.object),
    showCounterSigner: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    bundleType: PropTypes.string,
    signatureStatus: PropTypes.string,
    signatureId: PropTypes.number,
    onChangeSignerEmail: PropTypes.func,
    requestEmailChange: PropTypes.func,
    bounced: PropTypes.bool,
    bouncedEmailAddress: PropTypes.string,
    signerWithReminderId: PropTypes.number,
  };

  static defaultProps = {
    customerSigners: [],
    counterSigners: [],
    showCounterSigner: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      editEmail: false,
      signerId: null,
      initialEmailValue: '',
    };

    this.onEditEmailClick = this.onEditEmailClick.bind(this);
    this.onCancelEmailEditClick = this.onCancelEmailEditClick.bind(this);
    this.onApproveEmailEditClick = this.onApproveEmailEditClick.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  onEditEmailClick(signerId, initialEmailValue) {
    // cancel edit if other email was in edit state
    if (this.state.signerId !== null) {
      this.onEmailChange(this.state.signerId, this.state.initialEmailValue);
    }

    this.setState({
      editEmail: true,
      signerId,
      initialEmailValue,
    });
  }

  onCancelEmailEditClick() {
    this.onEmailChange(this.state.signerId, this.state.initialEmailValue);

    this.setState({
      editEmail: false,
      signerId: null,
      initialEmailValue: '',
    });
  }

  onApproveEmailEditClick(email) {
    if (typeof this.props.requestEmailChange === 'function' && email !== this.state.initialEmailValue) {
      this.props.requestEmailChange(this.props.signatureId, this.state.signerId, email, this.state.initialEmailValue);
    }

    this.setState({
      editEmail: false,
      signerId: null,
      initialEmailValue: '',
    });
  }

  onEmailChange(signerId, val) {
    if (typeof this.props.onChangeSignerEmail === 'function') {
      this.props.onChangeSignerEmail(this.props.signatureId, signerId, val);
    }
  }

  renderRows(columnWidth) {
    const { customerSigners, counterSigners, showCounterSigner } = this.props;
    const rows = [];
    const headers = [
      translator.getMessage('msg_name'),
      translator.getMessage('msg_email'),
      translator.getMessage('msg_last_viewed'),
      translator.getMessage('msg_last_reminded'),
      translator.getMessage('msg_signed_on'),
      translator.getMessage('msg_status'),
      translator.getMessage('msg_decline_reason'),
      translator.getMessage('msg_reassignments'),
    ];

    for (let i = 0; i < headers.length; i++) {
      const rowIndex = rows.length;
      const cells = [
        <th key={'signers-cell-' + rowIndex + '-th'} className="align-right">
          {headers[i]}
        </th>,
      ];
      let signers = customerSigners;

      if (showCounterSigner) {
        signers = signers.concat(counterSigners);
      }

      signers.forEach((signer, signerIndex) =>
        cells.push(
          <td
            id={['signers-cell', rowIndex, i, signerIndex].join('-')}
            key={['signers-cell', rowIndex, i, signerIndex].join('-')}
            width={columnWidth + '%'}
          >
            {this.renderSignerCell(signer, i)}
          </td>
        )
      );

      rows.push(
        <tr id={'signers-row-' + rowIndex} key={'signers-row-' + rowIndex} className="valign-middle">
          {cells}
        </tr>
      );
    }

    return rows;
  }

  renderSignerCell(signer, index) {
    switch (index) {
      case 0:
        return signer.name;
      case 1:
        const emailCellProps = {
          ...signer,
          isSalesOps: this.props.isSalesOps,
          isEmailEdit: this.state.editEmail && this.state.signerId === signer.id,
          onEmailChange: this.onEmailChange,
          onApproveEmailEditClick: this.onApproveEmailEditClick,
          onCancelEmailEditClick: this.onCancelEmailEditClick,
          onEditEmailClick: this.onEditEmailClick,
        };

        return <EmailCell {...emailCellProps} />;
      case 2:
        return signer.lastViewedOn ? dayjs(signer.lastViewedOn).format('MM/DD/YYYY h:mm:ss A') : '-';
      case 3:
        return signer.lastRemindedOn ? dayjs(signer.lastRemindedOn).format('MM/DD/YYYY h:mm:ss A') : '-';
      case 4:
        return signer.signedOn ? dayjs(signer.signedOn).format('MM/DD/YYYY h:mm:ss A') : '-';
      case 5:
        const statusCellProps = {
          displayReminderIcon: signer.id === this.props.signerWithReminderId,
          sendReminderToSigner: this.props.sendReminderToSigner,
          signatureId: this.props.signatureId,
          signerId: signer.id,
          status:
            this.props.bounced && this.props.bouncedEmailAddress === signer.email
              ? SIGNATURE_STATUS.BOUNCED
              : signer.status,
        };

        return <StatusCell {...statusCellProps} />;
      case 6:
        return signer.declineReason || '-';
      case 7:
        return <ReassignmentsCell reassignments={signer.eSignerReassignmentLogs} />;
      default:
        return null;
    }
  }

  render() {
    const { customerSigners, counterSigners, showCounterSigner } = this.props;

    if (!customerSigners.length) {
      return null;
    }

    const tableClassNames = ['signature-details-signers-table', 'rq-table', 'striped', 'fit-width'].join(' ');
    const thWidth = 17;
    const numColumns = showCounterSigner ? customerSigners.length + counterSigners.length : customerSigners.length;
    const columnWidth = Math.floor((100 - thWidth) / numColumns);

    return (
      <table className={tableClassNames}>
        <thead>
          <tr>
            <th width={thWidth + '%'}>&nbsp;</th>
            <th colSpan={customerSigners.length}>{SIGNERS_TYPE.CUSTOMER_SIGNER}</th>
            {showCounterSigner ? <th colSpan={counterSigners.length}>{SIGNERS_TYPE.COUNTER_SIGNER}</th> : null}
          </tr>
        </thead>
        <tbody>{this.renderRows(columnWidth)}</tbody>
      </table>
    );
  }
}

export default DetailsSignersTable;
