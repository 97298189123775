export default function formatString(string, formatMessage) {
  if (string === '') {
    return null;
  }

  let translatedString = '';
  const stringPieces = string.split('@');
  const { length } = stringPieces;

  for (let i = 0; i < length; i++) {
    const stringPiece = stringPieces[i];

    translatedString +=
      stringPiece.trim().substr(0, 4) === 'msg_' ? formatMessage({ id: stringPiece.trim() }) : stringPiece;
  }

  return translatedString;
}
