import React, { Component } from 'react';
import { FrameComponent, QuoteTableListComponent } from '../components/views';

class QuotesRetrieveLayout extends Component {
  render() {
    return (
      <FrameComponent {...this.props}>
        <QuoteTableListComponent {...this.props} />
      </FrameComponent>
    );
  }
}

export default QuotesRetrieveLayout;
