import { ModelAbstract } from './';
import ERROR_TEXT_COLLECTION from '../common/ERROR_TEXT_COLLECTION';
import { ORDER_TYPE_OPTIONS, PARTNER_TYPES, QUOTE_SERVICE_TYPE, QUOTE_SERVICE_TYPE_OPTIONS } from '../common/enums';
import { AuthContext } from '../Context';
import $http from '../common/$http';
import config from '../config';

const disabledCustomerActionIds = [null, 1];
const disabledLocationActionIds = [null, 1, 2];

class Validate {
  static selectedPartnerId(partner) {
    return partner ? '' : ERROR_TEXT_COLLECTION.PARTNER_SHOULD_BE_SELECTED;
  }

  static selectedAction(action) {
    return action ? '' : 'msg_create_quote_error_order_type';
  }

  static selectedCustomerId(customer, action) {
    return customer || action === ORDER_TYPE_OPTIONS.NEW_CUSTOMER
      ? ''
      : ERROR_TEXT_COLLECTION.CUSTOMER_SHOULD_BE_SELECTED;
  }

  static selectedLocationId(location, action) {
    return location || action !== ORDER_TYPE_OPTIONS.ADD_ON ? '' : ERROR_TEXT_COLLECTION.LOCATION_SHOULD_BE_SELECTED;
  }

  static selectedLocationIds(locations, action) {
    return locations.length || action !== ORDER_TYPE_OPTIONS.REWRITE
      ? ''
      : ERROR_TEXT_COLLECTION.AT_LEAST_ONE_LOCATION_SHOULD_BE_SELECTED;
  }

  static whiteLabelPartner(partnerType, isSalesOps, action, isSwitchvoxSipStation) {
    if (action === ORDER_TYPE_OPTIONS.REWRITE || !isSwitchvoxSipStation) {
      return '';
    }

    return partnerType === PARTNER_TYPES.WHITE_LABEL_WHOLESALE && !isSalesOps
      ? ERROR_TEXT_COLLECTION.WLW_PARTNER_TYPE
      : '';
  }

  static isNewLocationFinalized(isNewLocationFinalized, action, isSwitchvoxSipStation) {
    if (action !== ORDER_TYPE_OPTIONS.ADD_ON) {
      return '';
    }

    return !isNewLocationFinalized && isSwitchvoxSipStation ? ERROR_TEXT_COLLECTION.IS_NEW_LOCATION_FINALIZED : '';
  }

  static sipStationNotSupportedForRewrites(action, isSwitchvoxSipStation) {
    if (action !== ORDER_TYPE_OPTIONS.REWRITE) {
      return '';
    }

    return isSwitchvoxSipStation ? ERROR_TEXT_COLLECTION.SIP_STATION_NOT_SUPPORTED_FOT_REWRITES : '';
  }

  static nonFinalizedStandaloneLocation(action, isNewLocationFinalized, isStandaloneServiceNonUCaaS) {
    if (action !== ORDER_TYPE_OPTIONS.ADD_ON) {
      return '';
    }

    return !isNewLocationFinalized && isStandaloneServiceNonUCaaS
      ? ERROR_TEXT_COLLECTION.STANDALONE_LOCATION_MUST_BE_FINALIZED
      : '';
  }

  static sangomaCXStandaloneExistNew(action, isExistingCustomerSangomaCXStandalone) {
    if (action !== ORDER_TYPE_OPTIONS.NEW_LOCATIONS) {
      return '';
    }

    return isExistingCustomerSangomaCXStandalone ? ERROR_TEXT_COLLECTION.SANGOMA_STANDALONE_ONLY_SINGLE_LOC : '';
  }

  static sangomaCXStandaloneRewrite(action, isSangomaCXStandalone, locationsLength) {
    if (action !== ORDER_TYPE_OPTIONS.REWRITE) {
      return '';
    }

    return isSangomaCXStandalone && locationsLength > 1 ? ERROR_TEXT_COLLECTION.SANGOMA_STANDALONE_ONLY_SINGLE_LOC : '';
  }

  static industryTypeValidation(action, industryTypeId) {
    if (action !== ORDER_TYPE_OPTIONS.NEW_CUSTOMER) {
      return '';
    }

    return industryTypeId === null ? ERROR_TEXT_COLLECTION.INDUSTRY_TYPE_SHOULD_BE_SELECTED : '';
  }
}

class OrderInitiationModel extends ModelAbstract {
  static ACTIONS_MSG_LIST = {
    [ORDER_TYPE_OPTIONS.NEW_CUSTOMER]: 'msg_new_customer',
    [ORDER_TYPE_OPTIONS.NEW_LOCATIONS]: 'msg_new_locations',
    [ORDER_TYPE_OPTIONS.ADD_ON]: 'msg_add_on_to_existing_location',
    [ORDER_TYPE_OPTIONS.REWRITE]: 'msg_rewrite_order',
    [ORDER_TYPE_OPTIONS.REDUCTION]: 'msg_reduction_from_existing_location',
  };

  _startErrorsText = [];
  _selectedAction = null;
  _locationBcHash = {};
  _selectedLocationIds = [];
  _serviceType = QUOTE_SERVICE_TYPE.businessVoicePlatform;
  _isExistingCustomerSangomaCXStandalone = false;
  _selectedLocationId = null;
  _selectedCustomerId = null;

  partners = [];
  actions = {};
  customers = [];
  locations = [];
  selectedPartnerId = null;
  partnerLocked = true;
  bcQuote = false;
  actionOptionsLocked = [];
  fieldsErrors = {
    selectedPartnerId: '',
    selectedAction: '',
    selectedCustomerId: '',
    selectedLocationId: '',
    selectedLocationIds: '',
  };
  customersLoadedForPartnerId = null;
  standaloneServiceNonUCaaS = false;
  isExistingCustomerSwitchvoxSipStation = false;
  selectedPartnerType = null;
  selectedPartnerTier = null;
  isNewLocationFinalized = false;
  industryTypes = [];
  selectedIndustryTypeId = null;

  constructor(props) {
    super(props);

    this.isSalesForceOrder = false;
    this.quoteTitle = '';

    this.updateValidFields = this.updateValidFields.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeServiceType = this.onChangeServiceType.bind(this);
    this.onChangeIndustryTypeId = this.onChangeIndustryTypeId.bind(this);

    this.updateValidFields();
  }

  onChange(prop, value) {
    if (this.canSet(prop)) {
      this[prop] = value;
    }

    this.updateValidFields();
    this.modelChanged(this);
  }

  updateValidFields() {
    this.fieldsErrors = {
      selectedPartnerId: Validate.selectedPartnerId(this.selectedPartnerId),
      selectedAction: Validate.selectedAction(this.selectedAction),
      selectedCustomerId: Validate.selectedCustomerId(this.selectedCustomerId, this.selectedAction),
      selectedLocationId: Validate.selectedLocationId(this.selectedLocationId, this.selectedAction),
      selectedLocationIds: Validate.selectedLocationIds(this.selectedLocationIds, this.selectedAction),
      whiteLabelPartner: Validate.whiteLabelPartner(
        this.selectedPartnerType,
        AuthContext.model.isSalesOps,
        this.selectedAction,
        this.isSwitchvoxSipStation
      ),
      isNewLocationFinalized: Validate.isNewLocationFinalized(
        this.isNewLocationFinalized,
        this.selectedAction,
        this.isSwitchvoxSipStation
      ),
      sipStationNotSupportedForRewrites: Validate.sipStationNotSupportedForRewrites(
        this.selectedAction,
        this.isSwitchvoxSipStation
      ),
      nonFinalizedStandaloneLocation: Validate.nonFinalizedStandaloneLocation(
        this.selectedAction,
        this.isNewLocationFinalized,
        this.isStandaloneServiceNonUCaaS
      ),
      sangomaCXStandaloneExistNew: Validate.sangomaCXStandaloneExistNew(
        this.selectedAction,
        this.isExistingCustomerSangomaCXStandalone
      ),
      sangomaCXStandaloneRewrite: Validate.sangomaCXStandaloneRewrite(
        this.selectedAction,
        this.isSangomaCXStandalone,
        this.locations.length
      ),
      industryTypeValidation: Validate.industryTypeValidation(this.selectedAction, this.selectedIndustryTypeId),
    };
  }

  onChangeServiceType(value) {
    this.onChange('serviceType', value);
  }

  get invalidFieldsCnt() {
    return Object.keys(this.fieldsErrors).filter(error => !!this.fieldsErrors[error].length).length;
  }

  get startBtnDisabled() {
    return !!this.invalidFieldsCnt;
  }

  get startErrorsText() {
    this._startErrorsText = [];

    for (let key in this.fieldsErrors) {
      if (this.fieldsErrors[key] !== '') {
        this._startErrorsText.push(this.fieldsErrors[key]);
      }
    }

    return this._startErrorsText;
  }

  get selectedAction() {
    return this._selectedAction;
  }

  set selectedAction(v) {
    const normalizedValue = Number(v);

    if (normalizedValue === ORDER_TYPE_OPTIONS.NEW_LOCATIONS && !AuthContext.model.hasSalesOpsPermissions) {
      this.bcQuote = false;
    }

    // set serviceType do default value to prevent disabled value to be selected
    this._serviceType = QUOTE_SERVICE_TYPE.businessVoicePlatform;
    this._selectedAction = normalizedValue;
  }

  get locationBcHash() {
    return this._locationBcHash;
  }

  get customerDisabled() {
    if (this.isSalesForceOrder) {
      return false;
    }

    return Boolean(
      disabledCustomerActionIds.includes(this.selectedAction) ||
        this.selectedPartnerId === null ||
        this.customers.length === 0
    );
  }

  get locationDisabled() {
    if (this.isSalesForceOrder) {
      return false;
    }

    return Boolean(
      disabledLocationActionIds.includes(this.selectedAction) ||
        this.selectedCustomerId === null ||
        this.locations.length === 0
    );
  }

  get selectedLocationIds() {
    return this._selectedLocationIds;
  }

  set selectedLocationIds(v) {
    if (!v) {
      this._selectedLocationIds = [];
    } else {
      const idx = this._selectedLocationIds.indexOf(v);

      if (idx === -1) {
        this._selectedLocationIds.push(v);
      } else {
        this._selectedLocationIds.splice(idx, 1);
      }
    }
  }

  get partnerInfoLabel() {
    if (
      (!this.selectedPartnerType && !this.selectedPartnerTier) ||
      this.selectedPartnerType === PARTNER_TYPES.STARCLOUD_PLUS_4_0
    ) {
      return '';
    }

    const type = this.selectedPartnerType ? `@msg_type@: ${this.selectedPartnerType} ` : '';
    const delimiter = this.selectedPartnerType && this.selectedPartnerTier ? '/ ' : '';
    const tier = this.selectedPartnerTier ? `@msg_tier@: ${this.selectedPartnerTier}` : '';

    return type + delimiter + tier;
  }

  get serviceType() {
    if (this.isExistingCustomerSwitchvoxSipStation && this.selectedAction !== ORDER_TYPE_OPTIONS.NEW_CUSTOMER) {
      return QUOTE_SERVICE_TYPE.swvxSipstation;
    }

    if (this.isExistingCustomerSangomaCXStandalone && this.selectedAction !== ORDER_TYPE_OPTIONS.REWRITE) {
      return QUOTE_SERVICE_TYPE.sangomaCXStandalone;
    }

    // standaloneServiceNonUCaaS is fetching only for addons and isExistingCustomerSwitchvoxSipStation has higher priority
    if (this.standaloneServiceNonUCaaS && this.selectedAction === ORDER_TYPE_OPTIONS.ADD_ON) {
      return QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
    }

    return this._serviceType;
  }

  set serviceType(value) {
    this._serviceType = value;
  }

  get serviceTypeOptions() {
    return QUOTE_SERVICE_TYPE_OPTIONS.map(opt => {
      opt.disabled = false;

      if (
        ([ORDER_TYPE_OPTIONS.NEW_LOCATIONS, ORDER_TYPE_OPTIONS.REWRITE].includes(this.selectedAction) &&
          opt.value === QUOTE_SERVICE_TYPE.swvxSipstation) ||
        (![PARTNER_TYPES.STARCLOUD_PLUS_4_0, PARTNER_TYPES.FULL_SERVICE_WHOLESALE].includes(this.selectedPartnerType) &&
          opt.value === QUOTE_SERVICE_TYPE.sangomaCXStandalone) ||
        (ORDER_TYPE_OPTIONS.NEW_LOCATIONS === this.selectedAction &&
          opt.value === QUOTE_SERVICE_TYPE.sangomaCXStandalone) ||
        (ORDER_TYPE_OPTIONS.REWRITE === this.selectedAction &&
          this.isExistingCustomerSangomaCXStandalone &&
          [QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS, QUOTE_SERVICE_TYPE.swvxSipstation].includes(opt.value))
      ) {
        opt.disabled = true;
      }

      return opt;
    });
  }

  get serviceTypeDisabled() {
    return Boolean(
      this.selectedAction === ORDER_TYPE_OPTIONS.ADD_ON ||
        ([ORDER_TYPE_OPTIONS.NEW_LOCATIONS, ORDER_TYPE_OPTIONS.REWRITE].includes(this.selectedAction) &&
          this.isExistingCustomerSwitchvoxSipStation) ||
        (this.selectedAction === ORDER_TYPE_OPTIONS.NEW_LOCATIONS && this.isExistingCustomerSangomaCXStandalone)
    );
  }

  get isSwitchvoxSipStation() {
    return this.serviceType === QUOTE_SERVICE_TYPE.swvxSipstation;
  }

  get isStandaloneServiceNonUCaaS() {
    return this.serviceType === QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
  }

  get isCustomerRequired() {
    return [ORDER_TYPE_OPTIONS.NEW_LOCATIONS, ORDER_TYPE_OPTIONS.ADD_ON, ORDER_TYPE_OPTIONS.REWRITE].includes(
      this.selectedAction
    );
  }

  get isLocationRequired() {
    return [ORDER_TYPE_OPTIONS.ADD_ON, ORDER_TYPE_OPTIONS.REWRITE].includes(this.selectedAction);
  }

  get isSangomaCXStandalone() {
    return this.serviceType === QUOTE_SERVICE_TYPE.sangomaCXStandalone;
  }

  get isExistingCustomerSangomaCXStandalone() {
    if (
      ![PARTNER_TYPES.STARCLOUD_PLUS_4_0, PARTNER_TYPES.FULL_SERVICE_WHOLESALE].includes(this.selectedPartnerType) ||
      this.selectedAction === ORDER_TYPE_OPTIONS.NEW_CUSTOMER
    ) {
      return false;
    }

    return this._isExistingCustomerSangomaCXStandalone;
  }

  set isExistingCustomerSangomaCXStandalone(v) {
    this._isExistingCustomerSangomaCXStandalone = v;
  }

  onChangeIndustryTypeId(v) {
    this.onChange('selectedIndustryTypeId', v);
  }

  get selectedLocationId() {
    if (this.locationDisabled) {
      return null;
    }

    return this._selectedLocationId;
  }

  set selectedLocationId(v) {
    this._selectedLocationId = v;
  }

  get selectedCustomerId() {
    if (this.customerDisabled) {
      return null;
    }

    return this._selectedCustomerId;
  }

  set selectedCustomerId(v) {
    this._selectedCustomerId = v;
  }

  async asyncLoadPartnerInfo({ billingDealerId, dealerId }) {
    if (!dealerId) {
      dealerId = this.partners.find(partner => partner.billingDealerId === billingDealerId)?.dealerId;
    }

    if (!dealerId) {
      throw new Error('Unable to load partner info. dealerId must be set');
    }

    try {
      const url = config.api.url + '/partners/' + encodeURIComponent(dealerId);
      const { type, tier } = (await $http.instance.api.get(url))?.data?.content;

      this.setProps({
        selectedPartnerType: type || '',
        selectedPartnerTier: tier || '',
      });

      this.updateValidFields();
    } catch (e) {
      // TODO: Add graceful error handling
      throw e;
    }
  }

  async asyncLoadSwitchvoxSipStation({ billingDealerId, customerId }) {
    try {
      const url = [
        config.api.url,
        'partners',
        encodeURIComponent(billingDealerId),
        'customers',
        encodeURIComponent(customerId),
        'isSwitchvoxSIPStation',
      ].join('/');

      const r = await $http.instance.api.get(url);

      if (r?.data?.status !== 200) {
        throw new Error('OrderInitiationModel.asyncLoadSwitchvoxSipStation: Status code 200 expected');
      }

      this.onChange('isExistingCustomerSwitchvoxSipStation', Boolean(r.data.content));
    } catch (e) {
      // TODO: Add graceful error handling
      throw e;
    }
  }

  async asyncLoadIsSangomaCXStandalone({ billingDealerId, customerId }) {
    try {
      const url = [
        config.api.url,
        'partners',
        encodeURIComponent(billingDealerId),
        'customers',
        encodeURIComponent(customerId),
        'isSangomaCXStandalone',
      ].join('/');

      const r = await $http.instance.api.get(url);

      if (r?.data?.status !== 200) {
        throw new Error('OrderInitiationModel.asyncLoadIsSangomaCXStandalone: Status code 200 expected');
      }

      const value = Boolean(r.data.content);

      this.isExistingCustomerSangomaCXStandalone = value;

      if (value && ORDER_TYPE_OPTIONS.REWRITE === this.selectedAction) {
        this.serviceType = QUOTE_SERVICE_TYPE.sangomaCXStandalone;
      }

      this.updateValidFields();
    } catch (e) {
      // TODO: Add graceful error handling
      throw e;
    }
  }

  async asyncLoadLocationBusinessContinuity(locationId) {
    try {
      const url = [config.api.url, 'location', encodeURIComponent(locationId), 'isBusinessContinuity'].join('/');

      const r = await $http.instance.api.get(url);
      this.bcQuote = Boolean(r?.data?.content);
      this.locationBcHash[locationId] = this.bcQuote;
    } catch (e) {
      // TODO: Add graceful error handling
      throw e;
    }
  }

  async asyncLoadIsNewLocationFinalized(locationId) {
    try {
      const url = [config.api.url, 'location', encodeURIComponent(locationId), 'isNewLocationFinalized'].join('/');

      const r = await $http.instance.api.get(url);
      this.isNewLocationFinalized = Boolean(r?.data?.content);
    } catch (e) {
      this.isNewLocationFinalized = false;

      // TODO: Add graceful error handling
      throw e;
    }
  }

  async asyncLoadIsStandaloneServiceNonUCaaS(locationId) {
    try {
      const url = [config.api.url, 'location', encodeURIComponent(locationId), 'isStandaloneServiceNonUCaaS'].join('/');

      const r = await $http.instance.api.get(url);
      this.standaloneServiceNonUCaaS = Boolean(r?.data?.content);
    } catch (e) {
      this.standaloneServiceNonUCaaS = false;

      // TODO: Add graceful error handling
      throw e;
    }
  }
}

export default OrderInitiationModel;
