import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import * as formatter from '../../../common/helpers/format';
import translator from '../../../common/translate';
import { AmountControl, CheckboxControl } from '../../Controls';
import '../css/confirmation.css';
import AlternateDescription from './AlternateDescription';
import AdditionalDiscountTable from './AdditionalDiscountTable';
import bindMultiple from '../../../common/helpers/bindMultiple';
import ExternalLink from '../../ExternalLink';

class Confirmation extends Component {
  static propTypes = {
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    madRecurring: PropTypes.number,
    madNonRecurring: PropTypes.number,
    onChange: PropTypes.func,
    overridesVisible: PropTypes.bool,
    madPoolOverride: PropTypes.bool,
    madRecurringOverride: PropTypes.number,
    madUpfrontOverride: PropTypes.number,
    spiffOverride: PropTypes.number,
    spiffAmountCalculated: PropTypes.number,
    spiffDiscountOverridesForceDisabled: PropTypes.bool,
    isAltDescriptionsDisabled: PropTypes.bool,
    isCalcRequested: PropTypes.bool,
    showPartnerSpiff: PropTypes.bool,
    isAllInclusive: PropTypes.bool,
    showSpiffOverride: PropTypes.bool,
    spiffOverrideEnabled: PropTypes.bool,
    isSupportsSeparateSpiffOverride: PropTypes.bool,
    showAdditionalDiscountFields: PropTypes.bool,
    additionalRecurringDiscount: PropTypes.number,
    additionalUpfrontDiscount: PropTypes.number,
    calculatedMadRecurAmount: PropTypes.number,
    calculatedMadUpfrontAmount: PropTypes.number,
    additionalRcDiscountPercent: PropTypes.number,
    additionalUpfrontDiscountPercent: PropTypes.number,
    hideNrcAdditionalDiscountValues: PropTypes.bool,
    showSangomaPhoneIncentive: PropTypes.bool,
    sangomaPhoneIncentiveValue: PropTypes.number,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
    isConfirmed: false,
    spiffAmountCalculated: 0,
    madRecurring: 0,
    madNonRecurring: 0,
    packageIdSelected: 1,
    showSangomaPhoneIncentive: false,
    sangomaPhoneIncentiveValue: null,
  };

  constructor(props) {
    super(props);

    bindMultiple(
      this,
      this.handleChange,
      this.handleChangeIsConfirmed,
      this.handleChangeMadRecurringOverride,
      this.handleChangeMadUpfrontOverride,
      this.handleChangeSpiffOverride
    );
  }

  handleChange(propName, value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, propName);
    }
  }

  handleChangeIsConfirmed(value) {
    this.handleChange('isConfirmed', value);
  }

  handleChangeMadRecurringOverride(value) {
    this.handleChange('madRecurringOverride', value);
  }

  handleChangeMadUpfrontOverride(value) {
    this.handleChange('madUpfrontOverride', value);
  }

  handleChangeSpiffOverride(value) {
    this.handleChange('spiffOverride', value);
  }

  render() {
    const {
      lockedState,
      disabledState,
      isConfirmed,
      madPoolOverride,
      overridesVisible,
      madRecurringOverride,
      madUpfrontOverride,
      spiffOverride,
      spiffDiscountOverridesForceDisabled,
      recurringDiscountAltDescription,
      nonRecurringDiscountAltDescription,
      altDescriptionOptions,
      intl,
      isAltDescriptionsDisabled,
      isCalcRequested,
      showPartnerSpiff,
      isAllInclusive,
      showSpiffOverride,
      spiffOverrideEnabled,
      isSupportsSeparateSpiffOverride,
      onChange,
      showAdditionalDiscountFields,
      additionalRecurringDiscount,
      additionalUpfrontDiscount,
      calculatedMadRecurAmount,
      calculatedMadUpfrontAmount,
      additionalRcDiscountPercent,
      additionalUpfrontDiscountPercent,
      hideNrcAdditionalDiscountValues,
      showSangomaPhoneIncentive,
      sangomaPhoneIncentiveValue,
    } = this.props;

    const spiffOverrideValue =
      (!isSupportsSeparateSpiffOverride && madPoolOverride) || (isSupportsSeparateSpiffOverride && spiffOverrideEnabled);
    const spiffAmountCalculated = formatter.currency(
      Math.abs(spiffOverrideValue ? spiffOverride : this.props.spiffAmountCalculated)
    );
    // -1 * is to show overrides as negative values
    const madRecurring = formatter.currency(madPoolOverride ? -1 * madRecurringOverride : this.props.madRecurring);
    const madNonRecurring = formatter.currency(madPoolOverride ? -1 * madUpfrontOverride : this.props.madNonRecurring);
    const discountInputsDisabledState = !madPoolOverride || spiffDiscountOverridesForceDisabled || disabledState;
    const spiffInputsDisabledState =
      (!isSupportsSeparateSpiffOverride && !madPoolOverride) ||
      (isSupportsSeparateSpiffOverride && !spiffOverrideEnabled) ||
      spiffDiscountOverridesForceDisabled ||
      disabledState;

    const checkboxWrapperClassName = classNames({
      'discount-confirmation-checkbox': true,
      locked: lockedState || disabledState,
    });
    const discountConfirmationContainerClassName = classNames({
      'discount-confirmation-container': true,
      'with-additional-discount-fields': showAdditionalDiscountFields,
    });

    const recurringDescriptionOptions = altDescriptionOptions !== null ? altDescriptionOptions.recurring : [];
    const nonRecurringDescriptionOptions = altDescriptionOptions !== null ? altDescriptionOptions.nonRecurring : [];

    return (
      <div>
        <div className={discountConfirmationContainerClassName}>
          <div className={checkboxWrapperClassName}>
            <CheckboxControl
              id="madSpiffConfirmation"
              label={translator.getMessage('msg_please_acknowledge_that_you_accept_the_values_shown_below')}
              lockedState={lockedState}
              disabledState={disabledState || isCalcRequested}
              value={isConfirmed}
              onChange={this.handleChangeIsConfirmed}
            />
            {showPartnerSpiff ? (
              <ExternalLink
                path="https://experience.sangoma.com/sangomapartnerboard"
                className="confirmation-spiff-link"
                title={translator.getMessage('msg_terms_and_conditions')}
              />
            ) : null}
          </div>
          <div className="discount-confirmation-table">
            {showAdditionalDiscountFields ? (
              <AdditionalDiscountTable
                onChange={onChange}
                rcDescriptionOptions={recurringDescriptionOptions}
                rcDescriptionValue={recurringDiscountAltDescription}
                nrcDescriptionOptions={nonRecurringDescriptionOptions}
                nrcDescriptionValue={nonRecurringDiscountAltDescription}
                lockedState={lockedState}
                disabledState={disabledState}
                isAltDescriptionsDisabled={isAltDescriptionsDisabled}
                additionalRecurringDiscount={additionalRecurringDiscount}
                additionalUpfrontDiscount={additionalUpfrontDiscount}
                calculatedMadRecurAmount={calculatedMadRecurAmount}
                calculatedMadUpfrontAmount={calculatedMadUpfrontAmount}
                madRecurringFormatted={madRecurring}
                madNonRecurringFormatted={madNonRecurring}
                additionalRcDiscountPercent={additionalRcDiscountPercent}
                additionalUpfrontDiscountPercent={additionalUpfrontDiscountPercent}
                hideAdditionalDiscountValues={madPoolOverride || spiffOverrideEnabled}
                hideNrcAdditionalDiscountValues={hideNrcAdditionalDiscountValues}
                disableAdditionalDiscountInputs={isConfirmed}
                displayMadOverrideInputs={overridesVisible && madPoolOverride}
                discountInputsDisabledState={discountInputsDisabledState}
                madRecurringOverride={madRecurringOverride}
                madUpfrontOverride={madUpfrontOverride}
                isAllInclusive={isAllInclusive}
              />
            ) : (
              <>
                <div className="confirmation-table-row">
                  <span className="heading">
                    <strong>{translator.getMessage('msg_customer_discount_spiff_summary')}</strong>
                  </span>
                </div>
                <div className="confirmation-table-row persist-line-height mad-recurring">
                  <div
                    title={intl.formatMessage({
                      id: 'msg_alternate_quote_description',
                    })}
                  >
                    <AlternateDescription
                      id="alternate-description-recurring"
                      onChange={onChange}
                      value={recurringDiscountAltDescription}
                      lockedState={lockedState}
                      disabledState={disabledState || isAltDescriptionsDisabled}
                      type={'recurringDiscountAltDescription'}
                      altDescriptionOptions={recurringDescriptionOptions}
                    />
                  </div>
                  {overridesVisible && madPoolOverride ? (
                    <AmountControl
                      id="madRecurringOverride"
                      onChange={this.handleChangeMadRecurringOverride}
                      hideErrorText
                      lockedState={lockedState}
                      disabledState={discountInputsDisabledState}
                      value={madRecurringOverride}
                    />
                  ) : (
                    <span className="value-cell">{madRecurring}</span>
                  )}
                </div>
                <div className="confirmation-table-row persist-line-height mad-upfront">
                  <div
                    title={intl.formatMessage({
                      id: 'msg_alternate_quote_description',
                    })}
                  >
                    <AlternateDescription
                      id="alternate-description-non-recurring"
                      onChange={this.props.onChange}
                      value={nonRecurringDiscountAltDescription}
                      lockedState={lockedState}
                      disabledState={disabledState || isAltDescriptionsDisabled}
                      type="nonRecurringDiscountAltDescription"
                      altDescriptionOptions={nonRecurringDescriptionOptions}
                    />
                  </div>
                  {overridesVisible && madPoolOverride ? (
                    <AmountControl
                      id="madUpfrontOverride"
                      onChange={this.handleChangeMadUpfrontOverride}
                      hideErrorText
                      lockedState={lockedState}
                      disabledState={discountInputsDisabledState || isAllInclusive}
                      value={madUpfrontOverride}
                    />
                  ) : (
                    <span className="value-cell">{madNonRecurring}</span>
                  )}
                </div>
              </>
            )}
            {showPartnerSpiff && (
              <div className="persist-line-height spiff confirmation-table-row">
                <span>
                  {translator.getMessage('msg_spiff')}
                  {showAdditionalDiscountFields && (
                    <React.Fragment>
                      &nbsp;
                      <small>{translator.getMessage('msg_spiff_eligibility_requirements_note')}</small>
                    </React.Fragment>
                  )}
                  {!showAdditionalDiscountFields && (
                    <React.Fragment>
                      <br />
                      <small>{translator.getMessage('msg_spiff_eligibility_requirements_note')}</small>
                    </React.Fragment>
                  )}
                </span>

                {overridesVisible && showSpiffOverride ? (
                  <AmountControl
                    id="spiffOverride "
                    onChange={this.handleChangeSpiffOverride}
                    hideErrorText
                    lockedState={lockedState}
                    disabledState={spiffInputsDisabledState}
                    value={spiffOverride}
                  />
                ) : (
                  <span className="value-cell">{spiffAmountCalculated}</span>
                )}
              </div>
            )}
            {showSangomaPhoneIncentive ? (
              <div className="confirmation-table-row persist-line-height">
                <span>{translator.getMessage('msg_sangoma_phone_incentive')}</span>
                <span className="value-cell">{formatter.currency(sangomaPhoneIncentiveValue)}</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Confirmation);
