export const API_PROPERTIES_MAPPING = {
  esignature: {
    allowAnyoneToChoosePrintFlow: 'integration.esignature.allowAnyoneToChoosePrintFlow',
    defaultToPrintFlow: 'integration.esignature.defaultToPrintFlow',
  },
  sendgrid: {
    enabled: 'integration.sendgrid.enabled',
  },
  rewrites: {
    enabled: 'integration.rewrites.enabled',
  },
};
