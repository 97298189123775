import { CollectionAbstract, CustomerOrderModel } from './';

class QuotesCollectionModel extends CollectionAbstract {
  constructor(instance) {
    super(instance);

    this._collection[0] = new CustomerOrderModel(this);
    this._selectedQuote = {
      id: this._collection[0].locationInfo.locationQuoteId,
      index: 0,
    };
  }

  get locationQuotes() {
    let quotes = [];

    for (let i = 1; i < this._collection.length; i++) {
      quotes.push(this._collection[i]);
    }

    return quotes;
  }

  get locationsNames() {
    const quotesNames = [];

    for (let i = 1; i < this._collection.length; i++) {
      quotesNames.push(this._collection[i].serviceInfo.locationName);
    }

    return quotesNames;
  }

  get locationsS2sIds() {
    const ids = [];

    for (let i = 1; i < this._collection.length; i++) {
      ids.push(this._collection[i].star2starLocationId);
    }

    return ids;
  }

  /**
   * @return {CustomerOrderModel}
   */
  get masterOrder() {
    return this._collection[0];
  }

  /**
   * @return {CustomerOrderModel}
   */
  get customerOrder() {
    return this.masterOrder;
  }

  get selectedQuote() {
    return this._selectedQuote;
  }

  set selectedQuote(quote) {
    if (quote !== undefined) {
      const quotes = this.items;
      const length = quotes.length;
      let index = null;

      for (let i = 0; i < length; i++) {
        if (quotes[i].locationInfo.locationQuoteId === quote.locationInfo.locationQuoteId) {
          index = i;
          break;
        }
      }

      this._selectedQuote = {
        id: quote.locationInfo.locationQuoteId,
        index,
      };
    }
  }
}

export default QuotesCollectionModel;
