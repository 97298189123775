import { QuotesCollectionModel, IctpLineItemModel } from '../';
import fixFloat from '../../common/helpers/fixFloat';

export default class LocationIctpLineItemModel extends IctpLineItemModel {
  constructor(instance) {
    super(instance);

    this._parentUuid = null;

    this._ignoreOnExport.push('parent', 'masterOrder');
  }

  // Prevent setting parentUuid by property name or setProps
  get parentUuid() {
    return this._parentUuid;
  }

  setParentUuid(value) {
    this._parentUuid = String(value);
  }

  /**
   * @return {CustomerOrderModel|null}
   */
  get masterOrder() {
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    return quotes ? quotes.customerOrder : null;
  }

  /**
   * @return {LineItemModel|null}
   */
  get _parentLookup() {
    if (!this.masterOrder) {
      return null;
    }

    for (let i = 0; i < this.masterOrder.lineItems.items.length; i++) {
      if (this.masterOrder.lineItems.items[i].uuid === this.parentUuid) {
        return this.masterOrder.lineItems.items[i];
      }
    }

    return null;
  }

  get parent() {
    return this._getCachedValueOnExport('_parentLookup');
  }

  get total() {
    return fixFloat(this.parent.price, 2);
  }

  get available() {
    const parent = this.parent;
    const allocated = parent ? parent.allocated : 0;

    return fixFloat(this.total - fixFloat(allocated), 2);
  }

  get dealerNet() {
    return this.isStar ? super.dealerNet : this._dealerNet;
  }

  set dealerNet(v) {
    this._dealerNet = v;
  }

  get quantity() {
    return this._quantity;
  }

  set quantity(value) {
    this._quantity = value;
  }

  get parentQuoteLineItemUuid() {
    const parent = this.parent;

    return parent ? parent.quoteLineItemUuid : null;
  }

  // There are number of inherited properties that are not valid for locations
  // TODO: Create LineItemAbstract which will holds shared props only and new LineItemMasterOrderModel

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get locationSiblings() {
    return null;
  }

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get allocated() {
    return null;
  }

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get allocatedFinalized() {
    return null;
  }

  // TODO: Remove me when LineItemMasterOrderModel be created
  // Rewrite LineItemModel inherited property
  get allocatedOverride() {
    return null;
  }
}
