import React, { Component } from 'react';

function connectView(ViewComponent, controllerInstance, props = {}) {
  class ViewConnector extends Component {
    constructor(...args) {
      super(...args);

      controllerInstance.setView(this);

      this.state = controllerInstance.props;
    }

    componentDidMount() {
      controllerInstance.viewMounted = true;
    }

    componentWillUnmount() {
      controllerInstance.viewMounted = false;
    }

    render() {
      return (
        <ViewComponent key={controllerInstance.uuid} {...this.props} {...this.state} controller={controllerInstance} />
      );
    }
  }

  return <ViewConnector {...props} />;
}

export default connectView;
