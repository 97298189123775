import apiData from '../Storage/apiData';
import config from '../config';

let _types = [];

function parseBeanName(ref) {
  let result = null;

  try {
    result = ref.match(/^#\/definitions\/(.+)/i)[1];
  } catch (e) {
    result = false;
  }

  return result;
}

function getJsSchemaObj(beanName, beans) {
  const flatBean = beans[beanName];
  let nestedBean = {};

  for (let propName in flatBean) {
    if (!flatBean.hasOwnProperty(propName)) {
      continue;
    }

    const prop = flatBean[propName];

    if (prop['$ref']) {
      nestedBean[propName] = getJsSchemaObj(parseBeanName(prop['$ref']), beans);
      continue;
    }

    if (prop.type === 'array' && prop.items && prop.items['$ref']) {
      nestedBean[propName] = [getJsSchemaObj(parseBeanName(prop.items['$ref']), beans)];
      continue;
    }

    if (prop.type) {
      if (!_types.includes(prop.type)) {
        _types.push(prop.type);
      }

      let value = null;

      // eslint-disable-next-line
      switch (prop.type) {
        case 'integer':
        case 'number':
          value = 0;
          break;
        case 'string':
          value = 'string';
          break;
        case 'boolean':
          value = true;
      }

      nestedBean[propName] = value;
    } else {
      console.log('Schema error. Prop type not set for', prop);
    }
  }

  return nestedBean;
}

const isNumeric = v => !isNaN(v);

function fixType(type, value) {
  const fix = {
    number: v => {
      if (!isNumeric(v)) {
        console.error('fixType error converting non numeric value into number');
      }

      return Number(v);
    },
    // Prevent null conversion into string
    string: v => (v === null ? null : String(v)),
    boolean: v => {
      let result = Boolean(v);

      if (typeof v === 'string') {
        // Check, is value a number or not to make a proper boolean conversion
        // Why it is needed? ex.:
        // Boolean(0) => false
        // Boolean('0') => true
        // Boolean(Number('0')) => true
        if (isNumeric(v)) {
          result = Boolean(Number(v));
        }
        // Consider "true" / "false" strings as a boolean
        else if (v.toLowerCase() === 'true') {
          result = true;
        } else if (v.toLowerCase() === 'false') {
          result = false;
        }
      }

      return result;
    },
  };

  return typeof fix[type] === 'function' ? fix[type](value) : value;
}

function normalizeObject(schema, value, level = 0) {
  if (Array.isArray(schema)) {
    if (Array.isArray(value)) {
      const array = [];

      schema = schema[0];

      if (schema === undefined) {
        console.error('Schema array does not have reference element');
      }

      for (let i = 0; i < value.length; i++) {
        array.push(normalizeObject(schema, value[i], level + 1));
      }

      return array;
    } else {
      return value;
    }
  }

  if (typeof schema === 'object' && schema !== null) {
    if (typeof value === 'object' && value !== null) {
      const object = {};

      for (let key in schema) {
        if (!schema.hasOwnProperty(key)) {
          continue;
        }

        object[key] = normalizeObject(schema[key], value[key], level + 1);
      }

      return object;
    } else {
      return value;
    }
  }

  if (typeof schema !== typeof value && value !== null) {
    return value === undefined ? null : fixType(typeof schema, value);
  }

  return value;
}

const RequestBodyNormalize = (endpoint, requestBody, notes, apiSchemas = apiData.apiSchemas) => {
  const beanName = apiSchemas.refs[endpoint];

  if (!beanName) {
    return null;
  }

  const schema = getJsSchemaObj(beanName, apiSchemas.beans);

  if (config.showConsoleLog && notes) {
    console.groupCollapsed('RequestBodyNormalize', endpoint, notes);
    console.time('normalizeObject');
  }

  const result = schema ? normalizeObject(schema, requestBody) : null;

  if (config.showConsoleLog && notes) {
    console.log('Original Object', requestBody);
    console.log('Schema', schema);
    console.log('Normalized Object', result);
    console.timeEnd('normalizeObject');
    console.groupEnd();
  }

  return result;
};

export default RequestBodyNormalize;
