import CustomerInfo from '../../../../CustomerInfo';
import CollapsableCard from '../../../../CollapsableCard';

const CustomerInformationWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //CustomerInfo props
  customerOrder,
  ...customerInformationModelProps
}) => {
  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      <CustomerInfo customerOrder={customerOrder} {...customerInformationModelProps} />
    </CollapsableCard>
  );
};

export default CustomerInformationWrapper;
