import TypeConverter from '../common/helpers/TypeConverter';

export function apiPropertiesAssign(mapping, apiResponseObject) {
  const settings = {};
  const keys = Object.keys(mapping);

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    const bePropertyKey = mapping[key];

    if (typeof bePropertyKey === 'string') {
      const bePropertyValue = apiResponseObject[bePropertyKey];

      settings[key] = TypeConverter(bePropertyValue);

      continue;
    }

    // Mapping values should be strings or sub-objects only
    if (typeof bePropertyKey !== 'object' || bePropertyKey === null) {
      continue;
    }

    settings[key] = apiPropertiesAssign(bePropertyKey, apiResponseObject);
  }

  return settings;
}
