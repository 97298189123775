export const SWITCHVOX_ON_PREM_TYPES = {
  PLATINUM: 'PLATINUM',
  TITANIUM: 'TITANIUM',
};

export const SWITCHVOX_ON_PREM_TYPES_OPTIONS = [
  {
    key: SWITCHVOX_ON_PREM_TYPES.PLATINUM,
    value: SWITCHVOX_ON_PREM_TYPES.PLATINUM,
    text: 'msg_platinum',
  },
  {
    key: SWITCHVOX_ON_PREM_TYPES.TITANIUM,
    value: SWITCHVOX_ON_PREM_TYPES.TITANIUM,
    text: 'msg_titanium',
  },
];
