import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AutocompleteControl, LabelField } from '../../../Controls';
import PartnersContext from './PartnersContext';

const PartnerInput = ({ onChangePartner = () => {}, disabled = false, doFocus = false, isLabel = false }) => {
  const { partners, partnersFetching, selectedPartnerId, selectedPartnerLabel } = useContext(PartnersContext);

  if (partners.length === 1 || isLabel) {
    return <LabelField label="msg_partner" type="label" value={selectedPartnerLabel} />;
  }

  return (
    <AutocompleteControl
      fullWidth
      isLoading={partnersFetching}
      id="order-initiation-partner-select"
      label="msg_partner"
      onChange={onChangePartner}
      options={partners}
      required
      value={selectedPartnerId}
      focus={doFocus}
      disabledState={disabled}
      parentClassName="order-initiation-partner-select"
    />
  );
};

PartnerInput.propTypes = {
  onChangePartner: PropTypes.func,
  disabled: PropTypes.bool,
  doFocus: PropTypes.bool,
  isLabel: PropTypes.bool,
};

export default PartnerInput;
