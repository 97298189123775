import React from 'react';
import { func, number, objectOf, oneOfType, string } from 'prop-types';
import { TextControl } from '../../../../Controls';
import '../styles/filter-input.css';
import ColumnFilter from './ColumnFilter';

const Toolbar = ({
  inputIconClassName = 'icon-search',
  inputStyle = { marginBottom: 0 },
  onChangeSearch,
  onSearchClear,
  searchString = '',
  totalItemsCount,
  columnFilters,
  onChangeColumnFilter,
  onClearColumnFilter,
}) => (
  <div className="filter-toolbar">
    <div className="filter-input-wrapper">
      <TextControl
        ariaLabel="Quotes filter"
        className="filter-input"
        id="quotes-table-filter"
        onChange={onChangeSearch}
        style={inputStyle}
        value={searchString}
        resultsCount={totalItemsCount}
        clearFieldHandler={onSearchClear}
        showResultCnt={Boolean(searchString.length)}
        iconClassName={inputIconClassName}
        focus
      />
    </div>
    {columnFilters.map(columnFilter => (
      <ColumnFilter
        key={columnFilter.type + '-multi-select'}
        {...columnFilter}
        onChangeColumnFilter={onChangeColumnFilter}
        onClearColumnFilter={onClearColumnFilter}
      />
    ))}
  </div>
);

Toolbar.propTypes = {
  inputIconClassName: string,
  inputStyle: objectOf(oneOfType([string, number])),
  onChangeSearch: func.isRequired,
  onSearchClear: func.isRequired,
  searchString: string,
  totalItemsCount: number,
};

export default Toolbar;
