import config from '../../config';

export default function bindMultiple(instance, ...methods) {
  if (typeof instance !== 'object') {
    throw new Error('Prevent binding methods to non objects');
  }

  methods.forEach(method => {
    if (typeof method !== 'function') {
      throw new Error('Unable to bind instance to non function');
    }

    try {
      instance[method.name] = method.bind(instance);
    } catch (e) {
      const instanceName = instance.constructor.name;

      if (config.showConsoleLog) {
        console.error(
          `Unable to bind method ${method.name} to the instance` + (instanceName ? ' of ' + instanceName : '')
        );
      }

      throw e;
    }
  });
}
