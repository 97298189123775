import CommonSectionModel from './CommonSectionModel';
import bindMultiple from '../../common/helpers/bindMultiple';
import { AuthContext } from '../../Context';

class DaasSectionModel extends CommonSectionModel {
  constructor(higherOrderModel) {
    super(higherOrderModel);

    //TODO use ConstantsModel props when they will be refactored to static props
    this.id = 'daas-settings-section';
    this.title = 'msg_daas_settings_section';
    this.isGeneralSection = true;

    bindMultiple(this, this.onDaasWorkspaceTypeChange, this.onDaasScheduleTypeChange);
  }

  get lockedState() {
    return super.lockedState || this.appState.switchvoxWlwDisabled;
  }

  get daasWorkspaceTypeDisabled() {
    return (
      (this.customerOrder.existingDaasCustomerFromQuoteId !== null && !AuthContext.model.hasSalesOpsPermissions) ||
      this.customerOrder.existingDaasCustomer !== false
    );
  }

  onDaasWorkspaceTypeChange(value) {
    this.customerOrder.onChange('daasWorkspaceType', value);
  }
  onDaasScheduleTypeChange(value) {
    this.customerOrder.onChange('daasScheduleType', value);
  }
}

export default DaasSectionModel;
