import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { SelectControl } from '../../Controls';

class AlternateDescription extends PureComponent {
  static propTypes = {
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    onChange: PropTypes.func,
    errorText: PropTypes.string,
    value: PropTypes.string,
    type: PropTypes.string,
    altDescriptionOptions: PropTypes.array,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
    errorText: '',
    altDescriptionOptions: [],
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(value, this.props.type);
    }
  }

  render() {
    const { id, lockedState, disabledState, value, altDescriptionOptions } = this.props;

    return (
      <React.Fragment>
        <SelectControl
          id={id}
          placeholder="msg_select_alternate_description"
          label=""
          value={value}
          lockedState={lockedState}
          disabledState={disabledState}
          onChange={this.handleChange}
          options={altDescriptionOptions}
          ariaLabel="msg_select_alternate_description"
        />
      </React.Fragment>
    );
  }
}

export default AlternateDescription;
