import config from '../config';
import {
  CatalogModel,
  CustomerAttributesModel,
  LineItemsCollectionModel,
  LocationQuoteModel,
  QuoteModel,
  QuotesCollectionModel,
  ElectronicSignatureModel,
} from './';
import {
  SOLUTION_TYPES,
  constants,
  QUOTE_STATUS,
  ORDER_TYPES,
  USER_ROLES,
  CONTRACT_TERM_MONTH_OPTIONS,
  CONTRACT_TERM_MONTH_OPTIONS_LIST,
  LEASE_OPTIONS,
  PRICEBOOK_TAGS,
  PACKAGE_TYPE_IDS,
  ESIG_ENVELOPE_TYPES,
  FINANCING_OPTIONS,
  PACKAGE_MODEL_NAME,
  SWITCHVOX_ON_PREM_TYPES,
  SWITCHVOX_ON_PREM_TERM_YEARS,
  ADDENDUMS,
  WORKSPACE_TYPE_OPTIONS,
  SCHEDULE_TYPE_OPTIONS,
  APPLIANCE_PROVIDERS,
  QUOTE_OPERATION_MODES,
  SANGOMA_CX_USER_TYPE_OPTIONS,
  QUOTE_SERVICE_TYPE,
} from '../common/enums';
import { AuthContext, PartnerContext, PriceBookContext } from '../Context';
import apiData from '../Storage/apiData';
import { ExchangeMigrationToggleModel, ExistingServiceInsightCustomerToggleModel } from './Toggles';
import { AddDialogModel, AddExistingDialogModel, LineItemRemoveDialog } from './LineItems';
import {
  BilledServiceCsvDownloadButtonModel,
  ExtensionsCsvDownloadButtonModel,
  NrcTaxEstimatesCsvDownloadButtonModel,
  RcTaxEstimatesCsvDownloadButtonModel,
} from './Buttons';
import { RESULT_TYPES } from './CalcResultsModel';
import LocationBreakoutsCsvDownloadButtonModel from './Buttons/LocationBreakoutsCsvDownloadButtonModel';
import bindMultiple from '../common/helpers/bindMultiple';

class CustomerOrderModel extends QuoteModel {
  constructor(instance) {
    super(instance);

    this._dealerNetToggle = false;
    this._inHouseSystem = false;
    this._useESigDocumentsFlow = this.useESigDocumentsFlowDefaultValue;
    this._lineItems = new LineItemsCollectionModel(this);
    this._quoteStatus = QUOTE_STATUS.OPEN;
    this._packageIdSelected = 1;
    this._contractTermMonths = CONTRACT_TERM_MONTH_OPTIONS.FIVE_YEARS.value;
    this._availableDocuments = [];
    this._showOvernightGuaranteeWarning = false;
    this._quoteAddendums = ADDENDUMS;
    this._contractRewrite = false;
    this._creditDocumentsOnFile = false;
    this._greatAmericaRentalAgreementOnFile = false;
    this._existingDaasCustomer = false;
    this._daasScheduleType = SCHEDULE_TYPE_OPTIONS.NONE;
    this._overnightGuarantee = true;
    this._existingAlsAgreement = false;
    this._businessContinuity = false;
    this._dealerDemoKit = false;
    this._financingOption = FINANCING_OPTIONS.RENTAL_GREATAMERICA;
    this._creditDocumentsOnFileOverride = null;
    this._greatAmericaRentalAgreementOnFileOverride = null;
    this._existingDaasCustomerOverride = null;
    this._numSwitchvoxOnPremUsers = 1;
    this._solutionTypeId = null;
    this._sipStationEnabled = true;
    this._switchvoxOnPremEnabled = true;
    this._switchvoxOnPremMaintTermYears = 1;
    this._switchvoxOnPremMaintType = SWITCHVOX_ON_PREM_TYPES.PLATINUM;
    this._smartOfficeLocationsQty = 0;
    this._salesExceptionJustification = null;
    this._switchvoxApplianceProvider = APPLIANCE_PROVIDERS.SANGOMA;
    this._opportunityProductTypeOverride = null;
    this._quoteOperationMode = QUOTE_OPERATION_MODES.DEFAULT;
    this._existingSangomaCXCustomer = false;
    this._customerId = 0;
    this._existingSpeechAnalyticsCustomer = false;
    this._speechAnalyticsActive = false;

    this.locationInfo = {
      locationQuoteId: 'customer-order-default-location',
      isOrderTab: true,
      errors: [],
    };

    this.amendmentTaskNumber = '';
    this.saSigningDate = null;
    this.decentralizedAccounting = false;
    this.amendButtonEnabled = false;
    this.allowToVoid = false;
    this.orderType = ORDER_TYPES.NEW_CUSTOMER;
    this.masterOrderId = null;
    this.isRevisionOfQuoteId = null;
    this.availableLineProductGpCode = null;
    this.existingStarFaxGpCode = null;
    this.pricebookId = null;
    this.pricebookVersionId = null;
    this.quoteHistory = [];
    this.customerAttributes = new CustomerAttributesModel(this);
    this.eSignature = new ElectronicSignatureModel(this);
    this.lockedInPrintFlow = false;
    this.statusBeforeSalesException = null;
    this.quoteLineItemSettings = [];
    this.packageIdReturnedByApi = null;
    this.leaseOption = LEASE_OPTIONS.NONE;
    this.leaseCustomPaymentAmount = 0;
    this.cobrandingEnabled = false;
    this.expired = false;
    this.globalExpirationDate = null;
    this.expirationDateOverride = null;
    this.creditDocumentsFromQuoteId = null;
    this.greatAmericaRentalAgreementFromQuoteId = null;
    this.existingDaasCustomerFromQuoteId = null;
    this.daasWorkspaceType = WORKSPACE_TYPE_OPTIONS.NONE;
    this.daasScheduleTypeLocked = true;
    this.skipProration = false;
    this.switchvoxSipStation = false;
    this.isSipStationDocumentsAvailable = true;
    this.overrideFreeMonthPromoRules = false;
    this.standaloneServiceNonUCaaS = false;
    this.contactCenterConcurrency = false;
    this.switchvoxOnPremEnabledFromQuoteId = null;
    this.switchvoxOnPremMaintTypeEffective = null;
    this.switchvoxOnPremMaintTermYearsEffective = null;
    this.switchvoxOnPremMaintTypeOverride = null;
    this.switchvoxOnPremMaintTermYearsOverride = null;
    this.sipStationEnabledFromQuoteId = null;
    this.smartOfficeFromQuoteId = null;
    this.markers = [];
    this.taxEstimates = [];
    this.autoSetOpportunityProductType = true;
    this.originalPricebookId = null;
    this.sangomaCXUserType = SANGOMA_CX_USER_TYPE_OPTIONS.NONE;
    this.sangomaCXUserTypeAtCustomerLevel = '';
    this.existingSangomaCXCustomerFromQuoteId = null;
    this.existingSangomaCXCustomerOverride = null;
    this.sangomaCXDataExportFromQuoteId = null;
    this.sangomaCXStandalone = false;
    this.sangomaCXConcurrency = false;
    this.isFocusMode = false;
    this.opportunityId = null;
    this.salesforceOpportunityId = null;
    this.salesforceCustomerAccountId = null;
    this.addOnFinancingTermMonths = 0;
    this.existingSpeechAnalyticsCustomerFromQuoteId = null;
    this.existingSpeechAnalyticsCustomerOverride = null;

    this.lineItemsCatalog = new CatalogModel(this).initLineItemsCatalogs();
    this.getPackageModelById = this.getPackageModelById.bind(this);
    this.addLineItemDialog = new AddDialogModel(this);
    this.addExistingLineItemDialog = new AddExistingDialogModel(this);
    this.billedServiceCsvDownloadButton = new BilledServiceCsvDownloadButtonModel(this);
    this.extensionsCsvDownloadButton = new ExtensionsCsvDownloadButtonModel(this);
    this.lineItemRemoveDialog = new LineItemRemoveDialog(this);
    this.exchangeMigrationToggle = new ExchangeMigrationToggleModel(this);
    this.nrcTaxEstimatesCsvDownloadButton = new NrcTaxEstimatesCsvDownloadButtonModel(this);
    this.rcTaxEstimatesCsvDownloadButton = new RcTaxEstimatesCsvDownloadButtonModel(this);
    this.locationBreakoutsCsvDownloadButton = new LocationBreakoutsCsvDownloadButtonModel(this);
    this.existingServiceInsightCustomerToggle = new ExistingServiceInsightCustomerToggleModel(this);

    bindMultiple(
      this,
      this.onContractRewriteChange,
      this.onInHouseSystemChange,
      this.onUseESigDocumentsFlowChange,
      this.onSkipProrationChange,
      this.onOverrideFreeMonthPromoRulesChange,
      this.onAmendmentTaskNumberChange,
      this.onSaSigningDateChange,
      this.onFinancingOptionChange,
      this.onSolutionTypeIdChange,
      this.onCreditDocumentsOnFileOverrideChange,
      this.onGreatAmericaRentalAgreementOnFileOverrideChange,
      this.onExistingDaasCustomerOverrideChange,
      this.onSwitchvoxOnPremMaintTypeOverrideChange,
      this.onSwitchvoxOnPremMaintTermYearsOverrideChange,
      this.onSalesExceptionJustificationChange,
      this.onExistingSangomaCXCustomerOverrideChange,
      this.toggleFocusMode,
      this.onAddOnFinancingTermMonthsChange,
      this.onExistingSpeechAnalyticsCustomerOverrideChange
    );
  }

  /**
   * DO NOT REMOVE. This getter is used by allowedToAddOnCondition flags
   * @returns {boolean}
   */
  get isSalesOps() {
    return AuthContext.model.role === USER_ROLES.SALES_OPERATIONS_USER;
  }

  /**
   * DO NOT REMOVE. This getter is used by allowedToAddOnCondition flags
   * @returns {boolean}
   */
  get hasPartnerPermissions() {
    return AuthContext.model.hasPartnerPermissions;
  }

  /**
   * DO NOT REMOVE. This getter is used by allowedToAddOnCondition flags
   * @returns {boolean}
   */
  get hasInternalPermissions() {
    return AuthContext.model.hasInternalPermissions;
  }

  /**
   * DO NOT REMOVE. This getter is used by allowedToAddOnCondition flags
   * @returns {boolean}
   */
  get hasSalesOpsPermissions() {
    return AuthContext.model.hasSalesOpsPermissions;
  }

  get availableDocuments() {
    const availableDocuments = this._availableDocuments;
    let removeCreditBundle = false;

    if (
      [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_TWO].includes(this.packageIdSelected) &&
      [FINANCING_OPTIONS.RENTAL_GREATAMERICA, FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR].includes(this.financingOption)
    ) {
      removeCreditBundle =
        this.financingOption === FINANCING_OPTIONS.RENTAL_GREATAMERICA
          ? this.greatAmericaRentalAgreementOnFile
          : this.creditDocumentsOnFile;
    }

    if (removeCreditBundle) {
      const creditBundleIndex = availableDocuments.findIndex(envelope => envelope.type === ESIG_ENVELOPE_TYPES.CREDIT);

      if (creditBundleIndex !== -1) {
        availableDocuments.splice(creditBundleIndex, 1);
      }
    }

    return availableDocuments;
  }
  set availableDocuments(value) {
    this._availableDocuments = value;
  }

  get contractTermMonths() {
    if (this.businessContinuity || (PriceBookContext.model.isSupportsSwitchvoxSIPStation && !this.sipStationEnabled)) {
      return CONTRACT_TERM_MONTH_OPTIONS.ONE_YEAR.value;
    }

    return this._contractTermMonths;
  }

  set contractTermMonths(value) {
    this._contractTermMonths = value;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get numLocationsWithDealerDemoKit() {
    return this.dealerDemoKit ? this.numLocations : 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get numLocationsWithoutDealerDemoKit() {
    return this.dealerDemoKit ? 0 : this.numLocations;
  }

  setProps(props, skipProps = []) {
    skipProps.push(
      'locations',
      'eSignature',
      'numLines',
      'contractTermMonths',
      'overnightGuarantee',
      'addOnFinancingTermMonths'
    );

    super.setProps(props, skipProps);

    if (typeof props.numLines === 'number') {
      this.numLines = props.numLines;
    }

    if (props.locations && Array.isArray(props.locations)) {
      this.locations = props.locations;
    }

    if (props.eSignature && typeof props.eSignature === 'object') {
      this.eSignature.setProps(props.eSignature);
    }

    if (typeof props.contractTermMonths === 'number') {
      this.contractTermMonths = props.contractTermMonths;
    }

    if (typeof props.addOnFinancingTermMonths === 'number') {
      this.addOnFinancingTermMonths = props.addOnFinancingTermMonths;
    }

    if (typeof props.overnightGuarantee === 'boolean') {
      this.overnightGuarantee = props.overnightGuarantee;
    }

    return this;
  }

  get useESigDocumentsFlowDefaultValue() {
    return Boolean(config.eSignatureEnabled && !Boolean(apiData.properties.esignature.defaultToPrintFlow));
  }

  get useESigDocumentsFlow() {
    if (this.lockedInPrintFlow) {
      return false;
    }

    if (
      !config.eSignatureEnabled ||
      (this.orderType === ORDER_TYPES.ADD_ON && !this.isRental && !PriceBookContext.model.isSupportsSwitchvoxSIPStation)
    ) {
      return false;
    }

    return this._useESigDocumentsFlow;
  }

  set useESigDocumentsFlow(value) {
    this._useESigDocumentsFlow = Boolean(value);

    if (this._useESigDocumentsFlow === false) {
      // KM-7765: Force set Dealer Net (Bill To: Dealer) toggle to OFF
      this.dealerNetToggle = false;
    }
  }

  onUseESigDocumentsFlowChange(value) {
    this.onChange('useESigDocumentsFlow', value);
  }

  get lineItems() {
    return this._lineItems;
  }

  set lineItems(lineItemsDataArray) {
    if (!Array.isArray(lineItemsDataArray)) {
      return;
    }

    for (let i = 0; i < lineItemsDataArray.length; i++) {
      const lineItemApiData = lineItemsDataArray[i];
      const existedModel = this._lineItems.items.find(
        lineItemModel => lineItemModel.quoteLineItemUuid === lineItemApiData.quoteLineItemUuid
      );

      if (existedModel) {
        // Update existed model with a new data
        existedModel.setProps(lineItemApiData);

        continue;
      }

      // No matches...
      // Very likely it is a new line item. Just create a new model
      const addFunction = lineItemApiData.existing ? this._lineItems.addExisting : this._lineItems.add;
      addFunction(lineItemApiData, false);
    }
  }

  wipeLineItems() {
    this._lineItems.wipe();

    this.locations.forEach(d => d.lineItems.wipe());
  }

  get locations() {
    /** @type {QuotesCollectionModel} */
    const collection = this.findSibling(d => d instanceof QuotesCollectionModel);

    return collection ? collection.locationQuotes : [];
  }

  set locations(locationsDataArray) {
    /** @type {QuotesCollectionModel} */
    const collection = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (collection) {
      for (let i = 0; i < locationsDataArray.length; i++) {
        const data = locationsDataArray[i];

        /** @type {LocationQuoteModel} */
        let locationQuoteModel = collection.locationQuotes.find(d => d.locationUuid === data.locationUuid);

        if (!locationQuoteModel) {
          locationQuoteModel = new LocationQuoteModel(collection);

          collection.items.push(locationQuoteModel);
        }

        locationQuoteModel.setProps(data);
      }
    }
  }

  get madPoolOverride() {
    return this.spiffDiscount.madPoolOverride;
  }

  set madPoolOverride(value) {
    this.spiffDiscount.madPoolOverride = value;
  }

  get spiffOverrideEnabled() {
    return this.spiffDiscount.spiffOverrideEnabled;
  }

  set spiffOverrideEnabled(value) {
    this.spiffDiscount.spiffOverrideEnabled = value;
  }

  get madRecurringOverride() {
    return this.spiffDiscount.madRecurringOverride;
  }

  set madRecurringOverride(value) {
    this.spiffDiscount.madRecurringOverride = value;
  }

  get madUpfrontOverride() {
    return this.spiffDiscount.madUpfrontOverride;
  }

  set madUpfrontOverride(value) {
    this.spiffDiscount.madUpfrontOverride = value;
  }

  get spiffOverride() {
    return this.spiffDiscount.spiffOverride;
  }

  set spiffOverride(value) {
    this.spiffDiscount.spiffOverride = value;
  }

  get additionalRcDiscountPercent() {
    return this.spiffDiscount.additionalRcDiscountPercent;
  }

  set additionalRcDiscountPercent(value) {
    this.spiffDiscount.additionalRcDiscountPercent = value;
  }

  get additionalUpfrontDiscountPercent() {
    return this.spiffDiscount.additionalUpfrontDiscountPercent;
  }

  set additionalUpfrontDiscountPercent(value) {
    this.spiffDiscount.additionalUpfrontDiscountPercent = value;
  }

  /**
   * DO NOT REMOVE. This getter is used by forbidAddingIfPropertyEqualsTrue flags
   * @returns {boolean}
   */
  get overnightGuaranteeWaived() {
    return !this.overnightGuarantee;
  }

  get numLinesAllocated() {
    let locationsNumLinesSum = 0;

    for (let i = 0; i < this.locations.length; i++) {
      locationsNumLinesSum += this.locations[i].numLines;
    }

    return locationsNumLinesSum;
  }

  get numStarCloudLocationsCalculated() {
    let numStarCloudLocationsCalculated = 0;

    for (let i = 0; i < this.locations.length; i++) {
      if (this.locations[i].solutionTypeId === SOLUTION_TYPES.BUSINESS_VOICE) {
        numStarCloudLocationsCalculated++;
      }
    }

    return numStarCloudLocationsCalculated;
  }

  get allLocationsAreShipped() {
    return this.locations.length > 0 && this.locations.every(loc => loc.shipped);
  }

  get allLocationsAreFinalized() {
    return (
      this.locations.length === this.numLocations &&
      this.locations.every(loc => loc.quoteStatus === QUOTE_STATUS.FINALIZED)
    );
  }

  get isAnyLocationWithTrackingId() {
    return this.locations.length > 0 && this.locations.some(loc => loc.trackingLocationId !== null);
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get numSC3InitialSetup() {
    return this.customerAttributes.existingSC3ProfessionalCustomer ? 0 : 1;
  }

  // KM-6541 Validation with hardcoded SBReliant items id
  quoteHasStarboxWithoutSbreliant() {
    let quoteHasStarbox = false;
    let quoteHasSbreliant = false;

    for (let i = 0; i < this.lineItems.items.length; i++) {
      const lineItem = this.lineItems.items[i];

      if (!lineItem.active || lineItem.quantityWithOverride <= 0) {
        continue;
      }

      if (lineItem.lineItemSubCategoryId === constants.STAR_BOX_SUB_CATEGORY_ID) {
        quoteHasStarbox = true;
      }

      if (constants.SB_RELIANT_PRODUCT_IDS.includes(lineItem.productId)) {
        quoteHasSbreliant = true;
      }

      if (quoteHasStarbox && quoteHasSbreliant) {
        break;
      }
    }

    return quoteHasStarbox && !quoteHasSbreliant;
  }

  get isDiscountSpiffOverridesDisabled() {
    if (![QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(this.quoteStatus)) {
      return true;
    }

    for (let i = 0; i < this.locations.length; i++) {
      /** @type {LocationQuoteModel} */
      let locationQuote = this.locations[i];

      if (locationQuote.quoteStatus === QUOTE_STATUS.FINALIZED) {
        return true;
      }
    }

    return false;
  }

  get decentralizedAccounting() {
    return this.billingInfo.decentralizedAccounting;
  }

  set decentralizedAccounting(v) {
    this.billingInfo.decentralizedAccounting = v;
  }

  get dealerNetToggle() {
    // Force disable Dealer Net toggle if Financed package selected
    if (this.isFinanced) {
      return false;
    }

    return this._dealerNetToggle;
  }

  set dealerNetToggle(value) {
    this._dealerNetToggle = Boolean(value);

    // KM-7630: Force Bill To field to Customer once toggle is set to OFF
    if (!this._dealerNetToggle) {
      this.billingInfo.billToId = 2;
      this.billingInfo.disableBillToId = false;

      // Update all locations also
      for (let i = 0; i < this.locations.length; i++) {
        /** @type {LocationQuoteModel} */
        const d = this.locations[i];

        d.billingInfo.billToId = 2;
        d.billingInfo.disableBillToId = false;
      }
    }

    // KM-7713: Update default state of Customize PDF toggles
    this.eSignature.customizePdfOptionsMap.showNrcOnQuoteSummary = !this._dealerNetToggle;
    this.eSignature.customizePdfOptionsMap.showNrcPrices = !this._dealerNetToggle;
    this.eSignature.customizePdfOptionsMap.showQuoteDetails = true;
    this.eSignature.customizePdfOptionsMap.showCustomerDepositInvoice = !this._dealerNetToggle;
  }

  get sendForSignatureAllowed() {
    return (
      ([ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(this.orderType) &&
        QUOTE_STATUS.OPEN === this.quoteStatus) ||
      (this.orderType === ORDER_TYPES.ADD_ON && [QUOTE_STATUS.OPEN, QUOTE_STATUS.UNFINALIZED].includes(this.quoteStatus))
    );
  }

  get eSigFlowToggleDisabled() {
    // Disable toggle on specific quote status
    if (
      [QUOTE_STATUS.SENT_FOR_SIGNATURE, QUOTE_STATUS.SIGNED_AND_FINALIZED, QUOTE_STATUS.AMENDING].includes(
        this.quoteStatus
      )
    ) {
      return true;
    }

    // lockedInPrintFlow changes it state once user switches eSig toggle to NO and confirm change
    // State become persistent on quote save
    if (this.lockedInPrintFlow) {
      return true;
    }

    // Disable toggle for not rental addons
    if (
      this.orderType === ORDER_TYPES.ADD_ON &&
      !this.isRental &&
      !PriceBookContext.model.isSupportsSwitchvoxSIPStation
    ) {
      return true;
    }

    const eSigAllowedForAll = Boolean(apiData.properties.esignature.allowAnyoneToChoosePrintFlow);

    if (eSigAllowedForAll) {
      return false;
    }

    return !AuthContext.model.hasSalesOpsPermissions;
  }

  get inHouseSystem() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._inHouseSystem;
  }

  set inHouseSystem(value) {
    if (value) {
      this.dealerDemoKit = false;
    }

    this._inHouseSystem = value;
  }

  onInHouseSystemChange(value) {
    this.onChange('inHouseSystem', value);
  }

  get quoteStatus() {
    if (this._quoteStatus === QUOTE_STATUS.SE_APPROVED && this.statusBeforeSalesException) {
      return this.statusBeforeSalesException;
    }

    return this._quoteStatus;
  }

  set quoteStatus(v) {
    this._quoteStatus = v;
  }

  get quoteStatusLabel() {
    return this._quoteStatus;
  }

  get isPartnerTargetSpiffAvailable() {
    const { calculatesPartnerSPIFF } = PriceBookContext.model.flags;

    return !calculatesPartnerSPIFF;
  }

  get recurringDiscountAltDescription() {
    return this.spiffDiscount.recurringDiscountAltDescription;
  }

  set recurringDiscountAltDescription(value) {
    this.spiffDiscount.recurringDiscountAltDescription = value;
  }

  get nonRecurringDiscountAltDescription() {
    return this.spiffDiscount.nonRecurringDiscountAltDescription;
  }

  set nonRecurringDiscountAltDescription(value) {
    this.spiffDiscount.nonRecurringDiscountAltDescription = value;
  }

  get suppressedLineItemIds() {
    return this.quoteLineItemSettings.filter(d => d.suppressed).map(d => d.lineItemId);
  }

  set suppressedLineItemIds(suppressedIds) {
    if (!Array.isArray(suppressedIds)) {
      return;
    }

    // Reset suppressed property to false for all existed line item settings
    // Does not remove elements because there might be other options in future
    this.quoteLineItemSettings.forEach(settings => {
      settings.suppressed = false;
    });

    suppressedIds.forEach(lineItemId => {
      const index = this.quoteLineItemSettings.findIndex(settings => settings.lineItemId === lineItemId);

      if (index === -1) {
        this.quoteLineItemSettings.push({
          lineItemId,
          suppressed: true,
        });
      } else {
        this.quoteLineItemSettings[index].suppressed = true;
      }
    });

    // TODO: remove this clean up in case any new settings will be added
    // Temporary - do a clean-up of quoteLineItemSettings
    this.quoteLineItemSettings = this.quoteLineItemSettings.filter(settings => settings.suppressed);
  }

  get packagedApplicationsExists() {
    const { length } = this.lineItems.items;

    for (let i = 0; i < length; i++) {
      const l = this.lineItems.items[i];

      if (
        l.getFlagValuesArray('productTag').includes('packagedApplicationsThatRequireConsistentAllocation') &&
        l.quantityWithOverride > 0
      ) {
        return true;
      }
    }

    return false;
  }

  get _contractTermLengthOptionsLookup() {
    const options = [];

    if (this.businessContinuity) {
      return [CONTRACT_TERM_MONTH_OPTIONS.ONE_YEAR];
    }

    // KM-8788: Limit selection of contract term length options
    if (PartnerContext.model.isWholeSale && AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) {
      if (this.isFinanced) {
        options.push(CONTRACT_TERM_MONTH_OPTIONS.FIVE_YEARS);
      } else if ([3, 4].includes(this.packageIdSelected) || this.isRental) {
        options.push(
          CONTRACT_TERM_MONTH_OPTIONS.THREE_YEARS,
          CONTRACT_TERM_MONTH_OPTIONS.FOUR_YEARS,
          CONTRACT_TERM_MONTH_OPTIONS.FIVE_YEARS
        );
      }

      const optionsIncludesSelectedLength = options.some(({ value }) => value === this.contractTermMonths);

      if (!optionsIncludesSelectedLength) {
        if (this.packageIdSelected === this.packageIdReturnedByApi) {
          const savedOption = CONTRACT_TERM_MONTH_OPTIONS_LIST.find(({ value }) => value === this.contractTermMonths);

          options.push(savedOption);
          options.sort((a, b) => a.value - b.value);
        }
      }
    } else {
      options.push(...CONTRACT_TERM_MONTH_OPTIONS_LIST);
    }

    if (
      options.findIndex(opt => opt.value === CONTRACT_TERM_MONTH_OPTIONS.ONE_MONTH.value) === 0 &&
      (!PriceBookContext.model.isSupportsSwitchvoxSIPStation ||
        (PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
          !AuthContext.model.isSalesOps &&
          this.contractTermMonths !== CONTRACT_TERM_MONTH_OPTIONS.ONE_MONTH.value))
    ) {
      options.shift();
    }

    return options;
  }

  get contractTermLengthOptions() {
    return this._getCachedValueOnExport('_contractTermLengthOptionsLookup');
  }

  get packageIdSelected() {
    return this._packageIdSelected;
  }

  set packageIdSelected(value) {
    this._packageIdSelected = Number(value);

    // Reset packageIdReturnedByApi at very first package change by user
    // To prevent persisting contract term values not available for partners
    if (this.packageIdReturnedByApi !== null) {
      this.packageIdReturnedByApi = null;
    }

    // KM-8788: Reset contract term length to minimum available option
    // for non salesOps users
    if (
      AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER &&
      !this.contractTermLengthOptions.some(({ value }) => value === this.contractTermMonths)
    ) {
      this.contractTermMonths = this.contractTermLengthOptions[0].value;
    }

    if (this.isPurchase) {
      this.billingInfo.billToId = null;
    }

    // KM-9585: Reset overnightGuarantee to true on package change for FSW, non SalesOps users
    if (
      (PartnerContext.model.isFullServiceWholesale || PartnerContext.model.isWhiteLabelWholesale) &&
      !this.overnightGuarantee &&
      this.isFinanced
    ) {
      this.overnightGuarantee = true;
      this._showOvernightGuaranteeWarning = true;
    }

    // KM-10463: Force reset useESigDocumentsFlow to true if rental package selected for Add-On
    if (config.eSignatureEnabled && this.orderType === ORDER_TYPES.ADD_ON && this.isRental) {
      this.useESigDocumentsFlow = true;
    }
  }

  get isLeaseOptionAvailableExcludingPackageId() {
    const { pricebookTag } = PriceBookContext.model.flags;

    return pricebookTag.includes(PRICEBOOK_TAGS.HAS_LEASE_OPTIONS);
  }

  get isLeaseOptionAvailable() {
    if (!this.isLeaseOptionAvailableExcludingPackageId) {
      return false;
    }

    const { PACKAGE_THREE, PACKAGE_FOUR } = PACKAGE_TYPE_IDS;

    return [PACKAGE_THREE, PACKAGE_FOUR].includes(this.packageIdSelected);
  }

  get showOvernightGuaranteeWarning() {
    const originalSate = this._showOvernightGuaranteeWarning;

    // Reset state at very first request
    // In this case true value will be available only once per toJS call or direct request
    this._showOvernightGuaranteeWarning = false;

    return originalSate;
  }

  get additionalCreditAvailable() {
    return Boolean(
      AuthContext.model.hasInternalPermissions &&
        this.quoteStatus === QUOTE_STATUS.SENT_FOR_SIGNATURE &&
        this.availableDocuments.some(envelope => envelope.type === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT)
    );
  }

  get isSelectedPackageOverride() {
    return Boolean(PriceBookContext.model.flags['hidePackage' + this.packageIdSelected]);
  }

  get isFinanced() {
    return [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_TWO].includes(this.packageIdSelected);
  }

  get isPurchase() {
    return [PACKAGE_TYPE_IDS.PACKAGE_THREE, PACKAGE_TYPE_IDS.PACKAGE_FOUR].includes(this.packageIdSelected);
  }

  get isAllInclusive() {
    return this.isFinanced && this.financingOption === FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR;
  }

  get isRental() {
    return this.isFinanced && this.financingOption === FINANCING_OPTIONS.RENTAL_GREATAMERICA;
  }

  // TODO start using selectedPackage instead of packageIdSelected,
  //  after that move isFinanced, isAllInclusive, isRental getters to package model
  get selectedPackage() {
    return this.getPackageModelById(this.packageIdSelected);
  }

  getPackageModelById(id) {
    return this.controller.models[PACKAGE_MODEL_NAME[id]];
  }

  get showPrintFlowConfirmationModal() {
    // Prevent dialog to be shown if quote is locked
    if (!this.controller || this.controller.appState.locked) {
      return false;
    }

    return AuthContext.model.hasSalesOpsPermissions && !this.lockedInPrintFlow && this.requiresPrintFlowConfirmation;
  }

  get requiresPrintFlowConfirmation() {
    if (this.useESigDocumentsFlow) {
      return false;
    }

    return (
      [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(this.orderType) ||
      (this.orderType === ORDER_TYPES.ADD_ON &&
        (this.isRental || (PriceBookContext.model.isSupportsSwitchvoxSIPStation && this.isSipStationDocumentsAvailable)))
    );
  }

  get financingOption() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return FINANCING_OPTIONS.RENTAL_GREATAMERICA;
    }

    return this._financingOption;
  }

  set financingOption(val) {
    if (
      this.orderType === ORDER_TYPES.ADD_ON &&
      val === FINANCING_OPTIONS.RENTAL_GREATAMERICA &&
      !this.lockedInPrintFlow
    ) {
      this.useESigDocumentsFlow = true;
    }

    this._financingOption = val;
  }

  onFinancingOptionChange(value) {
    this.onChange('financingOption', value);
  }

  get quoteAddendums() {
    return this._quoteAddendums;
  }

  set quoteAddendums(val) {
    if (val && val.length > 0) {
      this._quoteAddendums = val;
    }
  }

  get skipSubscriptionAgreementDate() {
    return Boolean(
      this.quoteStatus !== QUOTE_STATUS.AMENDING &&
        ([ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.REWRITE].includes(this.orderType) ||
          (this.orderType === ORDER_TYPES.NEW_LOCATIONS && !this.existingAlsAgreement))
    );
  }

  get numLocations() {
    if (this.orderType === ORDER_TYPES.REWRITE) {
      return this.locations.length;
    }

    if (this.sangomaCXStandalone) {
      return 1;
    }

    if (
      [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS].includes(this.orderType) &&
      [
        QUOTE_STATUS.OPEN,
        QUOTE_STATUS.UNFINALIZED,
        QUOTE_STATUS.AMENDING,
        QUOTE_STATUS.SE_APPROVED,
        QUOTE_STATUS.SE_PENDING_APPROVAL,
      ].includes(this.quoteStatus) &&
      this._numLocations < this.numberOfLocationsWithTrackingId
    ) {
      return this.numberOfLocationsWithTrackingId;
    }

    return this._numLocations;
  }

  set numLocations(value) {
    this._numLocations = value;
  }

  // TODO: Rename numStarCloudLocations to numBusinessVoiceLocations (BE update required)
  get numStarCloudLocations() {
    if (this.orderType === ORDER_TYPES.REWRITE) {
      return this.numStarCloudLocationsCalculated;
    }

    if (this.sangomaCXStandalone) {
      return 0;
    }

    return super.numStarCloudLocations;
  }

  set numStarCloudLocations(value) {
    this._numStarCloudLocations = Number(value) || 0;
  }

  get contractRewrite() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    if (this.orderType === ORDER_TYPES.REWRITE) {
      return true;
    }

    return this._contractRewrite;
  }

  set contractRewrite(value) {
    this._contractRewrite = Boolean(value);
  }

  onContractRewriteChange(value) {
    this.onChange('contractRewrite', value);
  }

  get creditDocumentsOnFile() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    if (this.creditDocumentsOnFileOverride !== null) {
      return this.creditDocumentsOnFileOverride;
    }

    return this._creditDocumentsOnFile;
  }

  set creditDocumentsOnFile(v) {
    this._creditDocumentsOnFile = v;
  }

  get greatAmericaRentalAgreementOnFile() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    if (this.greatAmericaRentalAgreementOnFileOverride !== null) {
      return this.greatAmericaRentalAgreementOnFileOverride;
    }

    return this._greatAmericaRentalAgreementOnFile;
  }

  set greatAmericaRentalAgreementOnFile(v) {
    this._greatAmericaRentalAgreementOnFile = v;
  }

  get existingDaasCustomer() {
    if (this.existingDaasCustomerOverride !== null) {
      return this.existingDaasCustomerOverride;
    }

    return this._existingDaasCustomer;
  }

  set existingDaasCustomer(v) {
    this._existingDaasCustomer = v;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get daasMultiTenantWorkspaceCount() {
    return this.existingDaasCustomer === false && this.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.MULTI_TENANT ? 1 : 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get daasSingleTenantWorkspaceCount() {
    return this.existingDaasCustomer === false && this.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.SINGLE_TENANT
      ? 1
      : 0;
  }

  get daasScheduleType() {
    if (this.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.NONE) {
      return SCHEDULE_TYPE_OPTIONS.NONE;
    }

    return this._daasScheduleType;
  }

  set daasScheduleType(v) {
    this._daasScheduleType = v;
  }

  get daasScheduleTypeDisabled() {
    return Boolean(
      this.daasWorkspaceType === WORKSPACE_TYPE_OPTIONS.NONE ||
        (ORDER_TYPES.ADD_ON === this.orderType &&
          !AuthContext.model.hasSalesOpsPermissions &&
          this.daasScheduleTypeLocked) ||
        (!AuthContext.model.hasSalesOpsPermissions && this.daasScheduleType === SCHEDULE_TYPE_OPTIONS.MIXED)
    );
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get numOnPremisesExchangeMigration() {
    return this.onPremisesExchangeMigrationRequired ? 1 : 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get numExchangeMigrationBitTitan() {
    return this.onPremisesExchangeMigrationRequired
      ? this.calcResults.getValue('numDaaSExchanges', RESULT_TYPES.integer)
      : 0;
  }

  get onPremisesExchangeMigrationRequired() {
    return this.exchangeMigrationToggle.value;
  }

  get overnightGuarantee() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._overnightGuarantee;
  }

  set overnightGuarantee(v) {
    this._overnightGuarantee = v;
  }

  get existingAlsAgreement() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._existingAlsAgreement;
  }

  set existingAlsAgreement(v) {
    this._existingAlsAgreement = v;
  }

  get businessContinuity() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._businessContinuity;
  }

  set businessContinuity(v) {
    this._businessContinuity = v;
  }

  get dealerDemoKit() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return false;
    }

    return this._dealerDemoKit;
  }

  set dealerDemoKit(v) {
    this._dealerDemoKit = v;
  }

  get creditDocumentsOnFileOverride() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return null;
    }

    return this._creditDocumentsOnFileOverride;
  }

  set creditDocumentsOnFileOverride(v) {
    this._creditDocumentsOnFileOverride = v;
  }

  onCreditDocumentsOnFileOverrideChange(value) {
    this.onChange('creditDocumentsOnFileOverride', value);
  }

  get greatAmericaRentalAgreementOnFileOverride() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return null;
    }

    return this._greatAmericaRentalAgreementOnFileOverride;
  }

  set greatAmericaRentalAgreementOnFileOverride(v) {
    this._greatAmericaRentalAgreementOnFileOverride = v;
  }

  onGreatAmericaRentalAgreementOnFileOverrideChange(value) {
    this.onChange('greatAmericaRentalAgreementOnFileOverride', value);
  }

  get existingDaasCustomerOverride() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return null;
    }

    return this._existingDaasCustomerOverride;
  }

  set existingDaasCustomerOverride(v) {
    this._existingDaasCustomerOverride = v;
  }

  onExistingDaasCustomerOverrideChange(value) {
    this.onChange('existingDaasCustomerOverride', value);
  }

  get numSwitchvoxOnPremSIPStationLocations() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return this.numLocations;
    }

    return 0;
  }

  get numSwitchvoxOnPremUsers() {
    if (!this.switchvoxOnPremEnabled) {
      return 0;
    }

    return this._numSwitchvoxOnPremUsers;
  }

  set numSwitchvoxOnPremUsers(v) {
    this._numSwitchvoxOnPremUsers = v;
  }

  get numSwitchvoxOnPremUsersAllocated() {
    let numSwitchvoxOnPremUsersSum = 0;

    for (let i = 0; i < this.locations.length; i++) {
      numSwitchvoxOnPremUsersSum += this.locations[i].numSwitchvoxOnPremUsers;
    }

    return numSwitchvoxOnPremUsersSum;
  }

  get solutionTypeId() {
    if (this.standaloneServiceNonUCaaS) {
      return SOLUTION_TYPES.STANDALONE_NON_UCAAS;
    }

    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return SOLUTION_TYPES.SWITCHVOX_SIP_STATION;
    }

    if (this.sangomaCXStandalone) {
      return SOLUTION_TYPES.SANGOMA_CX_STANDALONE;
    }

    return this._solutionTypeId;
  }

  set solutionTypeId(val) {
    this._solutionTypeId = val;
  }

  onSolutionTypeIdChange(value) {
    this.onChange('solutionTypeId', value);
  }

  get sipStationEnabled() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return this._sipStationEnabled;
    }

    return false;
  }

  set sipStationEnabled(value) {
    this._sipStationEnabled = Boolean(value);
  }

  get switchvoxOnPremEnabled() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return this._switchvoxOnPremEnabled;
    }

    return false;
  }

  set switchvoxOnPremEnabled(value) {
    this._switchvoxOnPremEnabled = Boolean(value);
  }

  get switchvoxOnPremMaintTermYears() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return this._switchvoxOnPremMaintTermYears;
    }

    return 0;
  }

  set switchvoxOnPremMaintTermYears(value) {
    this._switchvoxOnPremMaintTermYears = Number(value);
  }

  get switchvoxOnPremMaintType() {
    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return this._switchvoxOnPremMaintType;
    }

    return SWITCHVOX_ON_PREM_TYPES.PLATINUM;
  }

  set switchvoxOnPremMaintType(value) {
    this._switchvoxOnPremMaintType = value;
  }

  get switchvoxExistsInOriginalLocation() {
    if (this.orderType !== ORDER_TYPES.ADD_ON) {
      return false;
    }

    return this.switchvoxOnPremEnabledFromQuoteId !== null && this.switchvoxOnPremEnabledFromQuoteId !== this.id;
  }

  get sipStationExistsInOriginalLocation() {
    if (this.orderType !== ORDER_TYPES.ADD_ON) {
      return false;
    }

    return this.sipStationEnabledFromQuoteId !== null && this.sipStationEnabledFromQuoteId !== this.id;
  }

  get maintTypeProp() {
    return this.orderType === ORDER_TYPES.ADD_ON
      ? this.switchvoxOnPremMaintTypeEffective
      : this.switchvoxOnPremMaintType;
  }

  get maintTermProp() {
    return this.orderType === ORDER_TYPES.ADD_ON
      ? this.switchvoxOnPremMaintTermYearsEffective
      : this.switchvoxOnPremMaintTermYears;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1R() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.TWO_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1R2() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.THREE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXPSUB1R4() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.PLATINUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.FIVE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1R() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.TWO_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1R2() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.THREE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get num1SWXTSUB1R4() {
    if (
      (this.switchvoxOnPremEnabledFromQuoteId !== null || this.switchvoxOnPremEnabled === true) &&
      this.maintTypeProp === SWITCHVOX_ON_PREM_TYPES.TITANIUM &&
      this.maintTermProp === SWITCHVOX_ON_PREM_TERM_YEARS.FIVE_YEARS
    ) {
      return this.numSwitchvoxOnPremUsersValue;
    }

    return 0;
  }

  get hasFinalizedLocations() {
    return !!this.locations.length && this.locations.some(loc => loc.quoteStatus === QUOTE_STATUS.FINALIZED);
  }

  buildMarkerOverridesRequest() {
    const request = [];

    for (let i = 0; this.markers.length > i; i++) {
      const markerOverrides = this.markers[i].buildSaveOverridesRequest();

      if (markerOverrides.length > 0) {
        request.push(...markerOverrides);
      }
    }

    return request;
  }

  get nrcTaxEstimates() {
    return this.taxEstimates.find(tax => tax.recurring === false);
  }

  get rcTaxEstimates() {
    return this.taxEstimates.find(tax => tax.recurring === true);
  }

  get contactCenterConcurrencyToggleEnabled() {
    return Boolean(
      this.orderType === ORDER_TYPES.NEW_CUSTOMER ||
        ([ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.ADD_ON, ORDER_TYPES.REWRITE].includes(this.orderType) &&
          AuthContext.model.isSalesOps)
    );
  }

  get smartOfficeLocationsQtyByLocation() {
    return this.locations.filter(loc => loc.smartOffice).length;
  }

  get smartOfficeLocationsQty() {
    if (!PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_SMART_OFFICE)) {
      return 0;
    }

    if (
      this.orderType === ORDER_TYPES.ADD_ON &&
      ((this.id === null && this.smartOfficeFromQuoteId !== null) ||
        (this.id !== null && this.smartOfficeFromQuoteId !== null && this.smartOfficeFromQuoteId !== this.id))
    ) {
      return 1;
    }

    if (this.numLocations < this._smartOfficeLocationsQty) {
      this._smartOfficeLocationsQty = this.numLocations;
    }

    return this._smartOfficeLocationsQty;
  }

  set smartOfficeLocationsQty(v) {
    this._smartOfficeLocationsQty = v;
  }

  /**
   * DO NOT REMOVE. This getter is used by flag
   * @returns {number}
   */
  get smartOfficeServerQty() {
    if (
      this.orderType === ORDER_TYPES.ADD_ON &&
      this.smartOfficeFromQuoteId !== null &&
      this.smartOfficeFromQuoteId !== this.id
    ) {
      return 0;
    }

    return this.smartOfficeLocationsQty;
  }

  /**
   * DO NOT REMOVE. This getter is used by flag
   * @returns {boolean}
   */
  get smartOfficeQuote() {
    return this.smartOfficeLocationsQty > 0;
  }

  get salesExceptionJustification() {
    if (this._salesExceptionJustification === '') {
      return null;
    }

    return this._salesExceptionJustification;
  }

  set salesExceptionJustification(v) {
    this._salesExceptionJustification = v;
  }

  onSalesExceptionJustificationChange(value) {
    this.onChange('salesExceptionJustification', value);
  }

  get switchvoxApplianceProvider() {
    if (!PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return null;
    }

    if (!this.switchvoxOnPremEnabled) {
      return APPLIANCE_PROVIDERS.SANGOMA;
    }

    return this._switchvoxApplianceProvider;
  }

  set switchvoxApplianceProvider(v) {
    this._switchvoxApplianceProvider = v;
  }

  get opportunityProductType() {
    if (this.autoSetOpportunityProductType) {
      const calculatedValue = this.calcResults.getValue('opportunityProductType', RESULT_TYPES.string);

      if (typeof calculatedValue === 'string' || calculatedValue === null) {
        return calculatedValue;
      }
    }

    return this.opportunityProductTypeOverride;
  }

  set opportunityProductType(value) {
    this.opportunityProductTypeOverride = value;
  }

  get opportunityProductTypeOverride() {
    if (!this.autoSetOpportunityProductType && this._opportunityProductTypeOverride === null) {
      const calculatedValue = this.calcResults.getValue('opportunityProductType', RESULT_TYPES.string);

      if (typeof calculatedValue === 'string' || calculatedValue === null) {
        this._opportunityProductTypeOverride = calculatedValue;
      }
    }

    return this._opportunityProductTypeOverride;
  }

  set opportunityProductTypeOverride(value) {
    this._opportunityProductTypeOverride = value;
  }

  get quoteOperationMode() {
    if (this.id !== null) {
      return QUOTE_OPERATION_MODES.DEFAULT;
    }

    return this._quoteOperationMode;
  }

  set quoteOperationMode(v) {
    this._quoteOperationMode = v;
  }

  get existingSangomaCXCustomer() {
    if (this.existingSangomaCXCustomerOverride !== null) {
      return this.existingSangomaCXCustomerOverride;
    }

    if (this.existingSangomaCXCustomerOverride === null && this.existingSangomaCXCustomerFromQuoteId !== null) {
      return true;
    }

    return this._existingSangomaCXCustomer;
  }

  set existingSangomaCXCustomer(v) {
    this._existingSangomaCXCustomer = v;
  }

  onSkipProrationChange(value) {
    this.onChange('skipProration', value);
  }

  onOverrideFreeMonthPromoRulesChange(value) {
    this.onChange('overrideFreeMonthPromoRules', value);
  }

  onAmendmentTaskNumberChange(value) {
    this.onChange('amendmentTaskNumber', value);
  }

  onSaSigningDateChange(value) {
    this.onChange('saSigningDate', value);
  }

  onSwitchvoxOnPremMaintTypeOverrideChange(value) {
    this.onChange('switchvoxOnPremMaintTypeOverride', value);
  }

  onSwitchvoxOnPremMaintTermYearsOverrideChange(value) {
    this.onChange('switchvoxOnPremMaintTermYearsOverride', value);
  }

  onExistingSangomaCXCustomerOverrideChange(value) {
    if (value === null && this.sangomaCXUserTypeAtCustomerLevel !== '') {
      this.sangomaCXUserType = this.sangomaCXUserTypeAtCustomerLevel;
    }

    this.onChange('existingSangomaCXCustomerOverride', value);
  }

  get serviceType() {
    if (this.sangomaCXStandalone) {
      return QUOTE_SERVICE_TYPE.sangomaCXStandalone;
    }

    if (this.standaloneServiceNonUCaaS) {
      return QUOTE_SERVICE_TYPE.standaloneServiceNonUCaaS;
    }

    if (PriceBookContext.model.isSupportsSwitchvoxSIPStation) {
      return QUOTE_SERVICE_TYPE.swvxSipstation;
    }

    return '';
  }

  toggleFocusMode() {
    this.onChange('isFocusMode', !this.isFocusMode);
  }

  get numberOfLocationsWithTrackingId() {
    let count = 0;

    for (let i = 0; i < this.locations.length; i++) {
      /** @type {LocationQuoteModel} */
      const location = this.locations[i];

      if (location.trackingLocationId !== null) {
        count++;
      }
    }

    return count;
  }

  get customerId() {
    return this._customerId;
  }

  set customerId(value) {
    value = Number(value);

    if (!isNaN(value)) {
      this._customerId = value;
    }
  }

  onAddOnFinancingTermMonthsChange(value) {
    this.onChange('addOnFinancingTermMonths', value);
  }

  get disableSangomaPhoneIncentive() {
    return this.spiffDiscount.disableSangomaPhoneIncentive;
  }

  set disableSangomaPhoneIncentive(value) {
    this.spiffDiscount.disableSangomaPhoneIncentive = value;
  }

  get existingSpeechAnalyticsCustomer() {
    if (this.existingSpeechAnalyticsCustomerOverride !== null) {
      return this.existingSpeechAnalyticsCustomerOverride;
    }

    if (this.calcResults.results.existingSpeechAnalyticsCustomer !== undefined) {
      return this.calcResults.getValue('existingSpeechAnalyticsCustomer', RESULT_TYPES.boolean);
    }

    return this._existingSpeechAnalyticsCustomer;
  }

  set existingSpeechAnalyticsCustomer(v) {
    this._existingSpeechAnalyticsCustomer = v;
  }

  onExistingSpeechAnalyticsCustomerOverrideChange(value) {
    this.onChange('existingSpeechAnalyticsCustomerOverride', value);
  }

  get speechAnalyticsActive() {
    if (this.sangomaCXUserType !== SANGOMA_CX_USER_TYPE_OPTIONS.PREMIUM) {
      return false;
    }

    if (this.existingSpeechAnalyticsCustomerOverride === null && this.existingSpeechAnalyticsCustomer) {
      return true;
    }

    return this._speechAnalyticsActive;
  }

  set speechAnalyticsActive(v) {
    this._speechAnalyticsActive = v;
  }

  /**
   * DO NOT REMOVE. This getter is used by fixedCountByPropertyName flags
   * @returns {number}
   */
  get numServiceInsightInitialSetup() {
    return this.customerAttributes.existingServiceInsightCustomer ? 0 : 1;
  }

  get salesOpsInAmending() {
    return AuthContext.model.hasSalesOpsPermissions && this.quoteStatus === QUOTE_STATUS.AMENDING;
  }

  get showDaasSection() {
    return Boolean(
      PriceBookContext.model.flags.pricebookTag.includes(PRICEBOOK_TAGS.SUPPORTS_NEW_DAAS) &&
        !PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
        !this.standaloneServiceNonUCaaS &&
        !this.sangomaCXStandalone
    );
  }

  //TODO create separate Model for show{UiElement} props?
  get showSangomaCxSettingsSection() {
    return Boolean(PriceBookContext.model.isSupportsSangomaCx && !this.standaloneServiceNonUCaaS);
  }

  get showAddendumsSection() {
    return Boolean(
      [ORDER_TYPES.NEW_CUSTOMER, ORDER_TYPES.NEW_LOCATIONS, ORDER_TYPES.REWRITE].includes(this.orderType) &&
        !PartnerContext.model.isWhiteLabelWholesale &&
        !PriceBookContext.model.isSupportsSwitchvoxSIPStation
    );
  }
}

export default CustomerOrderModel;
