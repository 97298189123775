import { DEFAULT_ROWS_LIMIT, DEFAULT_ROW_HEIGHT } from '../constants';

export default function getRowsLimit(paginatorRef, tableHeaderRef, tableRowRef) {
  if (!paginatorRef.current || !tableHeaderRef.current) {
    return DEFAULT_ROWS_LIMIT;
  }

  const { bottom: headerBottom } = tableHeaderRef.current.getBoundingClientRect();
  const { top: paginatorTop } = paginatorRef.current.getBoundingClientRect();
  const rowHeight = tableRowRef.current ? tableRowRef.current.getBoundingClientRect().height : DEFAULT_ROW_HEIGHT;

  // -10 is to prevent table overlapping
  const spaceForRows = paginatorTop - headerBottom - 10;

  return Math.max(2, Math.floor(spaceForRows / rowHeight));
}
