import axios from 'axios';
import config from './../config';
import ApiErrorHandler from '../api/ApiErrorHandler';
import { AuthContext } from '../Context';

const singleton = Symbol();
const singletonEnforcer = Symbol();
const axiosWithGlobalHandler = Symbol();
const axiosWithoutGlobalHandler = Symbol();
const tokenInterceptorWithGlobalHandler = Symbol();
const tokenInterceptorWithoutGlobalHandler = Symbol();

export default class $http {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this[axiosWithGlobalHandler] = axios.create();
    this[axiosWithoutGlobalHandler] = axios.create();

    this[axiosWithGlobalHandler].interceptors.response.use(
      response => Promise.resolve(response),
      error => {
        // skip handling of canceled requests
        if (error.message === 'canceled') {
          return Promise.reject(error);
        }

        return ApiErrorHandler(error);
      }
    );
  }

  get apiDomainURI() {
    return config.api.url;
  }

  initInterceptors() {
    this[tokenInterceptorWithGlobalHandler] = this[axiosWithGlobalHandler].interceptors.request.use(
      config => Object.assign(config, this.header),
      error => Promise.reject(error)
    );

    this[tokenInterceptorWithoutGlobalHandler] = this[axiosWithoutGlobalHandler].interceptors.request.use(
      config => Object.assign(config, this.header),
      error => Promise.reject(error)
    );
  }

  get api() {
    return this[axiosWithGlobalHandler];
  }

  get apiWithoutGlobalHandler() {
    return this[axiosWithoutGlobalHandler];
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new $http(singletonEnforcer);
    }

    return this[singleton];
  }

  get header() {
    return {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + AuthContext.model.token,
        UI_Version: config.version,
      },
    };
  }
}
