import React from 'react';
import { bool, string } from 'prop-types';
import translator from '../../../../../common/translate';
import headerItems from '../headerItems';

const NoResults = ({ message, visible = false }) => {
  if (!visible) {
    return null;
  }

  return (
    <tr>
      <td colSpan={headerItems.length}>
        <div className="no-results">
          <span>{translator.getMessage(message)}</span>
        </div>
      </td>
    </tr>
  );
};

NoResults.propTypes = {
  message: string.isRequired,
  visible: bool,
};

export default NoResults;
