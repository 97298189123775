import React, { Component } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { oneOfType, oneOf, string, element, bool, number, func } from 'prop-types';
import { injectIntl } from 'react-intl';
import { ERROR_CONTEXT_TYPES } from '../../common/enums';
import './errorhandler.style.css';
import TemplateStringReplace from '../../common/helpers/format/TemplateStringReplace';
import replaceStringUrlsWithLinkTag from '../../common/helpers/format/replaceStringUrlsWithLinkTag';

const ICON_CLASSNAME_MAPPING = {
  [ERROR_CONTEXT_TYPES.INFO]: 'info',
  [ERROR_CONTEXT_TYPES.WARNING]: 'warning',
  [ERROR_CONTEXT_TYPES.ERROR]: 'minus-circle',
  [ERROR_CONTEXT_TYPES.SUCCESS]: 'check-circle',
};

class ErrorHandlerComponent extends Component {
  static defaultProps = {
    autoHideDelay: null,
    details: '',
    isShown: false,
    message: '',
    onDialogClose: () => {},
    showCloseButton: true,
    type: ERROR_CONTEXT_TYPES.INFO,
  };

  static propTypes = {
    autoHideDelay: number,
    details: oneOfType([string, element]),
    isShown: bool,
    message: oneOfType([string, element]),
    onDialogClose: func,
    showCloseButton: bool,
    type: oneOf([...Object.values(ERROR_CONTEXT_TYPES)]),
  };

  constructor(props) {
    super(props);

    this.autoHideTimeout = null;

    this.handleClose = this.handleClose.bind(this);
    this.initAutoHide();
  }

  componentDidUpdate() {
    this.initAutoHide();
  }

  get rootNode() {
    return (
      document.getElementById('s2s-modal-error-handler-placeholder') ||
      document.getElementById('app-toolbar') ||
      document.getElementById('app-header') ||
      document.getElementById('root')
    );
  }

  initAutoHide() {
    const { autoHideDelay, isShown } = this.props;

    if (isShown && autoHideDelay) {
      this.setAutoHideTimeout();
    } else {
      this.clearAutoHideTimeout();
    }
  }

  setAutoHideTimeout() {
    const { autoHideDelay } = this.props;

    this.clearAutoHideTimeout();
    this.autoHideTimeout = setTimeout(this.handleClose, autoHideDelay);
  }

  clearAutoHideTimeout() {
    if (this.autoHideTimeout !== null) {
      clearTimeout(this.autoHideTimeout);
    }
  }

  handleClose() {
    const { onDialogClose } = this.props;

    // Removes timeout callback function in case if popup closed by user
    this.clearAutoHideTimeout();
    onDialogClose();
  }

  formatIntlString(msgString) {
    if (typeof msgString !== 'string' || msgString.indexOf('msg_') !== 0) {
      return msgString;
    }

    const parsedString = this.props.intl.formatMessage({ id: msgString });

    return TemplateStringReplace(parsedString, this.props.msgTemplateStringVariables);
  }

  render() {
    const { details, isShown, message, showCloseButton, type } = this.props;

    if (!isShown) {
      return null;
    }

    const className = classNames({
      'error-handler-component': true,
      [type.toLocaleLowerCase()]: true,
    });

    const iconClassName =
      'icon-' +
      (ICON_CLASSNAME_MAPPING[type.toUpperCase()] || ICON_CLASSNAME_MAPPING[Object.keys(ICON_CLASSNAME_MAPPING)[0]]);

    const node = (
      <div className={className}>
        <span className={['error-handler-icon', iconClassName].join(' ')} />
        <div className="error-handler-content">
          {message && <div className="message-text">{this.formatIntlString(message)}</div>}
          {details && (
            <div className="sub-message-text">{replaceStringUrlsWithLinkTag(this.formatIntlString(details))}</div>
          )}
        </div>
        {showCloseButton && <span className="icon-close" onClick={this.handleClose} />}
      </div>
    );

    return createPortal(node, this.rootNode);
  }
}

export default injectIntl(ErrorHandlerComponent);
