import React from 'react';
import PropTypes from 'prop-types';
import PureViewComponent from '../PureViewComponent';
import Category from './nested/Category';
import './css/catalog.css';

class Catalog extends PureViewComponent {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    hideAddButton: PropTypes.bool,
    isWholeSale: PropTypes.bool,
    packageIdSelected: PropTypes.number,
  };

  static defaultProps = {
    data: [],
    lockedState: false,
    disabledState: false,
    hideAddButton: false,
    isWholeSale: false,
  };

  render() {
    const { data, lockedState, disabledState, hideAddButton, isWholeSale, packageIdSelected } = this.props;

    const categories = data
      .filter(d => d.active && !d.hidden)
      .map(d => {
        return (
          <Category
            {...d}
            key={d.uuid}
            lockedState={lockedState}
            disabledState={disabledState}
            hideAddButton={hideAddButton}
            isWholeSale={isWholeSale}
            packageIdSelected={packageIdSelected}
          />
        );
      });

    return (
      <div className="li-catalog">
        <div>{categories}</div>
      </div>
    );
  }
}

export default Catalog;
