import { arrayOf, bool, func, number, oneOf, shape, string } from 'prop-types';
import { ORDER_TYPES, QUOTE_STATUS_LIST } from '../../../../common/enums';

export const quotePropType = shape({
  customerName: string,
  dealerContactName: string,
  dealerName: string,
  id: number,
  masterOrderId: number,
  orderType: oneOf(Object.values(ORDER_TYPES)),
  packageIdSelected: number,
  quoteStatus: oneOf(QUOTE_STATUS_LIST),
  quoteTitle: string,
  recurringTotal: string,
  trackingLocationId: string,
  updated: number,
  upfrontTotal: string,
});

const propTypes = {
  isLoading: bool,
  limit: number,
  onChangeLimit: func,
  onChangePage: func,
  onChangeSearch: func,
  onChangeSort: func,
  page: number,
  quotes: arrayOf(quotePropType),
  savePropsToStorage: func,
  searchString: string,
  sortBy: string,
  sortOrder: string,
  totalCount: number,
};

export default propTypes;
