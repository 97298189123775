import { ERROR_CONTEXT_TYPES, ORDER_TYPES, QUOTE_STATUS, USER_ROLES } from '../../common/enums';
import { AuthContext, ErrorContext } from '../../Context';
import { checkRequiredFieldsForFinalize, finalizeBtnTooltipText } from '../../common/check-required-for-finalize';
import CommonConditionsModel from './CommonConditionsModel';
import { Validate } from '../index';

class ToolbarButtonModel extends CommonConditionsModel {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    this.isActiveQuoteValidationRequested = false;
    this.asyncIsActiveQuote = this.asyncIsActiveQuote.bind(this);
  }

  get visible() {
    return true;
  }

  get disabled() {
    return this.appState.disabled;
  }

  get requiredFinalizeFields() {
    return checkRequiredFieldsForFinalize(
      this.masterOrder,
      !this.controller.rollUpDisabled,
      this.controller.constantsModel.getRequiredForFinalize,
      this.appState.errors.addOnFinalizeAllow
    );
  }

  get finalizeBtnDisabled() {
    return Boolean(
      this.requiredFinalizeFields.fields.length ||
        this.requiredFinalizeFields.separateLineFields.length ||
        [QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SUBMITTED, QUOTE_STATUS.CLOSED].includes(this.masterOrder.quoteStatus) ||
        !this.appState.isSaved ||
        !this.masterOrder.trackingLocationId ||
        !this.controller.rollUpDisabled
    );
  }

  get unFinalizeBtnDisabled() {
    return Boolean(
      !this.appState.isSaved ||
        (this.masterOrder.orderType === ORDER_TYPES.ADD_ON &&
          AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) ||
        (this.masterOrder.spiffDiscount.madPoolOverride &&
          AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) ||
        (this.masterOrder.quoteStatus === QUOTE_STATUS.SIGNED_AND_FINALIZED &&
          AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER) ||
        (this.masterOrder.spiffDiscount.spiffOverrideEnabled &&
          AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER)
    );
  }

  get finalizeBtnErrors() {
    if (
      this.masterOrder.orderType === ORDER_TYPES.ADD_ON &&
      this.masterOrder.quoteStatus === QUOTE_STATUS.FINALIZED &&
      AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER
    ) {
      return 'msg_add_on_can_only_be_un_finalized_by_sales_operation';
    } else if (
      this.finalizeBtnDisabled &&
      ![QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SUBMITTED, QUOTE_STATUS.CLOSED].includes(this.masterOrder.quoteStatus)
    ) {
      const requiredFields = this.requiredFinalizeFields;
      const singleMessage = requiredFields.fields.length === 1 && requiredFields.fields[0].singleMessage;

      return finalizeBtnTooltipText(
        requiredFields,
        AuthContext.model.hasInternalPermissions,
        this.appState.isSaved,
        !this.controller.rollUpDisabled,
        singleMessage
      );
    }

    return '';
  }

  get unFinalizeBtnErrors() {
    if (
      (this.masterOrder.spiffDiscount.madPoolOverride || this.masterOrder.spiffDiscount.spiffOverrideEnabled) &&
      AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER
    ) {
      return 'msg_this_quote_contains_overrides_un_finalization_can_only_be_done_by_sales_operation';
    } else if (
      this.masterOrder.orderType === ORDER_TYPES.ADD_ON &&
      AuthContext.model.role !== USER_ROLES.SALES_OPERATIONS_USER &&
      this.unFinalizeBtnDisabled
    ) {
      return 'msg_add_on_can_only_be_un_finalized_by_sales_operation';
    } else if (this.unFinalizeBtnDisabled) {
      return 'msg_please_save_to_continue';
    } else {
      return '';
    }
  }

  async asyncIsActiveQuote() {
    this.isActiveQuoteValidationRequested = true;
    this.forceRender();

    const isActiveQuote = await Validate.asyncIsActiveQuote(this.masterOrder.id);
    this.isActiveQuoteValidationRequested = false;

    if (!isActiveQuote) {
      this.controller.appState.isEdited = true;

      ErrorContext.model.setProps({
        isShown: true,
        message: 'msg_is_active_quote_validation_failed_message',
        autoHideDelay: 10000,
        showCloseButton: true,
        type: ERROR_CONTEXT_TYPES.WARNING,
      });
    }

    this.forceRender();

    return isActiveQuote;
  }
}

export default ToolbarButtonModel;
