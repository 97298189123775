import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import translator from '../../common/translate';
import Dialog from '../Dialog';
import './ictppsDetails.css';

const COLUMN_HEADERS_LABELS = [
  'msg_line_items_header_description',
  'msg_qty',
  'msg_install',
  'msg_config',
  'msg_train',
  'msg_port',
  'msg_prov',
  'msg_ship',
  'msg_adv_serv',
];

const COLUMN_HEADERS_TOOLTIP = [
  'msg_line_items_header_description',
  'msg_quantity',
  'msg_installation',
  'msg_configuration',
  'msg_training',
  'msg_porting',
  'msg_provisioning',
  'msg_shipping',
  'msg_advanced_services_fee',
];

const TABLE_LABELS = {
  ADMIN_FEE: 'msg_admin',
  CHANNEL_PARTNER: 'msg_channel_partner',
  TOTAL_BEFORE_OVERRIDES: 'msg_total_before_overrides',
  OVERRIDES: 'msg_overrides',
  TOTAL: 'msg_total',
};

class ICTPPSDetails extends React.Component {
  static propTypes = {
    ictppsItems: PropTypes.array,
    onClose: PropTypes.func,
    open: PropTypes.bool,
  };

  static defaultProps = {
    open: true,
  };

  getTable(ictppsItems) {
    return ictppsItems.map((ictppsItem, index) => {
      const isStrong = ictppsItem.type !== 'LINE_ITEM';
      const label =
        ictppsItem.type !== 'LINE_ITEM' ? translator.getMessage(TABLE_LABELS[ictppsItem.type]) : ictppsItem.label;

      return (
        <tr className="ictpps-table-row" key={'ictp-details-table-row' + index} title={ictppsItem.label}>
          {isStrong ? (
            <th title={label}>{label}</th>
          ) : (
            <td title={label} className="align-left">
              {label}
            </td>
          )}
          <td className="ictpps-value-cell" title={ictppsItem.quantity}>
            {ictppsItem.quantity}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.installation}>
            {ictppsItem.installation}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.configuration}>
            {ictppsItem.configuration}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.training}>
            {ictppsItem.training}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.porting}>
            {ictppsItem.porting}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.provisioning}>
            {ictppsItem.provisioning}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.shipping}>
            {ictppsItem.shipping}
          </td>
          <td className="ictpps-value-cell" title={ictppsItem.professionalServicesFee}>
            {ictppsItem.professionalServicesFee}
          </td>
        </tr>
      );
    });
  }

  render() {
    const { ictppsItems, onClose, open } = this.props;
    let dialogProps = {
      modalClassName: 'ictpps-details',
    };

    dialogProps.title = (
      <header className="package-details-title">{translator.getMessage('msg_ictpps_components')}</header>
    );

    dialogProps.actions = [
      <button
        id="package-details-cancel-button"
        key="package-details-close-button"
        className="package-details-dialog-btn-cancel"
        onClick={onClose}
      >
        {translator.getMessage('msg_close')}
      </button>,
    ];

    return (
      <Dialog {...dialogProps} onRequestClose={onClose} isOpen={open}>
        <table className="rq-table striped fit-width sticky align-right ictpps-table">
          <thead>
            <tr className="ictpps-table-header">
              {COLUMN_HEADERS_LABELS.map((header, index) => {
                return (
                  <th
                    className={(index === 0 ? 'align-left ' : '') + 'ictpps-table-header-column'}
                    title={this.props.intl.formatMessage({ id: COLUMN_HEADERS_TOOLTIP[index] })}
                    key={header}
                  >
                    {translator.getMessage(header)}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>{this.getTable(ictppsItems)}</tbody>
        </table>
      </Dialog>
    );
  }
}

export default injectIntl(ICTPPSDetails);
