import React from 'react';
import { string, any } from 'prop-types';

const PackageData = ({ className = '', value = null }) => (
  <div className="package-data">
    <div className={className}>{value}</div>
  </div>
);

PackageData.propTypes = {
  className: string,
  value: any,
};

export default PackageData;
