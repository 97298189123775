import React from 'react';
import './notfound.style.css';
import translator from '../../../common/translate';
import AppRouter from '../../../AppRouter/AppRouter';

export default function NotFoundComponent() {
  const handleButtonClick = () => {
    AppRouter.push('/landing');
  };

  return (
    <section className="not-found-component">
      <h1 className="not-found-header">{translator.getMessage('msg_we_are_sorry')}</h1>
      <h2 className="not-found-sub-header">{translator.getMessage('msg_we_cant_find_page')}</h2>
      <button key="back-to-home" id="back-to-home" className="large" onClick={handleButtonClick}>
        {translator.getMessage('msg_back_to_home_page')}
      </button>
    </section>
  );
}
