import React, { PureComponent } from 'react';
import { RadioButtonControl } from '../Controls';
import './css/location-solution-type.css';

class LocationSolutionType extends PureComponent {
  render() {
    return (
      <div className="location-solution-type">
        <div className="location-solution-type-options">
          <RadioButtonControl {...this.props} parentClassName="solution-type" label="msg_solution_type" />
        </div>
      </div>
    );
  }
}

export default LocationSolutionType;
