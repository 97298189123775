import React from 'react';
import translator from '../../../common/translate';
import Row from './Row';
import '../css/checkpoints.css';

const Table = ({ data }) => {
  return data.length > 0 ? (
    <table className="rq-table striped sticky checkpoint-wrapper">
      <thead>
        <tr className="checkpoint-table-header">
          <th className="align-right">{translator.getMessage('msg_checkpoint')}</th>
          <th>{translator.getMessage('msg_value')}</th>
          <th className="align-right">{translator.getMessage('msg_enabled')}</th>
          <th>{translator.getMessage('msg_name')}</th>
        </tr>
      </thead>
      <tbody>
        {data.map(row => (
          <Row data={row} key={'checkpoint-' + row.id} />
        ))}
      </tbody>
    </table>
  ) : (
    <div className="checkpoint-empty-data">{translator.getMessage('msg_please_save_quote_one_more')}</div>
  );
};

export default Table;
