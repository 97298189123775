import { ModelAbstract } from './';
import { COLUMN_FILTERS, FILTERABLE_COLUMNS } from '../common/enums';
import AppRouter from '../AppRouter/AppRouter';
import TypeConverter from '../common/helpers/TypeConverter';

class QuotesTableModel extends ModelAbstract {
  static SORT_ORDER = {
    ascending: 'asc',
    descending: 'desc',
  };

  constructor(props) {
    super(props);

    const query = window.location.search;
    const params = new URLSearchParams(query);

    this._urlProps = [
      'page',
      'limit',
      'sortBy',
      'sortOrder',
      'searchString',
      'quoteStatus',
      'orderType',
      'packageShortNameSelected',
    ];
    this._columnFilterProps = ['quoteStatus', 'orderType', 'packageShortNameSelected'];

    this.page = 0;
    this.limit = 10;
    this.sortBy = 'updated';
    this.sortOrder = QuotesTableModel.SORT_ORDER.descending;
    // set initial searchString prop to prevent focus on search input
    this.searchString = params.get('searchString') || '';
    this.columnFilters = [
      {
        ...COLUMN_FILTERS[FILTERABLE_COLUMNS.STATUS],
        selectedOptions: [],
      },
      {
        ...COLUMN_FILTERS[FILTERABLE_COLUMNS.ORDER_TYPE],
        selectedOptions: [],
      },
      {
        ...COLUMN_FILTERS[FILTERABLE_COLUMNS.QUOTE_TYPE],
        selectedOptions: [],
      },
    ];

    this.updateUrl = this.updateUrl.bind(this);
  }

  getColumnFilterByType(type) {
    return this.columnFilters.find(column => column.type === type);
  }

  addQuoteTypeFilterOptions(quoteTypes) {
    const quoteTypeFilter = this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.QUOTE_TYPE);
    let options = [];

    quoteTypes.forEach(quoteType => {
      options.push({
        key: quoteType,
        value: quoteType,
        text: quoteType,
      });
    });

    quoteTypeFilter.options = options;
  }

  readPropsFromUrl() {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    const keys = params.keys();
    const props = {};

    for (const key of keys) {
      let value = TypeConverter(params.get(key));

      if (this._columnFilterProps.includes(key)) {
        value = value.split(',');
      }

      props[key] = value;
    }
    this.setProps(props);

    return true;
  }

  updateUrl() {
    const search = [];

    for (let i = 0; i < this._urlProps.length; i++) {
      const propName = this._urlProps[i];
      const value = Array.isArray(this[propName]) ? this[propName].join(',') : encodeURIComponent(this[propName]);

      if (value) {
        search.push(propName + '=' + value);
      }
    }

    AppRouter.replaceWithoutCallback({
      search: search.length ? '?' + search.join('&') : '',
    });
  }

  get quoteStatus() {
    return this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.STATUS).selectedOptions;
  }

  set quoteStatus(v) {
    const quoteStatusItem = this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.STATUS);

    quoteStatusItem.selectedOptions = v;
  }

  get orderType() {
    return this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.ORDER_TYPE).selectedOptions;
  }

  set orderType(v) {
    const orderTypeItem = this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.ORDER_TYPE);

    orderTypeItem.selectedOptions = v;
  }

  get packageShortNameSelected() {
    return this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.QUOTE_TYPE).selectedOptions;
  }

  set packageShortNameSelected(v) {
    const quoteTypeItem = this.columnFilters.find(column => column.type === FILTERABLE_COLUMNS.QUOTE_TYPE);

    quoteTypeItem.selectedOptions = v;
  }
}

export default QuotesTableModel;
