import { CustomerOrderModel, ModelAbstract } from '../index';
import { AuthContext, PriceBookContextModel } from '../../Context';
import { PRICEBOOK_TAGS } from '../../common/enums';

class ExistingServiceInsightCustomerToggleModel extends ModelAbstract {
  constructor(higherOrderModelInstance) {
    super(higherOrderModelInstance);

    this._masterOrder = null;

    this.id = 'existingServiceInsightCustomerToggle';
    this.label = 'msg_existing_service_insight_customer';
    this.onChange = null;

    this._ignoreOnExport.push('masterOrder');
  }

  /**
   * @returns {CustomerOrderModel}
   */
  get masterOrder() {
    if (this._masterOrder !== null) {
      return this._masterOrder;
    }

    this._masterOrder = this.findSibling(i => i instanceof CustomerOrderModel);

    return this._masterOrder;
  }

  get disabledState() {
    return true;
  }

  get lockedState() {
    return this.disabledState;
  }

  get isVisible() {
    return (
      AuthContext.model.hasInternalPermissions &&
      PriceBookContextModel.hasPricebookTag(PRICEBOOK_TAGS.SUPPORTS_SERVICE_INSIGHT_INITIAL_SETUP)
    );
  }

  get value() {
    return this.masterOrder.customerAttributes.existingServiceInsightCustomer;
  }
}

export default ExistingServiceInsightCustomerToggleModel;
