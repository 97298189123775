import DaasSection from '../../../../DaasSection';
import CollapsableCard from '../../../../CollapsableCard';

const DaasSectionWrapper = ({
  //CollapsableCard props
  collapsedSections,
  toggleCollapse,
  id,
  title,
  //DaasSection props
  customerOrder,
  ...daasModelProps
}) => {
  return (
    <CollapsableCard
      collapsedSections={collapsedSections}
      toggleCollapse={toggleCollapse}
      key={id}
      id={id}
      title={title}
      className="quote-card-container"
    >
      <DaasSection customerOrder={customerOrder} {...daasModelProps} />
    </CollapsableCard>
  );
};

export default DaasSectionWrapper;
