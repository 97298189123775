import { AddDialogModel } from './index';

class AddExistingDialogModel extends AddDialogModel {
  get isForExistingItems() {
    return true;
  }

  get suppressedIds() {
    return [];
  }
}

export default AddExistingDialogModel;
