import { EMAIL_REGEX } from '../enums';

function isValidEmail(str) {
  if (typeof str !== 'string') {
    return false;
  }

  return str.match(EMAIL_REGEX) !== null;
}

export default isValidEmail;
