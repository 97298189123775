const REQUIRED_FIELDS_CONTACT_MO_NEW_NEW = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'businessContactEmail',
  'billToId',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactEmail',
];

const REQUIRED_FIELDS_CONTACT_LOC_NEW_NEW = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'businessContactEmail',
  'billToId',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactEmail',
  'shippingContactFirstName',
  'shippingContactLastName',
  'shippingAddress1',
  'shippingCity',
  'shippingStateId',
  'shippingCountryId',
  'shippingZip',
  'shippingCompanyName',
];

const REQUIRED_FIELDS_CONTACT_MO_NEW_LOCATIONS = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'billToId',
  'businessContactEmail',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactEmail',
  'shippingCompanyName',
];

const REQUIRED_FIELDS_CONTACT_MO_ADD_ON = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'businessContactEmail',
  'billToId',
  'shippingContactFirstName',
  'shippingContactLastName',
  'shippingAddress1',
  'shippingCity',
  'shippingStateId',
  'shippingCountryId',
  'shippingZip',
  'shippingCompanyName',
];

const REQUIRED_FIELDS_CONTACT_MO_REWRITE = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'billToId',
  'businessContactEmail',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactEmail',
  'shippingCompanyName',
];

const REQUIRED_FIELDS_CONTACT_MO_REDUCTION = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'businessContactEmail',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactEmail',
];

const REQUIRED_FIELDS_CONTACT_LOC_REDUCTION = [
  'businessContactFirstName',
  'businessContactLastName',
  'streetAddress1',
  'city',
  'stateId',
  'zip',
  'countryId',
  'businessContactOfficePhone',
  'btn',
  'businessContactEmail',
  'billingContactFirstName',
  'billingContactLastName',
  'billingContactAddress1',
  'billingContactCity',
  'billingContactStateId',
  'billingContactZip',
  'billingContactCountryId',
  'billingContactOfficePhone',
  'billingContactEmail',
  'shippingContactFirstName',
  'shippingContactLastName',
  'shippingAddress1',
  'shippingCity',
  'shippingStateId',
  'shippingCountryId',
  'shippingZip',
];

export const REQUIRED_FIELDS = {
  CONTACT_MO: {
    NEW_NEW: REQUIRED_FIELDS_CONTACT_MO_NEW_NEW,
    NEW_LOCATIONS: REQUIRED_FIELDS_CONTACT_MO_NEW_LOCATIONS,
    ADD_ON: REQUIRED_FIELDS_CONTACT_MO_ADD_ON,
    REWRITE: REQUIRED_FIELDS_CONTACT_MO_REWRITE,
    REDUCTION: REQUIRED_FIELDS_CONTACT_MO_REDUCTION,
  },
  CONTACT_LOC: {
    NEW_NEW: REQUIRED_FIELDS_CONTACT_LOC_NEW_NEW,
    REDUCTION: REQUIRED_FIELDS_CONTACT_LOC_REDUCTION,
  },
};
