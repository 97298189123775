import React from 'react';
import { injectIntl } from 'react-intl';
import translator from '../../../common/translate/index';
import AppRouter from '../../../AppRouter/AppRouter';
import EmblemLarge from './../../EmblemComponent';
import './landing.style.css';
import BrowserDetector from '../../../common/helpers/BrowserDetector';

class LandingComponent extends React.Component {
  render() {
    const { intl } = this.props;
    const isOutdatedBrowser = !new BrowserDetector().isSupported();

    if (isOutdatedBrowser) {
      return (
        <section className="landing-section">
          <div className="landing-left-part">
            <EmblemLarge />
          </div>
          <div className="landing-right-part">
            <div style={{ width: 400 }}>
              <span className="landing-panel-span ie-browser-warning">
                {intl.formatMessage({ id: 'msg_browser_not_supported' })}
                <a href="http://www.google.com/chrome/" target="_blank" rel="noopener noreferrer">
                  {intl.formatMessage({ id: 'msg_google_chrome' })}
                </a>
                .
              </span>
            </div>
          </div>
          {this.props.children}
        </section>
      );
    }

    return (
      <section className="landing-section">
        <div className="landing-left-part">
          <EmblemLarge />
        </div>
        <div className="landing-right-part">
          <button id="landing-start-new-quote-button" className="huge primary" onClick={() => AppRouter.push('/create')}>
            <span>{translator.getMessage('msg_create_quote')}</span>
          </button>
          <button
            className="huge tertiary"
            id="landing-retrieve-quotes-button"
            onClick={() => AppRouter.push('/quotes')}
          >
            <span>{translator.getMessage('msg_retrieve_quotes')}</span>
          </button>
        </div>
        {this.props.children}
      </section>
    );
  }
}

export default injectIntl(LandingComponent);
