import { CustomerOrderModel } from '../index';
import CommonDownloadButtonModel from './CommonDownloadButtonModel';
import { ORDER_TYPES } from '../../common/enums';
import config from '../../config';
import { AuthContext } from '../../Context';

class BilledServiceCsvDownloadButtonModel extends CommonDownloadButtonModel {
  constructor(customerOrderInstance) {
    if (!(customerOrderInstance instanceof CustomerOrderModel)) {
      throw new Error('BilledServiceCsvDownloadButtonModel only accept CustomerOrderModel as higher order instance');
    }

    super(customerOrderInstance);

    this._ignoreOnExport.push('masterOrder');

    this.label = 'msg_billed_service_csv_button_label';
  }

  get visible() {
    return this.masterOrder.orderType && this.masterOrder.orderType === ORDER_TYPES.REWRITE;
  }

  get downloadUrl() {
    return [
      config.api.url,
      '/customer/',
      this.masterOrder.customerId,
      '/locations/services?token=',
      AuthContext.model.token,
    ].join('');
  }

  get locationIdsAndNames() {
    let result = {};
    const locations = this.masterOrder.locations;

    for (let i = 0; i < locations.length; i++) {
      /** @type {LocationQuoteModel} */
      const locationModel = locations[i];
      const { star2starLocationId, locationName } = locationModel;

      result[star2starLocationId] = locationName;
    }

    return result;
  }
}

export default BilledServiceCsvDownloadButtonModel;
