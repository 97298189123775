import React from 'react';
import { bool, func } from 'prop-types';
import translator from '../../common/translate';
import texts from '../../common/texts/texts.quote';
import ExternalLink from '../ExternalLink';
import VersionInfo from '../VersionInfoComponent';
import Modal from 'react-modal';
import './about-modal.css';

const AboutComponent = ({ open = true, toggleAbout = () => {} }) => {
  const onRequestClose = React.useCallback(() => toggleAbout(false), [toggleAbout]);

  return (
    <Modal
      isOpen={open}
      overlayClassName="default-overlay-modal"
      className="default-confirm-modal about-modal"
      ariaHideApp={false}
      shouldCloseOnEsc={true}
      onRequestClose={onRequestClose}
    >
      <div className="default-modal-label">
        <p className="light-bold">{translator.getMessage(texts.Frame.about.title)}</p>
        <br />
        <VersionInfo />
        <div className="about-modal__content-link">
          <ExternalLink
            path="https://sangoma.com/terms"
            title={translator.getMessage('msg_about_dialog_link_terms_of_use')}
          />
          &nbsp;|&nbsp;
          <ExternalLink
            path="https://sangoma.com/privacy-policy"
            title={translator.getMessage('msg_about_dialog_link_privacy_policy')}
          />
        </div>
        <div className="about-modal__content-copyright">
          &copy;{new Date().getFullYear()}
          &nbsp;
          {translator.getMessage('msg_sangoma_all_rights_reserved')}
        </div>
      </div>
      <div className="default-confirm-modal-actions">
        <button className="about-modal__close-btn" onClick={onRequestClose}>
          {translator.getMessage('msg_close')}
        </button>
      </div>
    </Modal>
  );
};

AboutComponent.propTypes = {
  open: bool,
  toggleAbout: func,
};

export default AboutComponent;
