import ORDER_TYPES from '../../common/enums/orderTypes.enums';
import { CategoryModel, QuotesCollectionModel } from '../';

export default class PartnerCategoryModel extends CategoryModel {
  _showMarkupTotal = false;

  get columns() {
    let result = [];

    if (this.isInLocationQuote) {
      result = [
        {
          alias: 'lineItemName',
          header: null,
        },
        {
          alias: 'partnerProductName',
          header: 'msg_line_items_header_description',
        },
        {
          alias: 'totalQuantity',
          header: 'msg_total_quantity',
        },
        {
          alias: 'available',
          header: 'msg_available',
        },
        {
          alias: 'quantity',
          header: 'msg_quantity',
        },
        {
          alias: 'dealerNet',
          header: 'msg_quote_price',
        },
        {
          alias: 'total',
          header: 'msg_total',
        },
      ];
    } else {
      result = [
        {
          alias: 'lineItemName',
          header: null,
        },
        {
          alias: 'partnerProductName',
          header: 'msg_line_items_header_description',
        },
        {
          alias: 'quantity',
          header: 'msg_quantity',
        },
        {
          alias: 'allocated',
          header: 'msg_allocated',
        },
        {
          alias: 'dealerNet',
          header: 'msg_quote_price',
        },
        {
          alias: 'total',
          header: 'msg_total',
        },
      ];

      /** @type {QuotesCollectionModel} **/
      const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

      if (quotes && quotes.customerOrder) {
        if ([ORDER_TYPES.ADD_ON, ORDER_TYPES.REDUCTION].includes(quotes.customerOrder.orderType)) {
          result.splice(
            result.findIndex(d => d.alias === 'allocated'),
            1
          );
        }
      }
    }

    return result;
  }
}
