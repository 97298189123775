// Add formats for Number components (https://github.com/yahoo/react-intl/issues/539)
const formats = {
  number: {
    USD: {
      style: 'currency',
      currency: 'USD',
    },
  },
};

export default formats;
