export const ADDENDUM_TYPES = {
  AUTO_RENEWAL_ADDENDUM: 'AUTO_RENEWAL_ADDENDUM',
  TF_REDUCED_RATE_ADDENDUM: 'TF_REDUCED_RATE_ADDENDUM',
  LD_REDUCED_RATE_ADDENDUM: 'LD_REDUCED_RATE_ADDENDUM',
  RIGHT_TO_CANCEL_EXTENSION_ADDENDUM: 'RIGHT_TO_CANCEL_EXTENSION_ADDENDUM',
  ASSIGNMENT_ADDENDUM: 'ASSIGNMENT_ADDENDUM',
  BUSINESS_DOWNTURN_ADDENDUM: 'BUSINESS_DOWNTURN_ADDENDUM',
};

export const ADDENDUM_FIELDS = {
  SUBSCRIPTION_AGREEMENT_DATE: 'SUBSCRIPTION_AGREEMENT_DATE',
  TASK_NUMBER: 'TASK_NUMBER',
  ENTITY_TYPE: 'ENTITY_TYPE',
  TF_RATE: 'TF_RATE',
  LD_RATE: 'LD_RATE',
  MBG_PERIOD: 'MBG_PERIOD',
  BD_LOSS_PERCENTAGE: 'BD_LOSS_PERCENTAGE',
  BD_NUMERIC_LOSS_PERCENTAGE: 'BD_NUMERIC_LOSS_PERCENTAGE',
  BD_ANNIVERSARY: 'BD_ANNIVERSARY',
  BD_OBLIGATION_PERCENTAGE: 'BD_OBLIGATION_PERCENTAGE',
  BD_NUMERIC_OBLIGATION_PERCENTAGE: 'BD_NUMERIC_OBLIGATION_PERCENTAGE',
};

export const ADDENDUMS = [
  {
    documentType: ADDENDUM_TYPES.ASSIGNMENT_ADDENDUM,
    active: false,
    fields: {
      [ADDENDUM_FIELDS.TASK_NUMBER]: '',
      [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: '',
      [ADDENDUM_FIELDS.ENTITY_TYPE]: '',
    },
  },
  {
    documentType: ADDENDUM_TYPES.BUSINESS_DOWNTURN_ADDENDUM,
    active: false,
    fields: {
      [ADDENDUM_FIELDS.BD_LOSS_PERCENTAGE]: '',
      [ADDENDUM_FIELDS.BD_NUMERIC_LOSS_PERCENTAGE]: '',
      [ADDENDUM_FIELDS.BD_OBLIGATION_PERCENTAGE]: '',
      [ADDENDUM_FIELDS.BD_NUMERIC_OBLIGATION_PERCENTAGE]: '',
      [ADDENDUM_FIELDS.TASK_NUMBER]: '',
      [ADDENDUM_FIELDS.BD_ANNIVERSARY]: '',
      [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: '',
      [ADDENDUM_FIELDS.ENTITY_TYPE]: '',
    },
  },
  {
    documentType: ADDENDUM_TYPES.AUTO_RENEWAL_ADDENDUM,
    active: false,
    fields: {
      [ADDENDUM_FIELDS.TASK_NUMBER]: '',
      [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: '',
      [ADDENDUM_FIELDS.ENTITY_TYPE]: '',
    },
  },
  {
    documentType: ADDENDUM_TYPES.LD_REDUCED_RATE_ADDENDUM,
    active: false,
    fields: {
      [ADDENDUM_FIELDS.LD_RATE]: '',
      [ADDENDUM_FIELDS.TASK_NUMBER]: '',
      [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: '',
      [ADDENDUM_FIELDS.ENTITY_TYPE]: '',
    },
  },
  {
    documentType: ADDENDUM_TYPES.TF_REDUCED_RATE_ADDENDUM,
    active: false,
    fields: {
      [ADDENDUM_FIELDS.TF_RATE]: '',
      [ADDENDUM_FIELDS.TASK_NUMBER]: '',
      [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: '',
      [ADDENDUM_FIELDS.ENTITY_TYPE]: '',
    },
  },
  {
    documentType: ADDENDUM_TYPES.RIGHT_TO_CANCEL_EXTENSION_ADDENDUM,
    active: false,
    fields: {
      [ADDENDUM_FIELDS.TASK_NUMBER]: '',
      [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: '',
      [ADDENDUM_FIELDS.MBG_PERIOD]: '',
      [ADDENDUM_FIELDS.ENTITY_TYPE]: '',
    },
  },
];

export const ADDENDUM_TYPES_LABEL = {
  [ADDENDUM_TYPES.ASSIGNMENT_ADDENDUM]: 'msg_assignment_addendum',
  [ADDENDUM_TYPES.BUSINESS_DOWNTURN_ADDENDUM]: 'msg_business_downturn_addendum',
  [ADDENDUM_TYPES.AUTO_RENEWAL_ADDENDUM]: 'msg_auto_renewal_addendum',
  [ADDENDUM_TYPES.LD_REDUCED_RATE_ADDENDUM]: 'msg_ld_reduced_rate_addendum',
  [ADDENDUM_TYPES.TF_REDUCED_RATE_ADDENDUM]: 'msg_tf_reduced_rate_addendum',
  [ADDENDUM_TYPES.RIGHT_TO_CANCEL_EXTENSION_ADDENDUM]: 'msg_right_to_cancel_extension_addendum',
};

export const ADDENDUM_FIELDS_LABEL = {
  [ADDENDUM_FIELDS.SUBSCRIPTION_AGREEMENT_DATE]: 'msg_subscription_agreement_date',
  [ADDENDUM_FIELDS.TASK_NUMBER]: 'msg_task_number',
  [ADDENDUM_FIELDS.ENTITY_TYPE]: 'msg_entity_type',
  [ADDENDUM_FIELDS.TF_RATE]: 'msg_tf_rate',
  [ADDENDUM_FIELDS.LD_RATE]: 'msg_ld_rate',
  [ADDENDUM_FIELDS.MBG_PERIOD]: 'msg_mbg_period',
  [ADDENDUM_FIELDS.BD_LOSS_PERCENTAGE]: 'msg_bd_loss_percentage',
  [ADDENDUM_FIELDS.BD_NUMERIC_LOSS_PERCENTAGE]: 'msg_numeric_loss_percentage',
  [ADDENDUM_FIELDS.BD_ANNIVERSARY]: 'msg_bd_anniversary',
  [ADDENDUM_FIELDS.BD_OBLIGATION_PERCENTAGE]: 'msg_obligation_percentage',
  [ADDENDUM_FIELDS.BD_NUMERIC_OBLIGATION_PERCENTAGE]: 'msg_bd_numeric_obligation_percentage',
};

export const ADDENDUMS_ORDER = {
  [ADDENDUM_TYPES.ASSIGNMENT_ADDENDUM]: 1,
  [ADDENDUM_TYPES.BUSINESS_DOWNTURN_ADDENDUM]: 2,
  [ADDENDUM_TYPES.AUTO_RENEWAL_ADDENDUM]: 3,
  [ADDENDUM_TYPES.LD_REDUCED_RATE_ADDENDUM]: 4,
  [ADDENDUM_TYPES.TF_REDUCED_RATE_ADDENDUM]: 5,
  [ADDENDUM_TYPES.RIGHT_TO_CANCEL_EXTENSION_ADDENDUM]: 6,
};

export const ADDENDUM_DROPDOWN_FIELDS = [ADDENDUM_FIELDS.BD_ANNIVERSARY];
export const ADDENDUM_NUMBER_FIELDS = [
  ADDENDUM_FIELDS.MBG_PERIOD,
  ADDENDUM_FIELDS.BD_NUMERIC_LOSS_PERCENTAGE,
  ADDENDUM_FIELDS.BD_NUMERIC_OBLIGATION_PERCENTAGE,
];
export const ADDENDUM_AMOUNT_FIELDS = [ADDENDUM_FIELDS.TF_RATE, ADDENDUM_FIELDS.LD_RATE];

export const ADDENDUM_NUMBER_FIELDS_MAX_VALUE = {
  [ADDENDUM_FIELDS.MBG_PERIOD]: 999,
  [ADDENDUM_FIELDS.BD_NUMERIC_LOSS_PERCENTAGE]: 100,
  [ADDENDUM_FIELDS.BD_NUMERIC_OBLIGATION_PERCENTAGE]: 100,
};

export const ADDENDUM_DROPDOWN_FIELDS_OPTIONS = {
  [ADDENDUM_FIELDS.BD_ANNIVERSARY]: [
    {
      key: '1',
      value: 'First (1st)',
      text: 'First (1st)',
    },
    {
      key: '2',
      value: 'Second (2nd)',
      text: 'Second (2nd)',
    },
    {
      key: '3',
      value: 'Third (3rd)',
      text: 'Third (3rd)',
    },
    {
      key: '4',
      value: 'Fourth (4th)',
      text: 'Fourth (4th)',
    },
  ],
};
