import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Modal from 'react-modal';
import { injectIntl } from 'react-intl';
import translator from '../../../common/translate';
import {
  CUSTOMIZE_PDF_OPTIONS,
  CUSTOMIZE_PDF_OPTION_LABELS,
  CUSTOMIZE_PDF_OPTION_LIST,
  ESIG_DOCS_LABELS,
  ESIG_ENVELOPE_TYPES,
  LEASE_OPTIONS,
  ESIG_RENTAL_NOTICE,
} from '../../../common/enums';
import { AuthContext, ErrorContext } from '../../../Context';
import { UpperCaseEachWord } from '../../../common/helpers/format';
import { TextControl, CheckboxControl, ToggleControl, DatePickerControl } from '../../Controls';
import Loader from '../../Loader';
import Dialog from '../../Dialog';
import BadgeButton from '../../BadgeButton';
import HiddenFormHelper from '../../HiddenFormHelper';
import './signatureComponent.css';

const TITLE_MAP = {
  subject: 'msg_subject',
  title: 'msg_title',
  message: 'msg_message',
  messageNonCustomizable: 'msg_message',
  signerName: 'msg_signer_name',
  signerEmail: 'msg_signer_email_address',
  ccQuoteOwner: 'msg_cc_quote_owner',
  creditCustomerSigningDate: 'msg_credit_signing_date',
  contents: 'msg_contents',
  customizePdf: 'msg_customize_pdf',
};
const WHITE_LABEL_TITLE_MAP = {
  signerName: 'msg_wl_signer_name',
  signerEmail: 'msg_wl_signer_email',
};
const CUSTOM_DOCUMENT = 'CUSTOM_DOCUMENT';
const OPTIONAL_FIELDS = ['ccEmails', 'ccEmailsCheckbox', 'customDocument', 'ccEmail1', 'ccEmail2'];

class SignatureComponent extends React.Component {
  static propTypes = {
    availableDocuments: PropTypes.array,
    eSignature: PropTypes.object,
    onClickClose: PropTypes.func,
    onClickSubmit: PropTypes.func,
    onChange: PropTypes.func,
    previewPdfUrlAndValues: PropTypes.func,
    hint: PropTypes.string,
    hintWarning: PropTypes.string,
    ccEmail: PropTypes.string,
    customerName: PropTypes.string,
    billingContact: PropTypes.string,
    billingEmail: PropTypes.string,
    billingPhone: PropTypes.string,
    isSubmitSignatureRequested: PropTypes.bool,
    isSalesOps: PropTypes.bool,
    isVisible: PropTypes.bool,
    isInternalUser: PropTypes.bool,
    disabledState: PropTypes.bool,
    isPurchase: PropTypes.bool,
    isFinanced: PropTypes.bool,
    validateEmail: PropTypes.func,
    validateSignatureSubmit: PropTypes.func,
    quoteId: PropTypes.number,
    leaseOption: PropTypes.string,
    leasePayment: PropTypes.number,
    isLeaseOptionAvailable: PropTypes.bool,
    showCreditDocumentsOnFileMessage: PropTypes.bool,
    showRentalAgreementOnFileMessage: PropTypes.bool,
    isAddon: PropTypes.bool,
    showRentalNotice: PropTypes.bool,
    isWhiteLabelWholesale: PropTypes.bool,
    quoteOwnerEmail: PropTypes.string,
    standaloneServiceNonUCaaS: PropTypes.bool,
  };

  static defaultProps = {
    isVisible: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      confirmModalOpened: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getDocuments = this.getDocuments.bind(this);
    this.checkIfAnyDocSelected = this.checkIfAnyDocSelected.bind(this);
    this.checkCreditCustomerSigningDate = this.checkCreditCustomerSigningDate.bind(this);
    this.handleCloseConfirm = this.handleCloseConfirm.bind(this);
    this.getConfirmModalBody = this.getConfirmModalBody.bind(this);
  }

  handleOnChange(propName, type, event) {
    const value = 'message' === propName ? event.target.value : event;

    this.props.eSignature.onChangeRequestProps(type, propName, value);
  }

  handleClose() {
    if (ErrorContext.model.isShown) {
      ErrorContext.model.setProps({
        isShown: false,
      });
    }

    if (typeof this.props.onClickClose === 'function' && !this.props.isSubmitSignatureRequested) {
      this.props.onClickClose();
    }
  }

  handleCloseConfirm() {
    this.setState({ confirmModalOpened: false }, this.props.onClickClose);
  }

  handleSubmit() {
    if (!this.state.confirmModalOpened) {
      this.setState({ confirmModalOpened: true });
    } else if (typeof this.props.onClickSubmit === 'function') {
      this.props.onClickSubmit(this.props.onClickClose);
      this.setState({ confirmModalOpened: false });
    }
  }

  onClickFileUpload(index) {
    this.refs[`custom-pdf-${index}`].click();
  }

  handleFile(type, bundleIndex, e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    // Next line allow to upload same file second time
    e.target.value = '';

    let { defaultCounterSignerEmail, defaultCounterSignerName } = this.props.availableDocuments[
      bundleIndex
    ].documents.find(doc => doc.documentType === CUSTOM_DOCUMENT);

    reader.onloadend = () => {
      const fileObject = {};

      fileObject.base64EncodedFile = reader.result.split(',')[1];
      fileObject.fileName = file.name;
      fileObject.signerEmail = defaultCounterSignerEmail;
      fileObject.signerName = defaultCounterSignerName;

      this.props.eSignature.onChangeRequestProps(type, 'customDocument', fileObject);
    };

    reader.readAsDataURL(file);
  }

  getEnvelopeTitle(isMultipleEnvelopes) {
    const bundleTitleClassNames = classNames({
      'bundle-title': true,
      'bundle-title-multiple-envelope': isMultipleEnvelopes,
      'bundle-title-single-envelope': !isMultipleEnvelopes,
      'wide-label-row': this.props.isWhiteLabelWholesale,
    });

    return (
      <div className={bundleTitleClassNames}>
        <span />
        {this.props.availableDocuments.map(bundle => (
          <span key={'key-' + bundle.label}>
            {bundle.label} {this.getSubTitle(bundle.type)}
          </span>
        ))}
      </div>
    );
  }

  getSubTitle(bundleType) {
    return bundleType === ESIG_ENVELOPE_TYPES.CREDIT ? (
      <span>{translator.getMessage('msg_must_be_signed_by_majority')}</span>
    ) : null;
  }

  getRow(row, ariaLabel) {
    const { disabledState, eSignature, intl, isSalesOps, validateEmail, quoteId } = this.props;

    return this.props.availableDocuments.map((bundle, index) => {
      const availableBundle = bundle.available;
      const agreementBundle = bundle.type === ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER;
      const amendmentBundle = bundle.type === ESIG_ENVELOPE_TYPES.AMENDMENT;
      const isAdditionalCredit = bundle.type === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT;
      const customDocument = bundle.documents.find(doc => doc.documentType === CUSTOM_DOCUMENT);
      const isCustomDocumentSelected = customDocument ? customDocument.selected : false;
      const requiredFieldClassName = classNames({
        'signature-required-red': !OPTIONAL_FIELDS.includes(row),
      });

      switch (row) {
        case 'signerEmail': {
          return (
            <TextControl
              className={requiredFieldClassName}
              key={`signature-${row}-${index}`}
              id={`signature-signerEmail-${index}`}
              lockedState={!availableBundle || disabledState}
              label=""
              value={eSignature[bundle.type][row]}
              onChange={this.handleOnChange.bind(this, row, bundle.type)}
              errorText={validateEmail(eSignature[bundle.type][row])}
              parentClassName="signature-email"
              ariaLabel={ariaLabel}
            />
          );
        }
        case 'ccQuoteOwner': {
          return agreementBundle || amendmentBundle ? (
            <label key={`signature-${row}-${index}`} className="inline-grid-columns">
              <CheckboxControl
                id="signature-cc-quote-owner"
                lockedState={!availableBundle || disabledState}
                className="cc-checkbox"
                value={eSignature[bundle.type].ccEmailsCheckbox}
                onChange={this.handleOnChange.bind(this, 'ccEmailsCheckbox', bundle.type)}
              />
              <span>{this.props.quoteOwnerEmail}</span>
            </label>
          ) : null;
        }
        case 'message': {
          return (
            <div className={`signature-${row}-wrapper ${requiredFieldClassName}`} key={`signature-${row}-${index}`}>
              <textarea
                name={row}
                id={`signautre-${row}-${index}`}
                cols="30"
                rows={row === 'message' ? 3 : 1}
                maxLength={row === 'message' ? 2500 : 1024}
                disabled={!availableBundle || disabledState}
                value={eSignature[bundle.type][row]}
                onChange={this.handleOnChange.bind(this, row, bundle.type)}
                aria-label={ariaLabel}
              />
            </div>
          );
        }
        case 'messageNonCustomizable': {
          return agreementBundle ? (
            <div className={`signature-${row}-wrapper ${requiredFieldClassName}`} key={`signature-${row}-${index}`}>
              <span className="textarea-like-string disabled">{ESIG_RENTAL_NOTICE}</span>
            </div>
          ) : null;
        }
        case 'contents': {
          return (
            <div className="signature-contents-block" key={`signature-${row}-${index}`}>
              <div className="docs-pdf-wrapper">
                <div className="document-wrapper">
                  {this.getDocuments(bundle, index)}
                  {isSalesOps && !isAdditionalCredit && (
                    <div className="custom-pdf-wrapper">
                      <div className="pdf-title">{translator.getMessage('msg_custom_pdf')}:</div>
                      <input
                        style={{ display: 'none' }}
                        type="file"
                        id={`custom-pdf-${index}`}
                        ref={`custom-pdf-${index}`}
                        accept="application/pdf"
                        onChange={this.handleFile.bind(this, bundle.type, index)}
                        aria-label={this.props.intl.formatMessage({ id: 'msg_custom_pdf' })}
                      />
                      <div className="btn-collection">
                        <TextControl
                          id={`signature-custom-pdf-${index}`}
                          label=""
                          lockedState={true}
                          value={
                            eSignature[bundle.type].customDocument ? eSignature[bundle.type].customDocument.fileName : ''
                          }
                          placeholder={intl.formatMessage({
                            id: 'msg_file_name',
                          })}
                          onChange={() => {}}
                          ariaLabel={this.props.intl.formatMessage({ id: 'msg_file_name' })}
                        />
                        <button
                          className="pdf-upload-btn tertiary"
                          disabled={!availableBundle || disabledState || !isCustomDocumentSelected}
                          onClick={this.onClickFileUpload.bind(this, index)}
                        >
                          {translator.getMessage('msg_browse')}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                {agreementBundle && (
                  <div className="additional-cc-emails-wrapper">
                    <div className="additional-cc-emails-title">
                      {translator.getMessage('msg_additional_cc_emails')}:
                    </div>
                    <TextControl
                      key={`signature-cc-email-1-${row}-${index}`}
                      id={`signature-ccEmail1-${index}`}
                      lockedState={!availableBundle || disabledState}
                      label="1."
                      value={eSignature[bundle.type].ccEmail1}
                      onChange={this.handleOnChange.bind(this, 'ccEmail1', bundle.type)}
                      errorText={validateEmail(eSignature[bundle.type].ccEmail1)}
                      parentClassName="signature-email"
                      ariaLabel={this.props.intl.formatMessage({ id: 'msg_additional_cc_emails' })}
                    />
                    <TextControl
                      key={`signature-cc-email-2-${row}-${index}`}
                      id={`signature-ccEmail2-${index}`}
                      lockedState={!availableBundle || disabledState}
                      label="2."
                      value={eSignature[bundle.type].ccEmail2}
                      onChange={this.handleOnChange.bind(this, 'ccEmail2', bundle.type)}
                      errorText={validateEmail(eSignature[bundle.type].ccEmail2)}
                      parentClassName="signature-email"
                      ariaLabel={this.props.intl.formatMessage({ id: 'msg_additional_cc_emails' })}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        }
        case 'customizePdf': {
          if (this.props.isWhiteLabelWholesale) {
            return null;
          }

          return (
            <div className="customize-pdf-block" key={`signature-${row}-${index}`}>
              <div className="docs-pdf-wrapper">
                <div className="document-wrapper">{this.getPdfOptions(index)}</div>
              </div>
            </div>
          );
        }
        case 'subject': {
          return (
            <div key={'signature-' + row + '-' + index} className="esig-subject-wrapper">
              <TextControl
                className="esig-subject-input"
                type="text"
                id={'signature-' + row + '-' + index}
                lockedState={!availableBundle || disabledState}
                label=""
                value={eSignature[bundle.type][row]}
                onChange={this.handleOnChange.bind(this, row, bundle.type)}
                ariaLabel={ariaLabel}
              />
              <span className="esig-subject-quote-id plus-prefix input-like-string disabled">(Quote #{quoteId})</span>
            </div>
          );
        }
        case 'title': {
          return (
            <div key={'signature-' + row + '-' + index} className="esig-subject-wrapper">
              <TextControl
                className={requiredFieldClassName}
                key={`signature-${row}-${index}`}
                id={`signature-${row}-${index}`}
                lockedState={!availableBundle || disabledState}
                label=""
                value={eSignature[bundle.type][row]}
                onChange={this.handleOnChange.bind(this, row, bundle.type)}
                ariaLabel={ariaLabel}
              />
              <span className="esig-title-rq-id plus-prefix input-like-string disabled">RQ: {quoteId}</span>
            </div>
          );
        }
        case 'creditCustomerSigningDate': {
          if (!isAdditionalCredit) {
            return null;
          }
          const date = eSignature[bundle.type].creditCustomerSigningDate;
          const value = date ? new Date(date).getTime() : null;
          const isInternal = AuthContext.model.hasInternalPermissions;

          return (
            <DatePickerControl
              className={requiredFieldClassName}
              key={`signature-${row}-${index}`}
              id={`signature-${row}-${index}`}
              lockedState={!availableBundle || disabledState || !isInternal}
              value={value}
              onChange={this.handleOnChange.bind(this, row, bundle.type)}
              minDate={0}
            />
          );
        }
        default: {
          return (
            <TextControl
              className={requiredFieldClassName}
              key={`signature-${row}-${index}`}
              id={`signature-${row}-${index}`}
              lockedState={!availableBundle || disabledState}
              label=""
              value={eSignature[bundle.type][row]}
              onChange={this.handleOnChange.bind(this, row, bundle.type)}
              ariaLabel={ariaLabel}
            />
          );
        }
      }
    });
  }

  _isAdditionalCreditAvailable() {
    return this.props.availableDocuments.some(({ type }) => type === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT);
  }

  getRows(isMultipleEnvelopes) {
    const rows = [];
    const { isPurchase, isWhiteLabelWholesale } = this.props;

    for (let title in TITLE_MAP) {
      if (
        (title === 'customizePdf' && (!isPurchase || isWhiteLabelWholesale)) ||
        (title === 'creditCustomerSigningDate' && !this._isAdditionalCreditAvailable()) ||
        (title === 'messageNonCustomizable' && !this.props.showRentalNotice) ||
        (title === 'ccQuoteOwner' &&
          this.props.availableDocuments.every(
            bundle => ![ESIG_ENVELOPE_TYPES.AGREEMENT_AND_ORDER, ESIG_ENVELOPE_TYPES.AMENDMENT].includes(bundle.type)
          ))
      ) {
        continue;
      }

      const signatureRowClassName = classNames({
        'signature-row': true,
        'signature-row-multiple-envelope': isMultipleEnvelopes,
        'signature-row-single-envelope': !isMultipleEnvelopes,
        'wide-label-row': isWhiteLabelWholesale,
      });
      let rowTitle = TITLE_MAP[title];

      if (title === 'signerName' && isWhiteLabelWholesale) {
        rowTitle = WHITE_LABEL_TITLE_MAP.signerName;
      }

      if (title === 'signerEmail' && isWhiteLabelWholesale) {
        rowTitle = WHITE_LABEL_TITLE_MAP.signerEmail;
      }

      rows.push(
        <div className={signatureRowClassName} key={'key-' + title}>
          {/* TODO: Make use of htmlFor instead of aria-label */}
          <label className="signature-row-title">
            {translator.getMessage(rowTitle)}:
            {title === 'message' && (
              <p className="signature-message-sub">({translator.getMessage('msg_customizable')})</p>
            )}
            {title === 'messageNonCustomizable' && (
              <p className="signature-message-sub">{translator.getMessage('msg_lease_form_body_part_two_sub_label')}</p>
            )}
          </label>
          {this.getRow(title, this.props.intl.formatMessage({ id: TITLE_MAP[title] }))}
        </div>
      );
    }

    return rows;
  }

  getDocuments(bundle, bundleIndex) {
    return bundle.documents.map(doc => {
      return (
        <div key={`${doc.documentType}-signature-${bundleIndex}`}>
          <ToggleControl
            id={`${doc.documentType}-signature-${bundleIndex}`}
            parentClassName="signature-documents-input"
            className="compact"
            value={doc.selected}
            label={this.getDocumentLabel(doc.documentType)}
            onChange={() => this.props.onChange(doc.documentType, doc.order, bundleIndex)}
            lockedState={!doc.editable || !bundle.available || this.props.disabledState}
          />
        </div>
      );
    });
  }

  getDocumentLabel(type) {
    return ESIG_DOCS_LABELS[type]
      ? this.props.intl.formatMessage({ id: ESIG_DOCS_LABELS[type] })
      : UpperCaseEachWord(type, '_');
  }

  getPdfOptions(bundleIndex) {
    const { leaseOption, eSignature, disabledState, intl, leasePayment, isLeaseOptionAvailable } = this.props;
    const options = [];

    for (let i = 0; i < CUSTOMIZE_PDF_OPTION_LIST.length; i++) {
      const propName = CUSTOMIZE_PDF_OPTION_LIST[i];
      let tooltip = '';

      if (propName === CUSTOMIZE_PDF_OPTIONS.showEstimatedLeasePayment) {
        if (!isLeaseOptionAvailable) {
          continue;
        }

        if (leaseOption === LEASE_OPTIONS.NONE) {
          tooltip = intl.formatMessage({ id: 'msg_estimated_lease_payment_tooltip' });
        } else if (leasePayment <= 0) {
          tooltip = intl.formatMessage({ id: 'msg_not_eligible_for_lease' });
        }
      }

      options.push(
        <div key={propName + '-signature-' + bundleIndex}>
          <ToggleControl
            id={propName + '-signature-' + bundleIndex}
            parentClassName="signature-documents-input"
            className="compact"
            value={eSignature.customizePdfOptionsMap[propName]}
            label={CUSTOMIZE_PDF_OPTION_LABELS[propName]}
            onChange={this.onPdfOptionChange.bind(this, propName)}
            disabledState={eSignature.customizePdfOptionsDisabledMap[propName] || disabledState}
            tooltipText={tooltip}
          />
        </div>
      );
    }

    return options;
  }

  onPdfOptionChange(name, val) {
    if (typeof this.props.eSignature.onChangePdfOption === 'function') {
      this.props.eSignature.onChangePdfOption(name, val);
    }
  }

  checkIfAnyDocSelected(bundleIndex, bundleType) {
    let errorMessage = '';
    const docsBundle = this.props.availableDocuments[bundleIndex];
    const selectedDocs = docsBundle.documents.filter(doc => doc.selected);
    const customDocSelected = selectedDocs.find(doc => doc.documentType === 'CUSTOM_DOCUMENT') !== undefined;

    if (selectedDocs.length === 0) {
      errorMessage = '@msg_select_at_least_one_document_for_signature@\n';
    } else if (customDocSelected && this.props.eSignature[bundleType].customDocument === null) {
      errorMessage = '@msg_please_add_custom_pdf_file@\n';
    }

    return errorMessage;
  }

  checkCreditCustomerSigningDate(bundleType) {
    return this.props.eSignature[bundleType].creditCustomerSigningDate === ''
      ? '@msg_credit_signing_date_required@'
      : '';
  }

  __formatPhoneNumber(val, pattern) {
    let i = 0;

    return pattern.replace(/#/g, () => val[i++]);
  }

  __getErrorsCount(errors) {
    return (errors.match(new RegExp('@', 'g')) || []).length / 2;
  }

  render() {
    const {
      isSubmitSignatureRequested,
      availableDocuments,
      disabledState,
      intl,
      showCreditDocumentsOnFileMessage,
      showRentalAgreementOnFileMessage,
      standaloneServiceNonUCaaS,
    } = this.props;

    const isMultipleEnvelopes = availableDocuments.length > 1;
    const hasOnFileMessage = showCreditDocumentsOnFileMessage || showRentalAgreementOnFileMessage;

    let dialogProps = {
      actionsContainerClassName: isMultipleEnvelopes
        ? 'signature-actions-multiple-envelope'
        : 'signature-actions-single-envelope',
      modalClassName: isMultipleEnvelopes ? 'signature-wrapper-multiple-envelope' : 'signature-wrapper-single-envelope',
      contentClassName: hasOnFileMessage ? 'has-on-file-message' : '',
    };

    dialogProps.actionsContainerClassName += ' signature-submit-dialog-actions';
    dialogProps.modalClassName += ' signature-request-modal';

    let errorMessageFirstBundle = '';
    let errorMessageSecondBundle = '';

    for (let i = 0; i < availableDocuments.length; i++) {
      const bundleType = availableDocuments[i].type;
      let creditCustomerSigningDateError = '';

      if (bundleType === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT) {
        creditCustomerSigningDateError = this.checkCreditCustomerSigningDate(bundleType);
      }

      if (i === 0) {
        errorMessageFirstBundle = this.checkIfAnyDocSelected(i, bundleType);
        errorMessageFirstBundle += creditCustomerSigningDateError;
      } else {
        errorMessageSecondBundle = this.checkIfAnyDocSelected(i, bundleType);
        errorMessageSecondBundle += creditCustomerSigningDateError;
      }
    }

    const errorMessageForEmptyFields = this.props.validateSignatureSubmit(
      this.props.availableDocuments,
      this.props.eSignature,
      OPTIONAL_FIELDS
    );

    const submitBtnDisabled =
      Boolean(errorMessageFirstBundle) ||
      Boolean(errorMessageSecondBundle) ||
      Boolean(errorMessageForEmptyFields) ||
      isSubmitSignatureRequested;
    const submitBtnErrors = errorMessageFirstBundle || errorMessageSecondBundle || errorMessageForEmptyFields || '';
    const pdfUrlAndValuesBundleOne = availableDocuments[0] ? this.props.previewPdfUrlAndValues(0) : {};
    const pdfUrlAndValuesBundleTwo = availableDocuments[1] ? this.props.previewPdfUrlAndValues(1) : {};

    dialogProps.actions = availableDocuments.length > 0 && [
      <div key="empty-space" />,
      availableDocuments[0] && [
        <BadgeButton
          key="preview-pdf-first-bundle"
          id="preview-pdf-first-bundle"
          btnClassName="btn-signature-print"
          disabled={Boolean(errorMessageFirstBundle)}
          errors={errorMessageFirstBundle}
          errorsCnt={this.__getErrorsCount(errorMessageFirstBundle)}
          label={`${intl.formatMessage({ id: 'msg_preview' })} ${availableDocuments[0].label} PDF`}
          form="preview-pdf-first-bundle-form"
        />,
        <HiddenFormHelper
          id="preview-pdf-first-bundle-form"
          key="preview-pdf-first-bundle-form"
          action={pdfUrlAndValuesBundleOne.url}
          values={pdfUrlAndValuesBundleOne.values}
        />,
      ],
      <div className="signature-controls" key="signature">
        {availableDocuments[1] ? (
          [
            <BadgeButton
              key="preview-pdf-second-bundle"
              id="preview-pdf-second-bundle"
              btnClassName="btn-signature-print"
              disabled={Boolean(errorMessageSecondBundle)}
              errors={errorMessageSecondBundle}
              errorsCnt={this.__getErrorsCount(errorMessageSecondBundle)}
              label={`${intl.formatMessage({ id: 'msg_preview' })} ${availableDocuments[1].label} PDF`}
              form="preview-pdf-second-bundle-form"
            />,
            <HiddenFormHelper
              id="preview-pdf-second-bundle-form"
              key="preview-pdf-second-bundle-form"
              action={pdfUrlAndValuesBundleTwo.url}
              values={pdfUrlAndValuesBundleTwo.values}
            />,
          ]
        ) : (
          <div key="preview-pdf-empty" />
        )}

        <Loader isVisible={isSubmitSignatureRequested} />

        <div key="signature-controls" className="btn-collection">
          <button
            id="signature-cancel"
            className="btn-signature-cancel text-btn"
            onClick={this.handleClose}
            disabled={disabledState}
          >
            {intl.formatMessage({ id: 'msg_cancel' })}
          </button>
          <BadgeButton
            id="signature-submit"
            btnClassName="btn-signature-submit primary"
            errorsCnt={submitBtnDisabled && !isSubmitSignatureRequested ? 1 : 0}
            errors={submitBtnErrors}
            disabled={submitBtnDisabled}
            label={intl.formatMessage({ id: 'msg_submit' })}
            onClick={this.handleSubmit}
          />
        </div>
      </div>,
    ];

    dialogProps.title = (
      <div className="submit-title-wrapper">
        <header className="submit-title-header text-ellipsis">
          {intl.formatMessage({ id: 'msg_send_for_signature' })}&nbsp;
          {standaloneServiceNonUCaaS && (
            <span className="submit-title-header-hint text-ellipsis">
              {intl.formatMessage({ id: 'msg_quote_not_include_ucaas' })}
            </span>
          )}
        </header>
      </div>
    );

    dialogProps.onRequestClose = this.handleClose;

    return (
      <React.Fragment>
        <Dialog {...dialogProps} isOpen={this.props.isVisible}>
          <div className="signature-body-wrapper">
            {availableDocuments.length > 0 && this.getBody(isMultipleEnvelopes)}
          </div>
          {hasOnFileMessage ? (
            <div className="credit-document-on-file-block">
              <h2>Credit</h2>
              {showCreditDocumentsOnFileMessage && <p>{translator.getMessage('msg_credit_documents_on_file')}</p>}
              {showRentalAgreementOnFileMessage && <p>{translator.getMessage('msg_rental_agreement_on_file')}</p>}
            </div>
          ) : null}
        </Dialog>

        {this.state.confirmModalOpened ? (
          <Modal
            isOpen={this.state.confirmModalOpened}
            overlayClassName="modal-dialog-overlay"
            className="modal-dialog-content confirm-signature"
            ariaHideApp={false}
            shouldCloseOnEsc={true}
          >
            <div className="message">{this.getConfirmModalBody()}</div>
            <div className="signature-confirm-controll">
              <button
                id="signature-confirm-submit"
                className="btn-signature-submit primary"
                disabled={disabledState}
                onClick={this.handleSubmit}
              >
                {intl.formatMessage({ id: 'msg_submit' })}
              </button>
              <button
                id="signature-confirm-cancel"
                className="btn-signature-cancel tertiary"
                disabled={disabledState}
                onClick={this.handleCloseConfirm}
              >
                {intl.formatMessage({ id: 'msg_cancel' })}
              </button>
            </div>
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }

  getBody(isMultipleEnvelopes) {
    return (
      <React.Fragment>
        {this.getEnvelopeTitle(isMultipleEnvelopes)}
        {this.getRows(isMultipleEnvelopes)}
      </React.Fragment>
    );
  }

  getConfirmModalBody() {
    const body = [];
    const {
      customerName,
      billingContact,
      billingEmail,
      billingPhone,
      isFinanced,
      eSignature,
      isAddon,
      isWhiteLabelWholesale,
    } = this.props;

    if (this.props.availableDocuments.length) {
      const envelopType = this.props.availableDocuments[0].type;

      if (envelopType === ESIG_ENVELOPE_TYPES.ADDITIONAL_CREDIT) {
        body.push(
          <div key="esr-confirm-please_confirm_following">{translator.getMessage('msg_please_confirm_following')}</div>,
          <div key="esr-confirm-additional_credit_signer_name">
            {translator.getMessage('msg_additional_credit_signer_name')}&nbsp;
            <span>{eSignature[envelopType].signerName}</span>
          </div>,
          <div key="esr-confirm-additional_credit_signer_email" data-test="additional-credit">
            {translator.getMessage('msg_additional_credit_signer_email')}&nbsp;
            <span>{eSignature[envelopType].signerEmail}</span>
          </div>
        );
      } else {
        body.push(
          <div key="esr-confirm-please_confirm_following">{translator.getMessage('msg_please_confirm_following')}</div>,
          <div key="esr-confirm-customer_legal_business_name">
            {translator.getMessage('msg_customer_legal_business_name')}&nbsp;<span>{customerName}</span>
          </div>,
          <div key="esr-confirm-quote_document_signer_name">
            {translator.getMessage('msg_quote_document_signer_name')}&nbsp;
            <span>{eSignature[envelopType].signerName}</span>
          </div>,
          <div key="esr-confirm-quote_document_signer_email">
            {translator.getMessage('msg_quote_document_signer_email')}&nbsp;
            <span>{eSignature[envelopType].signerEmail}</span>
          </div>
        );

        if (!isAddon) {
          body.splice(2, 0, [
            <div key="esr-confirm-billing_contact">
              {translator.getMessage('msg_billing_contact')}&nbsp;<span>{billingContact}</span>
            </div>,
            <div key="esr-confirm-billing_email">
              {translator.getMessage('msg_billing_email')}&nbsp;<span>{billingEmail}</span>
            </div>,
            <div key="esr-confirm-billing_phone">
              {translator.getMessage('msg_billing_phone')}&nbsp;
              <span>{this.__formatPhoneNumber(billingPhone, '###-###-####')}</span>
            </div>,
          ]);
        }

        if (
          isFinanced &&
          !isWhiteLabelWholesale &&
          eSignature[ESIG_ENVELOPE_TYPES.CREDIT].signerName &&
          eSignature[ESIG_ENVELOPE_TYPES.CREDIT].signerEmail
        ) {
          body.push(
            <div key="esr-confirm-credit-signer-name">
              {translator.getMessage('msg_quote_document_signer_name_majority')}&nbsp;
              <span>{eSignature[ESIG_ENVELOPE_TYPES.CREDIT].signerName}</span>
            </div>,
            <div key="esr-confirm-credit-signer-email">
              {translator.getMessage('msg_quote_document_signer_email_majority')}&nbsp;
              <span>{eSignature[ESIG_ENVELOPE_TYPES.CREDIT].signerEmail}</span>
            </div>
          );
        }
      }
      return body;
    }
  }
}

export default injectIntl(SignatureComponent);
