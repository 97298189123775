import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import translator from '../../common/translate';
import * as formatter from '../../common/helpers/format';
import './css/location-spiff.css';

class LocationSpiff extends PureComponent {
  static propTypes = {
    nrcTotal: PropTypes.number,
    nrcAvailable: PropTypes.number,
    nrcApplied: PropTypes.number,
    rcTotal: PropTypes.number,
    rcAvailable: PropTypes.number,
    rcApplied: PropTypes.number,
    spiffTotal: PropTypes.number,
    spiffAvailable: PropTypes.number,
    spiffApplied: PropTypes.number,
    isSalesOps: PropTypes.bool,
    showSangomaPhoneIncentive: PropTypes.bool,
    showPartnerSpiff: PropTypes.bool,
    sangomaPhoneIncentiveTotal: PropTypes.number,
    sangomaPhoneIncentiveAvailable: PropTypes.number,
    sangomaPhoneIncentiveApplied: PropTypes.number,
  };

  static defaultProps = {
    nrcTotal: 0,
    nrcApplied: 0,
    rcTotal: 0,
    rcApplied: 0,
    spiffTotal: 0,
    spiffApplied: 0,
    showSangomaPhoneIncentive: false,
    sangomaPhoneIncentiveTotal: 0,
    sangomaPhoneIncentiveAvailable: 0,
    sangomaPhoneIncentiveApplied: 0,
  };

  renderSpiffRow(label, total, available, applied, id) {
    return (
      <div id={id} className="line-item">
        <div className="cell">
          <label>{translator.getMessage(label)}</label>
        </div>
        <div className="cell label">
          <label>{formatter.currency(total)}</label>
        </div>
        {this.props.isSalesOps && (
          <div className="cell label">
            <label>{formatter.currency(available)}</label>
          </div>
        )}
        <div className="cell label applied-to-location">
          <label>{formatter.currency(applied)}</label>
        </div>
      </div>
    );
  }

  render() {
    const {
      nrcTotal,
      nrcAvailable,
      nrcApplied,
      rcTotal,
      rcAvailable,
      rcApplied,
      spiffTotal,
      spiffAvailable,
      spiffApplied,
      isSalesOps,
      showSangomaPhoneIncentive,
      showPartnerSpiff,
      sangomaPhoneIncentiveTotal,
      sangomaPhoneIncentiveAvailable,
      sangomaPhoneIncentiveApplied,
    } = this.props;

    return (
      <div className="li-category">
        <div className="headers">
          <div className="cell" />
          <div className="cell">
            <label>{translator.getMessage('msg_total_discount')}</label>
          </div>
          {isSalesOps && (
            <div className="cell">
              <label>{translator.getMessage('msg_available')}</label>
            </div>
          )}
          <div className="cell applied-to-location">
            <label>{translator.getMessage('msg_applied_to_location')}</label>
          </div>
        </div>
        <div className="line-items">
          {this.renderSpiffRow('msg_location_rc_discount', rcTotal, rcAvailable, rcApplied, 'location-rc-discount')}
          {this.renderSpiffRow('msg_location_nrc_discount', nrcTotal, nrcAvailable, nrcApplied, 'location-nrc-discount')}
          {showPartnerSpiff &&
            this.renderSpiffRow('msg_spiff', spiffTotal, spiffAvailable, spiffApplied, 'location-spiff')}
          {showSangomaPhoneIncentive
            ? this.renderSpiffRow(
                'msg_sangoma_phone_incentive',
                sangomaPhoneIncentiveTotal,
                sangomaPhoneIncentiveAvailable,
                sangomaPhoneIncentiveApplied,
                'location-sangoma-phone-incentive'
              )
            : null}
        </div>
      </div>
    );
  }
}

export default LocationSpiff;
