import {
  LocationPartnerLineItemModel,
  LocationLineItemModel,
  PartnerLineItemModel,
  LocationQuoteModel,
  LineItemModel,
  IctpLineItemModel,
  LocationIctpLineItemModel,
  QuoteModel,
  CollectionAbstract,
  QuotesCollectionModel,
} from '../';
import apiData from '../../Storage/apiData';
import { constants, PARTNER_CATEGORY_IDS } from '../../common/enums';
import bindMultiple from '../../common/helpers/bindMultiple';

export default class LineItemsCollectionModel extends CollectionAbstract {
  constructor(higherOrderInstance) {
    super(higherOrderInstance);

    bindMultiple(this, this.add, this.addExisting);
  }

  /**
   * Returns an array of LineItemModel
   * @returns {LineItemModel[]}
   */
  get items() {
    return super.items;
  }

  __isIctpLineItem(categoryId) {
    return categoryId === constants.ICTP_CATEGORY_ID;
  }

  _pickClass(data) {
    let ClassDefinition = null;

    if (data.partnerAdd) {
      ClassDefinition = this.isInLocationQuote ? LocationPartnerLineItemModel : PartnerLineItemModel;
    } else if (this.__isIctpLineItem(data.lineItemCategoryId)) {
      ClassDefinition = this.isInLocationQuote ? LocationIctpLineItemModel : IctpLineItemModel;
    } else {
      ClassDefinition = this.isInLocationQuote ? LocationLineItemModel : LineItemModel;
    }

    return ClassDefinition;
  }

  _isUniqueByLineItemId(apiLineItemData) {
    return (
      PARTNER_CATEGORY_IDS.includes(apiLineItemData.lineItemCategoryId) === false &&
      (apiLineItemData.catalogFlags.find(d => d.flag.name === 'placeholderProduct') || {}).valueAsString !== 'TRUE'
    );
  }

  _add(data, doRecursion, isTopParentExisting, isExisting) {
    const apiLineItemData = apiData.lineItems.find(d => d.lineItemId === data.lineItemId);
    const isUniqueItem = this._isUniqueByLineItemId(apiLineItemData);

    // KM-6355: Customer should be able to add multiple partner line items of same type
    if (isUniqueItem) {
      const itemInQuote = this._collection.find(d => {
        return d.lineItemId === data.lineItemId && d.existing === isExisting;
      });

      if (itemInQuote) {
        return itemInQuote;
      }
    }

    let newData = {
      // Always add line item data returned by API
      ...apiLineItemData,

      // KM-3222: Default count for newly added line item should be equal 1
      quantity: 1,

      // But it can be overwrite by supplied data. For ex. on quote retrieve
      ...data,

      // KM-10628
      existing: isExisting,
    };

    const ClassDefinition = this._pickClass(newData);
    const lineItem = new ClassDefinition(this).setProps(newData);

    this._collection.push(lineItem);

    if (doRecursion) {
      for (let i = 0; i < lineItem.subLineItems.length; i++) {
        const s = lineItem.subLineItems[i];
        const subData = apiData.lineItems.find(d => d.lineItemId === s.subLineItemId);

        // Skip child if it is not found in catalog returned by API
        if (typeof subData !== 'object' || subData === null) {
          continue;
        }

        // KM-3880: Inactive line-items should NOT be auto-added by active parent
        if (!subData.active) {
          continue;
        }

        // KM-10628: Skip NonRecurring sub items if parent is added as existing item
        const subItemCategoryData = apiData.categories.find(c => c.id === subData.lineItemCategoryId);

        if (isTopParentExisting && !subItemCategoryData.recurring) {
          continue;
        }

        // KM-10628: Sub items always added as NON existing items
        this._add(subData, doRecursion, isTopParentExisting, false);
      }
    }

    return lineItem;
  }

  add(data, doRecursion = true) {
    return this._add(data, doRecursion, false, false);
  }

  addExisting(data, doRecursion = true) {
    return this._add(data, doRecursion, true, true);
  }

  remove(uuid) {
    const index = this._collection.findIndex(d => d.uuid === uuid);

    if (index === -1) {
      return false;
    }

    /** @type {LineItemModel} */
    const lineItem = this._collection[index];

    lineItem.remove();

    // KM-8764: Reset Packaged Applications allocation toggle in case all PA items removed
    /** @type {QuotesCollectionModel} */
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (!quotes) {
      return;
    }

    for (let i = 1; i < quotes.items.length; i++) {
      quotes.items[i].syncLineItems();
    }

    const masterOrder = quotes.masterOrder;

    if (masterOrder && !masterOrder.packagedApplicationsExists) {
      const locationQuotes = quotes.locationQuotes;

      for (let i = 0; i < locationQuotes.length; i++) {
        /** @type {LocationQuoteModel} */
        const location = locationQuotes[i];

        if (!location.allocateAllCustomerLevelPackagedApplications) {
          continue;
        }

        location.allocateAllCustomerLevelPackagedApplications = false;

        // Break the loop. Because there should never be more than one toggle set to ON
        break;
      }
    }
  }

  removeFromCollectionByIndex(index) {
    return Boolean(this._collection.splice(index, 1).length);
  }

  removeFromCollectionByUuid(uuid) {
    const index = this._collection.findIndex(d => d.uuid === uuid);

    if (index === -1) {
      return false;
    }

    return this.removeFromCollectionByIndex(index);
  }

  get isInLocationQuote() {
    return this.findSibling(d => d instanceof QuoteModel) instanceof LocationQuoteModel;
  }

  _resetIctpProps(instance) {
    instance.ictpProviders.mixed = false;
    instance.ictpProviders.inited = false;
    instance.ictpProviders.providers = [];
  }

  wipe() {
    if (this.isInLocationQuote) {
      /** @type {LocationQuoteModel} */
      const locationQuote = this.findSibling(d => d instanceof LocationQuoteModel);
      this._resetIctpProps(locationQuote);

      return super.wipe();
    }

    /** @type {QuotesCollectionModel} */
    const quotes = this.findSibling(d => d instanceof QuotesCollectionModel);

    if (!quotes) {
      return false;
    }

    const masterOrder = quotes.masterOrder;

    if (!masterOrder) {
      return false;
    }

    const locations = masterOrder.locations;
    const locationsLength = locations.length;

    for (let i = 0; i < locationsLength; i++) {
      /** @type {LocationQuoteModel} */
      const locationQuote = locations[i];

      // Recursion to this method on location level
      locationQuote.lineItems.wipe();
    }

    this._resetIctpProps(masterOrder);

    return super.wipe();
  }
}
