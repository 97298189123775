import { AppStateContext, AuthContext, ErrorContext } from '../Context';

// Generic API error handler
const ApiErrorHandler = error => {
  let message = 'API request failed';
  let details = 'Please try again later.';
  let priority = 99;

  if (error.response) {
    const r = error.response;
    const status = r.status;
    const d = r.data;
    const code = d?.code ? Number(d.code) : null;

    if (status) {
      if (status === 401 || (status === 400 && d === 'Token is empty')) {
        AuthContext.model.setProps({
          showLoginModal: true,
        });

        return Promise.reject(error);
      }

      // KM-11697: BE and UI version validation handler
      if (status === 400 && code === 1) {
        AppStateContext.model.setProps({
          versionsMismatch: true,
        });

        return Promise.reject(error);
      }

      message = 'API request failed with status code ' + r.status;
    }

    if (d) {
      if (d.message) {
        details = d.message;
      } else if (typeof d === 'string') {
        details = d;
      }
    }
  } else if (error.message && error.message === 'Network Error') {
    message = 'API Server Down or not Reachable';
    details = 'Check your internet connection and reload the page.';
    priority = 999;
  }

  ErrorContext.model.setProps({
    message,
    details,
    priority,
    isShown: true,
    autoHideDelay: null,
    onClose: null,
    showCloseButton: true,
  });

  return Promise.reject(error);
};

export default ApiErrorHandler;
