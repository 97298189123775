// TODO: Replace this file by CSS implementation
export const statusCollection = {
  CLOSED: {
    color: 'var(--color-crimson)',
  },
  SUBMITTING: {
    color: 'var(--color-pacific-blue)',
  },
  SUBMITTED: {
    color: 'var(--color-vivid-violet)',
  },
  FINALIZED: {
    color: 'var(--color-vivid-violet)',
  },
  UNFINALIZED: {
    color: 'var(--color-chateau-green)',
  },
  SAVED: {
    color: 'var(--color-chateau-green)',
  },
  IN_PROGRESS: {
    color: 'var(--color-orange-peel)',
  },
  LOADING: {
    color: 'var(--color-cerulean-blue)',
  },
  SAVING: {
    color: 'var(--color-cerulean-blue)',
  },
  CALCULATING: {
    color: 'var(--color-cerulean-blue)',
  },
  SE_PENDING_APPROVAL: {
    color: 'var(--color-vivid-violet',
  },
  LOADING_CATALOG: {
    color: 'var(--color-cerulean-blue)',
  },
  SE_APPROVED: {
    color: 'var(--color-chateau-green)',
  },
  FINALIZING: {
    color: 'var(--color-cerulean-blue)',
  },
  UN_FINALIZING: {
    color: 'var(--color-cerulean-blue)',
  },
  SENT_FOR_SIGNATURE: {
    color: 'var(--color-chateau-green)',
  },
  SIGNED_AND_FINALIZED: {
    color: 'var(--color-vivid-violet)',
  },
  AMENDING: {
    color: 'var(--color-vivid-violet)',
  },
  OPEN: {
    color: 'var(--color-chateau-green)',
  },
};

export const donutsCollection = ['IN_PROGRESS'];

export const loaderCollection = ['LOADING', 'SAVING', 'CALCULATING', 'FINALIZING', 'UN_FINALIZING', 'LOADING_CATALOG'];
