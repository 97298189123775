import { finalizeBtnTooltipText } from '../../common/check-required-for-finalize';
import { checkRequiredFieldsForFinalPrint } from '../../common/check-required-for-print';
import { ESIG_ENVELOPE_TYPES, ORDER_TYPES, QUOTE_STATUS, SIGNATURE_STATUS } from '../../common/enums';
import ToolbarButtonModel from './ToolbarButtonModel';
import { PriceBookContext } from '../../Context';

class PrintFinalModel extends ToolbarButtonModel {
  get disabled() {
    return Boolean(
      this.disabledBecauseOfAmending ||
        this.errorsCnt ||
        !this.appState.isSaved ||
        super.disabled ||
        this.appState.switchvoxWlwDisabled
    );
  }

  get visible() {
    return (
      this.printFlow ||
      !this.isESigFlow ||
      (this.masterOrder.orderType === ORDER_TYPES.ADD_ON &&
        PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
        !this.masterOrder.isSipStationDocumentsAvailable)
    );
  }

  get errors() {
    if (!this.appState.isSaved || this.hasRequiredFieldsError || this.appState.isSaveAllowed) {
      return finalizeBtnTooltipText(this.requiredFields);
    }

    return '';
  }

  get errorsCnt() {
    return this.requiredFields ? this.requiredFields.fields.length : 0;
  }

  get label() {
    if (this.printFlow) {
      return this.finalPdfLabel;
    }

    return this.masterOrder.existingAlsAgreement ? 'msg_final_order' : 'msg_final_quote';
  }

  get finalPdfLabel() {
    const { existingAlsAgreement, orderType, lockedInPrintFlow } = this.masterOrder;

    if (!lockedInPrintFlow && orderType !== ORDER_TYPES.ADD_ON) {
      return 'msg_preview_final_quote';
    }

    return existingAlsAgreement ? 'msg_final_order' : 'msg_final_quote';
  }

  get requiredFields() {
    return checkRequiredFieldsForFinalPrint(
      this.masterOrder,
      this.controller.constantsModel.getRequiredForFinalize,
      this.appState.errors.salesExceptionsRules,
      this.controller.rollUpDisabled
    );
  }

  get hasRequiredFieldsError() {
    if (!this.requiredFields) {
      return false;
    }

    return this.requiredFields.fields.length || this.requiredFields.seRulesMessage !== '';
  }

  get disabledBecauseOfAmending() {
    return (
      this.masterOrder.quoteStatus === QUOTE_STATUS.AMENDING ||
      this.masterOrder.amendmentTaskNumber ||
      this.masterOrder.signatureHistory.findIndex(
        s => s.bundleType === ESIG_ENVELOPE_TYPES.AMENDMENT && s.status === SIGNATURE_STATUS.COMPLETED
      ) !== -1
    );
  }

  get infoTooltip() {
    if (this.disabledBecauseOfAmending) {
      return 'msg_preview_final_quote_tooltip_in_amending';
    }

    return this.masterOrder.useESigDocumentsFlow ? 'msg_quick_quote_tooltip' : 'msg_final_quote_tooltip';
  }
}

export default PrintFinalModel;
