import React, { Component } from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ErrorContext } from '../../Context';
import './css/dialog.css';
import bindMultiple from '../../common/helpers/bindMultiple';
import { ERROR_BANNER_IDS } from '../../common/enums';

// Set root element for modal and reset default styles
Modal.setAppElement(document.getElementById('root'));
Modal.defaultStyles = {};

class _Dialog extends Component {
  static propTypes = {
    isOpen: PropTypes.bool,
    onRequestClose: PropTypes.func,
    title: PropTypes.node,
    actions: PropTypes.node,
    className: PropTypes.string,
    portalClassName: PropTypes.string,
    modalClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    contentClassName: PropTypes.string,
    actionsClassName: PropTypes.string,
    closeButton: PropTypes.bool,
    dialogWithErrors: PropTypes.bool,
  };

  static defaultProps = {
    dialogWithErrors: true,
    isOpen: true,
  };

  constructor(props) {
    super(props);

    bindMultiple(this, this.handleRequestClose, this.handleReactModalAfterOpen, this.handleReactModalAfterClose);
  }

  handleRequestClose() {
    if (typeof this.props.onRequestClose === 'function') {
      this.props.onRequestClose();
    }
  }

  forceRenderErrorHandler() {
    if (!ErrorContext.model.isShown) {
      return false;
    }

    ErrorContext.model.forceRender();
  }

  handleReactModalAfterOpen() {
    this.forceRenderErrorHandler();
  }

  handleReactModalAfterClose() {
    this.forceRenderErrorHandler();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isOpen && this.props.dialogWithErrors) {
      ErrorContext.model.setProps({
        id: ERROR_BANNER_IDS.QUOTE_ANNOUNCEMENT_BANNER,
        isShown: false,
      });
    }
  }

  render() {
    const { isOpen, title, actions, children, closeButton, dialogWithErrors } = this.props;

    const portalClassName = classNames('s2s-modal-portal', this.props.portalClassName);
    const modalClassName = classNames('s2s-modal-component', this.props.modalClassName);
    const titleClassName = classNames('s2s-modal-title', this.props.titleClassName);
    const contentClassName = classNames('s2s-modal-content', this.props.contentClassName);
    const actionsContainerClassName = classNames('s2s-modal-actions', this.props.actionsContainerClassName);

    return isOpen ? (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.handleRequestClose}
        bodyOpenClassName="body-overflow-hidden"
        portalClassName={portalClassName}
        overlayClassName="s2s-modal-overlay"
        className={modalClassName}
        shouldCloseOnEsc={true}
        shouldFocusAfterRender={true}
        ariaHideApp={false}
        onAfterOpen={this.handleReactModalAfterOpen}
        onAfterClose={this.handleReactModalAfterClose}
      >
        <div className={titleClassName}>
          {title}
          {!!closeButton && (
            <button className="icon-close s2s-modal-title-close-btn" onClick={this.handleRequestClose} />
          )}
        </div>
        {dialogWithErrors && <div id="s2s-modal-error-handler-placeholder" />}
        <div className={contentClassName} tabIndex="0">
          {children}
        </div>
        <div className={actionsContainerClassName}>{actions}</div>
      </Modal>
    ) : null;
  }
}

export default _Dialog;
