export const CUSTOMIZE_PDF_OPTIONS = {
  showNrcOnQuoteSummary: 'showNrcOnQuoteSummary',
  showNrcPrices: 'showNrcPrices',
  showQuoteDetails: 'showQuoteDetails',
  showCustomerDepositInvoice: 'showCustomerDepositInvoice',
  showEstimatedLeasePayment: 'showEstimatedLeasePayment',
};

export const CUSTOMIZE_PDF_OPTION_LIST = Object.keys(CUSTOMIZE_PDF_OPTIONS);

export const CUSTOMIZE_PDF_OPTION_LABELS = {
  [CUSTOMIZE_PDF_OPTIONS.showNrcOnQuoteSummary]: 'msg_nrc_on_quote_summary',
  [CUSTOMIZE_PDF_OPTIONS.showNrcPrices]: 'msg_nrc_prices_on_quote_details',
  [CUSTOMIZE_PDF_OPTIONS.showQuoteDetails]: 'msg_quote_details_page',
  [CUSTOMIZE_PDF_OPTIONS.showCustomerDepositInvoice]: 'msg_customer_deposit_invoice',
  [CUSTOMIZE_PDF_OPTIONS.showEstimatedLeasePayment]: 'msg_estimated_lease_payment',
};
