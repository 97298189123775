import React, { createRef, Component } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as UUID } from 'uuid';
import bindMultiple from '../../../common/helpers/bindMultiple';
import AbstractControl from '../AbstractControl';
import ResultsCount from '../nested/ResultsCount';
import AutocompleteOptionsList from './nested/AutocompleteOptionsList';
import './css/autocomplete-input.css';
import AutocompleteValue from '../../../common/helpers/AutocompleteValue';
import { getTranslateString } from '../../../common/helpers/getTranslateString';

class AutocompleteControl extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func,
    value: PropTypes.number,
    style: PropTypes.object,
    focus: PropTypes.bool,
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    required: PropTypes.bool,
    fullWidth: PropTypes.bool,
    requiredBlue: PropTypes.bool,
    clearInvalidOnBlur: PropTypes.bool,
    isLoading: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    hint: PropTypes.string,
    errorText: PropTypes.string,
    className: PropTypes.string,
    listClassName: PropTypes.string,
    parentClassName: PropTypes.string,
    optionsListTitle: PropTypes.string,
    tooltipText: PropTypes.string,
    placeholder: PropTypes.string,
    optionsToAdd: PropTypes.number,
  };

  static defaultProps = {
    clearInvalidOnBlur: true,
    isLoading: false,
    optionsToAdd: 100, // 10 is minimum
  };

  constructor(props) {
    super(props);
    this.state = {
      optionsList: [],
      isType: false,
      isOpen: false,
      optionsCount: props.optionsToAdd,
    };

    this.abstractComponentRef = null;
    this.inputElemRef = createRef();
    // TODO: Rework this components to use React's event handlers and parent state / children props
    //       instead of references to DOM nodes
    this.inputElem = null;
    this.inputWrapper = null;

    this.inputAutocompleteValue = AutocompleteValue();
    this.inputNameValue = 'input-' + UUID();

    bindMultiple(
      this,
      this.closeDropDown,
      this.clearFieldValue,
      this.handleOnChange,
      this.handleOnFocus,
      this.handleOnFocusout,
      this.handleInputKeyDown,
      this.handleSelectOption,
      this.fieldOnChange,
      this.openDropDown,
      this.renderMoreOptions,
      this.handleWrapperRefSet
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.options && props.options.length && !state.isType) {
      let newState = {
        optionsList: props.options,
      };

      if (props.lockedState && props.id === 'billingDealerIdDealerSelect') {
        newState.isOpen = false;
      }

      return newState;
    } else {
      return null;
    }
  }

  componentDidMount() {
    this.inputElem = this.inputElemRef.current;
    this.inputWrapper = this.abstractComponentRef.current.querySelector('.autocomplete-input-component');

    if (this.props.value && this.inputElem) {
      let activeOption = this.props.options.find(o => String(o.value) === String(this.props.value)) || {};

      this.inputElem.value = activeOption.text || '';
    }
  }

  getSnapshotBeforeUpdate() {
    if (!this.state.isType && this.props.value === null) {
      return '';
    } else if (this.props.options && this.props.value > 0 && !this.state.isType) {
      let activeOption = this.props.options.find(o => String(o.value) === String(this.props.value)) || {};

      return activeOption.text || '';
    } else if (this.props.options && this.props.value === 0 && !this.state.isType) {
      return '';
    } else {
      return this.inputElem.value;
    }
  }

  componentDidUpdate(prevProps, state, value) {
    this.inputElem.value = value;

    if (prevProps.focus && !this.props.focus) {
      this.inputElem.blur();
    }

    if (prevProps.options.length !== this.props.options.length && value !== '') {
      let optionsList = this.props.options.filter(o => {
        return o.text.toLowerCase().trim().includes(value.toLowerCase().trim());
      });

      this.setState({ optionsList, isType: true, isOpen: true });
    }
  }

  render() {
    const {
      // Props for current component
      id,
      value,
      lockedState,
      disabledState,
      className,
      listClassName,
      optionsListTitle,
      fullWidth,
      placeholder,
      intl,
      isLoading,
      // Props for abstract component
      hint,
      style,
      focus,
      label,
      required,
      errorText,
      requiredBlue,
      tooltipText,
      parentClassName,
    } = this.props;

    const componentClassName = classNames('autocomplete-input-component', className);
    const optionsListClassName = classNames('autocomplete-option-list', listClassName);

    const inputAbstractProps = {
      hint,
      style,
      focus,
      label,
      lockedState,
      disabledState,
      errorText,
      required,
      requiredBlue,
      tooltipText,
      parentClassName,
      className: componentClassName,
      labelFor: id,
    };

    const placeholderTextStr =
      typeof placeholder === 'string'
        ? getTranslateString(placeholder, intl)
        : placeholder && placeholder.props && intl.formatMessage({ id: placeholder.props.id });

    const listTitle = typeof optionsListTitle === 'string' ? getTranslateString(optionsListTitle, intl) : '';
    const isShowResultCnt = this.state.isOpen && this.inputElem && this.inputElem.value.length > 0;
    const iconClass = classNames({
      'field-icon': true,
      'icon-search': !isLoading || lockedState || disabledState,
      loader: isLoading && !(lockedState || disabledState),
    });

    return (
      <AbstractControl
        {...inputAbstractProps}
        containerProps={{ onBlur: this.handleOnFocusout }}
        forwardWrapperRef={this.handleWrapperRefSet}
      >
        <React.Fragment>
          <span className={iconClass} />
          <input
            name={this.inputNameValue}
            autoComplete={this.inputAutocompleteValue}
            type="text"
            id={id}
            className="autocomplete-input"
            ref={this.inputElemRef}
            disabled={lockedState || disabledState}
            placeholder={placeholderTextStr || ''}
            onChange={this.fieldOnChange}
            onFocus={this.handleOnFocus}
            onKeyDown={this.handleInputKeyDown}
          />

          {isShowResultCnt && (
            <ResultsCount clearFieldHandler={this.clearFieldValue} count={this.state.optionsList.length} />
          )}

          {this.state.optionsList.length > 0 && this.state.isOpen && (
            <AutocompleteOptionsList
              id={id}
              value={value}
              fullWidth={fullWidth}
              isOpen={this.state.isOpen}
              onSelectOption={this.handleSelectOption}
              optionsList={this.state.optionsList}
              optionsListTitle={listTitle}
              optionsListClassName={optionsListClassName}
              optionsCount={this.state.optionsCount}
              renderMoreOptions={this.renderMoreOptions}
            />
          )}
        </React.Fragment>
      </AbstractControl>
    );
  }

  renderMoreOptions() {
    this.setState({
      optionsCount: this.state.optionsCount + this.props.optionsToAdd,
    });
  }

  closeDropDown() {
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur();
    }

    if (this.props.clearInvalidOnBlur && this.props.options.length && this.inputElem) {
      // Do a strict match first to support similar labels with different letter case
      let activeOption = this.props.options.find(o => o.text.trim() === this.inputElem.value.trim());

      if (!activeOption) {
        activeOption = this.props.options.find(
          o => o.text.toLowerCase().trim() === this.inputElem.value.toLowerCase().trim()
        );
      }

      if (!activeOption) {
        this.inputElem.value = '';
        this.handleOnChange(null);
      } else {
        this.inputElem.value = activeOption.text;
        this.handleOnChange(activeOption.value);
      }
    }

    this.hideDropDown();
  }

  hideDropDown() {
    this.setState({
      optionsList: this.props.options,
      isType: false,
      isOpen: false,
    });
  }

  handleOnFocusout(ev) {
    if (!ev.relatedTarget) {
      this.closeDropDown();

      return;
    }

    const containerNode = ev.relatedTarget.closest('.autocomplete-input-component');
    const focusMovedToRelatedNode = containerNode !== null;

    if (!focusMovedToRelatedNode) {
      this.closeDropDown();
    }
  }

  openDropDown() {
    if (this.inputElem.value.length > 0) {
      let optionsList = this.props.options.filter(o => {
        return o.text.toLowerCase().trim().includes(this.inputElem.value.toLowerCase().trim());
      });
      this.setState({ optionsList, isType: true, isOpen: true });
    } else {
      this.setState({ optionsList: this.props.options, isOpen: true });
    }
  }

  handleOnFocus() {
    this.openDropDown();
  }

  clearFieldValue() {
    this.inputElem.value = '';
    this.fieldOnChange(null);
  }

  fieldOnChange(event) {
    if (event && event.target.value.length) {
      let optionsList = this.props.options.filter(o => {
        return o.text.toLowerCase().trim().includes(event.target.value.toLowerCase().trim());
      });
      this.setState({ optionsList, isType: true });
    } else {
      this.setState({ optionsList: this.props.options, isType: true });
    }
  }

  handleOnChange(value) {
    if (!this.props.lockedState && !this.props.disabledState && typeof this.props.onChange === 'function') {
      this.props.onChange(value);
    }
  }

  handleSelectOption(event) {
    const { type, value } = event.target.dataset;

    this.handleOnChange(type === 'number' ? Number(value) : value);
    this.hideDropDown();
  }

  handleInputKeyDown(event) {
    const key = event.key;
    const listElem = this.inputWrapper ? this.inputWrapper.querySelector('.autocomplete-option-list') : null;

    switch (key) {
      case 'ArrowDown':
        if (listElem && listElem.firstChild) {
          listElem.firstChild.focus();
        }
        event.preventDefault();
        break;
      case 'ArrowUp':
        if (listElem && listElem.lastChild) {
          listElem.lastChild.focus();
        }
        event.preventDefault();
        break;
      case 'Escape':
        document.activeElement.blur();
        event.preventDefault();
        break;
      case 'Enter':
        if (this.state.optionsList.length === 1) {
          let option = this.state.optionsList[0];
          if (option.text.toLowerCase().trim() === event.target.value.toLowerCase().trim()) {
            this.inputElem.value = option.text;
            this.inputElem.blur();
          }
        }
        event.preventDefault();
        break;
      default:
        break;
    }
  }

  handleWrapperRefSet(ref) {
    this.abstractComponentRef = ref;
  }
}

export default injectIntl(AutocompleteControl);
