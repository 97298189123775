import classnames from 'classnames';
import { FINANCING_OPTIONS, PACKAGE_TYPE_IDS, QUOTE_STATUS } from '../../common/enums';
import { AuthContext, PriceBookContext } from '../../Context';
import { RESULT_TYPES } from '../CalcResultsModel';
import ModelAbstract from '../ModelAbstract';

const SUMMARY_CALC_PROPS = [
  'allInclusive1',
  'allInclusive2',
  'proposalUpfront3',
  'proposalRecur3',
  'proposalUpfront4',
  'proposalRecur4',
];

class CommonPackageModel extends ModelAbstract {
  constructor(higherInstanceModel) {
    super(higherInstanceModel);

    this._ignoreOnExport.push(
      'appState',
      'masterOrder',
      'customerOrder',
      'calcResults',
      'summaryValues',
      'animatedClassName'
    );
  }

  // hardcode, use plain strings instead of 'msg_' translator messages
  // will be removed with proper data-driven implementation
  __getPackageNameForStandaloneQuote() {
    if (
      this.id === PACKAGE_TYPE_IDS.PACKAGE_ONE &&
      this.customerOrder.financingOption === FINANCING_OPTIONS.RENTAL_GREATAMERICA
    ) {
      return 'Rental';
    }

    if (
      this.id === PACKAGE_TYPE_IDS.PACKAGE_ONE &&
      this.customerOrder.financingOption === FINANCING_OPTIONS.ALL_INCLUSIVE_STAR2STAR
    ) {
      return 'All-Inclusive';
    }

    if (this.id === PACKAGE_TYPE_IDS.PACKAGE_THREE) {
      return 'Purchase';
    }
  }

  get appState() {
    return this.controller.appState;
  }

  get masterOrder() {
    return this.appState.masterOrder;
  }

  get customerOrder() {
    return this.controller.customerOrder;
  }

  get calcResults() {
    return this.masterOrder.calcResults;
  }

  get lockedState() {
    return this.appState.locked;
  }

  get disabledState() {
    return !this.isPackageChangeEnabled || this.appState.disabled;
  }

  get priceBookFlags() {
    return PriceBookContext.model.flags;
  }

  get animatedClassName() {
    return classnames({
      'calc-animation': true,
      'fade-in-update-value': this.appState.isCalculated,
      'flash-update-value': this.appState.isCalcRequested,
    });
  }

  get isPackageChangeEnabled() {
    const { locations, quoteStatus: status } = this.masterOrder;
    let enabled = true;

    if ([QUOTE_STATUS.UNFINALIZED, QUOTE_STATUS.AMENDING].includes(status)) {
      enabled = locations.every(({ quoteStatus }) => quoteStatus !== QUOTE_STATUS.FINALIZED);
    }

    return enabled;
  }

  get selectedPackage() {
    return this.customerOrder.packageIdSelected;
  }

  get summaryValues() {
    return SUMMARY_CALC_PROPS.filter(prop => this.calcResults.defined(prop)).reduce((acc, prop) => {
      acc[prop] = this.calcResults.getValue(prop, RESULT_TYPES.float);

      return acc;
    }, {});
  }

  get packageName() {
    if (this.customerOrder.standaloneServiceNonUCaaS) {
      return this.__getPackageNameForStandaloneQuote();
    }

    if (this.isFinanced) {
      return this.priceBookFlags[`packageName${this.id}_${this.customerOrder.financingOption}`];
    }

    return this.priceBookFlags[`packageName${this.id}`];
  }

  get packageShortName() {
    if (this.customerOrder.standaloneServiceNonUCaaS) {
      return this.__getPackageNameForStandaloneQuote();
    }

    if (this.isFinanced) {
      return this.priceBookFlags[`packageShortName${this.id}_${this.customerOrder.financingOption}`];
    }

    return this.priceBookFlags[`packageShortName${this.id}`];
  }

  get hidePackage() {
    if (this.priceBookFlags[`hidePackage${this.id}`] === 'FORCE') {
      return true;
    }

    if (
      this.customerOrder.standaloneServiceNonUCaaS &&
      [PACKAGE_TYPE_IDS.PACKAGE_TWO, PACKAGE_TYPE_IDS.PACKAGE_FOUR].includes(this.id)
    ) {
      return true;
    }

    return AuthContext.model.hasSalesOpsPermissions || this.customerOrder.packageIdSelected === this.id
      ? false
      : Boolean(this.priceBookFlags[`hidePackage${this.id}`]);
  }

  get isFinanced() {
    return [PACKAGE_TYPE_IDS.PACKAGE_ONE, PACKAGE_TYPE_IDS.PACKAGE_TWO].includes(this.id);
  }
}

export default CommonPackageModel;
