import { ModelAbstract, AppErrorsModel } from './index';
import { AppStateContext, AuthContext, PartnerContext, PriceBookContext } from '../Context';
import { ORDER_TYPES, QUOTE_STATUS, UI_QUOTE_STATUS, USER_ROLES } from '../common/enums';

// For copy paste purposes
// const ERROR_FIELDS = [
//   'lineItems',
//   'numLines',
//   'numLocations',
//   'billingDealerId',
//   'dealerContactUserId',
//   'businessContactFirstName',
//   'businessContactLastName',
//   'quoteTitle',
//   'customerContact',
//   'inactiveLineItems',
//   'spiffAmount',
//   'spiffAvailableAmountRc',
//   'spiffAvailableAmountNrc',
//   'salesExceptionsRules',
//   'isLocationUnique',
//   'customerNameLength',
//   'customerNameIsUnique'
// ];

class AppStateModel extends ModelAbstract {
  constructor(instance) {
    super(instance);

    this._forceLocked = false;

    this.accessForbidden = false;
    this.errors = new AppErrorsModel(this);
    this.isEdited = false;
    this.isCalcRequested = false;
    this.isCalculated = false;
    this.isSaveRequested = false;
    this.isFinalizeRequested = false;
    this.isUnFinalizeRequested = false;
    this.isCatalogRequested = false;
    this.isPriceBookRequested = false;
    this.isSubmitSignatureRequested = false;
    this.isSignatureCancelRequested = false;
    this.isCustomerNameValidationRequested = false;
    this.isSyncToTrackingRequested = false;
    this.isCheckpointsRequested = false;
    this.forceDisableQuoteControls = false;
    this.eSigatureFlow = false;
    this.rePushMasterOrderInProgress = false;
    this.rePushLocationInProgress = false;
    this.locationNameValidationRequestsCount = 0;
    this.pricebookIdValidationInProgress = false;
    this.isEmailValidationRequested = false;
    this.isSalesExceptionsRequestInProgress = false;
    this.isMarkersRequested = false;
    this.taxEstimatesGenerating = false;
    this.quoteOperationStateIsUpdating = false;

    this._quoteLoadingCounter = 0;
    this._ignoreOnExport.push('quotes', 'masterOrder', 'calcResults');

    this.setState = this.setState.bind(this);
  }

  get forceLocked() {
    return this._forceLocked || this.forceDisableQuoteControls;
  }

  set forceLocked(value) {
    this._forceLocked = value;
  }

  get quotes() {
    return this.controller.quotes;
  }

  /** @return {CustomerOrderModel} */
  get masterOrder() {
    return this.quotes.customerOrder;
  }

  /** @return {CalcResultsModel} */
  get calcResults() {
    return this.masterOrder ? this.masterOrder.calcResults : {};
  }

  get appInited() {
    return AppStateContext.model.hasDataToOperateQuote && !this.isCatalogRequested;
  }

  get constantsModel() {
    return this.controller.constantsModel;
  }

  setProps(props) {
    if (typeof props.errors === 'object' && props.error !== null) {
      this.errors.setProps(props.errors);
      delete props.errors;
    }

    return super.setProps(props);
  }

  _areFieldsValid(fields) {
    let valid = true;

    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];

      if (this.errors[field] !== '') {
        valid = false;
        break;
      }
    }

    return valid;
  }

  get isCalcIstppsAllowed() {
    return !this._areFieldsValid(this.constantsModel.getCalcFields);
  }

  get isCalcAllowed() {
    return (
      this.appInited &&
      this.isEdited &&
      this._areFieldsValid(this.constantsModel.getCalcFields) &&
      [
        QUOTE_STATUS.OPEN,
        QUOTE_STATUS.FINALIZED,
        QUOTE_STATUS.UNFINALIZED,
        QUOTE_STATUS.SE_PENDING_APPROVAL,
        QUOTE_STATUS.SIGNED_AND_FINALIZED,
        QUOTE_STATUS.SENT_FOR_SIGNATURE,
        QUOTE_STATUS.AMENDING,
      ].includes(this.masterOrder.quoteStatus) &&
      !this.isCalcRequested
    );
  }

  get isSaveAllowed() {
    return (
      !this.isCustomerNameValidationRequested &&
      this.appInited &&
      this.isCalcAllowed &&
      this._areFieldsValid(this.constantsModel.getSaveFields) &&
      [
        QUOTE_STATUS.OPEN,
        QUOTE_STATUS.FINALIZED,
        QUOTE_STATUS.UNFINALIZED,
        QUOTE_STATUS.SE_PENDING_APPROVAL,
        QUOTE_STATUS.SENT_FOR_SIGNATURE,
        QUOTE_STATUS.SIGNED_AND_FINALIZED,
        QUOTE_STATUS.AMENDING,
      ].includes(this.masterOrder.quoteStatus) &&
      !this.isSaved &&
      !this.isSaveRequested &&
      (this.isCalculated || !AppStateContext.model.isAutoCalculateEnabled) &&
      this.locationNameValidationRequestsCount === 0
    );
  }

  get isSaved() {
    return !this.isEdited && !this.isSaveRequested && !this.quoteLoading && this.masterOrder.id;
  }

  get isPrintAllowed() {
    return (
      this.isSaved &&
      this.masterOrder.isSpiffConfirmationValid &&
      [
        QUOTE_STATUS.OPEN,
        QUOTE_STATUS.FINALIZED,
        QUOTE_STATUS.UNFINALIZED,
        QUOTE_STATUS.SENT_FOR_SIGNATURE,
        QUOTE_STATUS.SIGNED_AND_FINALIZED,
        QUOTE_STATUS.AMENDING,
      ].includes(this.masterOrder.quoteStatus)
    );
  }

  get isSignatureFlowAllowed() {
    const {
      orderType,
      quoteStatus,
      quoteStatusLabel,
      useESigDocumentsFlow,
      lockedInPrintFlow,
      isRental,
      isSipStationDocumentsAvailable,
    } = this.masterOrder;

    if (
      (orderType === ORDER_TYPES.ADD_ON && !isRental && !PriceBookContext.model.isSupportsSwitchvoxSIPStation) ||
      (lockedInPrintFlow && !useESigDocumentsFlow)
    ) {
      return false;
    }

    if (
      orderType === ORDER_TYPES.ADD_ON &&
      PriceBookContext.model.isSupportsSwitchvoxSIPStation &&
      !isSipStationDocumentsAvailable
    ) {
      return false;
    }

    if (this.errors.salesExceptionsRules.length > 0) {
      const isStatusSpecial = [
        QUOTE_STATUS.SE_APPROVED,
        QUOTE_STATUS.SENT_FOR_SIGNATURE,
        QUOTE_STATUS.SIGNED_AND_FINALIZED,
      ].includes(quoteStatusLabel);

      return isStatusSpecial || (QUOTE_STATUS.AMENDING === quoteStatus && AuthContext.model.hasSalesOpsPermissions);
    }

    return ![QUOTE_STATUS.FINALIZED, QUOTE_STATUS.SE_PENDING_APPROVAL].includes(quoteStatus);
  }

  get quoteStatus() {
    if (this.isSaveRequested) {
      return UI_QUOTE_STATUS.SAVING;
    }

    if (this.quoteLoading) {
      return UI_QUOTE_STATUS.LOADING;
    }

    if (this.isCalcRequested) {
      return UI_QUOTE_STATUS.CALCULATING;
    }

    if (this.isCatalogRequested) {
      return UI_QUOTE_STATUS.LOADING_CATALOG;
    }

    if (this.isFinalizeRequested) {
      return UI_QUOTE_STATUS.FINALIZING;
    }

    if (this.isUnFinalizeRequested) {
      return UI_QUOTE_STATUS.UN_FINALIZING;
    }

    return this.isSaved ? UI_QUOTE_STATUS.SAVED : UI_QUOTE_STATUS.IN_PROGRESS;
  }

  get locked() {
    if (this.forceLocked || this.quoteOperationStateIsUpdating) {
      return true;
    }

    // KM-9530: Lock quote for non Sales Ops users in case of contract rewrite toggle is ON
    if (!AuthContext.model.hasSalesOpsPermissions && this.masterOrder.contractRewrite) {
      return true;
    }

    // KM-8300: Lock Demo Kit quote for Partners
    if (this.isDemoKitQuoteViewedByPartner) {
      return true;
    }

    if (this.masterOrder.quoteStatus !== QUOTE_STATUS.SE_PENDING_APPROVAL) {
      return (
        [
          QUOTE_STATUS.FINALIZED,
          QUOTE_STATUS.SUBMITTED,
          QUOTE_STATUS.CLOSED,
          QUOTE_STATUS.SENT_FOR_SIGNATURE,
          QUOTE_STATUS.SIGNED_AND_FINALIZED,
        ].includes(this.masterOrder.quoteStatus) ||
        (QUOTE_STATUS.AMENDING === this.masterOrder.quoteStatus && !AuthContext.model.hasInternalPermissions)
      );
    }

    return !AuthContext.model.hasInternalPermissions;
  }

  get disabled() {
    if (this.forceDisableQuoteControls) {
      return true;
    }

    // KM-8300: Force disable every inputs for Partners
    if (this.isDemoKitQuoteViewedByPartner) {
      return true;
    }

    // KM-10954 Lock Rewrite quotes for not Sales ops or Rewrite users
    if (
      this.masterOrder.orderType === ORDER_TYPES.REWRITE &&
      !(AuthContext.model.isSalesOps || AuthContext.model.isRewrite)
    ) {
      return true;
    }

    return (
      !this.appInited ||
      this.quoteLoading ||
      this.isSaveRequested ||
      this.isFinalizeRequested ||
      this.isCatalogRequested ||
      this.masterOrder.quoteStatus === QUOTE_STATUS.CLOSED
    );
  }

  get addOnsDisabled() {
    return this.masterOrder.orderType === ORDER_TYPES.ADD_ON && !AuthContext.model.hasInternalPermissions;
  }

  get isDemoKitQuoteViewedByPartner() {
    return (
      this.masterOrder.dealerDemoKit &&
      // Reverse logic. Quote is NOT viewed by SalesOps or Internal users
      ![USER_ROLES.SALES_OPERATIONS_USER, USER_ROLES.INTERNAL_USER].includes(AuthContext.model.role)
    );
  }

  get switchvoxWlwDisabled() {
    if (AuthContext.model.isSalesOps) {
      return false;
    }

    return PriceBookContext.model.isSupportsSwitchvoxSIPStation && PartnerContext.model.isWhiteLabelWholesale;
  }

  setState(propName, value) {
    if (typeof this[propName] !== 'boolean') {
      return false;
    }

    this[propName] = Boolean(value);
    this.forceRender();

    return true;
  }

  get quoteLoading() {
    return this._quoteLoadingCounter !== 0 || this.quoteOperationStateIsUpdating;
  }

  set quoteLoading(value) {
    if (value) {
      this._quoteLoadingCounter++;
    } else {
      this._quoteLoadingCounter--;
    }

    if (this._quoteLoadingCounter < 0) {
      this._quoteLoadingCounter = 0;
    }
  }
}

export default AppStateModel;
