import React from 'react';
import { AuthContext, ContextConnect, ContextModel } from './';

const context = React.createContext();

class AppStateContextModel extends ContextModel {
  constructor() {
    super();

    this.apiSchemasLoaded = false;
    this.categoriesLoaded = false;
    this.countriesLoaded = false;
    this.dealersLoaded = false;
    this.statesLoaded = false;
    this.tokenValidated = false;
    this.versionLoaded = false;
    this.altDecriptionSettingsLoaded = false;
    this.propertiesLoaded = false;
    this.announcementLoaded = false;
    this.brandNamesLoaded = false;
    this.versionsMismatch = false;
    this.industryTypesLoading = false;

    this._isAutoCalculateLocalStorageKey = 'isAutoCalculate';
    this._isAutoCalculateEnabledDefaultValue = true;
  }

  get appInitiated() {
    return this.tokenValidated && this.versionLoaded && this.propertiesLoaded;
  }

  get hasDataToOperateQuote() {
    return (
      this.appInitiated &&
      this.dealersLoaded &&
      this.categoriesLoaded &&
      this.countriesLoaded &&
      this.statesLoaded &&
      this.apiSchemasLoaded &&
      this.altDecriptionSettingsLoaded &&
      this.propertiesLoaded &&
      this.announcementLoaded &&
      this.brandNamesLoaded
    );
  }

  get isAutoCalculateEnabled() {
    if (!AuthContext.model.hasInternalPermissions) {
      return true;
    }

    const localStorageValue = localStorage.getItem(this._isAutoCalculateLocalStorageKey);

    // Set default value
    if (localStorageValue === null) {
      localStorage.setItem(
        this._isAutoCalculateLocalStorageKey,
        JSON.stringify(this._isAutoCalculateEnabledDefaultValue)
      );

      return this._isAutoCalculateEnabledDefaultValue;
    }

    return Boolean(JSON.parse(localStorageValue));
  }

  set isAutoCalculateEnabled(value) {
    value = Boolean(value);

    localStorage.setItem(this._isAutoCalculateLocalStorageKey, JSON.stringify(value));
  }
}

const model = new AppStateContextModel();
const Provider = ContextConnect(context, model);
const Consumer = context.Consumer;

const AppStateContext = {
  Provider,
  Consumer,
  model,
};

export default AppStateContext;
