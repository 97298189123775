import React, { PureComponent } from 'react';
import Slider from 'rc-slider';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import 'rc-slider/assets/index.css';
import * as formatter from '../../../common/helpers/format';
import translator from '../../../common/translate';
import '../css/mad-slider.css';
import '../css/mad-slider-anchors.css';
import bindMultiple from '../../../common/helpers/bindMultiple';
import SliderTooltip from './SliderTooltip';

const SLIDER_ANCHOR_VALUES = [0, 25, 50, 75, 100];

class MadSlider extends PureComponent {
  static propTypes = {
    lockedState: PropTypes.bool,
    disabledState: PropTypes.bool,
    isConfirmed: PropTypes.bool,
    value: PropTypes.number,
    limitValue: PropTypes.number,
    madRecurring: PropTypes.number,
    madNonRecurring: PropTypes.number,
    madRemain: PropTypes.number,
    madTotal: PropTypes.number,
    madTitle: PropTypes.node,
    isFinanced: PropTypes.bool,
    canBypassConfirmation: PropTypes.bool,
  };

  static defaultProps = {
    lockedState: false,
    disabledState: false,
    isConfirmed: false,
    value: 100,
    limitValue: 100,
    madRecurring: 0,
    madNonRecurring: 0,
    madRemain: 0,
    madTotal: 0,
  };

  constructor(props) {
    super(props);

    bindMultiple(
      this,
      this.handleChange,
      this.handleSliderHandle,
      this.handleSliderAnchorClick,
      this.handleSliderRender
    );
  }

  reverseValue(v) {
    return 100 - (Number(v) || 0);
  }

  formatAmount(v) {
    return formatter.currency(v);
  }

  formatPercent(v, digits = 4) {
    return parseFloat(v).toFixed(digits) + '%';
  }

  handleChange(value) {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.reverseValue(value), 'sliderValue');
    }
  }

  handleSliderHandle() {
    const sliderValue = this.reverseValue(this.props.value);
    const value = this.props.isFinanced ? 0 : sliderValue;
    return (
      <button
        aria-label="Slider handle"
        className={'rc-slider-handle'}
        style={{
          left: value + '%',
          position: 'absolute',
          top: '-50%',
        }}
      />
    );
  }

  handleSliderAnchorClick(ev) {
    if (typeof this.props.onChange === 'function') {
      const value = Number(ev.target.value);

      this.props.onChange(this.reverseValue(value), 'sliderValue');
    }
  }

  handleSliderRender(renderProps, { value, dragging }) {
    return (
      <div {...renderProps.props}>
        <SliderTooltip value={this.formatPercent(value)} dragging={dragging} />
      </div>
    );
  }

  render() {
    const {
      madTitle,
      madRemain,
      madRecurring,
      madNonRecurring,
      madRecurringMax,
      disabledState,
      lockedState,
      value,
      limitValue,
      madTotal,
      isConfirmed,
      isFinanced,
      canBypassConfirmation,
      intl,
    } = this.props;

    const sliderTooltipText = madTotal === 0 ? intl.formatMessage({ id: 'msg_mad_value_must_be_bigger_then_0' }) : '';
    const sliderValue = this.reverseValue(value);
    const monthlyValue = isFinanced ? 100 : value;
    const upfrontValue = isFinanced ? 0 : sliderValue;
    const locked =
      lockedState || disabledState || (!canBypassConfirmation && isConfirmed) || madTotal === 0 || isFinanced;

    const sliderProps = {
      min: 0,
      max: 100,
      step: 0.0001,
      disabled: locked,
      className: 'percent-slider',
      onChange: this.handleChange,
      handle: this.handleSliderHandle,
      onAfterChange: this.handleChange,
      handleRender: this.handleSliderRender,
      value: isFinanced ? 0 : sliderValue,
    };
    const limitSliderProps = {
      min: 0,
      max: 100,
      step: 0.0001,
      value: isFinanced ? 0 : limitValue,
      disabled: true,
      className: 'percent-slider-limit',
    };

    return (
      <div className="percent-slider-component">
        <div className="mad-amount-labels">
          <span>{translator.getMessage('msg_available_recurring')}</span>
          <span>{translator.getMessage('msg_available_non_recurring')}</span>
        </div>
        <div className="mad-amount-title">
          <span>{madTitle}</span>
        </div>
        <div className="dollar-amount-display">
          <span className="monthly-amount">
            {this.formatAmount(madRecurringMax)}/{translator.getMessage('msg_month')}
          </span>
          <span className="upfront-amount">{this.formatAmount(madRemain)}</span>
        </div>
        <div className="percent-slider-limit-label">
          {translator.getMessage('msg_allocation_of_maximum_allowable_discount_for_purchase_order_type')}
        </div>
        <div
          title={intl.formatMessage({
            id: 'msg_maximum_allowed_towards_non_recurring_mad',
          })}
        >
          <Slider {...limitSliderProps} />
        </div>
        <div title={sliderTooltipText}>
          <Slider {...sliderProps} />
          <div id="mad-slider-anchors">
            {SLIDER_ANCHOR_VALUES.map(percentage => {
              const id = 'mad-slider-anchor-' + percentage;

              return (
                <button
                  id={id}
                  key={id}
                  disabled={locked}
                  value={percentage}
                  title={percentage + '%'}
                  className={['mad-slider-anchor', 'p' + percentage].join(' ')}
                  style={{ left: percentage + '%' }}
                  type="button"
                  tabIndex="-1"
                  onClick={this.handleSliderAnchorClick}
                />
              );
            })}
          </div>
        </div>

        <div className="percent-slider-legend-wrapper">
          <div className="percent-slider-legend-left">
            <span className="percent-slider-value monthly">{this.formatPercent(monthlyValue, 2)}</span>
            <span>{translator.getMessage('msg_applied_recurring')}</span>
            <span>
              <strong>{this.formatAmount(madRecurring)}</strong>
            </span>
          </div>
          <div className="percent-slider-legend-right">
            <span className="percent-slider-value upfront">{this.formatPercent(upfrontValue, 2)}</span>
            <span>{translator.getMessage('msg_applied_non_recurring')}</span>
            <span>
              <strong>{this.formatAmount(madNonRecurring)}</strong>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(MadSlider);
